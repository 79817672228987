import { LocaleCode } from "../../features/i18n";

const MINUTE_MS = 60_000;
const EXPIRATION_MINUTES = 30;

export const setLocaleCodeCookie = (localeCode: LocaleCode) => {
  const expiration = new Date(Date.now() + EXPIRATION_MINUTES * MINUTE_MS);
  const domain =
    window.location.hostname === "localhost" ? "localhost" : "curative.com";
  document.cookie = `locale=${localeCode}; expires=${expiration.toUTCString()}; domain=${domain}; path=/`;
};
