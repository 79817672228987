import { RadioVariant } from "../Radio";
import { RadioSelection } from "../RadioSelection";
import { CUIComponent, CUIComponentProps } from "../types";

export type AppointmentTimeSelectorOption = {
  /* the unique key for this option to refer to it */
  value: string;

  /* the value of the label that appears beside the radio */
  label: string;

  /* whether the radio is an error state or not */
  hasError?: boolean;
};

export type AppointmentTimeSelectorProps = CUIComponentProps<{
  id: string;
  label: string | null; // label that can appear before the radio, ie. "Select pizza size"
  description?: string;
  options: Array<AppointmentTimeSelectorOption>;
  currentlySelectedOption: string | null;
  testId?: string;
  onRequestToggle: (key: string) => void; //called whenever a new radio is selected
}>;

export const AppointmentTimeSelector: CUIComponent<
  AppointmentTimeSelectorProps
> = ({
  id,
  className,
  label,
  description,
  options,
  currentlySelectedOption,
  onRequestToggle,
  testId = "AppointmentTimeSelector",
}) => {
  return (
    <RadioSelection
      testId={testId}
      id={id}
      label={label}
      description={description}
      options={options}
      currentlySelectedOption={currentlySelectedOption}
      onRequestToggle={onRequestToggle}
      variant={RadioVariant.LabelButton}
      className={className}
    />
  );
};
