import { useCallback } from "react";

import { useMixpanelContext } from "../contexts";
import { MixpanelAppName } from "../enums";
import { MixpanelEventOptions } from "../types";

export type EventOptionsWithoutAppName = Omit<MixpanelEventOptions, "appName">;

export type TrackAppSpecificEvent = (
  eventOptions: EventOptionsWithoutAppName
) => void;

const useAppSpecificMixpanelContext = (appName: MixpanelAppName) => {
  const {
    trackEvent: _trackEvent,
    debouncedTrackEvent: _debouncedTrackEvent,
    ...rest
  } = useMixpanelContext();

  const trackEvent: TrackAppSpecificEvent = useCallback(
    (eventOptions: EventOptionsWithoutAppName) => {
      return _trackEvent({
        ...eventOptions,
        appName,
      });
    },
    [_trackEvent, appName]
  );

  const debouncedTrackEvent: TrackAppSpecificEvent = useCallback(
    (eventOptions: EventOptionsWithoutAppName) => {
      return _debouncedTrackEvent({
        ...eventOptions,
        appName,
      });
    },
    [_debouncedTrackEvent, appName]
  );

  return {
    trackEvent,
    debouncedTrackEvent,
    ...rest,
  };
};

export const useMemberPortalMixpanelContext = () => {
  const context = useAppSpecificMixpanelContext(MixpanelAppName.MEMBER_PORTAL);

  return { ...context };
};

export const useMemberStatusPagesMixpanelContext = () => {
  const context = useAppSpecificMixpanelContext(
    MixpanelAppName.MEMBER_STATUS_PAGES
  );

  return { ...context };
};

export const useProviderPortalMixpanelContext = () => {
  const context = useAppSpecificMixpanelContext(
    MixpanelAppName.PROVIDER_PORTAL
  );

  return { ...context };
};

export const useAdminPortalMixpanelContext = () => {
  const context = useAppSpecificMixpanelContext(MixpanelAppName.ADMIN_PORTAL);

  return { ...context };
};

export const useProviderFacilitySearchMixpanelContext = () => {
  const context = useAppSpecificMixpanelContext(
    MixpanelAppName.PROVIDER_FACILITY_SEARCH
  );

  return { ...context };
};

export const useEmployerPortalMixpanelContext = () => {
  const context = useAppSpecificMixpanelContext(
    MixpanelAppName.EMPLOYER_PORTAL
  );

  return { ...context };
};

export const useFormularySearchMixpanelContext = () => {
  const context = useAppSpecificMixpanelContext(
    MixpanelAppName.FORMULARY_SEARCH
  );

  return { ...context };
};
