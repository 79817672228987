/* eslint-disable @typescript-eslint/no-explicit-any */

import { Icon, IconVariant, SpacingVariant } from "@chp/curative_ui";
import TablePagination from "@material-ui/core/TablePagination";
import clsx from "clsx";
import { isEmpty, isEqual, throttle } from "lodash-es";
import {
  Fragment,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { TableInstance, usePagination, useTable } from "react-table";

import { SpinnerCentered } from "../SpinnerCentered";
import TableCell from "./TableCell";
import TableHeader from "./TableHeader";
import TablePaginationActions from "./TablePaginationActions";
import TableRow from "./TableRow";
import {
  FetchDataInput,
  ItemPage,
  ORDER_ASC,
  ORDER_DESC,
  OrderableColumn,
  OrderSpec,
} from "./types";

const DEFAULT_PAGE_SIZE = 20;
const FETCH_DATA_THROTTLE_MS = 500;

const EmptyTableBody = ({
  hasFilters,
  className,
}: {
  hasFilters: boolean;
  className?: string;
}) => {
  return (
    <div className={clsx("text-base w-full py-14 text-center", className)}>
      {hasFilters ? (
        <FormattedMessage
          defaultMessage="No results found. Please check that your search filters are set appropriately."
          id="uLxOtL"
        />
      ) : (
        <FormattedMessage defaultMessage="No results found" id="hX5PAb" />
      )}
    </div>
  );
};

interface SmartTableContentProps {
  id: string;
  columns: Array<any>;
  data: ItemPage<any>;
  disablePagination?: boolean;
  fetchData: (...args: [FetchDataInput]) => void;
  searchQuery: string;
  filters?: Record<string, any>;
  defaultFilters?: Record<string, any>;
  isLoading: boolean;
  onRowClick?: (row: any) => void;
  rowToHref?: (row: any) => {
    href: string;
    as: string;
  };
  setIsLoading: (value: boolean) => void;
  refresh: boolean;
  noHover?: boolean;
  refreshInterval?: number;
  minWidth?: number;
}

const SmartTableContent: FunctionComponent<SmartTableContentProps> = (
  props
) => {
  // ~ Ordering ~ //
  //
  // Note that this does not currently support multiple orders. One day we can
  // if we need to by wrapping this method and adding/removing based on OrderSet
  const [orderBy, setOrderBy] = useState<OrderSpec>();
  const resetOrderBy = () => {
    //@ts-expect-error fails strict type checking - please fix!
    setOrderBy(null);
  };

  // ~ Pagination ~ //
  //
  // When the page changes, we want to know if we are moving forward or
  // backwards so we can send either `page[start]` or `page[end]` to the
  // backend
  const [previousPageIndex, setPreviousPageIndex] = useState(0);

  const hasMore = props.data.afterCursor != null;

  //                                                                  //
  // Now we build the actual table instance. This is from React-Table //
  // and does much of the heavy lifting.                              //
  //                                                                  //
  // We first give it the columns and associated data                 //
  //                                                                  //
  // We then set flags to let it know that we are doing our own       //
  // pagination and sorting, as well as some initial state.           //
  const table: TableInstance = useTable(
    {
      data: props.data.items,
      columns: props.columns,
      // @ts-ignore
      initialState: { pageIndex: 0, pageSize: DEFAULT_PAGE_SIZE },
      pageCount: -1,
      manualPagination: true,
      manualSortBy: true,
      defaultCanSort: true,
      autoResetSortBy: false,
    },
    usePagination
  );

  // The table itself is a collection functions. The different get*Props are
  // how it passes properties to the elements wrapping those things.
  //
  // `prepareRow` adds state data to a row before rendering it
  // `gotoPage` changes the active page of data
  // `setPageSize` sets the size of a paginated page
  // `rows` is the data we passed in as rows
  // `state` contains things we passed in and what's handled internally. You can
  //         see that reat-table handles the page state.
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    setPageSize,
    rows,
    // @ts-ignore
    state: { pageSize, pageIndex },
  } = table;

  // When a page changes, we want to know what the prior page was. This is
  // because pagination sent to the backend is cursor-based.

  // Focus on header on any page change
  const handleChangePage = (_event: any, newPage: number) => {
    setPreviousPageIndex(pageIndex);
    gotoPage(newPage);
    document?.getElementById(`${props.id}__Header`)?.scrollIntoView(false);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPageSize(Number(event.target.value));
    document?.getElementById(`${props.id}__Header`)?.scrollIntoView(false);
  };

  // If any of the non-page related state changes, we want to reset
  // the page to 0 so they are returned to the first results matching their
  // query/filters/order.
  useEffect(
    () => {
      setPreviousPageIndex(0);
      gotoPage(0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.fetchData, props.searchQuery, props.filters, orderBy]
  );

  const fetchPayload = {
    props,
    pageIndex,
    previousPageIndex,
    pageSize,
    orderBy,
  };

  // When any of the pagination, filter, ordering, or search query items change,
  // re-fetch the data. Also re-fetch if we have passed down a new fetching
  // function.
  const throttledSearch = useRef(
    throttle((payload: typeof fetchPayload) => {
      //@ts-expect-error fails strict type checking - please fix!
      asyncFetchData(payload);
    }, FETCH_DATA_THROTTLE_MS)
  ).current;
  useEffect(
    () => {
      throttledSearch(fetchPayload);
      let refreshTimer: number | undefined;
      if (props.refreshInterval) {
        // @ts-ignore
        refreshTimer = setInterval(() => {
          throttledSearch(Object.assign(fetchPayload, { silent: true }));
        }, props.refreshInterval);
      }
      return () => {
        if (refreshTimer) {
          clearInterval(refreshTimer);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props.fetchData,
      pageSize,
      pageIndex,
      props.filters,
      props.refresh,
      props.refreshInterval,
      orderBy,
    ]
  );

  return (
    <div className="flex min-h-0 flex-1 flex-col">
      <div className="flex-1 overflow-x-scroll">
        <div style={props.minWidth ? { minWidth: `${props.minWidth}px` } : {}}>
          <div className="flex w-full flex-col" {...getTableProps()}>
            <div id={`${props.id}__Header`}></div>
            <TableHeader className="">
              {headerGroups.map((headerGroup) => {
                const headerGroupKey = headerGroup.headers
                  .map((header) => header.Header)
                  .join("-");
                return (
                  <Fragment key={headerGroupKey}>
                    <TableRow {...headerGroup.getHeaderGroupProps()} noHover>
                      {headerGroup.headers.map((column) => {
                        return (
                          <TableCell
                            width={column.width}
                            key={`header-cell-${column.id}`}
                            className={clsx(
                              "relative break-words py-0 font-medium",
                              // @ts-ignore
                              column.canSort &&
                                "cursor-pointer font-semibold	text-gray-700"
                            )}
                            onClick={() => {
                              // If the column cannot sort or the current sorted
                              // column is not this one, return
                              // @ts-ignore
                              if (!column.canSort) {
                                return;
                              }
                              // If the column is sorted descending, swap that
                              // to ascending.
                              if (
                                !orderBy ||
                                // @ts-ignore
                                orderBy[0] !== column.sortType
                              ) {
                                setOrderBy([
                                  // @ts-ignore
                                  column.sortType as OrderableColumn,
                                  ORDER_DESC,
                                ]);
                              } else if (orderBy[1] === ORDER_DESC) {
                                setOrderBy([
                                  // @ts-ignore
                                  column.sortType,
                                  ORDER_ASC,
                                ]);
                              } else {
                                resetOrderBy();
                              }
                            }}
                          >
                            <div {...column.getHeaderProps()}>
                              <div>
                                <>{column.Header}</>
                                <span className="ml-2 inline-flex items-center">
                                  {/* @ts-ignore */}
                                  {orderBy && column.sortType === orderBy[0] ? (
                                    orderBy[1] === ORDER_DESC ? (
                                      <Icon
                                        className="pt-[2px]"
                                        size={SpacingVariant.S20}
                                        variant={IconVariant.CHEVRON_DOWN}
                                      />
                                    ) : (
                                      <Icon
                                        className="pt-[2px]"
                                        size={SpacingVariant.S20}
                                        variant={IconVariant.CHEVRON_UP}
                                      />
                                    )
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableHeader>
            {props.isLoading ? (
              <SpinnerCentered className="p-20" size={SpacingVariant.S48} />
            ) : rows.length === 0 ? (
              <EmptyTableBody
                hasFilters={
                  props.defaultFilters
                    ? !isEqual(props.filters, props.defaultFilters)
                    : !isEmpty(props.filters)
                }
              />
            ) : (
              <div className="overflow-y-scroll">
                <div {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    const hrefProps = props.rowToHref
                      ? props.rowToHref(row)
                      : {};
                    return (
                      <Fragment key={row.id}>
                        <TableRow
                          {...row.getRowProps()}
                          {...hrefProps}
                          onClick={() => {
                            if (props.onRowClick) props.onRowClick(row);
                          }}
                          dark={row.index % 2 === 0}
                          noHover={props.noHover}
                        >
                          {row.cells.map((cell: any) => {
                            return (
                              <TableCell
                                key={cell.key}
                                {...cell.getCellProps()}
                                textWrapping={cell.column.textWrapping}
                                width={cell.column.width}
                              >
                                {cell.render("Cell")}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="sticky bottom-0 shrink-0">
        {!props.disablePagination && (
          <TableRow noHover>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              colSpan={99}
              component="div"
              count={
                hasMore
                  ? (pageIndex + 1) * pageSize + 1
                  : pageIndex * pageSize + props.data.items.length
              }
              rowsPerPage={pageSize}
              page={pageIndex}
              labelDisplayedRows={({ from, to }) => `${from}-${to} `}
              style={{ justifyContent: "center" }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        )}
      </div>
    </div>
  );
};

// This is the function that compiles all of our various state and calls the
// provided `fetchData` function.
const asyncFetchData = async (payload: {
  props: SmartTableContentProps;
  pageIndex: number;
  previousPageIndex: number;
  pageSize: number;
  orderBy: OrderSpec;
  searchQuery: string;
  silent?: boolean;
}) => {
  const { props, pageIndex, previousPageIndex, pageSize, orderBy, silent } =
    payload;
  if (!silent) {
    props.setIsLoading(true);
  }
  let after, before;
  // If we're moving a page forward, we set the after
  if (pageIndex > previousPageIndex) {
    after = props.data.afterCursor;
    before = null;
    // If we're moving a page backwards, we set the pageBefore to start the
    // next page before our current first piece of data
  } else if (pageIndex !== 0 && pageIndex < previousPageIndex) {
    after = null;
    before = props.data.beforeCursor;
  } else if (pageIndex === 0) {
    after = null;
    before = null;
  }

  // eslint-disable-next-line @typescript-eslint/await-thenable
  await props.fetchData({
    pageSize,
    after,
    before,
    orderBy,
    filters: props.filters,
    searchQuery: props.searchQuery,
  });
  if (!silent) {
    props.setIsLoading(false);
  }
};

export default SmartTableContent;
