import clsx from "clsx";
import Link from "next/link";
import { FC, useState } from "react";

import { FOCUS_CLASS_NAMES, SpacingVariant } from "../../constants";
import { Icon, IconVariant } from "../Icon";
import { Tooltip } from "../Tooltip";
import { CUIComponentProps } from "../types";
import { ACTIVE_LINK_CLASSNAMES, INACTIVE_LINK_CLASSNAMES } from "./SideNavbar";
import { IconOrSpacer } from "./utils";

export type NavLinkProps = CUIComponentProps<{
  href: string | null;
  label: string;
  iconVariant?: IconVariant;
  isActive?: () => boolean;
  isExternal?: boolean;
  isNavbarCollapsed?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  testId?: string;
  isFloatingMenuItem?: boolean;
}>;

export const NavLink: FC<NavLinkProps> = ({
  href,
  label,
  iconVariant,
  isActive,
  isExternal,
  isNavbarCollapsed,
  onClick,
  testId,
  isFloatingMenuItem,
}) => {
  const navLinkProps = {
    label,
    iconVariant,
    href,
    testId,
    isFloatingMenuItem,
    isExternal,
    className: clsx(
      "relative",
      "focus:z-40",
      "focus-visible:z-40",
      "hover:text-textColor-highlight-default",
      isFloatingMenuItem ? "flex justify-between" : "grid items-center",
      !isNavbarCollapsed &&
        !isFloatingMenuItem &&
        "grid-cols-[40px_1fr_auto] px-4 py-3",
      isFloatingMenuItem ? "px-3 py-2" : "p-3",
      isActive?.() ? ACTIVE_LINK_CLASSNAMES : INACTIVE_LINK_CLASSNAMES,
      FOCUS_CLASS_NAMES
    ),
    linkProps: {
      key: href,
      onClick: () => onClick?.(),
      target: isExternal ? "_blank" : "_self",
      rel: "noreferrer",
    },
  };

  return isNavbarCollapsed ? (
    <CollapsedNavLink {...navLinkProps} />
  ) : (
    <ExpandedNavLink {...navLinkProps} />
  );
};

export const ExpandedNavLink: FC<
  NavLinkProps & {
    linkProps: {
      key: string | null;
      onClick: () => void | undefined;
      target: string;
      rel: string;
    };
  }
> = ({
  href,
  label,
  iconVariant,
  isActive,
  isExternal,
  testId,
  linkProps,
  isFloatingMenuItem,
  className,
}) => {
  if (!href) return null;
  const linkContent = (
    <>
      <IconOrSpacer
        iconVariant={iconVariant}
        testId={testId}
        isFloatingMenuItem={isFloatingMenuItem}
      />
      <span
        className={clsx(
          "whitespace-nowrap",
          "overflow-hidden",
          "text-left",
          "text-ellipsis",
          isActive?.() ? "text-lg-short font-semibold" : "text-lg font-normal"
        )}
      >
        {label}
      </span>
    </>
  );

  return (
    <div className={clsx("relative w-full")} data-testid={testId}>
      {isExternal ? (
        <a
          data-testid={`${testId}__ExternalLink`}
          href={href}
          className={clsx(className)}
          {...linkProps}
        >
          {linkContent}
          <Icon
            className="ml-2"
            variant={IconVariant.EXTERNAL_LINK}
            size={SpacingVariant.S24}
          />
        </a>
      ) : (
        <Link
          href={href}
          className={clsx(className)}
          data-testid={`${testId}__InternalLink`}
          {...linkProps}
        >
          {linkContent}
        </Link>
      )}
    </div>
  );
};

export const CollapsedNavLink: FC<
  NavLinkProps & {
    linkProps: {
      key: string | null;
      onClick: () => void | undefined;
      target: string;
      rel: string;
    };
  }
> = ({
  href,
  label,
  iconVariant,
  testId = "NavLinkCollapsed",
  linkProps,
  className,
}) => {
  const [tooltipTriggerElement, setTooltipTriggerElement] =
    useState<HTMLAnchorElement | null>(null);

  if (!href) return null;
  return (
    <div className={clsx("relative w-full")} data-testid={testId}>
      <a
        ref={setTooltipTriggerElement}
        data-testid={`${testId}__CollapsedLink`}
        href={href}
        className={clsx(className, "text-lg")}
        {...linkProps}
      >
        <IconOrSpacer iconVariant={iconVariant} testId={testId} />
        <Tooltip
          triggerElement={tooltipTriggerElement}
          message={label}
          fitMessage
        />
      </a>
    </div>
  );
};
