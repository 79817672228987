import { toDate } from "date-fns-tz";
import { useEffect, useState } from "react";

// A polling interval of every second
const PRIMARY_BUTTON_TIMEOUT_INTERVAL = 1000;

const MS_PER_MIN = 60_000;

export const useJoinVisitMeetingLinkTimer = ({
  appointmentDateString,
  timeToAppointment,
}: {
  appointmentDateString: string | null | undefined;
  // The amount of time in minutes before the appointment's scheduled time when the button becomes enabled
  timeToAppointment: number;
}): boolean => {
  const [isPrimaryButtonEnabled, setPrimaryButtonIsEnabled] = useState(
    calculateIfPrimaryButtonIsEnabled(appointmentDateString, timeToAppointment)
  );

  useEffect(() => {
    // Set the initial value when the timer first starts.
    // Without this there would be a period for the duration of the first time
    // interval where the value of `isPrimaryButtonEnabled` is incorrect.
    setPrimaryButtonIsEnabled(
      calculateIfPrimaryButtonIsEnabled(
        appointmentDateString,
        timeToAppointment
      )
    );

    // Start a timer that is cancelled the next time the effect runs
    const timer = setInterval(() => {
      setPrimaryButtonIsEnabled(
        calculateIfPrimaryButtonIsEnabled(
          appointmentDateString,
          timeToAppointment
        )
      );
    }, PRIMARY_BUTTON_TIMEOUT_INTERVAL);

    return () => clearInterval(timer);
  }, [timeToAppointment, appointmentDateString, setPrimaryButtonIsEnabled]);

  return isPrimaryButtonEnabled;
};

// Returns `true` if it is currently within 15 minutes from the appointment's scheduled time
const calculateIfPrimaryButtonIsEnabled = (
  appointmentDateString: string | null | undefined,
  timeToAppointment: number
): boolean => {
  if (!appointmentDateString) return false;
  const appointmentDate = toDate(appointmentDateString);
  const appointmentDateTime = appointmentDate.getTime();
  const currentDateTime = new Date(Date.now()).getTime();
  const timeDifference = appointmentDateTime - currentDateTime;
  const timeDifferenceInMinutes = timeDifference / 1000 / 60;
  return timeDifferenceInMinutes <= timeToAppointment;
};

// Calculate the appointment time, minus 15 minutes
export const calculateDateTimeToJoinMeeting = (
  appointmentDateString: string | null | undefined,
  timeToAppointment: number
): Date | null => {
  if (!appointmentDateString) return null;
  const appointmentDate = toDate(appointmentDateString);
  const appointmentDateTime = appointmentDate.getTime();
  return new Date(appointmentDateTime - timeToAppointment * MS_PER_MIN);
};
