import {
  Badge,
  BadgeVariant,
  Box,
  DisplayVariant,
  HeadingLevel,
  SpacingVariant,
  textColorsCommon,
  TextVariant,
  VerticalAlignVariant,
} from "@chp/curative_ui";
import { PlainDate } from "@chp/shared/utils/dateTime";
import clsx from "clsx";
import { isEmpty } from "lodash-es";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { FamilyCardMemberType } from "../../enums";
import { memberTypeToText } from "../../utils";

export type FamilyCardHeaderProps = {
  headingLevel: HeadingLevel;
  headingTextVariant?: TextVariant;
  headingVerticalSpacingVariant?: SpacingVariant;
  invitedOnDate?: PlainDate;
  memberName: string;
  memberType: FamilyCardMemberType;
  testId?: string;
};

export const memberTypeToBadgeVariant: Record<
  FamilyCardMemberType,
  BadgeVariant
> = {
  [FamilyCardMemberType.CHILD]: BadgeVariant.SUBDUED,
  [FamilyCardMemberType.OTHER]: BadgeVariant.SUBDUED,
  [FamilyCardMemberType.POLICYHOLDER]: BadgeVariant.DEPRESSED,
  [FamilyCardMemberType.SPOUSE]: BadgeVariant.SUBDUED,
};

const displayVariant: DisplayVariant = DisplayVariant.InlineBlock;
const verticalAlignVariant: VerticalAlignVariant = VerticalAlignVariant.Middle;

export const FamilyCardHeader: FC<FamilyCardHeaderProps> = ({
  headingLevel,
  headingTextVariant = TextVariant.LgBoldShort,
  headingVerticalSpacingVariant = SpacingVariant.S4,
  invitedOnDate,
  memberName,
  memberType,
  testId = "FamilyCardHeader",
}) => {
  const { formatMessage } = useIntl();

  return (
    <Box element="header" testId={testId}>
      <Box
        display={displayVariant}
        element={headingLevel}
        margin={{
          bottom: headingVerticalSpacingVariant,
          right: SpacingVariant.S8,
          top: headingVerticalSpacingVariant,
        }}
        testId={`${testId}__Heading`}
        textVariant={headingTextVariant}
        verticalAlign={verticalAlignVariant}
      >
        {memberName}
      </Box>
      <Box
        display={displayVariant}
        element="div"
        margin={{
          right: invitedOnDate ? SpacingVariant.S8 : undefined,
        }}
        verticalAlign={verticalAlignVariant}
        testId={`${testId}__BadgeContainer`}
      >
        <Badge
          size="small"
          message={memberTypeToText({ formatMessage })[memberType]}
          testId={`${testId}__Badge`}
          variant={memberTypeToBadgeVariant[memberType]}
        />
      </Box>
      {!isEmpty(invitedOnDate) && (
        <Box
          className={clsx(textColorsCommon.highlightDefault)}
          display={displayVariant}
          element="div"
          verticalAlign={verticalAlignVariant}
          testId={`${testId}__InvitedOnDate`}
          textVariant={TextVariant.MdRegularTall}
        >
          <FormattedMessage
            defaultMessage="Invited on {date}"
            id="YsZd7g"
            values={{
              date: invitedOnDate.toISOString(),
            }}
          />
        </Box>
      )}
    </Box>
  );
};
