import "../styles/globals.css";

import { ApolloProvider } from "@apollo/client";
import {
  ChpAppProps,
  createLaunchDarklyProviderConfig,
  ErrorBoundary,
  getAppInitialProps,
  NextPageWithLayout,
  SmartlingTracker,
} from "@chp/shared";
import { ContentfulProvider } from "@chp/shared/contexts/ContentfulContext";
import { initializeDatadogRum, MixpanelProvider } from "@chp/shared/features";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import type { AppContext, AppProps } from "next/app";
import { ComponentType, ReactElement } from "react";

import IdleLogout from "~/components/IdleLogout";
import Layout from "~/components/Layout";
import AuthProvider from "~/contexts/AuthContext";
import { contentfulProps } from "~/utils/contentfulProps";

import client from "../apollo-client";
import { MemberPortalI18nProvider } from "../components";

initializeDatadogRum({
  applicationId: "7777b7d4-07b5-4f56-bedc-e74a579fcf58",
  clientToken: "pub5cc82b11263223dedc7a4487eb8a1d03",
  site: "datadoghq.com",
  service: "member_portal",
  env: process.env.NEXT_PUBLIC_DEPLOYED_ENV || "",
});

type Props = AppProps &
  ChpAppProps & {
    Component: NextPageWithLayout;
  };

function App({ Component, localeCodesInitial, pageProps }: Props) {
  function getDefaultLayout(page: ReactElement) {
    return <Layout>{page}</Layout>;
  }

  const getLayout = Component.getLayout || getDefaultLayout;

  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <MemberPortalI18nProvider localeCodesInitial={localeCodesInitial}>
          <SmartlingTracker />
          <MixpanelProvider>
            <ContentfulProvider {...contentfulProps}>
              <AuthProvider>
                <IdleLogout />
                {getLayout(<Component {...pageProps} />)}
              </AuthProvider>
            </ContentfulProvider>
          </MixpanelProvider>
        </MemberPortalI18nProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
}

App.getServerSideProps = async ({ ctx }: AppContext) => {
  // Check if the current route is 'member-payment-card'
  if (ctx && ctx.req && ctx.req.url === "/member-payment-card") {
    if (ctx.res) {
      ctx.res.writeHead(302, { Location: "/zero-card" });
      ctx.res.end();
    }
    return { props: {} };
  }

  // No redirection needed
  return {
    props: {},
  };
};

App.getInitialProps = getAppInitialProps;

export default withLDProvider(
  createLaunchDarklyProviderConfig({
    clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_SIDE_ID || "",
  })
)(App as ComponentType<unknown>);
