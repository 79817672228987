import clsx from "clsx";
import { ReactNode, useEffect, useRef } from "react";

import { SpacingVariant } from "../../constants";
import { linkClassNamesCommonNoColor } from "../../utils";
import {
  Box,
  BoxProps,
  DisplayVariant,
  FOCUS_RING_CLASS_NAMES,
  Icon,
  IconVariant,
  ScreenReaderOnly,
} from "..";
import { CUIComponent, CUIComponentProps, TextVariant } from "../types";

export enum AlertType {
  SUCCESS = "SUCCESS",
  WARN = "WARN",
  ERROR = "ERROR",
}

export type AlertProps = CUIComponentProps<
  {
    type: AlertType;
    title: string | ReactNode;
    message?: string | ReactNode;
    ariaLive?: "polite" | "off" | "assertive";
    shouldSetFocusOnMount?: boolean;
  } & (
    | {
        closeButtonText: string;
        onRequestClose: () => void;
      }
    | {
        closeButtonText?: undefined;
        onRequestClose?: undefined;
      }
  )
>;

const ICON_MARGIN_RIGHT = "mr-2";

const getIconForType = (type: AlertType) => {
  switch (type) {
    case AlertType.SUCCESS:
      return (
        <Icon
          size={SpacingVariant.S24}
          variant={IconVariant.CHECK_CIRCLE}
          className={clsx(ICON_MARGIN_RIGHT, "text-textColor-success")}
        />
      );
    case AlertType.WARN:
      return (
        <Icon
          size={SpacingVariant.S24}
          variant={IconVariant.EXCLAMATION_CIRCLE}
          className={clsx(ICON_MARGIN_RIGHT, "text-textColor-warning")}
        />
      );
    default:
      return (
        <Icon
          size={SpacingVariant.S24}
          variant={IconVariant.X_CIRCLE}
          className={clsx(ICON_MARGIN_RIGHT, "text-textColor-critical")}
        />
      );
  }
};

const getColorsForType = (type: AlertType): string[] => {
  if (type === AlertType.SUCCESS) {
    return [
      "bg-surface-success",
      "border-t-borderColor-success",
      "text-textColor-success",
    ];
  }
  if (type === AlertType.WARN) {
    return [
      "bg-surface-warning",
      "border-t-borderColor-warning",
      "text-textColor-warning",
    ];
  }
  return [
    "bg-surface-critical",
    "border-t-borderColor-critical",
    "text-textColor-critical",
  ];
};

export const Alert: CUIComponent<AlertProps> = ({
  className,
  closeButtonText,
  onRequestClose,
  type,
  title,
  message,
  testId = "Alert",
  ariaLive = "off",
  shouldSetFocusOnMount,
}) => {
  const icon = getIconForType(type);
  const colorClassNames = getColorsForType(type);
  const [alertRef, closeButtonRef] = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLButtonElement>(null),
  ];

  useEffect(() => {
    if (!shouldSetFocusOnMount) return;

    if (onRequestClose) {
      closeButtonRef.current?.focus();
      return;
    }

    alertRef.current?.focus();
  }, [shouldSetFocusOnMount, onRequestClose, closeButtonRef, alertRef]);

  return (
    <div
      data-testid={testId}
      className={clsx(
        "flex",
        "flex-row",
        "items-start",
        "p-4",
        "border-solid",
        "border-t-4",
        "relative",
        ...colorClassNames,
        ...FOCUS_RING_CLASS_NAMES,
        className
      )}
      aria-live={ariaLive}
      ref={alertRef}
      tabIndex={-1}
    >
      <div>{icon}</div>
      <div className={clsx(onRequestClose && "pr-6")}>
        <Box
          element="div"
          textVariant={TextVariant.LgBoldShort}
          className="mb-1"
        >
          {title}
        </Box>
        {message && (
          <Box element="div" textVariant={TextVariant.LgRegular}>
            {message}
          </Box>
        )}
      </div>
      {onRequestClose && (
        <button
          type="button"
          className={clsx(
            "absolute right-2 top-2 p-0",
            "text-gray-700 active:text-gray-600",
            ...FOCUS_RING_CLASS_NAMES
          )}
          onClick={() => onRequestClose()}
          data-testid={`${testId}__DismissBtn`}
          ref={closeButtonRef}
        >
          <>
            <Icon variant={IconVariant.X} size={SpacingVariant.S24} />
            <ScreenReaderOnly element="span">
              {closeButtonText}
            </ScreenReaderOnly>
          </>
        </button>
      )}
    </div>
  );
};

const alertMessageBoldText: TextVariant = TextVariant.LgBoldShort;

export const AlertMessageBold = (
  props: Omit<BoxProps<"strong">, "element">
) => {
  return <Box element="strong" textVariant={alertMessageBoldText} {...props} />;
};

export const AlertMessageLink = (props: Omit<BoxProps<"a">, "element">) => {
  const { className, ...rest } = props;
  return (
    <Box
      className={clsx(linkClassNamesCommonNoColor, className)}
      display={DisplayVariant.Inline}
      element="a"
      textVariant={alertMessageBoldText}
      role="alertdialog"
      {...rest}
    />
  );
};
