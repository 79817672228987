import { FOCUS_CLASS_NAMES } from "@chp/curative_ui";
import clsx from "clsx";

import {
  PHARMACY_PHONE_NUMBERS_BY_NPI,
  PHARMACY_PHONE_NUMBERS_MNEMONIC_AND_DIGITS_BY_NPI,
} from "../../constants";

export enum PharmacyPhoneNumberLinkVariant {
  SIMPLE,
  FULL,
}

export type PharmacyPhoneNumberLinkProps = {
  className?: string;
  pharmacyNpi: string;
  variant?: PharmacyPhoneNumberLinkVariant;
};

/**
 * clickable phone number link
 */
export const PharmacyPhoneNumberLink = ({
  className,
  pharmacyNpi,
  variant = PharmacyPhoneNumberLinkVariant.SIMPLE,
}: PharmacyPhoneNumberLinkProps) => {
  // @ts-ignore
  const phoneNumber = PHARMACY_PHONE_NUMBERS_BY_NPI[pharmacyNpi];
  if (!phoneNumber) {
    console.error(`No phone number found for pharmacy NPI ${pharmacyNpi}`);
    return null;
  }

  // The phone number to call for pharmacy in a format suitable for the href attribute.
  const phoneNumberHref = `tel:${phoneNumber}`;

  let content;
  switch (variant) {
    case PharmacyPhoneNumberLinkVariant.SIMPLE:
      content = phoneNumber;
      break;
    case PharmacyPhoneNumberLinkVariant.FULL:
      // @ts-ignore
      content = PHARMACY_PHONE_NUMBERS_MNEMONIC_AND_DIGITS_BY_NPI[pharmacyNpi];
      if (!content) {
        console.error(
          `No mnemonic and digits phone number found for pharmacy NPI ${pharmacyNpi}`
        );
        return null;
      }
      break;
  }

  return (
    <a
      className={clsx(FOCUS_CLASS_NAMES, "!underline", className)}
      href={phoneNumberHref}
    >
      {content}
    </a>
  );
};
