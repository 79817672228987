/**
 * @param {string} dsn
 * @param {string} environment
 * @param {object} overrides
 */
const createSentryConfig = (dsn, environment, overrides = {}) => {
  const sentryConfig = (() => {
    // For legacy reasons (Sentry previously defaulting to grouping errors by
    // NODE_ENV), we use "production", "development", and "preprod" as our
    // Sentry environments
    // TODO: Revisit sampleRates for production and preprod when we get a
    // grip of our budget and start making use of performance metrics
    switch (environment) {
      case "prod":
        return { environment: "production", sampleRate: 0, enabled: true };
      case "preprod":
        return { environment: "preprod", sampleRate: 0, enabled: true };
      case "development":
        return { environment: "development", sampleRate: 0, enabled: false };
      default:
        console.warn(
          `Missing or unrecognized Sentry Environment: ${environment}. Sentry error reporting will be disabled.`
        );
        return { environment: "development", sampleRate: 0, enabled: false };
    }
  })();

  return {
    dsn,
    environment: sentryConfig.environment,
    tracesSampleRate: sentryConfig.sampleRate,
    ignoreErrors: [
      "ResizeObserver loop completed with undelivered notifications",
      "Cancel rendering route",
      // Some browsers just don't have webGL enabled, which may cause issues rendering the map
      "Failed to initialize WebGL",
      // Network errors
      "Unexpected error from Auth0 getTokenSilently: Timeout",
      "LaunchDarklyFlagFetchError",
      "Failed to fetch",
      // This is likely the result of someone trying to access a cached page during a deploy
      /Loading CSS chunk \d+ failed.*/,
      // Safari webkit
      /.*@webkit-masked-url.*/,
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ],
    ...overrides,
  };
};

module.exports = { createSentryConfig };
