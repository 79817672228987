import { ActionButton, IconVariant } from "@chp/curative_ui";
import { ArrayFieldTemplateProps } from "@rjsf/utils";
import clsx from "clsx";

const ArrayFieldTemplate: React.FC<ArrayFieldTemplateProps> = ({
  title,
  className,
  items,
  canAdd,
  onAddClick,
}: ArrayFieldTemplateProps) => {
  return (
    <div className={className}>
      <p className="font-medium">{title}</p>
      {items &&
        items.map((element) => (
          <div
            key={element.key}
            className={clsx(element.className, "flex flex-col")}
          >
            <div>{element.children}</div>
            <div className="flex flex-row justify-end">
              {element.hasMoveUp && (
                <ActionButton
                  iconVariant={IconVariant.ARROW_SMALL_UP}
                  onClick={element.onReorderClick(
                    element.index,
                    element.index - 1
                  )}
                  text="Up"
                />
              )}
              {element.hasMoveDown && (
                <ActionButton
                  iconVariant={IconVariant.ARROW_SMALL_DOWN}
                  onClick={element.onReorderClick(
                    element.index,
                    element.index + 1
                  )}
                  text="Down"
                />
              )}
              <ActionButton
                iconVariant={IconVariant.TRASH}
                onClick={element.onDropIndexClick(element.index)}
                text="Delete"
              />
            </div>
          </div>
        ))}

      {canAdd && (
        <div className="row">
          <p className="col-xs-3 col-xs-offset-9 array-item-add text-right">
            <ActionButton
              iconVariant={IconVariant.PLUS}
              onClick={onAddClick}
              text="Add"
            />
          </p>
        </div>
      )}
    </div>
  );
};

export default ArrayFieldTemplate;
