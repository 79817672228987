import clsx from "clsx";
import { useRef } from "react";

import { TEXT_CLASS_NAMES } from "../constants";
import { CUIComponent, CUIComponentProps, TextVariant } from "../types";

export enum RadioVariant {
  Normal,
  NormalColumn,
  Control,
  LabelButton,
  Control2,
}

export type RadioProps = CUIComponentProps<{
  id: string;
  value: string;
  labelTitle?: string;
  label: string | null;
  name: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  hasError?: boolean;
  variant?: RadioVariant;
  testId?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onRequestToggle: (key: string) => void;
}>;

const RADIO_BASE_CLASS_NAMES = [
  "inline-flex",
  "box-border",
  "items-center",
  "mr-10",
  "my-2",
  ...TEXT_CLASS_NAMES[TextVariant.SmRegularTall],
];

const RADIO_VARIANT_CLASS_NAMES: Record<RadioVariant, string[] | null> = {
  [RadioVariant.Normal]: RADIO_BASE_CLASS_NAMES,
  [RadioVariant.NormalColumn]: RADIO_BASE_CLASS_NAMES,
  [RadioVariant.Control]: [
    ...RADIO_BASE_CLASS_NAMES,
    "w-full",
    "p-6",
    "rounded",
    "border-0",
    "outline-none",
    "focus-within:ring-focused-default",
    "focus-within:ring-4",
    "focus-within:ring-offset-4",
    ...TEXT_CLASS_NAMES[TextVariant.LgRegular],
  ],

  [RadioVariant.Control2]: null,

  [RadioVariant.LabelButton]: [
    ...RADIO_BASE_CLASS_NAMES,
    "active:bg-foundation-primary",
    "active:text-textColor-onBg-primary",
    "border-borderColor-control",
    "border",
    "justify-center",
    "p-4",
    "rounded",
    "text-center",
    "text-textColor-highlight-default",
    "w-full",
    "focus-within:outline-none",
    "focus-within:ring-4",
    "focus-within:ring-focused-default",
    "focus-within:ring-offset-4",
    "focus:border-focused-default",
    "hover:bg-surface-highlight",
    ...TEXT_CLASS_NAMES[TextVariant.LgBoldShort],
  ],
};

const getRadioVariantClassName = (
  variant: RadioVariant,
  isChecked: boolean
) => {
  let output = RADIO_VARIANT_CLASS_NAMES[variant];

  if (variant === RadioVariant.Control2) {
    output = [
      "flex",
      "box-border",
      "items-center",
      "mr-10",
      "my-2",
      ...TEXT_CLASS_NAMES[TextVariant.SmRegularTall],
      "pl-1 pr-10 py-2.5",
      "rounded",
      isChecked ? "border border-borderColor-control" : "border-none",
      "outline-none",
      "focus-within:ring-focused-default",
      "focus-within:ring-2",
      "focus-within:ring-offset-2",
      "hover:bg-surface-highlight",
    ];
  }

  return output;
};

const RADIO_INPUT_BASE_CLASS_NAMES = [
  "text-textColor-highlight-default",
  "border-borderColor-control",
  "focus:ring-focused-default",
  "focus:ring-offset-2",
  "w-6",
  "h-6",
  "mx-1",
  "disabled:border-borderColor-default",
  "disabled:bg-surface-disabled",
];

const RADIO_INPUT_VARIANT_CLASS_NAMES: Record<RadioVariant, string[] | null> = {
  [RadioVariant.Normal]: [
    ...RADIO_INPUT_BASE_CLASS_NAMES,
    "duration-200",
    "ease-in-out",
  ],
  [RadioVariant.NormalColumn]: [
    ...RADIO_INPUT_BASE_CLASS_NAMES,
    "duration-200",
    "ease-in-out",
  ],
  [RadioVariant.Control]: [
    ...RADIO_INPUT_BASE_CLASS_NAMES,
    "focus:ring-0",
    "focus:ring-offset-0",
  ],
  // This one is a bit different from the rest so we don't take the base class names and define everything we need to fully control it - will be handled outside here
  [RadioVariant.Control2]: null,
  [RadioVariant.LabelButton]: [
    "opacity-0",
    "fixed",
    "w-0",
    "h-0",
    "border-none",
    "bg-none",
    "focus:ring-0",
    "focus:ring-offset-0",
    "focus:ring-transparent",
  ],
};

const getRadioInputVariantClassName = (
  variant: RadioVariant,
  isChecked: boolean,
  isDisabled: boolean,
  hasError: boolean
) => {
  let output = RADIO_INPUT_VARIANT_CLASS_NAMES[variant];

  if (variant === RadioVariant.Control2) {
    output = [
      isChecked
        ? "text-textColor-control border-borderColor-control"
        : "text-textColor-subdued border-borderColor-disabled",
      "border-2",
      "focus:ring-focused-default",
      "focus:ring-offset-2",
      "w-4",
      "h-4",
      "mx-1",
      "disabled:border-borderColor-default",
      "disabled:bg-surface-disabled",
      "focus:ring-0",
      "focus:ring-offset-0",
    ];
  }

  return clsx(
    output,
    !isDisabled && hasError
      ? isChecked
        ? "border-foundation-critical text-textColor-critical"
        : "border-foundation-critical bg-surface-critical"
      : ""
  );
};

const getControlClassNames = (
  variant: RadioVariant,
  isDisabled: boolean,
  hasError: boolean
) => {
  if (variant === RadioVariant.Control) {
    return clsx(
      hasError ? "ring-foundation-critical" : "ring-borderColor-control",
      isDisabled ? "bg-surface-disabled" : "bg-surface-highlight ring-1"
    );
  } else if (variant === RadioVariant.Control2) {
    return clsx(
      hasError ? "ring-foundation-critical" : "ring-borderColor-disabled",
      isDisabled ? "bg-surface-disabled" : "bg-background-default ring-1"
    );
  }

  return "";
};

export const Radio: CUIComponent<RadioProps> = ({
  id,
  labelTitle,
  label,
  value, // the 'value' attribute of the radio input, unique for each option
  name, // the 'name' attribute of the radio inputs, shared amongst radio inputs in the same group
  className,
  isChecked,
  isDisabled,
  hasError,
  variant = RadioVariant.Normal,
  testId = "Radio",
  onBlur,
  onRequestToggle,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const isControlVariant =
    variant === RadioVariant.Control || variant === RadioVariant.Control2;
  const isLabelButtonVariant = variant === RadioVariant.LabelButton;

  let variantClassNames = "";

  if (isControlVariant) {
    variantClassNames = getControlClassNames(
      variant,
      isDisabled || false,
      hasError || false
    );
  } else if (isLabelButtonVariant) {
    variantClassNames = isChecked
      ? clsx(
          "bg-foundation-primary text-textColor-onBg-primary",
          "border-borderColor-selected hover:bg-foundation-primary",
          "hover:text-textColor-onBg-primary"
        )
      : "";
  }

  const labelClassNames = clsx(
    getRadioVariantClassName(variant, Boolean(isChecked)),
    variantClassNames,
    isDisabled
      ? "cursor-not-allowed text-textColor-subdued"
      : "cursor-pointer hover:text-textColor-highlight-default",
    className
  );

  const inputClassNames = getRadioInputVariantClassName(
    variant,
    Boolean(isChecked),
    Boolean(isDisabled),
    Boolean(hasError)
  );

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <label
      // we do this because we want to focus the element when the label is clicked, it helps us pick up that focus with focus-within and show the border around controls.
      // On Safari, clicking label doesn't give focus to the input and putting a tabIndex on this label makes it less acessible.
      onClick={() => {
        inputRef?.current?.focus();
      }}
      data-testid={testId}
      className={labelClassNames}
    >
      <input
        ref={inputRef}
        data-testid={`${testId}__Input`}
        id={id}
        name={name}
        type="radio"
        checked={isChecked}
        disabled={isDisabled}
        value={value}
        onBlur={onBlur}
        onChange={() => {
          onRequestToggle(value);
        }}
        className={inputClassNames}
      />
      {isLabelButtonVariant ? (
        label
      ) : (
        <span data-testid={`${testId}__Label`} className={clsx("ml-2 block")}>
          {labelTitle && (
            <span
              className={clsx(TEXT_CLASS_NAMES[TextVariant.MdBold], "block")}
            >
              {labelTitle}
            </span>
          )}

          <span
            className={clsx(
              labelTitle && `my-1`,
              variant === RadioVariant.Control2 &&
                clsx(
                  "text-lg-short font-semibold",
                  isChecked
                    ? "text-textColor-highlight-default"
                    : "text-textColor-subdued"
                )
            )}
          >
            {label}
          </span>
        </span>
      )}
    </label>
  );
};
