export enum EmployerPortalMixpanelUiIdentifierName {
  INDEX_PAGE = "INDEX_PAGE",
  MEMBER_INFO_PAGE = "MEMBER_INFO_PAGE",
  MEMBER_UPDATE_FORM = "MEMBER_UPDATE_FORM",
  ENROLL_POLICYHOLDER_FORM = "ENROLL_POLICYHOLDER_FORM",
  ENROLL_DEPENDENT_FORM = "ENROLL_DEPENDENT_FORM",
  TERMINATE_COVERAGE_FORM = "TERMINATE_COVERAGE_FORM",
  LOCATION_QUERY_FIELD = "LOCATION_QUERY_FIELD",
}

export enum EmployerPortalMixpanelEventName {
  DOWNLOAD_ID_CARD = "DOWNLOAD_ID_CARD",
  PRINT_ID_CARD = "PRINT_ID_CARD",
  FIELD_POPULATED = "FIELD_POPULATED",
  SSN_ERROR_MATCHES_DB = "SSN_ERROR_MATCHES_DB",
  SSN_ERROR_MATCHES_DEPENDENT = "SSN_ERROR_MATCHES_DEPENDENT",
  SSN_ERROR_MATCHES_POLICYHOLDER = "SSN_ERROR_MATCHES_POLICYHOLDER",
}
