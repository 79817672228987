import { format } from "date-fns";
import { IntlFormatters } from "react-intl";
import * as Yup from "yup";

import { DATE_INPUT_FORMAT, PlainDate } from "../dateTime";
import { dateInputSchema } from "./dateInput";

export const coverageStartDateSchema = ({
  effectiveDateRange,
  formatMessage,
}: {
  effectiveDateRange: string[] | null;
  formatMessage: IntlFormatters["formatMessage"];
}): Yup.DateSchema => {
  if (!effectiveDateRange?.length) {
    // Returns a blank Yup date schema
    return Yup.date();
  }

  // These both should be defined as we check for its length above, but TS infers them
  // as possibly undefined due to how arrays work. Thus, just typing them to '' in order
  // for TS to be happy.
  const firstAvailableEffectiveDate = effectiveDateRange[0] || "";
  const lastAvailableEffectiveDate = effectiveDateRange.slice(-1)[0] || "";

  const startDateInPlainDate = PlainDate.fromIsoString(
    firstAvailableEffectiveDate
  ).asJsDate();

  const formattedEffectiveStartDate = format(
    startDateInPlainDate,
    DATE_INPUT_FORMAT
  );

  const coverageEndDateInPlainDate = PlainDate.fromIsoString(
    lastAvailableEffectiveDate
  ).asJsDate();

  const formattedCoverageEndDate = format(
    new Date(coverageEndDateInPlainDate),
    DATE_INPUT_FORMAT
  );

  return dateInputSchema({
    message: formatMessage({
      defaultMessage: "The effective date you entered is invalid.",
      id: "Dtb1Uv",
    }),
  })
    .min(
      firstAvailableEffectiveDate,
      formatMessage(
        {
          defaultMessage: "Effective date must be on or after {fromDate}",
          id: "WPmKFm",
        },
        { fromDate: formattedEffectiveStartDate }
      )
    )
    .max(
      lastAvailableEffectiveDate,
      formatMessage(
        {
          defaultMessage: "Effective date must be on or before {toDate}",
          id: "RN6k7X",
        },
        {
          toDate: formattedCoverageEndDate,
        }
      )
    )
    .required(
      formatMessage({
        defaultMessage: "Effective date is required.",
        id: "HMKfbO",
      })
    );
};
