import type {
  MinAgeErrorMessageFormatter,
  MinimumAge,
} from "@chp/shared/utils/validation/dateOfBirth";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { DeepPartial, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import type { MemberVerificationFormValues } from "../types";
import { getMemberVerificationFormValidationSchema } from "../utils";

export type UseMemberVerificationFormOptions<TStrict extends boolean> = {
  defaultValues?: DeepPartial<MemberVerificationFormValues<TStrict>>;
  formatMinAgeErrorMessage?: MinAgeErrorMessageFormatter;
  isStrict: TStrict;
  minAge: MinimumAge;
};

export const useMemberVerificationForm = <TStrict extends boolean>({
  defaultValues,
  formatMinAgeErrorMessage,
  isStrict,
  minAge,
}: UseMemberVerificationFormOptions<TStrict>) => {
  const { formatMessage } = useIntl();

  const validationSchema = useMemo(
    () =>
      getMemberVerificationFormValidationSchema({
        formatMessage,
        formatMinAgeErrorMessage,
        minAge,
        isStrict,
      }),
    [isStrict, formatMessage, formatMinAgeErrorMessage, minAge]
  );

  return useForm<MemberVerificationFormValues<TStrict>>({
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    criteriaMode: "all",
    defaultValues,
  });
};
