import clsx from "clsx";
import { useRef } from "react";

import { SpacingVariant } from "../../constants";
import {
  Box,
  formFieldErrorId,
  FormFieldErrorMessage,
  Icon,
  IconVariant,
  textColorsCommon,
  useHasError,
} from "..";
import { TEXT_CLASS_NAMES } from "../constants";
import { CUIComponent, CUIComponentProps, TextVariant } from "../types";

export enum CheckboxVariants {
  Normal,
  NormalColumn,
  Control,
  ControlShort,
}

export type CheckboxProps = CUIComponentProps<{
  labelClassName?: string;
  inputClassName?: string;
  errorMessage?: string;
  id: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  label: string | React.ReactElement;
  labelTitle?: string;
  name: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onRequestToggle: (value: string) => void;
  removeMargin?: boolean;
  value: string;
  variant?: CheckboxVariants;
  boxColor?: string;
}>;

export const Checkbox: CUIComponent<CheckboxProps> = ({
  labelClassName,
  inputClassName,
  className,
  errorMessage,
  id,
  isChecked,
  isDisabled,
  label,
  labelTitle,
  name, // the 'name' attribute of the checkbox inputs, shared amongst checkbox inputs in the same group
  onBlur,
  onRequestToggle,
  removeMargin,
  testId = "Checkbox",
  value, // the 'value' attribute of the checkbox input, unique for each option
  variant = CheckboxVariants.Normal,
  boxColor,
}) => {
  const hasError = useHasError({ errorMessage });
  const errorId = formFieldErrorId({ hasError: true, id });
  const inputRef = useRef<HTMLInputElement>(null);
  const isNormalControl = variant === CheckboxVariants.Control;
  const isShortControl = variant === CheckboxVariants.ControlShort;
  const isControl = isNormalControl || isShortControl;

  const controlsStyling =
    isControl &&
    clsx(
      "w-full rounded border-0 outline-none",
      isNormalControl ? "p-6" : "p-2.5",
      isShortControl &&
        clsx(
          "hover:bg-surface-highlight",
          "focus-within:ring-focused-default",
          "focus-within:ring-2",
          "focus-within:ring-offset-2",
          isChecked ? "border border-borderColor-control" : "border-none"
        ),
      !isDisabled
        ? hasError
          ? "ring-1 ring-foundation-critical"
          : isNormalControl
          ? "ring-1 ring-borderColor-control"
          : `ring-1 ${
              isChecked
                ? "ring-borderColor-control"
                : "ring-borderColor-subdued"
            }`
        : "",

      isDisabled
        ? "bg-surface-disabled"
        : isNormalControl
        ? "bg-surface-highlight"
        : "",

      !isDisabled &&
        "focus-within:ring-4 focus-within:ring-focused-default focus-within:ring-offset-4",

      `${isChecked || hasError ? "ring-1" : ""}`,

      TEXT_CLASS_NAMES[TextVariant.LgRegular]
    );

  return (
    <>
      <label
        // we do this because we want to focus the element when the label is clicked, it helps us pick up that focus with focus-within and show the border around controls. On Safari, clicking label doesn't give focus to the input and putting a tabIndex on this label makes it less acessible.
        onClick={() => {
          if (inputRef && inputRef.current) {
            inputRef.current.focus();
          }
        }}
        data-testid={testId}
        className={clsx(
          "box-border",
          "inline-flex",
          "items-center",
          !removeMargin && "my-2 mr-10",
          !isDisabled
            ? clsx(
                "cursor-pointer",
                isShortControl
                  ? `${
                      isChecked
                        ? "text-textColor-highlight-default"
                        : "text-textColor-subdued"
                    }`
                  : "text-textColor-default hover:text-textColor-highlight-default"
              )
            : "cursor-not-allowed text-textColor-subdued",
          TEXT_CLASS_NAMES[TextVariant.SmRegularTall],
          isControl && controlsStyling,
          className
        )}
      >
        <input
          data-testid={`${testId}__Input`}
          ref={inputRef}
          id={id}
          name={name}
          type="checkbox"
          checked={isChecked}
          disabled={isDisabled}
          value={value}
          onBlur={onBlur}
          onChange={() => {
            onRequestToggle(value);
          }}
          data-haserror={hasError}
          aria-describedby={errorId}
          className={clsx(
            !isDisabled &&
              !hasError &&
              "border-borderColor-control text-textColor-highlight-default",
            isDisabled && "border-borderColor-default bg-surface-disabled",

            !isDisabled && hasError
              ? isChecked
                ? "border-foundation-critical text-textColor-critical"
                : "border-foundation-critical bg-surface-critical"
              : "",

            !isControl
              ? "focus:ring-focused-default focus:ring-offset-2"
              : "focus:ring-0 focus:ring-offset-0",

            isShortControl ? "size-5" : "size-6",
            "mx-1",

            "rounded-sm",

            !isControl && "duration-200 ease-in-out",

            inputClassName
          )}
          style={{
            backgroundColor: boxColor ? boxColor : "",
          }}
        />

        <span
          data-testid={testId ? `${testId}__Label` : "Checkbox__Label"}
          className={clsx("ml-2 block")}
        >
          {labelTitle && (
            <span
              className={clsx(TEXT_CLASS_NAMES[TextVariant.MdBold], "block")}
            >
              {labelTitle}
            </span>
          )}

          <span className={clsx(labelTitle && `my-1`, labelClassName)}>
            {label}
          </span>
        </span>
      </label>
      {errorId && hasError && errorMessage && (
        <FormFieldErrorMessage
          errorMessage={errorMessage}
          testId={`${testId}__ErrorMessage`}
          id={errorId}
        />
      )}
    </>
  );
};

export type CheckboxDecorativeProps = CUIComponentProps<{
  isChecked?: boolean;
}>;

/**
 * Decorative Checkbox that is non-interactive & hidden from screen readers
 */
export const CheckboxDecorative: CUIComponent<CheckboxDecorativeProps> = ({
  className,
  isChecked,
  testId = "CheckboxDecorative",
}) => {
  return (
    <Box
      aria-hidden={true}
      className={clsx(
        isChecked ? "bg-borderColor-control" : "bg-background-default",
        "border",
        "border-borderColor-control",
        "duration-200",
        "ease-in-out",
        "h-6",
        "p-px",
        "rounded-sm",
        textColorsCommon.onBgPrimary,
        "w-6",
        className
      )}
      element="div"
      testId={testId}
    >
      {isChecked && (
        <Icon size={SpacingVariant.S20} variant={IconVariant.CHECK} />
      )}
    </Box>
  );
};
