import { useCallback } from "react";
import { useIntl } from "react-intl";

import { formatProcedureCodeTypeaheadOption } from "../../utils";
import { useTypeaheadOptions__SearchProcedureCodesLazyQuery } from "../memberPortalApi-TypeaheadOptions.generated";

export const useFetchCareCostProcedureCodeOptions = () => {
  const { formatMessage } = useIntl();
  const [
    searchProcedureCodes,
    { loading: areCareCostProcedureCodeOptionsLoading },
  ] = useTypeaheadOptions__SearchProcedureCodesLazyQuery();

  const fetchCareCostProcedureCodeOptions = useCallback(
    async (queryString: string) => {
      const response = await searchProcedureCodes({
        variables: { search: queryString, onlyCareCostProcedures: true },
      });

      if (response.error || !response.data) {
        throw new Error(
          formatMessage({
            defaultMessage: "There was an error searching for procedures.",
            id: "490oDV",
          })
        );
      }

      const results = response.data.searchProcedureCodes;
      return results.map((item) => {
        const { description, code, plainLanguageDescription } = item;
        if (!description || !code) {
          throw new Error(
            formatMessage({
              defaultMessage: "There was an error searching for procedures.",
              id: "490oDV",
            })
          );
        }

        // TODO: Add i18n support
        return formatProcedureCodeTypeaheadOption({
          label: description,
          value: {
            code: code,
            description: plainLanguageDescription || "",
          },
        });
      });
    },
    [searchProcedureCodes, formatMessage]
  );

  return {
    fetchCareCostProcedureCodeOptions,
    areCareCostProcedureCodeOptionsLoading,
  };
};
