import { TypeaheadOption } from "@chp/curative_ui";
import { MapCoordinate } from "@chp/shared/components/LocationSearch";

import { LocationSearchResult } from "../../components";
import {
  CoveragePackage,
  EmployerCoverageInfo,
  EmployerCoverageStatus,
  FundingType,
  PlanType,
} from "../../generated/memberPortalApi.graphql";

export const latLongValid: { latitude: number; longitude: number } = {
  latitude: 42.3068,
  longitude: -83.7059,
};

const coveragePeriodStartDate = "2023-01-01";
const coveragePeriodEndDate = "2030-01-01";

export const mockCoverageInfo: EmployerCoverageInfo = {
  coveragePeriodStartDate,
  coveragePeriodEndDate,
  coveragePeriodOptions: [
    {
      endDate: "2030-01-01",
      startDate: "2023-01-01",
    },
  ],
  effectiveDateRange: [coveragePeriodStartDate, coveragePeriodEndDate],
  coverageStatus: EmployerCoverageStatus.Active,
  planTypes: [CoveragePackage.Ppo],
  planYears: [2023],
  employerState: "TX",
  plans: [
    {
      externalId: "PPO-23",
      description: null,
      fundingType: FundingType.FullyFunded,
      coveragePackage: CoveragePackage.Ppo,
      hasHighDeductible: true,
      planYear: 2023,
      planType: PlanType.Oop,
      state: null,
    },
  ],
};

export const mockCoordinate: MapCoordinate = {
  latitude: 12,
  longitude: 34,
};

export const mockLocationQuery: TypeaheadOption<LocationSearchResult> = {
  label: "Some location",
  value: {
    ...mockCoordinate,
    name: "Some name",
    state: "TX",
    city: "Austin",
    zipCode: "12345",
    street: "123 ABC Blvd",
  },
};

export const mockIntersectionObserver = (): void => {
  // IntersectionObserver isn't available in test environment
  const mockIntersectionObserver = jest.fn();
  mockIntersectionObserver.mockReturnValue({
    observe: () => null,
    unobserve: () => null,
    disconnect: () => null,
  });
  window.IntersectionObserver = mockIntersectionObserver;
};

export const mockResizeObserver = () => {
  const mockResizeObserver = jest.fn().mockImplementation(() => ({
    observe: () => null,
    unobserve: () => null,
    disconnect: () => null,
  }));

  window.ResizeObserver = mockResizeObserver;
};

export const mockBothObservers = () => {
  mockIntersectionObserver();
  mockResizeObserver();
};

export const mockSessionStorage = () => {
  let storage: Record<string, string | null> = {};

  const value = {
    getItem: (key: string) => {
      return storage[key] || null;
    },
    setItem: (key: string, value: string) => {
      storage[key] = value;
    },
    removeItem: (key: string) => {
      delete storage[key];
    },
    clear: () => {
      storage = {};
    },
  };
  return Object.defineProperty(window, "sessionStorage", {
    value,
  });
};

export const MOCK_MEMBER_CARD_FRONT_URL =
  "https://static.curative.com/mocks/mock-generated-member-card-front.png";
export const MOCK_MEMBER_CARD_BACK_URL =
  "https://static.curative.com/mocks/mock-generated-member-card-back.png";
export const MOCK_MEMBER_CARD_BOTH_SIDES_URL =
  "https://static.curative.com/mocks/mock-generated-member-card-both-sides.png";
