import { useCallback } from "react";
import { useIntl } from "react-intl";

import { Practitioner } from "../../generated/memberPortalApi.graphql";
import { formatStreetAddress } from "../../utils/formatters";
import { useTypeaheadOptions__SearchHePractitionersLazyQuery } from "../memberPortalApi-TypeaheadOptions.generated";

export type HEPractitionerSearchResult = Pick<
  Practitioner,
  "firstName" | "lastName" | "nationalProviderId" | "practitionerId"
>;

export const useFetchHEPractitionerOptions = () => {
  const { formatMessage } = useIntl();
  const [searchHEPractitioners, { loading: areHEPractitionerOptionsLoading }] =
    useTypeaheadOptions__SearchHePractitionersLazyQuery();

  const fetchHEPractitionerOptions = useCallback(
    async (queryString: string) => {
      const response = await searchHEPractitioners({
        variables: {
          search: queryString,
        },
      });

      if (response.error || !response.data) {
        throw new Error(
          formatMessage({
            defaultMessage: "There was an error searching for providers.",
            id: "Kc9Rfi",
          })
        );
      }

      const providerNoNameFallback = formatMessage({
        defaultMessage: "(no name)",
        id: "1xSCmt",
      });

      const providers = response.data.searchHEPractitioners;
      return providers.map((provider) => {
        const formattedProviderName =
          [provider.firstName, provider.lastName].filter(Boolean).join(" ") ||
          providerNoNameFallback;

        return {
          label: formattedProviderName,
          value: provider,
          label2: provider.nationalProviderId
            ? `NPI: ${provider.nationalProviderId}`
            : "",
          label3: formatStreetAddress({
            street: provider.address?.addressLine,
            street2: provider.address?.addressLine2,
            city: provider.address?.cityName,
            state: provider.address?.stateCode,
            zipCode: provider.address?.zipCode,
          }),
        };
      });
    },
    [searchHEPractitioners, formatMessage]
  );

  return {
    fetchHEPractitionerOptions,
    areHEPractitionerOptionsLoading,
  };
};
