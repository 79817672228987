import { GetServerSidePropsContext } from "next/types";

import { validateLatitude, validateLongitude } from "../../utils";

enum GeolocationHeader {
  LATITUDE = "LATITUDE",
  LONGITUDE = "LONGITUDE",
}

export const geolocationHeaderKeys: Record<GeolocationHeader, string> = {
  [GeolocationHeader.LATITUDE]: "cloudfront-viewer-latitude",
  [GeolocationHeader.LONGITUDE]: "cloudfront-viewer-longitude",
};

/**
 * Get geolocation from CloudFront request headers
 * https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/using-cloudfront-headers.html#cloudfront-headers-viewer-location
 */
export class CloudFrontGeolocation {
  latitude?: number;
  longitude?: number;

  constructor(req: GetServerSidePropsContext["req"]) {
    // convert lat/long to numbers
    const latitudeHeader = req.headers[geolocationHeaderKeys.LATITUDE];
    this.Latitude = latitudeHeader;
    const longitudeHeader = req.headers[geolocationHeaderKeys.LONGITUDE];
    this.Longitude = longitudeHeader;
  }

  get coordinate():
    | {
        latitude: number;
        longitude: number;
      }
    | undefined {
    return this.latitude !== undefined && this.longitude !== undefined
      ? { latitude: this.latitude, longitude: this.longitude }
      : undefined;
  }

  set Latitude(value: string | string[] | undefined) {
    // update with value if it is a valid latitude, otherwise set to undefined
    if (value !== undefined) {
      const numValue = Number(value);
      try {
        validateLatitude(numValue);
        this.latitude = numValue;
        return;
      } catch (error) {
        if (error instanceof RangeError) {
          console.warn(error);
        }
      }
    }
    this.latitude = undefined;
  }

  set Longitude(value: string | string[] | undefined) {
    // update with value if it is a valid longitude, otherwise set to undefined
    if (value !== undefined) {
      const numValue = Number(value);
      try {
        validateLongitude(numValue);
        this.longitude = numValue;
        return;
      } catch (error) {
        if (error instanceof RangeError) {
          console.warn(error);
        }
      }
    }
    this.longitude = undefined;
  }
}
