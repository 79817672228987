import { LocaleCode } from "../../features";

export const getLocaleCodeFromCookie = ({
  cookie,
}: {
  cookie?: string;
}): LocaleCode | null => {
  // get cookie from param if present, or from document if present
  const cookieOption: string | null =
    cookie ?? (typeof document !== "undefined" ? document.cookie : null);
  if (cookieOption === null) return null;

  const langCookieValue = cookieOption
    .split("; ")
    .find((row) => row.startsWith("locale="))
    ?.split("=")?.[1];

  if (
    !langCookieValue ||
    !(Object.values(LocaleCode) as string[]).includes(langCookieValue)
  ) {
    return null;
  }

  return langCookieValue as LocaleCode;
};
