import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

export type SearchFreeTextState = {
  searchInputValue: string;
  setSearchInputValue: (_: string) => void;
};

export const useSearchFreeTextState = (
  initialValues?: Partial<Pick<SearchFreeTextState, "searchInputValue">>
): SearchFreeTextState => {
  const [searchInputValue, setSearchInputValue] = useState<string>(
    initialValues?.searchInputValue ?? ""
  );

  return {
    searchInputValue,
    setSearchInputValue,
  };
};

export type SearchFreeTextContextProviderProps =
  PropsWithChildren<SearchFreeTextState>;

export type SearchFreeTextContextValue = SearchFreeTextState;

export type SearchFreeTextContextResult = {
  Provider: FC<SearchFreeTextContextProviderProps>;
  useContext: () => SearchFreeTextContextValue;
};

const SearchFreeTextContext = createContext<SearchFreeTextContextValue | null>(
  null
);

export const useSearchFreeTextContext = () => {
  const context = useContext(SearchFreeTextContext);
  if (!context) {
    throw new Error(
      "useSearchFreeTextContext must be used within a SearchFreeTextProvider"
    );
  }
  return context;
};

export const createSearchFreeTextContext = (): SearchFreeTextContextResult => {
  const SearchFreeTextProvider: FC<SearchFreeTextContextProviderProps> = ({
    children,
    ...state
  }) => {
    return (
      <SearchFreeTextContext.Provider value={state}>
        {children}
      </SearchFreeTextContext.Provider>
    );
  };

  return {
    Provider: SearchFreeTextProvider,
    useContext: useSearchFreeTextContext,
  };
};
