import { SpacingVariant } from "../../constants";
import { Icon, IconVariant } from "../Icon/Icon";

export const IconOrSpacer = ({
  iconVariant,
  testId,
  isFloatingMenuItem,
}: {
  iconVariant?: IconVariant;
  testId?: string;
  isFloatingMenuItem?: boolean;
}) => {
  if (isFloatingMenuItem) return null;
  return iconVariant ? (
    <Icon
      variant={iconVariant}
      size={SpacingVariant.S24}
      data-testid={`${testId}__Icon`}
    />
  ) : (
    <div className="mr-3 h-6 w-6" />
  );
};
