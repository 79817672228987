const specialCharacters = [
  " ",
  "!",
  '"',
  "#",
  "\\$",
  "%",
  "&",
  "'",
  "\\(",
  "\\)",
  "\\*",
  "\\+",
  ",",
  "-",
  "\\.",
  "/",
  ":",
  ";",
  "<",
  "=",
  ">",
  "\\?",
  "@",
  "\\[",
  "\\\\",
  "\\]",
  "\\^",
  "_",
  "`",
  "{",
  "\\|",
  "}",
  "~",
].join("|");
export const containsSymbolRegex = new RegExp(specialCharacters);
export const containsNumberRegex = /^(?=.*?([0-9])).*$/;
export const containsUpperAndLowerCaseLetterRegex =
  /^(?=.*[A-Z])(?=.*[a-z]).*$/;
