import clsx from "clsx";

import { useArrayKeys } from "../../hooks";
import { Box } from "..";
import { CUIComponent, CUIComponentProps, TextVariant } from "../types";
import { TableBody, TableBodyRow } from "./TableBody";
import { TableHead } from "./TableHead";
import { RowControlData, TableContentType, TableKey } from "./utils";

/**
 * Props for Table
 *
 * Order of content types & keys & row cells should match. contentTypes[i] & keys[i] are for cell rows[n][i].
 */
export type TableProps = CUIComponentProps<{
  columnClassNames?: Array<string | undefined>;
  contentTypes: TableContentType[];
  // generate unique array key for each head cell
  headCellArrayKeyGenerator: (
    headCell: TableKey,
    index: number
  ) => string | number;
  keys: TableKey[];
  // generate unique array key for each body row
  rowArrayKeyGenerator: (row: TableBodyRow, index: number) => string | number;
  rows: TableBodyRow[];
  hasStripedRows?: boolean;
  clickableRowData?: RowControlData[];
}>;

export const Table: CUIComponent<TableProps> = (props) => {
  const {
    className,
    clickableRowData,
    columnClassNames,
    contentTypes,
    headCellArrayKeyGenerator,
    keys,
    rowArrayKeyGenerator,
    rows,
    hasStripedRows,
    testId = `Table`,
  } = props;

  const headCellArrayKeys = useArrayKeys({
    items: keys,
    keyGenerator: headCellArrayKeyGenerator,
  });

  const rowArrayKeys = useArrayKeys({
    items: rows,
    keyGenerator: rowArrayKeyGenerator,
  });

  const sharedProps = {
    columnClassNames,
    contentTypes,
    keys,
    testId,
  };

  const tableHeadProps = {
    ...sharedProps,
    headCellArrayKeys,
  };

  const tableBodyProps = {
    ...sharedProps,
    clickableRowData,
    descriptionListArrayKeys: headCellArrayKeys,
    rowArrayKeys,
    rows,
    hasStripedRows,
  };

  return (
    <Box
      className={clsx(
        "bg-background-default",
        "min-w-full",
        "text-textColor-default",
        className
      )}
      element="table"
      testId={testId}
      textVariant={TextVariant.MdRegularTall}
    >
      <TableHead {...tableHeadProps} />
      <TableBody {...tableBodyProps} />
    </Box>
  );
};
