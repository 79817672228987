import { Relationship } from "@chp/shared/generated/memberPortalApi.graphql";
import { PlainDate } from "@chp/shared/utils/dateTime";
import { addWeeks, isBefore } from "date-fns";
import { useEffect, useMemo, useState } from "react";

import { InsuranceCardMember } from "../../features";
import { checkCoverage } from "../../utils";
import {
  InsuranceCard__ActiveUser__MemberFragment,
  InsuranceCard__GetActiveUserQuery,
  useInsuranceCard__GetActiveUserQuery,
} from "./memberPortalApi-useInsuranceCardLogic.generated";

export type SelectedCardMember = InsuranceCard__ActiveUser__MemberFragment;

type MemberFragment = NonNullable<
  NonNullable<InsuranceCard__GetActiveUserQuery>["activeUser"]["member"]
>["subscriberGroup"]["dependents"][number];

type WithEnrollmentData<T> = T extends { __typename: "Member" }
  ? T & InsuranceCard__ActiveUser__MemberFragment
  : never;

// A type guard to check if a dependent is a full member, not a basic member
function isFullMemberFragment(
  member: MemberFragment
): member is WithEnrollmentData<MemberFragment> {
  return "__typename" in member && member["__typename"] === "Member";
}

export const useInsuranceCardLogic = () => {
  const [selectedMember, setSelectedMember] =
    useState<InsuranceCardMember | null>();

  const {
    data: insuranceCardData,
    loading: isInsuranceCardDataLoading,
    error: insuranceCardError,
  } = useInsuranceCard__GetActiveUserQuery();

  const member = insuranceCardData?.activeUser?.member;
  const displayedEnrollment = selectedMember?.displayedEnrollment;
  const shouldCheckForDependents =
    member?.relationship === Relationship.Self ||
    member?.relationship === Relationship.Spouse;

  const { hasExpiredCoverage, hasActiveCoverage } =
    checkCoverage(displayedEnrollment);

  const todayDate = PlainDate.now().asJsDate();
  const planStartDateString: string | undefined =
    displayedEnrollment?.planStartDate;

  const isLessThanTwoWeeksAfterCoverageStart =
    !!planStartDateString &&
    isBefore(
      todayDate,
      addWeeks(PlainDate.fromIsoString(planStartDateString).asJsDate(), 2)
    );

  useEffect(() => {
    if (isInsuranceCardDataLoading || !member) {
      return;
    }

    setSelectedMember(member);
  }, [isInsuranceCardDataLoading, member]);

  // Since the member can also be a dependent, we need to filter them out
  // so their name doesn't get shown in the dropdown twice
  const dependentsSelectOptions = useMemo(() => {
    if (!shouldCheckForDependents || !member?.subscriberGroup) {
      return [];
    }

    const filteredDependents = member.subscriberGroup.dependents
      .filter(isFullMemberFragment)
      .filter(({ externalId }) => externalId !== member.externalId);

    return [member, ...filteredDependents];
  }, [shouldCheckForDependents, member]);

  return {
    selectedMember,
    setSelectedMember,
    isInsuranceCardDataLoading,
    displayedEnrollment,
    insuranceCardError,
    dependentsSelectOptions,
    hasExpiredCoverage,
    hasActiveCoverage,
    isLessThanTwoWeeksAfterCoverageStart,
  };
};
