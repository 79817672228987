import { Radio } from "@chp/curative_ui";
import { FormattedModal } from "@chp/shared/components/FormattedModal";
import { FC, MutableRefObject, useCallback, useState } from "react";
import { useIntl } from "react-intl";

import type { MemberCardDownloadFileExtension } from "../types";
import { downloadMemberCard } from "../utils";

export type InsuranceCardDownloadModalProps = {
  isModalVisible: boolean;
  onRequestClose: () => void;
  cardContainerRef: MutableRefObject<HTMLElement | null>;
  cardDownloadName: string;
};

export const InsuranceCardDownloadModal: FC<
  InsuranceCardDownloadModalProps
> = ({
  isModalVisible,
  onRequestClose,
  cardContainerRef,
  cardDownloadName,
}) => {
  const { formatMessage } = useIntl();
  const [fileFormat, setFileFormat] =
    useState<MemberCardDownloadFileExtension>("jpeg");

  const handleClickDownloadButton = useCallback(async () => {
    await downloadMemberCard({
      cardContainerRef,
      cardDownloadName,
      fileFormat,
    });
    onRequestClose();
  }, [cardContainerRef, cardDownloadName, fileFormat, onRequestClose]);

  return (
    <FormattedModal
      isOpen={isModalVisible}
      onRequestClose={onRequestClose}
      title={formatMessage({
        defaultMessage: "Choose a file format to download",
        id: "/eGFeE",
      })}
      buttonText={formatMessage({ defaultMessage: "Download", id: "5q3qC0" })}
      onButtonClick={handleClickDownloadButton}
    >
      <Radio
        id="jpeg"
        label={formatMessage({ defaultMessage: "JPEG (.jpg)", id: "nwsbce" })}
        value="jpeg"
        name="fileFormat"
        onRequestToggle={() => setFileFormat("jpeg")}
        isChecked={fileFormat === "jpeg"}
      />
      <Radio
        id="png"
        label={formatMessage({ defaultMessage: "PNG (.png)", id: "br4+YH" })}
        value="png"
        name="fileFormat"
        onRequestToggle={() => setFileFormat("png")}
        isChecked={fileFormat === "png"}
      />
    </FormattedModal>
  );
};
