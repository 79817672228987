export enum MemberPortalMixpanelUiIdentifierName {
  CARE_COST_SEARCH_BY_ZIP = "care-cost-search-by-zip",
  CARE_COST_SEARCH_BY_PROVIDER = "care-cost-search-by-provider",
  NAVBAR_PHARMACY_LINK = "navbar-pharmacy-link",
  HELP_WIDGET = "help-widget",
  MEMBER_CARD = "MEMBER_CARD",
  PROFILE_PAGE = "PROFILE_PAGE",
  SIDE_NAV_LANGUAGE_SELECTOR = "SIDE_NAV_LANGUAGE_SELECTOR",

  // Baseline
  SCHEDULE_BASELINE_CTA = "schedule-baseline-cta",
  CANCEL_BASELINE = "cancel-baseline",
  RESCHEDULE_BASELINE = "reschedule-baseline",
  BASELINE_START_PAGE = "baseline.start-page",
  BASELINE_CONFIRM_GENERAL_INFO = "baseline.confirm-general-info",
  BASELINE_EDIT_GENERAL_INFO = "baseline.edit-general-info",
  BASELINE_PERSONAL_DETAILS = "baseline.personal-details",
  BASELINE_LEGAL_AGREEMENTS = "baseline.legal-agreements",
  BASELINE_QUESTIONNAIRE_CONTINUE = "baseline.questionnaire-continue",
  BASELINE_QUESTIONNAIRE_BACK = "baseline.questionnaire-back",
  BASELINE_SELECT_APPOINTMENT = "baseline.select-appointment",
  BASELINE_CONFIRM_APPOINTMENT = "baseline.confirm-appointment",
  BASELINE_SUCCESS = "baseline.success",
  BASELINE_SCHEDULE_APPOINTMENT = "baseline.schedule-appointment",
  BASELINE_VIRTUAL_CARE = "baseline.virtual-care",
  BASLINE_OPTIONAL_LAB_TESTING = "baseline.optional-lab-testing",
  BASELINE_LOW_SLOTS = "baseline.low-slots",
  BASELINE_NO_SLOTS = "baseline.no-slots",
  BASELINE_OFFERED_PRIMARY_CARE = "baseline.offered-primary-care",
  BASELINE_ACCEPT_PRIMARY_CARE = "baseline.accept-primary-care",
  BASELINE_REJECT_PRIMARY_CARE = "baseline.reject-primary-care",
  BASELINE_REJECT_PRIMARY_CARE_AGREEMENTS = "baseline.reject-primary-care-agreements",
  BASELINE_REJECT_PRIMARY_CARE_AVAILABAILITY = "baseline.reject-primary-care-availability",
  BASELINE_BOOK_PRIMARY_CARE_BLV = "baseline.book-primary-care-blv",

  // Onboarding
  ONBOARDING_VERIFY_IDENTITY = "onboarding.verify-identity",
  ONBOARDING_CREATE_ACCOUNT = "onboarding.create-account",
  ONBOARDING_SETUP_ACCOUNT = "onboarding.setup-account",
  ONBOARDING_SETUP_ACCOUNT_FAIL = "onboarding.setup-account-fail",
  ONBOARDING_INVITE_DEPENDENTS = "onboarding.invite-dependents",
  ONBOARDING_LEGAL_AGREEMENTS = "onboarding.legal-agreements",
  ONBOARDING_COMPLETE = "onboarding.complete",

  FAMILY_PAGE_INVITE_DEPENDENTS = "family.invite-dependents",
  INVITE_DEPENDENTS_FORM_INVITE = "invite-dependents-form.invite",

  WANT_HELP_PHONE = "WANT_HELP_PHONE",
  WANT_HELP_EMAIL = "WANT_HELP_EMAIL",

  PCP_LEARN_MORE = "PCP_LEARN_MORE",

  CLINICAL_DOCS = "clinical_docs",
  CLINICAL_DOCS_SELECT = "clinical_docs.select",

  // RX
  RX_DELIVERY_VERIFICATION = "RX_DELIVERY_VERIFICATION",
  RX_DELIVERY_CHECKOUT = "RX_DELIVERY_CHECKOUT",
  RX_DELIVERY_ADDRESS_ELIGIBILITY = "RX_DELIVERY_ADDRESS_ELIGIBILITY",
  RX_TRANSFER_FORM = "RX_TRANSFER_FORM",

  INVOICE_PAGE_USER_VERIFICATION = "INVOICE_PAGE_USER_VERIFICATION",

  // Claims
  CLAIMS_PAGE = "CLAIMS_PAGE",
  CLAIMS_PAGE_PAGINATION = "CLAIMS_PAGE_PAGINATION",
  CLAIMS_PAGE_MOBILE_DISCLOSURE_CARD = "CLAIMS_PAGE_MOBILE_DISCLOSURE_CARD",
}

export enum MemberPortalMixpanelEventName {
  SIGN = "SIGN",
  VIEW = "VIEW",
  EXPAND = "EXPAND",
  COLLAPSE = "COLLAPSE",
  // This could be changed to something more generic in the future, passing changed fields as props.  For now, all we're tracking is the preferred language change.
  UPDATE_PREFERRED_LANGUAGE = "UPDATE_PREFERRED_LANGUAGE",
  IDENTITY_VERIFICATION_SUCCESS = "IDENTITY_VERIFICATION_SUCCESS",
  IDENTITY_VERIFICATION_FAIL = "IDENTITY_VERIFICATION_FAIL",
}
