import { TextAlignVariant } from "@chp/curative_ui";
import { FormattedMessage } from "react-intl";

import { AnimationType } from "../Animation";
import {
  StatusMessageWithLogo,
  StatusMessageWithLogoSize,
} from "../StatusMessageWithLogo";

export type PageNotFoundErrorMessageProps = {
  testId?: string;
};

export const PageNotFoundErrorMessage = ({
  testId = "PageNotFoundErrorMessage",
}: PageNotFoundErrorMessageProps) => (
  <StatusMessageWithLogo
    size={StatusMessageWithLogoSize.MD}
    animationType={AnimationType.ERROR}
    testId={testId}
    textAlign={TextAlignVariant.CENTER}
    title={
      <FormattedMessage defaultMessage="Oops! Page Not Found" id="sxqBbq" />
    }
    message={
      <>
        <FormattedMessage
          defaultMessage="We're sorry, but the page you're looking for cannot be found."
          id="9ilALv"
        />{" "}
        <FormattedMessage
          defaultMessage="It may have been moved, renamed, or temporarily unavailable."
          id="XMp/e1"
        />
      </>
    }
  />
);
