import { useEffect, useState } from "react";

export type WindowSize = {
  height: number;
  width: number;
};

/**
 * Store window size in state. Update on window resize.
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Window/resize_event
 */
export const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    height: 0,
    width: 0,
  });
  useEffect(() => {
    const updateSize = (): void => {
      setWindowSize({ height: window.innerHeight, width: window.innerWidth });
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return windowSize;
};
