import {
  Box,
  SpacingVariant,
  TextAlignVariant,
  TextVariant,
} from "@chp/curative_ui";
import { Animation, AnimationType } from "@chp/shared/components";
import clsx from "clsx";
import { ReactNode, useMemo } from "react";

export interface StatusMessageWithLogoProps {
  children?: ReactNode;
  className?: string;
  animationType: AnimationType;
  testId?: string;
  title?: string | ReactNode;
  message?: string | ReactNode;
  preMessage?: string | ReactNode;
  size: StatusMessageWithLogoSize;
  textAlign?: TextAlignVariant;
}

export enum StatusMessageWithLogoSize {
  LG = "LG",
  MD = "MD",
  SM = "SM",
}

export const StatusMessageWithLogo = ({
  className,
  children,
  animationType,
  testId = "StatusMessageWithLogo",
  title,
  message,
  preMessage,
  size,
  textAlign,
}: StatusMessageWithLogoProps) => {
  const baseAnimationContainerClassnames = "mx-auto object-contain relative";
  const { animationContainerProps, titleContinerProps } = useMemo(() => {
    switch (size) {
      case StatusMessageWithLogoSize.LG:
        return {
          animationContainerProps: {
            className: clsx(
              baseAnimationContainerClassnames,
              "h-60 w-60 md:h-64 md:w-64"
            ),
          },
          titleContinerProps: {
            element: "h1" as keyof JSX.IntrinsicElements,
            textVariant: TextVariant["3XlBold"],
          },
        };
      case StatusMessageWithLogoSize.MD:
        return {
          animationContainerProps: {
            className: clsx(baseAnimationContainerClassnames, "h-36 w-36"),
          },
          titleContinerProps: {
            element: "h1" as keyof JSX.IntrinsicElements,
            textVariant: TextVariant["2XlBold"],
          },
        };
      case StatusMessageWithLogoSize.SM:
        return {
          animationContainerProps: {
            className: clsx(baseAnimationContainerClassnames, "h-28 w-28"),
          },
          titleContinerProps: {
            element: "h2" as keyof JSX.IntrinsicElements,
            textVariant: TextVariant["2XlBold"],
          },
        };
    }
  }, [size]);

  return (
    <div
      className={clsx(
        "flex w-full flex-col items-center text-center",
        className
      )}
      data-testid={testId}
    >
      <div className="max-w-md">
        <div {...animationContainerProps}>
          <Animation type={animationType} />
        </div>
        <Box
          {...titleContinerProps}
          margin={{ top: SpacingVariant.S24 }}
          textAlign={textAlign}
        >
          {title}
        </Box>
        {preMessage && (
          <Box
            element="p"
            margin={{
              top: SpacingVariant.S24,
              bottom: children ? SpacingVariant.S24 : undefined,
            }}
            textVariant={TextVariant["2XlRegular"]}
            textAlign={textAlign}
            testId="StatusMessageWithLogo__Pre_Message"
          >
            {preMessage}
          </Box>
        )}
        {message && (
          <Box
            element="p"
            margin={{
              top: SpacingVariant.S24,
              bottom: children ? SpacingVariant.S24 : undefined,
            }}
            textVariant={TextVariant.LgRegular}
            textAlign={textAlign}
            testId="StatusMessageWithLogo__Message"
          >
            {message}
          </Box>
        )}
        {children}
      </div>
    </div>
  );
};
