import { SignatureResponseType } from "@chp/shared/generated/memberPortalApi.graphql";
import { IntlFormatters } from "react-intl";
import * as Yup from "yup";

export const getLegalAgreementFormValidators = ({
  formatMessage,
  allowedSignatureResponseTypes,
}: {
  formatMessage: IntlFormatters["formatMessage"];
  allowedSignatureResponseTypes: SignatureResponseType[];
}) => ({
  signatureResponseType: Yup.string()
    // Adding nullable() overrides Yup's generic "signatureResponseType cannot be null" validation string
    .nullable()
    // We then set our own non-null validation error message using this "is-required" test()
    .test(
      "is-required",
      formatMessage({
        defaultMessage: "A consent response is required.",
        id: "qi/tnY",
      }),
      (value) => value !== null && value !== undefined
    )
    .oneOf(
      allowedSignatureResponseTypes,
      formatMessage({
        defaultMessage: "Invalid response type.",
        id: "9UZy97",
      })
    ),
});

export const getLegalAgreementFormValidationSchema = (options: {
  formatMessage: IntlFormatters["formatMessage"];
  allowedSignatureResponseTypes: SignatureResponseType[];
}) => {
  return Yup.object().shape(getLegalAgreementFormValidators(options));
};

export type LegalAgreementFormValidationSchema = ReturnType<
  typeof getLegalAgreementFormValidationSchema
>;
