/* eslint-disable @typescript-eslint/no-explicit-any */

import { Button, ButtonVariant, Modal, ModalMaxWidth } from "@chp/curative_ui";
import React from "react";
import { useIntl } from "react-intl";

import { SmartTableFilter, SmartTableFilterDisplay } from "./types";

interface FiltersHeaderProps {
  addFilters: (futureFilters: Record<string, any>) => void;
  removeFilters: (value: Record<string, any>) => void;
  activeFilters: Record<string, any>;
  filters: Array<SmartTableFilter>;

  allFiltersButtonLabel?: string;
}

const FiltersHeader = ({
  addFilters,
  removeFilters,
  activeFilters,
  filters,
  allFiltersButtonLabel,
}: FiltersHeaderProps) => {
  const { formatMessage } = useIntl();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const rowFilters = filters.filter((filter) => {
    if (filter.display === SmartTableFilterDisplay.WhenActive) {
      return Object.keys(activeFilters).includes(filter.key);
    } else return filter.display !== SmartTableFilterDisplay.Never;
  });

  return (
    <>
      <div className="flex flex-col px-2 py-0">
        <div className="flex flex-wrap gap-2">
          {rowFilters.map((filter) => {
            return (
              <div key={`FiltersHeader-filter-${filter.key}`}>
                {filter.component({ addFilters, removeFilters, activeFilters })}
              </div>
            );
          })}
          {allFiltersButtonLabel && filters.length > rowFilters.length && (
            <Button
              variant={ButtonVariant.SmallOutline}
              onClick={() => setIsModalOpen(true)}
              className="mt-7"
            >
              {allFiltersButtonLabel}
            </Button>
          )}
        </div>
      </div>
      {allFiltersButtonLabel && (
        <Modal
          isOpen={isModalOpen}
          maxWidth={ModalMaxWidth.TwoExtraLarge}
          onRequestClose={() => {
            setIsModalOpen(false);
          }}
          closeButtonText="Close"
          title={allFiltersButtonLabel}
        >
          <div
            className={`${
              filters.length < 4
                ? "flex flex-col flex-wrap"
                : "grid grid-cols-2"
            } mt-2 gap-2`}
          >
            {filters.map((filter) => {
              return (
                <div key={`FiltersModal-filter-${filter.key}`}>
                  {filter.component({
                    addFilters,
                    removeFilters,
                    activeFilters,
                  })}
                </div>
              );
            })}
          </div>
          <div className="sticky inset-x-0 bottom-0 flex items-center justify-between p-4">
            <Button
              className="mr-4"
              onClick={() => {
                removeFilters(activeFilters);
              }}
              variant={ButtonVariant.Flat}
            >
              {formatMessage({ defaultMessage: "Clear All", id: "cqlObT" })}
            </Button>
            <Button
              variant={ButtonVariant.BigPrimary}
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              {formatMessage({ defaultMessage: "See results", id: "CpNb11" })}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default FiltersHeader;
