import { mapValues } from "lodash-es";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createActions<Actions extends { readonly [key: string]: any }>(
  pathName: string,
  actions: Actions
): Actions {
  return mapValues(actions, (value, key) =>
    createAction(pathName, key, value)
  ) as Actions;
}

// Wrap the action with analytics and logging
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createAction<F extends (...args: any[]) => any>(
  pathName: string,
  actionName: string,
  fn: F
): (...args: Parameters<F>) => ReturnType<F> {
  return (...args: Parameters<F>): ReturnType<F> => {
    logAction(actionName, args);
    return fn(...args);
  };
}

// Log action to the console (in development only)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function logAction<F extends (...args: any[]) => any>(
  actionName: string,
  args: Parameters<F>
): void {
  if (process.env.LOG_ACTIONS) {
    console.debug(`${actionName}`, args);
  }
}
