import { Button, ButtonVariant } from "@chp/curative_ui";
import Form, { IChangeEvent } from "@rjsf/core";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import React, { ReactNode, useState } from "react";
import { FormattedMessage } from "react-intl";

import ArrayFieldTemplate from "./widgets/ArrayFieldTemplate";
import { BaseInput } from "./widgets/BaseInput";
import { DateWidget } from "./widgets/DateWidget";
import { FieldTemplate } from "./widgets/FieldTemplate";

export type JSONSchemaFormProps = {
  disabled?: boolean;
  errorMessage?: ReactNode;
  initialFormData?: any; //eslint-disable-line @typescript-eslint/no-explicit-any
  onChange?: (e: IChangeEvent) => void;
  onSubmit?: (e: IChangeEvent) => void;
  schema: RJSFSchema;
  transformErrors?: (errors: any, uiSchema: any) => any; //eslint-disable-line @typescript-eslint/no-explicit-any
  uiSchema?: UiSchema;
};

export const JSONSchemaForm = ({
  disabled,
  errorMessage,
  initialFormData,
  onChange,
  onSubmit,
  schema,
  transformErrors,
  uiSchema,
}: JSONSchemaFormProps) => {
  const [formData, setFormData] = useState(initialFormData);
  return (
    <div className="w-full">
      <div className="w-full max-w-xl">
        <Form
          validator={validator}
          formData={formData}
          schema={schema}
          uiSchema={uiSchema}
          widgets={{
            DateWidget,
          }}
          templates={{
            FieldTemplate,
            BaseInputTemplate: BaseInput,
            ArrayFieldTemplate,
          }}
          transformErrors={transformErrors}
          onChange={(e) => {
            setFormData(e.formData);
            onChange && onChange(e);
          }}
          onSubmit={onSubmit}
          disabled={disabled}
          showErrorList="top"
        >
          {errorMessage && (
            <div className="mt-5 w-full" aria-live="polite">
              <p className="text-red-700">{errorMessage}</p>
            </div>
          )}
          <div className="my-5">
            <Button type="submit" variant={ButtonVariant.FullWidthPrimary}>
              <FormattedMessage defaultMessage="Submit" id="wSZR47" />
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
