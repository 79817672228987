import { IdToken } from "@auth0/auth0-spa-js/dist/typings/global";
import jwtDecode from "jwt-decode";

/**
 * Returns true if the token is expired or *about* to expire. We fudge our
 * definition of "expired" here so that the frontend will treat tokens as
 * expired before the backend does, to avoid scenarios where the frontend sends
 * a token just as it's expiring and the backend checks it once it has expired
 * and rejects it.
 */
export const isTokenExpired = (accessToken: string) => {
  const currTimeUnix = Math.floor(Date.now() / 1000);

  const accessTokenDecoded = jwtDecode<IdToken>(accessToken);

  return accessTokenDecoded.exp && accessTokenDecoded.exp - currTimeUnix <= 60;
};
