import clsx from "clsx";
import { useMediaPredicate } from "react-media-hook";
import ReactPaginate from "react-paginate";

import { SpacingVariant } from "../../constants";
import { BREAKPOINT_SM } from "../../mediaBreakpoints";
import { buttonBaseClassNames } from "../../utils";
import { Box } from "../Box";
import {
  DISPLAY_CLASS_NAMES,
  PADDING_BOTTOM_CLASS_NAMES,
  PADDING_TOP_CLASS_NAMES,
} from "../constants";
import { Icon, IconProps, IconVariant } from "../Icon";
import {
  CUIComponent,
  CUIComponentProps,
  DisplayVariant,
  TextAlignVariant,
  TextVariant,
} from "../types";

const activeClassNames: string[] = [
  // use higher specificity for border color override
  "[&_a]:border-borderColor-control",
  "hover:[&_a]:border-borderColor-control",
  "focus:[&_a]:border-borderColor-control",
];
const activeLinkClassNames: string[] = ["bg-surface-highlight", "z-10"];

const baseControlClassNames: string[] = [
  clsx(
    buttonBaseClassNames,
    DISPLAY_CLASS_NAMES[DisplayVariant.InlineBlock],
    PADDING_BOTTOM_CLASS_NAMES[SpacingVariant.S6],
    PADDING_TOP_CLASS_NAMES[SpacingVariant.S6]
  ),
  "border",
  "rounded",
  "border-transparent",
  "min-w-[34px]",
  "px-1",
  "relative",
  "hover:bg-surface-highlight",
  "hover:border-surface-highlight",
  "hover:text-textColor-highlight-default",
  "focus:bg-surface-highlight",
  "focus:border-surface-highlight",
  "focus:text-textColor-highlight-default",
  "focus:z-20",
];

const baseIconProps: Pick<IconProps, "size"> = {
  size: SpacingVariant.S16,
};

const containerClassNames: string[] = ["inline-flex", "isolate", "-space-x-px"];

const nextPrevLinkClassNames: string[] = [
  ...baseControlClassNames,
  "inline-flex",
  "items-center",
  "sm:px-3",
  "text-textColor-highlight-default",
];

const nextPrevDisabledClassNames: string[] = [
  // use higher specificity for text color override
  "[&_a]:text-textColor-subdued",
];

const nextPrevDisabledLinkClassNames: string[] = [
  "bg-opacity-0",
  "border-transparent",
  "cursor-not-allowed",
  "hover:bg-opacity-0",
  "hover:border-transparent",
  "hover:text-textColor-subdued",
  "focus:bg-opacity-0",
  "focus:border-transparent",
  "focus:text-textColor-subdued",
];

export const pageRangeDisplayed = ({
  activePageNumber,
  hasMinWidthSmall,
  totalPages,
}: {
  activePageNumber: number;
  hasMinWidthSmall: boolean;
  totalPages: number;
}): number => {
  // return 1 for less than sm, to save space
  if (!hasMinWidthSmall) {
    return 1;
  } else if (totalPages <= 7) {
    // show all pages in one group for up to 7 total pages
    return 7;
  } else if ([1, totalPages - 1, totalPages].includes(activePageNumber)) {
    // show 5 pages if active page is very early or late
    return 5;
  } else if ([2, 3, totalPages - 2].includes(activePageNumber)) {
    // show 4 pages if active page is somewhat early or late
    return 4;
  } else {
    // otherwise show 3 pages in middle group => active page +- 1
    return 3;
  }
};

export type CommonPaginationProps = {
  ariaLabel: string;
  nextText: string;
  previousText: string;
};

export type PaginationProps = CUIComponentProps<
  CommonPaginationProps & {
    // start with 1 (no zero index)
    activePageNumber: number;
    FormattedNumber: (props: { value: number }) => React.ReactNode | null;
    // start with 1 (no zero index)
    onPageClick: (pageNumber: number) => void;
    separatorText: string;
    summaryText?: string;
    hrefBuilder?: (
      pageIndex: number,
      pageCount: number,
      selectedPage: number
    ) => void;
    // start with 1 (no zero index)
    totalPages: number;
  }
>;

/**
 * See: https://tailwindui.com/components/application-ui/navigation/pagination#component-69eb9381f977800aa890ce8f7d9e2d20
 */
export const Pagination: CUIComponent<PaginationProps> = ({
  activePageNumber,
  ariaLabel,
  className,
  FormattedNumber,
  hrefBuilder,
  onPageClick,
  nextText,
  previousText,
  separatorText,
  summaryText,
  testId = `Pagination`,
  totalPages,
}) => {
  // convert activePageNumber to zero index
  const forcePage: number = activePageNumber - 1;

  // convert selected to start with 1 (no zero index)
  const onPageChange = ({ selected }: { selected: number }): void =>
    onPageClick(selected + 1);

  // show 1 margin page
  const marginPagesDisplayed: number = 1;

  const hasMinWidthSmall = useMediaPredicate(`(min-width: ${BREAKPOINT_SM}px)`);

  return (
    <Box
      className={clsx(
        "flex",
        "items-center",
        "justify-between",
        "py-3",
        "text-textColor-default",
        className
      )}
      element="div"
      testId={testId}
      textVariant={TextVariant.SmRegularTall}
    >
      <Box
        className={clsx(
          "sm:flex",
          "sm:flex-1",
          "sm:items-center",
          "sm:justify-between",
          "w-full"
        )}
        element="div"
      >
        {/* keep layout container div even if summary text is empty */}
        <Box element="div">
          {/* check if summary text is present */}
          {summaryText && (
            <Box
              className={clsx("mb-2", "sm:mb-0")}
              element="p"
              testId={`${testId}__SummaryText`}
            >
              {summaryText}
            </Box>
          )}
        </Box>

        {/* layout container div for pagination controls */}
        <Box element="div" textAlign={TextAlignVariant.RIGHT}>
          <Box aria-label={ariaLabel} element="nav" testId={`${testId}__Nav`}>
            <ReactPaginate
              activeClassName={clsx(activeClassNames)}
              activeLinkClassName={clsx(activeLinkClassNames)}
              breakLabel={separatorText}
              breakLinkClassName={clsx(baseControlClassNames)}
              containerClassName={clsx(containerClassNames)}
              disabledClassName={clsx(nextPrevDisabledClassNames)}
              disabledLinkClassName={clsx(nextPrevDisabledLinkClassNames)}
              forcePage={forcePage}
              hrefBuilder={hrefBuilder}
              marginPagesDisplayed={marginPagesDisplayed}
              nextLabel={
                <>
                  {nextText}{" "}
                  <Icon
                    {...baseIconProps}
                    variant={IconVariant.CHEVRON_RIGHT}
                  />
                </>
              }
              nextLinkClassName={clsx(nextPrevLinkClassNames)}
              onPageChange={onPageChange}
              pageCount={totalPages}
              pageLabelBuilder={(page) => <FormattedNumber value={page} />}
              pageLinkClassName={clsx(baseControlClassNames)}
              pageRangeDisplayed={pageRangeDisplayed({
                activePageNumber,
                hasMinWidthSmall,
                totalPages,
              })}
              previousLabel={
                <>
                  <Icon {...baseIconProps} variant={IconVariant.CHEVRON_LEFT} />{" "}
                  {previousText}
                </>
              }
              previousLinkClassName={clsx(nextPrevLinkClassNames)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
