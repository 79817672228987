import clsx from "clsx";
import { useMemo } from "react";
import { useMediaPredicate } from "react-media-hook";

import { FOCUS_CLASS_NAMES, SpacingVariant } from "../../constants";
import { desktopBreakpoint } from "../../mediaBreakpoints";
import {
  MARGIN_BOTTOM_CLASS_NAMES,
  MARGIN_LEFT_CLASS_NAMES,
  ScreenReaderOnly,
} from "..";
import { Box } from "../Box";
import { Icon, IconVariant } from "../Icon";
import { Logo, LogoVariant } from "../Logo";
import { CUIComponent, CUIComponentProps, TextVariant } from "../types";
import { DividerItem } from "./DividerItem";
import { ExpandableItem, ExpandableItemProps } from "./ExpandableItem";
import { MobileSideNavbar } from "./MobileSideNavbar";
import { NavButton, NavButtonProps } from "./NavButton";
import { NavItemProps } from "./NavItem";
import { NavLink, NavLinkProps } from "./NavLink";

export type SideNavbarProps = CUIComponentProps<{
  collapseDesktopMenuBtnText: string;
  expandDesktopMenuScreenReaderText: string;
  isMobileNavbarOpen: boolean;
  isNavbarCollapsed: boolean;
  items: SideNavbarItemProps[];
  onRequestCollapseNavbar: (arg: boolean) => void;
  onRequestOpenMobileNavbar: (arg: boolean) => void;
  openMobileMenuButtonAriaLabel: string;
  closeMobileMenuButtonAriaLabel: string;
  logoLinkAriaLabel: string;
  logoHref?: string;
  breakpoint?: number;
}>;

export enum SideNavbarItemType {
  NavButton,
  NavLink,
  /**
   *
   * @deprecated use NavLink for navigation items and NavButton for actions
   */
  NavItem,
  ExpandableItem,
  Divider,
}

export type SideNavbarItemProps =
  | (ExpandableItemProps & { type: SideNavbarItemType.ExpandableItem })
  | (NavItemProps & { type: SideNavbarItemType.NavItem })
  | (NavButtonProps & { type: SideNavbarItemType.NavButton })
  | (NavLinkProps & { type: SideNavbarItemType.NavLink })
  | { type: SideNavbarItemType.Divider; key: string };

export const ACTIVE_LINK_CLASSNAMES =
  "bg-white text-textColor-highlight-default rounded-r-lg";
export const INACTIVE_LINK_CLASSNAMES =
  "bg-surface-neutral text-textColor-default";

export const SideNavbar: CUIComponent<SideNavbarProps> = ({
  className,
  collapseDesktopMenuBtnText,
  expandDesktopMenuScreenReaderText,
  isMobileNavbarOpen,
  isNavbarCollapsed,
  items,
  onRequestCollapseNavbar,
  onRequestOpenMobileNavbar,
  breakpoint = desktopBreakpoint,
  testId = "SideNavbar",
  openMobileMenuButtonAriaLabel,
  closeMobileMenuButtonAriaLabel,
  logoHref = "/",
  logoLinkAriaLabel,
}) => {
  const desktop = useMediaPredicate(`(min-width: ${breakpoint}px)`);
  const desktopContainerClassnames = useMemo(
    () =>
      clsx(
        "h-screen",
        "top-0 bg-surface-neutral",
        "left-0 flex shrink-0 flex-col",
        "justify-between px-2 py-4",
        "transition-width ease-out",
        "z-40",
        isNavbarCollapsed ? "w-16" : "w-72"
      ),
    [isNavbarCollapsed]
  );

  return desktop ? (
    <nav>
      <div
        className={clsx(desktopContainerClassnames, "invisible", className)}
      />
      <div
        data-testid={testId}
        className={clsx(
          desktopContainerClassnames,
          "fixed",
          // If overflow-y-auto is on while the Navbar is collapsed, the floating menu items disappear.
          !isNavbarCollapsed && "overflow-y-auto",
          className
        )}
      >
        <div className="flex flex-col items-start">
          <a
            aria-label={logoLinkAriaLabel}
            className={clsx(
              MARGIN_BOTTOM_CLASS_NAMES[SpacingVariant.S16],
              isNavbarCollapsed
                ? "self-center"
                : MARGIN_LEFT_CLASS_NAMES[SpacingVariant.S8],
              FOCUS_CLASS_NAMES
            )}
            href={logoHref}
          >
            <Logo
              altText=""
              height={SpacingVariant.S40}
              variant={LogoVariant.SignalOrangeSymbolOnly}
            />
          </a>

          {items.map((item) => {
            switch (item.type) {
              case SideNavbarItemType.NavButton:
                return (
                  <NavButton
                    key={item.label}
                    testId={`${testId}__${item.label}__NavButton`}
                    isNavbarCollapsed={isNavbarCollapsed}
                    {...(item as NavButtonProps)}
                  />
                );
              case SideNavbarItemType.NavLink:
                return (
                  <NavLink
                    key={item.label}
                    testId={`${testId}__${item.label}__NavLink`}
                    isNavbarCollapsed={isNavbarCollapsed}
                    {...item}
                  />
                );
              case SideNavbarItemType.NavItem:
                return (
                  <NavButton
                    key={item.label}
                    testId={`${testId}__${item.label}__NavItem`}
                    isNavbarCollapsed={isNavbarCollapsed}
                    {...(item as NavButtonProps)}
                  />
                );
              case SideNavbarItemType.ExpandableItem:
                return (
                  <ExpandableItem
                    key={item.label}
                    isNavbarCollapsed={isNavbarCollapsed}
                    testId={`${testId}__ExpandableItem`}
                    {...(item as ExpandableItemProps)}
                  />
                );
              case SideNavbarItemType.Divider:
                return <DividerItem key={item.key} />;
              default:
                return null;
            }
          })}
        </div>

        <Box
          element="div"
          padding={{
            left: SpacingVariant.S24,
            right: SpacingVariant.S24,
          }}
          className={clsx("flex", isNavbarCollapsed && "justify-center")}
        >
          <button
            data-testid={`${testId}__CollapseBtn`}
            aria-expanded={!isNavbarCollapsed}
            type="button"
            className={clsx(
              "flex items-center gap-3",
              "text-textColor-subdued",
              FOCUS_CLASS_NAMES
            )}
            onClick={() => onRequestCollapseNavbar(!isNavbarCollapsed)}
          >
            {isNavbarCollapsed ? (
              <>
                <Icon
                  variant={IconVariant.CHEVRON_RIGHT}
                  size={SpacingVariant.S24}
                />
                <ScreenReaderOnly element="span">
                  {expandDesktopMenuScreenReaderText}
                </ScreenReaderOnly>
              </>
            ) : (
              <>
                <Icon
                  variant={IconVariant.CHEVRON_LEFT}
                  size={SpacingVariant.S24}
                />
                <Box element="p" textVariant={TextVariant.SmRegularTall}>
                  {collapseDesktopMenuBtnText}
                </Box>
              </>
            )}
          </button>
        </Box>
      </div>
    </nav>
  ) : (
    <MobileSideNavbar
      aria-expanded={!isMobileNavbarOpen}
      isOpen={isMobileNavbarOpen}
      items={items}
      onRequestOpen={onRequestOpenMobileNavbar}
      testId={`${testId}__Mobile`}
      openMobileMenuButtonAriaLabel={openMobileMenuButtonAriaLabel}
      closeMobileMenuButtonAriaLabel={closeMobileMenuButtonAriaLabel}
    />
  );
};
