// Apple Wallet Guidelines: https://developer.apple.com/wallet/add-to-apple-wallet-guidelines/
// mini tutorial for jpasskit: https://whataboutcassandra.blogspot.com/2015/08/this-has-nothing-to-do-with-cassandra.html

export type AppleWalletBadgeConfig = {
  altText: string;
  height: number;
  imageUrl: string;
  width: number;
};

export const appleWalletBadgeConfigEN: AppleWalletBadgeConfig = {
  altText: "Add to Apple Wallet",
  height: 35.016,
  imageUrl:
    "https://static.curative.com/health_plan/member_card/US-UK_Add_to_Apple_Wallet_RGB_101421.svg",
  width: 110.739,
};

export const appleWalletBadgeConfigES: AppleWalletBadgeConfig = {
  altText: "Añadir a Cartera de Apple",
  height: 35.211,
  imageUrl:
    "https://static.curative.com/health_plan/member_card/ES_Add_to_Apple_Wallet_RGB_101921.svg",
  width: 132.525,
};

/**
 * Create Apple Wallet Object URL from data string.
 */
export const appleWalletCreateObjectUrl = ({
  data,
}: {
  data: string;
}): string => {
  const base64ToArrayBuffer = (base64: string) => {
    const binaryString = atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  return window.URL.createObjectURL(
    new Blob([base64ToArrayBuffer(data)], {
      type: "application/vnd.apple.pkpass",
    })
  );
};

/**
 * Trigger Apple Wallet download from URL.
 */
export const appleWalletDownloadFromUrl = ({ url }: { url: string }): void => {
  const link = document.createElement("a");
  link.download = "membercard.pkpass";
  link.href = url;
  link.click();
};
