const fs = require("fs");
const path = require("path");
const postcss = require("postcss");
const plugin = require("tailwindcss/plugin");

/**
 * cui scoped react-day-picker css. Based on https://github.com/tailwindlabs/tailwindcss/blob/v3.0.23/src/corePlugins.js
 */

const index = plugin(({ addBase }) => {
  let rdpStyles = postcss.parse(
    fs.readFileSync(
      path.join(require.resolve("react-day-picker"), "../style.css"),
      "utf8"
    )
  );

  addBase([...rdpStyles.nodes]);
});

module.exports = index;
