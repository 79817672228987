import { useMemo } from "react";

/**
 * Memoized list with array keys.
 */
export const useWithArrayKeys = <T,>({
  array,
  keyGenerator,
}: {
  array: T[];
  keyGenerator: (item: T) => string | number;
}): Array<{ item: T; key: string | number }> =>
  useMemo(
    () =>
      array.map((item) => ({
        item: item,
        key: keyGenerator(item),
      })),
    [array, keyGenerator]
  );
