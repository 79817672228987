import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";

import {
  FormattedTextField,
  FormattedTextFieldProps,
} from "../FormattedTextField";

export type FormTextFieldProps<T extends FieldValues> = {
  hasClearButton?: boolean;
  name: Path<T>;
  id?: string;
  className?: string;
  onValueChanged?: (newValue: string | null) => void;
  label: string;
  autoComplete?: string;
  isLabelSrOnly?: boolean;
  placeholder?: string;
  maxLength?: number;
  type?: string;
  pattern?: string;
  isDisabled?: boolean;
  testId?: string;
} & Pick<
  FormattedTextFieldProps,
  "description" | "inputClassName" | "max" | "min" | "htmlElement"
>;

export function FormTextField<T extends FieldValues>({
  hasClearButton,
  name,
  id,
  onValueChanged,
  ...rest
}: FormTextFieldProps<T>) {
  const { control } = useFormContext<T>();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { name, value, ref, onBlur, onChange },
        fieldState: { error },
      }) => {
        const onRequestChangeValue = (newValue: string): void => {
          const newValueOrNull = newValue ? newValue : null;

          onChange({
            target: {
              value: newValueOrNull,
            },
          });

          onValueChanged?.(newValueOrNull);
        };

        const onClickClearButton: (() => void) | undefined =
          hasClearButton && value ? () => onRequestChangeValue("") : undefined;
        return (
          <FormattedTextField
            onBlur={onBlur}
            id={id || name}
            value={(value as string) || ""}
            innerRef={ref}
            onClickClearButton={onClickClearButton}
            onRequestChangeValue={onRequestChangeValue}
            errorMessage={error?.message}
            name={name}
            {...rest}
          />
        );
      }}
    />
  );
}
