import { HEALTH_PLAN_CUSTOMER_SUPPORT_EMAIL_ADDRESS } from "@chp/shared/constants";
import clsx from "clsx";

export const EmailLink = ({
  email,
  className,
}: {
  email: string;
  className?: string;
}) => {
  return (
    <a className={clsx("!underline", className)} href={`mailto:${email}`}>
      {email}
    </a>
  );
};

/**
 * clickable email link with default underline
 */
export const MemberServicesEmailLink = ({
  className,
}: {
  className?: string;
}) => {
  return (
    <EmailLink
      className={className}
      email={HEALTH_PLAN_CUSTOMER_SUPPORT_EMAIL_ADDRESS}
    />
  );
};
