export enum SpacingVariant {
  S0 = "S0",
  S1 = "S1",
  S2 = "S2",
  S4 = "S4",
  S6 = "S6",
  S8 = "S8",
  S12 = "S12",
  S16 = "S16",
  S20 = "S20",
  S24 = "S24",
  S32 = "S32",
  S40 = "S40",
  S48 = "S48",
  S64 = "S64",
  S80 = "S80",
  S160 = "S160",
  S240 = "S240",
  S320 = "S320",
}
