import { Modal, ModalProps } from "@chp/curative_ui";
import { useIntl } from "react-intl";

export type FormattedModalProps = Omit<ModalProps, "closeButtonText">;

export const FormattedModal = (props: FormattedModalProps): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <Modal
      closeButtonText={formatMessage({ defaultMessage: "Close", id: "rbrahO" })}
      {...props}
    />
  );
};
