import {
  AdjustmentsHorizontalIcon,
  AdjustmentsVerticalIcon,
  ArchiveBoxIcon,
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ArrowRightOnRectangleIcon,
  ArrowSmallDownIcon,
  ArrowSmallLeftIcon,
  ArrowSmallRightIcon,
  ArrowSmallUpIcon,
  ArrowTopRightOnSquareIcon,
  Bars3Icon,
  BookOpenIcon,
  BuildingOfficeIcon,
  CalendarIcon,
  CameraIcon,
  ChatBubbleLeftEllipsisIcon,
  ChatBubbleLeftRightIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ClipboardDocumentIcon,
  ClipboardIcon,
  ClockIcon,
  CloudArrowUpIcon,
  CogIcon,
  ComputerDesktopIcon,
  CreditCardIcon,
  CubeIcon,
  CurrencyDollarIcon,
  DocumentIcon,
  DocumentTextIcon,
  EllipsisHorizontalIcon,
  EnvelopeIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  GlobeAltIcon,
  HomeIcon,
  InformationCircleIcon,
  LinkIcon,
  MagnifyingGlassIcon,
  MapIcon,
  MapPinIcon,
  MinusIcon,
  PaperClipIcon,
  PencilIcon,
  PhoneIcon,
  PlusIcon,
  PrinterIcon,
  QuestionMarkCircleIcon,
  RectangleStackIcon,
  TrashIcon,
  UserGroupIcon,
  UserIcon,
  UsersIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  CreditCardIcon as CreditCardFilledIcon,
  DocumentCheckIcon,
  QuestionMarkCircleIcon as QuestionMarkCircleFilledIcon,
} from "@heroicons/react/24/solid";
import clsx from "clsx";
import { PropsWithChildren, useMemo } from "react";

import { SpacingVariant } from "../../constants";
import { generateRandomString } from "../../utils/text";
import { HEIGHT_CLASS_NAMES, WIDTH_CLASS_NAMES } from "../constants";
import { CUIComponent, CUIComponentProps } from "../types";

export enum IconVariant {
  ARCHIVE_BOX = "ARCHIVE_BOX",
  ADJUSTMENTS = "ADJUSTMENTS",
  ADJUSTMENTS_HORIZONTAL = "ADJUSTMENTS_HORIZONTAL",
  ARROW_SMALL_DOWN = "ARROW_SMALL_DOWN",
  BOOK_OPEN = "BOOK_OPEN",
  ARROW_SMALL_LEFT = "ARROW_SMALL_LEFT",
  ARROW_SMALL_RIGHT = "ARROW_SMALL_RIGHT",
  ARROW_SMALL_UP = "ARROW_SMALL_UP",
  CALENDAR = "CALENDAR",
  CAMERA = "CAMERA",
  CHAT_BUBBLE_LEFT_RIGHT = "CHAT_BUBBLE_LEFT_RIGHT",
  CHAT_BUBBLE_LEFT_ELLIPSIS = "CHAT_BUBBLE_LEFT_ELLIPSIS",
  CHECK = "CHECK",
  CHECK_CIRCLE = "CHECK_CIRCLE",
  CHEVRON_DOWN = "CHEVRON_DOWN",
  CHEVRON_DOUBLE_LEFT = "CHEVRON_DOUBLE_LEFT",
  CHEVRON_DOUBLE_RIGHT = "CHEVRON_DOUBLE_RIGHT",
  CHEVRON_LEFT = "CHEVRON_LEFT",
  CHEVRON_RIGHT = "CHEVRON_RIGHT",
  CHEVRON_UP = "CHEVRON_UP",
  CLIPBOARD_LIST = "CLIPBOARD_LIST",
  CLIPBOARD_HEART = "CLIPBOARD_HEART",
  CLOCK = "CLOCK",
  CLOUD_UPLOAD = "CLOUD_UPLOAD",
  CLIPBOARD_DOCUMENT = "CLIPBOARD_DOCUMENT",
  COG = "COG",
  COMPUTER_DESKTOP = "COMPUTER_DESKTOP",
  CREDIT_CARD = "CREDIT_CARD",
  CREDIT_CARD_FILLED = "CREDIT_CARD_FILLED",
  CUBE = "CUBE",
  CURRENCY_DOLLAR = "CURRENCY_DOLLAR",
  CURRENT_LOCATION = "CURRENT_LOCATION",
  DIRECTION = "DIRECTION",
  DOCUMENT = "DOCUMENT",
  DOCUMENT_CHECK = "DOCUMENT_CHECK",
  DOCUMENT_TEXT = "DOCUMENT_TEXT",
  DOWNLOAD = "DOWNLOAD",
  DOTS_HORIZONTAL = "DOTS_HORIZONTAL",
  EXCLAMATION_CIRCLE = "EXCLAMATION_CIRCLE",
  EXCLAMATION_TRIANGLE = "EXCLAMATION_TRIANGLE",
  EXTERNAL_LINK = "EXTERNAL_LINK",
  FILTER = "FILTER",
  FLIP = "FLIP",
  GLOBE_ALT = "GLOBE_ALT",
  HEART_BEAT = "HEART_BEAT",
  HOME = "HOME",
  HOSPITAL_COLOR = "HOSPITAL_COLOR",
  INFORMATION_CIRCLE = "INFORMATION_CIRCLE",
  LINK = "LINK",
  LIST = "LIST",
  LOCATION_MARKER = "LOCATION_MARKER",
  LOGOUT = "LOGOUT",
  MAIL = "MAIL",
  MAIL_BOX = "MAILBOX",
  MAP = "MAP",
  MEMBER_CARD = "MEMBER_CARD",
  MENU = "MENU",
  MINUS = "MINUS",
  NEEDLE = "NEEDLE",
  BUILDING_OFFICE = "BUILDING_OFFICE",
  BUILDING_OFFICE_2_COLOR = "BUILDING_OFFICE_2_COLOR",
  BUILDING_OFFICE_COLOR = "BUILDING_OFFICE_COLOR",
  PAPER_CLIP = "PAPER_CLIP",
  PHONE = "PHONE",
  PILL = "PILL",
  PIN = "PIN",
  PLUS = "PLUS",
  PRINT = "PRINT",
  QUESTION_MARK_CIRCLE = "QUESTION_MARK_CIRCLE",
  QUESTION_MARK_CIRCLE_FILLED = "QUESTION_MARK_CIRCLE_FILLED",
  RECTANGLE_STACK = "RECTANGLE_STACK",
  SCAN = "SCAN",
  SEARCH = "SEARCH",
  STETHOSCOPE = "STETHOSCOPE",
  STETHOSCOPE_COLOR = "STETHOSCOPE_COLOR",
  TEAM_COLOR = "TEAM_COLOR",
  TEAM_2_COLOR = "TEAM_2_COLOR",
  TEST_TUBE = "TEST_TUBE",
  TRASH = "TRASH",
  UNPIN = "UNPIN",
  USER = "USER",
  USERS = "USERS",
  USER_GROUP = "USER_GROUP",
  VIRTUAL_CARE_COLOR = "VIRTUAL_CARE_COLOR",
  WALLET = "WALLET",
  X = "X",
  X_CIRCLE = "X_CIRCLE",
  PENCIL = "PENCIL",
}

type IconSVGProps = {
  "aria-hidden"?: boolean;
  className?: string;
  height?: number | string;
  viewBox?: string;
  width?: number | string;
};

/**
 * IconSVG represents either an imported icon svg from heroicons or a custom icon svg
 */
type IconSVG = (props: IconSVGProps) => JSX.Element;

type HeroIconSvgElement = React.ForwardRefExoticComponent<
  React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
    title?: string;
    titleId?: string;
  } & React.RefAttributes<SVGSVGElement>
>;

const CUSTOM_SVG_PATH_FILL = "currentColor";

const CustomSVGWrapper = (
  props: PropsWithChildren<IconSVGProps>
): JSX.Element => {
  const { children, ...rest } = props;
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {children}
    </svg>
  );
};

const variantSVG: Record<IconVariant, IconSVG | HeroIconSvgElement> = {
  [IconVariant.ADJUSTMENTS]: AdjustmentsVerticalIcon,
  [IconVariant.ADJUSTMENTS_HORIZONTAL]: AdjustmentsHorizontalIcon,
  [IconVariant.ARCHIVE_BOX]: ArchiveBoxIcon,
  [IconVariant.ARROW_SMALL_DOWN]: ArrowSmallDownIcon,
  [IconVariant.BOOK_OPEN]: BookOpenIcon,
  [IconVariant.ARROW_SMALL_LEFT]: ArrowSmallLeftIcon,
  [IconVariant.ARROW_SMALL_RIGHT]: ArrowSmallRightIcon,
  [IconVariant.ARROW_SMALL_UP]: ArrowSmallUpIcon,
  [IconVariant.CALENDAR]: CalendarIcon,
  [IconVariant.CAMERA]: CameraIcon,
  [IconVariant.CHAT_BUBBLE_LEFT_RIGHT]: ChatBubbleLeftRightIcon,
  [IconVariant.CHAT_BUBBLE_LEFT_ELLIPSIS]: ChatBubbleLeftEllipsisIcon,
  [IconVariant.CHECK]: CheckIcon,
  [IconVariant.CHECK_CIRCLE]: CheckCircleIcon,
  [IconVariant.CHEVRON_DOUBLE_LEFT]: ChevronDoubleLeftIcon,
  [IconVariant.CHEVRON_DOUBLE_RIGHT]: ChevronDoubleRightIcon,
  [IconVariant.CHEVRON_DOWN]: ChevronDownIcon,
  [IconVariant.CHEVRON_LEFT]: ChevronLeftIcon,
  [IconVariant.CHEVRON_RIGHT]: ChevronRightIcon,
  [IconVariant.CHEVRON_UP]: ChevronUpIcon,
  [IconVariant.CLIPBOARD_DOCUMENT]: ClipboardDocumentIcon,
  [IconVariant.CLIPBOARD_LIST]: ClipboardIcon,
  [IconVariant.CLIPBOARD_HEART]: (props: IconSVGProps) => (
    <CustomSVGWrapper {...props}>
      <path
        d="M10.4438 2.59192C10.2661 1.96181 9.68697 1.5 9 1.5H7C6.31303 1.5 5.73391 1.96181 5.55618 2.59192M10.4438 2.59192C10.4804 2.72167 10.5 2.85855 10.5 3V3C10.5 3.27614 10.2761 3.5 10 3.5H6C5.72386 3.5 5.5 3.27614 5.5 3V3C5.5 2.85855 5.51958 2.72167 5.55618 2.59192M10.4438 2.59192C10.8746 2.62446 11.303 2.66552 11.7289 2.71494C12.4626 2.80009 13 3.43267 13 4.17132V13C13 13.8284 12.3284 14.5 11.5 14.5H4.5C3.67157 14.5 3 13.8284 3 13V4.17132C3 3.43267 3.53739 2.80009 4.27112 2.71494C4.69698 2.66552 5.12539 2.62446 5.55618 2.59192"
        stroke={CUSTOM_SVG_PATH_FILL}
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="matrix(1.5,0,0,1.5,0,0)"
      />
      <path
        d="M 11.792969 17.148438 L 11.789062 17.148438 L 11.777344 17.140625 L 11.738281 17.121094 C 11.703125 17.101562 11.652344 17.074219 11.589844 17.039062 C 11.46875 16.96875 11.289062 16.867188 11.078125 16.730469 C 10.660156 16.464844 10.097656 16.078125 9.53125 15.59375 C 8.425781 14.640625 7.199219 13.210938 7.199219 11.5 C 7.199219 10.007812 8.410156 8.800781 9.898438 8.800781 C 10.75 8.800781 11.503906 9.191406 12 9.804688 C 12.496094 9.191406 13.25 8.800781 14.101562 8.800781 C 15.589844 8.800781 16.800781 10.007812 16.800781 11.5 C 16.800781 13.210938 15.574219 14.640625 14.46875 15.59375 C 13.902344 16.078125 13.339844 16.464844 12.921875 16.730469 C 12.710938 16.867188 12.53125 16.96875 12.410156 17.039062 C 12.347656 17.074219 12.296875 17.101562 12.261719 17.121094 L 12.222656 17.140625 L 12.210938 17.148438 L 12.207031 17.148438 C 12.078125 17.21875 11.921875 17.21875 11.792969 17.148438 Z M 11.792969 17.148438"
        fill={CUSTOM_SVG_PATH_FILL}
      />
    </CustomSVGWrapper>
  ),
  [IconVariant.CLOCK]: ClockIcon,
  [IconVariant.CLOUD_UPLOAD]: CloudArrowUpIcon,
  [IconVariant.COG]: CogIcon,
  [IconVariant.COMPUTER_DESKTOP]: ComputerDesktopIcon,
  [IconVariant.CREDIT_CARD]: CreditCardIcon,
  [IconVariant.CREDIT_CARD_FILLED]: CreditCardFilledIcon,
  [IconVariant.CUBE]: CubeIcon,
  [IconVariant.CURRENCY_DOLLAR]: CurrencyDollarIcon,
  [IconVariant.CURRENT_LOCATION]: (props: IconSVGProps) => (
    <CustomSVGWrapper {...props}>
      <path
        d="M12,8 C9.79,8 8,9.79 8,12 C8,14.21 9.79,16 12,16 C14.21,16 16,14.21 16,12 C16,9.79 14.21,8 12,8 Z M20.94,11 C20.48,6.83 17.17,3.52 13,3.06 L13,2 C13,1.45 12.55,1 12,1 C11.45,1 11,1.45 11,2 L11,3.06 C6.83,3.52 3.52,6.83 3.06,11 L2,11 C1.45,11 1,11.45 1,12 C1,12.55 1.45,13 2,13 L3.06,13 C3.52,17.17 6.83,20.48 11,20.94 L11,22 C11,22.55 11.45,23 12,23 C12.55,23 13,22.55 13,22 L13,20.94 C17.17,20.48 20.48,17.17 20.94,13 L22,13 C22.55,13 23,12.55 23,12 C23,11.45 22.55,11 22,11 L20.94,11 Z M12,19 C8.13,19 5,15.87 5,12 C5,8.13 8.13,5 12,5 C15.87,5 19,8.13 19,12 C19,15.87 15.87,19 12,19 Z"
        fill={CUSTOM_SVG_PATH_FILL}
      />
    </CustomSVGWrapper>
  ),
  [IconVariant.DIRECTION]: (props: IconSVGProps) => (
    <CustomSVGWrapper {...props}>
      <path
        d="M13.9245 22.9985H13.8581C13.3571 22.9682 12.9391 22.6039 12.8397 22.1109L10.9689 13.0136L1.89156 11.1387C1.2919 11.0193 0.902322 10.4354 1.02142 9.83441C1.09902 9.44281 1.3806 9.1232 1.75872 8.99751L19.4705 3.08427C20.0356 2.85042 20.6829 3.11996 20.9162 3.6863C21.0223 3.94379 21.0278 4.23184 20.9317 4.49324L15.0315 22.2441C14.8732 22.716 14.4206 23.0244 13.9245 22.9985L13.9245 22.9985ZM6.397 9.79629L12.1423 10.9723C12.5814 11.0572 12.9265 11.3986 13.0168 11.8376L14.1791 17.5956L18.0757 5.89111L6.397 9.79629Z"
        fill={CUSTOM_SVG_PATH_FILL}
      />
    </CustomSVGWrapper>
  ),
  [IconVariant.DOCUMENT]: DocumentIcon,
  [IconVariant.DOCUMENT_CHECK]: DocumentCheckIcon,
  [IconVariant.DOCUMENT_TEXT]: DocumentTextIcon,
  [IconVariant.DOWNLOAD]: ArrowDownTrayIcon,
  [IconVariant.DOTS_HORIZONTAL]: EllipsisHorizontalIcon,
  [IconVariant.EXCLAMATION_CIRCLE]: ExclamationCircleIcon,
  [IconVariant.EXCLAMATION_TRIANGLE]: ExclamationTriangleIcon,
  [IconVariant.FILTER]: (props: IconSVGProps) => (
    <CustomSVGWrapper {...props}>
      <path
        d="M3.00049 4.94737C3.00049 4.69611 3.09531 4.45514 3.26409 4.27748C3.43287 4.09981 3.66179 4 3.90049 4H17.1005C17.3392 4 17.5681 4.09981 17.7369 4.27748C17.9057 4.45514 18.0005 4.69611 18.0005 4.94737C18.0005 5.19863 17.9057 5.43959 17.7369 5.61726C17.5681 5.79493 17.3392 5.89474 17.1005 5.89474H3.90049C3.66179 5.89474 3.43287 5.79493 3.26409 5.61726C3.09531 5.43959 3.00049 5.19863 3.00049 4.94737ZM5.40049 10C5.40049 9.74874 5.49531 9.50778 5.66409 9.33011C5.83288 9.15244 6.06179 9.05263 6.30049 9.05263H14.7005C14.9392 9.05263 15.1681 9.15244 15.3369 9.33011C15.5057 9.50778 15.6005 9.74874 15.6005 10C15.6005 10.2513 15.5057 10.4922 15.3369 10.6699C15.1681 10.8476 14.9392 10.9474 14.7005 10.9474H6.30049C6.06179 10.9474 5.83288 10.8476 5.66409 10.6699C5.49531 10.4922 5.40049 10.2513 5.40049 10ZM7.80049 15.0526C7.80049 14.8014 7.89531 14.5604 8.06409 14.3827C8.23287 14.2051 8.46179 14.1053 8.70049 14.1053H12.3005C12.5392 14.1053 12.7681 14.2051 12.9369 14.3827C13.1057 14.5604 13.2005 14.8014 13.2005 15.0526C13.2005 15.3039 13.1057 15.5449 12.9369 15.7225C12.7681 15.9002 12.5392 16 12.3005 16H8.70049C8.46179 16 8.23287 15.9002 8.06409 15.7225C7.89531 15.5449 7.80049 15.3039 7.80049 15.0526Z"
        fill={CUSTOM_SVG_PATH_FILL}
      />
    </CustomSVGWrapper>
  ),
  [IconVariant.FLIP]: ArrowPathIcon,
  [IconVariant.GLOBE_ALT]: GlobeAltIcon,
  [IconVariant.HEART_BEAT]: (props: IconSVGProps) => (
    <CustomSVGWrapper {...props}>
      <path
        d="M11.9904 21.2707C11.3198 21.2707 10.672 21.016 10.1633 20.5065L4.84352 15.204C4.45034 14.8103 4.45034 14.1851 4.84352 13.7915C5.2367 13.3979 5.86126 13.3979 6.25442 13.7915L11.5742 19.0941C11.8055 19.3257 12.1755 19.3257 12.4068 19.0941L19.577 11.9159C19.5999 11.8928 19.6231 11.8697 19.6231 11.8697C20.5021 10.9898 20.9646 9.85522 20.9646 8.60475C20.9646 7.37751 20.4788 6.21974 19.6231 5.33979C17.819 3.53367 14.9047 3.53367 13.1237 5.33979C13.1007 5.36289 13.1007 5.36289 13.0774 5.38615L12.6842 5.77977C12.5223 5.96503 12.2678 6.08084 11.9903 6.08084C11.7359 6.08084 11.4815 5.96503 11.2734 5.77977L10.8339 5.33978C9.02976 3.53366 6.11546 3.53366 4.33449 5.33978C3.01604 6.65972 2.64609 8.65092 3.34001 10.3413H7.01755L8.54396 7.70163C8.72902 7.40056 9.05281 7.19221 9.39971 7.19221C9.74658 7.19221 10.0936 7.37747 10.2555 7.70163L12.9846 12.4716L13.9097 10.8508C14.0948 10.5497 14.4186 10.3414 14.7655 10.3414H16.6389C17.1941 10.3414 17.6334 10.7814 17.6334 11.337C17.6334 11.8928 17.1939 12.3326 16.6389 12.3326H15.3437L13.8404 14.9723C13.6553 15.2734 13.3315 15.4817 12.9846 15.4817C12.6378 15.4817 12.2907 15.2965 12.1289 14.9723L9.39987 10.2254L8.47478 11.8463C8.28973 12.1473 7.96593 12.3557 7.61903 12.3557H2.7156C2.34549 12.3557 2.02168 12.1704 1.83664 11.8463C0.402661 9.27603 0.842161 6.03427 2.92372 3.95029C5.4217 1.44963 9.39996 1.35692 11.9904 3.67247C14.5809 1.35693 18.5592 1.44947 21.0572 3.92719C22.3061 5.1775 23 6.82161 23 8.60458C23 10.3412 22.3293 11.9854 21.1033 13.2357L21.057 13.282L13.8175 20.5065C13.3088 21.0159 12.661 21.2706 11.9904 21.2706L11.9904 21.2707Z"
        fill={CUSTOM_SVG_PATH_FILL}
      />
    </CustomSVGWrapper>
  ),
  [IconVariant.HOME]: HomeIcon,
  [IconVariant.HOSPITAL_COLOR]: (props: IconSVGProps) => {
    return (
      <CustomSVGWrapper height="64" viewBox="0 0 64 64" width="64" {...props}>
        <path
          d="M27.1483 27.3208V49.9542H8.12744V29.4542L10.2159 27.3208H27.1483Z"
          fill="#00B2FF"
          stroke="#181B25"
          strokeWidth="0.992593"
          strokeLinejoin="bevel"
        />
        <path
          d="M55.8726 29.4542V49.9542H36.8511V27.3208H53.7835L55.8726 29.4542Z"
          fill="#00B2FF"
          stroke="#181B25"
          strokeWidth="0.992593"
          strokeLinejoin="bevel"
        />
        <path
          d="M46.3619 13.9971H17.6376V49.9548H46.3619V13.9971Z"
          fill="#074ADF"
          stroke="#181B25"
          strokeWidth="0.992593"
          strokeLinejoin="bevel"
        />
        <path
          d="M37.488 20.9124V25.533H34.5699V28.8306H29.3927V25.533H26.5112V20.9124H29.3927V17.6142H34.5699V20.9124H37.488Z"
          fill="#FC5C50"
          stroke="#181B25"
          strokeWidth="0.992593"
          strokeLinejoin="bevel"
        />
        <path
          d="M35.5173 38.6375H28.4827V49.9548H35.5173V38.6375Z"
          fill="#F6F3EB"
          stroke="#181B25"
          strokeWidth="0.992593"
          strokeLinejoin="bevel"
        />
      </CustomSVGWrapper>
    );
  },
  [IconVariant.INFORMATION_CIRCLE]: InformationCircleIcon,
  [IconVariant.EXTERNAL_LINK]: ArrowTopRightOnSquareIcon,
  [IconVariant.LINK]: LinkIcon,
  [IconVariant.LIST]: (props: IconSVGProps) => (
    <CustomSVGWrapper {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 17V19H8V17H22ZM22 11V13H8V11H22ZM22 5V7H8V5H22ZM4 20C2.89543 20 2 19.1046 2 18C2 16.8954 2.89543 16 4 16C5.10457 16 6 16.8954 6 18C6 19.1046 5.10457 20 4 20ZM4 14C2.89543 14 2 13.1046 2 12C2 10.8954 2.89543 10 4 10C5.10457 10 6 10.8954 6 12C6 13.1046 5.10457 14 4 14ZM4 8C2.89543 8 2 7.10457 2 6C2 4.89543 2.89543 4 4 4C5.10457 4 6 4.89543 6 6C6 7.10457 5.10457 8 4 8Z"
        fill={CUSTOM_SVG_PATH_FILL}
      />
    </CustomSVGWrapper>
  ),
  [IconVariant.LOCATION_MARKER]: MapPinIcon,
  [IconVariant.LOGOUT]: ArrowRightOnRectangleIcon,
  [IconVariant.MAIL]: EnvelopeIcon,
  [IconVariant.MAIL_BOX]: (props: IconSVGProps) => (
    <CustomSVGWrapper {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.8748 12.4241C6.8748 12.8041 6.56731 13.1115 6.1873 13.1115H3.4373C3.0573 13.1115 2.7498 12.8041 2.7498 12.4241C2.7498 12.044 3.0573 11.7366 3.4373 11.7366H6.1873C6.56731 11.7366 6.8748 12.044 6.8748 12.4241ZM20.45 17.4499H9.6248V11.0481C9.6248 9.70224 9.06773 8.59467 8.17364 7.72012H11.6462V10.6013C11.6462 10.9811 11.9537 11.2888 12.3337 11.2888C12.7139 11.2888 13.0212 10.9811 13.0212 10.6013V7.72012H17.1873C18.819 7.72012 20.385 9.44792 20.45 11.0481V17.4499ZM8.06294 17.4499H1.56V11.0484C1.56 9.29988 2.91706 7.72012 4.8123 7.72012C6.70754 7.72012 8.06294 9.29988 8.06294 11.0481V17.4499ZM13.0212 3.5116H15.3847V2.30847H13.0212V3.5116ZM17.1873 6.19988H13.0212V4.8866H16.0722C16.4522 4.8866 16.7597 4.5791 16.7597 4.1991V1.62097C16.7597 1.24097 16.4522 0.933472 16.0722 0.933472H12.3337C11.9537 0.933472 11.6462 1.24097 11.6462 1.62097V6.19988C11.6462 6.19988 7.46561 6.19988 4.8125 6.19988C2.15939 6.19988 0 8.3953 0 11.0484V18.3791C0 18.7591 0.307494 19.0666 0.6875 19.0666H21.3125C21.6925 19.0666 22 18.7591 22 18.3791V11.0484C22 8.3953 19.8404 6.19988 17.1873 6.19988Z"
        fill={CUSTOM_SVG_PATH_FILL}
      />
    </CustomSVGWrapper>
  ),
  [IconVariant.MAP]: MapIcon,
  [IconVariant.MEMBER_CARD]: (props: IconSVGProps) => (
    <CustomSVGWrapper {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 8C2 5.79087 3.79087 4 6 4H18C20.2092 4 22 5.79088 22 8V16C22 18.2092 20.2092 20 18 20H6C3.79088 20 2 18.2092 2 16V8ZM6 6C4.89543 6 4 6.89543 4 8V16C4 17.1046 4.89542 18 6 18H18C19.1046 18 20 17.1046 20 16V8C20 6.89542 19.1046 6 18 6H6ZM6 9C6 8.44772 6.44772 8 7 8H11C11.5523 8 12 8.44772 12 9V15C12 15.5523 11.5523 16 11 16H7C6.44772 16 6 15.5523 6 15V9ZM8 10V14H10V10H8ZM13.5 9C13.5 8.44772 13.9477 8 14.5 8H17C17.5523 8 18 8.44772 18 9C18 9.55228 17.5523 10 17 10H14.5C13.9477 10 13.5 9.55228 13.5 9ZM13.5 12C13.5 11.4477 13.9477 11 14.5 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H14.5C13.9477 13 13.5 12.5523 13.5 12ZM13.5 15C13.5 14.4477 13.9477 14 14.5 14H17C17.5523 14 18 14.4477 18 15C18 15.5523 17.5523 16 17 16H14.5C13.9477 16 13.5 15.5523 13.5 15Z"
        fill={CUSTOM_SVG_PATH_FILL}
      />
    </CustomSVGWrapper>
  ),
  [IconVariant.MENU]: Bars3Icon,
  [IconVariant.NEEDLE]: (props: IconSVGProps) => (
    <CustomSVGWrapper {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8461 8.229C21.4556 8.61952 20.8224 8.61952 20.4319 8.229L18.8783 6.67545L17.1945 8.35923L19.4702 10.6349L9.80813 20.297L7.53214 18.021L3.70711 21.8461C3.31658 22.2366 2.68342 22.2366 2.29289 21.8461C1.90237 21.4555 1.90237 20.8224 2.29289 20.4318L6.11793 16.6068L3.88846 14.3774L13.5506 4.71525L15.7803 6.94501L17.4641 5.26124L15.91 3.70711C15.5194 3.31658 15.5194 2.68342 15.91 2.29289C16.3005 1.90237 16.9337 1.90237 17.3242 2.29289L21.8461 6.81478C22.2366 7.20531 22.2366 7.83847 21.8461 8.229ZM15.7674 11.5094L16.6418 10.6349L13.5506 7.54365L6.71686 14.3774L9.80813 17.4686L11.371 15.9057L10.1693 14.704C9.89597 14.4306 9.89597 13.9874 10.1693 13.7141C10.4427 13.4407 10.8859 13.4407 11.1593 13.7141L12.361 14.9158L13.0742 14.2025L11.8725 13.0008C11.5991 12.7274 11.5991 12.2842 11.8725 12.0109C12.1459 11.7375 12.5891 11.7375 12.8625 12.0109L14.0642 13.2126L14.7774 12.4994L13.5757 11.2976C13.3023 11.0243 13.3023 10.5811 13.5757 10.3077C13.8491 10.0343 14.2923 10.0343 14.5656 10.3077L15.7674 11.5094Z"
        fill={CUSTOM_SVG_PATH_FILL}
      />
    </CustomSVGWrapper>
  ),
  [IconVariant.QUESTION_MARK_CIRCLE]: QuestionMarkCircleIcon,
  [IconVariant.QUESTION_MARK_CIRCLE_FILLED]: QuestionMarkCircleFilledIcon,
  [IconVariant.BUILDING_OFFICE]: BuildingOfficeIcon,
  [IconVariant.BUILDING_OFFICE_2_COLOR]: (props: IconSVGProps) => (
    <CustomSVGWrapper {...props}>
      <path
        fillRule="evenodd"
        d="M3 2.25a.75.75 0 000 1.5v16.5h-.75a.75.75 0 000 1.5H15v-18a.75.75 0 000-1.5H3zM6.75 19.5v-2.25a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v2.25a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75zM6 6.75A.75.75 0 016.75 6h.75a.75.75 0 010 1.5h-.75A.75.75 0 016 6.75zM6.75 9a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zM6 12.75a.75.75 0 01.75-.75h.75a.75.75 0 010 1.5h-.75a.75.75 0 01-.75-.75zM10.5 6a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zm-.75 3.75A.75.75 0 0110.5 9h.75a.75.75 0 010 1.5h-.75a.75.75 0 01-.75-.75zM10.5 12a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zM16.5 6.75v15h5.25a.75.75 0 000-1.5H21v-12a.75.75 0 000-1.5h-4.5zm1.5 4.5a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008zm.75 2.25a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75v-.008a.75.75 0 00-.75-.75h-.008zM18 17.25a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008z"
        clipRule="evenodd"
        fill="#00AEFF"
        stroke="#181B25"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
    </CustomSVGWrapper>
  ),
  [IconVariant.BUILDING_OFFICE_COLOR]: (props: IconSVGProps) => (
    <CustomSVGWrapper {...props}>
      <path
        fillRule="evenodd"
        d="M4.5 2.25a.75.75 0 000 1.5v16.5h-.75a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5h-.75V3.75a.75.75 0 000-1.5h-15zM9 6a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5H9zm-.75 3.75A.75.75 0 019 9h1.5a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM9 12a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5H9zm3.75-5.25A.75.75 0 0113.5 6H15a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zM13.5 9a.75.75 0 000 1.5H15A.75.75 0 0015 9h-1.5zm-.75 3.75a.75.75 0 01.75-.75H15a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zM9 19.5v-2.25a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75v2.25a.75.75 0 01-.75.75h-4.5A.75.75 0 019 19.5z"
        clipRule="evenodd"
        fill="#074ADF"
        stroke="#181B25"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
    </CustomSVGWrapper>
  ),
  [IconVariant.VIRTUAL_CARE_COLOR]: (props: IconSVGProps) => (
    <CustomSVGWrapper {...props} viewBox="0 0 31 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6251 10.5C11.6251 8.63604 13.1362 7.125 15.0001 7.125C16.8641 7.125 18.3751 8.63604 18.3751 10.5C18.3751 12.364 16.8641 13.875 15.0001 13.875C13.1362 13.875 11.6251 12.364 11.6251 10.5Z"
        fill="#00B2FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.81356 21.079C8.87157 17.7117 11.6191 15 15.0001 15C18.3812 15 21.1288 17.7118 21.1867 21.0792C21.1905 21.3026 21.0619 21.507 20.8589 21.6002C19.0747 22.4188 17.09 22.875 15.0004 22.875C12.9105 22.875 10.9257 22.4188 9.14137 21.5999C8.93836 21.5068 8.80971 21.3023 8.81356 21.079Z"
        fill="#00B2FF"
      />
      <path
        d="M8.81356 21.079L9.16351 21.085L9.16351 21.085L8.81356 21.079ZM21.1867 21.0792L21.5366 21.0732L21.5366 21.0732L21.1867 21.0792ZM20.8589 21.6002L21.0048 21.9183L21.0048 21.9183L20.8589 21.6002ZM9.14137 21.5999L8.99539 21.918L8.99539 21.918L9.14137 21.5999ZM15.0001 6.775C12.9429 6.775 11.2751 8.44274 11.2751 10.5H11.9751C11.9751 8.82934 13.3295 7.475 15.0001 7.475V6.775ZM18.7251 10.5C18.7251 8.44274 17.0574 6.775 15.0001 6.775V7.475C16.6708 7.475 18.0251 8.82934 18.0251 10.5H18.7251ZM15.0001 14.225C17.0574 14.225 18.7251 12.5573 18.7251 10.5H18.0251C18.0251 12.1707 16.6708 13.525 15.0001 13.525V14.225ZM11.2751 10.5C11.2751 12.5573 12.9429 14.225 15.0001 14.225V13.525C13.3295 13.525 11.9751 12.1707 11.9751 10.5H11.2751ZM15.0001 14.65C11.4278 14.65 8.5249 17.5151 8.46361 21.073L9.16351 21.085C9.21823 17.9083 11.8104 15.35 15.0001 15.35V14.65ZM21.5366 21.0732C21.4755 17.5152 18.5725 14.65 15.0001 14.65V15.35C18.1899 15.35 20.7821 17.9084 20.8367 21.0853L21.5366 21.0732ZM21.0048 21.9183C21.3342 21.7671 21.5429 21.4355 21.5366 21.0732L20.8367 21.0853C20.8382 21.1696 20.7896 21.2469 20.7129 21.282L21.0048 21.9183ZM15.0004 23.225C17.1414 23.225 19.1758 22.7575 21.0048 21.9183L20.7129 21.282C18.9736 22.0802 17.0386 22.525 15.0004 22.525V23.225ZM8.99539 21.918C10.8246 22.7575 12.8591 23.225 15.0004 23.225V22.525C12.9619 22.525 11.0268 22.0801 9.28735 21.2818L8.99539 21.918ZM8.46361 21.073C8.45737 21.4353 8.66607 21.7669 8.99539 21.918L9.28735 21.2818C9.21066 21.2466 9.16206 21.1694 9.16351 21.085L8.46361 21.073Z"
        fill="black"
      />
      <path
        d="M3 18.5H27V19.3C27 21.5091 25.2091 23.3 23 23.3H7C4.79086 23.3 3 21.5091 3 19.3V18.5Z"
        fill="#074ADF"
      />
      <path
        d="M11 23V24.3431C11 25.404 10.5786 26.4214 9.82843 27.1716L9 28H21L20.1716 27.1716C19.4214 26.4214 19 25.404 19 24.3431V23M27 7V20C27 21.6569 25.6569 23 24 23H6C4.34315 23 3 21.6569 3 20V7M27 7C27 5.34315 25.6569 4 24 4H6C4.34315 4 3 5.34315 3 7M27 7V16C27 17.6569 25.6569 19 24 19H6C4.34315 19 3 17.6569 3 16V7"
        stroke="#0F172A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </CustomSVGWrapper>
  ),
  [IconVariant.TEAM_2_COLOR]: (props: IconSVGProps) => (
    <CustomSVGWrapper {...props} viewBox="0 0 64 64">
      <path
        d="M54.7685 26.2859C54.7685 29.8796 51.8552 32.793 48.2614 32.793C44.6676 32.793 41.7542 29.8796 41.7542 26.2859C41.7542 22.6921 44.6676 19.7787 48.2614 19.7787C51.8552 19.7787 54.7685 22.6921 54.7685 26.2859ZM34.4741 50.9341C33.607 50.2611 33.2641 49.1203 33.6514 48.1107C35.9055 42.2348 41.6004 38.0644 48.2684 38.0644C54.9328 38.0644 60.6251 42.2302 62.8817 48.1011C63.2696 49.1105 62.9275 50.2515 62.0608 50.9251C58.2499 53.8868 53.4622 55.6501 48.2616 55.6501C43.0664 55.6501 38.2832 53.8903 34.4741 50.9341Z"
        fill="#074ADF"
        stroke="black"
        strokeWidth="0.7"
      />
      <path
        d="M21.6254 26.2859C21.6254 29.8796 18.7121 32.793 15.1183 32.793C11.5245 32.793 8.61116 29.8796 8.61116 26.2859C8.61116 22.6921 11.5245 19.7787 15.1183 19.7787C18.7121 19.7787 21.6254 22.6921 21.6254 26.2859ZM1.33106 50.9341C0.463896 50.2611 0.121041 49.1203 0.508332 48.1107C2.76243 42.2348 8.45729 38.0644 15.1253 38.0644C21.7897 38.0644 27.482 42.2302 29.7386 48.1011C30.1266 49.1105 29.7845 50.2515 28.9177 50.9251C25.1068 53.8868 20.3191 55.6501 15.1186 55.6501C9.92333 55.6501 5.14009 53.8903 1.33106 50.9341Z"
        fill="#FC5C50"
        stroke="black"
        strokeWidth="0.7"
      />
      <path
        d="M41.2501 15.9994C41.2501 21.108 37.1087 25.2494 32.0001 25.2494C26.8915 25.2494 22.7501 21.108 22.7501 15.9994C22.7501 10.8908 26.8915 6.74941 32.0001 6.74941C37.1087 6.74941 41.2501 10.8908 41.2501 15.9994ZM12.6121 50.6176C11.352 49.6396 10.85 47.9782 11.4154 46.5041C14.5912 38.2254 22.6148 32.3494 32.0099 32.3494C41.3999 32.3494 49.4198 38.219 52.5992 46.4906C53.1656 47.9643 52.6647 49.626 51.4052 50.6049C46.0462 54.7696 39.3134 57.2494 32.0005 57.2494C24.695 57.2494 17.9685 54.7747 12.6121 50.6176Z"
        fill="#00AEFF"
        stroke="black"
        strokeWidth="0.7"
      />
    </CustomSVGWrapper>
  ),
  [IconVariant.PENCIL]: PencilIcon,
  [IconVariant.PILL]: (props: IconSVGProps) => (
    <CustomSVGWrapper {...props}>
      <path
        fill={CUSTOM_SVG_PATH_FILL}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5476 2.29747C12.5002 0.344852 15.666 0.344853 17.6186 2.29747C19.5712 4.25009 19.5712 7.41593 17.6186 9.36851L9.369 17.6181C7.41641 19.5708 4.25058 19.5708 2.29796 17.6181C0.345341 15.6655 0.34534 12.4997 2.29796 10.5471L10.5476 2.29747ZM16.4401 8.19003L12.9046 11.7256L8.19052 7.01152L11.7261 3.47598C13.0278 2.17424 15.1383 2.17424 16.4401 3.47598C17.7418 4.77773 17.7418 6.88828 16.4401 8.19003Z"
      />
    </CustomSVGWrapper>
  ),
  [IconVariant.PIN]: (props: IconSVGProps) => (
    <CustomSVGWrapper {...props}>
      <path
        d="M20.4986 7.67481L16.5006 3.65481C15.7486 2.89881 14.4376 2.89081 13.6706 3.64881C13.4746 3.84481 13.3206 4.08481 13.2526 4.27781C12.5996 5.63981 11.8986 6.49281 10.9986 7.00481L10.7816 7.10981C9.81356 7.59481 8.49656 8.08881 6.06256 8.08881C5.79656 8.08881 5.54156 8.14081 5.29656 8.24081C4.81256 8.44281 4.41756 8.83581 4.21456 9.32481C4.01556 9.80881 4.01556 10.3658 4.21456 10.8498C4.31856 11.0988 4.46456 11.3208 4.64956 11.5008L7.88456 14.7358L3.2009 19.4195L4.73139 20.95L9.41456 16.2668L12.6416 19.4938C12.8276 19.6828 13.0476 19.8328 13.2976 19.9348C13.5446 20.0378 13.8006 20.0888 14.0636 20.0888C14.3266 20.0888 14.5826 20.0368 14.8286 19.9348C15.3266 19.7298 15.7116 19.3428 15.9086 18.8568C16.0116 18.6148 16.0636 18.3498 16.0636 18.0888C16.0636 15.6528 16.5576 14.3368 17.0416 13.3678C17.5376 12.3758 18.4106 11.6198 19.7956 10.9538C20.0666 10.8498 20.3056 10.6978 20.5066 10.4968C21.2786 9.71481 21.2746 8.44581 20.4986 7.67481ZM15.2506 12.4758C14.4316 14.1188 14.0626 15.8458 14.0556 18.0798L6.06256 10.0888C8.20156 10.0888 9.87656 9.75381 11.4586 9.00481L11.6936 8.89981C13.0926 8.20081 14.1616 7.00681 15.0816 5.06581L19.0056 9.11681C17.1426 10.0098 15.9496 11.0768 15.2506 12.4758Z"
        fill={CUSTOM_SVG_PATH_FILL}
      />
    </CustomSVGWrapper>
  ),
  [IconVariant.PLUS]: PlusIcon,
  [IconVariant.PRINT]: PrinterIcon,
  [IconVariant.MINUS]: MinusIcon,
  [IconVariant.PAPER_CLIP]: PaperClipIcon,
  [IconVariant.PHONE]: PhoneIcon,
  [IconVariant.RECTANGLE_STACK]: RectangleStackIcon,
  [IconVariant.SCAN]: (props: IconSVGProps) => (
    <CustomSVGWrapper {...props}>
      <path
        d="M3 4V9H5V5H9V3H4C3.447 3 3 3.447 3 4ZM21 9V4C21 3.447 20.553 3 20 3H15V5H19V9H21ZM19 19H15V21H20C20.553 21 21 20.553 21 20V15H19V19ZM9 21V19H5V15H3V20C3 20.553 3.447 21 4 21H9ZM2 11H22V13H2V11Z"
        fill={CUSTOM_SVG_PATH_FILL}
      />
    </CustomSVGWrapper>
  ),
  [IconVariant.SEARCH]: MagnifyingGlassIcon,
  [IconVariant.STETHOSCOPE]: (props: IconSVGProps) => (
    <CustomSVGWrapper {...props}>
      <path
        d="M19.9803 16.4618C19.4371 15.9584 18.7224 15.7345 17.9792 15.9024C17.1215 16.0702 16.4068 16.7416 16.2354 17.6087C16.1211 18.1681 16.2354 18.7556 16.5213 19.2311L16.0926 19.5948C15.1207 20.434 13.7771 20.6297 12.6907 20.1263C11.6042 19.6229 10.9183 18.5599 10.9183 17.413L10.9181 14.8115C13.8911 14.4199 16.1782 11.9024 16.1782 8.93723V3.84624C16.1782 2.83923 15.3205 2 14.2913 2H13.6339C13.1764 2 12.805 2.3637 12.805 2.8111C12.805 3.25871 13.1767 3.62221 13.6339 3.62221H14.2913C14.4056 3.62221 14.5201 3.73401 14.5201 3.84601V8.93701C14.5201 11.3148 12.5475 13.2728 10.089 13.2728C7.65895 13.2728 5.65789 11.3426 5.65789 8.93701L5.65809 3.84601C5.65809 3.73421 5.77235 3.62221 5.88682 3.62221H6.54427C7.00172 3.62221 7.3732 3.2585 7.3732 2.8111C7.3732 2.3635 7.00151 2 6.54427 2H5.88682C4.85767 2 4 2.83923 4 3.84624V8.93673C4 11.9018 6.28704 14.4193 9.26007 14.811V17.2726C9.26007 18.5034 9.71751 19.6504 10.518 20.5175C11.4041 21.4685 12.662 22 13.9771 22C15.1205 22 16.2354 21.5805 17.0931 20.8531L17.865 20.1817C18.2367 20.2656 18.6369 20.2656 19.0084 20.1538C19.8661 19.93 20.495 19.2307 20.638 18.3915C20.7522 17.6922 20.5235 16.9649 19.9803 16.4613L19.9803 16.4618ZM19.0369 18.0563C19.0369 18.364 18.7796 18.6438 18.4364 18.6438C18.0935 18.6438 17.836 18.3921 17.836 18.0563C17.836 17.7486 18.0932 17.4688 18.4364 17.4688C18.7796 17.4688 19.0369 17.7205 19.0369 18.0563Z"
        fill={CUSTOM_SVG_PATH_FILL}
      />
    </CustomSVGWrapper>
  ),
  [IconVariant.STETHOSCOPE_COLOR]: (props: IconSVGProps) => (
    <CustomSVGWrapper height="64" viewBox="0 0 64 64" width="64" {...props}>
      <path
        d="M51.812 34.9739C50.7567 34.9738 49.7276 34.6486 48.868 34.0436C48.0084 33.4386 47.3609 32.5838 47.0157 31.5981C46.6704 30.6125 46.6445 29.545 46.9416 28.5441C47.2386 27.5432 47.8439 26.6588 48.6732 26.0137C49.5025 25.3686 50.5145 24.9951 51.5686 24.9449C52.6227 24.8948 53.6664 25.1706 54.5545 25.734C55.4426 26.2974 56.131 27.1204 56.5239 28.0884C56.9168 29.0565 56.9947 30.1215 56.7468 31.1354V31.1354C56.4777 32.2289 55.8452 33.2015 54.9504 33.8975C54.0557 34.5934 52.9506 34.9725 51.812 34.9739Z"
        fill="#F6F3EB"
      />
      <path
        d="M8.40975 26.77L15.3339 38.4638L14.4972 38.9472L7.11111 26.4737L9.93581 24.6166C9.94113 24.37 10.0101 24.1288 10.1362 23.9159C10.2622 23.703 10.4412 23.5255 10.6562 23.4001C11.3352 22.9908 12.1919 23.1549 12.5699 23.7673C12.9479 24.3797 12.7036 25.208 12.0246 25.6192C11.4541 25.9634 10.7576 25.9021 10.3254 25.51L8.40975 26.77Z"
        fill="#FC5C50"
        stroke="#181B25"
        strokeWidth="0.711111"
        strokeMiterlimit="10"
      />
      <path
        d="M27.7511 30.8798L26.9325 31.3907L19.5826 19.9599L17.5694 21.0666C17.7238 21.6254 17.4602 22.2652 16.8897 22.6094C16.2107 23.0188 15.3539 22.854 14.976 22.2422C14.598 21.6305 14.8422 20.8016 15.5213 20.3903C15.7319 20.259 15.9729 20.1828 16.2216 20.1689C16.4703 20.1551 16.7184 20.204 16.9427 20.3111L19.9095 18.6827L27.7511 30.8798Z"
        fill="#FC5C50"
        stroke="#181B25"
        strokeWidth="0.711111"
        strokeMiterlimit="10"
      />
      <path
        d="M51.2693 25.6493L49.5966 26.6174C48.6843 25.0771 47.7417 23.4851 45.3182 20.8701C41.5366 16.7876 38.2313 17.8387 36.596 18.8266C35.4692 19.5093 34.5699 20.856 34.1292 22.5221C33.7002 24.1441 33.7707 25.8562 34.3166 27.1008C35.3361 29.4227 36.6354 30.9611 37.8921 32.449C39.3968 34.23 40.9526 36.0717 41.9146 39.1165C42.4961 40.9576 42.4625 42.8286 41.8164 44.5279C41.1703 46.2272 39.9615 47.7145 38.2229 48.9476C37.5968 49.3946 36.7026 49.7778 35.4899 49.7778C34.451 49.7778 33.1782 49.4936 31.6392 48.7171C29.5821 47.6787 27.8919 46.1863 27.5773 45.8971C26.4127 44.8344 25.424 43.5978 24.6473 42.2322C23.5452 42.7363 22.345 42.9961 21.1306 42.9934C19.6092 42.9922 18.115 42.5941 16.7991 41.8394C15.4831 41.0846 14.392 40 13.636 38.6951L15.6001 37.5877C16.0233 38.3244 16.5937 38.9683 17.2765 39.4803C17.9594 39.9924 18.7404 40.3618 19.5722 40.5661C21.1959 40.9748 22.9093 40.7315 24.2738 39.8982C25.6868 39.0354 26.6844 37.5718 27.01 35.8833C27.1764 35.0261 27.1612 34.1441 26.9653 33.2929C26.7695 32.4417 26.3973 31.6399 25.8722 30.9381L27.6858 29.6028C28.3978 30.5533 28.9028 31.6394 29.1688 32.7925C29.4348 33.9456 29.456 35.1406 29.2313 36.3023C28.8669 38.2307 27.8259 39.9704 26.2916 41.2149C26.9756 42.4364 27.8529 43.5419 28.8901 44.4896C29.175 44.7482 30.6882 46.0861 32.5211 47.012C34.5007 48.0114 36.0384 48.1385 37.0922 47.3914C39.9408 45.3709 40.9959 42.6351 40.0642 39.6881C39.2172 37.006 37.8507 35.3891 36.4041 33.677C35.0596 32.085 33.6686 30.4387 32.5386 27.8658C31.815 26.2164 31.7109 24.0931 32.2543 22.04C32.8203 19.9001 34.0336 18.1344 35.5829 17.1956C37.5858 15.9823 39.6914 15.6905 41.8364 16.3291C43.5537 16.84 45.2516 17.9639 46.7467 19.5776C49.1262 22.1434 50.1219 23.7125 51.2693 25.6493Z"
        fill="#00B2FF"
        stroke="#181B25"
        strokeWidth="0.711111"
        strokeMiterlimit="10"
      />
      <path
        d="M52.9376 30.2271C53.0877 29.6145 52.7071 28.9977 52.0874 28.8493C51.4676 28.7009 50.8435 29.0771 50.6934 29.6896C50.5432 30.3021 50.9239 30.919 51.5436 31.0674C52.1633 31.2158 52.7874 30.8396 52.9376 30.2271Z"
        fill="#FC5C50"
        stroke="#181B25"
        strokeWidth="0.711111"
        strokeMiterlimit="10"
      />
      <path
        d="M51.812 34.9739C50.7567 34.9738 49.7276 34.6486 48.868 34.0436C48.0084 33.4386 47.3609 32.5838 47.0157 31.5981C46.6704 30.6125 46.6445 29.545 46.9416 28.5441C47.2386 27.5432 47.8439 26.6588 48.6732 26.0137C49.5025 25.3686 50.5145 24.9951 51.5686 24.9449C52.6227 24.8948 53.6664 25.1706 54.5545 25.734C55.4426 26.2974 56.131 27.1204 56.5239 28.0884C56.9168 29.0565 56.9947 30.1215 56.7468 31.1354V31.1354C56.4777 32.2289 55.8452 33.2015 54.9504 33.8975C54.0557 34.5934 52.9506 34.9725 51.812 34.9739ZM51.8074 26.8568C51.2186 26.8577 50.6418 27.0223 50.143 27.3318C49.6443 27.6413 49.2437 28.0832 48.9871 28.6071C48.7305 29.131 48.6281 29.7157 48.6917 30.2943C48.7554 30.873 48.9825 31.4223 49.347 31.8794C49.7115 32.3366 50.1988 32.6831 50.7532 32.8795C51.3076 33.0759 51.9067 33.1141 52.482 32.9899C53.0573 32.8656 53.5857 32.5839 54.0067 32.1769C54.4277 31.77 54.7244 31.2541 54.8628 30.6884V30.6884C54.9598 30.2926 54.977 29.8818 54.9134 29.4794C54.8498 29.0771 54.7067 28.691 54.4921 28.3434C54.2776 27.9957 53.9959 27.6932 53.663 27.4532C53.3302 27.2131 52.9528 27.0402 52.5524 26.9443C52.3085 26.8861 52.0584 26.8567 51.8074 26.8568V26.8568Z"
        fill="#074ADF"
        stroke="#181B25"
        strokeWidth="0.711111"
        strokeMiterlimit="10"
      />
    </CustomSVGWrapper>
  ),
  [IconVariant.TEAM_COLOR]: (props: IconSVGProps) => (
    <CustomSVGWrapper height="64" viewBox="0 0 64 64" width="64" {...props}>
      <path
        d="M45.5129 27.9804C48.5403 27.9804 50.9944 25.5263 50.9944 22.499C50.9944 19.4716 48.5403 17.0175 45.5129 17.0175C42.4856 17.0175 40.0314 19.4716 40.0314 22.499C40.0314 25.5263 42.4856 27.9804 45.5129 27.9804Z"
        fill="#00AEFF"
        stroke="black"
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M53.3458 39.8293V48.003H41.9881V37.5751L40.1238 31.1947L40.4047 30.2276H50.541L53.3458 39.8293Z"
        fill="#00AEFF"
        stroke="black"
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M18.8225 27.9804C21.8499 27.9804 24.304 25.5263 24.304 22.499C24.304 19.4716 21.8499 17.0175 18.8225 17.0175C15.7952 17.0175 13.341 19.4716 13.341 22.499C13.341 25.5263 15.7952 27.9804 18.8225 27.9804Z"
        fill="#074ADB"
        stroke="black"
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M24.1043 31.0975L22.2246 37.5751V48.003H10.928V39.8293L13.6711 30.3739L13.7138 30.2276H23.8501L24.1043 31.0975Z"
        fill="#074ADB"
        stroke="black"
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M32.1369 26.0414C35.1642 26.0414 37.6184 23.5873 37.6184 20.56C37.6184 17.5326 35.1642 15.0785 32.1369 15.0785C29.1095 15.0785 26.6554 17.5326 26.6554 20.56C26.6554 23.5873 29.1095 26.0414 32.1369 26.0414Z"
        fill="#F85C50"
        stroke="black"
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M39.9704 37.8891V49.9413H24.2424V37.8891L27.0281 28.2885H37.1656L39.9704 37.8891Z"
        fill="#F85C50"
        stroke="black"
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M39.9704 37.8891V49.9413H37.1656V28.2885L39.9704 37.8891Z"
        fill="#F3EEE6"
        stroke="black"
        strokeWidth="0.8"
        strokeLinejoin="bevel"
      />
      <path
        d="M53.3458 39.8293V48.003H50.541V30.2276L53.3458 39.8293Z"
        fill="#F3EEE6"
        stroke="black"
        strokeWidth="0.8"
        strokeLinejoin="bevel"
      />
      <path
        d="M13.6711 30.374V48.003H10.928V39.8294L13.6711 30.374Z"
        fill="#F3EEE6"
        stroke="black"
        strokeWidth="0.8"
        strokeLinejoin="bevel"
      />
    </CustomSVGWrapper>
  ),
  [IconVariant.TEST_TUBE]: (props: IconSVGProps) => (
    <CustomSVGWrapper {...props}>
      <path
        d="M13.7493 1.75115C13.3345 2.16599 13.3345 2.83859 13.7493 3.25344L13.8672 3.37137L2.4003 14.8383C1.49722 15.7414 1 16.942 1 18.219C1 19.4961 1.49722 20.6966 2.4003 21.6008C3.30444 22.5028 4.505 23 5.78099 23C7.05699 23 8.25755 22.5028 9.16168 21.5997L20.6286 10.1328L20.7466 10.2507C21.1614 10.6655 21.834 10.6655 22.2489 10.2507C22.6637 9.83584 22.6637 9.16324 22.2489 8.74839L15.2516 1.75115C14.8368 1.3363 14.1642 1.3363 13.7493 1.75115ZM16.3789 11.3779L14.577 9.57529C14.1621 9.16029 13.4894 9.16022 13.0745 9.57513C12.6597 9.98992 12.6596 10.6624 13.0743 11.0773L14.8766 12.8802L13.3743 14.3836L13.0736 14.0829C12.6588 13.6681 11.9862 13.6681 11.5713 14.0829C11.1565 14.4978 11.1565 15.1704 11.5713 15.5852L11.872 15.8859L10.3686 17.3892L8.56619 15.5861C8.15157 15.1713 7.47926 15.171 7.06427 15.5854C6.64898 16.0001 6.64867 16.673 7.06358 17.088L8.86526 18.8905L7.65833 20.0974C6.65432 21.1004 4.9066 21.1004 3.90259 20.0974C3.40112 19.5949 3.12489 18.9287 3.12489 18.219C3.12489 17.5093 3.40112 16.8431 3.90259 16.3406L15.3695 4.87367L19.1253 8.6294L16.3789 11.3779Z"
        fill={CUSTOM_SVG_PATH_FILL}
      />
    </CustomSVGWrapper>
  ),
  [IconVariant.UNPIN]: (props: IconSVGProps) => {
    const id = useMemo(() => generateRandomString(), []);
    return (
      <CustomSVGWrapper {...props}>
        <g clip-path={`url(#${id})`}>
          <path
            d="M14.8329 2.02638L9.16327 2.01082C8.09695 2.008 7.16428 2.92936 7.15791 4.00769C7.15791 4.28488 7.21872 4.56348 7.30711 4.74803C7.80845 6.17285 7.91593 7.2717 7.64157 8.27013L7.56238 8.49782C7.22085 9.52525 6.6389 10.8058 4.9178 12.5269C4.72971 12.715 4.58617 12.9321 4.48364 13.176C4.28423 13.6611 4.28282 14.2183 4.48505 14.7076C4.68658 15.1906 5.08043 15.5844 5.56339 15.786C5.813 15.8885 6.07321 15.9422 6.3313 15.9387L10.9063 15.9387L10.9063 22.5624L13.0707 22.5624L13.0707 15.9394L17.6344 15.9394C17.8996 15.9415 18.1612 15.892 18.4101 15.7874C18.6576 15.6856 18.8747 15.5406 19.0606 15.3546C19.2466 15.1687 19.3909 14.9509 19.4927 14.7048C19.6999 14.2077 19.6985 13.6618 19.4941 13.1789C19.3958 12.9349 19.2452 12.7108 19.0606 12.5262C17.3381 10.8037 16.7569 9.52383 16.4139 8.49641C16.0632 7.44423 16.1459 6.29235 16.6544 4.84208C16.7724 4.57691 16.834 4.30043 16.834 4.01618C16.8269 2.91733 15.9267 2.02284 14.8329 2.02638Z"
            fill={CUSTOM_SVG_PATH_FILL}
          />
        </g>
        <defs>
          <clipPath id={id}>
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </CustomSVGWrapper>
    );
  },
  [IconVariant.TRASH]: TrashIcon,
  [IconVariant.USER]: UserIcon,
  [IconVariant.USERS]: UsersIcon,
  [IconVariant.USER_GROUP]: UserGroupIcon,
  [IconVariant.X]: XMarkIcon,
  [IconVariant.X_CIRCLE]: XCircleIcon,
  [IconVariant.WALLET]: (props: IconSVGProps) => (
    <CustomSVGWrapper {...props}>
      <path
        d="M16 0C17.103 0 18 0.897 18 2V4C19.103 4 20 4.897 20 6V16C20 17.103 19.103 18 18 18H3C1.794 18 0 17.201 0 15V3C0 1.346 1.346 0 3 0H16ZM16 2H3C2.448 2 2 2.449 2 3C2 3.551 2.448 4 3 4H16V2ZM3.012 16H18V6H3C2.647 6 2.314 5.928 2 5.815V15C2 15.805 2.55 15.988 3.012 16ZM16 9H14V13H16V9Z"
        fill={CUSTOM_SVG_PATH_FILL}
      />
    </CustomSVGWrapper>
  ),
};

export type IconProps = CUIComponentProps<{
  ariaHidden?: boolean;
  // use null if you want to use className to set height & width
  size: SpacingVariant | null;
  variant: IconVariant;
}>;

export const Icon: CUIComponent<IconProps> = ({
  ariaHidden = true,
  className,
  size,
  variant,
  testId = `Icon__${variant}`,
}) => {
  const SVG: IconSVG | HeroIconSvgElement = variantSVG[variant];
  return (
    <SVG
      aria-hidden={ariaHidden}
      className={clsx(
        size ? [HEIGHT_CLASS_NAMES[size], WIDTH_CLASS_NAMES[size]] : null,
        className
      )}
      data-testid={testId}
    />
  );
};
