import { Box, Icon, SpacingVariant, TextVariant } from "@chp/curative_ui";
import clsx from "clsx";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { PlainDate } from "../../utils";
import { benefitsStatusConfig } from "./benefitsStatusConfig";

export const BenefitsStatus = ({
  isBeforeBaselineVisitDueDate,
  isBaselineVisitComplete,
  isBaselineVisitRequired,
  memberStartDate,
  memberEndDate,
  shouldDisplayStatusText = true,
}: {
  isBeforeBaselineVisitDueDate: boolean;
  isBaselineVisitComplete: boolean;
  isBaselineVisitRequired: boolean;
  memberStartDate?: PlainDate;
  memberEndDate?: PlainDate;
  shouldDisplayStatusText?: boolean;
}) => {
  const { formatMessage } = useIntl();
  const { iconVariant, statusHeading, statusSubheading, textColorClassName } =
    useMemo(
      () =>
        benefitsStatusConfig({
          isBeforeBaselineVisitDueDate,
          isBaselineVisitComplete,
          formatMessage,
          isBaselineVisitRequired,
          memberStartDate,
          memberEndDate,
        }),
      [
        formatMessage,
        isBeforeBaselineVisitDueDate,
        isBaselineVisitComplete,
        isBaselineVisitRequired,
        memberStartDate,
        memberEndDate,
      ]
    );

  return (
    <>
      {shouldDisplayStatusText && (
        <Box
          element="p"
          textVariant={TextVariant.SmRegularTall}
          className="mb-2 text-textColor-subdued"
        >
          <FormattedMessage defaultMessage="Benefits status" id="VA/rVg" />
        </Box>
      )}

      <Box
        element="div"
        textVariant={TextVariant.LgRegular}
        className={clsx("flex items-start", textColorClassName)}
      >
        <Icon
          variant={iconVariant}
          size={SpacingVariant.S20}
          className="mr-1 mt-[0.175rem] min-w-[20px]"
        />
        {statusHeading}
      </Box>
      <Box
        element="p"
        textVariant={TextVariant.SmRegularTall}
        className="mb-2 text-textColor-subdued"
      >
        {statusSubheading}
      </Box>
    </>
  );
};
