import { isEmpty } from "lodash-es";
import { isValidElement, ReactNode } from "react";

/**
 * Check if textual message will render as empty
 */
export const isMessageEmpty = ({
  message,
}: {
  message?: ReactNode;
}): boolean => {
  return !hasTextContent(message);
};

/**
 * Using a second function to not have to modify the origional API of the function
 */
function hasTextContent(element: ReactNode): boolean {
  if (
    element === "" ||
    typeof element === null || // eslint-disable-line valid-typeof
    typeof element === undefined // eslint-disable-line valid-typeof
  ) {
    return false;
  }

  if (typeof element === "string" || typeof element === "number") {
    return true;
  }

  if (Array.isArray(element)) {
    return element.some(hasTextContent);
  }

  if (!isValidElement(element)) {
    return false;
  }

  // NOTE: This is a temporary workaround so that passing in FormattedMessage components will work,
  // because this function needs to be fixed or removed. The function doesn't accurately determine
  // whether an element will have visible content, I don't think that's even possible before actually rendering.
  // https://linear.app/curativeinc/issue/GHP-8324/hastextcontent-does-not-return-expected-results-for-formattedmessage
  if (!isEmpty(element.props.defaultMessage)) {
    return true;
  }

  if (Array.isArray(element.props.children)) {
    return element.props.children.some(hasTextContent);
  }

  return hasTextContent(element.props.children);
}
