import type { CountyElement } from "../types";

export const CAPITAL_RX_MEMBER_CARD_STATIC_LOGO_URI =
  "/capitalrx_logo_horizontal.svg";

export const CURATIVE_MEMBER_CARD_STATIC_LOGO_URI =
  "/curative_logo_horizontal.svg";

// https://www.ciclt.net/sn/clt/capitolimpact/gw_ziplist.aspx?ClientCode=capitolimpact&State=tx&StName=Texas&StFIPS=48&FIPS=48453
export const TRAVIS_COUNTY_LIST: CountyElement[] = [
  { zipCode: "78617", city: "Del Valle" },
  { zipCode: "78641", city: "Leander" },
  { zipCode: "78641", city: "Volente" },
  { zipCode: "78645", city: "Jonestown" },
  { zipCode: "78645", city: "Lago Vista" },
  { zipCode: "78645", city: "Leander" },
  { zipCode: "78645", city: "Point Venture" },
  { zipCode: "78651", city: "Mcneil" },
  { zipCode: "78652", city: "Manchaca" },
  { zipCode: "78653", city: "Manor" },
  { zipCode: "78660", city: "Pflugerville" },
  { zipCode: "78669", city: "Briarcliff" },
  { zipCode: "78669", city: "Spicewood" },
  { zipCode: "78691", city: "Pflugerville" },
  { zipCode: "78701", city: "Austin" },
  { zipCode: "78702", city: "Austin" },
  { zipCode: "78703", city: "Austin" },
  { zipCode: "78704", city: "Austin" },
  { zipCode: "78705", city: "Austin" },
  { zipCode: "78708", city: "Austin" },
  { zipCode: "78709", city: "Austin" },
  { zipCode: "78710", city: "Austin" },
  { zipCode: "78711", city: "Austin" },
  { zipCode: "78712", city: "Austin" },
  { zipCode: "78713", city: "Austin" },
  { zipCode: "78714", city: "Austin" },
  { zipCode: "78715", city: "Austin" },
  { zipCode: "78716", city: "Austin" },
  { zipCode: "78718", city: "Austin" },
  { zipCode: "78719", city: "Austin" },
  { zipCode: "78720", city: "Austin" },
  { zipCode: "78721", city: "Austin" },
  { zipCode: "78722", city: "Austin" },
  { zipCode: "78723", city: "Austin" },
  { zipCode: "78724", city: "Austin" },
  { zipCode: "78725", city: "Austin" },
  { zipCode: "78726", city: "Austin" },
  { zipCode: "78727", city: "Austin" },
  { zipCode: "78728", city: "Austin" },
  { zipCode: "78730", city: "Austin" },
  { zipCode: "78731", city: "Austin" },
  { zipCode: "78732", city: "Austin" },
  { zipCode: "78733", city: "Austin" },
  { zipCode: "78733", city: "Bee Cave" },
  { zipCode: "78733", city: "Bee Caves" },
  { zipCode: "78734", city: "Austin" },
  { zipCode: "78734", city: "Bee Cave" },
  { zipCode: "78734", city: "Lakeway" },
  { zipCode: "78734", city: "Bee Caves" },
  { zipCode: "78735", city: "Austin" },
  { zipCode: "78735", city: "Sunset Valley" },
  { zipCode: "78736", city: "Austin	" },
  { zipCode: "78736", city: "Bee Cave" },
  { zipCode: "78736", city: "Bee Caves" },
  { zipCode: "78738", city: "Austin" },
  { zipCode: "78738", city: "Bee Cave" },
  { zipCode: "78738", city: "Lakeway" },
  { zipCode: "78738", city: "Bee Caves" },
  { zipCode: "78739", city: "Austin" },
  { zipCode: "78741", city: "Austin" },
  { zipCode: "78742", city: "Austin" },
  { zipCode: "78744", city: "Austin" },
  { zipCode: "78745", city: "Austin" },
  { zipCode: "78745", city: "Sunset Valley" },
  { zipCode: "78746", city: "Austin" },
  { zipCode: "78746", city: "Rollingwood" },
  { zipCode: "78746", city: "West Lake Hills" },
  { zipCode: "78747", city: "Austin" },
  { zipCode: "78748", city: "Austin" },
  { zipCode: "78749", city: "Austin" },
  { zipCode: "78750", city: "Austin" },
  { zipCode: "78751", city: "Austin" },
  { zipCode: "78752", city: "Austin" },
  { zipCode: "78753", city: "Austin" },
  { zipCode: "78754", city: "Austin" },
  { zipCode: "78755", city: "Austin" },
  { zipCode: "78756", city: "Austin" },
  { zipCode: "78757", city: "Austin" },
  { zipCode: "78758", city: "Austin" },
  { zipCode: "78759", city: "Austin" },
  { zipCode: "78760", city: "Austin" },
  { zipCode: "78761", city: "Austin" },
  { zipCode: "78762", city: "Austin" },
  { zipCode: "78763", city: "Austin" },
  { zipCode: "78764", city: "Austin" },
  { zipCode: "78765", city: "Austin" },
  { zipCode: "78766", city: "Austin" },
  { zipCode: "78767", city: "Austin" },
  { zipCode: "78768", city: "Austin" },
  { zipCode: "78769", city: "Austin" },
];

// http://ciclt.net/sn/clt/capitolimpact/gw_ziplist.aspx?ClientCode=capitolimpact&State=tx&StName=Texas&StFIPS=48&FIPS=48491
export const WILLIAMSON_COUNTY_LIST: CountyElement[] = [
  { zipCode: "76527", city: "Florence" },
  { zipCode: "76530", city: "Granger" },
  { zipCode: "76537", city: "Jarrell" },
  { zipCode: "76573", city: "Schwertner" },
  { zipCode: "76574", city: "Taylor" },
  { zipCode: "76578", city: "Thrall" },
  { zipCode: "78613", city: "Cedar Park" },
  { zipCode: "78615", city: "Coupland" },
  { zipCode: "78626", city: "Georgetown" },
  { zipCode: "78627", city: "Georgetown" },
  { zipCode: "78628", city: "Georgetown" },
  { zipCode: "78628", city: "Andice" },
  { zipCode: "78628", city: "Sun City" },
  { zipCode: "78630", city: "Cedar Park" },
  { zipCode: "78634", city: "Hutto" },
  { zipCode: "78642", city: "Liberty Hill" },
  { zipCode: "78646", city: "Leander" },
  { zipCode: "78664", city: "Round Rock" },
  { zipCode: "78673", city: "Walburg" },
  { zipCode: "78674", city: "Weir" },
  { zipCode: "78680", city: "Round Rock" },
  { zipCode: "78681", city: "Round Rock" },
  { zipCode: "78683", city: "Round Rock" },
  { zipCode: "78717", city: "Austin" },
  { zipCode: "78729", city: "Austin" },
];
