import { FCWithChildren } from "@chp/shared/types/fcWithChildren";
import { debounce } from "lodash-es";
import { useRouter } from "next/router";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { MixpanelEventOptions } from "../types";
import {
  initMixpanel,
  trackMixpanelEvent,
  trackMixpanelPageViewEvent,
} from "../utils";

const DEFAULT_DEBOUNCE_MS = 1_000;

export type TrackEventFn = typeof trackMixpanelEvent;

type TrackPageViewEventFn = typeof trackMixpanelPageViewEvent;

export type MixpanelContextValue = {
  isMixpanelLoaded: boolean;
  trackEvent: TrackEventFn;
  debouncedTrackEvent: TrackEventFn;
  trackPageViewEvent: TrackPageViewEventFn;
  setDebounceMs: (ms: number) => void;
};

export const MixpanelContext = createContext<MixpanelContextValue>({
  isMixpanelLoaded: false,
  trackEvent: () => {},
  debouncedTrackEvent: () => {},
  trackPageViewEvent: () => {},
  setDebounceMs: () => {},
});

export const useMixpanelContext = () => useContext(MixpanelContext);

export const MixpanelProvider: FCWithChildren = ({ children }) => {
  const [debounceMs, setDebounceMs] = useState(DEFAULT_DEBOUNCE_MS);
  const [isMixpanelLoaded, setIsMixpanelLoaded] = useState<boolean>(false);
  const didTrackPageViewEventForPathnameRef = useRef(false);
  const { pathname, query } = useRouter();

  const trackEvent = useCallback<TrackEventFn>(
    (options) => {
      if (!isMixpanelLoaded) {
        return;
      }

      trackMixpanelEvent(options);
    },
    [isMixpanelLoaded]
  );

  const debounceFunc = debounce((eventOptions: MixpanelEventOptions) => {
    trackEvent(eventOptions);
  }, debounceMs);

  const debouncedTrackEvent = useCallback(
    (eventOptions: MixpanelEventOptions) => {
      debounceFunc(eventOptions);
    },
    [debounceFunc]
  );

  const trackPageViewEvent = useCallback<TrackPageViewEventFn>(
    (options) => {
      if (!isMixpanelLoaded) {
        return;
      }

      trackMixpanelPageViewEvent(options);
    },
    [isMixpanelLoaded]
  );

  useEffect(() => {
    if (isMixpanelLoaded) {
      return;
    }

    initMixpanel(() => {
      setIsMixpanelLoaded(true);
    });
  }, [isMixpanelLoaded]);

  // We want to track the page view event when pathname changes, but not when
  // query changes. Otherwise we'd be generating a lot of useless noise on
  // pages like the Formulary Search drug search page.
  useEffect(() => {
    didTrackPageViewEventForPathnameRef.current = false;
  }, [pathname]);

  useEffect(() => {
    if (didTrackPageViewEventForPathnameRef.current || !isMixpanelLoaded) {
      return;
    }

    trackPageViewEvent({ pathname, query });
    didTrackPageViewEventForPathnameRef.current = true;
  }, [isMixpanelLoaded, trackPageViewEvent, pathname, query]);

  // This context provider should be rendered in the root `App` component,
  // so we don't want the value object's identity to change unnecessarily.
  const contextProviderValue = useMemo(
    () => ({
      isMixpanelLoaded,
      trackEvent,
      debouncedTrackEvent,
      trackPageViewEvent,
      setDebounceMs,
    }),
    [isMixpanelLoaded, trackEvent, debouncedTrackEvent, trackPageViewEvent]
  );

  return (
    <MixpanelContext.Provider value={contextProviderValue}>
      {children}
    </MixpanelContext.Provider>
  );
};
