export enum Auth0ErrorCode {
  // The “login required” error, is what you get when your browser does not,
  // or cannot, send the “auth0” cookie. This will be the case when the user is not logged in on auth0.
  // https://community.auth0.com/t/failed-silent-auth-login-required/33165/24
  // See
  // https://github.com/auth0/auth0-spa-js/blob/79720c5fe345f194151259a8b05cfe452734c6b5/EXAMPLES.md?plain=1#L80
  LOGIN_REQUIRED = "login_required",
  // missing_refresh_token is the error we encounter when we attempt to get
  // an access token but we don't have a refresh token in memory. This is
  // the case when we first load the page so we need to do the redirect to
  // /authorize either in the browser or in the iframe when the
  // useRefreshTokensFallback is enabled to populate it after which we
  // should not encounter it. In the case the useRefreshTokensFallback is
  // disabled, we will encounter this error and will need to redirect the
  // user to auth0.
  MISSING_REFRESH_TOKEN = "missing_refresh_token",
  // this is the error we get if we use a bad refresh token, i.e. an expired or revoked
  // refresh token. If useRefreshTokensFallback option is enabled, it will
  // attempt to handle this in the iframe, otherwise we need to redirect
  INVALID_GRANT = "invalid_grant",
}
