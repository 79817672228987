import clsx from "clsx";
import { FC, useState } from "react";

import { FOCUS_CLASS_NAMES, SpacingVariant } from "../../constants";
import { Box, Icon, IconVariant, Tooltip } from "..";
import { CUIComponentProps, TextVariant } from "../types";
import { ACTIVE_LINK_CLASSNAMES, INACTIVE_LINK_CLASSNAMES } from "./SideNavbar";
import { IconOrSpacer } from "./utils";

export type NavButtonProps = CUIComponentProps<{
  label: string;
  iconVariant?: IconVariant;
  /**
   * If this returns true, we highlight this navbar item to indicate that it is
   * what the user is currently viewing (e.g. the page they're currently on).
   */
  isActive?: () => boolean;
  isNavbarCollapsed?: boolean;
  onClick: () => void;
  /**
   * If true, we grey out this navbar item and make it unclickable.
   */
  isDisabled?: boolean;
}>;

export const NavButton: FC<NavButtonProps> = ({
  label,
  iconVariant,
  isActive,
  isNavbarCollapsed,
  onClick,
  testId = "NavButton",
  className,
  isDisabled = false,
}) => {
  const [tooltipTriggerElement, setTooltipTriggerElement] =
    useState<HTMLButtonElement | null>(null);

  return (
    <>
      <button
        data-testid={testId}
        key={label}
        title={label}
        onClick={onClick}
        disabled={isDisabled}
        ref={setTooltipTriggerElement}
        className={clsx(
          "relative",
          "focus:z-40",
          "focus-visible:z-40",
          "px-4 py-3",
          "grid w-full items-center",
          isDisabled
            ? "text-textColor-subdued"
            : "hover:text-textColor-highlight-default",
          FOCUS_CLASS_NAMES,
          className,
          isNavbarCollapsed
            ? "justify-center px-2"
            : "grid-cols-[40px_1fr_auto]",
          isActive?.() ? ACTIVE_LINK_CLASSNAMES : INACTIVE_LINK_CLASSNAMES,
          isDisabled ? "disabled" : ""
        )}
      >
        {isNavbarCollapsed ? (
          iconVariant && (
            <>
              <Icon variant={iconVariant} size={SpacingVariant.S24} />
              <Tooltip
                triggerElement={tooltipTriggerElement}
                message={label}
                fitMessage
              />
            </>
          )
        ) : (
          <>
            <IconOrSpacer iconVariant={iconVariant} testId={testId} />
            <Box
              element="span"
              textVariant={
                isActive?.() ? TextVariant.LgBoldShort : TextVariant.LgRegular
              }
              className={clsx(
                "whitespace-nowrap",
                "overflow-hidden",
                "text-left",
                "text-ellipsis"
              )}
            >
              {label}
            </Box>
          </>
        )}
      </button>
    </>
  );
};
