import clsx from "clsx";
import { useEffect, useState } from "react";

import {
  Tooltip,
  TooltipPlacement,
  TooltipSize,
  TooltipVariant,
} from "../Tooltip";
import { CUIComponent, CUIComponentProps } from "../types";

export type SensitiveFieldProps = CUIComponentProps<{
  children: JSX.Element | string;
  overrideShowHide?: boolean | null;
}>;

export const SensitiveField: CUIComponent<SensitiveFieldProps> = ({
  children,
  overrideShowHide = null,
  testId = "SensitiveField",
  className,
}) => {
  const [toggleBlur, setToggleBlur] = useState<boolean>(true);
  const [tooltipTriggerElement, setTooltipTriggerElement] =
    useState<HTMLElement | null>(null);

  useEffect(() => {
    setToggleBlur(!overrideShowHide);
  }, [overrideShowHide]);

  return (
    <>
      <div
        ref={setTooltipTriggerElement}
        data-testid={testId}
        className={clsx(className, "cursor-pointer", toggleBlur ? "blur" : "")}
        onClick={() => {
          setToggleBlur(!toggleBlur);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            setToggleBlur(!toggleBlur);
          }
        }}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
      <Tooltip
        triggerElement={tooltipTriggerElement}
        message={"Click to " + (toggleBlur ? "reveal" : "hide")}
        size={TooltipSize.Small}
        variant={TooltipVariant.Light}
        placement={TooltipPlacement.TOP_START}
      />
    </>
  );
};
