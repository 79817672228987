import { Disclosure } from "@headlessui/react";
import clsx from "clsx";
import { FC, ReactNode } from "react";

import { SpacingVariant } from "../../constants";
import { Icon, IconVariant } from "..";

export type AccordionItemProps = {
  summaryContent: ReactNode;
  panelContent: ReactNode;
  onButtonClick?: () => void;
  testid?: string;
  defaultOpen?: boolean;
};

export const AccordionItem: FC<AccordionItemProps> = ({
  summaryContent,
  panelContent,
  onButtonClick,
  testid,
  defaultOpen,
}) => (
  <div data-testid={testid}>
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <div
          className={clsx(
            "w-full border-l-4 bg-white py-4 pl-3 pr-4",
            open ? "border-l-foundation-primary" : "border-l-white"
          )}
        >
          <Disclosure.Button
            data-testid={`${testid}_toggle-accordion`}
            onClick={onButtonClick}
          >
            <div className="flex items-center">
              <Icon
                size={SpacingVariant.S16}
                variant={
                  open ? IconVariant.CHEVRON_DOWN : IconVariant.CHEVRON_RIGHT
                }
              />
              <span className="ml-2 font-semibold">{summaryContent}</span>
            </div>
          </Disclosure.Button>
          <div data-testid={`${testid}_panel-content`}>
            <Disclosure.Panel className="text-textColor-default">
              {panelContent}
            </Disclosure.Panel>
          </div>
        </div>
      )}
    </Disclosure>
  </div>
);
