import { AssetCollection, createClient } from "contentful";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from "react";

export interface ContentfulContextValue {
  getMediaUrl: (assetName: string) => Promise<string>;
  getAssets: (tagName: string) => Promise<AssetCollection["items"]>;
}

export const ContentfulContext = createContext<ContentfulContextValue>({
  getMediaUrl: async () => "",
  getAssets: async () => [],
});

export interface ContentfulProviderProps {
  space: string;
  accessToken: string;
  environment: string;
  children: ReactNode;
}

export const ContentfulProvider = ({
  space,
  accessToken,
  environment,
  children,
}: ContentfulProviderProps) => {
  const client = useMemo(() => {
    return createClient({
      space,
      accessToken,
      environment,
    });
  }, [accessToken, environment, space]);

  const getAssets = useCallback(
    async (tagName: string) => {
      try {
        const assets = await client.getAssets({
          "metadata.tags.sys.id[in]": tagName,
        });
        return assets.items;
      } catch (error) {
        console.error(`Error fetching assets: ${(error as Error).toString()}`);
      }
      console.debug("0 assets found");
      return [];
    },
    [client]
  );

  const getMediaUrl = useCallback(
    async (assetName: string) => {
      try {
        const assets = await client.getAssets({
          "fields.title": assetName,
        });
        if (assets.items[0]) {
          const mediaUrl = assets.items[0].fields.file.url;
          return `https:${mediaUrl}`;
        }
      } catch (error) {
        console.error(
          `Error fetching asset of name ${assetName}: ${(
            error as Error
          ).toString()}`
        );
      }
      console.debug(`0 assets found for name ${assetName}`);
      return "";
    },
    [client]
  );

  return (
    <ContentfulContext.Provider value={{ getMediaUrl, getAssets }}>
      {children}
    </ContentfulContext.Provider>
  );
};

export const useContentful = () => useContext(ContentfulContext);
