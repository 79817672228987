/**
 * We use this to represent the internal "search state", for lack of a better
 * term, for the search autocomplete context / components. Each value has a
 * corresponding "state" as described below:
 *
 * FREE_TEXT:
 *   If the `isFreeSearchEnabled` prop on the `SearchAutocomplete` component is
 *   set to `true`, the component will render a "submit" button next to the
 *   search bar. This corresponds to how Formulary Search's drug search works,
 *   where you can type in a drug name and hit submit to get a list of results
 *   in addition to selecting a single result from the autocomplete options
 *   list. When this type of search is performed, `searchType` is set to
 *   `FREE_TEXT` to indicate that we're working with a list of results instead
 *   of a single selected result. A lot of the complexity with the internal
 *   state management stems from supporting this feature; when we have time, we
 *   should try to split out the "free-text search" functionality into its own
 *   context / component(s) in order to simplify the core autocomplete logic.
 *
 * RESULT_SELECTED:
 *   This is much more straightforward to describe, it just indicates that one
 *   of the results in the autocomplete options list has been selected.
 *
 * NONE
 *   This is the default `searchType` value when the search autocomplete is
 *   initially rendered. This is also the value used for `searchType` when the
 *   user has entered some text into the search input, but hasn't either (a.)
 *   selected a result from the autocomplete results list or (b.) performed a
 *   free-text search.
 *
 * Once we split out the free-text search functionality from the autocomplete
 * functionality, we will be able to remove `searchType` / `setSearchType`
 * entirely, along with this enum. At that point the only two possible values
 * would be `RESULT_SELECTED` or `NONE`, which we would be able to determine
 * based on whether `selectedSearchResult` is set (RESULT_SELECTED) or if it's
 * `undefined` (NONE).
 */
export enum SearchAutocompleteSearchType {
  FREE_TEXT = "FREE_TEXT",
  RESULT_SELECTED = "RESULT_SELECTED",
  NONE = "NONE",
}
