import { TypeaheadOption } from "@chp/curative_ui";

type ProcedureCode = {
  code: string;
  description: string;
};

export const formatProcedureCodeTypeaheadOption = (
  option: TypeaheadOption<ProcedureCode>
): TypeaheadOption<ProcedureCode> => {
  return {
    label: option.label,
    value: option.value,
    label2: option.value.code,
    label3: option.value.description,
  };
};
