// polyfill for Intl.ListFormat
import "@formatjs/intl-listformat/polyfill";
import "@formatjs/intl-listformat/locale-data/en";
import "@formatjs/intl-listformat/locale-data/es";

import { LocaleOption, LocalePicker } from "@chp/curative_ui";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IntlProvider } from "react-intl";

import { Language } from "../../generated/memberPortalApi.graphql";
import {
  findSupportedLocaleCode,
  getLocaleCodesToDisplay,
  setLocaleCodeCookie,
} from "../../utils";
import { DEFAULT_LOCALE_CODE, LocaleCode } from "./constants";
import { localeCodeOption } from "./localeCodeOption";
import { localeCodeToLanguages } from "./localeCodeToLanguages";
import { LocaleCodesInitial, LocaleCodeToMessages } from "./types";

export interface RequestSetLocaleOptions {
  locale: LocaleCode;
  shouldSetCookie?: boolean;
  shouldUpdateActiveUser?: boolean;
}

// fallback to empty object if we cant get messages from locale code
const messagesEmpty = {};

export const I18nContext = createContext<{
  locale: LocaleCode;
  localeOptions: LocaleOption<LocaleCode>[];
  userPreferredLanguage: Language | null;
  refreshLocale: (_?: Language | null) => void;
  renderLocalePicker: (
    trackLanguageMenuClick?: () => void,
    trackLanguageOptionClick?: (newLanguage: Language) => void
  ) => JSX.Element | null;
  requestSetLocale: (_: RequestSetLocaleOptions) => void;
  setTimeZone: (_: string) => void;
  updateActiveUser?: ({ locale }: { locale: LocaleCode }) => Promise<void>;
}>({
  locale: DEFAULT_LOCALE_CODE,
  localeOptions: [],
  userPreferredLanguage: null,
  refreshLocale: () => {},
  renderLocalePicker: () => null,
  requestSetLocale: () => {},
  setTimeZone: () => {},
});

export type I18nProviderProps = PropsWithChildren<{
  localeCodeToMessages?: LocaleCodeToMessages;
  localeCodesInitial?: LocaleCodesInitial;
  supportedLocales?: LocaleCode[];
  // optional way to provide app-specific locale handling to update active user
  updateActiveUser?: ({ locale }: { locale: LocaleCode }) => Promise<void>;
}>;

export const I18nProvider = ({
  children,
  localeCodeToMessages,
  localeCodesInitial,
  supportedLocales = [DEFAULT_LOCALE_CODE],
  updateActiveUser,
}: I18nProviderProps) => {
  const localeCodeInitial: LocaleCode = useMemo(
    () =>
      findSupportedLocaleCode({
        localeCodes: localeCodesInitial,
        supportedLocales,
      }),
    [localeCodesInitial, supportedLocales]
  );
  const [locale, setLocale] = useState<LocaleCode>(localeCodeInitial);
  const [userPreferredLanguage, setUserPreferredLanguage] =
    useState<Language | null>(null);
  const [timeZone, setTimeZone] = useState("");

  const requestSetLocale = useCallback(
    ({ locale: newLocale, shouldSetCookie }: RequestSetLocaleOptions) => {
      if (!supportedLocales.includes(newLocale)) {
        console.warn("Locale not supported: ", { newLocale });
        return;
      }
      setLocale(newLocale);
      if (shouldSetCookie) setLocaleCodeCookie(newLocale);
    },
    [supportedLocales]
  );

  const refreshLocale = useCallback(
    (specifiedLangauge?: Language | null) => {
      const localeCodes = getLocaleCodesToDisplay(specifiedLangauge);

      const newLocale: LocaleCode = findSupportedLocaleCode({
        localeCodes,
        supportedLocales,
      });

      setLocale(newLocale);

      if (specifiedLangauge) {
        setUserPreferredLanguage(specifiedLangauge);
        setLocaleCodeCookie(newLocale);
      }
    },
    [setLocale, supportedLocales]
  );

  const handleClickLocaleOption = async (
    { value }: LocaleOption<LocaleCode>,
    trackLanguageOptionClick?: (newLanguage: Language) => void
  ) => {
    const language = localeCodeToLanguages[value];
    // if there's an active user, update their preferred language
    if (updateActiveUser) {
      setUserPreferredLanguage(language);
      await updateActiveUser({ locale: value });
    }
    requestSetLocale({ locale: value, shouldSetCookie: true });

    // Call trackLanguageOptionClick if it's provided
    if (trackLanguageOptionClick) {
      trackLanguageOptionClick(language);
    }
  };

  const supportedLocaleOptions: LocaleOption<LocaleCode>[] = useMemo(
    () =>
      supportedLocales.map((localeCode) => localeCodeOption({ localeCode })),
    [supportedLocales]
  );

  const selectedLocaleOption: LocaleOption<LocaleCode> = useMemo(
    () => localeCodeOption({ localeCode: locale }),
    [locale]
  );

  const renderLocalePicker = (
    trackLanguageMenuClick?: () => void,
    trackLanguageOptionClick?: (newLanguage: Language) => void
  ) => {
    if (supportedLocales.length <= 1) return null;

    return (
      <LocalePicker
        localeOptions={supportedLocaleOptions}
        selectedLocaleOption={selectedLocaleOption}
        onClickLocaleOption={(localeOption) =>
          handleClickLocaleOption(localeOption, trackLanguageOptionClick)
        }
        onClickLanguageMenu={trackLanguageMenuClick}
      />
    );
  };

  useEffect(() => {
    if (!localeCodeToMessages) return;
    document.documentElement.lang = locale;
  }, [locale, localeCodeToMessages]);

  useEffect(() => {
    refreshLocale();
  }, [refreshLocale]);

  const messages = localeCodeToMessages
    ? localeCodeToMessages[locale]
    : messagesEmpty;

  return (
    <I18nContext.Provider
      value={{
        locale,
        localeOptions: supportedLocaleOptions,
        userPreferredLanguage,
        refreshLocale,
        renderLocalePicker,
        requestSetLocale,
        setTimeZone,
        updateActiveUser,
      }}
    >
      <IntlProvider messages={messages} locale={locale} timeZone={timeZone}>
        {children}
      </IntlProvider>
    </I18nContext.Provider>
  );
};
