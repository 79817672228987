import { FieldTemplateProps } from "@rjsf/utils";
import clsx from "clsx";

export const FieldTemplate = (props: FieldTemplateProps) => {
  const { classNames, help, description, children } = props;
  return (
    <div className={clsx("mt-5 max-w-xl", classNames)}>
      {description}
      {children}
      {help}
    </div>
  );
};
