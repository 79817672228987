import { format } from "date-fns";

import { PlainDate } from "./PlainDate";

export * from "./format";
export * from "./PlainDate";

export const getUnixTimestamp = (date: Date) => {
  return date.valueOf();
};

/**
 * Calculates the next business day from a given date, excluding weekends and specified holidays.
 *
 * This function checks each subsequent day from the start date, determining
 * whether it is a business day. A business day is defined as a day that is not a Saturday,
 * Sunday, or a specified holiday. The function stops once it finds the next business day
 * and returns it.
 *
 * @param {PlainDate} startDate The date from which to calculate the next business day.
 * @param {string[]} holidays An optional array of dates (in 'YYYY-MM-DD' format) that are considered holidays.
 *                            These dates are excluded when calculating the next business day.
 *                            If not provided, defaults to an empty array, meaning no holidays are considered.
 * @returns {PlainDate} The next business day as a PlainDate object.
 *
 * @example
 * // Assuming the start date is Friday, 2023-07-14, and there's a holiday on 2023-07-17 (Monday)
 * const startDate = new Date('2023-07-14');
 * const holidays = ['2023-07-17'];
 * console.log(getNextBusinessDay(startDate, holidays)); // Outputs: 2023-07-18 (Tuesday)
 */
export const getNextBusinessDay = (
  startDate: PlainDate,
  holidays: string[] = []
): PlainDate => {
  // This function calculates the next business day from startDate
  const jsStartDate = startDate.asJsDate();

  const dayOfWeek = jsStartDate.getDay();
  const dayOfMonth = jsStartDate.getDate();

  const nextBusinessDay = new Date(jsStartDate);

  // Move to the next day depending on the day of the week
  if (dayOfWeek === 5) {
    // Friday
    nextBusinessDay.setDate(dayOfMonth + 3); // Skip to Monday
  } else if (dayOfWeek === 6) {
    // Saturday
    nextBusinessDay.setDate(dayOfMonth + 2); // Skip to Monday
  } else {
    nextBusinessDay.setDate(dayOfMonth + 1); // Next day
  }

  // Check if the calculated day is a holiday
  if (
    holidays.some(
      (holiday) => holiday === format(nextBusinessDay, "yyyy-MM-dd")
    )
  ) {
    return getNextBusinessDay(PlainDate.fromJsDate(nextBusinessDay), holidays); // Recursively find the next business day
  }

  return PlainDate.fromJsDate(nextBusinessDay);
};
