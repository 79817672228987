import { Coordinate, LocationDetails } from "@chp/shared/types";
import GeocodingService, {
  GeocodeFeature,
} from "@mapbox/mapbox-sdk/services/geocoding";
import { captureMessage } from "@sentry/nextjs";

const MAPBOX_COUNTRY_CODES = ["US", "PR"];

export async function forwardGeocode(query: string): Promise<GeocodeFeature> {
  const geocodingClient = GeocodingService({
    accessToken: process.env.NEXT_PUBLIC_MAPBOX_KEY as string,
  });
  const request = geocodingClient.forwardGeocode({
    query: query,
    mode: "mapbox.places",
    countries: MAPBOX_COUNTRY_CODES,
    autocomplete: true,
    limit: 1,
  });
  const {
    body: { features },
  } = await request.send();

  if (features && features.length > 0 && features[0]) {
    return features[0];
  }

  throw new Error("No coordinates available for location");
}

export function geocodeToLocationDetails(
  feature: GeocodeFeature
): LocationDetails {
  const context = feature.context || [];
  context.push(feature);
  let city = null,
    state = null,
    zipCode = null,
    street = null;

  context.forEach((c) => {
    if (c.id.startsWith("place")) {
      city = c.text;
    } else if (c.id.startsWith("region")) {
      const short_code = c.short_code || c.properties?.short_code;
      if (short_code) {
        state = short_code.replace("US-", "").toUpperCase();
      }
    } else if (c.id.startsWith("postcode")) {
      zipCode = c.text;
    } else if (c.id.startsWith("address")) {
      street = c.address ? `${c.address} ${c.text}` : c.text;
    }
  });

  return {
    city,
    street,
    state,
    zipCode,
    longitude: feature.center[0] as number,
    latitude: feature.center[1] as number,
  };
}

export async function reverseGeocode(coordinate: Coordinate) {
  try {
    const geocodingClient = GeocodingService({
      accessToken: process.env.NEXT_PUBLIC_MAPBOX_KEY as string,
    });
    const request = geocodingClient.reverseGeocode({
      query: [coordinate.longitude, coordinate.latitude],
      mode: "mapbox.places",
      countries: MAPBOX_COUNTRY_CODES,
      limit: 1,
    });
    const {
      body: { features },
    } = await request.send();

    return features[0];
  } catch (e) {
    if (e instanceof Error) {
      captureMessage(`Error attempting to reverse geocode: ${e.message}`);
    }
    if (e instanceof ProgressEvent) {
      captureMessage(
        "Mapbox threw a ProgressEvent while attempting to reverse geocode"
      );
    }
  }
}
