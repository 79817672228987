import { TextAlignVariant } from "@chp/curative_ui";
import { FormattedMessage } from "react-intl";

import { AnimationType } from "../Animation";
import {
  MemberServicesEmailLink,
  MemberServicesPhoneNumberLink,
} from "../links";
import {
  StatusMessageWithLogo,
  StatusMessageWithLogoSize,
} from "./StatusMessageWithLogo";

export type DownErrorMessageWithLogoProps = {
  message?: string | JSX.Element;
};

export const DownErrorMessageWithLogo = ({
  message,
}: DownErrorMessageWithLogoProps) => (
  <StatusMessageWithLogo
    animationType={AnimationType.ERROR}
    size={StatusMessageWithLogoSize.SM}
    textAlign={TextAlignVariant.CENTER}
    title={
      <FormattedMessage defaultMessage="We'll be back shortly!" id="YZKb31" />
    }
    message={
      message ?? (
        <FormattedMessage
          defaultMessage="We are temporarily down for maintenance. Thank you for your patience, we will be back soon! If you need assistance in the interim, please contact support at {phoneNumber} or {email}."
          id="IbVRPb"
          values={{
            phoneNumber: <MemberServicesPhoneNumberLink />,
            email: <MemberServicesEmailLink />,
          }}
        />
      )
    }
  />
);
