import { Button, ButtonVariant, TextField } from "@chp/curative_ui";
import { FunctionComponent } from "react";

interface SearchBarProps {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  placeholder: string;
  reloadResults: () => void;
}
const SearchBar: FunctionComponent<SearchBarProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: any) => {
    try {
      e.preventDefault();
      e.stopPropagation();
    } catch {
      // do nothing
    }
    props.setSearchQuery(e);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (e: any) => {
    try {
      e.preventDefault();
      e.stopPropagation();
    } catch {
      // do nothing
    }
    props.reloadResults();
  };

  return (
    <form className="w-full" onSubmit={handleSubmit}>
      <TextField
        label="Search"
        isLabelSrOnly={true}
        onRequestChangeValue={handleChange}
        placeholder={props.placeholder}
        value={props.searchQuery}
      />
    </form>
  );
};

interface SmartTableHeaderProps {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  reloadResults: () => void;
  searchInputPlaceholder: string;
}
const SmartTableHeader: FunctionComponent<SmartTableHeaderProps> = (props) => {
  return (
    <div className="flex h-20 w-full justify-between px-2">
      <div className="flex w-full items-center">
        <SearchBar
          setSearchQuery={props.setSearchQuery}
          searchQuery={props.searchQuery}
          placeholder={props.searchInputPlaceholder}
          reloadResults={props.reloadResults}
        />
      </div>
      <div className="flex items-center">
        <span className="ml-2"></span>
        <Button
          onClick={() => props.reloadResults()}
          variant={ButtonVariant.BigPrimary}
        >
          Search
        </Button>
      </div>
    </div>
  );
};

export default SmartTableHeader;
