import clsx from "clsx";

import { SpacingVariant } from "../../constants";
import { Box } from "..";
import { CUIComponent, CUIComponentProps } from "../types";

export enum LogoVariant {
  OffWhite = "OFF_WHITE",
  SignalOrange = "SIGNAL_ORANGE",
  SignalOrangeSymbolOnly = "SIGNAL_ORANGE_SYMBOL_ONLY",
}

export const LOGO_SOURCE: Record<LogoVariant, string> = {
  [LogoVariant.OffWhite]:
    "https://static.curative.com/logos/Curative_Lockup_Horizontal_A_OffWhite.png",
  [LogoVariant.SignalOrange]:
    "https://static.curative.com/logos/Curative_Lockup_Horizontal_A_RedOrange.png",
  [LogoVariant.SignalOrangeSymbolOnly]:
    "https://static.curative.com/logos/Curative_Symbol_RedOrange.svg",
};

export type LogoProps = CUIComponentProps<{
  altText: string;
  height: SpacingVariant;
  variant: LogoVariant;
}>;

export const Logo: CUIComponent<LogoProps> = ({
  altText,
  className,
  height,
  variant,
  testId = "Logo",
}) => {
  return (
    <Box
      alt={altText}
      className={clsx(className)}
      data-testid={testId}
      element="img"
      height={height}
      src={LOGO_SOURCE[variant]}
    />
  );
};

export type LogoResponsiveProps = Omit<LogoProps, "height">;

/**
 * Responsive Logo for use in navbars, etc. Set height to 32px on desktop and 24px on mobile.
 */
export const LogoResponsive: CUIComponent<LogoResponsiveProps> = (props) => {
  const { className, ...rest } = props;
  return (
    <Logo
      {...rest}
      className={clsx("md:h-8", "object-contain", className)}
      height={SpacingVariant.S24}
    />
  );
};

/**
 * Responsive light & dark logos for use in navbars, etc.
 */
export const navbarLogoImages = (
  altText: string
): { light: JSX.Element; dark: JSX.Element } => ({
  light: <LogoResponsive altText={altText} variant={LogoVariant.OffWhite} />,
  dark: <LogoResponsive altText={altText} variant={LogoVariant.SignalOrange} />,
});
