import { FOCUS_CLASS_NAMES } from "../constants";

// base class names that do not include font weight, underline, or colors
export const linkBaseClassNames: string[] = [
  "bg-none",
  "border-none",
  ...FOCUS_CLASS_NAMES,
];

// base + underline class names that do not include font weight or colors
export const linkBaseClassNamesWithUnderline: string[] = [
  ...linkBaseClassNames,
  "underline",
  "hover:no-underline",
];

// common class names that include font weight, underline, but no colors
export const linkClassNamesCommonNoColor: string[] = [
  ...linkBaseClassNamesWithUnderline,
  "font-bold",
];

// common class names that include font weight, underline, & colors
export const linkClassNamesCommon: string[] = [
  ...linkClassNamesCommonNoColor,
  "text-textColor-highlight-default",
  "hover:text-textColor-highlight-hover",
];
