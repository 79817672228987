import { IntlFormatters } from "react-intl";

import { BaselineVisitEventVariant } from "../../types";

export interface BaselineVisitEventDescriptionsProps {
  formatDate: IntlFormatters["formatDate"];
  formatMessage: IntlFormatters["formatMessage"];
  formatTime: IntlFormatters["formatTime"];
  careTeamMemberFullName: string | null | undefined;
  scheduledDate: string;
  telehealthLink: string | null;
  timeZone: string;
}

export const baselineVisitEventDescriptions = ({
  formatDate,
  formatMessage,
  formatTime,
  careTeamMemberFullName,
  scheduledDate,
  telehealthLink,
  timeZone,
}: BaselineVisitEventDescriptionsProps): Record<
  BaselineVisitEventVariant,
  string
> => {
  const time = formatTime(scheduledDate, {
    timeZoneName: "short",
    timeZone,
  });
  const date = formatDate(scheduledDate, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone,
  });
  return {
    [BaselineVisitEventVariant.IS_VIRTUAL_HAS_PHYSICIAN]: `${formatMessage(
      {
        defaultMessage: `Your virtual Baseline Visit appointment with {careTeamMemberFullName} is confirmed for {date} at {time}.`,
        id: "+Jij4W",
      },
      {
        time: time,
        date: date,
        careTeamMemberFullName,
      }
    )} ${formatMessage(
      {
        defaultMessage: `Zoom link: {link}`,
        id: "IBl2cn",
      },
      {
        link: telehealthLink,
      }
    )}`,
    [BaselineVisitEventVariant.IS_VIRTUAL_NOT_PHYSICIAN]: `${formatMessage(
      {
        defaultMessage: `Your virtual Baseline Visit appointment is confirmed for {date} at {time}.`,
        id: "spQJYq",
      },
      {
        time: time,
        date: date,
      }
    )} ${formatMessage(
      {
        defaultMessage: `Zoom link: {link}`,
        id: "IBl2cn",
      },
      {
        link: telehealthLink,
      }
    )}`,
    [BaselineVisitEventVariant.NOT_VIRTUAL_HAS_PHYSICIAN]: formatMessage(
      {
        defaultMessage: `Your Baseline Visit appointment with {careTeamMemberFullName} is confirmed for {date} at {time}.`,
        id: "5yjGVS",
      },
      {
        time: time,
        date: date,
        careTeamMemberFullName,
      }
    ),
    [BaselineVisitEventVariant.NOT_VIRTUAL_NOT_PHYSICIAN]: formatMessage(
      {
        defaultMessage: `Your Baseline Visit appointment is confirmed for {date} at {time}.`,
        id: "kudhKP",
      },
      {
        time: time,
        date: date,
      }
    ),
  };
};
