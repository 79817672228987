import { DATE_INPUT_FORMAT } from "@chp/shared/utils/dateTime/format";
import { dateInputSchema } from "@chp/shared/utils/validation/dateInput";
import { WidgetProps } from "@rjsf/utils";
import { format } from "date-fns";
import { ChangeEvent, useState } from "react";

import { reformatDate } from "../../../utils";
import { FormattedDateInput } from "../../";

// Date format JSON Schema expects
const SCHEMA_FORMAT = "yyyy-MM-dd";
// Date format we want to display to the user
const DISPLAY_FORMAT = DATE_INPUT_FORMAT;

const fromSchemaFormat = (date: string | null | undefined): string => {
  return reformatDate(date, SCHEMA_FORMAT, DISPLAY_FORMAT);
};

const toSchemaFormat = (date: string | null | undefined): string => {
  return reformatDate(date, DISPLAY_FORMAT, SCHEMA_FORMAT);
};

const checkDateInputSchema = (dateStr?: string) => {
  return (
    !dateStr ||
    dateInputSchema({ message: "Invalid date" }).validateSync(
      fromSchemaFormat(dateStr)
    )
  );
};

export const DateWidget = (props: WidgetProps) => {
  const {
    id,
    label,
    value,
    placeholder,
    required,
    disabled,
    onChange,
    onBlur,
    rawErrors,
    hideError,
    options,
  } = props;

  const [customError, setCustomError] = useState<string>();

  const errorMessage = hideError
    ? ""
    : rawErrors
    ? [...rawErrors, customError].join(" ")
    : customError;

  return (
    <FormattedDateInput
      id={id}
      isDisabled={disabled}
      isRequired={required}
      placeholder={placeholder}
      label={required ? `${label}*` : label}
      testId="DateWidgetInput"
      value={fromSchemaFormat(value)}
      errorMessage={errorMessage}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        onChange(toSchemaFormat(event.target.value));
      }}
      onRequestChangeValue={(s) => {
        try {
          checkDateInputSchema(s);
          setCustomError("");
          onChange(toSchemaFormat(s));
        } catch (e) {
          setCustomError((e as Error).message);
        }
      }}
      // @ts-ignore
      onBlur={onBlur}
      fromDate={String(options.fromDate)}
      toDate={
        options.excludeFutureDates
          ? format(Date.now(), DATE_INPUT_FORMAT)
          : undefined
      }
      displayMaskPlaceholder
    />
  );
};
