/**
 * Shared logic for Dialog related components.
 */

import { Dialog } from "@headlessui/react";
import clsx from "clsx";
import { PropsWithChildren, RefObject, useRef } from "react";

import { Box } from "./Box";
import { TextVariant } from "./types";

export const DIALOG_BACKGROUND_COLOR_CLASS_NAME = "bg-black/50";

/**
 *
 * Add a backdrop (overlay) behind Dialog.Panel. The actual dialog panel should be in children prop.
 *
 * See: https://headlessui.com/react/dialog#adding-a-backdrop
 */
export const DialogWithBackdrop = ({
  className,
  children,
  isOpen,
  onClose,
  testId = "DialogWithBackdrop",
  title,
  initialFocusRef,
}: PropsWithChildren<{
  className?: string;
  isOpen: boolean;
  onClose: (value: boolean) => void;
  testId?: string;
  title: string;
  initialFocusRef?: RefObject<HTMLElement> | null;
}>) => {
  const placeholderRef = useRef<HTMLDivElement | null>(null);

  return (
    <Dialog
      className={clsx("relative", "z-50", className)}
      data-testid={testId}
      onClose={onClose}
      open={isOpen}
      title={title}
      initialFocus={initialFocusRef || placeholderRef}
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div
        aria-hidden="true"
        className={clsx("fixed", "inset-0", DIALOG_BACKGROUND_COLOR_CLASS_NAME)}
        data-testid={`${testId}__DialogBackdrop`}
      />

      {/* Full-screen container to center the panel */}
      <Box
        className={clsx(
          "fixed",
          "inset-0",
          "flex",
          "items-center",
          "justify-center",
          "p-4"
        )}
        data-testid={`${testId}__DialogPanelContainer`}
        element="div"
        textVariant={TextVariant.MdRegularTall}
      >
        {/* Bit of a hack but we create this element so we can put the initial focus on it to avoid letting headless overlay focus things on its own
        // e.g. In the date picker, the month selector dropdown gets the focus being the first element
         on iOs, it causes the dropdown to open if its focused which we want to avoid on dialog open
         Use cases that need to deliberately set an initial focus should pass initalFocusRef parameter  */}
        <div className="hidden" ref={placeholderRef}></div>
        {/* The actual dialog panel  */}
        {children}
      </Box>
    </Dialog>
  );
};
