import { useCallback } from "react";
import { useIntl } from "react-intl";

import { useTypeaheadOptions__SearchDiagnosisCodesLazyQuery } from "../memberPortalApi-TypeaheadOptions.generated";

export const TYPEAHEAD_OPTIONS_LIMIT = 10;

export const useFetchDiagnosisCodeOptions = () => {
  const { formatMessage } = useIntl();
  const [searchDiagnosisCodes, { loading: areDiagnosisCodeOptionsLoading }] =
    useTypeaheadOptions__SearchDiagnosisCodesLazyQuery();

  const fetchDiagnosisCodeOptions = useCallback(
    async (queryString: string) => {
      const response = await searchDiagnosisCodes({
        variables: {
          search: queryString,
          limit: TYPEAHEAD_OPTIONS_LIMIT,
        },
      });

      if (response.error || !response.data) {
        throw new Error(
          formatMessage({
            defaultMessage: "There was an error searching for diagnosis codes.",
            id: "dezlZf",
          })
        );
      }

      const results = response.data.searchDiagnosisCodes;

      // TODO: Add i18n support
      return results.map((result) => ({
        label: formatMessage(
          {
            defaultMessage: "{code} - {description}",
            id: "WH38SH",
          },
          {
            code: result.code,
            description: result.description,
          }
        ),
        value: result,
      }));
    },
    [searchDiagnosisCodes, formatMessage]
  );

  return { fetchDiagnosisCodeOptions, areDiagnosisCodeOptionsLoading };
};
