import {
  InsuranceCard__ActiveUser__MemberFragment,
  InsuranceCard__MemberFragment,
} from "@chp/shared/hooks/useInsuranceCardLogic/memberPortalApi-useInsuranceCardLogic.generated";

import { MemberAddress } from "../../../generated/memberPortalApi.graphql";
import { MemberCardVariant } from "../enums";

export type InsuranceCardEnrollment =
  NonNullable<InsuranceCard__ActiveUser__MemberFragment>["displayedEnrollment"];

export type CountyElement = {
  zipCode: string;
  city: string;
};

export type InsuranceCardMember = Pick<
  InsuranceCard__MemberFragment,
  | "closestMemberPlanYear"
  | "externalId"
  | "givenName"
  | "id"
  | "middleName"
  | "familyName"
  | "front"
  | "back"
  | "bothSides"
  | "displayedCoveragePeriod"
> & {
  displayedEnrollment?: InsuranceCardEnrollment | null;
  address?: MemberAddress | null;
};

export type MemberCardConfig = {
  memberCardVariant: MemberCardVariant;
  logo: JSX.Element | null;
  insuranceDepartment: InsuranceDepartment | null;
};

export enum InsuranceDepartment {
  TDI = "TDI",
  DOI = "DOI",
}

export type MemberCardDownloadFileExtension = "jpeg" | "png";
