import clsx from "clsx";

import { SpacingVariant } from "../../constants";
import { Box } from "../Box";
import { Icon, IconVariant } from "../Icon";
import { CUIComponent, CUIComponentProps, HeadingLevel } from "../types";

export type SelectableListItemProps = CUIComponentProps<{
  description?: string | JSX.Element;
  heading: string | JSX.Element;
  headingLevel?: HeadingLevel;
  defaultIcon?: IconVariant;
  selectedIcon?: IconVariant;
  isSelected: boolean;
  onClick: () => void;
  key: string | number;
  subheading?: string | JSX.Element;
}>;

export const SelectableListItem: CUIComponent<SelectableListItemProps> = ({
  description,
  heading,
  headingLevel = "h3",
  defaultIcon = IconVariant.DOCUMENT_TEXT,
  selectedIcon = IconVariant.DOCUMENT_CHECK,
  isSelected,
  subheading,
  className,
  testId = "SelectableListItem",
  onClick,
}) => {
  return (
    <li className={clsx("list-none", className)} data-testid={testId}>
      <button
        className={clsx(
          "grid grid-cols-[auto_1fr] gap-x-2 px-6 py-4",
          "w-full cursor-pointer hover:bg-gray-100",
          "rounded-lg hover:text-blue-500",
          isSelected && "bg-gray-100"
        )}
        onClick={onClick}
        data-testid={`${testId}__Button`}
      >
        <Icon
          variant={isSelected ? selectedIcon : defaultIcon}
          size={SpacingVariant.S24}
          className={clsx(isSelected && "text-blue-700")}
        />
        <div className="flex flex-col gap-y-2 text-left">
          <Box
            element={headingLevel}
            className={clsx("text-lg underline", isSelected && "text-blue-700")}
            data-testid={`${testId}__Heading`}
          >
            {heading}
          </Box>
          {subheading && <p className="text-textColor-default">{subheading}</p>}
          {description && (
            <p className="text-sm-tall text-textColor-default">{description}</p>
          )}
        </div>
      </button>
    </li>
  );
};

export type ListItem = Pick<
  SelectableListItemProps,
  | "heading"
  | "description"
  | "className"
  | "defaultIcon"
  | "selectedIcon"
  | "subheading"
  | "testId"
  | "key"
>;

export type SelectableListProps = CUIComponentProps<{
  listItems: Array<ListItem>;
  selectedListItem: ListItem | null;
  onChangeSelection: (item: ListItem) => void;
}>;

export const SelectableList: CUIComponent<SelectableListProps> = ({
  listItems,
  onChangeSelection,
  selectedListItem,
  testId = "SelectableList",
  className,
}) => {
  return (
    <ul className={clsx(className)} data-testid={testId}>
      {listItems.map((listItem) => (
        <SelectableListItem
          {...listItem}
          key={listItem.key}
          onClick={() => onChangeSelection(listItem)}
          isSelected={selectedListItem?.key === listItem.key}
        />
      ))}
    </ul>
  );
};
