/* eslint-disable @typescript-eslint/no-explicit-any */

export const ORDER_DESC = "desc";
export const ORDER_ASC = "asc";
export type OrderableColumn = string;
export type OrderDirection = typeof ORDER_DESC | typeof ORDER_ASC;
export type OrderSpec = [OrderableColumn, OrderDirection];

export interface DownloadCSVProps {
  searchQuery?: string;
  filters?: Record<string, any>;
}

export type ItemPage<T> = {
  items: Array<T>;
  afterCursor?: string | null;
  beforeCursor?: string | null;
};

export enum SmartTableFilterDisplay {
  Always = "always",
  Never = "never",
  WhenActive = "whenActive",
}

export interface SmartTableFilter {
  key: string;
  type: string;
  component: (props: any) => JSX.Element;
  display?: SmartTableFilterDisplay;
  defaultValue?: Record<string, any> | null;
}

export interface FetchDataInput {
  pageSize: number;
  after?: string | null;
  before?: string | null;
  orderBy: OrderSpec;
  filters?: Record<string, any>;
  searchQuery: string;
}
