import { useElements, useStripe } from "@stripe/react-stripe-js";
import {
  StripeIssuingCardCopyButtonElement,
  StripeIssuingCardCvcDisplayElement,
  StripeIssuingCardExpiryDisplayElement,
  StripeIssuingCardNumberDisplayElement,
} from "@stripe/stripe-js";
import React, { ReactNode, useCallback, useContext, useState } from "react";

export enum PaymentCardType {
  Physical = "PHYSICAL",
  Virtual = "VIRTUAL",
}

export type PaymentCard = {
  stripeId: string;
  cardType: PaymentCardType;
};

export type CardDetail = {
  info: PaymentCard;
  elements: CardElements | null;
};

export type CardElements = {
  number: StripeIssuingCardNumberDisplayElement;
  expiry: StripeIssuingCardExpiryDisplayElement;
  cvc: StripeIssuingCardCvcDisplayElement;
  copyButton: StripeIssuingCardCopyButtonElement;
};

export type MemberPaymentCardsContext = {
  cards: { [key in PaymentCardType]: CardDetail | null };
  loadCard: (type: PaymentCardType) => Promise<void>; // returns void because the loaded values end up in the state
};

export type EphemeralKeyFactory = (
  cardId: string,
  nonce: string
) => Promise<string>;

const PaymentCardsContext = React.createContext<MemberPaymentCardsContext>({
  cards: {
    PHYSICAL: null,
    VIRTUAL: null,
  },
  loadCard: async () => {},
});

const elementsStyle = {
  base: {
    color: "#000000",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "IBM Plex Mono",
  },
};

// Populate the raw card details
const cardNumberStyle = {
  base: {
    ...elementsStyle.base,
    letterSpacing: "0.1em",
  },
};

export const usePaymentCardsContext = () => useContext(PaymentCardsContext);

export const PaymentCardsProvider = ({
  children,
  cards: cardInfo,
  ephemeralKeyFactory,
}: {
  children: ReactNode;
  cards: PaymentCard[];
  ephemeralKeyFactory: EphemeralKeyFactory;
}) => {
  const stripe = useStripe();
  const elementFactory = useElements();

  const cardDetails: CardDetail[] = cardInfo.map((c) => ({
    info: { ...c },
    elements: null,
  }));

  const [cards, setCards] = useState<{
    [key in PaymentCardType]: CardDetail | null;
  }>({
    PHYSICAL:
      cardDetails.find((c) => c.info.cardType === PaymentCardType.Physical) ??
      null,
    VIRTUAL:
      cardDetails.find((c) => c.info.cardType === PaymentCardType.Virtual) ??
      null,
  });
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});

  const loadCard = useCallback(
    async (type: PaymentCardType) => {
      if (!stripe || !elementFactory || !cards[type]) {
        return;
      }

      const card = cards[type]!;

      // if we are loading or already finished loading, move on
      if (loading[type] || card.elements) {
        return;
      }

      setLoading((value) => ({
        ...value,
        [type]: true,
      }));

      const stripeId = card.info.stripeId;

      const { nonce } = await stripe.createEphemeralKeyNonce({
        issuingCard: card.info.stripeId,
      });

      // @ts-ignore
      const ephemeralKey = await ephemeralKeyFactory(stripeId, nonce!);

      const number = elementFactory.create("issuingCardNumberDisplay", {
        issuingCard: stripeId,
        ephemeralKeySecret: ephemeralKey,
        nonce: nonce,
        style: cardNumberStyle,
      });

      const copyButton = elementFactory.create("issuingCardCopyButton", {
        toCopy: "number",
        style: {
          base: {
            fontSize: "50px",
            // This isn't great and Stripe recommends against this but they use it in their own examples and seems to be
            // the only way to set the iframe container's height
            // For this component their docs say its supposed to use the parent containers dimensions but it only uses
            // the width and we have to set the height manually
            lineHeight: "24px",
          },
        },
      });

      const expiry = elementFactory.create("issuingCardExpiryDisplay", {
        issuingCard: stripeId,
        ephemeralKeySecret: ephemeralKey,
        nonce: nonce,
        style: elementsStyle,
      });

      const cvc = elementFactory.create("issuingCardCvcDisplay", {
        issuingCard: stripeId,
        ephemeralKeySecret: ephemeralKey,
        nonce: nonce,
        style: elementsStyle,
      });

      setCards((value) => ({
        ...value,
        [type]: {
          ...value[type],
          elements: {
            number,
            cvc,
            expiry,
            copyButton,
          },
        },
      }));

      setLoading((value) => ({
        ...value,
        [type]: false,
      }));
    },
    [stripe, elementFactory, cards, loading, ephemeralKeyFactory]
  );

  const providerValue = {
    cards,
    loadCard,
  };

  return (
    <PaymentCardsContext.Provider value={providerValue}>
      {children}
    </PaymentCardsContext.Provider>
  );
};
