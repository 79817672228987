import { filterOptions } from "@chp/curative_ui";

import { MemberAddressState, MemberAddressStateOption } from "../../types";

export const findMemberAddressStateOption = ({
  stateAbbreviation,
}: {
  stateAbbreviation: string;
}): MemberAddressStateOption | null =>
  memberAddressStateOptions.find(
    (option) => option.value.valueOf() === stateAbbreviation.toUpperCase()
  ) ?? null;

// display names for MemberAddressState
// https://pe.usps.com/text/pub28/28apb.htm
export const memberAddressStateDisplayNames: Record<
  MemberAddressState,
  string
> = {
  [MemberAddressState.AK]: "Alaska",
  [MemberAddressState.AL]: "Alabama",
  [MemberAddressState.AR]: "Arkansas",
  [MemberAddressState.AS]: "American Samoa",
  [MemberAddressState.AZ]: "Arizona",
  [MemberAddressState.CA]: "California",
  [MemberAddressState.CO]: "Colorado",
  [MemberAddressState.CT]: "Connecticut",
  [MemberAddressState.DC]: "District of Columbia",
  [MemberAddressState.DE]: "Delaware",
  [MemberAddressState.FL]: "Florida",
  [MemberAddressState.FM]: "Federated States of Micronesia",
  [MemberAddressState.GA]: "Georgia",
  [MemberAddressState.GU]: "Guam",
  [MemberAddressState.HI]: "Hawaii",
  [MemberAddressState.IA]: "Iowa",
  [MemberAddressState.ID]: "Idaho",
  [MemberAddressState.IL]: "Illinois",
  [MemberAddressState.IN]: "Indiana",
  [MemberAddressState.KS]: "Kansas",
  [MemberAddressState.KY]: "Kentucky",
  [MemberAddressState.LA]: "Louisiana",
  [MemberAddressState.MA]: "Massachusetts",
  [MemberAddressState.MD]: "Maryland",
  [MemberAddressState.ME]: "Maine",
  [MemberAddressState.MH]: "Marshall Islands",
  [MemberAddressState.MI]: "Michigan",
  [MemberAddressState.MN]: "Minnesota",
  [MemberAddressState.MO]: "Missouri",
  [MemberAddressState.MP]: "Northern Mariana Islands",
  [MemberAddressState.MS]: "Mississippi",
  [MemberAddressState.MT]: "Montana",
  [MemberAddressState.NC]: "North Carolina",
  [MemberAddressState.ND]: "North Dakota",
  [MemberAddressState.NE]: "Nebraska",
  [MemberAddressState.NH]: "New Hampshire",
  [MemberAddressState.NJ]: "New Jersey",
  [MemberAddressState.NM]: "New Mexico",
  [MemberAddressState.NV]: "Nevada",
  [MemberAddressState.NY]: "New York",
  [MemberAddressState.OH]: "Ohio",
  [MemberAddressState.OK]: "Oklahoma",
  [MemberAddressState.OR]: "Oregon",
  [MemberAddressState.PA]: "Pennsylvania",
  [MemberAddressState.PR]: "Puerto Rico",
  [MemberAddressState.PW]: "Palau",
  [MemberAddressState.RI]: "Rhode Island",
  [MemberAddressState.SC]: "South Carolina",
  [MemberAddressState.SD]: "South Dakota",
  [MemberAddressState.TN]: "Tennessee",
  [MemberAddressState.TX]: "Texas",
  [MemberAddressState.UT]: "Utah",
  [MemberAddressState.VA]: "Virginia",
  [MemberAddressState.VI]: "Virgin Islands",
  [MemberAddressState.VT]: "Vermont",
  [MemberAddressState.WA]: "Washington",
  [MemberAddressState.WI]: "Wisconsin",
  [MemberAddressState.WV]: "West Virginia",
  [MemberAddressState.WY]: "Wyoming",
};

export const memberAddressStateOption = (
  memberAddressState: MemberAddressState
): MemberAddressStateOption => ({
  // use abbreviation for both label & value
  label: memberAddressStateDisplayNames[memberAddressState],
  value: memberAddressState,
});

// list of member address states sorted by display name
export const memberAddressStateOptions: MemberAddressStateOption[] =
  Object.values(MemberAddressState).sort().map(memberAddressStateOption);

// filter by both abbreviation & display name
export const filterMemberAddressStateOptions = (
  queryString: string
): MemberAddressStateOption[] => {
  const queryStringUpper = queryString.toUpperCase();
  const fuzzyResults = filterOptions({
    queryString,
    options: memberAddressStateOptions,
    fuzzy: true,
  });
  const abbrevResults = memberAddressStateOptions.filter((option) =>
    option.value.toUpperCase().includes(queryStringUpper)
  );
  return Array.from(new Set([...abbrevResults, ...fuzzyResults]));
};

export const fetchMemberAddressStatesOptions = (
  queryString: string
): Promise<MemberAddressStateOption[]> => {
  const optionsFiltered =
    queryString === ""
      ? memberAddressStateOptions
      : filterMemberAddressStateOptions(queryString);
  return new Promise((resolve) => resolve(optionsFiltered));
};
