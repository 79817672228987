import { defaultFormatMessage } from "@chp/shared/features/i18n/utils";
import { IntlFormatters } from "react-intl";
import * as Yup from "yup";

const SSN_PATTERN = /^\d{3}-\d{2}-\d{4}$/i;

// State for tracking validated and invalid SSNs to prevent redundant API calls
const validatedSSNs = new Set<string>();
const invalidSSNs = new Set<string>();

export const ssnSchema = (
  formatMessage: IntlFormatters["formatMessage"] = defaultFormatMessage
) =>
  Yup.string().matches(
    SSN_PATTERN,
    formatMessage({ defaultMessage: "SSN must be 9 digits", id: "KG4ukG" })
  );

export const ssnValidationSchema = (
  formatMessage: IntlFormatters["formatMessage"] = defaultFormatMessage,
  checkIfMemberRecordAlreadyExists?: (
    ssn: string
  ) => Promise<boolean | undefined>
) =>
  ssnSchema(formatMessage)
    .required(
      formatMessage({
        defaultMessage: "Social Security Number is required.",
        id: "8xun06",
      })
    )
    .test(
      "cannot-add-existing-member",
      formatMessage({
        defaultMessage:
          "Invalid Social Security Number, contact account representative for assistance.",
        id: "iSoOBM",
      }),
      async (ssn) => {
        // Skip API call if SSN is already known to be invalid
        if (invalidSSNs.has(ssn)) {
          return false;
        }

        // Early return for conditions that don't require an API check
        if (
          checkIfMemberRecordAlreadyExists === undefined ||
          ssn.includes("_") ||
          validatedSSNs.has(ssn)
        ) {
          return true;
        }

        // Check if the SSN matches an existing member
        const memberAlreadyExists = await checkIfMemberRecordAlreadyExists(ssn);

        // Update sets based on the existence check
        memberAlreadyExists ? invalidSSNs.add(ssn) : validatedSSNs.add(ssn);

        return !memberAlreadyExists;
      }
    );

// This function does not test for a 4 digit string, but rather for a valid SSN
export const isValidLast4DigitsOfSSN = (
  last4: string,
  formatMessage: IntlFormatters["formatMessage"] = defaultFormatMessage
): boolean => Boolean(last4) && ssnSchema(formatMessage).isValidSync(last4);
