import { Icon, IconVariant, SpacingVariant } from "@chp/curative_ui";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export type ScrollToBottomButtonProps = {
  onClick: () => void;
};

export const ScrollToBottomButton: FC<ScrollToBottomButtonProps> = ({
  onClick,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className="fixed bottom-0 w-[inherit] bg-gradient-to-t from-white via-white/90 pt-12 text-center md:pt-16">
      <button
        onClick={onClick}
        className="items-center p-4 text-lg-short font-extrabold md:p-6"
        aria-label={formatMessage({
          defaultMessage: "Scroll to bottom",
          id: "p4l9m9",
        })}
      >
        <div className="flex flex-col items-center">
          <span className="block">
            <FormattedMessage defaultMessage="Scroll to bottom" id="p4l9m9" />
          </span>
          <span className="block">
            <Icon
              variant={IconVariant.CHEVRON_DOWN}
              size={SpacingVariant.S16}
            />
          </span>
        </div>
      </button>
    </div>
  );
};
