import { useCallback } from "react";
import { useIntl } from "react-intl";

import { formatStreetAddress } from "../../utils";
import { useTypeaheadOptions__SearchPharmaciesLazyQuery } from "../memberPortalApi-TypeaheadOptions.generated";

export const useFetchPharmacyOptions = () => {
  const { formatMessage } = useIntl();
  const [searchPharmacies, { loading: arePharmacyOptionsLoading }] =
    useTypeaheadOptions__SearchPharmaciesLazyQuery();

  const fetchPharmacyOptions = useCallback(
    async (query: string, zipCode: string) => {
      const response = await searchPharmacies({
        variables: {
          query,
          zipCode,
        },
      });

      if (response.error || !response.data) {
        throw new Error(
          formatMessage({
            defaultMessage: "There was an error searching for pharmacies.",
            id: "8f4VK2",
          })
        );
      }

      const results = response.data.searchPharmacies;

      return results.map((result) => ({
        label: result.name,
        label2: formatStreetAddress(result),
        value: result,
      }));
    },
    [searchPharmacies, formatMessage]
  );

  return {
    fetchPharmacyOptions,
    arePharmacyOptionsLoading,
  };
};
