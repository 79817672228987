import { DEFAULT_LOCALE } from "@chp/shared/constants";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";

import type { RequiredLegalAgreementProps } from "../types";

export interface LegalAgreementDocumentProps {
  legalAgreement: RequiredLegalAgreementProps;
}

export const LegalAgreementDocument = ({
  legalAgreement: { agreementMarkdown },
}: LegalAgreementDocumentProps) => (
  <ReactMarkdown
    className="text-left"
    remarkPlugins={[
      // The "remarkBreaks" plugin allows us to use newlines (i.e. "\n") as a line break, which
      // is not part of the official markdown specification
      remarkBreaks,
    ]}
    components={{
      li: ({ ...props }) => <li className="my-4 ml-4 list-disc" {...props} />,
      p: ({ ...props }) => (
        <p className="my-4" lang={DEFAULT_LOCALE} {...props} />
      ),
      a: ({ ...props }) => (
        <a
          target="_blank"
          className="block pb-2 text-textColor-highlight-default underline hover:text-textColor-highlight-hover hover:no-underline"
          rel="noreferrer"
          {...props}
        />
      ),
      h1: ({ ...props }) => <h1 className="font-bold" {...props} />,
      h2: ({ ...props }) => <h2 className="font-bold" {...props} />,
      h3: ({ ...props }) => <h3 className="font-bold" {...props} />,
    }}
  >
    {agreementMarkdown || ""}
  </ReactMarkdown>
);
