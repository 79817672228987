/**
 * Shared focus outline ring class names for focus-able elements
 *
 * See: https://tailwindcss.com/docs/hover-focus-and-other-states#hover-focus-and-active
 * See: https://tailwindcss.com/docs/ring-width
 * See: https://tailwindcss.com/docs/ring-offset-width
 * See: https://tailwindcss.com/docs/ring-color
 * See: https://tailwindcss.com/docs/outline-style
 *
 */
export const FOCUS_CLASS_NAMES: string[] = [
  "focus:outline-none",
  "focus:ring",
  "focus:ring-offset-2",
  "focus:ring-focused-default",
  "focus-visible:outline-none",
  "focus-visible:ring",
  "focus-visible:ring-offset-2",
  "focus-visible:ring-focused-default",
];

/**
 * Same styles as FOCUS_CLASS_NAMES but without focus: or focus-visible: pseudo-classes. Indicate focus-like state when
 * FOCUS_CLASS_NAMES is not sufficient.
 */
export const FOCUS_LIKE_CLASS_NAMES: string[] = [
  "outline-none",
  "ring",
  "ring-offset-2",
  "ring-focused-default",
];

/**
 * Suppress focus styles when we do not want to indicate focus state.
 */
export const FOCUS_NONE_CLASS_NAMES: string[] = [
  "focus:outline-none",
  "focus-visible:outline-none",
];
