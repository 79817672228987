import {
  Icon,
  IconVariant,
  inlineLinkStyling,
  SpacingVariant,
} from "@chp/curative_ui";
import clsx from "clsx";
import Link from "next/link";

export interface ProviderSearchShortcutProps {
  className?: string;
  iconVariant: IconVariant;
  label: string;
  providerFacilitySearchURI: string;
  queryParams: {
    location?: string;
    serviceType?: string;
    searchType?: string;
    specialty?: string;
  };
}
export const ProviderSearchShortcut = ({
  className,
  iconVariant,
  label,
  providerFacilitySearchURI,
  queryParams: { location, serviceType, searchType, specialty },
}: ProviderSearchShortcutProps) => {
  return (
    <div className={clsx("flex items-center", className)}>
      <Icon variant={iconVariant} size={SpacingVariant.S20} className="mr-1" />
      <Link
        href={{
          pathname: `${providerFacilitySearchURI}/search`,
          query: {
            location,
            specialty: specialty?.toLowerCase(),
            serviceType: serviceType?.toLowerCase(),
            searchType: searchType?.toLowerCase(),
            expand: 1,
          },
        }}
        target="_blank"
        className={clsx("text-md-tall", inlineLinkStyling)}
      >
        {label}
      </Link>
    </div>
  );
};
