import { FormattedModal } from "@chp/shared";
import { formatDuration } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

export const IdleLogoutModal = ({
  isOpen,
  msToLogout,
  onButtonClick,
}: {
  isOpen: boolean;
  msToLogout: number | undefined;
  onButtonClick: () => void;
}) => {
  const { formatMessage } = useIntl();

  const formatLogoutTime = useCallback(
    (millisecondsToLogout?: number) => {
      if (!millisecondsToLogout) {
        return formatMessage({ defaultMessage: "0 seconds", id: "j34dGY" });
      }
      const mins = Math.floor(millisecondsToLogout / 1000 / 60);
      const secs = Math.floor(millisecondsToLogout / 1000) % 60;

      return formatDuration({ minutes: mins, seconds: secs });
    },
    [formatMessage]
  );

  const [formattedLogoutTime, setFormattedLogoutTime] = useState(
    formatLogoutTime(msToLogout)
  );

  useEffect(() => {
    setFormattedLogoutTime(formatLogoutTime(msToLogout));
  }, [msToLogout, formatLogoutTime]);

  return (
    <FormattedModal
      isOpen={isOpen}
      title={formatMessage({
        defaultMessage: "Are you still using the portal?",
        id: "ooanNc",
      })}
      message={formatMessage(
        {
          defaultMessage:
            "You haven't been active in a while. You will be logged out in {formattedLogoutTime}. Click the button if you'd like to continue.",
          id: "ilFB+n",
        },
        {
          formattedLogoutTime,
        }
      )}
      buttonText={formatMessage({
        defaultMessage: "Continue session",
        id: "AstaIr",
      })}
      onButtonClick={onButtonClick}
      onRequestClose={onButtonClick}
    />
  );
};
export default IdleLogoutModal;
