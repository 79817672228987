import clsx from "clsx";
import { Locale as DateFnsLocale } from "date-fns";
import { enUS, es } from "date-fns/locale";
import { ClassNames, DayPicker, DayPickerSingleProps } from "react-day-picker";

import { SpacingVariant } from "../../constants";
import { buttonBaseClassNames } from "../../utils";
import { textColorsCommon } from "../colors";
import { MARGIN_RIGHT_CLASS_NAMES, TEXT_CLASS_NAMES } from "../constants";
import { CUIComponentProps, Locale, TextVariant } from "../types";

// keep in sync with react-day-picker package version
// see: https://github.com/gpbl/react-day-picker/blob/13ec7cd078fc17602fe12196dad24f2b44c745ce/packages/react-day-picker/src/types/Styles.ts
// see: https://github.com/gpbl/react-day-picker/blob/13ec7cd078fc17602fe12196dad24f2b44c745ce/packages/react-day-picker/src/style.css.d.ts
// see: https://github.com/gpbl/react-day-picker/blob/13ec7cd078fc17602fe12196dad24f2b44c745ce/packages/react-day-picker/src/style.css
function getClassNames({ isFullWidth }: { isFullWidth: boolean }): ClassNames {
  return {
    button: clsx(
      "rdp-button",
      // button styles are like ButtonVariant.FLAT, but we need to override default colors for day_selected
      buttonBaseClassNames,
      "border",
      "rounded",
      "bg-transparent",
      "text-textColor-highlight-default",
      "active:bg-background-depressed",
      "active:text-textColor-onBg-primary",
      "hover:bg-transparent",
      "hover:text-textColor-highlight-depressed",
      "hover:underline",
      "disabled:bg-transparent",
      "disabled:no-underline",
      "disabled:text-textColor-subdued",
      "focus:bg-transparent",
      "focus:border-focused-default",
      "focus:text-textColor-highlight-default"
    ),
    button_reset: "rdp-button_reset",
    caption: clsx("rdp-caption", "bg-foundation-primary", "p-6", "rounded-t"),
    caption_between: "rdp-caption_between",
    caption_dropdowns: "rdp-caption_dropdowns",
    caption_end: "rdp-caption_end",
    caption_label: clsx(
      "rdp-caption_label",
      "!bg-transparent",
      buttonBaseClassNames,
      "border",
      "rounded",
      TEXT_CLASS_NAMES[TextVariant.XlRegular],
      "text-textColor-onBg-primary",
      // add peer-focus/peer-focus-visible styles similar to FOCUS_RING_CLASS_NAMES
      "peer-focus:outline-none",
      "peer-focus:ring",
      "peer-focus:ring-offset-2",
      "peer-focus:ring-focused-default",
      "peer-focus-visible:outline-none",
      "peer-focus-visible:ring",
      "peer-focus-visible:ring-offset-2",
      "peer-focus-visible:ring-focused-default",
      // undo rdp peer-focus-visible default styles
      "peer-focus-visible:bg-inherit",
      "peer-focus-visible:border-transparent"
    ),
    caption_start: "rdp-caption_start",
    cell: clsx("rdp-cell"),
    day: clsx("rdp-day", isFullWidth && ["w-full", "max-w-full"]),
    day_disabled: "rdp-day_disabled",
    day_hidden: "rdp-day_hidden",
    day_outside: "rdp-day_outside",
    day_range_end: "rdp-day_range_end",
    day_range_middle: "rdp-day_range_middle",
    day_range_start: "rdp-day_range_start",
    day_selected: clsx(
      "rdp-day_selected",
      // colors are like ButtonVariant.FLAT_PRIMARY. use higher specificity to override default button colors.
      "[&.rdp-button]:bg-foundation-primary",
      "[&.rdp-button]:text-textColor-onBg-primary",
      "[&.rdp-button]:active:bg-surface-highlight",
      "[&.rdp-button]:active:text-textColor-highlight-depressed",
      "[&.rdp-button]:hover:bg-foundation-primary",
      "[&.rdp-button]:hover:text-surface-highlight",
      "[&.rdp-button]:disabled:text-textColor-subdued",
      "[&.rdp-button]:disabled:bg-transparent",
      "[&.rdp-button]:focus:bg-foundation-primary",
      "[&.rdp-button]:focus:border-focused-default",
      "[&.rdp-button]:focus:text-textColor-onBg-primary"
    ),
    day_today: "rdp-day_today",
    dropdown: clsx("rdp-dropdown", "peer"),
    dropdown_icon: "rdp-dropdown_icon",
    dropdown_month: clsx(
      "rdp-dropdown_month",
      MARGIN_RIGHT_CLASS_NAMES[SpacingVariant.S16]
    ),
    dropdown_year: "rdp-dropdown_year",
    head: "rdp-head",
    head_cell: "rdp-head_cell",
    head_row: "rdp-head_row",
    month: clsx("rdp-month", isFullWidth && "w-full"),
    months: clsx("rdp-months", isFullWidth && "w-full"),
    multiple_months: "rdp-multiple_months",
    nav: "rdp-nav",
    nav_button: "rdp-nav_button",
    nav_button_next: "rdp-nav_button_next",
    nav_button_previous: "rdp-nav_button_previous",
    nav_icon: "rdp-nav_icon",
    root: clsx(
      "rdp",
      ...TEXT_CLASS_NAMES[TextVariant.MdRegularTall],
      ...textColorsCommon.default,
      "m-0",
      isFullWidth && "w-full"
    ),
    row: "rdp-row",
    table: clsx(
      "rdp-table",
      "px-6",
      "my-3",
      isFullWidth && ["w-full", "max-w-full"]
    ),
    tbody: "rdp-tbody",
    tfoot: "rdp-tfoot",
    vhidden: "rdp-vhidden",
    weeknumber: "rdp-weeknumber",
    with_weeknumber: "rdp-with_weeknumber",
  };
}

export type DatePickerProps = CUIComponentProps<
  Partial<Omit<DayPickerSingleProps, "locale" | "selected">> & {
    className?: string;
    locale: Locale;
    value?: Date;
    isFullWidth?: boolean;
  }
>;

const localeToDateFnsLocales: Record<Locale, DateFnsLocale> = {
  [Locale.ENGLISH]: enUS,
  [Locale.SPANISH]: es,
};

export const DatePicker = ({
  className,
  locale,
  value,
  isFullWidth = false,
  ...dayPickerProps
}: DatePickerProps) => {
  return (
    <DayPicker
      {...dayPickerProps}
      locale={localeToDateFnsLocales[locale]}
      selected={value}
      mode="single"
      captionLayout="dropdown"
      className={className}
      classNames={getClassNames({ isFullWidth })}
    />
  );
};
