import { Relationship } from "@chp/shared/generated/memberPortalApi.graphql";
import { IntlFormatters } from "react-intl";

import { FamilyCardMemberType } from "../enums";

export const getMemberTypeFromRelationship = (relationship: Relationship) => {
  switch (relationship) {
    case Relationship.Child:
      return FamilyCardMemberType.CHILD;
    case Relationship.Self:
      return FamilyCardMemberType.POLICYHOLDER;
    case Relationship.Spouse:
      return FamilyCardMemberType.SPOUSE;
    default:
      return FamilyCardMemberType.OTHER;
  }
};

export const memberTypeToText = ({
  formatMessage,
}: {
  formatMessage: IntlFormatters["formatMessage"];
}): Record<FamilyCardMemberType, string> => {
  return {
    [FamilyCardMemberType.CHILD]: formatMessage({
      defaultMessage: "Dependent - Child",
      id: "2AAxZq",
    }),
    [FamilyCardMemberType.OTHER]: formatMessage({
      defaultMessage: "Dependent",
      id: "R1OkyM",
    }),
    [FamilyCardMemberType.POLICYHOLDER]: formatMessage({
      defaultMessage: "Policyholder",
      id: "YONuwq",
    }),
    [FamilyCardMemberType.SPOUSE]: formatMessage({
      defaultMessage: "Dependent - Spouse",
      id: "CYjhcH",
    }),
  };
};
