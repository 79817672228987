import { Alert, AlertType } from "@chp/curative_ui";
import { useIntl } from "react-intl";

export const DegradedPerformanceAlert = () => {
  const { formatMessage } = useIntl();
  return (
    <Alert
      title={formatMessage({
        defaultMessage: "We'll be back up to speed shortly!",
        id: "gZZAtD",
      })}
      message={formatMessage({
        defaultMessage:
          "We are temporarily experiencing degraded performance. Thank you for your patience, we will be back soon!",
        id: "T4jqUt",
      })}
      type={AlertType.ERROR}
      shouldSetFocusOnMount
    />
  );
};
