import { useEffect, useRef, useState } from "react";

/**
 * Workaround for React 18's double-mounting behaviour in dev environments in
 * StrictMode, nicked from
 * https://dev.to/ag-grid/react-18-avoiding-use-effect-getting-called-twice-4i9e
 * (and tweaked slightly just to appease our linters).
 *
 * Useful for effects that are truly only meant to run once during the startup
 * of the application and never again thereafter - e.g. effects in providers
 * used in _app.tsx - and which have side effects that make running them twice
 * problematic. In all other cases, it's probably preferable to instead handle
 * unmounting and remounting cleanly.
 */
export const useEffectOnce = (effect: () => void | (() => void)) => {
  const destroyFunc = useRef<void | (() => void)>();
  const effectCalled = useRef(false);
  const renderAfterCalled = useRef(false);
  const [_, setVal] = useState<number>(0);

  if (effectCalled.current) {
    renderAfterCalled.current = true;
  }

  useEffect(() => {
    // only execute the effect first time around
    if (!effectCalled.current) {
      destroyFunc.current = effect();
      effectCalled.current = true;
    }

    // this forces one render after the effect is run
    setVal((val) => val + 1);

    return () => {
      // if the comp didn't render since the useEffect was called,
      // we know it's the dummy React cycle
      if (!renderAfterCalled.current) {
        return;
      }
      if (destroyFunc.current) {
        destroyFunc.current();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
