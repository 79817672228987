import { useEffect, useMemo, useState } from "react";

import { useActiveUser__GetMemberPlanYearsQuery } from "./memberPortalApi-useActiveUserPlanYearOptions.generated";

export type PlanYearOption = {
  label: string;
  value: number;
};

export const useActiveUserPlanYearOptions = () => {
  const [selectedPlanYearOption, setSelectedPlanYearOption] =
    useState<PlanYearOption | null>(null);

  const {
    data: dataAvailablePlanYears,
    error: errorAvailablePlanYears,
    loading: isLoadingAvailablePlanYears,
  } = useActiveUser__GetMemberPlanYearsQuery();

  // get planYearOptions from dataAvailablePlanYears if query was successful
  const planYearOptions: PlanYearOption[] = useMemo(() => {
    const planYears = dataAvailablePlanYears?.activeUser.member?.planYears;
    if (isLoadingAvailablePlanYears || errorAvailablePlanYears || !planYears) {
      return [];
    }

    return planYears.map<PlanYearOption>((planYear) => ({
      label: planYear.toString(),
      value: planYear,
    }));
  }, [
    dataAvailablePlanYears?.activeUser.member?.planYears,
    errorAvailablePlanYears,
    isLoadingAvailablePlanYears,
  ]);

  // if selectedPlanYearOption is empty, set to first item in planYearOptions
  useEffect(() => {
    if (selectedPlanYearOption) return;
    const planYearOptionsFirst = planYearOptions[0];
    if (planYearOptionsFirst) setSelectedPlanYearOption(planYearOptionsFirst);
  }, [planYearOptions, selectedPlanYearOption]);

  return {
    planYearOptions,
    selectedPlanYearOption,
    isLoadingAvailablePlanYears,
    setSelectedPlanYearOption,
  };
};
