import { DateInput, DateInputProps } from "@chp/curative_ui";
import { useContext } from "react";

// see https://github.com/curative/covid19lab/pull/15789 for context on import path
import { I18nContext, localeCodeToCuiLocales } from "../../features/i18n";
import { useErrorMessageWithPrefix } from "../../hooks";
import {
  DATE_INPUT_EMPTY_MASK_VALUE,
  DATE_INPUT_EMPTY_PLACEHOLDER_VALUE,
  DATE_INPUT_FORMAT,
} from "../../utils";

export type FormattedDateInputProps = Omit<
  DateInputProps,
  "dateInputFormat" | "emptyMaskValue" | "emptyPlaceholderValue" | "locale"
>;

export const FormattedDateInput = ({
  errorMessage,
  testId = "FormattedDateInput",
  ...rest
}: FormattedDateInputProps): JSX.Element => {
  const { locale } = useContext(I18nContext);
  const errorWithPrefix = useErrorMessageWithPrefix({ errorMessage });
  return (
    <DateInput
      dateInputFormat={DATE_INPUT_FORMAT}
      emptyMaskValue={DATE_INPUT_EMPTY_MASK_VALUE}
      emptyPlaceholderValue={DATE_INPUT_EMPTY_PLACEHOLDER_VALUE}
      errorMessage={errorWithPrefix}
      locale={localeCodeToCuiLocales[locale]}
      testId={testId}
      {...rest}
    />
  );
};
