import { MixpanelContext, TrackEventFn } from "../../features";
import { FCWithChildren } from "../../types";

export const MockMixpanelContextProvider: FCWithChildren<{
  trackEvent?: TrackEventFn;
}> = ({ children, trackEvent = jest.fn() }) => (
  <MixpanelContext.Provider
    value={{
      isMixpanelLoaded: false,
      trackEvent,
      trackPageViewEvent: jest.fn(),
      debouncedTrackEvent: jest.fn(),
      setDebounceMs: jest.fn(),
    }}
  >
    {children}
  </MixpanelContext.Provider>
);
