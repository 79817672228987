import clsx from "clsx";
import {
  FocusEventHandler,
  HTMLProps,
  RefCallback,
  RefObject,
  useMemo,
} from "react";

import { generateRandomString } from "../../utils/text";
import { Box } from "../Box";
import { Button, ButtonVariant } from "../Button";
import { FOCUS_RING_CLASS_NAMES, TEXT_CLASS_NAMES } from "../constants";
import {
  formFieldBorderClassNames,
  FormFieldDescription,
  formFieldErrorId,
  FormFieldErrorMessage,
  formFieldIconSize,
  FormFieldLabelText,
  formFieldTextVariant,
  formFieldWrapperMargin,
  useHasError,
} from "../forms";
import { Icon, IconVariant } from "../Icon";
import {
  CUIComponent,
  CUIComponentProps,
  FormFieldBaseProps,
  TextVariant,
} from "../types";

export type TextFieldProps = CUIComponentProps<
  FormFieldBaseProps & {
    autoComplete?: string;
    id?: string;
    htmlElement?: "input" | "textarea";
    innerRef?:
      | RefObject<HTMLInputElement | HTMLTextAreaElement>
      | RefCallback<HTMLInputElement | HTMLTextAreaElement>;
    isDisabled?: boolean;
    isRequired?: boolean;
    name?: string;
    onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onRequestChangeValue?(value: string): void;
    placeholder?: string;
    trailingText?: string;
    type?: string;
    pattern?: HTMLProps<HTMLInputElement>["pattern"];
    inputMode?: HTMLProps<HTMLInputElement>["inputMode"];
    value?: string;
    maxLength?: number;
    icon?: IconVariant;
    buttonIcon?: IconVariant;
    buttonCaption?: string;
    buttonType?: "submit" | "reset" | "button";
    buttonVariant?: ButtonVariant.Base | ButtonVariant.Primary;
    onClickButton?: () => void;
    onKeyDown?: (
      e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    inputClassName?: string;
    min?: number;
    max?: number;
  }
>;

export const getTextFieldClassNames = ({
  isDisabled = false,
  hasError = false,
  hasIcon = false,
  hasButton = false,
}: {
  isDisabled?: boolean;
  hasError?: boolean;
  hasIcon?: boolean;
  hasButton?: boolean;
}): string => {
  return clsx(
    ...FOCUS_RING_CLASS_NAMES,
    ...formFieldBorderClassNames({ hasError, isDisabled }),
    !isDisabled
      ? hasError
        ? "cursor-text bg-surface-critical text-textColor-default"
        : "cursor-text text-textColor-default hover:bg-surface-highlight"
      : "cursor-not-allowed bg-surface-disabled text-textColor-subdued",
    hasIcon && "pl-12",
    hasButton && "pr-9",
    "w-full",
    "rounded",
    "placeholder:text-textColor-subdued",
    "duration-200",
    "ease-in-out",
    TEXT_CLASS_NAMES[TextVariant.LgRegularTall],
    "px-4"
  );
};

export const TextField: CUIComponent<TextFieldProps> = ({
  autoComplete,
  className = "",
  inputClassName = "",
  description,
  errorMessage,
  htmlElement = "input",
  id: _id,
  innerRef,
  isDisabled,
  isLabelSrOnly,
  isRequired,
  label,
  name,
  onBlur,
  onFocus,
  onRequestChangeValue = () => {},
  placeholder,
  maxLength,
  trailingText,
  type = "text",
  pattern,
  inputMode,
  value,
  testId = "TextField",
  icon,
  buttonIcon,
  buttonCaption,
  buttonType,
  buttonVariant = ButtonVariant.Base,
  onClickButton,
  onKeyDown,
  min,
  max,
}) => {
  const id = useMemo(() => _id || generateRandomString(), [_id]);
  const hasError = useHasError({ errorMessage });
  const errorId = formFieldErrorId({ hasError, id });

  return (
    <Box
      className={className}
      element="div"
      testId={testId}
      textVariant={formFieldTextVariant}
    >
      <label data-testid="TextField__Label" className={clsx("block")}>
        <FormFieldLabelText isLabelSrOnly={isLabelSrOnly} label={label} />

        <FormFieldDescription description={description} />

        <Box
          className="relative"
          element="div"
          margin={formFieldWrapperMargin({ isLabelSrOnly })}
        >
          {icon && (
            <Icon
              className={clsx(
                "absolute",
                "top-[calc(50%_-_0.75em)]",
                "left-4",
                "text-textColor-subdued"
              )}
              size={formFieldIconSize}
              variant={icon}
            />
          )}
          {htmlElement === "input" ? (
            <input
              onBlur={onBlur}
              onFocus={onFocus}
              autoComplete={autoComplete}
              // 1password does not always respect `autocomplete="off"` for who knows why
              // https://1password.community/discussion/117501/as-a-web-developer-how-can-i-disable-1password-filling-for-a-specific-field/
              data-1p-ignore={autoComplete === "off"}
              data-testid={`${testId}__Input`}
              id={id}
              ref={
                innerRef as
                  | RefObject<HTMLInputElement>
                  | RefCallback<HTMLInputElement>
              }
              name={name}
              type={type}
              pattern={pattern}
              inputMode={inputMode}
              disabled={isDisabled}
              placeholder={placeholder}
              required={isRequired}
              value={value}
              maxLength={maxLength}
              onChange={(e) =>
                onRequestChangeValue && onRequestChangeValue(e.target.value)
              }
              onKeyDown={onKeyDown}
              className={clsx(
                getTextFieldClassNames({
                  isDisabled,
                  hasError,
                  hasIcon: Boolean(icon),
                  hasButton: Boolean(buttonIcon),
                }),
                inputClassName
              )}
              min={min}
              max={max}
              aria-describedby={errorId}
              aria-invalid={hasError}
            />
          ) : (
            <textarea
              onBlur={onBlur}
              onFocus={onFocus}
              autoComplete={autoComplete}
              data-testid={`${testId}__Input`}
              id={id}
              ref={
                innerRef as
                  | RefObject<HTMLTextAreaElement>
                  | RefCallback<HTMLTextAreaElement>
              }
              name={name}
              disabled={isDisabled}
              placeholder={placeholder}
              required={isRequired}
              value={value}
              maxLength={maxLength}
              onChange={(e) =>
                onRequestChangeValue && onRequestChangeValue(e.target.value)
              }
              onKeyDown={onKeyDown}
              className={clsx(
                getTextFieldClassNames({
                  isDisabled,
                  hasError,
                  hasIcon: Boolean(icon),
                  hasButton: Boolean(buttonIcon),
                }),
                inputClassName
              )}
              rows={4}
              aria-describedby={errorId}
              aria-invalid={hasError}
            />
          )}
          {trailingText && (
            <div
              className={clsx(
                "absolute",
                "right-2",
                "top-[calc(50%_-_0.75em)]",
                "flex"
              )}
            >
              <p className="text-right">{trailingText}</p>
            </div>
          )}
          {buttonIcon && (
            <Button
              testId={`${testId}__Button`}
              onClick={() => onClickButton && onClickButton()}
              type={buttonType}
              variant={buttonVariant}
              className={clsx(
                "absolute",
                "right-2",
                "top-[calc(50%_-_0.75em)]"
              )}
            >
              <Icon size={formFieldIconSize} variant={buttonIcon} />
              <span className="sr-only">{buttonCaption}</span>
            </Button>
          )}
        </Box>
      </label>
      {hasError && errorId && (
        <FormFieldErrorMessage
          errorMessage={errorMessage}
          testId={`${testId}__ErrorMessage`}
          id={errorId}
        />
      )}
    </Box>
  );
};
