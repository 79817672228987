import clsx from "clsx";

import { SpacingVariant } from "../../constants";
import { ActionButton } from "../ActionButton";
import { IconVariant } from "../Icon";
import { CUIComponent, CUIComponentProps } from "../types";
import { CommonPaginationProps } from "./Pagination";

export type CursorPaginationProps = CUIComponentProps<
  CommonPaginationProps & {
    firstPageText: string;
    onNextPageClick: () => void;
    onPreviousPageClick: () => void;
    onFirstPageClick: () => void;
    isPreviousPageButtonDisabled: boolean;
    isNextPageButtonDisabled: boolean;
    pageIndex: number;
    setPageIndex: (pageIndex: number) => void;
  }
>;

export const CursorPagination: CUIComponent<CursorPaginationProps> = ({
  ariaLabel,
  className,
  testId = "CursorPagination",
  onNextPageClick,
  onPreviousPageClick,
  onFirstPageClick,
  firstPageText,
  nextText,
  previousText,
  isPreviousPageButtonDisabled,
  isNextPageButtonDisabled,
  pageIndex,
  setPageIndex,
}) => {
  return (
    <nav
      className={clsx("flex gap-x-8", className)}
      aria-label={ariaLabel}
      data-testid={testId}
    >
      <ActionButton
        iconVariant={IconVariant.CHEVRON_DOUBLE_LEFT}
        onClick={() => {
          onFirstPageClick();
          setPageIndex(0);
        }}
        text={firstPageText}
        isDisabled={isPreviousPageButtonDisabled}
        onlyDisplayIcon
        className="hover:bg-gray-200"
        overrideIconSize={SpacingVariant.S16}
        testId={`${testId}__firstPageButton`}
      />
      <ActionButton
        iconVariant={IconVariant.CHEVRON_LEFT}
        onClick={() => {
          onPreviousPageClick();
          setPageIndex(pageIndex - 1);
        }}
        text={previousText}
        isDisabled={isPreviousPageButtonDisabled}
        onlyDisplayIcon
        className="hover:bg-gray-200"
        overrideIconSize={SpacingVariant.S16}
        testId={`${testId}__previousPageButton`}
      />
      <ActionButton
        iconVariant={IconVariant.CHEVRON_RIGHT}
        onClick={() => {
          onNextPageClick();
          setPageIndex(pageIndex + 1);
        }}
        text={nextText}
        isDisabled={isNextPageButtonDisabled}
        onlyDisplayIcon
        className="hover:bg-gray-200"
        overrideIconSize={SpacingVariant.S16}
        testId={`${testId}__nextPageButton`}
      />
    </nav>
  );
};
