import { useRouter } from "next/router";
import { ParsedUrlQueryInput } from "querystring";
import { useCallback, useMemo } from "react";

import { hashForLocation } from "../services";

export const useHashForLocation = ({
  latitude,
  longitude,
  pathname,
}: {
  latitude?: number;
  longitude?: number;
  pathname?: string;
}): {
  hash?: string;
  pushWithHash: ({ query }: { query?: ParsedUrlQueryInput }) => void;
} => {
  const hash: string | undefined = useMemo(
    () =>
      latitude !== undefined && longitude !== undefined
        ? hashForLocation({ latitude, longitude })
        : undefined,
    [latitude, longitude]
  );

  const router = useRouter();
  const pushWithHash: ({ query }: { query?: ParsedUrlQueryInput }) => void =
    useCallback(
      ({ query }: { query?: ParsedUrlQueryInput }) => {
        router.push({ pathname, query, hash });
      },
      [hash, pathname, router]
    );

  return { hash, pushWithHash };
};
