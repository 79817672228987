import { Button, ButtonVariant } from "@chp/curative_ui";
import {
  ProviderSearchResult,
  useFetchHEPractitionerOptions,
  useFetchMediviewProviderOptions,
  useFetchProviderOptions,
} from "@chp/shared/hooks/typeahead";
import { VBAProviderSearchResult } from "@chp/shared/types";
import { generateArrayKey } from "@chp/shared/utils/array";
import { FieldValues, Path } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { HEPractitionerSearchResult } from "../../../hooks/typeahead/useFetchHEPractitionerOptions";
import {
  MemberServicesEmailLink,
  MemberServicesPhoneNumberLink,
} from "../../links";
import { FormTypeahead, FormTypeaheadProps } from "./FormTypeahead";

export interface ProviderTypeaheadProps<T extends FieldValues> {
  label: string;
  name: Path<T>;
  className?: string;
  onClickAddProvider?: () => void;
  placeholder?: string;
  shouldUseMediviewProviders?: boolean;
  shouldUseHEProviders?: boolean;
  shouldResetFieldOnChange?: boolean;
  isDisabled?: boolean;
}

const testId = "ProviderTypeahead";

export function ProviderTypeahead<T extends FieldValues>({
  onClickAddProvider,
  shouldUseMediviewProviders,
  shouldUseHEProviders,
  ...rest
}: ProviderTypeaheadProps<T>) {
  const { fetchMediviewProviderOptions, areMediviewProviderOptionsLoading } =
    useFetchMediviewProviderOptions();
  const { fetchProviderOptions, areProviderOptionsLoading } =
    useFetchProviderOptions();
  const { fetchHEPractitionerOptions, areHEPractitionerOptionsLoading } =
    useFetchHEPractitionerOptions();
  const fetchProvidersProps: Pick<
    FormTypeaheadProps<
      T,
      | VBAProviderSearchResult
      | ProviderSearchResult
      | HEPractitionerSearchResult
    >,
    "fetchOptions" | "isLoading" | "queryStringMinLength"
  > = shouldUseMediviewProviders
    ? {
        fetchOptions: fetchMediviewProviderOptions,
        isLoading: areMediviewProviderOptionsLoading,
      }
    : shouldUseHEProviders
    ? {
        fetchOptions: fetchHEPractitionerOptions,
        isLoading: areHEPractitionerOptionsLoading,
      }
    : {
        fetchOptions: fetchProviderOptions,
        isLoading: areProviderOptionsLoading,
      };

  const noResultsTextOverride = (
    <>
      <FormattedMessage
        defaultMessage="No results match your search term."
        id="aZ6y2N"
      />{" "}
      <FormattedMessage
        defaultMessage="Please contact support at {supportNumber} or {supportEmail}."
        id="mcc19Q"
        values={{
          supportNumber: <MemberServicesPhoneNumberLink />,
          supportEmail: <MemberServicesEmailLink />,
        }}
      />
      {onClickAddProvider && (
        <span className="mt-3 block text-right">
          <Button
            onClick={onClickAddProvider}
            variant={ButtonVariant.BigOutline}
          >
            <FormattedMessage defaultMessage="Add a provider" id="PVMlio" />
          </Button>
        </span>
      )}
    </>
  );

  return (
    <FormTypeahead<
      T,
      | VBAProviderSearchResult
      | ProviderSearchResult
      | HEPractitionerSearchResult
    >
      {...rest}
      {...fetchProvidersProps}
      hasDropdownToggle={false}
      optionKeyExtractor={() => generateArrayKey()}
      testId={testId}
      noResultsTextWithQuery={noResultsTextOverride}
      noResultsTextWithoutQuery={noResultsTextOverride}
    />
  );
}
