import { defaultFormatMessage } from "@chp/shared/features/i18n/utils";
import { IntlFormatters } from "react-intl";
import * as Yup from "yup";

export const memberIdSchema = (
  formatMessage: IntlFormatters["formatMessage"] = defaultFormatMessage
) =>
  Yup.string().matches(
    /^[0-9a-zA-Z]+$/,
    formatMessage({
      defaultMessage: "The Member ID should be a series of letters and digits",
      id: "4eMLnq",
    })
  );

export const isValidMemberId = (
  memberId: string,
  formatMessage: IntlFormatters["formatMessage"] = defaultFormatMessage
): boolean =>
  Boolean(memberId) && memberIdSchema(formatMessage).isValidSync(memberId);
