import clsx from "clsx";

import { SpacingVariant } from "../constants";
import { PaddingSize } from "../types";
import {
  DISPLAY_CLASS_NAMES,
  HEIGHT_CLASS_NAMES,
  MARGIN_BOTTOM_CLASS_NAMES,
  MARGIN_LEFT_CLASS_NAMES,
  MARGIN_RIGHT_CLASS_NAMES,
  MARGIN_TOP_CLASS_NAMES,
  PADDING_BOTTOM_CLASS_NAMES,
  PADDING_LEFT_CLASS_NAMES,
  PADDING_RIGHT_CLASS_NAMES,
  PADDING_TOP_CLASS_NAMES,
  TEXT_ALIGN_CLASS_NAMES,
  TEXT_CLASS_NAMES,
  VERTICAL_ALIGN_CLASS_NAMES,
  WIDTH_CLASS_NAMES,
} from "./constants";
import {
  DisplayVariant,
  MarginProps,
  TextAlignVariant,
  TextVariant,
  VerticalAlignVariant,
} from "./types";

export const cuiClassNames = ({
  className,
  display,
  height,
  margin,
  padding,
  textAlign,
  textVariant,
  verticalAlign,
  width,
}: {
  className?: string;
  display?: DisplayVariant;
  height?: SpacingVariant;
  margin?: MarginProps;
  padding?: PaddingSize;
  textAlign?: TextAlignVariant;
  textVariant?: TextVariant;
  verticalAlign?: VerticalAlignVariant;
  width?: SpacingVariant;
}): string =>
  clsx(
    display ? DISPLAY_CLASS_NAMES[display] : null,
    height ? HEIGHT_CLASS_NAMES[height] : null,
    margin?.bottom ? MARGIN_BOTTOM_CLASS_NAMES[margin.bottom] : null,
    margin?.left ? MARGIN_LEFT_CLASS_NAMES[margin.left] : null,
    margin?.right ? MARGIN_RIGHT_CLASS_NAMES[margin.right] : null,
    margin?.top ? MARGIN_TOP_CLASS_NAMES[margin.top] : null,
    padding?.bottom ? PADDING_BOTTOM_CLASS_NAMES[padding.bottom] : null,
    padding?.left ? PADDING_LEFT_CLASS_NAMES[padding.left] : null,
    padding?.right ? PADDING_RIGHT_CLASS_NAMES[padding.right] : null,
    padding?.top ? PADDING_TOP_CLASS_NAMES[padding.top] : null,
    textAlign ? TEXT_ALIGN_CLASS_NAMES[textAlign] : null,
    textVariant ? TEXT_CLASS_NAMES[textVariant] : null,
    verticalAlign ? VERTICAL_ALIGN_CLASS_NAMES[verticalAlign] : null,
    width ? WIDTH_CLASS_NAMES[width] : null,
    className
  );
