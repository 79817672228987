export interface EmailBlockingConfiguration {
  blockedPatterns: string[];
  whitelistedPatterns: string[];
}

export type stateCoordsType = {
  maxLat: number;
  maxLng: number;
  minLat: number;
  minLng: number;
};

export type RestrictedPharmaLocsFFType = Record<string, stateCoordsType[]>;

export type OpenSearchIndexNamesType = {
  facilities?: string;
  offices?: string;
  providers?: string;
};

export enum ProductAdoptionFlowFFType {
  USER_PILOT = "USERPILOT",
  APP_CUES = "APPCUES",
  DISABLED = "DISABLED",
}
