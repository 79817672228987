import { setContext } from "@apollo/client/link/context";
import { Operation } from "@apollo/client/link/core";
import { ErrorResponse, onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import {
  CLIENT_NAME_HEADER_NAME,
  SESSION_ID_HEADER_NAME,
} from "@chp/shared/constants";
import { getOrCreateStoredSessionId } from "@chp/shared/utils/storage/sessionId";
import DebounceLink from "apollo-link-debounce";

import { fetchAccessToken } from "./accessToken";

export const DEFAULT_DEBOUNCE_TIMEOUT = 100;

const getNetworkErrorStatusCode = ({
  networkError,
}: ErrorResponse): number | undefined => {
  if (networkError && "statusCode" in networkError) {
    return networkError.statusCode;
  }

  return undefined;
};

export const getAppendHeadersLink = (clientName: string) => {
  const appendAuthHeaderLink = setContext((_, { headers }) => {
    const sessionId = getOrCreateStoredSessionId();
    // fetch the access token using the provided function
    // or fallback to local storage if it's not provided
    const token = fetchAccessToken();

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
        [SESSION_ID_HEADER_NAME]: sessionId,
        [CLIENT_NAME_HEADER_NAME]: clientName,
      },
    };
  });

  return appendAuthHeaderLink;
};

export const errorLink = onError((errors: ErrorResponse) => {
  const statusCode = getNetworkErrorStatusCode(errors);

  if (statusCode === 401) {
    window.location.href = "/";
  }
});

export const debounceLink = new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT);

// default config taken from https://www.npmjs.com/package/apollo-link-retry
const retryLinkOptions = {
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    retryIf: (error: any, operation: Operation) => {
      // operationNames should match the name of the query/mutation defined in .graphql files
      const doNotRetry = [
        "BaselineVisit_ScheduleBaselineVisitAvailability", // schedulingEffects.graphql
        "dashboard_CancelBaselineAppointment", // dashboard.graphql
        "AdminBaselineVisit_ScheduleBaselineVisit", // adminBaselineVisitScheduling.graphql
        "AdminBaselineVisit_ScheduleBaselineVisitNotSuspend", // adminBaselineVisitScheduling.graphql
        "MemberDetails__CancelBaselineVisit", // MemberDetailsContext/index.graphql
        "AdminPrimaryCare_SchedulePrimaryCare", // adminPrimaryCareScheduling.graphql
      ];
      return !!error && !doNotRetry.includes(operation.operationName);
    },
  },
};
export const retryLink = new RetryLink(retryLinkOptions);
