import { AlertType } from "@chp/curative_ui";
import { createContext, FC, PropsWithChildren, useState } from "react";

import { AuthenticateFormFields } from "../shared/form";
import { MemberState } from "../shared/form/types";

export const PRIOR_AUTH_FORM_FIELDS_KEY = "priorAuthFormFields";
export const EXPIRY_AT_SESSION_STORAGE_KEY = "priorAuthExpiryAt";

export type FormAlertConfig = {
  type: AlertType;
  title: string;
  message?: JSX.Element;
  shouldAutoDismiss?: boolean;
};

export enum PriorAuthApprovalStatusEnum {
  APPROVED = "Approved",
  PENDING = "Pending",
  DENIED = "Denied",
}

export type PriorAuthApprovalStatus = {
  status: PriorAuthApprovalStatusEnum;
  priorAuthId: string;
  memberId: string;
};

export type PriorAuthContextValue = {
  formAlertConfig: FormAlertConfig | null;
  setFormAlertConfig: (_: FormAlertConfig | null) => void;
  authenticateFormData: AuthenticateFormFields | null;
  setAuthenticateFormData: (_: AuthenticateFormFields | null) => void;
  priorAuthApprovalStatus: PriorAuthApprovalStatus | null;
  setPriorAuthApprovalStatus: (_: PriorAuthApprovalStatus | null) => void;
  memberState: MemberState;
  setMemberState: (_: MemberState) => void;
};

// Create context with default values
export const PriorAuthContext = createContext<PriorAuthContextValue>({
  formAlertConfig: null,
  priorAuthApprovalStatus: null,
  setFormAlertConfig: () => {},
  authenticateFormData: null,
  setAuthenticateFormData: () => {},
  setPriorAuthApprovalStatus: () => {},
  memberState: { isValid: false, claimSystem: null } as MemberState,
  setMemberState: () => {},
});

export const PriorAuthProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [formAlertConfig, setFormAlertConfig] =
    useState<FormAlertConfig | null>(null);

  const [authenticateFormData, setAuthenticateFormData] =
    useState<AuthenticateFormFields | null>(null);

  const [priorAuthApprovalStatus, setPriorAuthApprovalStatus] =
    useState<PriorAuthApprovalStatus | null>(null);

  const [memberState, setMemberState] = useState<MemberState>({
    isValid: false,
    claimSystem: null,
  } as MemberState);

  let formDataFromStorage: AuthenticateFormFields | null = null;

  const item = sessionStorage.getItem(PRIOR_AUTH_FORM_FIELDS_KEY);

  if (item) {
    formDataFromStorage = JSON.parse(item);
  }

  return (
    <PriorAuthContext.Provider
      value={{
        formAlertConfig,
        priorAuthApprovalStatus,
        setFormAlertConfig,
        authenticateFormData: formDataFromStorage || authenticateFormData,
        setAuthenticateFormData,
        setPriorAuthApprovalStatus,
        memberState,
        setMemberState,
      }}
    >
      {children}
    </PriorAuthContext.Provider>
  );
};
