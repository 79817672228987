import * as Types from '@chp/shared/generated/memberPortalApi.graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PackagedDrugFieldsFragment = { __typename?: 'PackagedDrug', packagedDrugId: number, routedDoseFormGenericId: number, dispensableDrugId: number, dispensableGenericId: number, dispensableDrugTallManDesc: string, genericDrugNameDesc: string, medStrength: string, medStrengthUnit: string, drugNameTallmanDesc: string };

export type DrugSearchTypeahead__SearchPackagedDrugsQueryVariables = Types.Exact<{
  searchTerm: Types.Scalars['String']['input'];
  shouldSearchMedicalDevices?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type DrugSearchTypeahead__SearchPackagedDrugsQuery = { __typename?: 'Query', searchPackagedDrugs?: Array<{ __typename?: 'PackagedDrug', packagedDrugId: number, routedDoseFormGenericId: number, dispensableDrugId: number, dispensableGenericId: number, dispensableDrugTallManDesc: string, genericDrugNameDesc: string, medStrength: string, medStrengthUnit: string, drugNameTallmanDesc: string }> | null };

export const PackagedDrugFieldsFragmentDoc = gql`
    fragment PackagedDrugFields on PackagedDrug {
  packagedDrugId
  routedDoseFormGenericId
  dispensableDrugId
  dispensableGenericId
  dispensableDrugTallManDesc
  genericDrugNameDesc
  medStrength
  medStrengthUnit
  drugNameTallmanDesc
}
    `;
export const DrugSearchTypeahead__SearchPackagedDrugsDocument = gql`
    query DrugSearchTypeahead__searchPackagedDrugs($searchTerm: String!, $shouldSearchMedicalDevices: Boolean) {
  searchPackagedDrugs(
    searchTerm: $searchTerm
    shouldSearchMedicalDevices: $shouldSearchMedicalDevices
  ) {
    ...PackagedDrugFields
  }
}
    ${PackagedDrugFieldsFragmentDoc}`;

/**
 * __useDrugSearchTypeahead__SearchPackagedDrugsQuery__
 *
 * To run a query within a React component, call `useDrugSearchTypeahead__SearchPackagedDrugsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDrugSearchTypeahead__SearchPackagedDrugsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDrugSearchTypeahead__SearchPackagedDrugsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      shouldSearchMedicalDevices: // value for 'shouldSearchMedicalDevices'
 *   },
 * });
 */
export function useDrugSearchTypeahead__SearchPackagedDrugsQuery(baseOptions: Apollo.QueryHookOptions<DrugSearchTypeahead__SearchPackagedDrugsQuery, DrugSearchTypeahead__SearchPackagedDrugsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DrugSearchTypeahead__SearchPackagedDrugsQuery, DrugSearchTypeahead__SearchPackagedDrugsQueryVariables>(DrugSearchTypeahead__SearchPackagedDrugsDocument, options);
      }
export function useDrugSearchTypeahead__SearchPackagedDrugsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DrugSearchTypeahead__SearchPackagedDrugsQuery, DrugSearchTypeahead__SearchPackagedDrugsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DrugSearchTypeahead__SearchPackagedDrugsQuery, DrugSearchTypeahead__SearchPackagedDrugsQueryVariables>(DrugSearchTypeahead__SearchPackagedDrugsDocument, options);
        }
export function useDrugSearchTypeahead__SearchPackagedDrugsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DrugSearchTypeahead__SearchPackagedDrugsQuery, DrugSearchTypeahead__SearchPackagedDrugsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DrugSearchTypeahead__SearchPackagedDrugsQuery, DrugSearchTypeahead__SearchPackagedDrugsQueryVariables>(DrugSearchTypeahead__SearchPackagedDrugsDocument, options);
        }
export type DrugSearchTypeahead__SearchPackagedDrugsQueryHookResult = ReturnType<typeof useDrugSearchTypeahead__SearchPackagedDrugsQuery>;
export type DrugSearchTypeahead__SearchPackagedDrugsLazyQueryHookResult = ReturnType<typeof useDrugSearchTypeahead__SearchPackagedDrugsLazyQuery>;
export type DrugSearchTypeahead__SearchPackagedDrugsSuspenseQueryHookResult = ReturnType<typeof useDrugSearchTypeahead__SearchPackagedDrugsSuspenseQuery>;
export type DrugSearchTypeahead__SearchPackagedDrugsQueryResult = Apollo.QueryResult<DrugSearchTypeahead__SearchPackagedDrugsQuery, DrugSearchTypeahead__SearchPackagedDrugsQueryVariables>;