export const FIRST_HEALTH_COMPLEMENTARY_URL_NO_PROTOCOL =
  "www.firsthealthcomplementary.com";
export const FIRST_HEALTH_URL_NO_PROTOCOL = "www.firsthealth.com";
export const MEMBER_PORTAL_FAMILY_ROUTE_RELATIVE = "/plan/family";
export const MY_FIRST_HEALTH_URL_NO_PROTOCOL = "www.myfirsthealth.com";
export const CMS_GOV_SITE_URL =
  "https://www.cms.gov/healthplan-price-transparency/resources/500-items-services";
export const FAQ_HEALTH_INSURANCE_101_URL =
  "https://curative.com/faq/health-insurance-101";
export const PHARMACY_MARKETING_URL = "https://curative.com/curative-pharmacy";
export const BASELINE_INFO_URL = "https://www.curative.com/baseline";
export const ELIGIBILITY_URL_SIMPLE = "curative.com/eligibility";

// Navigation
export const MEMBER_PORTAL_HOMEPAGE_URL = "https://health.curative.com";
export const FOR_MEMBERS_URL = "https://curative.com/for-members";
export const ABOUT_US_URL = "https://curative.com/about-us";

// Dashboard
export const FIND_A_PROVIDER_URL_NO_PROTOCOL = "curative.com/providers";
export const FIND_A_PROVIDER_URL = `https://${FIND_A_PROVIDER_URL_NO_PROTOCOL}`;
export const FAQ_URL = "https://curative.com/faq";
export const VIRTUAL_URGENT_CARE_URL =
  "https://curative.com/virtual-urgent-care";
export const FORMULARY_SEARCH_URL = "https://curative.com/drugs";

export const PRIMARY_CARE_URL = "https://curative.com/primary-care";
