const path = require("path");
const colors = require("./src/tokens/colors/theme");
const fontFamily = require("./src/tokens/fontFamily/theme");
const fontSize = require("./src/tokens/fontSize/theme");

module.exports = {
  content: [
    path.resolve(__dirname, "src/components/**/*.ts"),
    path.resolve(__dirname, "src/components/**/*.tsx"),
    `!${path.resolve(__dirname, "src/components/**/*.stories.tsx")}`,
    path.resolve(__dirname, "src/constants/**/*.ts"),
    path.resolve(__dirname, "src/constants/**/*.tsx"),
    path.resolve(__dirname, "src/utils/**/*.ts"),
    path.resolve(__dirname, "src/utils/**/*.tsx"),
  ],
  theme: {
    colors,
    fontFamily,
    fontSize,
    extend: {
      transitionProperty: {
        width: "width",
      },
    },
  },
  plugins: [
    require("./src/plugins/react-day-picker"),
    require("@tailwindcss/forms"),
    require("@tailwindcss/typography"),
    require("@tailwindcss/aspect-ratio"),
  ],
};
