import { Switch } from "@headlessui/react";
import clsx from "clsx";

import { FOCUS_RING_CLASS_NAMES } from "../constants";
import { CUIComponent, CUIComponentProps } from "../types";

export enum ToggleSize {
  LARGE = "LARGE",
  SMALL = "SMALL",
}

const toggleSizeClassNames: Record<
  ToggleSize,
  {
    buttonClassNames: string[];
    indicatorClassNames: string[];
    translateClassNames: string[];
  }
> = {
  [ToggleSize.LARGE]: {
    buttonClassNames: ["w-16"],
    indicatorClassNames: ["h-6", "w-6"],
    translateClassNames: ["translate-x-8"],
  },
  [ToggleSize.SMALL]: {
    buttonClassNames: ["w-8"],
    indicatorClassNames: ["h-3", "w-3"],
    translateClassNames: ["translate-x-3"],
  },
};

export type ToggleProps = CUIComponentProps<{
  onRequestChangeValue: (v: boolean) => void;
  size?: ToggleSize;
  srOnlyLabel: string;
  value: boolean;
}>;

export const Toggle: CUIComponent<ToggleProps> = ({
  className,
  onRequestChangeValue,
  size = ToggleSize.LARGE,
  srOnlyLabel,
  testId = "Toggle",
  value,
}) => {
  return (
    <Switch
      data-testid={testId}
      checked={value}
      onChange={onRequestChangeValue}
      className={clsx(
        value ? "bg-foundation-primary" : "bg-textColor-subdued",
        "border-none",
        "duration-200",
        "ease-in-out",
        "inline-flex",
        "items-center",
        "p-1",
        "relative",
        "rounded-full",
        "transition-colors",
        "cursor-pointer",
        FOCUS_RING_CLASS_NAMES,
        toggleSizeClassNames[size].buttonClassNames,
        className
      )}
    >
      <span className="sr-only text-textColor-onBg-primary">{srOnlyLabel}</span>
      <span
        className={clsx(
          value
            ? toggleSizeClassNames[size].translateClassNames
            : "translate-x-0",
          "bg-surface-default",
          "duration-200",
          "ease-in-out",
          "inline-block",
          "rounded-full",
          "transition",
          toggleSizeClassNames[size].indicatorClassNames
        )}
      />
    </Switch>
  );
};
