export const escapeRegExpString = (value: string): string =>
  value.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");

export const createEscapedRegExp = (value: string, flags?: string): RegExp =>
  RegExp(escapeRegExpString(value), flags);

export function filterInvalidURLs(array?: string[] | null): string[] | null {
  if (array === null || array === undefined || array.length === 0) {
    return null;
  }
  // dedupe list, ignoring case, trailing / characters, and leading https/www's
  const deduplicatedList = Array.from(
    new Set(
      array.map((str) =>
        str
          .toLowerCase()
          .replace(/\/$/, "")
          .replace(/^(https?:\/\/(www\.)?)?/, "")
      )
    )
  );

  // specifically filters out email addresses and only allows urls through
  const urlPattern =
    /^(?!.*@)(?:http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(?::[0-9]{1,5})?(\/.*)?$/i;
  // filter and add back https:// so the redirect works
  return deduplicatedList
    .filter((item) => urlPattern.test(item))
    .map((x) => `https://${x}`);
}

export const ACCEPTED_NAME_REG_EXP = RegExp(/^[a-zA-Z-.,\s']+$/);
