import { Divider } from "@chp/shared/components/Divider";
import clsx from "clsx";
import { Path, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import {
  DOB_FIELD_NAME,
  DOB_FIELD_TEST_ID,
  MEMBER_EXTERNAL_ID_FIELD_NAME,
  MEMBER_EXTERNAL_ID_FIELD_TEST_ID,
  SSN_FIELD_NAME,
  SSN_FIELD_TEST_ID,
} from "../constants";
import type {
  LooseMemberVerificationFormValues,
  StrictMemberVerificationFormValues,
} from "../types";
import {
  DOBInput,
  DOBInputProps,
  MemberExternalIdInput,
  MemberExternalIdInputProps,
  SSNInput,
  SSNInputProps,
} from "./inputs";

export type LooseMemberVerificationFormFieldsProps = {
  className?: string;
  onDOBInputValueChanged?: DOBInputProps["onValueChanged"];
};

export const LooseMemberVerificationFormFields = <
  TFormValues extends LooseMemberVerificationFormValues,
>({
  className,
  onDOBInputValueChanged,
}: LooseMemberVerificationFormFieldsProps) => {
  return (
    <div className={className}>
      <DOBInput<TFormValues>
        name={DOB_FIELD_NAME as Path<TFormValues>}
        id={DOB_FIELD_NAME}
        testId={DOB_FIELD_TEST_ID}
        onValueChanged={onDOBInputValueChanged}
      />
    </div>
  );
};

export type StrictMemberVerificationFormFieldsProps = {
  className?: string;
  onSSNInputValueChanged?: SSNInputProps["onValueChanged"];
  onMemberExternalIdInputValueChanged?: MemberExternalIdInputProps["onValueChanged"];
};

export const StrictMemberVerificationFormFields = <
  TFormValues extends StrictMemberVerificationFormValues,
>({
  className,
  onSSNInputValueChanged,
  onMemberExternalIdInputValueChanged,
}: StrictMemberVerificationFormFieldsProps) => {
  const { watch } = useFormContext<TFormValues>();
  const ssnValue = watch(SSN_FIELD_NAME as Path<TFormValues>);
  const memberIdValue = watch(
    MEMBER_EXTERNAL_ID_FIELD_NAME as Path<TFormValues>
  );

  return (
    <div className={clsx("bg-surface-neutral", className)}>
      <SSNInput<TFormValues>
        name={SSN_FIELD_NAME as Path<TFormValues>}
        id={SSN_FIELD_NAME}
        testId={SSN_FIELD_TEST_ID}
        onValueChanged={onSSNInputValueChanged}
        isDisabled={!!memberIdValue}
      />
      <Divider>
        <FormattedMessage defaultMessage="Or" id="4wpUNc" />
      </Divider>
      <MemberExternalIdInput<TFormValues>
        name={MEMBER_EXTERNAL_ID_FIELD_NAME as Path<TFormValues>}
        id={MEMBER_EXTERNAL_ID_FIELD_NAME}
        testId={MEMBER_EXTERNAL_ID_FIELD_TEST_ID}
        onValueChanged={onMemberExternalIdInputValueChanged}
        isDisabled={!!ssnValue}
      />
    </div>
  );
};
