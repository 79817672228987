import { IntlFormatters } from "react-intl";
import * as Yup from "yup";

import {
  MEMBER_FAMILY_NAME_MAX_LENGTH,
  MEMBER_GIVEN_NAME_MAX_LENGTH,
  MEMBER_MIDDLE_NAME_MAX_LENGTH,
} from "../../constants";
import { ACCEPTED_NAME_REG_EXP } from "../string";

export const checkForSpecialCharacters = ({
  formatMessage,
}: {
  formatMessage: IntlFormatters["formatMessage"];
}): Yup.TestConfig<string | null | undefined> => {
  return {
    name: "check-for-special-characters",
    message: formatMessage({
      defaultMessage:
        "Please enter only English alphabetical characters or . , - '",
      id: "CrVUmK",
    }),
    test: (value: string | null | undefined) => {
      return !value || ACCEPTED_NAME_REG_EXP.test(value);
    },
  };
};

export const namesSchema = ({
  formatMessage,
  hasMiddleNameValidation = false,
}: {
  formatMessage: IntlFormatters["formatMessage"];
  hasMiddleNameValidation?: boolean;
}) => {
  return {
    givenName: Yup.string()
      .trim()
      .required(
        formatMessage({
          defaultMessage: "First name is required.",
          id: "lBU5Qr",
        })
      )
      .max(
        MEMBER_GIVEN_NAME_MAX_LENGTH,
        formatMessage(
          {
            defaultMessage:
              "First name must be {maxLength, number} characters or less.",
            id: "wBWTWr",
          },
          { maxLength: MEMBER_GIVEN_NAME_MAX_LENGTH }
        )
      )
      .test(checkForSpecialCharacters({ formatMessage })),
    familyName: Yup.string()
      .trim()
      .required(
        formatMessage({
          defaultMessage: "Last name is required.",
          id: "j7TwoG",
        })
      )
      .max(
        MEMBER_FAMILY_NAME_MAX_LENGTH,
        formatMessage(
          {
            defaultMessage:
              "Last name must be {maxLength, number} characters or less.",
            id: "VpwI1y",
          },
          { maxLength: MEMBER_FAMILY_NAME_MAX_LENGTH }
        )
      )
      .test(checkForSpecialCharacters({ formatMessage })),
    ...(hasMiddleNameValidation && {
      middleName: Yup.string()
        .trim()
        .nullable()
        .max(
          MEMBER_MIDDLE_NAME_MAX_LENGTH,
          formatMessage(
            {
              defaultMessage:
                "Middle name must be {maxLength, number} characters or less.",
              id: "2aX+h4",
            },
            { maxLength: MEMBER_MIDDLE_NAME_MAX_LENGTH }
          )
        )
        .test(checkForSpecialCharacters({ formatMessage })),
    }),
  };
};
