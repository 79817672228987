import clsx from "clsx";
import { FC, ReactNode } from "react";

export enum DividerLineColor {
  GRAY_486 = "GRAY_486",
  GRAY_700 = "GRAY_700",
  GRAY_200 = "GRAY_200",
}

export type DividerLineProps = {
  className?: string;
  dividerLineColor?: DividerLineColor;
};

export const DividerLine: FC<DividerLineProps> = ({
  className,
  dividerLineColor = DividerLineColor.GRAY_700,
}) => (
  <div
    className={clsx(
      "grow border-t-2",
      dividerLineColor === DividerLineColor.GRAY_486 && "border-gray-486",
      dividerLineColor === DividerLineColor.GRAY_700 && "border-gray-700",
      className
    )}
  />
);

export type DividerProps = {
  children?: ReactNode;
  className?: string;
  dividerLineColor?: DividerLineColor;
  dividerLineClassName?: string;
};

export const Divider: FC<DividerProps> = ({
  children,
  className,
  dividerLineColor = DividerLineColor.GRAY_700,
  dividerLineClassName,
}) => (
  <div className={clsx("relative flex items-center py-5", className)}>
    {!!children && (
      <>
        <DividerLine
          dividerLineColor={dividerLineColor}
          className={dividerLineClassName}
        />
        <span className="text-bold mx-4 shrink text-lg text-gray-700">
          {children}
        </span>
      </>
    )}
    <DividerLine
      dividerLineColor={dividerLineColor}
      className={dividerLineClassName}
    />
  </div>
);
