/* eslint-disable @typescript-eslint/no-explicit-any */

import { FunctionComponent, PropsWithChildren, useState } from "react";

import SmartTableContent from "./SmartTableContent";
import SmartTableFilters from "./SmartTableFilters";
import SmartTableHeader from "./SmartTableHeader";
import {
  DownloadCSVProps,
  FetchDataInput,
  ItemPage,
  SmartTableFilter,
} from "./types";

export type SmartTableProps = PropsWithChildren<{
  id: string;
  columns: Array<any>;
  data: ItemPage<any>;
  defaultFilters?: Record<string, any>;
  disablePagination?: boolean;
  disableSearch?: boolean;
  fetchData: (...args: [FetchDataInput]) => void;
  filters?: Array<SmartTableFilter>;
  searchQuery?: string;
  setSearchQuery?: (value: string) => void;
  isLoading: boolean;
  isDownloadingCSV?: boolean;
  onRowClick?: (row: any) => void;
  onClickDownloadCSV?: (...args: [DownloadCSVProps]) => void;
  onClickNew?: () => void;
  newButtonLabel?: string;
  allFiltersButtonLabel?: string;
  rowToHref?: (row: any) => {
    href: string;
    as: string;
  };
  searchInputPlaceholder?: string;
  setIsLoading: (value: boolean) => void;
  noHover?: boolean;
  canDownloadCsv?: boolean;
  refreshInterval?: number;
  minWidth?: number;
}>;

const SmartTable: FunctionComponent<SmartTableProps> = (props) => {
  props = {
    filters: [],
    defaultFilters: {},
    ...props,
  };

  //                                                                  //
  // First we're going to set up some state around search, filters,   //
  // ordering, and pagination along with respective helper methods.   //
  //                                                                  //

  const [refresh, setRefresh] = useState(false);

  // ~ Filters ~ //
  const defaultFilters = props.filters?.reduce(
    //@ts-expect-error
    (filters, filter) => Object.assign(filters, filter.defaultValue),
    props.defaultFilters
  );
  const [activeFilters, setFilters] = useState<Record<string, any>>(
    //@ts-expect-error
    defaultFilters
  );

  const handleClickDownloadCSV = (filters: Record<string, any>) => {
    if (!props.onClickDownloadCSV) return;

    props.onClickDownloadCSV({
      searchQuery: props.searchQuery,
      filters,
    });
  };

  return (
    <div className="flex min-h-0 flex-1 flex-col">
      {!props.disableSearch && (
        <div className="mx-4 flex">
          <SmartTableHeader
            searchQuery={props.searchQuery || ""}
            setSearchQuery={(v) =>
              props.setSearchQuery ? props.setSearchQuery(v) : null
            }
            reloadResults={() => setRefresh(!refresh)}
            //@ts-expect-error fails strict type checking - please fix!
            searchInputPlaceholder={props.searchInputPlaceholder}
          />
        </div>
      )}
      <div className="mx-4 mb-2 flex">
        <SmartTableFilters
          filters={props.filters}
          activeFilters={activeFilters}
          setFilters={setFilters}
          showClickDownloadCSV={props.onClickDownloadCSV !== undefined}
          canDownloadCSV={props.canDownloadCsv}
          onClickDownloadCSV={() => handleClickDownloadCSV(activeFilters)}
          isDownloadingCSV={props.isDownloadingCSV}
          onClickNew={props.onClickNew}
          newButtonLabel={props.newButtonLabel}
          allFiltersButtonLabel={props.allFiltersButtonLabel}
        />
      </div>
      <SmartTableContent
        id={`${props.id}__Content`}
        noHover={props.noHover}
        columns={props.columns}
        data={props.data}
        fetchData={props.fetchData}
        isLoading={props.isLoading}
        searchQuery={props.searchQuery || ""}
        setIsLoading={props.setIsLoading}
        filters={activeFilters}
        defaultFilters={defaultFilters}
        disablePagination={props.disablePagination}
        rowToHref={props.rowToHref}
        refresh={refresh}
        refreshInterval={props.refreshInterval}
        onRowClick={props.onRowClick}
        minWidth={props.minWidth}
      />
    </div>
  );
};

export default SmartTable;
