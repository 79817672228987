/**
 * Some common Flex class names.
 *
 * See: https://tailwindcss.com/docs/flex
 */
export const flexCommon: { [flex: string]: string[] } = {
  flex1: ["flex-1"],
};

/**
 * Some common Flex Direction class names.
 *
 * See: https://tailwindcss.com/docs/flex-direction
 */
export const flexDirectionCommon: { [flexDirection: string]: string[] } = {
  col: ["flex-col"],
  lgRow: ["lg:flex-row"],
};

/**
 * Some common Flex Shrink class names.
 *
 * See: https://tailwindcss.com/docs/flex-shrink
 */
export const flexShrinkCommon: { [flexShrink: string]: string[] } = {
  shrink0: ["shrink-0"],
};

/**
 * Some common Padding class names.
 *
 * See: https://tailwindcss.com/docs/padding
 */
export const paddingCommon: { [padding: string]: string[] } = {
  lgTop28: ["lg:pt-7"],
};

/**
 * Some common Space Between class names.
 *
 * See: https://tailwindcss.com/docs/space
 */
export const spaceBetweenCommon: { [spaceBetween: string]: string[] } = {
  lgHorizontal8: ["lg:space-x-2"],
};

/**
 * Some common Width class names.
 *
 * See: https://tailwindcss.com/docs/width
 */
export const widthCommon: { [width: string]: string[] } = {
  lgHalf: ["lg:w-1/2"],
  lgThird: ["lg:w-1/3"],
  lgSixth: ["lg:w-1/6"],
};
