const { mergeWith } = require("lodash");
const curativeUITailwindConfig = require("@chp/curative_ui/tailwind.config");

/**
 * @param {string} projectSourceFolderPath
 * @param {object} overrides
 */
const createTailwindConfig = (projectSourceFolderPath = ".", overrides = {}) =>
  mergeWith(
    {},
    curativeUITailwindConfig,
    {
      content: ["../shared/**/*.tsx", `${projectSourceFolderPath}/**/*.tsx`],
      plugins: [require("./tailwindStripesPlugin")],
    },
    overrides,
    (objVal, sourceVal) => {
      if (Array.isArray(objVal) && Array.isArray(sourceVal)) {
        return [...sourceVal, ...objVal];
      }
    }
  );

module.exports = { createTailwindConfig };
