import { Button, ButtonProps, ButtonVariant } from "@chp/curative_ui";
import { ESignature } from "@chp/shared/generated/memberPortalApi.graphql";
import { RefObject } from "react";
import { FormattedMessage } from "react-intl";

import { FormSubmitButton } from "../../../components/form/FormSubmitButton";
import type { RequiredLegalAgreementProps } from "../types";
import { ConsentFields } from "./ConsentFields";

export type LegalAgreementFormFieldsProps = {
  bottomRef: RefObject<HTMLDivElement>;
  legalAgreement: RequiredLegalAgreementProps;
  previousESignature?: ESignature | null;
  submitButtonText?: ButtonProps["children"];
  isSaving?: boolean;
  isSubmitButtonDisabled?: boolean;
  onBackButtonClick?: () => void;
  onSkip?: () => void;
  displaySkipOption?: boolean;
};

export const LegalAgreementFormFields = ({
  bottomRef,
  legalAgreement,
  previousESignature = null,
  submitButtonText,
  isSaving,
  isSubmitButtonDisabled,
  onBackButtonClick,
  displaySkipOption,
  onSkip,
}: LegalAgreementFormFieldsProps) => {
  return (
    <>
      <div ref={bottomRef}>
        <ConsentFields
          legalAgreement={legalAgreement}
          previousESignature={previousESignature}
        />
      </div>

      <div className="mx-auto max-w-[300px] space-y-2 py-8">
        <FormSubmitButton
          className="py-4"
          isDisabled={isSubmitButtonDisabled}
          isLoading={isSaving}
        >
          {submitButtonText || (
            <FormattedMessage defaultMessage="Submit" id="wSZR47" />
          )}
        </FormSubmitButton>

        {onSkip && displaySkipOption && (
          <Button
            onClick={onSkip}
            className="py-4"
            variant={ButtonVariant.FullWidthOutline}
          >
            <FormattedMessage defaultMessage="Skip" id="/4tOwT" />
          </Button>
        )}

        {onBackButtonClick && (
          <Button
            onClick={onBackButtonClick}
            className="py-4"
            variant={ButtonVariant.FullWidthOutline}
          >
            <FormattedMessage defaultMessage="Back" id="cyR7Kh" />
          </Button>
        )}
      </div>
    </>
  );
};
