import { Button, ButtonVariant } from "@chp/curative_ui";
import { Fragment, useState } from "react";

import { FormattedModal } from "../FormattedModal";
export interface DownloadCSVButtonProps {
  isDisabled?: boolean;
  canDownloadCsv?: boolean;
  onConfirm: () => void;
}

export const DownloadCSVButton = ({
  isDisabled = false,
  canDownloadCsv,
  onConfirm,
}: DownloadCSVButtonProps) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isPermissionErrorModalOpen, setIsPermissionErrorModalOpen] =
    useState(false);

  return (
    <Fragment>
      <Button
        isDisabled={isDisabled}
        onClick={() => {
          if (!canDownloadCsv) return setIsPermissionErrorModalOpen(true);
          setIsConfirmationModalOpen(true);
        }}
        className="whitespace-nowrap"
        data-testid="csv-download-button"
        variant={ButtonVariant.SmallPrimary}
      >
        Download CSV
      </Button>
      {isConfirmationModalOpen && (
        <FormattedModal
          title="Download CSV"
          message="Are you sure you want to download this file?"
          onButtonClick={() => {
            onConfirm();
            setIsConfirmationModalOpen(false);
          }}
          onRequestClose={() => {
            setIsConfirmationModalOpen(false);
          }}
          buttonText="Download"
          isOpen={isConfirmationModalOpen}
        />
      )}
      {isPermissionErrorModalOpen && (
        <FormattedModal
          title="Error"
          id="csvDownloadErrorModal"
          message="You don't have permission to download a csv of this data"
          buttonText="I understand"
          isOpen={isPermissionErrorModalOpen}
          onRequestClose={() => setIsPermissionErrorModalOpen(false)}
          onButtonClick={() => setIsPermissionErrorModalOpen(false)}
        />
      )}
    </Fragment>
  );
};
