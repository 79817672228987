import { TypeaheadOption } from "@chp/curative_ui";
import { FieldValues, Path } from "react-hook-form";

import {
  useFetchCareCostProcedureCodeOptions,
  useFetchProcedureCodeOptions,
} from "../../../hooks";
import { generateArrayKey } from "../../../utils";
import { FormTypeahead } from "./FormTypeahead";

export type ProcedureCode = {
  code: string;
  description: string;
  type?: string | null;
  plainLanguageDescription?: string | null;
};

const testId = "ProcedureTypeahead";
export const PROCEDURE_CODES_NOT_COVERED = ["98940", "98941", "98943"];

interface Props<T extends FieldValues> {
  label: string;
  name: Path<T>;
  className?: string;
  placeholder?: string;
  onlyCareCostProcedures?: boolean;
  shouldResetFieldOnChange?: boolean;
  setIsProcedureCodeCovered?: (isCovered: boolean) => void;
}

export function ProcedureTypeahead<T extends FieldValues>({
  onlyCareCostProcedures,
  setIsProcedureCodeCovered,
  ...props
}: Props<T>) {
  const {
    fetchCareCostProcedureCodeOptions,
    areCareCostProcedureCodeOptionsLoading,
  } = useFetchCareCostProcedureCodeOptions();
  const { fetchProcedureCodeOptions, areProcedureCodeOptionsLoading } =
    useFetchProcedureCodeOptions();

  return (
    <FormTypeahead<T, ProcedureCode>
      {...props}
      hasDropdownToggle={false}
      optionKeyExtractor={() => generateArrayKey()}
      fetchOptions={
        onlyCareCostProcedures
          ? fetchCareCostProcedureCodeOptions
          : fetchProcedureCodeOptions
      }
      isLoading={
        onlyCareCostProcedures
          ? areCareCostProcedureCodeOptionsLoading
          : areProcedureCodeOptionsLoading
      }
      testId={testId}
      onClickOption={(newValue?: TypeaheadOption<ProcedureCode> | null) => {
        // This is a fix implemented for KRN-1388 to show an error when the user searches chiropractic codes.
        // https://linear.app/curativeinc/issue/KRN-1388/show-error-in-cce-when-user-selects-a-procedure-code-that-is-not
        if (newValue) {
          const procedureCode = newValue.value.code;
          const isCovered =
            PROCEDURE_CODES_NOT_COVERED.indexOf(procedureCode) === -1;
          if (setIsProcedureCodeCovered) {
            setIsProcedureCodeCovered(isCovered);
          }
        }
      }}
    />
  );
}
