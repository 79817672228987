import { FieldValues } from "react-hook-form";
import { useIntl } from "react-intl";

import {
  FormTextField,
  FormTextFieldProps,
} from "../../../../components/form/FormTextField";
import { MEMBER_EXTERNAL_ID_FIELD_TEST_ID } from "../../constants";

type MemberExternalIdInputOptionalProps<T extends FieldValues = FieldValues> =
  Partial<
    Pick<
      FormTextFieldProps<T>,
      | "id"
      | "testId"
      | "isDisabled"
      | "label"
      | "description"
      | "placeholder"
      | "onValueChanged"
    >
  >;

type MemberExternalIdInputRequiredProps<T extends FieldValues = FieldValues> =
  Required<Pick<FormTextFieldProps<T>, "name">>;

export type MemberExternalIdInputProps<T extends FieldValues = FieldValues> =
  MemberExternalIdInputOptionalProps<T> & MemberExternalIdInputRequiredProps<T>;

export const MemberExternalIdInput = <T extends FieldValues = FieldValues>({
  name,
  id = name,
  testId = MEMBER_EXTERNAL_ID_FIELD_TEST_ID,
  isDisabled,
  label,
  description,
  placeholder,
  onValueChanged,
}: MemberExternalIdInputProps<T>) => {
  const { formatMessage } = useIntl();

  return (
    <FormTextField<T>
      autoComplete="off"
      name={name}
      id={id}
      testId={testId}
      isDisabled={isDisabled}
      label={
        label || formatMessage({ defaultMessage: "Member ID", id: "BSilTn" })
      }
      description={
        description ||
        formatMessage({
          defaultMessage:
            "You can find your Member ID in your welcome email. It is also found on the card you received in the welcome package.",
          id: "eE1oKR",
        })
      }
      placeholder={
        placeholder ||
        formatMessage({ defaultMessage: "e.g. AB123456701", id: "UPjV6h" })
      }
      onValueChanged={onValueChanged}
    />
  );
};
