import { SpacingVariant, Spinner, SpinnerProps } from "@chp/curative_ui";
import clsx from "clsx";
import { FC } from "react";

export type SpinnerCenteredProps = Pick<SpinnerProps, "size"> & {
  className?: string;
  testId?: string;
};

export const SpinnerCentered: FC<SpinnerProps> = ({
  className,
  size = SpacingVariant.S32,
  testId = "SpinnerCentered",
}) => (
  <span
    className={clsx("flex w-full flex-row justify-center", className)}
    data-testid={testId}
  >
    <Spinner size={size} />
  </span>
);
