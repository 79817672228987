import { defaultFormatMessage } from "@chp/shared/features/i18n/utils";
import { IntlFormatters } from "react-intl";
import * as Yup from "yup";

import { PhoneNumberType } from "../../types";

export const phoneNumberRegex = /^\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/i;

export const phoneNumberOptionSchema = ({
  messages,
}: {
  messages: {
    matches: string;
  };
}) => Yup.string().matches(phoneNumberRegex, messages.matches);

export const phoneNumberSchema = ({
  messages,
  formatMessage = defaultFormatMessage,
}: {
  messages?: {
    matches?: string;
    required?: string;
  };
  // TODO: formatMessage should be required if we need translated default messages
  formatMessage?: IntlFormatters["formatMessage"];
}) => {
  const matchesMessage =
    messages?.matches ??
    formatMessage({
      defaultMessage: "Phone number must match format 123-456-7890.",
      id: "exFYJE",
    });
  const requiredMessage =
    messages?.required ??
    formatMessage({
      defaultMessage: "Phone number is required.",
      id: "Il7p07",
    });
  return phoneNumberOptionSchema({
    messages: { matches: matchesMessage },
  }).required(requiredMessage);
};

export const isValidPhoneNumber = (
  phoneNumber: string,
  formatMessage: IntlFormatters["formatMessage"] = defaultFormatMessage
): boolean => phoneNumberSchema({ formatMessage }).isValidSync(phoneNumber);

// for use with FormMaskedInput
export function formatPhoneNumberForInput(
  phoneNumber?: string | null
): string | undefined {
  if (!phoneNumber) return;
  // Remove the leading '+' and country code (assuming the country code is always 1)
  const cleanedNumber = phoneNumber.slice(2);

  // Split the cleaned number into area code, local prefix, and line number
  const areaCode = cleanedNumber.slice(0, 3);
  const localPrefix = cleanedNumber.slice(3, 6);
  const lineNumber = cleanedNumber.slice(6, 10);

  // Concatenate the parts with hyphens
  const formattedNumber = `${areaCode}-${localPrefix}-${lineNumber}`;

  return formattedNumber;
}

export function getPlainPhoneNumber(phoneNumber: string): string {
  return formatPhoneNumberForApi(phoneNumber).slice(-10);
}

export function formatPhoneNumberForApi(phoneNumber: string): string {
  // Remove all non-numeric characters
  return phoneNumber.replace(/\D/g, "");
}

export const phoneNumberTypeSchema = () =>
  Yup.string().oneOf(Object.values(PhoneNumberType));

export const phoneNumberTypeNullableSchema = () =>
  phoneNumberTypeSchema().nullable();

export const phoneNumberTypeRequiredSchema = ({
  messages,
}: {
  messages: {
    required: string;
  };
}) => phoneNumberTypeSchema().required(messages.required);
