import { Radio, RadioProps } from "@chp/curative_ui";
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";

export type FormRadioProps<
  T extends FieldValues,
  Value extends string = string,
> = {
  name: Path<T>;
  id?: string;
  label: string;
  className?: string;
  value: Value;
} & Partial<Pick<RadioProps, "isChecked" | "isDisabled">>;

export function FormRadio<
  T extends FieldValues,
  Value extends string = string,
>({
  name,
  id,
  label,
  className,
  isChecked,
  isDisabled,
  value,
}: FormRadioProps<T, Value>) {
  const { control } = useFormContext<T>();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { name, onChange, onBlur },
        fieldState: { error },
      }) => (
        <Radio
          id={id || name}
          name={name}
          label={label}
          value={value}
          isDisabled={isDisabled}
          className={className}
          isChecked={isChecked}
          //workaround react-hook-form typing issue
          hasError={Boolean(error)}
          onBlur={onBlur}
          onRequestToggle={(newValue) => {
            onChange({
              target: {
                value: newValue,
              },
            });
          }}
        />
      )}
    />
  );
}
