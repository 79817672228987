import { SexAtBirth } from "@chp/shared/generated/memberPortalApi.graphql";
import { FieldValues, Path } from "react-hook-form";
import { useIntl } from "react-intl";

import { FormSelect } from "../form";

const getOptions = (options: string[], labels: { [key: string]: string }) =>
  options.map((option) => ({ key: option, label: labels[option] ?? "" }));

export type SexAtBirthLabels = { [key in SexAtBirth]?: string };

interface Props<T extends FieldValues> {
  name: Path<T>;
}

export const SexAtBirthSelect = <T extends FieldValues>({ name }: Props<T>) => {
  const { formatMessage } = useIntl();
  const sexAtBirthLabels: SexAtBirthLabels = {
    [SexAtBirth.Female]: formatMessage({
      defaultMessage: "Female",
      id: "74BYXL",
    }),
    [SexAtBirth.Male]: formatMessage({ defaultMessage: "Male", id: "jIbAky" }),
    [SexAtBirth.Intersex]: formatMessage({
      defaultMessage: "Intersex",
      id: "fHiDzZ",
    }),
    [SexAtBirth.NotShared]: formatMessage({
      defaultMessage: "Prefer not to share",
      id: "lvrRik",
    }),
  };

  return (
    <FormSelect
      testId="SexAtBirth__Select"
      label={formatMessage({
        defaultMessage: "Sex at birth",
        id: "y+aTkL",
      })}
      options={getOptions(Object.keys(sexAtBirthLabels), sexAtBirthLabels)}
      optionKeyExtractor={(option) => option.key}
      optionLabelExtractor={(option) => option.label}
      optionStringValueExtractor={(option) => option.key}
      name={name}
      placeholder={formatMessage({
        defaultMessage: "Choose a sex at birth",
        id: "MZIKoZ",
      })}
    />
  );
};
