import * as Types from '@chp/shared/generated/memberPortalApi.graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsuranceCard__GetActiveUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type InsuranceCard__GetActiveUserQuery = { __typename?: 'Query', activeUser: { __typename?: 'User', id: number, member?: { __typename: 'Member', relationship: Types.Relationship, externalId: string, familyName: string, middleName?: string | null, givenName?: string | null, id: string, bothSides?: string | null, front?: string | null, back?: string | null, subscriberGroup: { __typename?: 'SubscriberGroup', dependents: Array<{ __typename: 'BasicMember', externalId: string, familyName: string, middleName?: string | null, givenName?: string | null, id: string, address?: { __typename?: 'MemberAddress', city: string, state: string, street: string, street2?: string | null, zipCode: string } | null } | { __typename: 'Member', externalId: string, familyName: string, middleName?: string | null, givenName?: string | null, id: string, bothSides?: string | null, front?: string | null, back?: string | null, address?: { __typename?: 'MemberAddress', city: string, state: string, street: string, street2?: string | null, zipCode: string } | null, displayedCoveragePeriod: { __typename?: 'CoveragePeriod', startDate: string }, closestMemberPlanYear?: { __typename?: 'MemberPlanYear', planCoveragePackage?: Types.CoveragePackage | null, isPlanHighDeductible: boolean } | null, displayedEnrollment: { __typename?: 'Enrollment', type: Types.EnrollmentType, status: Types.EnrollmentStatus, planStartDate: string } }> }, displayedCoveragePeriod: { __typename?: 'CoveragePeriod', startDate: string }, closestMemberPlanYear?: { __typename?: 'MemberPlanYear', planCoveragePackage?: Types.CoveragePackage | null, isPlanHighDeductible: boolean } | null, displayedEnrollment: { __typename?: 'Enrollment', groupId: string, type: Types.EnrollmentType, status: Types.EnrollmentStatus, planStartDate: string, planEndDate: string }, address?: { __typename?: 'MemberAddress', city: string, state: string, street: string, street2?: string | null, zipCode: string } | null } | null } };

export type InsuranceCard__ActiveUser__MemberFragment = { __typename: 'Member', relationship: Types.Relationship, externalId: string, familyName: string, middleName?: string | null, givenName?: string | null, id: string, bothSides?: string | null, front?: string | null, back?: string | null, subscriberGroup: { __typename?: 'SubscriberGroup', dependents: Array<{ __typename: 'BasicMember', externalId: string, familyName: string, middleName?: string | null, givenName?: string | null, id: string, address?: { __typename?: 'MemberAddress', city: string, state: string, street: string, street2?: string | null, zipCode: string } | null } | { __typename: 'Member', externalId: string, familyName: string, middleName?: string | null, givenName?: string | null, id: string, bothSides?: string | null, front?: string | null, back?: string | null, address?: { __typename?: 'MemberAddress', city: string, state: string, street: string, street2?: string | null, zipCode: string } | null, displayedCoveragePeriod: { __typename?: 'CoveragePeriod', startDate: string }, closestMemberPlanYear?: { __typename?: 'MemberPlanYear', planCoveragePackage?: Types.CoveragePackage | null, isPlanHighDeductible: boolean } | null, displayedEnrollment: { __typename?: 'Enrollment', type: Types.EnrollmentType, status: Types.EnrollmentStatus, planStartDate: string } }> }, displayedCoveragePeriod: { __typename?: 'CoveragePeriod', startDate: string }, closestMemberPlanYear?: { __typename?: 'MemberPlanYear', planCoveragePackage?: Types.CoveragePackage | null, isPlanHighDeductible: boolean } | null, displayedEnrollment: { __typename?: 'Enrollment', groupId: string, type: Types.EnrollmentType, status: Types.EnrollmentStatus, planStartDate: string, planEndDate: string }, address?: { __typename?: 'MemberAddress', city: string, state: string, street: string, street2?: string | null, zipCode: string } | null };

export type InsuranceCard__MemberFragment = { __typename: 'Member', externalId: string, familyName: string, middleName?: string | null, givenName?: string | null, id: string, bothSides?: string | null, front?: string | null, back?: string | null, displayedCoveragePeriod: { __typename?: 'CoveragePeriod', startDate: string }, closestMemberPlanYear?: { __typename?: 'MemberPlanYear', planCoveragePackage?: Types.CoveragePackage | null, isPlanHighDeductible: boolean } | null, displayedEnrollment: { __typename?: 'Enrollment', type: Types.EnrollmentType, status: Types.EnrollmentStatus, planStartDate: string }, address?: { __typename?: 'MemberAddress', city: string, state: string, street: string, street2?: string | null, zipCode: string } | null };

export const InsuranceCard__MemberFragmentDoc = gql`
    fragment InsuranceCard__Member on Member {
  __typename
  externalId
  familyName
  middleName
  givenName
  id
  displayedCoveragePeriod {
    startDate
  }
  closestMemberPlanYear {
    planCoveragePackage
    isPlanHighDeductible
  }
  displayedEnrollment {
    planStartDate: startDate
    type
    status
  }
  address {
    city
    state
    street
    street2
    zipCode
  }
  bothSides: memberCardUrl(fileType: PNG, displayType: BOTH_SIDES)
  front: memberCardUrl(fileType: PNG, displayType: FRONT)
  back: memberCardUrl(fileType: PNG, displayType: BACK)
}
    `;
export const InsuranceCard__ActiveUser__MemberFragmentDoc = gql`
    fragment InsuranceCard__ActiveUser__Member on Member {
  ...InsuranceCard__Member
  relationship
  subscriberGroup {
    dependents {
      __typename
      externalId
      familyName
      middleName
      givenName
      id
      address {
        city
        state
        street
        street2
        zipCode
      }
      ...InsuranceCard__Member
    }
  }
  displayedCoveragePeriod {
    startDate
  }
  closestMemberPlanYear {
    planCoveragePackage
    isPlanHighDeductible
  }
  displayedEnrollment {
    planStartDate: startDate
    planEndDate: endDate
    groupId
    type
    status
  }
}
    ${InsuranceCard__MemberFragmentDoc}`;
export const InsuranceCard__GetActiveUserDocument = gql`
    query InsuranceCard__GetActiveUser {
  activeUser {
    id
    member {
      ...InsuranceCard__ActiveUser__Member
    }
  }
}
    ${InsuranceCard__ActiveUser__MemberFragmentDoc}`;

/**
 * __useInsuranceCard__GetActiveUserQuery__
 *
 * To run a query within a React component, call `useInsuranceCard__GetActiveUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuranceCard__GetActiveUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuranceCard__GetActiveUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useInsuranceCard__GetActiveUserQuery(baseOptions?: Apollo.QueryHookOptions<InsuranceCard__GetActiveUserQuery, InsuranceCard__GetActiveUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsuranceCard__GetActiveUserQuery, InsuranceCard__GetActiveUserQueryVariables>(InsuranceCard__GetActiveUserDocument, options);
      }
export function useInsuranceCard__GetActiveUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsuranceCard__GetActiveUserQuery, InsuranceCard__GetActiveUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsuranceCard__GetActiveUserQuery, InsuranceCard__GetActiveUserQueryVariables>(InsuranceCard__GetActiveUserDocument, options);
        }
export function useInsuranceCard__GetActiveUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InsuranceCard__GetActiveUserQuery, InsuranceCard__GetActiveUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InsuranceCard__GetActiveUserQuery, InsuranceCard__GetActiveUserQueryVariables>(InsuranceCard__GetActiveUserDocument, options);
        }
export type InsuranceCard__GetActiveUserQueryHookResult = ReturnType<typeof useInsuranceCard__GetActiveUserQuery>;
export type InsuranceCard__GetActiveUserLazyQueryHookResult = ReturnType<typeof useInsuranceCard__GetActiveUserLazyQuery>;
export type InsuranceCard__GetActiveUserSuspenseQueryHookResult = ReturnType<typeof useInsuranceCard__GetActiveUserSuspenseQuery>;
export type InsuranceCard__GetActiveUserQueryResult = Apollo.QueryResult<InsuranceCard__GetActiveUserQuery, InsuranceCard__GetActiveUserQueryVariables>;