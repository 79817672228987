import { act } from "@testing-library/react";
import { AnyStateMachine, interpret, InterpreterFrom } from "xstate";

export * from "./graphql";
export * from "./IntlWrapper";
export * from "./mixpanelContext";
export * from "./mocks";
export * from "./schemaMocks";

export const waitForNextTick = async (manualTimeoutInMs: number = 0) =>
  act(() => {
    return new Promise((resolve) => {
      setTimeout(resolve, manualTimeoutInMs);
    });
  });

export const withTransitionLogging = <TMachine extends AnyStateMachine>(
  machine: TMachine
) =>
  interpret(machine).onTransition((state) =>
    console.log(
      `STATE: ${JSON.stringify(
        { value: state.value, context: state.context },
        null,
        2
      )}`
    )
  );

// Wait for an asynchronous state transition to complete
export const waitForTransitionTo = async <TMachine extends AnyStateMachine>(
  state: string,
  machine: InterpreterFrom<TMachine>
): Promise<void> => {
  await new Promise<void>((resolve) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    machine.onTransition((transition: any) => {
      if (transition.matches(state)) {
        resolve();
      }
    });
  });
};
