import { Box, ScreenReaderOnly, SpacingVariant } from "@chp/curative_ui";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  BenefitsCategory,
  BenefitsCategoryContent,
  benefitsCategoryContent,
  BenefitsCategoryTableCards,
  BenefitsCategoryTableCardsProps,
} from "../BenefitsCategoryTable";
import { FormattedSelect } from "../FormattedSelect";

// each option represents a list of categories to display
type BenefitsViewerOption = {
  optionKey: string;
  optionLabel: string;
  categories: BenefitsCategory[];
};

const categoriesAll: BenefitsCategory[] = [
  BenefitsCategory.OUT_OF_POCKET_COSTS,
  BenefitsCategory.OFFICE_SERVICES,
  BenefitsCategory.PHARMACY_BENEFITS,
  BenefitsCategory.EMERGENCY_CARE,
  BenefitsCategory.OUTPATIENT_CARE,
  BenefitsCategory.INPATIENT_CARE,
  BenefitsCategory.OBSTETRICAL_CARE,
  BenefitsCategory.THERAPY,
  BenefitsCategory.EXTENDED_CARE,
  BenefitsCategory.BEHAVIORAL_HEALTH,
  BenefitsCategory.OTHER_SERVICES,
];

export type BenefitsViewerProps = Omit<
  BenefitsCategoryTableCardsProps,
  "categories"
> & { testId?: string };

export const BenefitsViewer = (props: BenefitsViewerProps): JSX.Element => {
  const {
    columnClassNames,
    headingLevel,
    isPlanHighDeductible,
    planCoveragePackage,
    benefitsBookletUrl,
    testId = "BenefitsViewer",
  } = props;
  const { formatMessage } = useIntl();

  const memoizedContent: Record<BenefitsCategory, BenefitsCategoryContent> =
    useMemo(
      () =>
        benefitsCategoryContent({
          formatMessage,
        }),
      [formatMessage]
    );

  const optionViewAll: BenefitsViewerOption = {
    categories: categoriesAll,
    optionLabel: formatMessage({
      defaultMessage: "View all",
      id: "pFK6bJ",
    }),
    optionKey: "VIEW_ALL",
  };
  const optionForSingleCategory = (
    category: BenefitsCategory
  ): BenefitsViewerOption => ({
    categories: [category],
    optionLabel: memoizedContent[category].title,
    optionKey: category,
  });
  const options: BenefitsViewerOption[] = [
    // start with each option matching a single category
    ...categoriesAll.map((category) => optionForSingleCategory(category)),
    // put view all as the last option
    optionViewAll,
  ];

  // default to general category
  const optionDefault: BenefitsViewerOption =
    options[0] ?? optionForSingleCategory(BenefitsCategory.OUT_OF_POCKET_COSTS);
  const [optionSelected, setOptionSelected] =
    useState<BenefitsViewerOption>(optionDefault);

  const tableProps = {
    categories: optionSelected.categories,
    columnClassNames,
    headingLevel,
    isPlanHighDeductible,
    planCoveragePackage,
    benefitsBookletUrl,
  };

  return (
    <Box element="div" testId={testId}>
      <Box element="div" margin={{ bottom: SpacingVariant.S24 }}>
        <FormattedSelect
          label={formatMessage({
            defaultMessage: "Select a category of common services:",
            id: "K0sxK8",
          })}
          onChangeSelection={setOptionSelected}
          options={options}
          optionKeyExtractor={(o) => o.optionKey}
          optionLabelExtractor={(o) => o.optionLabel}
          value={optionSelected}
          testId={`${testId}__Select`}
        />
      </Box>
      <ScreenReaderOnly aria-live="polite" element="p">
        <FormattedMessage
          defaultMessage="Benefits for category: {optionLabel}"
          id="e9lTPQ"
          values={{
            optionLabel: optionSelected.optionLabel,
          }}
        />
      </ScreenReaderOnly>
      <BenefitsCategoryTableCards {...tableProps} />
    </Box>
  );
};
