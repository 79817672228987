import {
  Box,
  TextVariant,
  Tooltip,
  TooltipPlacement,
  TooltipVariant,
} from "@chp/curative_ui";
import clsx from "clsx";
import { useState } from "react";

export const StaticField = ({
  label,
  value,
  className,
  hasTooltip,
  testId = "StaticField",
}: {
  label: JSX.Element | string;
  value: JSX.Element | string;
  className?: string;
  hasTooltip?: boolean;
  testId?: string;
}) => {
  const [tooltipTriggerElement, setTooltipTriggerElement] =
    useState<HTMLElement | null>(null);

  // determine if the text is overflowing its parent element and being truncated with an elepsis
  const isEllipsisActive = (e: HTMLElement | null) => {
    if (!e || !e.parentElement) return false;
    return e.offsetWidth > e.parentElement.offsetWidth;
  };

  return (
    <div data-testid={testId} className={clsx("!mb-4 sm:mb-0", className)}>
      <Box
        element="p"
        textVariant={TextVariant.MdRegularTall}
        className="text-textColor-subdued"
        testId={`${testId}__Label`}
      >
        {label}
      </Box>
      <Box
        element="div"
        textVariant={TextVariant.LgRegular}
        className={clsx(hasTooltip && "overflow-hidden text-ellipsis")}
        testId={`${testId}__ValueWrapper`}
      >
        <span
          data-testid={`${testId}__Value`}
          ref={hasTooltip ? setTooltipTriggerElement : undefined}
          className="break-words"
        >
          {value}
        </span>
      </Box>

      {hasTooltip &&
        isEllipsisActive(tooltipTriggerElement) &&
        typeof value === "string" && (
          <Tooltip
            testId={`${testId}StaticField__Tooltip`}
            triggerElement={tooltipTriggerElement}
            message={value}
            placement={TooltipPlacement.BOTTOM}
            variant={TooltipVariant.Dark}
            className="break-all"
          />
        )}
    </div>
  );
};
