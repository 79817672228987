import { CommonBackgroundColor, CommonTextColor } from "./types";

/**
 * Some common background color class names. See src/tokens/colors/theme.js for full colors config.
 *
 * See: https://tailwindcss.com/docs/background-color
 */
export const bgColorsCommon: Record<CommonBackgroundColor, string[]> = {
  depressed: ["bg-background-depressed"],
  subdued: ["bg-background-subdued"],
  surfaceHighlight: ["bg-surface-highlight"],
  surfaceNeutral: ["bg-surface-neutral"],
};

/**
 * Some common text color class names. See src/tokens/colors/theme.js for full colors config.
 *
 * See: https://tailwindcss.com/docs/text-color
 */
export const textColorsCommon: Record<CommonTextColor, string[]> = {
  default: ["text-textColor-default"],
  highlightDefault: ["text-textColor-highlight-default"],
  onBgPrimary: ["text-textColor-onBg-primary"],
  subdued: ["text-textColor-subdued"],
  warning: ["text-textColor-warning"],
};
