import { useIntl } from "react-intl";

export enum ProviderTypes {
  PrimaryCareProviders = "Primary Care Providers",
  SpecialtyProviders = "Specialty Care Providers",
  Hospitals = "Hospitals & Hospital Providers",
}

export enum ProviderNetworks {
  CurativeDirectContract = "Curative Direct Contract",
  CurativeEPO = "Curative EPO",
  CurativePPO = "Curative PPO",
  FirstHealthChoiceMidwest = "First Health Choice of the Midwest",
  FirstHealthCofinity = "First Health Cofinity",
  FirstHealthComplementary = "First Health Complementary Network",
  FirstHealthPrimary = "First Health Primary Network",
  ZeroCard = "Zero Card Network",
}

export enum SearchCategory {
  AllCategories = "All Categories",
  PrimaryCareProviders = "Primary Care Providers",
  SpecialtyProviders = "Specialty Care Providers",
  Hospitals = "Hospitals & Hospital Providers",
  Pharmacies = "Pharmacies",
  Labs = "Labs",
  UrgentCare = "Urgent Care",
  EmergencyCenter = "Emergency Center",
  CareFacilities = "Care Facilities",
  Dialysis = "Dialysis",
  DurableMedicalEquipments = "Durable Medical Equipment",
  InfusionCenters = "Infusion Center",
  RadiologyImagingCenters = "Radiology Imaging Center",
  SkilledNursingFacilities = "Skilled Nursing Facility",
  OtherFacilities = "Other Facilities",
}

export const facilityCategoryTypes = [
  SearchCategory.Pharmacies,
  SearchCategory.Labs,
  SearchCategory.UrgentCare,
  SearchCategory.EmergencyCenter,
  SearchCategory.CareFacilities,
  SearchCategory.Dialysis,
  SearchCategory.DurableMedicalEquipments,
  SearchCategory.InfusionCenters,
  SearchCategory.RadiologyImagingCenters,
  SearchCategory.SkilledNursingFacilities,
  SearchCategory.OtherFacilities,
];

export const providerCategoryTypes = [
  SearchCategory.PrimaryCareProviders,
  SearchCategory.SpecialtyProviders,
];
export const bothCategoryTypes = [
  SearchCategory.AllCategories,
  SearchCategory.Hospitals,
];

// This helper function is needed in order to allow localization of category option text
export const GetLabelForCategory = (category: SearchCategory) => {
  const { formatMessage } = useIntl();
  switch (category) {
    case SearchCategory.AllCategories:
      return formatMessage({ defaultMessage: "All Categories", id: "1X6HtI" });
    case SearchCategory.CareFacilities:
      return formatMessage({ defaultMessage: "Care Facilities", id: "EH3cTu" });
    case SearchCategory.Hospitals:
      return formatMessage({
        defaultMessage: "Hospitals & Hospital Providers",
        id: "Y5ymJK",
      });
    case SearchCategory.Labs:
      return formatMessage({ defaultMessage: "Labs", id: "ylFNoY" });
    case SearchCategory.OtherFacilities:
      return formatMessage({
        defaultMessage: "Other Facilities",
        id: "ZHWefw",
      });
    case SearchCategory.Pharmacies:
      return formatMessage({ defaultMessage: "Pharmacies", id: "3+qSKB" });
    case SearchCategory.PrimaryCareProviders:
      return formatMessage({
        defaultMessage: "Primary Care Providers",
        id: "hI2cND",
      });
    case SearchCategory.SpecialtyProviders:
      return formatMessage({
        defaultMessage: "Specialty Care Providers",
        id: "xcplqk",
      });
    case SearchCategory.UrgentCare:
      return formatMessage({ defaultMessage: "Urgent Care", id: "U/Da+o" });
    case SearchCategory.EmergencyCenter:
      return formatMessage({
        defaultMessage: "Emergency Center",
        id: "GiJc2K",
      });
    case SearchCategory.Dialysis:
      return formatMessage({ defaultMessage: "Dialysis", id: "NXhVxf" });
    case SearchCategory.DurableMedicalEquipments:
      return formatMessage({
        defaultMessage: "Durable Medical Equipment",
        id: "ItapB+",
      });
    case SearchCategory.InfusionCenters:
      return formatMessage({ defaultMessage: "Infusion Center", id: "h3zH4c" });
    case SearchCategory.RadiologyImagingCenters:
      return formatMessage({
        defaultMessage: "Radiology Imaging Center",
        id: "W4l0kn",
      });
    case SearchCategory.SkilledNursingFacilities:
      return formatMessage({
        defaultMessage: "Skilled Nursing Facility",
        id: "7KoD8J",
      });
    default:
      return formatMessage({ defaultMessage: "All Categories", id: "1X6HtI" });
  }
};
