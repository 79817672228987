import { IconVariant } from "@chp/curative_ui";
import { FieldValues, Path } from "react-hook-form";
import { useIntl } from "react-intl";

import { FormMaskedInput } from "../FormMaskedInput";

export const SSN_DEFAULT_EMPTY_VALUE = "___-__-____";

export type SsnFormInputProps<T extends FieldValues = FieldValues> = {
  id: string;
  isDisabled?: boolean;
  name: Path<T>;
  testId?: string;
  icon?: IconVariant;
  clearButtonText?: string;
  onClickButton?: () => void;
};

export const SsnFormInput = <T extends FieldValues = FieldValues>({
  id,
  isDisabled,
  name,
  testId = "SsnFormInput",
  icon,
  clearButtonText,
  onClickButton,
}: SsnFormInputProps<T>) => {
  const { formatMessage } = useIntl();

  return (
    <FormMaskedInput<T>
      autoComplete="off"
      emptyValue={SSN_DEFAULT_EMPTY_VALUE}
      id={id}
      isDisabled={isDisabled}
      label={formatMessage({
        defaultMessage: "Social Security Number",
        id: "bhOaOJ",
      })}
      mask="000-00-0000"
      name={name}
      placeholder={formatMessage({
        defaultMessage: "e.g. 123-45-6789",
        id: "tSbQAr",
      })}
      testId={testId}
      unmask={false}
      icon={icon}
      onClickButton={onClickButton}
      clearButtonText={clearButtonText}
    />
  );
};
