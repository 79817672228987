import clsx from "clsx";
import type { MaskedPattern } from "imask";
import { RefCallback, useMemo } from "react";
import { IMaskInput } from "react-imask";

import { SpacingVariant } from "../../constants";
import { generateRandomString } from "../../utils/text";
import {
  Box,
  formFieldErrorId,
  formFieldIconSize,
  getTextFieldClassNames,
  ScreenReaderOnly,
  useHasError,
} from "..";
import {
  FormFieldDescription,
  FormFieldErrorMessage,
  FormFieldLabelText,
  formFieldTextVariant,
  formFieldWrapperMargin,
} from "../forms";
import { Icon, IconVariant } from "../Icon";
import { CUIComponent, CUIComponentProps, FormFieldBaseProps } from "../types";

export type MaskedInputProps = CUIComponentProps<
  FormFieldBaseProps & {
    autoComplete?: string;
    id?: string;
    innerRef?: RefCallback<HTMLInputElement>;
    isDisabled?: boolean;
    isRequired?: boolean;
    name?: string;
    onBlur?: () => void;
    onFocus?: () => void;
    onRequestChangeValue?(value: string): void;
    placeholder?: string;
    type?: string;
    value?: string;
    maxLength?: number;
    icon?: IconVariant;
    unmask?: true | false | "typed";
    mask: string;
    placeholderAlwaysVisible?: boolean;
    placeholderChar?: string;
    blocks?: MaskedPattern["blocks"];
    onClickButton?: () => void;
    clearButtonText?: string;
  }
>;

//We are only using the pattern mask option from iMask, masks should be provided as a pattern adhering to the following format. ie. '{#}000[aaa]/NIC-`*[**]' or '+{7}(000)000-00-00'
// 0 - any digit
// a - any letter
// * - any char
// other chars which is not in custom definitions supposed to be fixed
// [] - make input optional
// {} - include fixed part in unmasked value
// ` - prevent symbols shift back

// https://imask.js.org/guide.html
export const MaskedInput: CUIComponent<MaskedInputProps> = ({
  autoComplete,
  className = "",
  description,
  errorMessage,
  id: _id,
  innerRef,
  isDisabled,
  isLabelSrOnly,
  isRequired,
  label,
  name,
  onBlur,
  onFocus,
  onRequestChangeValue = () => {},
  placeholder,
  type = "text",
  value,
  testId = "MaskedInput",
  icon,
  mask,
  placeholderAlwaysVisible = true,
  placeholderChar = "_",
  unmask = true,
  blocks,
  onClickButton,
  clearButtonText,
}) => {
  const id = useMemo(() => _id || generateRandomString(), [_id]);
  const hasError = useHasError({ errorMessage });
  const errorId = formFieldErrorId({ hasError, id });

  return (
    <Box
      className={className}
      element="div"
      testId={testId}
      textVariant={formFieldTextVariant}
    >
      <label data-testid="MaskedInput__Label" className={clsx("block")}>
        <FormFieldLabelText isLabelSrOnly={isLabelSrOnly} label={label} />

        <FormFieldDescription description={description} />

        <Box
          className="relative flex"
          element="div"
          margin={formFieldWrapperMargin({ isLabelSrOnly })}
        >
          {icon && (
            <Icon
              className={clsx("absolute", "top-[calc(50%_-_0.75em)]", "left-2")}
              size={SpacingVariant.S24}
              variant={icon}
            />
          )}

          <IMaskInput
            mask={mask}
            blocks={blocks}
            lazy={!placeholderAlwaysVisible}
            placeholderChar={placeholderChar}
            value={value}
            unmask={unmask} // true|false|'typed'
            inputRef={innerRef}
            onAccept={(value, _) => {
              // depending on prop above first argument is
              // `value` if `unmask=false`,
              // `unmaskedValue` if `unmask=true`,
              // `typedValue` if `unmask='typed'`
              onRequestChangeValue && onRequestChangeValue(value);
            }}
            onBlur={onBlur}
            onFocus={onFocus}
            autoComplete={autoComplete}
            // 1password does not always respect `autocomplete="off"` for who knows why
            // https://1password.community/discussion/117501/as-a-web-developer-how-can-i-disable-1password-filling-for-a-specific-field/
            data-1p-ignore={autoComplete === "off"}
            placeholder={placeholder}
            disabled={isDisabled}
            data-testid={`${testId}__Input`}
            id={id}
            name={name}
            type={type}
            required={isRequired}
            className={getTextFieldClassNames({
              isDisabled,
              hasError,
              hasIcon: Boolean(icon),
            })}
            aria-describedby={errorId}
            aria-invalid={hasError}
          />
          {clearButtonText && onClickButton && (
            <button
              data-testid={`${testId}__ClearButton`}
              onClick={onClickButton}
              className={clsx("absolute", "right-2", "top-3")}
              type="reset"
            >
              <Icon size={formFieldIconSize} variant={IconVariant.X} />
              <ScreenReaderOnly element="span">
                {clearButtonText}
              </ScreenReaderOnly>
            </button>
          )}
        </Box>
      </label>
      {hasError && errorId && (
        <FormFieldErrorMessage
          errorMessage={errorMessage}
          testId={`${testId}__ErrorMessage`}
          id={errorId}
        />
      )}
    </Box>
  );
};
