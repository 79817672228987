import clsx from "clsx";
import { ReactElement } from "react";

import { SpacingVariant } from "../../constants";
import { Box } from "../Box";
import { bgColorsCommon, textColorsCommon } from "../colors";
import { Icon, IconVariant } from "../Icon";
import { CUIComponent, TextVariant } from "../types";

export enum BadgeVariant {
  DEFAULT = "DEFAULT",
  DEPRESSED = "DEPRESSED",
  ERROR = "ERROR",
  INFO = "INFO",
  SUBDUED = "SUBDUED",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  MEDIUM_RED = "MEDIUM_RED",
}

export type BadgeSize = "small" | "large";

export type BadgeProps = {
  message: string | ReactElement;
  variant?: BadgeVariant;
  size?: BadgeSize;
  iconVariant?: IconVariant;
  textVariant?: TextVariant;
  textColorOverride?: string;
  backgroundColorOverride?: string;
  style?: React.HTMLAttributes<HTMLElement>["style"];
};

const backgroundColorClassNames: Record<BadgeVariant, string[]> = {
  [BadgeVariant.DEFAULT]: ["bg-surface-neutral"],
  [BadgeVariant.DEPRESSED]: bgColorsCommon.depressed,
  [BadgeVariant.ERROR]: ["bg-surface-critical"],
  [BadgeVariant.INFO]: ["bg-surface-highlight"],
  [BadgeVariant.SUBDUED]: bgColorsCommon.subdued,
  [BadgeVariant.SUCCESS]: ["bg-surface-success"],
  [BadgeVariant.WARNING]: ["bg-surface-warning"],
  [BadgeVariant.MEDIUM_RED]: ["bg-red-300"],
};

const textColorClassNames: Record<BadgeVariant, string[]> = {
  [BadgeVariant.DEFAULT]: ["text-textColor-subdued"],
  [BadgeVariant.DEPRESSED]: textColorsCommon.onBgPrimary,
  [BadgeVariant.ERROR]: ["text-textColor-critical"],
  [BadgeVariant.INFO]: ["text-textColor-highlight-depressed"],
  [BadgeVariant.SUBDUED]: textColorsCommon.onBgPrimary,
  [BadgeVariant.SUCCESS]: ["text-textColor-success"],
  [BadgeVariant.WARNING]: ["text-foundation-warning"],
  [BadgeVariant.MEDIUM_RED]: textColorsCommon.default,
};

const getLayoutClassName = (size: BadgeSize): string => {
  const className = "rounded w-fit";

  if (size === "small") {
    return clsx(className, "px-3 py-0.5");
  } else {
    return clsx(className, "px-4 py-2");
  }
};

export const Badge: CUIComponent<BadgeProps> = ({
  message,
  variant = BadgeVariant.DEFAULT,
  size = "large",
  iconVariant,
  style,
  className,
  testId = "Badge",
  textColorOverride,
  textVariant = TextVariant.SmRegularTall,
  backgroundColorOverride,
}) => {
  return (
    <div
      data-testid={testId}
      className={clsx(
        getLayoutClassName(size),
        backgroundColorOverride ?? backgroundColorClassNames[variant],
        className
      )}
      style={style}
    >
      <Box
        element="span"
        className={clsx(
          "flex",
          "items-center",
          "gap-x-1.5",
          textColorOverride ?? textColorClassNames[variant]
        )}
        textVariant={textVariant}
      >
        {iconVariant && (
          <Icon
            variant={iconVariant}
            size={SpacingVariant.S16}
            className="inline-block"
          />
        )}{" "}
        {message}
      </Box>
    </div>
  );
};
