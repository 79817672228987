import { PropsWithChildren, useMemo } from "react";

import { SpacingVariant } from "../../constants";
import { PaddingSize } from "../../types";
import { cuiClassNames } from "../cuiClassNames";
import {
  CUIComponentProps,
  DisplayVariant,
  MarginProps,
  TextAlignVariant,
  TextVariant,
  VerticalAlignVariant,
} from "../types";

export type BoxProps<T extends keyof JSX.IntrinsicElements> =
  JSX.IntrinsicElements[T] &
    CUIComponentProps<
      PropsWithChildren<{
        display?: DisplayVariant;
        element: T;
        height?: SpacingVariant;
        margin?: MarginProps;
        padding?: PaddingSize;
        textAlign?: TextAlignVariant;
        textVariant?: TextVariant;
        verticalAlign?: VerticalAlignVariant;
        width?: SpacingVariant;
      }>
    >;

/**
 * Box component is a basic generic component that can render any HTML element with global CUI style resets &
 * Typography or Spacing variants.
 *
 * Warning: ref logic may not work with Box. See:
 * - https://react.dev/reference/react/forwardRef
 * - https://react.dev/warnings/special-props
 */
export const Box = <T extends keyof JSX.IntrinsicElements>(
  props: BoxProps<T>
): JSX.Element => {
  const {
    children,
    className,
    display,
    element,
    height,
    margin,
    padding,
    textAlign,
    textVariant,
    verticalAlign,
    width,
    testId = "Box",
    // Warning: ref logic may not work. See:
    // https://react.dev/reference/react/forwardRef
    // https://react.dev/warnings/special-props
    // TODO: remove broken ref?
    ref,
    ...rest
  } = props;
  const Element = element.toString();
  const classNames = useMemo(
    () =>
      cuiClassNames({
        className,
        display,
        height,
        margin,
        padding,
        textAlign,
        textVariant,
        verticalAlign,
        width,
      }),
    [
      className,
      display,
      height,
      margin,
      padding,
      textAlign,
      textVariant,
      verticalAlign,
      width,
    ]
  );

  return (
    <Element
      className={classNames}
      data-testid={testId}
      // TODO: remove broken ref?
      ref={ref}
      {...rest}
    >
      {children}
    </Element>
  );
};
