import clsx from "clsx";
import Link from "next/link";

interface TableRowProps {
  dark?: boolean;
  noHover?: boolean;
  href?: string;
  as?: string;
  children?: JSX.Element | JSX.Element[];
  className?: string;
  onClick?: () => void;
}

export default function TableRow(props: TableRowProps) {
  const classNames = clsx(
    "flex w-full max-w-full items-center justify-around p-2",
    props.dark ? "bg-gray-100" : "bg-white",
    !props.noHover && "cursor-pointer hover:bg-blue-200",
    props.className
  );

  if (!props.href) {
    return (
      <div className={classNames} onClick={props.onClick}>
        {props.children}
      </div>
    );
  }
  return (
    <Link href={props.href} as={props.as}>
      <div className={classNames}>{props.children}</div>
    </Link>
  );
}
