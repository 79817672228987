import {
  FormattedAlert,
  type FormattedAlertProps,
} from "@chp/shared/components/FormattedAlert";
import type { WithRequiredProps } from "@chp/shared/types";
import clsx from "clsx";
import type { FC } from "react";

import { ToastAlertAlignment, ToastAlertPosition } from "../enums";

export type FormattedToastAlertProps = WithRequiredProps<
  FormattedAlertProps,
  "onRequestClose"
> & {
  alignment?: ToastAlertAlignment;
  position?: ToastAlertPosition;
};

const alignmentClassNames: Record<ToastAlertAlignment, string> = {
  [ToastAlertAlignment.LEFT]: "justify-start",
  [ToastAlertAlignment.MIDDLE]: "justify-center",
  [ToastAlertAlignment.RIGHT]: "justify-end",
};

const positionClassNames: Record<ToastAlertPosition, string> = {
  [ToastAlertPosition.ABSOLUTE]: "!absolute",
  [ToastAlertPosition.FIXED]: "!fixed",
  [ToastAlertPosition.STICKY]: "!sticky",
};

export const FormattedToastAlert: FC<FormattedToastAlertProps> = ({
  className,
  testId = "FormattedToastAlert",
  alignment = ToastAlertAlignment.RIGHT,
  position = ToastAlertPosition.FIXED,
  type,
  title,
  message,
  onRequestClose,
}) => (
  <div
    className={clsx(
      "z-[100] flex w-full flex-row p-5",
      alignmentClassNames[alignment],
      positionClassNames[position],
      className
    )}
  >
    <FormattedAlert
      className="max-w-md md:max-w-2xl"
      testId={testId}
      type={type}
      title={title}
      message={message}
      onRequestClose={onRequestClose}
      shouldSetFocusOnMount
    />
  </div>
);
