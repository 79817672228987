import { Alert, AlertType } from "@chp/curative_ui";
import clsx from "clsx";
import { useState } from "react";

import { IS_NON_PROD_ENV } from "../../env";

export const NonProdBanner = ({ className }: { className?: string }) => {
  const [isVisible, setIsVisible] = useState(true);

  if (IS_NON_PROD_ENV && isVisible)
    return (
      <Alert
        onRequestClose={() => setIsVisible(false)}
        closeButtonText="Close"
        type={AlertType.WARN}
        title="This is a non-production environment." //not translated as this is only seen in a testing environment
        className={clsx("!fixed bottom-2 left-4 z-50", className)}
        testId="NonProd__Alert"
      />
    );
  return null;
};
