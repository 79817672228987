import { Select, SelectProps } from "@chp/curative_ui";

import { useErrorMessageWithPrefix } from "../../hooks";

export type FormattedSelectProps<T> = SelectProps<T>;

export const FormattedSelect = <T,>({
  errorMessage,
  ...rest
}: FormattedSelectProps<T>): JSX.Element => {
  const errorWithPrefix = useErrorMessageWithPrefix({ errorMessage });
  return <Select errorMessage={errorWithPrefix} {...rest} />;
};
