import { FC } from "react";

import { AccordionItem, AccordionItemProps } from "./AccordionItem";

export type AccordionItemDescriptor = AccordionItemProps & {
  key: string;
};

export type AccordionProps = {
  className?: string;
  accordionItems: AccordionItemDescriptor[];
  defaultOpen?: boolean;
};

export const Accordion: FC<AccordionProps> = ({
  className,
  accordionItems,
  defaultOpen,
}) => (
  <div className={className}>
    {accordionItems.map(
      ({ key, summaryContent, panelContent, onButtonClick }) => (
        <AccordionItem
          key={key}
          testid={key}
          summaryContent={summaryContent}
          panelContent={panelContent}
          onButtonClick={onButtonClick}
          defaultOpen={defaultOpen}
        />
      )
    )}
  </div>
);
