import { NullOrUndefined } from "./NullOrUndefined";

export type MemberAddress = {
  city: string;
  state: MemberAddressState;
  street: string;
  street2?: string | NullOrUndefined;
  zipCode: string;
};

/**
 * Valid US states (or territories) for MemberAddress.state. Abbreviations only, store display names separately.
 *
 * See: https://pe.usps.com/text/pub28/28apb.htm
 *
 */
export enum MemberAddressState {
  AK = "AK",
  AL = "AL",
  AR = "AR",
  AS = "AS",
  AZ = "AZ",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DC = "DC",
  DE = "DE",
  FL = "FL",
  FM = "FM",
  GA = "GA",
  GU = "GU",
  HI = "HI",
  IA = "IA",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  MA = "MA",
  MD = "MD",
  ME = "ME",
  MH = "MH",
  MI = "MI",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MS = "MS",
  MT = "MT",
  NC = "NC",
  ND = "ND",
  NE = "NE",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NV = "NV",
  NY = "NY",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  PR = "PR",
  PW = "PW",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VA = "VA",
  VI = "VI",
  VT = "VT",
  WA = "WA",
  WI = "WI",
  WV = "WV",
  WY = "WY",
}

export type MemberAddressStateOption = {
  label: string;
  value: MemberAddressState;
};
