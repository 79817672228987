/**
 * @param {string} projectSourceFolderPath
 */
const createApolloConfig = (projectSourceFolderPath = ".") => ({
  client: {
    includes: [
      `${projectSourceFolderPath}/pages/**.*`,
      `${projectSourceFolderPath}/components/**.*`,
    ],
  },
});

module.exports = { createApolloConfig };
