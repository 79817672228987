import {
  MemberPortalMixpanelUiIdentifierName,
  SharedMixpanelEventName,
  useMemberPortalMixpanelContext,
} from "@chp/shared";
import { useCallback } from "react";
import { useIntl } from "react-intl";

import { ActiveUser } from "~/state/effects/registrationEffects";

export const useHelpWidgetLogic = (activeUser: ActiveUser | null) => {
  const { formatMessage } = useIntl();
  const { trackEvent } = useMemberPortalMixpanelContext();

  const onHelpWidgetOpen = useCallback(() => {
    trackEvent({
      uiIdentifier: MemberPortalMixpanelUiIdentifierName.HELP_WIDGET,
      eventName: SharedMixpanelEventName.CLICK,
      eventProps: {
        user: activeUser,
      },
    });
  }, [activeUser, trackEvent]);

  const helpWidgetText = {
    needHelpText: formatMessage({
      defaultMessage: "Need help?",
      id: "krty63",
    }),
    pharmacyHeadingText: formatMessage({
      defaultMessage: "Pharmacy Help & Support",
      id: "Ep5juT",
    }),
    srOnlyOpenLabel: formatMessage({
      defaultMessage: "Open Help Widget Dialog",
      id: "Oyouep",
    }),
    headingText: formatMessage({
      defaultMessage: "Help & Support",
      id: "Uf3+S6",
    }),
    descriptionText: formatMessage({
      defaultMessage: "Got questions? Don't worry! We've got you covered.",
      id: "jgRu2z",
    }),
  };

  return { helpWidgetText, onHelpWidgetOpen };
};
