import { Combobox } from "@headlessui/react";
import clsx from "clsx";
import React, {
  Dispatch,
  MouseEvent as ReactMouseEvent,
  MutableRefObject,
  RefCallback,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { SpacingVariant } from "../../constants";
import { useArrayKeys } from "../../hooks";
import { generateRandomString } from "../../utils/text";
import { Badge } from "../Badge";
import { Box } from "../Box";
import { textColorsCommon } from "../colors";
import { DISPLAY_CLASS_NAMES, TEXT_CLASS_NAMES } from "../constants";
import {
  formFieldErrorId,
  FormFieldErrorMessage,
  formFieldIconSize,
  formFieldLabelTextDisplayVariant,
  formFieldTextVariant,
  formFieldWrapperMargin,
  useHasError,
} from "../forms";
import { Icon, IconVariant } from "../Icon";
import { Spinner } from "../Spinner";
import { getTextFieldClassNames } from "../TextField";
import {
  CUIComponentProps,
  DisplayVariant,
  FormFieldBaseProps,
  TextVariant,
  VerticalAlignVariant,
} from "../types";
import {
  TypeaheadOption,
  TypeaheadOptionGroups,
  WithTypeaheadOptions,
} from "./types";

const FIELD_BUTTON_CLASS_NAMES: string[] = ["absolute", "inset-y-0", "right-2"];

export const USER_TYPING_TIMEOUT = 700;

export type TypeaheadProps<U = string> = CUIComponentProps<
  Pick<FormFieldBaseProps, "errorMessage" | "label"> &
    WithTypeaheadOptions<U> & {
      isAlwaysOpen?: boolean;
      id?: string;
      isLabelSrOnly?: boolean;
      hasDropdownToggle?: boolean;
      queryString: string;
      queryStringMinLength?: number;
      setQueryString: Dispatch<SetStateAction<string>>;
      //  what to use as the array key for this option
      optionKeyExtractor: (
        option: TypeaheadOption<U>,
        index: number
      ) => string | number;
      //  what to use as the display value in the dropdown
      optionLabelExtractor: (option: TypeaheadOption<U>) => string;
      optionLabel2Extractor?: (
        option: TypeaheadOption<U>
      ) => string | JSX.Element | undefined;
      optionLabel3Extractor?: (
        option: TypeaheadOption<U>
      ) => string | JSX.Element | undefined;
      value: TypeaheadOption<U> | null;
      onClickOption: (option: TypeaheadOption<U> | null) => void;
      name?: string;
      placeholder?: string;
      isLoading?: boolean;
      inputRef?:
        | MutableRefObject<HTMLInputElement>
        | RefCallback<HTMLInputElement>;
      noResultsTextWithQuery: string | JSX.Element;
      noResultsTextWithoutQuery: string | JSX.Element;
      isDisabled?: boolean;
      clearButtonAriaLabel: string;
      loadingAriaLabel: string;
      prefixIcon?: IconVariant;
      onQueryStringDirty?: () => void;
      prefixIconLoading?: boolean;
      disableEnterWhileLoading?: boolean;
      createNewOptionText?: (queryString: string) => string | JSX.Element;
      alwaysDisplayCreateNewOption?: boolean;
    }
>;

const DropdownToggle = (
  props: Required<Pick<TypeaheadProps, "hasDropdownToggle" | "testId">> & {
    isComboboxOpen: boolean;
  }
): JSX.Element | null => {
  const { isComboboxOpen, hasDropdownToggle, testId } = props;

  // check if dropdown toggle is needed
  if (!hasDropdownToggle) {
    return null;
  }

  return (
    <Combobox.Button
      className={clsx(...FIELD_BUTTON_CLASS_NAMES)}
      data-testid={testId}
    >
      <Icon
        variant={
          isComboboxOpen ? IconVariant.CHEVRON_UP : IconVariant.CHEVRON_DOWN
        }
        size={formFieldIconSize}
      />
    </Combobox.Button>
  );
};

/**
 * Message to display when options list is empty
 */
const getEmptyListMessage = ({
  noResultsTextWithQuery,
  noResultsTextWithoutQuery,
  queryString,
}: Pick<
  TypeaheadProps,
  "noResultsTextWithQuery" | "noResultsTextWithoutQuery" | "queryString"
>): string | JSX.Element => {
  return queryString ? noResultsTextWithQuery : noResultsTextWithoutQuery;
};

const EmptyListMessage = ({
  children,
  testId,
}: {
  children: string | JSX.Element;
  testId: string;
}): JSX.Element => {
  const padding = SpacingVariant.S16;
  return (
    <Box
      className={clsx(textColorsCommon.default)}
      display={DisplayVariant.FLEX}
      element="div"
      padding={{ bottom: padding, left: padding, right: padding, top: padding }}
      testId={testId}
      textVariant={TextVariant.MdRegularTall}
      role="option"
    >
      <Box
        className={clsx(textColorsCommon.warning, "flex-none")}
        element="div"
        margin={{ right: SpacingVariant.S8 }}
      >
        <Icon
          size={SpacingVariant.S24}
          variant={IconVariant.EXCLAMATION_CIRCLE}
        />
      </Box>
      <Box element="p">{children}</Box>
    </Box>
  );
};

const Option = <T,>({
  option,
  optionLabelExtractor,
  optionLabel2Extractor,
  optionLabel3Extractor,
  testId,
}: Pick<
  TypeaheadProps<T>,
  | "optionLabelExtractor"
  | "optionLabel2Extractor"
  | "optionLabel3Extractor"
  | "testId"
> & {
  option: TypeaheadOption<T>;
}): JSX.Element | null => {
  const optionLabel = useMemo(
    () => optionLabelExtractor(option),
    [option, optionLabelExtractor]
  );
  const optionLabel2 = useMemo(
    () => (optionLabel2Extractor ? optionLabel2Extractor(option) : null),
    [option, optionLabel2Extractor]
  );
  const optionLabel3 = useMemo(
    () => (optionLabel3Extractor ? optionLabel3Extractor(option) : null),
    [option, optionLabel3Extractor]
  );
  return (
    <Combobox.Option
      className={({ active }) =>
        clsx(
          "cursor-pointer",
          "w-full",
          "text-left",
          "p-2",
          active && clsx("bg-surface-highlight", "underline")
        )
      }
      value={option}
    >
      {({ active, selected }) => (
        <Box
          element="div"
          textVariant={
            selected ? TextVariant.MdBold : TextVariant.MdRegularTall
          }
        >
          {option.badge ? (
            <Box element="div">
              <Box
                className={clsx(active && "underline")}
                display={DisplayVariant.InlineBlock}
                element="p"
                margin={{
                  right: SpacingVariant.S8,
                }}
                testId={`${testId}__Option`}
                verticalAlign={VerticalAlignVariant.Middle}
              >
                {optionLabel}
              </Box>
              <Box
                display={DisplayVariant.InlineBlock}
                element="div"
                verticalAlign={VerticalAlignVariant.Middle}
              >
                <Badge
                  size="small"
                  testId={`${testId}__OptionBadge`}
                  {...option.badge}
                />
              </Box>
            </Box>
          ) : (
            <Box element="p" testId={`${testId}__Option`}>
              {optionLabel}
            </Box>
          )}
          {optionLabel2 && (
            <Box
              element="p"
              className={clsx(
                TEXT_CLASS_NAMES[TextVariant.SmRegularTall],
                "text-textColor-subdued"
              )}
            >
              {optionLabel2}
            </Box>
          )}
          {optionLabel3 && (
            <Box element="p" className="text-sm-tall">
              {optionLabel3}
            </Box>
          )}
        </Box>
      )}
    </Combobox.Option>
  );
};

const OptionItems = <T,>({
  optionKeyExtractor,
  optionLabelExtractor,
  optionLabel2Extractor,
  optionLabel3Extractor,
  options,
  testId,
}: Pick<
  TypeaheadProps<T>,
  | "optionKeyExtractor"
  | "optionLabelExtractor"
  | "optionLabel2Extractor"
  | "optionLabel3Extractor"
  | "testId"
> & {
  options: TypeaheadOption<T>[];
}): JSX.Element | null => {
  const optionKeys = useArrayKeys({
    items: options,
    keyGenerator: optionKeyExtractor,
  });
  return (
    <>
      {options.map((option, idx) => (
        <Option
          key={optionKeys[idx]}
          {...{
            option,
            optionLabelExtractor,
            optionLabel2Extractor,
            optionLabel3Extractor,
            testId,
          }}
        />
      ))}
    </>
  );
};

const OptionGroups = <T,>({
  optionGroups,
  optionKeyExtractor,
  optionLabelExtractor,
  optionLabel2Extractor,
  optionLabel3Extractor,
  testId,
}: Pick<
  TypeaheadProps<T>,
  | "optionKeyExtractor"
  | "optionLabelExtractor"
  | "optionLabel2Extractor"
  | "optionLabel3Extractor"
  | "testId"
> & {
  optionGroups: TypeaheadOptionGroups<T>;
}): JSX.Element | null => {
  return (
    <>
      {optionGroups.groupList.map((group) => {
        if (group.options.length === 0) return null;
        return (
          <Box element="li" key={group.key} testId={`${testId}__OptionGroup`}>
            <Box
              className={clsx(textColorsCommon.subdued)}
              element={optionGroups.headingLevel}
              onClick={(event: ReactMouseEvent<HTMLHeadingElement>) =>
                event.preventDefault()
              }
              padding={{
                bottom: SpacingVariant.S12,
                left: SpacingVariant.S16,
                right: SpacingVariant.S16,
                top: SpacingVariant.S12,
              }}
              testId={`${testId}__OptionGroup__Heading`}
              textVariant={TextVariant.MdBold}
            >
              {group.heading}
            </Box>
            <Box
              className="[&_li]:pl-8"
              element="ul"
              testId={`${testId}__OptionGroup__List`}
            >
              <OptionItems
                {...{
                  optionKeyExtractor,
                  optionLabelExtractor,
                  optionLabel2Extractor,
                  optionLabel3Extractor,
                  options: group.options,
                  testId,
                }}
              />
            </Box>
          </Box>
        );
      })}
    </>
  );
};

type OptionsContainerProps<T> = Pick<
  TypeaheadProps<T>,
  | "isAlwaysOpen"
  | "isLoading"
  | "noResultsTextWithQuery"
  | "noResultsTextWithoutQuery"
  | "optionGroups"
  | "optionKeyExtractor"
  | "optionLabelExtractor"
  | "optionLabel2Extractor"
  | "optionLabel3Extractor"
  | "options"
  | "queryString"
  | "testId"
  | "createNewOptionText"
  | "alwaysDisplayCreateNewOption"
  | "loadingAriaLabel"
>;

const OptionsContainer = <T,>({
  isAlwaysOpen,
  isLoading,
  noResultsTextWithQuery,
  noResultsTextWithoutQuery,
  optionGroups,
  optionKeyExtractor,
  optionLabelExtractor,
  optionLabel2Extractor,
  optionLabel3Extractor,
  options,
  queryString,
  testId,
  createNewOptionText,
  alwaysDisplayCreateNewOption,
  loadingAriaLabel,
}: OptionsContainerProps<T>): JSX.Element | null => {
  const isEmpty: boolean = useMemo(
    () =>
      (!!options && options.length === 0) ||
      (!!optionGroups &&
        optionGroups.groupList.find((group) => group.options.length > 0) ===
          undefined),
    [optionGroups, options]
  );

  const emptyComponent = createNewOptionText ? (
    <Combobox.Option
      className={({ active }) =>
        clsx(
          "cursor-pointer",
          "w-full",
          "text-left",
          "p-2",
          active && clsx("bg-surface-highlight", "underline")
        )
      }
      value={{ value: null, label: queryString }}
    >
      {createNewOptionText(queryString)}
    </Combobox.Option>
  ) : (
    <EmptyListMessage testId={`${testId}__EmptyListMessage`}>
      {getEmptyListMessage({
        noResultsTextWithoutQuery,
        noResultsTextWithQuery,
        queryString,
      })}
    </EmptyListMessage>
  );

  return (
    <Combobox.Options
      static={isAlwaysOpen}
      className={clsx(
        "max-h-48",
        "overflow-x-hidden",
        "overflow-y-auto",
        "z-10",
        "border-solid",
        "border-borderColor-control",
        "rounded",
        "bg-background-default",
        "mt-2",
        "border",
        "flex",
        "flex-col",
        "w-full",
        "absolute",
        optionGroups && !isLoading && !isEmpty && "pt-1"
      )}
      data-testid={`${testId}__Results`}
    >
      {isLoading ? (
        <div
          data-testid={`${testId}__Loader`}
          className="flex justify-center p-2"
          role="option"
          aria-selected={false}
          aria-label={loadingAriaLabel}
        >
          <Spinner size={SpacingVariant.S32} />
        </div>
      ) : isEmpty ? (
        emptyComponent
      ) : options ? (
        <>
          {alwaysDisplayCreateNewOption && createNewOptionText && (
            <Combobox.Option
              className={({ active }) =>
                clsx(
                  "cursor-pointer",
                  "w-full",
                  "text-left",
                  "p-2",
                  "border-b",
                  "bg-gray-100",
                  active && clsx("bg-surface-highlight", "underline")
                )
              }
              value={{ value: null, label: queryString }}
            >
              {createNewOptionText(queryString)}
            </Combobox.Option>
          )}
          <OptionItems
            {...{
              optionKeyExtractor,
              optionLabelExtractor,
              optionLabel2Extractor,
              optionLabel3Extractor,
              options,
              testId,
            }}
          />
        </>
      ) : (
        optionGroups && (
          <OptionGroups
            {...{
              optionGroups,
              optionKeyExtractor,
              optionLabelExtractor,
              optionLabel2Extractor,
              optionLabel3Extractor,
              testId,
            }}
          />
        )
      )}
    </Combobox.Options>
  );
};

export function Typeahead<T>({
  isAlwaysOpen = false,
  id: _id,
  isLabelSrOnly,
  hasDropdownToggle = true,
  optionGroups,
  options,
  queryString,
  queryStringMinLength,
  setQueryString,
  optionKeyExtractor,
  optionLabelExtractor,
  optionLabel2Extractor,
  optionLabel3Extractor,
  onClickOption,
  onQueryStringDirty,
  isLoading,
  prefixIconLoading,
  className,
  label,
  name,
  value,
  placeholder,
  inputRef,
  testId = "Typeahead",
  errorMessage,
  isDisabled,
  clearButtonAriaLabel,
  noResultsTextWithQuery,
  noResultsTextWithoutQuery,
  prefixIcon,
  disableEnterWhileLoading = false,
  createNewOptionText,
  alwaysDisplayCreateNewOption,
  loadingAriaLabel,
}: TypeaheadProps<T>): JSX.Element {
  const [isUserTyping, setIsUserTyping] = useState(false);
  const id = useMemo(() => _id || generateRandomString(), [_id]);
  const hasError = useHasError({ errorMessage });
  const errorId = formFieldErrorId({ hasError, id });
  const hasMinLength: boolean =
    queryStringMinLength === undefined ||
    queryString.length >= queryStringMinLength;
  const isDisplayLoading = isLoading || isUserTyping;
  // This ref is used to clear the input when the clear button is clicked
  const internalInputRef = useRef<HTMLInputElement | null>(null);
  // Use the internal ref if not inputRef is provided or if inputRef is a RefCallback
  const ref =
    inputRef && !(typeof inputRef === "function") ? inputRef : internalInputRef;

  useEffect(() => {
    // This is necessary to ensure that the value of `queryString` always matches
    // the value of `value.label` in the case it is modified without using the
    // Typeahead component to select it. A use case is where the input is pre-filled
    // on page load from local/session storage. By keeping these in sync, we make
    // sure that the toggle arrows and the X icon show the correct state.
    setQueryString(value?.label || "");
    // Update the input value in the DOM
    if (ref && ref.current) {
      // @ts-ignore
      ref.current.value = value?.label || "";
    }
  }, [ref, value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsUserTyping(false);
    }, USER_TYPING_TIMEOUT);

    return () => clearTimeout(timeout);
  }, [queryString]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (disableEnterWhileLoading && isDisplayLoading && event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Box
      id={id}
      className={clsx(className)}
      element="div"
      testId={testId}
      textVariant={formFieldTextVariant}
    >
      <Combobox
        value={value}
        onChange={(option) => {
          if (!option) {
            return;
          }

          setQueryString(optionLabelExtractor(option));
          onClickOption(option);
        }}
        name={name}
        disabled={isDisabled}
      >
        {({ open: isComboboxOpen }) => (
          <>
            <Combobox.Label
              className={clsx(
                ...DISPLAY_CLASS_NAMES[formFieldLabelTextDisplayVariant],
                isLabelSrOnly ? "sr-only" : null
              )}
            >
              {label}
            </Combobox.Label>

            <Box
              className="relative"
              element="div"
              margin={formFieldWrapperMargin({})}
            >
              <Combobox.Input
                aria-describedby={errorId}
                aria-invalid={hasError}
                data-testid={`${testId}__Input`}
                ref={(element: HTMLInputElement) => {
                  ref.current = element;
                  if (typeof inputRef === "function") {
                    inputRef(element);
                  }
                }}
                className={clsx(
                  getTextFieldClassNames({
                    isDisabled,
                    hasError,
                    hasButton: true,
                  }),
                  "text-ellipsis",
                  "placeholder:text-textColor-default",
                  !!prefixIcon && "indent-7"
                )}
                onChange={(e) => {
                  setIsUserTyping(true);
                  onQueryStringDirty && onQueryStringDirty();
                  setQueryString(e.target.value);
                }}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                autoComplete="off"
                displayValue={(option: TypeaheadOption<T> | null) => {
                  if (!option) {
                    return queryString;
                  }

                  return optionLabelExtractor(option);
                }}
              />
              {prefixIcon && (
                <>
                  {prefixIconLoading ? (
                    <div className="absolute left-4 top-4">
                      <Spinner size={SpacingVariant.S16} />
                    </div>
                  ) : (
                    <Icon
                      variant={prefixIcon}
                      size={SpacingVariant.S20}
                      className="absolute left-4 top-3.5"
                    />
                  )}
                </>
              )}
              {!isDisabled &&
                (queryString ? (
                  <Combobox.Button
                    type="button"
                    aria-label={clearButtonAriaLabel}
                    className={clsx(...FIELD_BUTTON_CLASS_NAMES)}
                    onClick={() => {
                      setQueryString("");
                      onClickOption(null);
                      // Setting query string to empty string will not clear the input value in the DOM
                      // so we need to do it manually using the ref
                      if (ref && ref.current) {
                        // @ts-ignore
                        ref.current.value = "";
                        ref.current.focus();
                      }
                    }}
                    data-testid={`${testId}__Clear`}
                  >
                    <Icon variant={IconVariant.X} size={formFieldIconSize} />
                  </Combobox.Button>
                ) : (
                  hasMinLength && (
                    <DropdownToggle
                      hasDropdownToggle={hasDropdownToggle}
                      isComboboxOpen={isComboboxOpen}
                      testId={`${testId}__DropdownToggle`}
                    />
                  )
                ))}

              {!isDisabled && hasMinLength && (
                <OptionsContainer
                  isLoading={isDisplayLoading}
                  {...{
                    isAlwaysOpen,
                    noResultsTextWithQuery,
                    noResultsTextWithoutQuery,
                    optionGroups,
                    optionKeyExtractor,
                    optionLabelExtractor,
                    optionLabel2Extractor,
                    optionLabel3Extractor,
                    options,
                    queryString,
                    testId,
                    createNewOptionText,
                    alwaysDisplayCreateNewOption,
                    loadingAriaLabel,
                  }}
                />
              )}
            </Box>
          </>
        )}
      </Combobox>

      {hasError && errorId && (
        <FormFieldErrorMessage
          errorMessage={errorMessage}
          id={errorId}
          testId={`${testId}__ErrorMessage`}
        />
      )}
    </Box>
  );
}
