import clsx from "clsx";

import { SpacingVariant } from "../../constants";
import { HEIGHT_CLASS_NAMES, WIDTH_CLASS_NAMES } from "../constants";
import { CUIComponent, CUIComponentProps } from "../types";

const spinnerDefaultStyles: string[] = [
  "stroke-blue-700",
  "stroke-2",
  "animate-spin",
];
const svgPath: string =
  "M11.0754 20C9.47674 20 7.90645 19.5812 6.52343 18.786C5.14041 17.9909 3.99374 16.8475 3.19945 15.4717C2.40515 14.0958 1.99143 12.5363 2.00013 10.951C2.00884 9.36563 2.43968 7.81069 3.24904 6.44351C4.0584 5.07633 5.21756 3.94543 6.60924 3.16524C8.00091 2.38505 9.5757 1.98326 11.1743 2.00053C12.7728 2.01781 14.3384 2.45352 15.7126 3.26359C17.0868 4.07367 18.2209 5.22935 19 6.61369";

export type SpinnerProps = CUIComponentProps<{
  containerClassName?: string;
  size?: SpacingVariant;
}>;

export const Spinner: CUIComponent<SpinnerProps> = ({
  containerClassName,
  className,
  size = SpacingVariant.S24,
  testId = "Spinner",
}) => {
  return (
    <div
      className={clsx(
        containerClassName,
        spinnerDefaultStyles,
        WIDTH_CLASS_NAMES[size],
        HEIGHT_CLASS_NAMES[size]
      )}
    >
      <svg
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(className)}
        data-testid={testId}
      >
        <path d={svgPath} />
      </svg>
    </div>
  );
};
