import { DEFAULT_LOCALE } from "@chp/shared/constants";
import clsx from "clsx";
import Image from "next/image";
import { FC, MutableRefObject } from "react";
import { useIntl } from "react-intl";

export type InsuranceCardBothSidesProps = {
  cardContainerRef?: MutableRefObject<HTMLDivElement | null>;
  className?: string;
  testId?: string;
  insuranceCardFrontClassName?: string;
  insuranceCardBackClassName?: string;
  insuranceCardBothSidesClassName?: string;
  cardFrontUrl?: string | null;
  cardBackUrl?: string | null;
  cardBothSidesUrl?: string;
};

export const InsuranceCardBothSides: FC<InsuranceCardBothSidesProps> = ({
  cardContainerRef,
  className,
  testId = "InsuranceCardBothSides",
  insuranceCardFrontClassName,
  insuranceCardBackClassName,
  insuranceCardBothSidesClassName,
  cardFrontUrl,
  cardBackUrl,
  cardBothSidesUrl,
}) => {
  const { formatMessage } = useIntl();
  const singleSideImageWidth = 555;
  const singleSideImageHeight = 825;
  const bothSidesImageWidth = 600;
  const bothSidesImageHeight = 300;

  return (
    <div
      ref={cardContainerRef}
      className={clsx("flex flex-wrap justify-center", className)}
      data-testid={testId}
      lang={DEFAULT_LOCALE}
    >
      {cardBothSidesUrl && (
        <article className={insuranceCardBothSidesClassName}>
          <Image
            width={bothSidesImageWidth}
            height={bothSidesImageHeight}
            src={cardBothSidesUrl}
            data-testid={`${testId}__both`}
            alt=""
          />
        </article>
      )}
      {cardBackUrl && cardFrontUrl && (
        <>
          <article
            className={insuranceCardFrontClassName}
            aria-label={formatMessage({
              defaultMessage: "Insurance card front",
              id: "4cU+Kw",
            })}
          >
            <Image
              width={singleSideImageWidth}
              height={singleSideImageHeight}
              src={cardFrontUrl}
              data-testid={`${testId}__front`}
              alt=""
            />
          </article>
          <article
            className={insuranceCardBackClassName}
            aria-label={formatMessage({
              defaultMessage: "Insurance card back",
              id: "uoRj+F",
            })}
          >
            <Image
              width={singleSideImageWidth}
              height={singleSideImageHeight}
              src={cardBackUrl}
              data-testid={`${testId}__back`}
              alt=""
            />
          </article>
        </>
      )}
    </div>
  );
};
