import { I18nContext, languageToLocaleCodes, LocaleCode } from "@chp/shared";
import { Language } from "@chp/shared/generated/memberPortalApi.graphql";
import { useContext, useEffect } from "react";

import { useAuth } from "../../contexts/AuthContext";

/**
 * When user logs in with a locale code cookie, update user preferred language to match
 */
export const handleLocaleCodeFromLogin = async ({
  localeCodeFromLogin,
  refreshSession,
  setLocaleCodeFromLogin,
  updateActiveUser,
  userPreferredLanguage,
}: {
  localeCodeFromLogin: LocaleCode;
  refreshSession: () => Promise<void>;
  setLocaleCodeFromLogin: (localeCodeFromLogin: LocaleCode | null) => void;
  updateActiveUser: ({ locale }: { locale: LocaleCode }) => Promise<void>;
  userPreferredLanguage: Language;
}): Promise<void> => {
  // clear state whether or not we do an update so we only handle this once per login
  setLocaleCodeFromLogin(null);

  if (localeCodeFromLogin !== languageToLocaleCodes[userPreferredLanguage]) {
    await updateActiveUser({ locale: localeCodeFromLogin });
    await refreshSession();
  }
};

export const useLocaleCodeFromLoginEffect = () => {
  const {
    activeUser,
    localeCodeFromLogin,
    refreshSession,
    setLocaleCodeFromLogin,
  } = useAuth();
  const { updateActiveUser } = useContext(I18nContext);

  useEffect(() => {
    const userPreferredLanguage = activeUser?.preferences?.preferredLanguage;
    if (localeCodeFromLogin && updateActiveUser && userPreferredLanguage) {
      handleLocaleCodeFromLogin({
        localeCodeFromLogin,
        refreshSession,
        setLocaleCodeFromLogin,
        updateActiveUser,
        userPreferredLanguage,
      });
    }
  }, [
    activeUser?.preferences?.preferredLanguage,
    localeCodeFromLogin,
    refreshSession,
    setLocaleCodeFromLogin,
    updateActiveUser,
  ]);
};
