import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import {
  Dispatch,
  Fragment,
  PropsWithChildren,
  SetStateAction,
  useMemo,
} from "react";

import { SpacingVariant } from "../../constants";
import { useArrayKeys } from "../../hooks";
import { generateRandomString } from "../../utils/text";
import {
  Box,
  CheckboxDecorative,
  CUIComponentProps,
  DisplayVariant,
  FormFieldBaseProps,
  formFieldErrorId,
  FormFieldLayout,
  Icon,
  IconVariant,
  TextVariant,
  useHasError,
} from "..";
import { FOCUS_RING_CLASS_NAMES, TEXT_CLASS_NAMES } from "../constants";
import {
  formFieldBorderClassNames,
  FormFieldDescription,
  FormFieldErrorMessage,
  FormFieldLabelText,
  formFieldTextVariant,
  formFieldWrapperMargin,
} from "../forms";
import {
  TooltipPlacement,
  TooltipSize,
  TooltipVariant,
  TooltipWrapper,
} from "../Tooltip";

export enum SelectVariant {
  DEFAULT = "DEFAULT",
  SIMPLE = "SIMPLE",
}

export type SelectOption<T> = T & { isDisabled?: boolean };

type ClearableSingleSelectProps<T> = {
  isClearable: true;
  value: SelectOption<T> | null;
  isMulti?: false;
  onChangeSelection: (
    option: SelectOption<T> | null
  ) => void | Dispatch<SetStateAction<T>>;
};

type ClearableMultiSelectProps<T> = {
  isClearable: true;
  value: SelectOption<T>[] | null;
  isMulti: true;
  onChangeSelection: (
    option: SelectOption<T>[] | null
  ) => void | Dispatch<SetStateAction<SelectOption<T>[]>>;
};

type NonClearableSingleSelectProps<T> = {
  isClearable?: false;
  value: SelectOption<T> | null;
  isMulti?: false;
  onChangeSelection: (
    option: SelectOption<T>
  ) => void | Dispatch<SetStateAction<T>>;
};

type NonClearableMultiSelectProps<T> = {
  isClearable?: false;
  value: SelectOption<T>[] | null;
  isMulti: true;
  onChangeSelection: (
    option: SelectOption<T>[]
  ) => void | Dispatch<SetStateAction<T[]>>;
};

type DefaultSelectProps<T> = {
  variant?: SelectVariant;
  placeholder?: string;
  tooltipText?: string;
  tooltipPlacement?: TooltipPlacement;
} & (
  | ClearableSingleSelectProps<T>
  | ClearableMultiSelectProps<T>
  | NonClearableSingleSelectProps<T>
  | NonClearableMultiSelectProps<T>
);

export enum SelectButtonSize {
  LARGE = "LARGE",
  SMALL = "SMALL",
}

const buttonContainerClassName: Record<SelectButtonSize, string> = {
  [SelectButtonSize.LARGE]: "w-60",
  [SelectButtonSize.SMALL]: "w-28",
};

export type SelectProps<T> = CUIComponentProps<
  FormFieldBaseProps & {
    // TODO (Wyvern): remove these two when we're using the new results page
    boxClassName?: string;
    labelClassName?: string;
    buttonSize?: SelectButtonSize;
    /** headless will not let us set our own id on the component, with a future update they might we can pass it then */
    id?: string;
    isDisabled?: boolean;
    iconSize?: SpacingVariant;
    isMulti?: boolean;
    isLabelSrOnly?: boolean;
    options: SelectOption<T>[];
    /**  what to use as the display value in the dropdown */
    optionLabelExtractor: (item: T) => string;
    /**  what to use as the key for this item */
    optionKeyExtractor: (item: T, index: number) => string | number;
    shouldUseDisplayValue?: boolean;
    useLargeTextForOptions?: boolean;
    textVariant?: TextVariant;
    noMargin?: boolean;
    initialYScrollPositionPercentage?: number;
  } & DefaultSelectProps<T>
>;

export const Select = <T,>(
  props: CUIComponentProps<SelectProps<T>>
): JSX.Element | null => {
  const {
    boxClassName,
    labelClassName,
    buttonSize,
    errorMessage,
    id: _id,
    isClearable,
    isDisabled,
    isLabelSrOnly = false,
    onChangeSelection,
    optionLabelExtractor,
    placeholder,
    shouldUseDisplayValue = false,
    testId = "Select",
    value,
    layout = FormFieldLayout.VERTICAL,
    variant = SelectVariant.DEFAULT,
    iconSize,
    isMulti = false,
    textVariant,
    tooltipText,
    tooltipPlacement = TooltipPlacement.AUTO,
    noMargin = false,
    initialYScrollPositionPercentage,
  } = props;

  const id = useMemo(() => _id || generateRandomString(), [_id]);
  const hasError = useHasError({ errorMessage });
  const errorId = formFieldErrorId({ hasError, id });

  const displayValue = useMemo(() => {
    if (isMulti && Array.isArray(value)) {
      return value?.length !== 0
        ? Array.from(value).reduce((acc: string, name: T, idx: number) => {
            return (
              acc +
              optionLabelExtractor(name) +
              (idx !== value.length - 1 ? ", " : "")
            );
          }, "")
        : placeholder;
    }
    return value !== null && !Array.isArray(value)
      ? optionLabelExtractor(value)
      : placeholder;
  }, [isMulti, optionLabelExtractor, placeholder, value]);

  const simpleSelectPlaceholder = useMemo(() => {
    let text = placeholder;
    if (isMulti && Array.isArray(value) && value.length > 0) {
      text += `( ${value.length} )`;
    } else if (!isMulti && shouldUseDisplayValue && displayValue) {
      text = displayValue;
    } else if (value) {
      text += " ( 1 )";
    }

    return text;
  }, [placeholder, isMulti, value, displayValue, shouldUseDisplayValue]);

  return variant === SelectVariant.SIMPLE ? (
    <SelectContainer {...props}>
      <Listbox
        disabled={isDisabled}
        value={isMulti ? value || [] : value}
        onChange={onChangeSelection}
        multiple={isMulti}
      >
        {({ open }) => (
          <>
            <TooltipWrapper
              message={tooltipText}
              size={TooltipSize.Medium}
              variant={TooltipVariant.Dark}
              placement={tooltipPlacement}
            >
              <>
                <Label {...props} />
                <div
                  className={clsx(
                    ...FOCUS_RING_CLASS_NAMES,
                    !isDisabled &&
                      clsx(
                        "cursor-default",
                        hasError && "text-textColor-critical"
                      ),
                    isDisabled && "text-textColor-subdued",
                    "relative",
                    "w-fit",
                    "rounded",
                    "flex flex-row",
                    "ml-1",
                    boxClassName
                  )}
                >
                  <Listbox.Button
                    className={clsx(
                      "flex items-center py-1 text-left",
                      isDisabled && "cursor-not-allowed"
                    )}
                    data-testid={`${testId}__Button`}
                  >
                    <Box
                      element="span"
                      textVariant={textVariant || TextVariant.MdBold}
                      className="mr-1"
                      testId={`${testId}__Placeholder`}
                    >
                      {simpleSelectPlaceholder}
                    </Box>
                    <span className="pt-0.5">
                      <DropdownIcon size={iconSize} open={open} />
                    </span>
                  </Listbox.Button>
                  <span className="flex flex-col justify-center">
                    {isClearable && value && !isMulti && !isDisabled && (
                      <button
                        data-testid={`${testId}__ClearButton`}
                        onClick={() => {
                          // @ts-ignore
                          onChangeSelection(null);
                        }}
                      >
                        <Icon
                          variant={IconVariant.X}
                          size={SpacingVariant.S16}
                        />
                      </button>
                    )}
                  </span>
                </div>
              </>
            </TooltipWrapper>

            <Options open={open} {...props} />

            <ValidationError {...props} hasError={hasError} errorId={errorId} />
          </>
        )}
      </Listbox>
    </SelectContainer>
  ) : (
    <SelectContainer {...props}>
      <Listbox
        disabled={isDisabled}
        value={isMulti ? value || [] : value}
        onChange={onChangeSelection}
        multiple={isMulti}
      >
        {({ open }) => {
          const buttonContainer = (
            <Box
              className={clsx(
                "relative",
                buttonSize && buttonContainerClassName[buttonSize]
              )}
              element="div"
              margin={
                noMargin
                  ? {}
                  : formFieldWrapperMargin({ isLabelSrOnly, layout })
              }
            >
              <div
                className={clsx(
                  ...FOCUS_RING_CLASS_NAMES,
                  ...formFieldBorderClassNames({ hasError, isDisabled }),
                  !isDisabled &&
                    clsx(
                      "cursor-default",
                      !hasError ? "bg-surface-default" : "bg-surface-critical"
                    ),

                  isDisabled && "bg-surface-disabled",

                  "relative",
                  "rounded",
                  "flex flex-row",
                  clsx(boxClassName)
                )}
              >
                <Listbox.Button
                  className={clsx(
                    "py-2 pl-4 pr-10",
                    "text-left",
                    TEXT_CLASS_NAMES[TextVariant.LgRegularTall],
                    // use placeholder text color when value is empty
                    value ? "" : "text-textColor-subdued",
                    "w-full",
                    isDisabled && "cursor-not-allowed"
                  )}
                  aria-describedby={errorId}
                  aria-invalid={hasError}
                  data-testid={`${testId}__Button`}
                  onClick={() => {
                    setTimeout(function () {
                      if (initialYScrollPositionPercentage && !value) {
                        const listbox = document
                          .getElementById(id)
                          ?.querySelector('[role="listbox"]');
                        if (listbox) {
                          const scrollPos =
                            listbox.scrollHeight *
                            (initialYScrollPositionPercentage / 100);

                          listbox?.scroll({
                            top: scrollPos,
                            left: 0,
                          });
                        }
                      }
                    }, 100);
                  }}
                >
                  <span
                    className={clsx("block truncate", labelClassName)}
                    data-testid={`${testId}__Value`}
                  >
                    {displayValue}
                  </span>
                  <span
                    className={clsx(
                      "pointer-events-none absolute inset-y-0 right-0 flex items-center",
                      isClearable && value ? "pr-8" : "pr-2"
                    )}
                  >
                    <DropdownIcon size={iconSize} open={open} />
                  </span>
                </Listbox.Button>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                  {isClearable && value && (
                    <button
                      data-testid={`${testId}__ClearButton`}
                      onClick={() => {
                        // @ts-ignore
                        onChangeSelection(null);
                      }}
                    >
                      <Icon variant={IconVariant.X} size={SpacingVariant.S16} />
                    </button>
                  )}
                </span>
              </div>
              <Options open={open} {...props} />
              <ValidationError
                {...props}
                hasError={hasError}
                errorId={errorId}
              />
            </Box>
          );
          return layout === FormFieldLayout.HORIZONTAL ? (
            <Box
              className="items-center"
              display={DisplayVariant.FLEX}
              element="div"
              testId={`${testId}__LayoutHorizontal`}
            >
              <Box
                element="div"
                margin={{ right: SpacingVariant.S8 }}
                textVariant={TextVariant.LgRegular}
              >
                <Label {...props} />
              </Box>
              {buttonContainer}
            </Box>
          ) : (
            <>
              <Label {...props} />
              {buttonContainer}
            </>
          );
        }}
      </Listbox>
    </SelectContainer>
  );
};

const SelectContainer = <T,>(
  props: CUIComponentProps<SelectProps<T>> & PropsWithChildren
) => {
  const { children, className, id, testId } = props;
  return (
    <Box
      className={className}
      element="div"
      id={id}
      testId={testId}
      textVariant={formFieldTextVariant}
    >
      {children}
    </Box>
  );
};

const Label = <T,>(props: CUIComponentProps<SelectProps<T>>) => {
  const { isLabelSrOnly, label, description } = props;

  return (
    <Listbox.Label className="block">
      <span data-testid="Select__Label">
        <FormFieldLabelText isLabelSrOnly={isLabelSrOnly} label={label} />
        <FormFieldDescription
          className={clsx(isLabelSrOnly && "sr-only")}
          description={description}
        />
      </span>
    </Listbox.Label>
  );
};

const DropdownIcon = ({
  open,
  size,
}: {
  open: boolean;
  size?: SpacingVariant;
}) =>
  open ? (
    <Icon variant={IconVariant.CHEVRON_UP} size={size ?? SpacingVariant.S16} />
  ) : (
    <Icon
      variant={IconVariant.CHEVRON_DOWN}
      size={size ?? SpacingVariant.S16}
    />
  );

const Options = <T,>(
  props: { open: boolean } & CUIComponentProps<SelectProps<T>>
) => {
  const {
    isMulti,
    open,
    variant = SelectVariant.DEFAULT,
    useLargeTextForOptions = true,
    options,
    testId = "Select",
    optionKeyExtractor,
    optionLabelExtractor,
  } = props;
  const optionKeys = useArrayKeys({
    items: options,
    keyGenerator: optionKeyExtractor,
  });

  return (
    <Transition
      show={open}
      as={Fragment}
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Listbox.Options
        className={clsx(
          "border border-borderColor-control",
          "absolute z-40 mt-1 py-1",
          variant === SelectVariant.DEFAULT && "w-full",
          "max-h-60",
          "bg-surface-default shadow-lg",
          "rounded",
          "text-textColor-default",
          "ring-1 ring-foundation-onSurface/5",
          "overflow-auto",
          "focus:outline-none",
          "text-sm",
          useLargeTextForOptions ? "md:text-lg" : "md:text-md-tall"
        )}
        data-testid={`${testId}__Results`}
      >
        {options.map((option, index) => (
          <Listbox.Option
            key={optionKeys[index]}
            value={option}
            disabled={option.isDisabled}
          >
            {({ active, selected, disabled }) => (
              <div
                className={clsx(
                  active && "bg-surface-highlight",
                  selected && "font-semibold text-textColor-highlight-default",
                  "relative cursor-default select-none py-2 pl-3 pr-9",
                  isMulti && "flex items-center",
                  disabled && "cursor-not-allowed"
                )}
              >
                {isMulti && (
                  <CheckboxDecorative
                    className="mr-2"
                    isChecked={selected}
                    testId={`${testId}__Option-Checkbox`}
                  />
                )}

                <span
                  data-testid={`${testId}__Option`}
                  className={clsx("truncate", disabled && "text-gray-400")}
                >
                  {optionLabelExtractor(option)}
                </span>
              </div>
            )}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Transition>
  );
};

const ValidationError = <T,>(
  props: { hasError: boolean; errorId?: string } & CUIComponentProps<
    SelectProps<T>
  >
) => {
  const {
    isDisabled,
    hasError,
    errorId,
    errorMessage,
    testId = "Select",
  } = props;

  return !isDisabled && hasError && errorId ? (
    <div className={clsx("my-2")}>
      <FormFieldErrorMessage
        errorMessage={errorMessage}
        testId={`${testId}__ErrorMessage`}
        id={errorId}
      />
    </div>
  ) : null;
};
