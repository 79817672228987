import { SelectOption } from "@chp/curative_ui";
import {
  FormattedSelect,
  FormattedSelectProps,
} from "@chp/shared/components/FormattedSelect";
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";

export interface FormSelectOption<T = string, U = string> {
  key: T;
  label: U;
}

type FormSingleSelectProps<S> = {
  isMulti?: false;
  onValueChanged?: (option: S | undefined) => void;
};

type FormMultiSelectProps<S> = {
  isMulti: true;
  onValueChanged?: (option: S[]) => void;
};

type BaseFormSelectProps<T extends FieldValues, S> = {
  name: Path<T>;
  id?: string;
  label: string;
  className?: string;
  optionKeyExtractor: (option: S, index: number) => string | number;
  optionLabelExtractor: (option: S) => string;
  options: SelectOption<S>[];
} & Partial<
  Pick<
    FormattedSelectProps<S>,
    "description" | "placeholder" | "isDisabled" | "isLabelSrOnly" | "testId"
  >
> &
  (
    | {
        optionStringValueExtractor: (option: S) => string;
        optionNumberValueExtractor?: undefined;
      }
    | {
        optionStringValueExtractor?: undefined;
        optionNumberValueExtractor: (option: S) => number;
      }
  );

export type FormSelectProps<T extends FieldValues, S> = BaseFormSelectProps<
  T,
  S
> &
  (FormSingleSelectProps<S> | FormMultiSelectProps<S>);

export function FormSelect<T extends FieldValues, S>({
  name,
  id,
  onValueChanged,
  options,
  optionStringValueExtractor,
  optionNumberValueExtractor,
  isMulti = false,
  ...rest
}: FormSelectProps<T, S>) {
  const { control, trigger } = useFormContext<T>();
  const valueExtractor =
    optionStringValueExtractor ?? optionNumberValueExtractor;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormattedSelect<S>
          id={id || name}
          options={options}
          errorMessage={error?.message}
          {...(isMulti
            ? {
                isMulti: true,
                value:
                  value === undefined
                    ? []
                    : options.filter((option) =>
                        Array.from(value).includes(valueExtractor(option))
                      ),
                onChangeSelection: (newValue) => {
                  onChange({
                    target: {
                      value: newValue.map((v) => valueExtractor(v)),
                    },
                  });
                  (
                    onValueChanged as FormMultiSelectProps<S>["onValueChanged"]
                  )?.(newValue);
                },
              }
            : {
                isMulti: false,
                value:
                  options.find((option) => valueExtractor(option) === value) ||
                  null,
                onChangeSelection: (newValue) => {
                  onChange({
                    target: {
                      value: valueExtractor(newValue),
                    },
                  });
                  (
                    onValueChanged as FormSingleSelectProps<S>["onValueChanged"]
                  )?.(newValue);
                  trigger(name);
                },
              })}
          {...rest}
        />
      )}
    />
  );
}
