import { FOCUS_CLASS_NAMES, SpacingVariant } from "../constants";
import {
  DisplayVariant,
  ModalMaxWidth,
  TextAlignVariant,
  TextVariant,
  VerticalAlignVariant,
} from "./types";

/**
 * See: https://tailwindcss.com/docs/display
 */
export const DISPLAY_CLASS_NAMES: Record<DisplayVariant, string[]> = {
  [DisplayVariant.Block]: [`block`],
  [DisplayVariant.FLEX]: [`flex`],
  [DisplayVariant.Inline]: [`inline`],
  [DisplayVariant.InlineBlock]: [`inline-block`],
};

/** @deprecated See FOCUS_CLASS_NAMES */
export const FOCUS_RING_CLASS_NAMES: string[] = FOCUS_CLASS_NAMES;

/**
 * See: https://tailwindcss.com/docs/height
 */
export const HEIGHT_CLASS_NAMES: Record<SpacingVariant, string[]> = {
  [SpacingVariant.S0]: [`h-0`],
  [SpacingVariant.S1]: [`h-px`],
  [SpacingVariant.S2]: [`h-0.5`],
  [SpacingVariant.S4]: [`h-1`],
  [SpacingVariant.S6]: [`h-1.5`],
  [SpacingVariant.S8]: [`h-2`],
  [SpacingVariant.S12]: [`h-3`],
  [SpacingVariant.S16]: [`h-4`],
  [SpacingVariant.S20]: [`h-5`],
  [SpacingVariant.S24]: [`h-6`],
  [SpacingVariant.S32]: [`h-8`],
  [SpacingVariant.S40]: [`h-10`],
  [SpacingVariant.S48]: [`h-12`],
  [SpacingVariant.S64]: [`h-16`],
  [SpacingVariant.S80]: [`h-20`],
  [SpacingVariant.S160]: [`h-40`],
  [SpacingVariant.S240]: [`h-60`],
  [SpacingVariant.S320]: [`h-80`],
};

/**
 * See: https://tailwindcss.com/docs/margin
 */
export const MARGIN_BOTTOM_CLASS_NAMES: Record<SpacingVariant, string[]> = {
  [SpacingVariant.S0]: [`mb-0`],
  [SpacingVariant.S1]: [`mb-px`],
  [SpacingVariant.S2]: [`mb-0.5`],
  [SpacingVariant.S4]: [`mb-1`],
  [SpacingVariant.S6]: [`mb-1.5`],
  [SpacingVariant.S8]: [`mb-2`],
  [SpacingVariant.S12]: [`mb-3`],
  [SpacingVariant.S16]: [`mb-4`],
  [SpacingVariant.S20]: [`mb-5`],
  [SpacingVariant.S24]: [`mb-6`],
  [SpacingVariant.S32]: [`mb-8`],
  [SpacingVariant.S40]: [`mb-10`],
  [SpacingVariant.S48]: [`mb-12`],
  [SpacingVariant.S64]: [`mb-16`],
  [SpacingVariant.S80]: [`mb-20`],
  [SpacingVariant.S160]: [`mb-40`],
  [SpacingVariant.S240]: [`mb-60`],
  [SpacingVariant.S320]: [`mb-80`],
};

/**
 * See: https://tailwindcss.com/docs/margin
 */
export const MARGIN_LEFT_CLASS_NAMES: Record<SpacingVariant, string[]> = {
  [SpacingVariant.S0]: [`ml-0`],
  [SpacingVariant.S1]: [`ml-px`],
  [SpacingVariant.S2]: [`ml-0.5`],
  [SpacingVariant.S4]: [`ml-1`],
  [SpacingVariant.S6]: [`ml-1.5`],
  [SpacingVariant.S8]: [`ml-2`],
  [SpacingVariant.S12]: [`ml-3`],
  [SpacingVariant.S16]: [`ml-4`],
  [SpacingVariant.S20]: [`ml-5`],
  [SpacingVariant.S24]: [`ml-6`],
  [SpacingVariant.S32]: [`ml-8`],
  [SpacingVariant.S40]: [`ml-10`],
  [SpacingVariant.S48]: [`ml-12`],
  [SpacingVariant.S64]: [`ml-16`],
  [SpacingVariant.S80]: [`ml-20`],
  [SpacingVariant.S160]: [`ml-40`],
  [SpacingVariant.S240]: [`ml-60`],
  [SpacingVariant.S320]: [`ml-80`],
};

/**
 * See: https://tailwindcss.com/docs/margin
 */
export const MARGIN_RIGHT_CLASS_NAMES: Record<SpacingVariant, string[]> = {
  [SpacingVariant.S0]: [`mr-0`],
  [SpacingVariant.S1]: [`mr-px`],
  [SpacingVariant.S2]: [`mr-0.5`],
  [SpacingVariant.S4]: [`mr-1`],
  [SpacingVariant.S6]: [`mr-1.5`],
  [SpacingVariant.S8]: [`mr-2`],
  [SpacingVariant.S12]: [`mr-3`],
  [SpacingVariant.S16]: [`mr-4`],
  [SpacingVariant.S20]: [`mr-5`],
  [SpacingVariant.S24]: [`mr-6`],
  [SpacingVariant.S32]: [`mr-8`],
  [SpacingVariant.S40]: [`mr-10`],
  [SpacingVariant.S48]: [`mr-12`],
  [SpacingVariant.S64]: [`mr-16`],
  [SpacingVariant.S80]: [`mr-20`],
  [SpacingVariant.S160]: [`mr-40`],
  [SpacingVariant.S240]: [`mr-60`],
  [SpacingVariant.S320]: [`mr-80`],
};

/**
 * See: https://tailwindcss.com/docs/margin
 */
export const MARGIN_TOP_CLASS_NAMES: Record<SpacingVariant, string[]> = {
  [SpacingVariant.S0]: [`mt-0`],
  [SpacingVariant.S1]: [`mt-px`],
  [SpacingVariant.S2]: [`mt-0.5`],
  [SpacingVariant.S4]: [`mt-1`],
  [SpacingVariant.S6]: [`mt-1.5`],
  [SpacingVariant.S8]: [`mt-2`],
  [SpacingVariant.S12]: [`mt-3`],
  [SpacingVariant.S16]: [`mt-4`],
  [SpacingVariant.S20]: [`mt-5`],
  [SpacingVariant.S24]: [`mt-6`],
  [SpacingVariant.S32]: [`mt-8`],
  [SpacingVariant.S40]: [`mt-10`],
  [SpacingVariant.S48]: [`mt-12`],
  [SpacingVariant.S64]: [`mt-16`],
  [SpacingVariant.S80]: [`mt-20`],
  [SpacingVariant.S160]: [`mt-40`],
  [SpacingVariant.S240]: [`mt-60`],
  [SpacingVariant.S320]: [`mt-80`],
};

/**
 * See: https://tailwindcss.com/docs/padding
 */
export const PADDING_BOTTOM_CLASS_NAMES: Record<SpacingVariant, string[]> = {
  [SpacingVariant.S0]: [`pb-0`],
  [SpacingVariant.S1]: [`pb-px`],
  [SpacingVariant.S2]: [`pb-0.5`],
  [SpacingVariant.S4]: [`pb-1`],
  [SpacingVariant.S6]: [`pb-1.5`],
  [SpacingVariant.S8]: [`pb-2`],
  [SpacingVariant.S12]: [`pb-3`],
  [SpacingVariant.S16]: [`pb-4`],
  [SpacingVariant.S20]: [`pb-5`],
  [SpacingVariant.S24]: [`pb-6`],
  [SpacingVariant.S32]: [`pb-8`],
  [SpacingVariant.S40]: [`pb-10`],
  [SpacingVariant.S48]: [`pb-12`],
  [SpacingVariant.S64]: [`pb-16`],
  [SpacingVariant.S80]: [`pb-20`],
  [SpacingVariant.S160]: [`pb-40`],
  [SpacingVariant.S240]: [`pb-60`],
  [SpacingVariant.S320]: [`pb-80`],
};

/**
 * See: https://tailwindcss.com/docs/padding
 */
export const PADDING_LEFT_CLASS_NAMES: Record<SpacingVariant, string[]> = {
  [SpacingVariant.S0]: [`pl-0`],
  [SpacingVariant.S1]: [`pl-px`],
  [SpacingVariant.S2]: [`pl-0.5`],
  [SpacingVariant.S4]: [`pl-1`],
  [SpacingVariant.S6]: [`pl-1.5`],
  [SpacingVariant.S8]: [`pl-2`],
  [SpacingVariant.S12]: [`pl-3`],
  [SpacingVariant.S16]: [`pl-4`],
  [SpacingVariant.S20]: [`pl-5`],
  [SpacingVariant.S24]: [`pl-6`],
  [SpacingVariant.S32]: [`pl-8`],
  [SpacingVariant.S40]: [`pl-10`],
  [SpacingVariant.S48]: [`pl-12`],
  [SpacingVariant.S64]: [`pl-16`],
  [SpacingVariant.S80]: [`pl-20`],
  [SpacingVariant.S160]: [`pl-40`],
  [SpacingVariant.S240]: [`pl-60`],
  [SpacingVariant.S320]: [`pl-80`],
};

/**
 * See: https://tailwindcss.com/docs/padding
 */
export const PADDING_RIGHT_CLASS_NAMES: Record<SpacingVariant, string[]> = {
  [SpacingVariant.S0]: [`pr-0`],
  [SpacingVariant.S1]: [`pr-px`],
  [SpacingVariant.S2]: [`pr-0.5`],
  [SpacingVariant.S4]: [`pr-1`],
  [SpacingVariant.S6]: [`pr-1.5`],
  [SpacingVariant.S8]: [`pr-2`],
  [SpacingVariant.S12]: [`pr-3`],
  [SpacingVariant.S16]: [`pr-4`],
  [SpacingVariant.S20]: [`pr-5`],
  [SpacingVariant.S24]: [`pr-6`],
  [SpacingVariant.S32]: [`pr-8`],
  [SpacingVariant.S40]: [`pr-10`],
  [SpacingVariant.S48]: [`pr-12`],
  [SpacingVariant.S64]: [`pr-16`],
  [SpacingVariant.S80]: [`pr-20`],
  [SpacingVariant.S160]: [`pr-40`],
  [SpacingVariant.S240]: [`pr-60`],
  [SpacingVariant.S320]: [`pr-80`],
};

/**
 * See: https://tailwindcss.com/docs/padding
 */
export const PADDING_TOP_CLASS_NAMES: Record<SpacingVariant, string[]> = {
  [SpacingVariant.S0]: [`pt-0`],
  [SpacingVariant.S1]: [`pt-px`],
  [SpacingVariant.S2]: [`pt-0.5`],
  [SpacingVariant.S4]: [`pt-1`],
  [SpacingVariant.S6]: [`pt-1.5`],
  [SpacingVariant.S8]: [`pt-2`],
  [SpacingVariant.S12]: [`pt-3`],
  [SpacingVariant.S16]: [`pt-4`],
  [SpacingVariant.S20]: [`pt-5`],
  [SpacingVariant.S24]: [`pt-6`],
  [SpacingVariant.S32]: [`pt-8`],
  [SpacingVariant.S40]: [`pt-10`],
  [SpacingVariant.S48]: [`pt-12`],
  [SpacingVariant.S64]: [`pt-16`],
  [SpacingVariant.S80]: [`pt-20`],
  [SpacingVariant.S160]: [`pt-40`],
  [SpacingVariant.S240]: [`pt-60`],
  [SpacingVariant.S320]: [`pt-80`],
};

/**
 * See: https://tailwindcss.com/docs/font-size
 * See: https://tailwindcss.com/docs/font-weight
 */
export const TEXT_CLASS_NAMES: Record<TextVariant, string[]> = {
  [TextVariant.XsRegular]: [`font-normal`, `text-xs`],
  [TextVariant.SmRegularTall]: [`font-normal`, `text-sm-tall`],
  [TextVariant.MdRegularTall]: [`font-normal`, `text-md-tall`],
  [TextVariant.LgRegular]: [`font-normal`, `text-lg`],
  [TextVariant.LgRegularTall]: [`font-normal`, `text-lg-tall`],
  [TextVariant.XlRegular]: [`font-normal`, `text-xl`],
  [TextVariant.XlRegularTall]: [`font-normal`, `text-xl-tall`],
  [TextVariant["2XlRegular"]]: [`font-normal`, `text-2xl`],
  [TextVariant["3XlRegular"]]: [`font-normal`, `text-3xl`],
  [TextVariant["4XlRegular"]]: [`font-normal`, `text-4xl`],
  [TextVariant.XsBold]: [`font-semibold`, `text-xs`],
  [TextVariant.SmBold]: [`font-semibold`, `text-sm`],
  [TextVariant.SmBoldTall]: [`font-semibold`, `text-sm-tall`],
  [TextVariant.MdBold]: [`font-semibold`, `text-md`],
  [TextVariant.LgBoldShort]: [`font-semibold`, `text-lg-short`],
  [TextVariant.XlBold]: [`font-semibold`, `text-xl`],
  [TextVariant.XlBoldTall]: [`font-semibold`, `text-xl-tall`],
  [TextVariant["2XlBold"]]: [`font-semibold`, `text-2xl`],
  [TextVariant["3XlBold"]]: [`font-semibold`, `text-3xl`],
  [TextVariant["4XlBold"]]: [`font-semibold`, `text-4xl`],
};

/**
 * See: https://tailwindcss.com/docs/text-align
 */
export const TEXT_ALIGN_CLASS_NAMES: Record<TextAlignVariant, string[]> = {
  [TextAlignVariant.CENTER]: [`text-center`],
  [TextAlignVariant.LEFT]: [`text-left`],
  [TextAlignVariant.RIGHT]: [`text-right`],
};

/**
 * See: https://tailwindcss.com/docs/vertical-align
 */
export const VERTICAL_ALIGN_CLASS_NAMES: Record<
  VerticalAlignVariant,
  string[]
> = {
  [VerticalAlignVariant.Bottom]: [`align-bottom`],
  [VerticalAlignVariant.Middle]: [`align-middle`],
  [VerticalAlignVariant.Top]: [`align-top`],
};

/**
 * See: https://tailwindcss.com/docs/width
 */
export const WIDTH_CLASS_NAMES: Record<SpacingVariant, string[]> = {
  [SpacingVariant.S0]: [`w-0`],
  [SpacingVariant.S1]: [`w-px`],
  [SpacingVariant.S2]: [`w-0.5`],
  [SpacingVariant.S4]: [`w-1`],
  [SpacingVariant.S6]: [`w-1.5`],
  [SpacingVariant.S8]: [`w-2`],
  [SpacingVariant.S12]: [`w-3`],
  [SpacingVariant.S16]: [`w-4`],
  [SpacingVariant.S20]: [`w-5`],
  [SpacingVariant.S24]: [`w-6`],
  [SpacingVariant.S32]: [`w-8`],
  [SpacingVariant.S40]: [`w-10`],
  [SpacingVariant.S48]: [`w-12`],
  [SpacingVariant.S64]: [`w-16`],
  [SpacingVariant.S80]: [`w-20`],
  [SpacingVariant.S160]: [`w-40`],
  [SpacingVariant.S240]: [`w-60`],
  [SpacingVariant.S320]: [`w-80`],
};

/**
 * See: https://tailwindcss.com/docs/max-width
 */
export const MODAL_VARIANT_MAX_WIDTHS: Record<ModalMaxWidth, string[]> = {
  [ModalMaxWidth.Small]: [`max-w-sm`],
  [ModalMaxWidth.Medium]: [`max-w-md`],
  [ModalMaxWidth.Large]: [`max-w-lg`],
  [ModalMaxWidth.ExtraLarge]: [`max-w-xl`],
  [ModalMaxWidth.TwoExtraLarge]: [`max-w-2xl`],
  [ModalMaxWidth.MaxContent]: [`max-w-max`],
};
