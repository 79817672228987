import { CursorPagination, CursorPaginationProps } from "@chp/curative_ui";
import { FC } from "react";
import { useIntl } from "react-intl";

export type FormattedCursorPaginationProps = Omit<
  CursorPaginationProps,
  "nextText" | "previousText" | "firstPageText"
>;

export const FormattedCursorPagination: FC<FormattedCursorPaginationProps> = (
  props
) => {
  const { formatMessage } = useIntl();
  return (
    <CursorPagination
      nextText={formatMessage({ defaultMessage: "Next", id: "9+Ddtu" })}
      previousText={formatMessage({ defaultMessage: "Previous", id: "JJNc3c" })}
      firstPageText={formatMessage({
        defaultMessage: "Back to first page",
        id: "POMMTU",
      })}
      {...props}
    />
  );
};
