import { DEFAULT_LOCALE_CODE, LocaleCode } from "../../features";

export const findSupportedLocaleCode = ({
  localeCodes,
  supportedLocales = [DEFAULT_LOCALE_CODE],
}: {
  localeCodes?: LocaleCode[];
  supportedLocales?: LocaleCode[];
}): LocaleCode => {
  if (localeCodes === undefined) return DEFAULT_LOCALE_CODE;
  return (
    localeCodes.find((localeCode) => supportedLocales.includes(localeCode)) ??
    DEFAULT_LOCALE_CODE
  );
};
