import { IntlShape } from "react-intl";

import { CoverageTier } from "../../generated/employerPortalApi.graphql";

export const coverageTierDisplayNames = ({
  formatMessage,
}: {
  formatMessage: IntlShape["formatMessage"];
}): Record<CoverageTier, string> => ({
  [CoverageTier.E1]: formatMessage({
    defaultMessage: "Employee and 1 Child",
    id: "XCsH14",
  }),
  [CoverageTier.E2]: formatMessage({
    defaultMessage: "Employee and 2 Children",
    id: "MFHdkb",
  }),
  [CoverageTier.E3]: formatMessage({
    defaultMessage: "Employee and 3+ Children",
    id: "0aCzVo",
  }),
  [CoverageTier.Ec]: formatMessage({
    defaultMessage: "Employee + Children",
    id: "87kQKX",
  }),
  [CoverageTier.Ee]: formatMessage({
    defaultMessage: "Employee Only",
    id: "jWtVn2",
  }),
  [CoverageTier.Ef]: formatMessage({
    defaultMessage: "Employee + Family",
    id: "45ejUI",
  }),
  [CoverageTier.Es]: formatMessage({
    defaultMessage: "Employee + Spouse",
    id: "/Bzf5s",
  }),
  [CoverageTier.F1]: formatMessage({
    defaultMessage: "Employee, Spouse, and 1 Child",
    id: "T6uPAi",
  }),
  [CoverageTier.F2]: formatMessage({
    defaultMessage: "Employee, Spouse, and 2 Children",
    id: "85E68A",
  }),
  [CoverageTier.F3]: formatMessage({
    defaultMessage: "Employee, Spouse, and 3+ Children",
    id: "oKNDae",
  }),
});
