import { MaskedInput, MaskedInputProps } from "@chp/curative_ui";

import { useErrorMessageWithPrefix } from "../../hooks";

export type FormattedMaskedInputProps = MaskedInputProps;

export const FormattedMaskedInput = ({
  errorMessage,
  ...rest
}: FormattedMaskedInputProps): JSX.Element => {
  const errorWithPrefix = useErrorMessageWithPrefix({ errorMessage });
  return <MaskedInput errorMessage={errorWithPrefix} {...rest} />;
};
