export enum ToastAlertAlignment {
  LEFT = "LEFT",
  MIDDLE = "MIDDLE",
  RIGHT = "RIGHT",
}

export enum ToastAlertPosition {
  ABSOLUTE = "ABSOLUTE",
  FIXED = "FIXED",
  STICKY = "STICKY",
}
