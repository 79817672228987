import { IconVariant, TextField, TextFieldProps } from "@chp/curative_ui";
import { useIntl } from "react-intl";

import { useErrorMessageWithPrefix } from "../../hooks";

export type FormattedTextFieldProps = TextFieldProps & {
  onClickClearButton?: () => void;
};

export const FormattedTextField = ({
  errorMessage,
  onClickClearButton,
  ...rest
}: FormattedTextFieldProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const errorWithPrefix = useErrorMessageWithPrefix({ errorMessage });
  return (
    <TextField
      errorMessage={errorWithPrefix}
      {...(onClickClearButton
        ? {
            buttonCaption: formatMessage({
              defaultMessage: "Clear",
              id: "/GCoTA",
            }),
            buttonIcon: IconVariant.X,
            buttonType: "button",
            onClickButton: onClickClearButton,
          }
        : {})}
      {...rest}
    />
  );
};
