import { Icon, IconVariant, SpacingVariant } from "@chp/curative_ui";
import {
  StripeElementBase,
  StripeIssuingCardCopyButtonElement,
} from "@stripe/stripe-js";
import clsx from "clsx";
import React, { ReactNode, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { CardElements } from "./MemberPaymentCardsContext";

const COPY_CHECKMARK_DISPLAY_TIME = 2000;

function CopyButtonWrapper({
  idPrefix,
  element,
}: {
  idPrefix: string;
  element: StripeIssuingCardCopyButtonElement | null | undefined;
}) {
  const [copied, setCopied] = useState(false);

  element?.on("click", () => {
    setCopied(true);
    setTimeout(() => setCopied(false), COPY_CHECKMARK_DISPLAY_TIME);
  });

  const containerId = `${idPrefix}_copy-button`;

  useEffect(() => element?.mount(`#${containerId}`), [containerId, element]);

  return (
    <span className="relative inline-block size-6">
      <div className="size-6" id={containerId} />
      <div className="pointer-events-none absolute top-0 m-0 size-full p-0 text-black">
        {copied ? (
          <Icon
            size={SpacingVariant.S24}
            variant={IconVariant.CHECK_CIRCLE}
            className="text-foundation-primary"
          />
        ) : (
          <Icon
            size={SpacingVariant.S24}
            variant={IconVariant.CLIPBOARD_DOCUMENT}
          />
        )}
      </div>
    </span>
  );
}

const MountedField = ({
  fieldName,
  element,
  classes,
  idPrefix,
  copyButton = null,
}: {
  fieldName: string;
  element: StripeElementBase | null | undefined;
  classes: string;
  idPrefix: string;
  copyButton?: ReactNode | null | undefined;
}) => {
  const containerId = `${idPrefix}_${fieldName}`;

  useEffect(() => element?.mount(`#${containerId}`), [containerId, element]);

  return (
    <>
      <div
        className={clsx(classes, "pointer-events-none inline-block h-6")}
        id={containerId}
      />
      {copyButton}
    </>
  );
};

const FieldRow = ({
  children,
  fieldName: name,
}: {
  children: ReactNode;
  fieldName: string;
}) => {
  return (
    <div
      className="my-2 flex flex-row justify-between border-b-2 border-gray-300 py-2"
      key={name}
    >
      <dt>{name}</dt>
      <dd className="font-ibm-plex">{children}</dd>
    </div>
  );
};

export default function CardDetails({
  cardHolderName,
  cardId,
  elements,
  physical,
}: {
  cardHolderName: string;
  cardId: string;
  elements: CardElements | null;
  physical?: boolean;
}) {
  const { formatMessage } = useIntl();

  return (
    <dl className="py-4">
      <FieldRow
        fieldName={formatMessage({ defaultMessage: "Name", id: "HAlOn1" })}
      >
        {cardHolderName}
      </FieldRow>

      {!physical ? (
        <>
          <FieldRow
            fieldName={formatMessage({
              defaultMessage: "Card Number",
              id: "WTocjo",
            })}
          >
            <MountedField
              fieldName="card-number"
              element={elements?.number}
              classes="w-[195px]"
              idPrefix={cardId}
              copyButton={
                <CopyButtonWrapper
                  idPrefix={`${cardId}-number`}
                  element={elements?.copyButton}
                />
              }
            />
          </FieldRow>

          <FieldRow
            fieldName={formatMessage({
              defaultMessage: "Expiration",
              id: "YjvJn3",
            })}
          >
            <MountedField
              fieldName="expiry"
              element={elements?.expiry}
              classes="w-[50px]"
              idPrefix={cardId}
            />
          </FieldRow>

          <FieldRow
            fieldName={formatMessage({
              defaultMessage: "Security Code",
              id: "f0EhUc",
            })}
          >
            <MountedField
              fieldName="cvc"
              element={elements?.cvc}
              classes="w-[35px]"
              idPrefix={cardId}
            />
          </FieldRow>

          <FieldRow
            fieldName={formatMessage({
              defaultMessage: "Network",
              id: "I3JhPS",
            })}
          >
            {formatMessage({ defaultMessage: "Visa", id: "GSvRmU" })}
          </FieldRow>
        </>
      ) : (
        <FieldRow
          fieldName={formatMessage({
            defaultMessage: "Card Number",
            id: "WTocjo",
          })}
        >
          {formatMessage({
            defaultMessage: "Physical card number not available online",
            id: "2UK5u5",
          })}
        </FieldRow>
      )}
    </dl>
  );
}
