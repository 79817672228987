import {
  FormDateInput,
  FormDateInputProps,
} from "@chp/shared/components/form/FormDateInput";
import { FieldValues } from "react-hook-form";
import { useIntl } from "react-intl";

import { dateOfBirthMax, dateOfBirthMin } from "../../../../utils";
import { DOB_FIELD_TEST_ID } from "../../constants";

type DOBInputOptionalProps<T extends FieldValues = FieldValues> = Partial<
  Pick<
    FormDateInputProps<T>,
    | "id"
    | "testId"
    | "isDisabled"
    | "label"
    | "fromDate"
    | "toDate"
    | "dialogTitle"
    | "openCalendarButtonAriaLabel"
    | "onValueChanged"
  >
>;

type DOBInputRequiredProps<T extends FieldValues = FieldValues> = Required<
  Pick<FormDateInputProps<T>, "name">
>;

export type DOBInputProps<T extends FieldValues = FieldValues> =
  DOBInputOptionalProps<T> & DOBInputRequiredProps<T>;

export const DOBInput = <T extends FieldValues = FieldValues>({
  // TODO: support PlainDate
  fromDate = dateOfBirthMin.toMMDDYYYYFormat(),
  name,
  id = name,
  testId = DOB_FIELD_TEST_ID,
  isDisabled,
  label,
  dialogTitle,
  openCalendarButtonAriaLabel,
  onValueChanged,
  // TODO: support PlainDate
  toDate = dateOfBirthMax().toMMDDYYYYFormat(),
}: DOBInputProps<T>) => {
  const { formatMessage } = useIntl();

  return (
    <FormDateInput<T>
      name={name}
      id={id}
      testId={testId}
      isDisabled={isDisabled}
      label={
        label ||
        formatMessage({ defaultMessage: "Date of birth", id: "GFTdXw" })
      }
      fromDate={fromDate}
      toDate={toDate}
      dialogTitle={
        dialogTitle ||
        formatMessage({ defaultMessage: "Select a date", id: "7qOQpv" })
      }
      openCalendarButtonAriaLabel={
        openCalendarButtonAriaLabel ||
        formatMessage({
          defaultMessage: "Open date selection helper",
          id: "+uuUin",
        })
      }
      onValueChanged={onValueChanged}
    />
  );
};
