import clsx from "clsx";
import { FC } from "react";

import {
  OrangeArrowIcon,
  OrangeArrowIconOpacity,
  OrangeArrowSize,
} from "./OrangeArrow";

export type SixOrangeArrowsProps = {
  size?: OrangeArrowSize;
  className?: string;
};

const middleRowSizeToClass = {
  [OrangeArrowSize.XSMALL]: "top-[1.1rem]",
  [OrangeArrowSize.MEDIUM]: "top-[2.25rem]",
  [OrangeArrowSize.LARGE]: "top-[3.3rem]",
};

const bottomRowSizeToClass = {
  [OrangeArrowSize.XSMALL]: "top-[2.2rem]",
  [OrangeArrowSize.MEDIUM]: "top-[4.5rem]",
  [OrangeArrowSize.LARGE]: "top-[6.6rem]",
};

export const SixOrangeArrows: FC<SixOrangeArrowsProps> = ({
  size = OrangeArrowSize.MEDIUM,
  className,
}) => {
  return (
    <div className={clsx("absolute w-full", className)}>
      <div className="absolute right-0 flex">
        <OrangeArrowIcon
          opacity={OrangeArrowIconOpacity.SIX}
          className="mr-[.15rem]"
          size={size}
        />
        <OrangeArrowIcon
          opacity={OrangeArrowIconOpacity.NINE}
          className="mr-[.15rem]"
          size={size}
        />
        <OrangeArrowIcon opacity={OrangeArrowIconOpacity.TWELVE} size={size} />
      </div>
      <div
        className={clsx("absolute right-0 flex", middleRowSizeToClass[size])}
      >
        <OrangeArrowIcon
          opacity={OrangeArrowIconOpacity.SIX}
          className="mr-[.15rem]"
          size={size}
        />
        <OrangeArrowIcon opacity={OrangeArrowIconOpacity.NINE} size={size} />
      </div>
      <div
        className={clsx("absolute right-0 flex", bottomRowSizeToClass[size])}
      >
        <OrangeArrowIcon opacity={OrangeArrowIconOpacity.SIX} size={size} />
      </div>
    </div>
  );
};
