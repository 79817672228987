import {
  CostProviderAddress,
  ProviderFlat,
} from "@chp/shared/generated/memberPortalApi.graphql";
import { format, parse } from "date-fns";
import { compact } from "lodash-es";
import { useContext } from "react";

import { I18nContext, LocaleCode } from "../../features";

type StreetAddress = {
  street?: string | null;
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
};

export const formatStreetAddress = (address: StreetAddress): string => {
  let result = compact([
    address.street || address.street1,
    address.street2,
    toTitleCase(address.city),
    address.state && address.state.toUpperCase(),
  ]).join(", ");

  if (address.zipCode) result += ` ${address.zipCode}`;

  return result;
};

export const toTitleCase = (s: string | null | undefined) => {
  /*
    This function converts a string to title case.
    e.g. IN_PROGRESS -> In Progress

    The eslint-disable-next-line is needed because of the regex used.
    */
  if (!s) {
    return "";
  }

  return (
    s
      .replace(/([^A-Z\\/\\(\\)])([A-Z])/g, "$1 $2") // split cameCase
      // eslint-disable-next-line
      .replace(/_+/g, " ") // split snake_case using _ as a separator
      .toLowerCase()
      .replace(/(^\w|\b\w)/g, function (m) {
        return m.toUpperCase();
      }) // title case words
      .replace(/\s+/g, " ") // collapse repeated whitespace
      .replace(/^\s+|\s+$/, "")
  ); // remove leading/trailing whitespace
};

// Format person's name to be in title case, with handling apostrophes in names like O'Brien and
// prefixes like mc, mac
// e.g. o'brien -> O'Brien, mcconnell -> McConnell, macdonald -> MacDonald, de la cruz -> de la Cruz
export const formatPersonName = (name: string) => {
  return name
    .toLowerCase()
    .replace(
      /\b(?!(?:de|la)\b)(ma?c)?([a-z]+)/g,
      (_, prefix, word) =>
        (prefix
          ? prefix[0].toUpperCase() + prefix.slice(1).toLowerCase()
          : "") +
        word[0].toUpperCase() +
        word.slice(1).toLowerCase()
    );
};

export const formatFullNameWithMiddleInitial = ({
  givenName,
  familyName,
  middleName,
}: {
  givenName?: string | null;
  familyName?: string | null;
  middleName?: string | null;
}) => {
  const formattedNames = [givenName, middleName, familyName]
    .map((name, index) => {
      if (index === 1) {
        // If it's the middleName and it's present, include the middle initial
        const middleInitial = name?.charAt(0).toUpperCase() || "";
        return middleInitial !== "" ? `${middleInitial}.` : ""; // Ensure no period for empty middle name
      }
      return name ? formatPersonName(name) : "";
    })
    .filter(Boolean) // Prevent extra space added between empty names
    .join(" ");

  return formattedNames;
};

export const formatRxTransferDetail = (key: string, value: string) => {
  if (key.includes("phone")) {
    return formatPhoneNumber(value);
  }
  return value;
};

export const cleanPhoneNumber = (
  phoneNumberString: string | null | undefined,
  removeIntlCode: boolean = false
) => {
  if (!phoneNumberString) {
    return "";
  }
  // Remove any non-digit characters from it
  let cleanedNumber = ("" + phoneNumberString).replace(/\D/g, "");
  // Assuming the country code is at the beginning and is US/Canada country code
  if (
    removeIntlCode &&
    cleanedNumber.length > 10 &&
    cleanedNumber.startsWith("1")
  ) {
    cleanedNumber = cleanedNumber.substring(1);
  }
  return cleanedNumber;
};

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = cleanPhoneNumber(phoneNumberString);
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

// Will convert enum values such as HOME_HEALTH to user friendly text like Home Health
export const formatEnumValue = (value: string) => {
  return value
    .split("_")
    .map((word) => `${word[0]?.toUpperCase()}${word.slice(1).toLowerCase()}`)
    .join(" ");
};

export const formatCostProviderAddress = (
  address:
    | Pick<
        CostProviderAddress,
        "street1" | "street2" | "state" | "city" | "zipCode"
      >
    | undefined
): string[] => {
  if (!address) {
    return [];
  }

  return compact([
    address.street1,
    address.street2,
    `${address.city} ${address.state} ${address.zipCode}`,
  ]);
};

// Returns a string with non-repeating items in an array separated by commas or null if the array is empty or undefined
export const formatArray = (v?: (string | null | undefined)[] | null) => {
  return v && v.length > 0
    ? v.filter((item, index) => v.indexOf(item) === index && !!item).join(", ")
    : null;
};

export const formatProviderDegreeAbbreviations = (
  provider: ProviderFlat,
  locale: LocaleCode
) => {
  const degreeAbbreviations = provider?.degreesList?.map(
    (d) => d.abbreviation ?? ""
  );
  if (!degreeAbbreviations) return "";

  const formatter = new Intl.ListFormat(locale, {
    style: "narrow",
    type: "conjunction",
  });

  return formatter.format(degreeAbbreviations);
};

export const formatProviderNameWithDegree = (
  provider: ProviderFlat,
  locale: LocaleCode
) => {
  const degreeAbbreviations = formatProviderDegreeAbbreviations(
    provider,
    locale
  );

  return `${formatPersonName(provider.fullName ?? "")}${
    degreeAbbreviations ? `, ${degreeAbbreviations}` : ""
  }`;
};

export const useFormattedProviderNameWithDegree = (provider: ProviderFlat) => {
  const { locale } = useContext(I18nContext);
  return formatProviderNameWithDegree(provider, locale);
};

export const formatProviderDegreeDescription = (provider: ProviderFlat) => {
  return provider.degreesList
    ?.map((degree) =>
      toTitleCase(degree.name).replaceAll(/(Of|And)/g, (match) =>
        match.toLowerCase()
      )
    )
    .join(", ");
};

// Convert array to string with first item and "+x more" if array has more than 1 item
export const formatArrayWithMore = (
  v?: (string | null | undefined)[] | null
) => {
  return v && v?.length > 0
    ? v.length > 1
      ? `${v[0]}, +${v.length - 1} more`
      : v[0]
    : null;
};

/** @deprecated See useFormatNumber */
export const formatNumericValueAsCurrency = (
  value: string | number
): string => {
  const floatValue = Number.parseFloat(String(value));

  if (Number.isNaN(floatValue)) {
    return "$0.00";
  }

  return floatValue.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });
};

export const reformatDate = (
  date: string | null | undefined,
  srcFormat: string = "yyyy-MM-dd",
  dstFormat: string = "MM-dd-yyyy"
): string => {
  try {
    if (!date) {
      return "";
    }
    return format(
      parse(date, srcFormat, new Date(Date.UTC(0, 0, 0))),
      dstFormat
    );
  } catch (e) {
    return date || "";
  }
};

/**
 * Extracts and returns the base domain from a given URL string.
 *
 * This function parses the given URL and returns its hostname, which represents
 * the base domain. If the URL is not valid, the function catches the error, logs
 * it, and returns an empty string to indicate the failure to extract a valid domain.
 *
 * @param {string} url - The URL string from which the base domain is to be extracted.
 * @returns {string} The extracted base domain if the URL is valid, or an empty string if not.
 *
 * @example
 * // Returns "curative.zoom.us"
 * extractBaseDomain('https://curative.zoom.us/j/94107488923');
 */
export const extractBaseDomain = (url: string): string => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname;
  } catch (error) {
    return url;
  }
};
