import clsx from "clsx";

import { SpacingVariant } from "../../constants";
import { ScreenReaderOnly } from "..";
import { Button, ButtonVariant } from "../Button";
import { Icon, IconVariant } from "../Icon";
import { CUIComponent, CUIComponentProps } from "../types";

export enum ActionButtonVariant {
  DEFAULT,
  SUBDUED_UNDERLINED,
}

export type ActionButtonProps = CUIComponentProps<{
  text: string;
  iconVariant: IconVariant;
  isDisabled?: boolean;
  onClick: (event: React.MouseEvent) => void;
  onlyDisplayIcon?: boolean;
  overrideIconSize?: SpacingVariant;
  variant?: ActionButtonVariant;
}>;

export const ActionButton: CUIComponent<ActionButtonProps> = ({
  text,
  iconVariant,
  isDisabled,
  className,
  onlyDisplayIcon,
  onClick,
  overrideIconSize,
  variant = ActionButtonVariant.DEFAULT,
  testId = "ActionButton",
}) => {
  const iconSize =
    overrideIconSize ??
    (onlyDisplayIcon ? SpacingVariant.S24 : SpacingVariant.S20);

  return (
    <Button
      isDisabled={isDisabled}
      testId={testId}
      variant={ButtonVariant.Flat}
      className={clsx("flex", "gap-x-1", "active:bg-gray-100", className)}
      onClick={onClick}
    >
      <Icon
        variant={iconVariant}
        size={iconSize}
        className={!isDisabled ? "text-textColor-default" : undefined}
      />
      {onlyDisplayIcon ? (
        <ScreenReaderOnly element="span">{text}</ScreenReaderOnly>
      ) : (
        <span
          className={clsx(
            variant === ActionButtonVariant.SUBDUED_UNDERLINED &&
              "text-textColor-subdued underline",
            variant !== ActionButtonVariant.SUBDUED_UNDERLINED && !isDisabled
              ? "text-textColor-default"
              : undefined
          )}
        >
          {text}
        </span>
      )}
    </Button>
  );
};
