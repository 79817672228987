import { merge } from "lodash-es";

import { LocaleCode, LocaleCodeToMessages} from "../features/i18n";

const localeCodeToSharedMessages: LocaleCodeToMessages = {
    [LocaleCode.EN]: require("./compiled/en.json"),
    [LocaleCode.ES]: require("./compiled/es.json"),
}

export const mergeLocaleCodeToSharedMessages = (
 localeCodeToMessages: LocaleCodeToMessages
): LocaleCodeToMessages => merge(
  localeCodeToSharedMessages,
  localeCodeToMessages
);
