import { Alert, AlertType, HelpWidget } from "@chp/curative_ui";
// @ts-ignore
import theme from "@chp/curative_ui/src/tokens/colors/theme";
import {
  checkCoverage,
  DegradedPerformanceAlert,
  DownErrorMessageWithLogo,
  FCWithChildren,
  FormattedSideNavbar,
  HEALTH_PLAN_CUSTOMER_SUPPORT_EMAIL_ADDRESS,
  I18nContext,
  LaunchDarklyFlags,
  MEMBER_PORTAL_HOMEPAGE_URL,
  MemberServicesEmailLink,
  MemberServicesPhoneNumberLink,
  NonProdBanner,
  PharmacyPhoneNumberLink,
  PharmacyPhoneNumberLinkVariant,
} from "@chp/shared";
import { Sidetab } from "@typeform/embed-react";
import clsx from "clsx";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useAuth } from "~/contexts/AuthContext";
import { useHelpWidgetLogic } from "~/hooks/useHelpWidgetLogic";
import { useNavItems } from "~/hooks/useNavItems";
import { OUT_OF_POCKET_COSTS_ROUTE } from "~/utils/constants";

import { useLocaleCodeFromLoginEffect } from "../hooks";

const Layout: FCWithChildren = ({ children }) => {
  const { formatMessage } = useIntl();
  const [isMobileNavbarOpen, setIsMobileNavbarOpen] = useState(false);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(false);
  const { activeUser, hasCompletedOnboarding } = useAuth();
  const {
    enableMemberPortalDownScreen,
    enableMemberPortalDegradedPerformanceBanner,
    showNonProductionBanner,
    memberPortalAlert,
    showMemberPortalFeedbackSidetab,
  } = useFlags<LaunchDarklyFlags>();

  const { navItems } = useNavItems(setIsMobileNavbarOpen);
  const { helpWidgetText, onHelpWidgetOpen } = useHelpWidgetLogic(activeUser);

  const hasOnboardedUser = activeUser && hasCompletedOnboarding;

  const { refreshLocale } = useContext(I18nContext);
  const router = useRouter();

  useLocaleCodeFromLoginEffect();

  useEffect(() => {
    refreshLocale(activeUser?.preferences?.preferredLanguage);
  }, [activeUser?.preferences?.preferredLanguage, refreshLocale]);

  const { hasFutureCoverage, hasExpiredCoverage } = checkCoverage(
    activeUser?.member?.displayedEnrollment
  );

  const isOnOutOfPocketCostsPage =
    router.pathname === OUT_OF_POCKET_COSTS_ROUTE;

  if (enableMemberPortalDownScreen) {
    return <DownErrorMessageWithLogo />;
  }

  const nonProdStyles = isNavbarCollapsed ? "md:left-18" : "md:left-72";

  return (
    <div className="flex">
      {hasOnboardedUser && (
        <FormattedSideNavbar
          isNavbarCollapsed={isNavbarCollapsed}
          onRequestCollapseNavbar={setIsNavbarCollapsed}
          isMobileNavbarOpen={isMobileNavbarOpen}
          onRequestOpenMobileNavbar={setIsMobileNavbarOpen}
          items={navItems}
          logoHref={MEMBER_PORTAL_HOMEPAGE_URL}
          logoLinkAriaLabel={formatMessage({
            defaultMessage: "Go to Member Portal homepage",
            id: "Nsmeq0",
          })}
        />
      )}
      <div
        className={clsx(
          "flex flex-1 flex-col overflow-hidden",
          "px-4 pb-16 pt-6 sm:px-6",
          "min-h-screen max-w-full 2xl:max-w-7xl",
          "2xl:mx-auto 2xl:my-0"
        )}
      >
        {hasOnboardedUser && (
          <>
            {enableMemberPortalDegradedPerformanceBanner && (
              <div className="-m-6 mb-6 ">
                <DegradedPerformanceAlert />
              </div>
            )}
            {memberPortalAlert && (
              <Alert
                type={AlertType.WARN}
                title={formatMessage({
                  defaultMessage: "Attention",
                  id: "71PFKb",
                })}
                message={memberPortalAlert}
                className="mb-6"
              />
            )}

            {hasFutureCoverage && (
              <Alert
                type={AlertType.SUCCESS}
                title={formatMessage({
                  defaultMessage: "Your coverage starts soon.",
                  id: "Nie47v",
                })}
                message={formatMessage({
                  defaultMessage:
                    "We are excited to have you join us. Please become familiar with the features of the member portal before your plan begins. ",
                  id: "i5eiKr",
                })}
                className="mb-6"
              />
            )}

            {hasExpiredCoverage && (
              <Alert
                type={AlertType.WARN}
                title={formatMessage({
                  defaultMessage: "Your coverage has expired.",
                  id: "ui312Q",
                })}
                message={
                  isOnOutOfPocketCostsPage ? (
                    formatMessage({
                      defaultMessage:
                        "The feature is showing the most recent period for which you had active coverage.",
                      id: "TTC233",
                    })
                  ) : (
                    <FormattedMessage
                      defaultMessage="Some features are only available for members with an active plan. Please reach out to {email} if you cannot find the information you are looking for."
                      id="s+Ko/L"
                      values={{
                        email: <MemberServicesEmailLink />,
                      }}
                    />
                  )
                }
                className="mb-6"
              />
            )}
          </>
        )}

        <main className="relative h-full">
          {showNonProductionBanner && (
            <NonProdBanner className={clsx("left-16", nonProdStyles)} />
          )}
          {children}
        </main>

        {hasOnboardedUser && (
          <div className="fixed bottom-4 right-4">
            {showMemberPortalFeedbackSidetab && (
              <Sidetab
                id="dd0W5hy6"
                buttonText="Feedback"
                buttonColor={theme.foundation.primary}
              />
            )}
            <HelpWidget
              memberServicesEmail={HEALTH_PLAN_CUSTOMER_SUPPORT_EMAIL_ADDRESS}
              memberServicesPhoneNumber={
                <MemberServicesPhoneNumberLink className="font-bold" />
              }
              pharmacyPhoneNumber={
                <PharmacyPhoneNumberLink
                  pharmacyNpi="1174253520"
                  variant={PharmacyPhoneNumberLinkVariant.FULL}
                  className="font-bold"
                />
              }
              onOpen={onHelpWidgetOpen}
              {...helpWidgetText}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Layout;
