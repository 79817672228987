import { Tabs, TabsProps } from "@chp/curative_ui";
import { FC } from "react";
import { useIntl } from "react-intl";

type FormattedTabsProps = Omit<
  TabsProps,
  "leftButtonAriaLabel" | "rightButtonAriaLabel"
>;

export const FormattedTabs: FC<FormattedTabsProps> = ({
  testId = "FormattedTabs",
  ...rest
}) => {
  const { formatMessage } = useIntl();

  return (
    <Tabs
      testId={testId}
      leftButtonAriaLabel={formatMessage({
        defaultMessage: "Scroll left",
        id: "jzFcrt",
      })}
      rightButtonAriaLabel={formatMessage({
        defaultMessage: "Scroll right",
        id: "+lsh8v",
      })}
      {...rest}
    />
  );
};
