export enum ProviderPortalMixpanelappName {
  PROVIDER_PORTAL = "prior_auth",
}

export enum ProviderPortalMixpanelUiIdentifierName {
  AUTHENTICATE_FORM = "authenticate-form",
  AUTHENTICATE_FORM_SUBMIT_BUTTON = "authenticate-form-submit-button",
  AUTHORIZATION_DETAILS_FORM = "authorization-details-form",
  AUTHORIZATION_DETAILS_FORM_SUBMIT_BUTTON = "authorization-details-form-submit-button",
  DOWNLOAD_PDF_LINK = "download-pdf-link",
  SUCCESS_MESSAGE = "success-message",
  FAILURE_MESSAGE = "failure-message",
  SEARCH_FORM = "search-form",
  SEARCH_FORM_FAILURE_MESSAGE = "seach-form-failure-message",
  SEARCH_FORM_SUBMIT_BUTTON = "search-form-submit-button",
  SEARCH_FORM_SUCCESS_MESSAGE = "seach-form-success-message",
}
