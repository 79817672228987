import { IS_DEV } from "@chp/shared/env";
import { captureException } from "@sentry/nextjs";
import mixpanel, { Dict } from "mixpanel-browser";

import { MIXPANEL_TOKEN } from "../constants";
import type {
  MixpanelEventOptions,
  MixpanelOnLoadedCallback,
  MixpanelPageViewEventOptions,
} from "../types";
import {
  formatFullyQualifiedEventName,
  formatPageViewEventName,
} from "./eventNames";

export const initMixpanel = (onLoaded: MixpanelOnLoadedCallback) => {
  if (!MIXPANEL_TOKEN) {
    console.error(
      "Unable to initialize Mixpanel:",
      "process.env.NEXT_PUBLIC_HEALTH_PLAN_MIXPANEL_TOKEN is not set"
    );
    return;
  }

  mixpanel.init(MIXPANEL_TOKEN, {
    loaded: onLoaded,
  });
};

const mixpanelTrackHelper = (eventName: string, eventProps?: Dict) => {
  // The reason we're doing it this way instead of using `IS_TEST` is because
  // overriding process.env manually in jest tests do not seem to work when
  // process.env is evaluated in a different file (ie. `env.ts`).
  const NODE_ENV = process.env.NODE_ENV;

  if (IS_DEV || NODE_ENV === "test") {
    console.debug(`(Not tracking Mixpanel event in ${NODE_ENV})`);
    console.debug("Mixpanel Event:", eventName);
    if (eventProps) {
      console.debug("Event Props:", eventProps);
    }
    return;
  }

  try {
    mixpanel.track(eventName, eventProps);
  } catch (error) {
    const sentryEventId = captureException(error);

    console.error(
      "Error tracking mixpanel event.",
      `Sentry eventId: ${sentryEventId},`,
      `original error: ${JSON.stringify(error)}`
    );
  }
};

export const trackMixpanelEvent = (eventOptions: MixpanelEventOptions) => {
  const { browserLanguage = window.navigator.language, eventProps } =
    eventOptions;

  const eventName = formatFullyQualifiedEventName(eventOptions);

  mixpanelTrackHelper(eventName, { browserLanguage, ...eventProps });
};

export const trackMixpanelPageViewEvent = ({
  browserLanguage = window.navigator.language,
  pathname,
  query,
}: MixpanelPageViewEventOptions) => {
  const eventNameWithPathname = formatPageViewEventName(pathname);

  mixpanelTrackHelper(eventNameWithPathname, {
    browserLanguage,
    query,
  });
};
