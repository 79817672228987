import { IntlShape } from "react-intl";

import { ClaimStatus } from "../../generated/memberPortalApi.graphql";

export const claimStatusDisplayNames = ({
  formatMessage,
}: {
  formatMessage: IntlShape["formatMessage"];
}): Record<ClaimStatus, string> => ({
  [ClaimStatus.Denied]: formatMessage({
    defaultMessage: "Denied",
    id: "5kp1Ie",
  }),
  [ClaimStatus.DeniedPartial]: formatMessage({
    defaultMessage: "Partially Denied",
    id: "XmAg6m",
  }),
  [ClaimStatus.Error]: formatMessage({
    defaultMessage: "Unknown",
    id: "5jeq8P",
  }),
  [ClaimStatus.NotAdjudicated]: formatMessage({
    defaultMessage: "Unknown",
    id: "5jeq8P",
  }),
  [ClaimStatus.Paid]: formatMessage({
    defaultMessage: "Approved",
    id: "6XFO/C",
  }),
  [ClaimStatus.Payable]: formatMessage({
    defaultMessage: "Approved",
    id: "6XFO/C",
  }),
  [ClaimStatus.Pended]: formatMessage({
    defaultMessage: "Unknown",
    id: "5jeq8P",
  }),
  [ClaimStatus.Processed]: formatMessage({
    defaultMessage: "Processed",
    id: "YM5KYw",
  }),
  [ClaimStatus.Processing]: formatMessage({
    defaultMessage: "Processing",
    id: "1Ia8qN",
  }),
  [ClaimStatus.Refund]: formatMessage({
    defaultMessage: "Unknown",
    id: "5jeq8P",
  }),
  [ClaimStatus.Unknown]: formatMessage({
    defaultMessage: "Unknown",
    id: "5jeq8P",
  }),
  [ClaimStatus.Void]: formatMessage({
    defaultMessage: "Unknown",
    id: "5jeq8P",
  }),
});
