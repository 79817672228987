export enum MemberCardVariant {
  TexasCountiesSelfInsured,
  TexasCountiesFullyInsured,
  RestOfTexasSelfInsured,
  RestOfTexasFullyInsured,
  MichiganSelfInsured,
  MichiganFullyInsured,
  MidwestSelfInsured,
  MidwestFullyInsured,
  EverywhereElseSelfInsured,
  EverywhereElseFullyInsured,
}

export enum MemberCardLogoVariant {
  COFINITY,
  FCOM,
  FIRST_HEALTH,
}

export enum MemberCardStaticLogoVariant {
  CURATIVE,
  CAPITAL_RX,
}
