import { PHARMACY_SUPPORT_EMAIL_ADDRESS } from "@chp/shared/constants";
import clsx from "clsx";

/**
 * clickable email link with default underline
 */
export const PharmacyEmailLink = ({ className }: { className?: string }) => {
  return (
    <a
      className={clsx("!underline", className)}
      href={`mailto:${PHARMACY_SUPPORT_EMAIL_ADDRESS}`}
    >
      {PHARMACY_SUPPORT_EMAIL_ADDRESS}
    </a>
  );
};
