import { LaunchDarklyFlags } from "@chp/shared";
import { useFlags } from "launchdarkly-react-client-sdk";
import { throttle } from "lodash-es";
import { useCallback, useEffect, useRef, useState } from "react";

import { IdleLogoutModal } from "~/components/IdleLogoutModal";
import { useAuth } from "~/contexts/AuthContext";

// Show warning modal when there are five minutes left to the session
const WARN_USER_IDLE_MS = 1000 * 60 * 5;

const ACTIVE_EVENTS = ["click", "keydown", "scroll"];
const LAST_REFRESH_SESSION_STORAGE_KEY = "lastTimeRefreshed";

export const IdleLogout = () => {
  const { logout, activeUser } = useAuth();
  const { memberPortalIdleLogoutMs } = useFlags<LaunchDarklyFlags>();
  const [warnUserSessionExpiring, setWarnUserSessionExpiring] = useState(false);
  const [msToLogout, setMsToLogout] = useState(memberPortalIdleLogoutMs);
  const inactiveCheckInterval = useRef<NodeJS.Timeout>();

  const debouncedRefreshStorage = throttle(() => {
    const timeStamp = Date.now();
    localStorage.setItem(
      LAST_REFRESH_SESSION_STORAGE_KEY,
      timeStamp.toString()
    );
  }, 2000);

  const removeListeners = useCallback(() => {
    ACTIVE_EVENTS.forEach((event) => {
      window.removeEventListener(event, resetTimer, true);
    });
    // eslint wants this useEffect to depend on resetTimer, but it's not defined by this line
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addListeners = useCallback(() => {
    ACTIVE_EVENTS.forEach((event) => {
      window.addEventListener(event, resetTimer, true);
    });
    // eslint wants this useEffect to depend on resetTimer, but it's not defined by this line
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const timeChecker = useCallback(() => {
    let localMsToLogout: number;
    const lastRefreshTimestamp = localStorage.getItem(
      LAST_REFRESH_SESSION_STORAGE_KEY
    );
    if (memberPortalIdleLogoutMs) {
      inactiveCheckInterval.current = setInterval(() => {
        localMsToLogout =
          memberPortalIdleLogoutMs -
          (Date.now() - Number(lastRefreshTimestamp));
        setMsToLogout(localMsToLogout);

        if (localMsToLogout <= 0) {
          setWarnUserSessionExpiring(false);
          localStorage.removeItem(LAST_REFRESH_SESSION_STORAGE_KEY);
          logout();
        } else if (localMsToLogout < WARN_USER_IDLE_MS) {
          // remove listeners so users have to interact with popup
          removeListeners();
          setWarnUserSessionExpiring(true);
        } else {
          setWarnUserSessionExpiring(false);
        }
      }, 1000);
    }
    // eslint wants this useEffect to depend on resetTimer, but it's not defined by this line
  }, [memberPortalIdleLogoutMs]); // eslint-disable-line react-hooks/exhaustive-deps

  const resetTimer = useCallback(() => {
    setWarnUserSessionExpiring(false);
    clearInterval(inactiveCheckInterval.current);

    debouncedRefreshStorage();

    timeChecker();
  }, [timeChecker, debouncedRefreshStorage]);

  useEffect(() => {
    if (!activeUser || !memberPortalIdleLogoutMs) {
      return;
    }
    const timeStamp = Date.now();
    localStorage.setItem(LAST_REFRESH_SESSION_STORAGE_KEY, `${timeStamp}`);

    addListeners();
    timeChecker();

    return () => {
      clearInterval(inactiveCheckInterval.current);
      removeListeners();
    };
    // eslint wants this useEffect to depend on resetTimer and timerchecker also, but it causes the countdown to constantly reset
  }, [activeUser, memberPortalIdleLogoutMs]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <IdleLogoutModal
      isOpen={warnUserSessionExpiring}
      msToLogout={msToLogout}
      onButtonClick={() => {
        // add listeners back if they're keeping the session alive
        addListeners();
        resetTimer();
      }}
    />
  );
};
export default IdleLogout;
