import clsx from "clsx";
import { useState } from "react";

import { VirtualCareVariant } from "../../types";
import { VirtualCareButtonRed } from "../VirtualCareButton";

export enum VirtualCareWidgetLocation {
  LOWER_LEFT,
}

export type VirtualCareWidgetProps = {
  className?: string;
  innerClassName?: string;
  fixedLocation?: VirtualCareWidgetLocation;
  variant: VirtualCareVariant;
  trackOpen?: () => void;
  trackLogin?: () => void;
  trackRegister?: () => void;
  trackClick?: () => void;
  allowClose?: boolean;
};

export const VirtualCareWidget = ({
  className,
  innerClassName,
  fixedLocation = VirtualCareWidgetLocation.LOWER_LEFT,
  variant,
  trackOpen,
  trackLogin,
  trackRegister,
  trackClick,
  allowClose,
}: VirtualCareWidgetProps): JSX.Element | null => {
  const [isWidgetVisible, setIsWidgetVisible] = useState<boolean>(true);

  if (!isWidgetVisible) return null;

  const widgetContent = (
    <VirtualCareButtonRed
      trackOpen={trackOpen}
      trackLogin={trackLogin}
      trackRegister={trackRegister}
      trackClick={trackClick}
      className={className}
      allowClose={allowClose}
      onClose={() => setIsWidgetVisible(false)}
      virtualCareVariant={variant}
    />
  );

  switch (fixedLocation) {
    case VirtualCareWidgetLocation.LOWER_LEFT:
      return (
        <div
          className={clsx(
            "fixed bottom-4 left-4 z-30",
            "shadow-md",
            innerClassName
          )}
          data-testid="virtual-care-widget"
        >
          {widgetContent}
        </div>
      );
    default:
      return null;
  }
};
