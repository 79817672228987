import chroma from "chroma-js";
import clsx from "clsx";
import { useMemo } from "react";
import Select, { components, MultiValue, StylesConfig } from "react-select";

import { FOCUS_CLASS_NAMES } from "../../constants";
import { generateRandomString } from "../../utils";
import {
  formFieldBorderClassNames,
  FormFieldDescription,
  formFieldErrorId,
  FormFieldErrorMessage,
  FormFieldLabelText,
  useHasError,
} from "..";
import { CUIComponentProps, FormFieldBaseProps } from "../types";

export type MultiSelectProps<Option> = CUIComponentProps<
  FormFieldBaseProps & {
    id?: string;
    isClearable?: boolean;
    isDisabled?: boolean;
    isSearchable?: boolean;
    controlClassName?: string;
    placeholder?: string;
    options: Option[];
    value: MultiValue<Option>;
    onChangeSelection: (option: MultiValue<Option>) => void;
    /**  what to use as the key for this item */
    optionKeyExtractor: (option: Option) => string;
    /**  what to use as the display value in the dropdown */
    optionLabelExtractor: (option: Option) => string;
    /**  what to use as the display value in the control */
    optionControlLabelExtractor?: (option: Option) => string;
    /**  what color to use as the display value in the control */
    optionColorExtractor?(option: Option): string | undefined;
  }
>;

export function MultiSelect<Option>(props: MultiSelectProps<Option>) {
  const {
    className,
    controlClassName,
    id: _id,
    testId,
    placeholder,
    options,
    errorMessage,
    isClearable,
    isDisabled,
    isSearchable = false,
    value,
    onChangeSelection,
    optionKeyExtractor,
    optionLabelExtractor,
    optionControlLabelExtractor,
    optionColorExtractor,
  } = props;
  const id = useMemo(() => _id || generateRandomString(), [_id]);
  const hasError = useHasError({ errorMessage });
  const errorId = formFieldErrorId({ hasError, id });

  const stylesConfig: StylesConfig<Option> = {
    multiValue: (styles, { data: option }) => ({
      ...styles,
      backgroundColor: optionColorExtractor?.(option) ?? styles.backgroundColor,
    }),
    multiValueLabel: (styles, { data: option }) => {
      const color = optionColorExtractor?.(option);
      return {
        ...styles,
        color:
          color && chroma.contrast(color, "white") > 2 ? "#ffffff" : "#000000",
      };
    },
    multiValueRemove: (styles, { data: option }) => {
      const color = optionColorExtractor?.(option);
      return {
        ...styles,
        color:
          color && chroma.contrast(color, "white") > 2 ? "#ffffff" : "#000000",
      };
    },
  };

  return (
    <div
      className={clsx(
        "cui-font-normal cui-text-md-tall",
        isDisabled && "cursor-not-allowed",
        className
      )}
      id={id}
      data-testid={testId}
    >
      <Label {...props} />
      <Select
        defaultValue={value}
        value={value}
        onChange={(options) => onChangeSelection(options)}
        className={clsx(
          controlClassName,
          ...FOCUS_CLASS_NAMES,
          ...formFieldBorderClassNames({
            hasError,
            isDisabled,
          }),
          isDisabled && "bg-surface-disabled text-textColor-subdued",
          hasError && "bg-surface-critical text-textColor-critical",
          "mt-1",
          "w-full",
          "relative",
          "rounded",
          "flex flex-row"
        )}
        styles={stylesConfig}
        placeholder={placeholder}
        options={options}
        getOptionLabel={optionLabelExtractor}
        getOptionValue={optionKeyExtractor}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isMulti={true}
        isSearchable={isSearchable}
        controlShouldRenderValue={true}
        components={{
          SelectContainer: ({ children, innerProps, ...props }) => (
            <components.SelectContainer
              {...props}
              innerProps={{
                ...innerProps,
                // @ts-ignore
                "data-testid": "MultiSelect__SelectContainer",
              }}
            >
              {children}
            </components.SelectContainer>
          ),
          Input: ({ children, innerRef, ...props }) => (
            <components.Input
              innerRef={innerRef}
              {...props}
              inputClassName="focus:ring-0"
            >
              {children}
            </components.Input>
          ),
          Control: ({ children, innerProps, innerRef, ...props }) => (
            <components.Control
              className="flex w-full flex-row"
              innerRef={innerRef}
              innerProps={{
                ...innerProps,
                // @ts-ignore
                "data-testid": "MultiSelect__Control",
              }}
              {...props}
            >
              {children}
            </components.Control>
          ),
          MultiValueLabel: ({ innerProps, selectProps, data, children }) => (
            <components.MultiValueLabel
              innerProps={innerProps}
              selectProps={selectProps}
              data={data}
            >
              {optionControlLabelExtractor?.(data as Option) ?? children}
            </components.MultiValueLabel>
          ),
        }}
      />
      <ValidationError {...props} hasError={hasError} errorId={errorId} />
    </div>
  );
}

type LabelProps = FormFieldBaseProps;

const Label = (props: LabelProps) => {
  const { isLabelSrOnly, label, description } = props;

  return (
    <span data-testid="MultiSelect__Label">
      <FormFieldLabelText isLabelSrOnly={isLabelSrOnly} label={label} />
      <FormFieldDescription
        className={clsx(isLabelSrOnly && "sr-only")}
        description={description}
      />
    </span>
  );
};

type ValidationErrorProps = FormFieldBaseProps & {
  isDisabled?: boolean;
  hasError: boolean;
  errorId?: string;
  testId?: string;
};

const ValidationError = (props: ValidationErrorProps) => {
  const {
    isDisabled,
    hasError,
    errorId,
    errorMessage,
    testId = "MultiSelect",
  } = props;

  return !isDisabled && hasError && errorId ? (
    <div className={clsx("my-2")}>
      <FormFieldErrorMessage
        errorMessage={errorMessage}
        testId={`${testId}__ErrorMessage`}
        id={errorId}
      />
    </div>
  ) : null;
};
