import {
  Button,
  ButtonVariant,
  DatePicker,
  Icon,
  IconVariant,
  Modal,
  ModalMaxWidth,
  RadioVariant,
  SpacingVariant,
  TypeaheadOption,
} from "@chp/curative_ui";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { addBusinessDays, addDays, format } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import { parsePhoneNumber } from "libphonenumber-js";
import { isEmpty } from "lodash-es";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import {
  DOB_FIELD_NAME,
  I18nContext,
  localeCodeToCuiLocales,
} from "../../features";
import { Member } from "../../generated/adminPortalApi.graphql";
import { PharmacySearchResultGql } from "../../generated/memberPortalApi.graphql";
import { useFetchPharmacyOptions } from "../../hooks/typeahead/useFetchPharmacyOptions";
import {
  LaunchDarklyFlags,
  MemberAddressStateOption,
  ReactHookFormFieldValue,
  RecursivePartial,
} from "../../types";
import { DATE_INPUT_FORMAT, formatStreetAddress } from "../../utils";
import {
  DrugSearchTypeahead,
  FormCheckbox,
  FormDateInput,
  FormMaskedInput,
  FormRadioSelection,
  FormSelect,
  FormSubmitButton,
  FormTextField,
  FormTypeahead,
  PhoneNumberInput,
} from "../form";
import { useRxTransferFormSchema } from "./schema";

const testId = "RxTransferForm";

export enum RxTransferFormTheme {
  DEFAULT,
  GRAY,
}

type RxTransferFormFormFields = {
  memberId: ReactHookFormFieldValue<string>;
  memberName: ReactHookFormFieldValue<string>;
  memberDateOfBirth: ReactHookFormFieldValue<string>;
  memberEmail: ReactHookFormFieldValue<string>;
  hasNoDrugAllergies: ReactHookFormFieldValue<boolean>;
  drugAllergiesDetails: ReactHookFormFieldValue<string>;
  phoneNumber: ReactHookFormFieldValue<string>;
  isMailOrderPharmacy: ReactHookFormFieldValue<boolean>;
  pharmacyOption: ReactHookFormFieldValue<object>;
  pharmacyName: ReactHookFormFieldValue<string>;
  pharmacyPhoneNumber: ReactHookFormFieldValue<string>;
  pharmacyAddress: ReactHookFormFieldValue<string>;
  requestedTransferTimeline: ReactHookFormFieldValue<string>;
  requestedTransferDate: ReactHookFormFieldValue<string>;
  shouldAutomaticallyRefill: ReactHookFormFieldValue<boolean>;

  additionalInfo: ReactHookFormFieldValue<string>;
  deliveryState: ReactHookFormFieldValue<MemberAddressStateOption>;
  drugs: {
    drug: ReactHookFormFieldValue<TypeaheadOption<string>>;
    prescriptionNumber: ReactHookFormFieldValue<string>;
    dosage: ReactHookFormFieldValue<string>;
  }[];
};

function formatDrugs(drugs: RxTransferFormFormFields["drugs"]) {
  return drugs
    .map(
      (item, index) =>
        `Medication ${index + 1}: ${item.drug?.label} - ${
          item.prescriptionNumber
        } - ${item.dosage}`
    )
    .join(", ");
}

function formatPhoneNumber(phoneNumber?: ReactHookFormFieldValue<string>) {
  if (!phoneNumber) {
    return null;
  }

  return parsePhoneNumber(phoneNumber, "US")?.format("E.164") ?? "";
}

export const RxTransferForm = ({
  theme = RxTransferFormTheme.DEFAULT,
  memberOptions,
  isSubmitting,
  didSubmit,
  onSubmit,
  onCancel,
}: {
  theme?: RxTransferFormTheme;
  memberOptions: RecursivePartial<Member>[];
  onSubmit: (formResponse: string, memberId: string | null) => Promise<void>;
  isSubmitting: boolean;
  didSubmit: boolean;
  onCancel: () => void;
}) => {
  const { formatMessage } = useIntl();

  const [isConfirmCancelationModalOpen, setIsConfirmCancelationModalOpen] =
    useState(false);

  const displayMemberOptions = memberOptions.length > 0;
  const yupSchema = useRxTransferFormSchema(displayMemberOptions);

  const methods = useForm<RxTransferFormFormFields>({
    resolver: yupResolver(yupSchema),
    mode: "onTouched",
    criteriaMode: "all",
    defaultValues: {
      drugs: [
        {
          drug: undefined,
          prescriptionNumber: undefined,
          dosage: undefined,
        },
      ],
    },
  });

  const {
    handleSubmit: validateForm,
    reset,
    formState: { isDirty },
  } = methods;

  const handleSubmit: SubmitHandler<RxTransferFormFormFields> = async (
    formData
  ) => {
    const { memberId, memberName, memberDateOfBirth, memberEmail } = formData;
    const memberOption =
      memberOptions.length > 0 && memberId
        ? memberOptions.find((o) => o.externalId === memberId)
        : null;

    if (memberOptions.length > 0 && (!memberId || !memberOption?.birthDate)) {
      throw new Error("Missing required data to submit transfer form");
    }

    const memberData = memberOption
      ? {
          dob: memberOption.birthDate,
          name: memberOption.fullName,
          email: memberOption.email ?? null,
        }
      : {
          name: memberName,
          dob: memberDateOfBirth,
          email: memberEmail,
        };

    const formResponse = JSON.stringify({
      ...memberData,
      phone: formatPhoneNumber(formData.phoneNumber),
      drug_allergies: formData.drugAllergiesDetails ?? "",

      pharmacy_name: formData.pharmacyName,
      pharmacy_address: formData.pharmacyAddress ?? "",
      pharmacy_phone: formatPhoneNumber(formData.pharmacyPhoneNumber),
      is_mail_order_pharmacy: formData.isMailOrderPharmacy,

      prescription_details: formatDrugs(formData.drugs),
      requested_transfer_date:
        formData.requestedTransferDate ?? formData.requestedTransferTimeline,
      should_automatically_refill: formData.shouldAutomaticallyRefill ?? "",

      additional_info: formData.additionalInfo ?? "",

      address: formData.deliveryState ?? "",
    });

    await onSubmit(formResponse, memberId || null);
  };

  useEffect(() => {
    if (!isSubmitting && didSubmit) {
      reset();
    }
  }, [isSubmitting, didSubmit, reset]);

  return (
    <FormProvider<RxTransferFormFormFields> {...methods}>
      <form
        onSubmit={validateForm(handleSubmit)}
        className={clsx("flex max-w-5xl flex-col items-center")}
      >
        <div
          className={clsx(
            "rounded-lg",
            "w-full",
            "py-7",
            "px-4",
            "mb-5",
            "md:mb-11",
            "md:px-12",
            theme === RxTransferFormTheme.GRAY ? "bg-gray-100" : "bg-white"
          )}
        >
          <MemberInformationSection memberOptions={memberOptions} />
          <SectionDivider />
          <PrescriptionInformationSection theme={theme} />
          <SectionDivider />
          <PharmacyInformationSection />
        </div>
        <div
          className={clsx(
            "mb-12 flex w-full flex-col-reverse justify-between gap-y-5",
            "md:flex-row md:gap-y-0 md:px-16"
          )}
        >
          <Button
            variant={ButtonVariant.BigOutline}
            onClick={() => {
              if (isDirty) {
                setIsConfirmCancelationModalOpen(true);
              } else {
                onCancel();
              }
            }}
          >
            <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
          </Button>
          <FormSubmitButton
            variant={ButtonVariant.BigPrimary}
            isLoading={isSubmitting}
          >
            <FormattedMessage
              defaultMessage="Submit Transfer Request"
              id="7XazIE"
            />
          </FormSubmitButton>
        </div>
      </form>
      <Modal
        isOpen={isConfirmCancelationModalOpen}
        onRequestClose={() => setIsConfirmCancelationModalOpen(false)}
        title={formatMessage({
          defaultMessage: "Discard changes?",
          id: "41zpq3",
        })}
        message={formatMessage({
          defaultMessage:
            "You have unsaved changes. Are you sure you want to leave?",
          id: "Wq1yhE",
        })}
        closeButtonText={formatMessage({
          defaultMessage: "Close",
          id: "rbrahO",
        })}
        buttonText={formatMessage({ defaultMessage: "Confirm", id: "N2IrpM" })}
        onButtonClick={onCancel}
      />
    </FormProvider>
  );
};

const MemberInformationSection = ({
  memberOptions,
}: {
  memberOptions: RecursivePartial<Member>[];
}) => {
  const { formatMessage } = useIntl();
  const methods = useFormContext();

  const { watch, trigger, setValue } = methods;

  const hasNoDrugAllergies = watch("hasNoDrugAllergies");

  useEffect(() => {
    if (hasNoDrugAllergies) {
      setValue("drugAllergiesDetails", null);
      trigger("drugAllergiesDetails");
    }
  }, [hasNoDrugAllergies, setValue, trigger]);

  return (
    <div>
      <p className={clsx("font-semibold", "text-lg", "leading-6", "mb-2")}>
        <FormattedMessage defaultMessage="Member Information" id="lrpwIc" />
      </p>
      <p className={clsx("leading-6", "mb-2")}>
        <FormattedMessage
          defaultMessage="All fields are required unless marked as optional"
          id="QlRmJE"
        />
      </p>
      <div
        className={clsx("grid", "grid-cols-1 gap-4 gap-x-12", "md:grid-cols-2")}
      >
        {memberOptions.length === 0 ? (
          <FormTextField<RxTransferFormFormFields>
            name="memberName"
            label={formatMessage({ defaultMessage: "Name", id: "HAlOn1" })}
            placeholder={formatMessage({
              defaultMessage: "Enter member's full name",
              id: "1HJGYD",
            })}
          />
        ) : (
          <FormSelect
            name="memberId"
            label={formatMessage({ defaultMessage: "Name", id: "HAlOn1" })}
            options={memberOptions}
            optionKeyExtractor={(o) => o.externalId!}
            optionLabelExtractor={(o) => o.fullName!}
            optionStringValueExtractor={(o) => o.externalId!}
            placeholder={formatMessage({
              defaultMessage: "Select plan member",
              id: "WT4nlm",
            })}
          />
        )}
        <PhoneNumberInput name="phoneNumber" />
        {memberOptions.length === 0 && (
          <>
            <FormDateInput<RxTransferFormFormFields>
              name="memberDateOfBirth"
              label={formatMessage({
                defaultMessage: "Date of Birth",
                id: "e9Z+tg",
              })}
              fromDate="01/01/1900"
              toDate={format(Date.now(), DATE_INPUT_FORMAT)}
              id={DOB_FIELD_NAME}
              className="mb-6"
              openCalendarButtonAriaLabel={formatMessage({
                defaultMessage: "Open date selection helper",
                id: "+uuUin",
              })}
              dialogTitle={formatMessage({
                defaultMessage: "Select a date",
                id: "7qOQpv",
              })}
            />
            <FormTextField<RxTransferFormFormFields>
              id="email"
              name="memberEmail"
              label={formatMessage({
                defaultMessage: "Email address",
                id: "hJZwTS",
              })}
              placeholder={formatMessage({
                defaultMessage: "Enter member's email address",
                id: "f293Nq",
              })}
            />
          </>
        )}
        <div className="md:col-span-2">
          <FormTextField<RxTransferFormFormFields>
            name="drugAllergiesDetails"
            htmlElement="textarea"
            label={formatMessage({
              defaultMessage: "Drug allergies",
              id: "l0j/aE",
            })}
            placeholder={formatMessage({
              defaultMessage:
                "Enter the names of substances that the member has an allergic reaction to",
              id: "j1zyC6",
            })}
            isDisabled={hasNoDrugAllergies}
          />
          <FormCheckbox<RxTransferFormFormFields>
            name="hasNoDrugAllergies"
            testId="No_Known_Drug_Allergies_Checkbox"
            label={formatMessage({
              defaultMessage:
                "Selected plan member does not have any known drug allergies",
              id: "k7E/Z4",
            })}
            value=""
          />
        </div>
      </div>
    </div>
  );
};

const PharmacyInformationSection = () => {
  const { formatMessage } = useIntl();
  const { watch, trigger, formState, setValue } = useFormContext();
  const pharmacyPhoneNumber = watch("pharmacyPhoneNumber");
  const pharmacyAddress = watch("pharmacyAddress");
  const pharmacyZip = watch("pharmacyZip");
  const pharmacyOption = watch("pharmacyOption");
  const isMailOrderPharmacy = watch("isMailOrderPharmacy");
  const isPharmacySearchDisabled = !pharmacyZip || pharmacyZip.length < 5;
  const [isPharmacySearchModalOpen, setIsPharmacySearchModalOpen] =
    useState(false);

  const { fetchPharmacyOptions, arePharmacyOptionsLoading } =
    useFetchPharmacyOptions();

  useEffect(() => {
    if (
      !formState.touchedFields.pharmacyPhoneNumber &&
      !formState.touchedFields.pharmacyAddress
    )
      return;

    trigger(["pharmacyPhoneNumber", "pharmacyAddress"]);
  }, [
    trigger,
    pharmacyPhoneNumber,
    pharmacyAddress,
    formState.touchedFields.pharmacyPhoneNumber,
    formState.touchedFields.pharmacyAddress,
  ]);

  return (
    <>
      <div>
        <p className={clsx("font-semibold", "text-lg", "leading-6", "mb-2")}>
          <FormattedMessage defaultMessage="Pharmacy Information" id="IHSVBg" />
        </p>
        <p className={clsx("leading-6", "mb-2")}>
          <FormattedMessage
            defaultMessage="Please enter the name and either the phone number <s>OR</s> address for the current pharmacy for these prescription(s)"
            id="oJ8mgC"
            values={{
              s: (content) => <strong>{content}</strong>,
            }}
          />
        </p>
        <div
          className={clsx(
            "grid",
            "grid-cols-1 gap-4 gap-x-12",
            "md:grid-cols-2"
          )}
        >
          <FormCheckbox<RxTransferFormFormFields>
            className="md:col-span-2"
            name="isMailOrderPharmacy"
            testId="Mail_Order_Checkbox"
            label={formatMessage({
              defaultMessage: "This is a mail order pharmacy.",
              id: "l0WtoY",
            })}
            value=""
          />
          <FormTextField<RxTransferFormFormFields>
            className="col-span-1"
            label={formatMessage({ defaultMessage: "Name", id: "HAlOn1" })}
            name="pharmacyName"
            placeholder={formatMessage({
              defaultMessage: "Enter the name of the current pharmacy",
              id: "MXHK3g",
            })}
            testId="Pharmacy_Name_Manual"
          />

          <div className="flex items-end">
            <Button
              className="!flex"
              variant={ButtonVariant.SmallPrimary}
              onClick={() => setIsPharmacySearchModalOpen(true)}
              isDisabled={isMailOrderPharmacy}
            >
              <Icon
                className="mr-2 stroke-[3px]"
                size={SpacingVariant.S16}
                variant={IconVariant.SEARCH}
              />
              <FormattedMessage defaultMessage="Find a pharmacy" id="YC8Khm" />
            </Button>
          </div>

          <PhoneNumberInput<RxTransferFormFormFields>
            className="col-span-1 md:row-start-4"
            testId={`${testId}__pharmacy_phone_input`}
            name="pharmacyPhoneNumber"
            label={formatMessage({
              defaultMessage: "Phone number",
              id: "jdJhOL",
            })}
          />
          <FormTextField<RxTransferFormFormFields>
            className="col-span-1 md:row-start-4"
            testId={`${testId}__pharmacy_address_input`}
            label={formatMessage({ defaultMessage: "Address", id: "e6Ph5+" })}
            name="pharmacyAddress"
            placeholder={formatMessage({
              defaultMessage: "Enter the pharmacy's address",
              id: "h/wReT",
            })}
          />
        </div>
      </div>
      <Modal
        maxWidth={ModalMaxWidth.ExtraLarge}
        title="Find a pharmacy"
        isOpen={isPharmacySearchModalOpen}
        onRequestClose={() => setIsPharmacySearchModalOpen(false)}
        isButtonDisabled={isEmpty(pharmacyOption)}
        buttonText="Select"
        closeButtonText="Close"
        onButtonClick={() => {
          setValue("pharmacyName", pharmacyOption?.value?.name);
          setValue(
            "pharmacyAddress",
            formatStreetAddress(pharmacyOption?.value)
          );
          setValue("pharmacyPhoneNumber", pharmacyOption?.value.phoneNumber);

          trigger(["pharmacyName", "pharmacyAddress", "pharmacyPhoneNumber"]);

          setIsPharmacySearchModalOpen(false);
        }}
      >
        <p className="!my-2">
          <FormattedMessage
            defaultMessage="Find and select the pharmacy where you currently receive your prescription."
            id="TFJczq"
          />
        </p>
        <div className="mb-6 flex flex-col md:flex-row">
          <FormMaskedInput
            className="mb-8 md:mb-0 md:mr-8 md:max-w-[114px]"
            name="pharmacyZip"
            mask="00000"
            placeholderAlwaysVisible={false}
            testId={`${testId}__pharmacy_zip`}
            label={formatMessage({
              defaultMessage: "Zipcode",
              id: "NRtSKR",
            })}
            placeholder={formatMessage({
              defaultMessage: "Enter zip",
              id: "Vktniy",
            })}
            emptyValue=""
          />
          <FormTypeahead<
            RxTransferFormFormFields,
            Partial<PharmacySearchResultGql>
          >
            name="pharmacyOption"
            testId={`${testId}__pharmacy_option`}
            className="flex-1"
            optionKeyExtractor={(o) => JSON.stringify(o.value)}
            fetchOptions={(query) => fetchPharmacyOptions(query, pharmacyZip)}
            isLoading={arePharmacyOptionsLoading}
            label={formatMessage({
              defaultMessage: "Pharmacy name",
              id: "pVgqb4",
            })}
            placeholder={
              isPharmacySearchDisabled
                ? formatMessage({
                    defaultMessage: "Zip code is required",
                    id: "z7+EXa",
                  })
                : formatMessage({
                    defaultMessage: "Search",
                    id: "xmcVZ0",
                  })
            }
            isDisabled={isPharmacySearchDisabled}
            queryStringMinLength={3}
          />
        </div>
      </Modal>
    </>
  );
};

const PrescriptionInformationSection = ({
  theme,
}: {
  theme: RxTransferFormTheme;
}) => {
  const flags = useFlags<LaunchDarklyFlags>();
  const { locale } = useContext(I18nContext);
  const { formatMessage } = useIntl();
  const methods = useFormContext();

  const { control, watch, setValue } = methods;

  const drugsArray = useFieldArray({
    control: control,
    name: "drugs",
  });

  const requestedTransferTimeline = watch("requestedTransferTimeline");
  const requestedTransferDate = watch("requestedTransferDate");
  const listFormatter = useMemo(() => {
    return new Intl.ListFormat(locale, {
      style: "long",
      type: "conjunction",
    });
  }, [locale]);

  useEffect(() => {
    if (requestedTransferTimeline !== "custom_date") {
      setValue("requestedTransferDate", undefined);
    }
  }, [requestedTransferTimeline, setValue]);

  return (
    <div className="flex flex-col">
      <p className={clsx("font-semibold", "text-lg", "leading-6", "mb-2")}>
        <FormattedMessage
          defaultMessage="Prescription Information"
          id="Gw7eCt"
        />
      </p>
      <div
        className={clsx("grid", "grid-cols-1 gap-4 gap-x-12", "md:grid-cols-2")}
      >
        <FormSelect<RxTransferFormFormFields, { label: string; value: string }>
          name="deliveryState"
          label={formatMessage({
            defaultMessage: "Where will these prescriptions be delivered?",
            id: "ZGSs2L",
          })}
          options={(flags.restrictedLocationsForCurativePharmacyList ?? []).map(
            (stateAbbrev) => {
              return {
                label: stateAbbrev,
                value: stateAbbrev,
                isDisabled: false,
              };
            }
          )}
          placeholder={formatMessage({
            defaultMessage: "Select State",
            id: "owRf8v",
          })}
          optionKeyExtractor={(o) => o.value}
          optionLabelExtractor={(o) => o.label}
          optionStringValueExtractor={(o) => o.value}
        />
        <p className="text-sm leading-6 md:mt-6">
          <FormattedMessage
            defaultMessage="Curative Pharmacy delivers to the following states: {stateList}."
            id="NGyblD"
            values={{
              stateList: listFormatter.format(
                flags.restrictedLocationsForCurativePharmacyList ?? []
              ),
            }}
          />
        </p>
      </div>
      <div
        className={clsx(
          "grid",
          "grid-cols-1 gap-4 gap-x-12",
          "md:grid-cols-3",
          "mt-4"
        )}
        data-testid="Medications_grid"
      >
        {drugsArray.fields.map((drug, idx) => (
          <Fragment key={drug.id}>
            <DrugSearchTypeahead
              name={`drugs.${idx}.drug`}
              testId={`drugs.${idx}`}
              label={formatMessage({
                defaultMessage: "Medication name",
                id: "v8FnhO",
              })}
              placeholder={formatMessage({
                defaultMessage: "Search for medication name",
                id: "911d0D",
              })}
            />
            <FormTextField
              name={`drugs.${idx}.prescriptionNumber`}
              label={formatMessage({
                defaultMessage: "Prescription number (optional)",
                id: "bcFjZ1",
              })}
              placeholder={formatMessage({
                defaultMessage: "Enter number",
                id: "VsgYoF",
              })}
            />
            <div className="flex flex-row">
              <FormTextField
                name={`drugs.${idx}.dosage`}
                label={formatMessage({
                  defaultMessage: "Dosage (optional)",
                  id: "5PKnJq",
                })}
                placeholder={formatMessage({
                  defaultMessage: "Enter dosage",
                  id: "xajEkb",
                })}
              />
              {idx > 0 && (
                <button
                  type="button"
                  onClick={() => drugsArray.remove(idx)}
                  className="flex"
                  aria-label={formatMessage({
                    defaultMessage: "Remove this prescription",
                    id: "DjV+Wc",
                  })}
                >
                  <Icon
                    className="translate-y-8"
                    variant={IconVariant.TRASH}
                    size={SpacingVariant.S24}
                  />
                </button>
              )}
            </div>
          </Fragment>
        ))}
      </div>
      <button
        type="button"
        className="flex items-center self-end"
        onClick={() => {
          drugsArray.append({
            drug: undefined,
            dosage: undefined,
            prescriptionNumber: undefined,
          });
        }}
      >
        <Icon variant={IconVariant.PLUS} size={SpacingVariant.S16} />
        <FormattedMessage defaultMessage="Add Prescription" id="O5sdVB" />
      </button>
      <div
        className={clsx("grid", "grid-cols-1 gap-4 gap-x-12", "md:grid-cols-2")}
      >
        <FormRadioSelection<RxTransferFormFormFields>
          className="md:col-span-2"
          variant={RadioVariant.NormalColumn}
          name="requestedTransferTimeline"
          label={formatMessage({
            defaultMessage: "Approximately when is this medication needed?",
            id: "LBeLde",
          })}
          options={[
            {
              label: formatMessage({
                defaultMessage: "As soon as possible (3-5 business days)",
                id: "m1uPmt",
              }),
              value: "3-5 days",
            },
            {
              label: formatMessage({
                defaultMessage: "1 Week  (7 business days)",
                id: "7a8x0P",
              }),
              value: "7 days",
            },
            {
              label: formatMessage(
                {
                  defaultMessage: "Choose date: {requestedTransferDate}",
                  id: "s+49lP",
                },
                {
                  requestedTransferDate: requestedTransferDate
                    ? format(new Date(requestedTransferDate), "MMM. d, y")
                    : "",
                }
              ),
              value: "custom_date",
            },
          ]}
        />
        <Controller
          control={control}
          name="requestedTransferDate"
          render={({ field: { onChange } }) => {
            return (
              <DatePicker
                className={clsx(
                  requestedTransferTimeline !== "custom_date" && "hidden",
                  "w-fit",
                  "!mx-auto",
                  "md:mx-0",
                  theme === RxTransferFormTheme.GRAY && "bg-white"
                )}
                locale={localeCodeToCuiLocales[locale]}
                fromDate={addBusinessDays(new Date(), 3)}
                toDate={addDays(new Date(), 90)}
                disabled={
                  // Exclude Sundays from selection
                  (d) => d.getDay() === 0
                }
                onSelect={(v) => {
                  onChange({
                    target: { value: format(v ?? new Date(), "MM/dd/yyyy") },
                  });
                }}
              />
            );
          }}
        />
        <FormRadioSelection<RxTransferFormFormFields>
          className="md:col-span-2"
          name="shouldAutomaticallyRefill"
          label={formatMessage({
            defaultMessage:
              "Should these prescriptions be automatically refilled?",
            id: "fPy62G",
          })}
          options={[
            {
              label: formatMessage({ defaultMessage: "Yes", id: "a5msuh" }),
              value: "Yes",
            },
            {
              label: formatMessage({ defaultMessage: "No", id: "oUWADl" }),
              value: "No",
            },
          ]}
        />
        <FormTextField<RxTransferFormFormFields>
          className="md:col-span-2"
          label={formatMessage({
            defaultMessage:
              "Anything Curative Pharmacy should know? Special requests? (optional)",
            id: "u+kqcA",
          })}
          name="additionalInfo"
          htmlElement="textarea"
        />
      </div>
    </div>
  );
};

const SectionDivider = () => {
  return <div className="my-12 h-px bg-black" />;
};
