import { FC, useEffect, useState } from "react";

import {
  AccordionDropdownItem,
  AccordionDropdownItemProps,
} from "./AccordionDropdownItem";

export enum DropdownButtonAlignment {
  LEFT,
  RIGHT,
}

export type AccordionDropdownItemDescriptor = Omit<
  AccordionDropdownItemProps,
  "isOpen"
> & {
  key?: string;
};

export type AccordionDropdownProps = {
  className?: string;
  defaultIndex?: number; // only used if expandOneAtATime is true
  expandOneAtATime?: boolean;
  accordionItems: AccordionDropdownItemDescriptor[];
  dropDownButtonAlignment?: DropdownButtonAlignment;
};

// AccordionDropdown manages its own state internally by default. You can use defaultOpen to set the initial state of each accordion item.
// If you want to control the accordion, you must set expandOneAtATime to true and pass in a defaultIndex which controls which accordion item is open.
export const AccordionDropdown: FC<AccordionDropdownProps> = ({
  className,
  defaultIndex,
  expandOneAtATime = false,
  accordionItems,
  dropDownButtonAlignment = DropdownButtonAlignment.RIGHT,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(
    defaultIndex || null
  );

  if (defaultIndex !== undefined && !expandOneAtATime) {
    console.warn(
      "The defaultIndex prop is only used when expandOneAtATime is true."
    );
  }

  // This is useful for when the parent component wants to control the accordion
  useEffect(() => {
    if (defaultIndex !== undefined) {
      setSelectedIndex(defaultIndex);
    }
  }, [defaultIndex]);

  return (
    <div className={className} data-testid="AccordionDropdown">
      {accordionItems.map(
        (
          {
            defaultOpen,
            icon,
            key,
            onButtonClick,
            panelContent,
            summaryContent,
            hasUnderline,
            className,
            parentContainerClassName,
            summaryClassNames,
            testId,
            ...rest
          },
          index
        ) => (
          <AccordionDropdownItem
            isOpen={expandOneAtATime ? selectedIndex === index : undefined}
            defaultOpen={defaultOpen}
            icon={icon}
            key={key || "accordion-item-" + index}
            onButtonClick={(isOpen) => {
              if (!isOpen) {
                setSelectedIndex(index);
              } else {
                setSelectedIndex(null);
              }
              onButtonClick && onButtonClick(isOpen);
            }}
            panelContent={panelContent}
            summaryContent={summaryContent}
            testId={testId}
            hasUnderline={hasUnderline}
            dropDownButtonAlignment={dropDownButtonAlignment}
            parentContainerClassName={parentContainerClassName}
            summaryClassNames={summaryClassNames}
            className={className}
            {...rest}
          />
        )
      )}
    </div>
  );
};
