import { HEALTH_PLAN_CUSTOMER_SUPPORT_EMAIL_ADDRESS } from "@chp/shared";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { healthCheckClinicalDocument } from "./utils";

export type ClinicalDocPreviewProps = {
  /**
   * URL of the document to embed.
   *
   * @remarks
   *
   * See: {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#src}
   */
  documentUrl: string;
  testId?: string;
  /**
   * The title's value should concisely describe the embedded content.
   *
   * @remarks
   *
   * See: {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#accessibility_concerns}
   */
  title: string;
  className?: string;
};

export const ClinicalDocPreview = ({
  documentUrl,
  testId = "ClinicalDocPreview",
  title,
  className,
}: ClinicalDocPreviewProps): JSX.Element => {
  const [shouldDisplayDocument, setShouldDisplayDocument] = useState(false);

  useEffect(() => {
    const checkDocument = async () => {
      setShouldDisplayDocument(await healthCheckClinicalDocument(documentUrl));
    };

    checkDocument();
  }, [documentUrl]);

  if (!shouldDisplayDocument) {
    return (
      <>
        <FormattedMessage
          defaultMessage="The requested document is missing."
          id="FEOkXm"
        />{" "}
        <FormattedMessage
          defaultMessage="Please contact member services for assistance at {email}."
          id="Cjavob"
          values={{ email: HEALTH_PLAN_CUSTOMER_SUPPORT_EMAIL_ADDRESS }}
        />
      </>
    );
  }

  return (
    <iframe
      className={clsx("w-full", "h-full", className)}
      data-testid={testId}
      src={documentUrl}
      title={title}
    />
  );
};
