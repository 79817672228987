import {
  TypeaheadOption,
  TypeaheadOptionGroups,
  USER_TYPING_TIMEOUT,
} from "@chp/curative_ui";
import { debounce } from "lodash-es";
import { useCallback, useEffect, useMemo, useState } from "react";

export type UseTypeaheadOptions<T> = {
  fetchOptions?: (queryString: string) => Promise<TypeaheadOption<T>[]>;
  fetchOptionGroups?: (
    queryString: string
  ) => Promise<TypeaheadOptionGroups<T>>;
  initialOptions?: TypeaheadOption<T>[];
  initialOptionGroups?: TypeaheadOptionGroups<T>;
  debounceMs?: number;
};

export const useTypeaheadOptions = <T = string>({
  fetchOptions,
  fetchOptionGroups,
  initialOptions: _initialOptions,
  initialOptionGroups,
  debounceMs = USER_TYPING_TIMEOUT,
}: UseTypeaheadOptions<T>) => {
  const initialOptions = useMemo(
    () => _initialOptions || [],
    [_initialOptions]
  );

  const [options, setOptions] = useState<TypeaheadOption<T>[]>(initialOptions);
  const [optionGroups, setOptionGroups] = useState<
    TypeaheadOptionGroups<T> | undefined
  >(initialOptionGroups);

  const [queryString, setQueryString] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFetchOptions = useCallback(
    debounce(async (queryString: string) => {
      if (!fetchOptions) return undefined;

      setErrorMessage("");
      try {
        const options = await fetchOptions(queryString);
        setOptions(options);
      } catch (error: unknown) {
        setErrorMessage((error as Error).message);
      }
    }, debounceMs),
    [debounceMs, fetchOptions, setErrorMessage, setOptions]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFetchOptionGroups = useCallback(
    debounce(async (queryString: string) => {
      if (!fetchOptionGroups) return undefined;

      setErrorMessage("");

      try {
        const optionGroups = await fetchOptionGroups(queryString);
        setOptionGroups(optionGroups);
      } catch (error) {
        setErrorMessage((error as Error).message);
      }
    }, debounceMs),
    [debounceMs, fetchOptionGroups, setErrorMessage, setOptionGroups]
  );

  useEffect(() => {
    if (queryString) {
      handleFetchOptions(queryString);
      return;
    }
    // If the user quickly clears out the typeahead,
    // we can wind up with a pending call to handleFetchOptions while
    // Resetting the initial options. In that case, we want to cancel.
    handleFetchOptions.cancel();
    setOptions(initialOptions);
  }, [handleFetchOptions, initialOptions, queryString]);

  // This `useEffect` is identical to the one above, but just for optionGroups
  useEffect(() => {
    if (!initialOptionGroups) {
      return;
    }

    if (queryString) {
      handleFetchOptionGroups(queryString);
      return;
    }

    handleFetchOptionGroups.cancel();
    setOptionGroups(initialOptionGroups);
  }, [handleFetchOptionGroups, initialOptionGroups, queryString]);

  return {
    options,
    optionGroups,
    queryString,
    setQueryString,
    errorMessage,
  };
};
