import { isValid } from "date-fns";
import * as Yup from "yup";

import { parseDateInput } from "../dateTime";

export const dateInputSchema = ({ message }: { message: string }) =>
  Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }

      return parseDateInput(originalValue);
    })
    .test(
      "isDateInputValid",
      () => message,
      (_, context) => {
        const { originalValue } = context;
        // allow empty or valid originalValue
        // see: https://github.com/jquense/yup/issues/1035

        return !originalValue || isValid(parseDateInput(originalValue));
      }
    )
    .typeError(message);
