import downloadjs from "downloadjs";
import { toJpeg, toPng } from "html-to-image";
import { Options } from "html-to-image/lib/types";
import { MutableRefObject } from "react";

import type { MemberCardDownloadFileExtension } from "../types";

const SAFARI_USER_AGENT_REGEXP = /^((?!chrome|android).)*safari/i;

const isSafari = (): boolean =>
  SAFARI_USER_AGENT_REGEXP.test(navigator.userAgent);

export class MemberCardImageError extends Error {
  constructor(message: string = "Error creating member card image") {
    super(message);
    // Set the prototype explicitly as per the recommendation at
    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, MemberCardImageError.prototype);
    this.name = "MemberCardImageError";
  }
}

export type GenerateMemberCardDataUrlParams = {
  cardContainerRef: MutableRefObject<HTMLElement | null>;
  fileFormat: MemberCardDownloadFileExtension;
  onError?: (error: MemberCardImageError) => void;
};

export const generateMemberCardDataUrl = async ({
  cardContainerRef,
  fileFormat,
  onError,
}: GenerateMemberCardDataUrlParams): Promise<string> => {
  if (!cardContainerRef.current) {
    return "";
  }

  try {
    let dataUrl = "";

    const options: Options = { includeQueryParams: true };

    if (fileFormat === "jpeg") {
      const optionsJpeg: Options = {
        ...options,
        backgroundColor: "#ffffff",
      };

      dataUrl = await toJpeg(cardContainerRef.current, optionsJpeg);

      // There is an issue on safari where the image does not render correctly on the first pass.
      // https://github.com/bubkoo/html-to-image/issues/292
      if (isSafari()) {
        dataUrl = await toJpeg(cardContainerRef.current, optionsJpeg);
      }
    } else {
      dataUrl = await toPng(cardContainerRef.current, options);

      // There is an issue on safari where the image does not render correctly on the first pass.
      // https://github.com/bubkoo/html-to-image/issues/292
      if (isSafari()) {
        dataUrl = await toPng(cardContainerRef.current, options);
      }
    }

    return dataUrl;
  } catch {
    onError?.(new MemberCardImageError());
    return "";
  }
};

const extensionToMimeType = (
  extension: MemberCardDownloadFileExtension
): string => {
  switch (extension) {
    case "png":
      return "image/png";
    case "jpeg":
      return "image/jpeg";
  }
};

export type DownloadMemberCardFromUrlParams = {
  cardUrl: string;
  cardDownloadName: string;
  fileFormat: MemberCardDownloadFileExtension;
  onError?: (error: MemberCardImageError) => void;
};

export const downloadMemberCardFromUrl = async ({
  cardUrl,
  cardDownloadName,
  fileFormat,
  onError,
}: DownloadMemberCardFromUrlParams): Promise<void> => {
  if (cardUrl === "") {
    return;
  }

  try {
    // This is to get around a weird quirk with downloadjs. When passing in the
    // file URL as a string, it sometimes gets confused and thinks the string is
    // intended to represent the file content. When this happens, the file will
    // still be downloaded but instead of the expected file data we end up with
    // an "image" or a "PDF" that is actually a text file containing the URL.
    const response = await window.fetch(cardUrl);
    const fileBlob = await response.blob();

    downloadjs(fileBlob, cardDownloadName, extensionToMimeType(fileFormat));
  } catch {
    onError?.(new MemberCardImageError("Error downloading member card image"));
  }
};

export type DownloadMemberCardParams = {
  cardContainerRef: MutableRefObject<HTMLElement | null>;
  cardDownloadName: string;
  fileFormat: MemberCardDownloadFileExtension;
  onError?: (error: MemberCardImageError) => void;
};

export const downloadMemberCard = async ({
  cardContainerRef,
  cardDownloadName,
  fileFormat,
  onError,
}: DownloadMemberCardParams): Promise<void> => {
  const dataUrl = await generateMemberCardDataUrl({
    cardContainerRef,
    fileFormat,
    onError,
  });

  await downloadMemberCardFromUrl({
    cardUrl: dataUrl,
    cardDownloadName,
    fileFormat,
    onError,
  });
};
