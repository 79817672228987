import {
  Member,
  MemberPortalRegistrationStatus,
  Relationship,
} from "../../generated/memberPortalApi.graphql";
import { InviteDependentMember } from "../../types";

export const getNotRegisteredDependents = (
  members?: InviteDependentMember[]
) => {
  if (!members) return [];

  return members.filter(
    (member) =>
      member.portalRegistrationStatus ===
      MemberPortalRegistrationStatus.NotRegistered
  );
};

export const dependentsEligibleForOnboardingInvitation = (
  members?: Member[]
) => {
  return getNotRegisteredDependents(
    members?.filter(
      ({ closestMemberPlanYear }) =>
        closestMemberPlanYear?.baselineVisitStatus?.visitRequired
    )
  ).filter(({ email }) => !email);
};

export const isPolicyholder = (relationship?: Relationship | null) =>
  relationship === Relationship.Self;

export const isPolicyholderOrSpouse = (relationship?: Relationship | null) =>
  relationship === Relationship.Self || relationship === Relationship.Spouse;
