import { ApolloError } from "@apollo/client";
import { useCallback } from "react";
import {
  createContext,
  FC,
  PropsWithChildren,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";

import { SearchAutocompleteSearchType } from "../enums";
import type {
  BaseSearchAutocompleteResultData,
  SearchAutocompleteResult,
} from "../types";

/**
 * This contains all of the state values & setters that we hoist up into the
 * consuming context provider and pass down to `SearchAutocompleteProvider` as
 * props. See the comment for `useSearchAutocompleteState` below for more info
 * on why we need to hoist these up in the first place and some suggestions for
 * how we could eliminate the need to do this.
 */
export type SearchAutocompleteState<
  T extends BaseSearchAutocompleteResultData = BaseSearchAutocompleteResultData,
> = {
  searchInputValue: string;
  setSearchInputValue: (_: string) => void;
  searchType: SearchAutocompleteSearchType;
  setSearchType: (_: SearchAutocompleteSearchType) => void;
  selectedSearchResult: SearchAutocompleteResult<T> | undefined;
  setSelectedSearchResult: (_: SearchAutocompleteResult<T> | undefined) => void;
};

/**
 * This is a convenience hook that sets up the required state values & setters
 * so we can also have access to them in the consuming context provider. For
 * example:
 *
 * - In `MemberSearchProvider` we need access to `searchInputValue` so we can
 *   pass it in as a variable to the member search GraphQL query.
 *
 * - In `DrugSearchProvider` we need access to `searchType` and `setSearchType`
 *   so we can sync up the internal `searchType` with its `drugSearchType`
 *   value. We completely ignore the `searchInputValue` state value / setter
 *   here because we store this value in a URL query param, which illustrates
 *   the need to replace the current interface with something more streamlined.
 *
 * This would all be much simpler if we split out the free-text search feature
 * from the autocomplete feature. If we did that, we would only need to hoist
 * up `searchInputValue` and `setSearchInputValue`. Even those only need to be
 * hoisted because we use `searchInputValue` in the consuming context provider.
 * Instead of hoisting them up and using them directly in a GraphQL query, we
 * could add a function that takes the search input value as an argument and
 * returns those values. For example:
 *
 *   const getSearchQueryResult = (searchQuery: string) => {
 *     const { loading, error, data } = useSomeQuery({
 *       variables: { searchQuery },
 *       skip: searchQuery.length === 0,
 *     })
 *
 *     const searchResults = data?.queryResult?.searchResults;
 *
 *     return { loading, error, searchResults };
 *   }
 *
 * This would allow us to remove most of the props that we currently pass to
 * `SearchAutocompleteProvider` in favor of this function, and we wouldn't need
 * to hoist any of these state values / setters up into the consuming context
 * provider.
 */
export const useSearchAutocompleteState = <
  T extends BaseSearchAutocompleteResultData = BaseSearchAutocompleteResultData,
>(
  initialValues?: Partial<
    Pick<
      SearchAutocompleteState<T>,
      "searchInputValue" | "searchType" | "selectedSearchResult"
    >
  >
): SearchAutocompleteState<T> => {
  const [searchInputValue, setSearchInputValue] = useState<string>(
    initialValues?.searchInputValue ?? ""
  );

  const [searchType, setSearchType] = useState<SearchAutocompleteSearchType>(
    initialValues?.searchType ?? SearchAutocompleteSearchType.NONE
  );

  const [selectedSearchResult, setSelectedSearchResult] = useState<
    SearchAutocompleteResult<T> | undefined
  >(initialValues?.selectedSearchResult ?? undefined);

  /**
   * Currently the internal state is a bit more complex than it probably needs
   * to be, and we have multiple state values that all need to be kept in sync
   * in order to accurately represent certain states. This wrapper function is
   * a side-effect of that problem. When we call `setSelectedSearchResult` with
   * an `undefined` value (i.e. clearing the selected search result), we also
   * need to set `searchType` to `NONE`. When we call `setSelectedSearchResult`
   * with a result, we also need to (1.) update `searchInputValue` to match the
   * new result's label, and (2.) set `searchType` to `RESULT_SELECTED`. See
   * `features/searchAutocomplete/enums/index.ts` for a more thorough breakdown
   * of the `SearchAutocompleteSearchType` values and what each one means.
   *
   * This is only required because of the "free-text" search option; if we were
   * to split that out into a separate component we could remove `searchType`
   * entirely since we would only have `RESULT_SELECTED` and `NONE` left, and
   * we can determine those states based on whether `selectedSearchResult` is
   * set or not.
   *
   * We could also remove the `setSearchInputValue` call if we were to add a
   * "tag" component that is rendered inside the search input and displays the
   * selected result. Then the `SearchAutocomplete` component could check if
   * `selectedSearchResult` is set or not, and either render the tag or show
   * the input with its value normally. We could also add an "x" button which
   * would clear the selected result and/or have the backspace key do that.
   * Then the "x" button / backspace would handle clearing out the selected
   * result instead of needing to do that in the search input `onChange`
   * handler.
   *
   * If we were to do both of these things, this wrapper function would no
   * longer be necessary and we could just remove it.
   */
  const _setSelectedSearchResult = useCallback(
    (result: SearchAutocompleteResult<T> | undefined) => {
      if (result) {
        setSearchInputValue(result.label);
        setSearchType(SearchAutocompleteSearchType.RESULT_SELECTED);
      } else {
        setSearchType(SearchAutocompleteSearchType.NONE);
      }

      setSelectedSearchResult(result);
    },
    [setSearchInputValue, setSearchType, setSelectedSearchResult]
  );

  return {
    searchInputValue,
    setSearchInputValue,
    searchType,
    setSearchType,
    selectedSearchResult,
    setSelectedSearchResult: _setSelectedSearchResult,
  };
};

/**
 * The `searchResultsLoading` and `searchResultsError` values below correspond
 * to the `loading` and `error` values returned by the GraphQL query that is
 * used in the consuming context provider to get the autocomplete results. The
 * `searchResults` value is the search results themselves, or an empty array if
 * the query is still loading or encountered an error. For example, you might
 * pass in something like `data?.searchResults ?? []` for this value.
 */
export type SearchAutocompleteQueryStateAndResults<
  T extends BaseSearchAutocompleteResultData = BaseSearchAutocompleteResultData,
> = {
  searchResultsLoading: boolean;
  searchResultsError: ApolloError | undefined;
  searchResults: SearchAutocompleteResult<T>[];
};

/**
 * These are the props that are passed to `SearchAutocompleteProvider`.
 */
type SearchAutocompleteContextProviderProps<
  T extends BaseSearchAutocompleteResultData = BaseSearchAutocompleteResultData,
> = SearchAutocompleteState<T> &
  SearchAutocompleteQueryStateAndResults<T> &
  PropsWithChildren<{
    noResultsMessage?: ReactNode;
    renderErrorMessage?: (error: ApolloError) => ReactNode;
    maxAutocompleteOptions?: number;
  }>;

/**
 * This is the context value returned by `useSearchAutocompleteContext()`.
 * Most of these are only used internally by components like:
 * - SearchAutocomplete
 * - SearchAutocompleteResultsMenu
 * - SearchAutocompleteOptionsList
 *
 * TODO - move internal values to `SearchAutocompleteInternalContextValue`
 */
export type SearchAutocompleteContextValue<
  T extends BaseSearchAutocompleteResultData = BaseSearchAutocompleteResultData,
> = SearchAutocompleteState<T> &
  SearchAutocompleteQueryStateAndResults<T> & {
    searchResultsForAutocomplete: SearchAutocompleteResult<T>[];
    noResultsMessage?: ReactNode;
    renderErrorMessage: (error: ApolloError) => ReactNode;
    shouldDisplayAutocomplete: boolean;
    setShouldDisplayAutocomplete: (_: boolean) => void;
    shouldDisplaySearchResults: boolean;
  };

export const createSearchAutocompleteContextDefaultValue = <
  T extends BaseSearchAutocompleteResultData = BaseSearchAutocompleteResultData,
>(): SearchAutocompleteContextValue<T> => ({
  searchResultsLoading: false,
  searchResultsError: undefined,
  searchResults: [],
  searchResultsForAutocomplete: [],
  searchInputValue: "",
  setSearchInputValue: (_) => {},
  searchType: SearchAutocompleteSearchType.NONE,
  setSearchType: (_) => {},
  selectedSearchResult: undefined,
  setSelectedSearchResult: (_) => {},
  shouldDisplayAutocomplete: false,
  setShouldDisplayAutocomplete: (_) => {},
  shouldDisplaySearchResults: false,
  noResultsMessage: (
    <FormattedMessage defaultMessage="No results found" id="hX5PAb" />
  ),
  renderErrorMessage: (_) => _.message,
});

/**
 * In order to pass in a generic autocomplete result type for the context value
 * type (`SearchAutocompleteContextValue`) and related types defined above, we
 * have to wrap them up into a function that creates the context provider and
 * consumer (`SearchAutocompleteProvider` and `useSearchAutocompleteContext`).
 * Previously we had a prop named `useSearchAutocompleteContext` that was
 * passed around to internal components so they could get access to various
 * context values, but we don't actually care what the autocomplete result type
 * is in any of those internal components, that only matters for the consuming
 * context provider and component(s). This is a hacky workaround so we can
 * avoid "prop-drilling" (passing props around from one component to the next,
 * to the next, etc.) and instead call `useSearchAutocompleteInternalContext`
 * in the internal components that need access to context values.
 */
type SearchAutocompleteInternalContextValue = {
  __searchAutocompleteInternalContext__: SearchAutocompleteContextValue;
};

const SearchAutocompleteInternalContext =
  createContext<SearchAutocompleteInternalContextValue>({
    __searchAutocompleteInternalContext__:
      createSearchAutocompleteContextDefaultValue(),
  });

export const useSearchAutocompleteInternalContext = <
  T extends BaseSearchAutocompleteResultData = BaseSearchAutocompleteResultData,
>(): SearchAutocompleteContextValue<T> => {
  const { __searchAutocompleteInternalContext__ } = useContext(
    SearchAutocompleteInternalContext
  );

  return __searchAutocompleteInternalContext__ as SearchAutocompleteContextValue<T>;
};

/**
 * This is the result of the `createSearchAutocompleteContext` factory function
 * that creates the context provider and consumer.
 */
export type SearchAutocompleteContextResult<
  T extends BaseSearchAutocompleteResultData = BaseSearchAutocompleteResultData,
> = {
  Provider: FC<SearchAutocompleteContextProviderProps<T>>;
  useContext: () => SearchAutocompleteContextValue<T>;
};

export const createSearchAutocompleteContext = <
  T extends BaseSearchAutocompleteResultData = BaseSearchAutocompleteResultData,
>(): SearchAutocompleteContextResult<T> => {
  const SearchAutocompleteContext = createContext<
    SearchAutocompleteContextValue<T>
  >(createSearchAutocompleteContextDefaultValue<T>());

  const useSearchAutocompleteContext = () =>
    useContext(SearchAutocompleteContext);

  const SearchAutocompleteProvider: FC<
    SearchAutocompleteContextProviderProps<T>
  > = ({
    children,
    searchResultsLoading,
    searchResultsError,
    searchResults = [],
    searchInputValue,
    setSearchInputValue,
    searchType,
    setSearchType,
    selectedSearchResult,
    setSelectedSearchResult,
    noResultsMessage = (
      <FormattedMessage defaultMessage="No results found" id="hX5PAb" />
    ),
    renderErrorMessage = (error) => error.message,
    maxAutocompleteOptions = 35,
  }) => {
    const [shouldDisplayAutocomplete, setShouldDisplayAutocomplete] =
      useState<boolean>(false);

    const shouldDisplaySearchResults = useMemo<boolean>(
      () => searchType !== SearchAutocompleteSearchType.NONE,
      [searchType]
    );

    const searchResultsForAutocomplete = useMemo<
      SearchAutocompleteResult<T>[]
    >(() => {
      if (!maxAutocompleteOptions) {
        return searchResults;
      }

      return searchResults.slice(0, maxAutocompleteOptions);
    }, [searchResults, maxAutocompleteOptions]);

    const contextValue: SearchAutocompleteContextValue<T> = {
      searchResultsLoading,
      searchResultsError,
      searchResults,
      searchResultsForAutocomplete,
      searchInputValue,
      setSearchInputValue,
      noResultsMessage,
      renderErrorMessage,
      searchType,
      setSearchType,
      selectedSearchResult,
      setSelectedSearchResult,
      shouldDisplayAutocomplete,
      setShouldDisplayAutocomplete,
      shouldDisplaySearchResults,
    };

    const internalContextValue = {
      __searchAutocompleteInternalContext__: contextValue,
    } as SearchAutocompleteInternalContextValue;

    return (
      <SearchAutocompleteContext.Provider value={contextValue}>
        <SearchAutocompleteInternalContext.Provider
          value={internalContextValue}
        >
          {children}
        </SearchAutocompleteInternalContext.Provider>
      </SearchAutocompleteContext.Provider>
    );
  };

  return {
    Provider: SearchAutocompleteProvider,
    useContext: useSearchAutocompleteContext,
  };
};
