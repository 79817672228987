import { Tab } from "@headlessui/react";
import clsx from "clsx";
import { ReactNode, useEffect, useRef, useState } from "react";

import { SpacingVariant } from "../../constants";
import {
  Badge,
  BadgeVariant,
  MARGIN_LEFT_CLASS_NAMES,
  ScreenReaderOnly,
} from "..";
import { Box } from "../Box";
import {
  MARGIN_TOP_CLASS_NAMES,
  PADDING_BOTTOM_CLASS_NAMES,
  PADDING_LEFT_CLASS_NAMES,
  PADDING_RIGHT_CLASS_NAMES,
  PADDING_TOP_CLASS_NAMES,
} from "../constants";
import { Icon, IconVariant } from "../Icon";
import {
  CUIComponent,
  CUIComponentProps,
  PaddingProps,
  TextVariant,
} from "../types";

export type Tab = {
  name: string;
  content: ReactNode;
  badgeText?: string;
  icon?: IconVariant;
  key?: string;
};

export type TabsProps = CUIComponentProps<{
  contentMargin?: SpacingVariant;
  contentPadding?: PaddingProps;
  hasUnderline?: boolean;
  isCompact?: boolean;
  leftButtonAriaLabel: string;
  onTabChange?: (index: number) => void;
  rightButtonAriaLabel: string;
  selectedIndex?: number;
  shouldUnmount?: boolean;
  tabVariant?: TabVariant;
  tabs: Tab[];
  tabsWidth?: number;
  vertical?: boolean;
  disableArrows?: boolean;
}>;

const activeTabClassNames = (tabVariant: TabVariant, vertical?: boolean) => {
  switch (tabVariant) {
    case TabVariant.Outline: {
      return [
        vertical ? "border-l-2" : "border-b-2",
        vertical ? "border-l-blue-500" : "border-b-blue-500",
        "bg-blue-100",
        "text-black",
      ];
    }
    case TabVariant.Primary: {
      return ["bg-foundation-primary", "text-textColor-onBg-primary"];
    }
  }
};

export enum TabVariant {
  Outline = "TAB_OUTLINE",
  Primary = "TAB_PRIMARY",
}

export const Tabs: CUIComponent<TabsProps> = ({
  tabs,
  selectedIndex,
  hasUnderline,
  isCompact,
  vertical,
  tabsWidth,
  testId = "Tabs",
  onTabChange,
  contentMargin,
  contentPadding,
  className,
  leftButtonAriaLabel,
  rightButtonAriaLabel,
  shouldUnmount = true,
  tabVariant = TabVariant.Outline,
  disableArrows = false,
}) => {
  const [areArrowsVisible, setAreArrowsVisible] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const tabsListRef = useRef<HTMLDivElement>(null);

  const tabClassNames: string[] = [
    "bg-none",
    "text-gray-700",
    "min-w-fit",
    isCompact ? "py-1 px-4" : "py-3 px-8",
    hasUnderline && !areArrowsVisible && !vertical ? "border-b-2" : "",
  ];

  useEffect(() => {
    const handleResize = () => {
      if (!tabsListRef.current || !wrapperRef.current) {
        return;
      }

      const wrapperStyle = getComputedStyle(wrapperRef.current);

      const widthWithoutPaddings =
        parseFloat(wrapperStyle.width) -
        parseFloat(wrapperStyle.paddingLeft) -
        parseFloat(wrapperStyle.paddingRight);

      setAreArrowsVisible(
        !disableArrows && widthWithoutPaddings < tabsListRef.current.scrollWidth
      );
    };

    // We need this in order to trigger a first time check, since
    // resize may not occur for some users.
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [disableArrows]);

  const onArrowClick = (dir: "left" | "right" | "up" | "down") => {
    if (!tabsListRef.current) {
      return;
    }

    let tabToScrollIntoView: Element | null = null;

    if (dir === "left" || dir === "up") {
      tabToScrollIntoView = tabsListRef.current.firstElementChild;
    } else {
      tabToScrollIntoView = tabsListRef.current.lastElementChild;
    }

    if (tabToScrollIntoView) {
      tabToScrollIntoView.scrollIntoView({
        behavior: "smooth",
        // This is important: https://stackoverflow.com/questions/48634459/scrollintoview-block-vs-inline/48635751#48635751
        block: "nearest",
      });
    }
  };

  return (
    <div
      className={clsx(vertical ? "flex flex-row" : "", className)}
      data-testid={testId}
      ref={wrapperRef}
    >
      <Tab.Group
        manual
        vertical={vertical}
        selectedIndex={selectedIndex}
        onChange={(index) => onTabChange?.(index)}
      >
        <div
          className={clsx(
            "flex",
            vertical ? "items-start gap-y-2" : "items-center gap-x-2"
          )}
          style={{ width: `${tabsWidth}px` }}
        >
          {areArrowsVisible && (
            <button
              type="button"
              onClick={() => onArrowClick("left")}
              data-testid={`${testId}__LeftArrow`}
              aria-hidden
            >
              <Icon
                variant={IconVariant.CHEVRON_LEFT}
                size={SpacingVariant.S16}
              />
              <ScreenReaderOnly element="span">
                {leftButtonAriaLabel}
              </ScreenReaderOnly>
            </button>
          )}
          <Tab.List
            data-testid={`${testId}__List`}
            className={clsx(
              "flex flex-1",
              vertical ? "h-full flex-col overflow-y-auto" : "overflow-x-auto",
              vertical && hasUnderline
                ? "border-r border-borderColor-default"
                : ""
            )}
            ref={tabsListRef}
          >
            {tabs.map(({ name, badgeText, icon, key }) => (
              <Tab
                key={name}
                data-testid={`${testId}__TabItem_${key}`}
                className={({ selected }) =>
                  clsx(
                    ...tabClassNames,
                    "flex",
                    "items-center",
                    selected
                      ? activeTabClassNames(tabVariant, vertical)
                      : "border-surface-neutral"
                  )
                }
              >
                <Box
                  element="span"
                  textVariant={TextVariant.LgRegularTall}
                  className="flex flex-row items-center text-left"
                >
                  {icon && (
                    <Icon
                      variant={icon}
                      className="pr-2"
                      size={isCompact ? SpacingVariant.S24 : SpacingVariant.S32}
                    />
                  )}
                  {name}
                </Box>
                {badgeText && (
                  <Badge
                    className="ml-2"
                    iconVariant={IconVariant.INFORMATION_CIRCLE}
                    message={badgeText}
                    variant={BadgeVariant.SUBDUED}
                    size="small"
                  />
                )}
              </Tab>
            ))}
          </Tab.List>
          {areArrowsVisible && (
            <button
              type="button"
              onClick={() => onArrowClick("right")}
              data-testid={`${testId}__RightArrow`}
              aria-hidden
            >
              <Icon
                variant={IconVariant.CHEVRON_RIGHT}
                size={SpacingVariant.S16}
              />
              <ScreenReaderOnly element="span">
                {rightButtonAriaLabel}
              </ScreenReaderOnly>
            </button>
          )}
        </div>

        <Tab.Panels
          className={clsx(
            contentMargin
              ? vertical
                ? MARGIN_LEFT_CLASS_NAMES[contentMargin]
                : MARGIN_TOP_CLASS_NAMES[contentMargin]
              : null,
            vertical ? "flex flex-1" : ""
          )}
          data-testid={`${testId}__Panels`}
        >
          {tabs.map(({ content, name, key }) => (
            <Tab.Panel
              data-testid={`${testId}__TabPanel_${key}`}
              key={name}
              className={clsx(
                "rounded",
                contentPadding?.top
                  ? PADDING_TOP_CLASS_NAMES[contentPadding.top]
                  : "pt-4",
                contentPadding?.right
                  ? PADDING_RIGHT_CLASS_NAMES[contentPadding.right]
                  : "pr-4",
                contentPadding?.bottom
                  ? PADDING_BOTTOM_CLASS_NAMES[contentPadding.bottom]
                  : "pb-4",
                contentPadding?.left
                  ? PADDING_LEFT_CLASS_NAMES[contentPadding.left]
                  : "pl-4",
                vertical ? "w-full" : ""
              )}
              unmount={shouldUnmount}
            >
              <ScreenReaderOnly aria-live="polite" element="p">
                {name}
              </ScreenReaderOnly>
              {content}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
