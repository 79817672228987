import clsx from "clsx";
import { PropsWithChildren, ReactNode } from "react";

import { BoxProps } from "../Box";
import { ScreenReaderOnly } from "../ScreenReaderOnly";
import { TextAlignVariant, TextVariant } from "../types";
import { TableBodyRow } from "./TableBody";

/**
 * Type of table value content for columns (on desktop). TEXT aligns left & NUMERIC aligns right.
 *
 * See: https://polaris.shopify.com/components/data-table#alignment
 */
export enum TableContentType {
  NUMERIC = "NUMERIC",
  TEXT = "TEXT",
}

const textAlignVariants: Record<TableContentType, TextAlignVariant> = {
  [TableContentType.NUMERIC]: TextAlignVariant.RIGHT,
  [TableContentType.TEXT]: TextAlignVariant.LEFT,
};

export const textAlignForContentType = (params: {
  contentType?: TableContentType;
}): TextAlignVariant => {
  const { contentType } = params;
  // default to left aligned
  return contentType ? textAlignVariants[contentType] : TextAlignVariant.LEFT;
};

// table header or description term
export type TableKey = ReactNode;

// table body cell value or description details
export type TableValue = ReactNode;

export const baseTableDataCellBoxProps = (params: {
  cellIndex: number;
  contentType?: TableContentType;
}): BoxProps<"td"> => {
  const { cellIndex, contentType } = params;
  return {
    className: clsx(
      "md:px-4",
      "md:py-2",
      // display as table cell on desktop
      "md:table-cell",
      "md:w-auto",
      "md:max-w-none"
    ),
    element: "td",
    textVariant: TextVariant.MdRegularTall,
    key: cellIndex,
    textAlign: textAlignForContentType({ contentType }),
  };
};

/**
 * Used to add link to `tr` on desktop and `dl` on mobile, appears as first item in cell to optimize screenreader and keyboard navigation
 *
 */
export const ClickableRowControl = ({
  href,
  RowControl,
  srLabel,
}: RowControlData) => {
  const className: string = clickableRowLinkStyling;
  const srOnlyContent = (
    <ScreenReaderOnly
      testId="ClickableRowControl__ScreenReaderOnly"
      element="span"
    >
      {srLabel}
    </ScreenReaderOnly>
  );

  // return custom row control if it exists, otherwise use regular link
  if (RowControl) {
    return (
      <RowControl
        className={className}
        testId="ClickableRowControl__RowControl"
      >
        {srOnlyContent}
      </RowControl>
    );
  }

  // otherwise fallback to regular link
  return (
    <a
      className={className}
      href={href}
      data-testid="ClickableRowControl__Link"
    >
      {srOnlyContent}
    </a>
  );
};

export type RowControlProps = PropsWithChildren<{
  className: string;
  testId: string;
}>;

/**
 * Functional component that passes className & children props to some kind of link or button, with no other styles
 *
 * @example
 * ({ children, className, testId }) => <Link className={className} data-testid={testId} ... >{children}</Link>
 */
export type RowControlFC = (props: RowControlProps) => JSX.Element;

export type RowControlData = {
  srLabel: JSX.Element; // text that will be read by screenreader, should be a `<FormattedMessage>` component.
} & (
  | {
      href: string; // link the row should navigate to
      RowControl?: undefined;
    }
  | {
      href?: undefined;
      // unstyled link or button functional component for next/link, button, etc.
      RowControl: RowControlFC;
    }
);

/**
 * Adds a link to the first cell of each row, if the row has a corresponding `clickableRowData` object. Only used on desktop.
 *
 */
export const getClickableRows = (
  rows: TableBodyRow[],
  clickableRowData: RowControlData[]
): TableBodyRow[] => {
  if (rows.length !== clickableRowData.length) {
    return rows;
  }

  return rows.map((row, i) => {
    // The empty fallbacks are included because even with the early returns above, the typescript is concerned that clickableRowData[i] could be undefined and the href & srLabel props below would be missing.
    const clickableRowConfig = clickableRowData[i] || {
      href: "",
      srLabel: <></>,
    };

    const firstCellWithLink = (
      <>
        <ClickableRowControl {...clickableRowConfig} />
        {row[0]}
      </>
    );

    return [firstCellWithLink, ...row.slice(1)];
  });
};

// enables styling for buttons and links in table cells, prevents overriding of hover and focus styles
export const tableCellControlZindexStyles = clsx("relative", "z-1");

export const clickableRowLinkStyling = clsx(
  "absolute",
  "w-full",
  "h-full",
  "right-0",
  "top-0",
  "focus:outline-none"
);
