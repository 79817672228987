export enum ProviderFacilitySearchMixpanelUiIdentifierName {
  SEARCH_PAGE = "search-page",
  GUIDE_PAGE = "guide-page-location",
  LOCATION_FIELD = "location-field",
  SEARCH_BAR = "search-bar",
  SEARCH_BAR_SUGGESTIONS_LIST_OPTION = "search-bar.suggestions-list.option",
  SEARCH_BAR_DROPDOWN = "search-bar.dropdown",
  SEARCH_BAR_TAB = "search-bar.tab",
  SEARCH_BAR_SEARCH_FIELD = "search-bar.search-field",
  SEARCH_BAR_SUGGESTIONS_LIST_ALL_MATCHING_RESULTS_OPTION = "search-bar.suggestions-list.all-matching-results.option",
  SEARCH_BAR_BACK_BUTTON = "search-bar.back-button",
  SEARCH_BAR_ACCORDION_ITEM = "search-bar.accordion-item",
  SEARCH_BAR_SEARCH_BUTTON = "search-bar.search-button",
  SEARCH_BAR_SEARCH_TYPE_BUTTON = "search-bar.search-type-button",
  PROVIDER_DIRECTORY_PDF_MODAL = "provider-directory-pdf-modal",
  GENDER_FILTER = "provider-search-gender-list",
  LANGUAGE_FILTER = "provider-search-language-list",
  SEARCH_GET_CARE_NOW_LINK = "provider-search-get-care-now-link",
  SEARCH_NORMAN_MD_LINK = "provider-search-norman-md-learn-more",
  ACCEPTING_PATIENTS_CHECKBOX = "accepting-patients-checkbox",
  INCLUDE_ZERO_CARD_ONLY_CHECKBOX = "include-zero-card-only-checkbox",
  GUIDE_DOCTOR_BY_NAME = "guide-doctor-by-name",
  GUIDE_DOCTOR_BY_TYPE = "guide-doctor-by-type",
  GUIDE_FACILITY_BY_NAME = "guide-facility-by-name",
  GUIDE_FACILITY_BY_TYPE = "guide-facility-by-type",
  GUIDE_GET_CARE_NOW_LINK = "guide-get-care-now-link",
  GUIDE_VIRTUAL_CARE = "guide-virtual-care",
  GUIDE_VIRTUAL_CARE_REGISTER = "guide-virtual-care-register",
  GUIDE_VIRTUAL_CARE_LOGIN = "guide-virtual-care-login",
  GUIDE_VIRTUAL_CARE_LEARN_MORE = "provider-search-virtual-care-learn-more",
  PROVIDER_SEARCH_FEEDBACK_MODAL = "provider-search-feedback-modal",
  GUIDE_DOCTOR_BY_NAME_SEARCH_TERM = "guide-doctor-by-name-search-term",
  GUIDE_FACILITY_BY_NAME_SEARCH_TERM = "guide-facility-by-name-search-term",
  GUIDE_FACILITY_BY_TYPE_SEARCH_TERM = "guide-facility-by-type-search-term",
  DOWNLOAD_PROVIDER_DIRECTORY_MODAL = "download-provider-directory-modal",
  PROVIDER_CARD_MINI = "provider-card-mini",
  FACILITY_CARD = "facility-card",
  OFFICE_CARD = "office-card",
  PROVIDER_CARD = "provider-card",
  PROVIDER_SEARCH_MAP_HYPERLINK = "provider-search-map-hyperlink",
  PROVIDER_SEARCH_TELEPHONE_HYPERLINK = "provider-search-telephone-hyperlink",
  PROVIDER_SEARCH_MAP_PIN = "provider-search-map-pin",
  PROVIDER_SEARCH_VIRTUAL_CARE_BANNER = "provider-search-virtual-care-banner",
  PROVIDER_SEARCH_VIRTUAL_CARE_BULLETIN = "provider-search-virtual-care-bulletin",
  VIRTUAL_CARE_BULLETIN_TELADOC_REGISTER_BUTTON = "virtual-care-bulletin-teladoc-register-button",
  VIRTUAL_CARE_BULLETIN_NORMANMD_REGISTER_BUTTON = "virtual-care-bulletin-normanmd-register-button",
  VIRTUAL_FACILITY_CARD = "virtual-facility-card",
}

export enum ProviderFacilitySearchMixpanelEventName {
  CHANGE = "CHANGE",
  NO_RESULTS_FOUND = "NO_RESULTS_FOUND",
  SEARCH = "SEARCH",
}
