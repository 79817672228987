import { TypeaheadOption } from "./types";

const MIN_JARO_DISTANCE = 0.8;
export function filterOptions<T>({
  queryString,
  options,
  fuzzy = false,
  synonyms = {},
}: {
  queryString: string;
  options: TypeaheadOption<T>[];
  fuzzy?: boolean;
  // synonyms are in the form of "Option" : ["applicable", "synonyms"]
  synonyms?: { [key: string]: string[] };
}) {
  queryString = queryString.toLowerCase();

  let synonymOption = queryString;
  let bestMatch = 0;

  for (const key in synonyms) {
    const synonymsList = synonyms[key];
    synonymsList?.forEach((synonym) => {
      const match = jaroWinklerDistance(queryString, synonym);

      if (match > bestMatch && match > MIN_JARO_DISTANCE) {
        bestMatch = match;
        synonymOption = key.toLowerCase();
      }
    });
  }

  const distances: Map<string, number> = new Map();
  const filteredOptions = options.filter((o) => {
    const option = o.label.toLowerCase();
    const queryDistance = jaroWinklerDistance(option, queryString);
    const synonymDistance = jaroWinklerDistance(option, synonymOption);
    const maxDistance = Math.max(queryDistance, synonymDistance);
    if (option.includes(synonymOption)) {
      if (fuzzy) {
        // Add 1 to give priority to partial matches
        distances.set(o.label, synonymDistance + 1);
      }
      return true;
    }
    if (option.includes(queryString)) {
      if (fuzzy) {
        // Add some to give priority to partial matches, but weight it on input length
        distances.set(o.label, queryDistance + (1 * queryString.length) / 10);
      }
      return true;
    }
    if (fuzzy) {
      distances.set(o.label, maxDistance);
      return maxDistance > MIN_JARO_DISTANCE;
    }
    return false;
  });
  if (fuzzy) {
    filteredOptions.sort((a, b) => {
      const aDistance = distances.get(a.label) || 0;
      const bDistance = distances.get(b.label) || 0;
      return bDistance - aDistance;
    });
  }
  return filteredOptions;
}

function jaroDistance(s1: string, s2: string): number {
  if (s1.length === 0 || s2.length === 0) {
    return 0;
  }

  const matchDistance = Math.floor(Math.max(s1.length, s2.length) / 2) - 1;
  const s1Matches = new Array(s1.length).fill(false);
  const s2Matches = new Array(s2.length).fill(false);

  let matches = 0;
  let transpositions = 0;

  for (let i = 0; i < s1.length; i++) {
    const start = Math.max(0, i - matchDistance);
    const end = Math.min(s2.length - 1, i + matchDistance);

    for (let j = start; j <= end; j++) {
      if (s1Matches[i] || s2Matches[j]) {
        continue;
      }
      if (s1[i] !== s2[j]) {
        continue;
      }
      s1Matches[i] = true;
      s2Matches[j] = true;

      matches++;
      break;
    }
  }
  if (matches === 0) {
    return 0;
  }
  let k = 0;
  for (let i = 0; i < s1.length; i++) {
    if (!s1Matches[i]) {
      continue;
    }
    while (!s2Matches[k]) {
      k++;
    }
    if (s1[i] !== s2[k]) {
      transpositions++;
    }
    k++;
  }
  return (
    (matches / s1.length +
      matches / s2.length +
      (matches - transpositions / 2) / matches) /
    3
  );
}

function jaroWinklerDistance(s1: string, s2: string, p: number = 0.1): number {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();
  const jaro = jaroDistance(s1, s2);
  let prefixLength = 0;
  for (let i = 0; i < Math.min(s1.length, s2.length); i++) {
    if (s1[i] === s2[i]) {
      prefixLength++;
    } else {
      break;
    }
  }
  return jaro + prefixLength * p * (1 - jaro);
}
