import { isEmpty } from "lodash-es";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import * as Yup from "yup";

import {
  dateOfBirthSchema,
  getYupEmailValidation,
  phoneNumberRegex,
} from "../../utils";

export const useRxTransferFormSchema = (withMemberSelector: boolean) => {
  const { formatMessage } = useIntl();

  return useMemo(
    () =>
      Yup.object().shape(
        {
          memberId: withMemberSelector
            ? Yup.string().required(
                formatMessage({
                  defaultMessage: "Member is required",
                  id: "kB46Wk",
                })
              )
            : Yup.string().nullable(),
          memberName: withMemberSelector
            ? Yup.string().nullable()
            : Yup.string().required(
                formatMessage({
                  defaultMessage: "Member Name is required",
                  id: "eEEK9b",
                })
              ),
          memberDateOfBirth: withMemberSelector
            ? Yup.string().nullable()
            : dateOfBirthSchema({ formatMessage }).required(
                formatMessage({
                  defaultMessage: "Date of Birth is required",
                  id: "Lj4qT5",
                })
              ),
          memberEmail: getYupEmailValidation({
            formatMessage,
            isEmailRequired: !withMemberSelector,
          }),
          hasNoDrugAllergies: Yup.boolean(),
          drugAllergiesDetails: Yup.string()
            .when("hasNoDrugAllergies", {
              is: (v: string) => !v,
              then: (schema) =>
                schema.required(
                  formatMessage({
                    defaultMessage: "Drug allergy details are required",
                    id: "XBUO+C",
                  })
                ),
              otherwise: (schema) => schema.nullable(),
            })
            .nullable(),
          phoneNumber: Yup.string()
            .required("Phone number is required")
            .matches(
              phoneNumberRegex,
              formatMessage({
                defaultMessage: "Must be a valid phone number",
                id: "mtpRBp",
              })
            ),
          isMailOrderPharmacy: Yup.boolean(),
          pharmacyOption: Yup.object().nullable(),
          pharmacyName: Yup.string().required(
            formatMessage({
              defaultMessage: "Pharmacy name is required",
              id: "YsdE3b",
            })
          ),
          pharmacyPhoneNumber: Yup.string()
            .matches(
              phoneNumberRegex,
              formatMessage({
                defaultMessage: "Must be a valid phone number",
                id: "mtpRBp",
              })
            )
            .nullable()
            .when("pharmacyAddress", {
              is: isEmpty,
              then: (schema) => {
                return schema.required(
                  formatMessage({
                    defaultMessage:
                      "Pharmacy phone number OR address is required",
                    id: "JcsOMu",
                  })
                );
              },
            }),
          pharmacyAddress: Yup.string()
            .nullable()
            .when("pharmacyPhoneNumber", {
              is: isEmpty,
              then: (schema) => {
                return schema.required(
                  formatMessage({
                    defaultMessage:
                      "Pharmacy phone number OR address is required",
                    id: "JcsOMu",
                  })
                );
              },
            }),
          requestedTransferTimeline: Yup.string().required(
            formatMessage({
              defaultMessage: "Please select an option",
              id: "PPLP3R",
            })
          ),
          shouldAutomaticallyRefill: Yup.string().required(
            formatMessage({
              defaultMessage: "Please select an option",
              id: "PPLP3R",
            })
          ),
          deliveryState: Yup.string().required(
            formatMessage({
              defaultMessage: "Delivery state is required",
              id: "tG1RvD",
            })
          ),
          drugs: Yup.array()
            .of(
              Yup.object().shape({
                drug: Yup.object()
                  .test(
                    "option",
                    formatMessage({
                      defaultMessage: "Medication name is required",
                      id: "yb4hf2",
                    }),
                    (val) => !!val
                  )
                  .required(
                    formatMessage({
                      defaultMessage: "Medication name is required",
                      id: "yb4hf2",
                    })
                  ),
                prescriptionNumber: Yup.string().nullable(),
                dosage: Yup.string().nullable(),
              })
            )
            .required(
              formatMessage({
                defaultMessage: "Prescription information is required",
                id: "mA2G1/",
              })
            ),
        },
        [["pharmacyAddress", "pharmacyPhoneNumber"]] // noSortEdges argument, otherwise we get an error because the field validations depend on each other.
      ),
    [formatMessage, withMemberSelector]
  );
};
