import {
  Button,
  ButtonVariant,
  Icon,
  IconVariant,
  SpacingVariant,
} from "@chp/curative_ui";
import clsx from "clsx";
import { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";

import { VirtualCareVariant } from "../../types";
import { VirtualCareModal } from "../VirtualCareModal";

const VirtualCareButtonWrapper = ({
  buttonContent,
  className,
  variant,
  trackOpen,
  trackLogin,
  trackRegister,
  trackClick,
  allowClose,
  onClose,
  virtualCareVariant,
}: {
  buttonContent: ReactElement;
  className?: string;
  variant: ButtonVariant;
  trackOpen?: () => void;
  trackLogin?: () => void;
  trackRegister?: () => void;
  trackClick?: () => void;
  allowClose?: boolean;
  onClose?: () => void;
  virtualCareVariant?: VirtualCareVariant;
}) => {
  const [isVirtualCareModalOpen, setIsVirtualCareModalOpen] =
    useState<boolean>(false);
  return (
    <div data-testid="virtual-care-links" className="flex">
      <Button
        variant={variant}
        className={clsx(allowClose && "!rounded-r-none !pr-4", className)}
        onClick={() => {
          trackOpen?.();
          setIsVirtualCareModalOpen(true);
        }}
      >
        {buttonContent}
      </Button>
      <Button
        variant={variant}
        className={clsx(
          allowClose ? "!rounded-l-none !px-4" : "!hidden",
          className
        )}
        onClick={onClose}
      >
        <Icon variant={IconVariant.X} size={SpacingVariant.S24} />
      </Button>
      <VirtualCareModal
        isOpen={isVirtualCareModalOpen}
        onRequestClose={() => setIsVirtualCareModalOpen(false)}
        trackLogin={trackLogin}
        trackRegister={trackRegister}
        trackClick={trackClick}
        virtualCareVariant={virtualCareVariant}
      />
    </div>
  );
};

export const VirtualCareButtonRed = ({
  className,
  trackOpen,
  trackLogin,
  trackRegister,
  trackClick,
  allowClose,
  onClose,
  virtualCareVariant,
}: {
  className?: string;
  trackOpen?: () => void;
  trackLogin?: () => void;
  trackRegister?: () => void;
  trackClick?: () => void;
  allowClose?: boolean;
  onClose?: () => void;
  virtualCareVariant: VirtualCareVariant;
}) => {
  const buttonContent = (
    <div className="text-bold flex text-lg">
      <FormattedMessage defaultMessage="Get Care Now 24/7" id="BPZqxx" />
    </div>
  );

  return (
    <VirtualCareButtonWrapper
      buttonContent={buttonContent}
      className={clsx(
        "font-lg !border-0 !bg-[#fc5c50] font-bold hover:!bg-[#b8332a] focus:!bg-[#b8332a]",
        className
      )}
      variant={ButtonVariant.BigPrimary}
      trackOpen={trackOpen}
      trackLogin={trackLogin}
      trackRegister={trackRegister}
      trackClick={trackClick}
      allowClose={allowClose}
      onClose={onClose}
      virtualCareVariant={virtualCareVariant}
    />
  );
};

export const VirtualCareGetCareNowButton = ({
  className,
  virtualCareVariant,
}: {
  className?: string;
  virtualCareVariant: VirtualCareVariant;
}) => {
  const buttonContent = (
    <div className="text-left underline">
      <FormattedMessage defaultMessage="Get care now" id="3bI255" />
    </div>
  );

  return (
    <VirtualCareButtonWrapper
      buttonContent={buttonContent}
      className={className}
      variant={ButtonVariant.Flat}
      virtualCareVariant={virtualCareVariant}
    />
  );
};

export const VirtualCareButtonWithIcon = ({
  className,
  virtualCareVariant,
}: {
  className?: string;
  virtualCareVariant: VirtualCareVariant;
}) => {
  const buttonContent = (
    <div className="text-bold flex text-lg">
      <Icon variant={IconVariant.STETHOSCOPE} size={SpacingVariant.S24} />
      <div className="pl-2">
        <FormattedMessage defaultMessage="Need care now?" id="8gxgti" />
      </div>
    </div>
  );

  return (
    <VirtualCareButtonWrapper
      buttonContent={buttonContent}
      className={className}
      variant={ButtonVariant.Flat}
      virtualCareVariant={virtualCareVariant}
    />
  );
};
