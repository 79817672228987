import {
  Box,
  DisplayVariant,
  Icon,
  IconVariant,
  SpacingVariant,
  TextAlignVariant,
  textColorsCommon,
  TextVariant,
  VerticalAlignVariant,
} from "@chp/curative_ui";
import { linkBaseClassNamesWithUnderline } from "@chp/curative_ui/src/utils";
import {
  claimStatusDisplayNames,
  FormattedDisclosureCard,
  FormattedDisclosureCardProps,
  FormattedPlainDate,
  FormattedPlainDateVariant,
  MemberPortalMixpanelUiIdentifierName,
  PlainDate,
  SharedMixpanelEventName,
  useFormatNumber,
  useMemberPortalMixpanelContext,
} from "@chp/shared";
import {
  ClaimStatus,
  ClaimType,
} from "@chp/shared/generated/memberPortalApi.graphql";
import clsx from "clsx";
import Link from "next/link";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

const TBD_STRING = "$_.__";

export type ClaimCardProps = {
  allowableAmount: number | null | undefined;
  claimId?: string | number;
  claimStatus: ClaimStatus;
  claimType: ClaimType;
  curativePaysAmount: number | null | undefined;
  eobUrl?: string;
  outOfPocketAmount: number | null | undefined;
  providerChargedAmount: number | null | undefined;
  providerName: string | undefined;
  serviceDate: PlainDate;
};

const ClaimId = ({ claimId }: Pick<ClaimCardProps, "claimId">): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <dl>
      <Box display={DisplayVariant.Inline} element="dt">
        <FormattedMessage defaultMessage="Claim #:" id="l1FWPi" />
      </Box>{" "}
      <Box display={DisplayVariant.Inline} element="dd">
        {claimId || formatMessage({ defaultMessage: "TBD", id: "UE+7ux" })}
      </Box>
    </dl>
  );
};

const ClaimStatusContainer = ({
  claimStatus,
}: Pick<ClaimCardProps, "claimStatus">): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <dl>
      <Box display={DisplayVariant.Inline} element="dt">
        <FormattedMessage defaultMessage="Status:" id="3fVD9R" />
      </Box>{" "}
      <Box
        className={clsx(textColorsCommon.highlightDefault)}
        display={DisplayVariant.Inline}
        element="dd"
      >
        {claimStatusDisplayNames({ formatMessage })[claimStatus]}
      </Box>
    </dl>
  );
};

const EobLink = ({
  eobUrl,
}: Pick<ClaimCardProps, "eobUrl">): JSX.Element | null => {
  return eobUrl ? (
    <Link
      className={clsx(linkBaseClassNamesWithUnderline)}
      href={eobUrl}
      target="_blank"
    >
      <Box
        display={DisplayVariant.InlineBlock}
        element="span"
        margin={{ right: SpacingVariant.S4 }}
        verticalAlign={VerticalAlignVariant.Middle}
      >
        <Icon size={SpacingVariant.S16} variant={IconVariant.DOWNLOAD} />
      </Box>
      <FormattedMessage defaultMessage="Explanation Of Benefits" id="+sTF+c" />
    </Link>
  ) : null;
};

const OutOfPocketAmount = ({
  outOfPocketAmount,
}: Pick<ClaimCardProps, "outOfPocketAmount">): JSX.Element => {
  const { formatCurrency } = useFormatNumber();
  return (
    <Box
      className={clsx(
        textColorsCommon.onBgPrimary,
        "bg-foundation-primary",
        "flex",
        "flex-col",
        "h-full",
        "justify-center",
        "rounded"
      )}
      element="dl"
      padding={{
        bottom: SpacingVariant.S8,
        left: SpacingVariant.S8,
        right: SpacingVariant.S8,
        top: SpacingVariant.S8,
      }}
      textAlign={TextAlignVariant.CENTER}
    >
      <dt>
        <FormattedMessage defaultMessage="Out-of-Pocket" id="gMsQZk" />
      </dt>
      <dd>
        {formatCurrency({ value: outOfPocketAmount, tbdString: TBD_STRING })}
      </dd>
    </Box>
  );
};

const ProviderName = ({
  providerName,
  textVariant = TextVariant.MdBold,
}: Pick<ClaimCardProps, "providerName"> & {
  textVariant?: TextVariant;
}): JSX.Element => (
  <Box display={DisplayVariant.InlineBlock} element="p">
    <Box
      display={DisplayVariant.InlineBlock}
      element="span"
      margin={{ right: SpacingVariant.S4 }}
      verticalAlign={VerticalAlignVariant.Middle}
    >
      <Icon size={SpacingVariant.S20} variant={IconVariant.CLIPBOARD_HEART} />
    </Box>
    <Box
      element="span"
      textVariant={textVariant}
      verticalAlign={VerticalAlignVariant.Middle}
    >
      {providerName}
    </Box>
  </Box>
);

const ServiceDate = ({
  serviceDate,
  variant = FormattedPlainDateVariant.NUMERIC,
}: Pick<ClaimCardProps, "serviceDate"> & {
  variant?: FormattedPlainDateVariant;
}): JSX.Element => (
  <Box display={DisplayVariant.InlineBlock} element="p">
    {variant === FormattedPlainDateVariant.TEXT && (
      <Box
        display={DisplayVariant.InlineBlock}
        element="span"
        margin={{ right: SpacingVariant.S4 }}
        verticalAlign={VerticalAlignVariant.Middle}
      >
        <Icon size={SpacingVariant.S20} variant={IconVariant.CALENDAR} />
      </Box>
    )}
    <Box
      display={DisplayVariant.InlineBlock}
      element="span"
      verticalAlign={VerticalAlignVariant.Middle}
    >
      <FormattedPlainDate value={serviceDate} variant={variant} />
    </Box>
  </Box>
);

const LargeScreenLeadingContent = ({
  claimId,
  claimStatus,
  eobUrl,
}: Pick<ClaimCardProps, "claimId" | "eobUrl" | "claimStatus">): JSX.Element => (
  <>
    <Box element="div" margin={{ bottom: SpacingVariant.S8 }}>
      <ClaimId claimId={claimId} />
    </Box>
    <Box element="div" margin={{ bottom: SpacingVariant.S8 }}>
      <ClaimStatusContainer claimStatus={claimStatus} />
    </Box>
    <EobLink eobUrl={eobUrl} />
  </>
);

const LargeScreenTrailingContent = ({
  allowableAmount,
  curativePaysAmount,
  outOfPocketAmount,
  providerChargedAmount,
  providerName,
  serviceDate,
}: Pick<
  ClaimCardProps,
  | "allowableAmount"
  | "curativePaysAmount"
  | "outOfPocketAmount"
  | "providerChargedAmount"
  | "providerName"
  | "serviceDate"
>): JSX.Element => {
  const { formatCurrency } = useFormatNumber();

  return (
    <div
      className={clsx(
        "gap-x-1",
        "grid",
        "grid-cols-12",
        "h-full",
        "min-h-[88px]"
      )}
    >
      <div
        className={clsx(
          "col-span-9",
          "lg:col-span-10",
          "flex",
          "flex-col",
          "justify-between"
        )}
      >
        <Box element="div" margin={{ bottom: SpacingVariant.S16 }}>
          <Box
            display={DisplayVariant.InlineBlock}
            element="div"
            margin={{ right: SpacingVariant.S16 }}
          >
            <ProviderName
              providerName={providerName}
              textVariant={TextVariant.LgBoldShort}
            />
          </Box>

          <ServiceDate
            serviceDate={serviceDate}
            variant={FormattedPlainDateVariant.TEXT}
          />
        </Box>

        <div className={clsx("gap-x-1", "grid", "grid-cols-12")}>
          <dl className="col-span-4">
            <Box
              display={DisplayVariant.InlineBlock}
              element="dt"
              margin={{ right: SpacingVariant.S8 }}
            >
              <FormattedMessage defaultMessage="Provider Charged" id="AnSh0V" />
            </Box>
            <Box display={DisplayVariant.InlineBlock} element="dd">
              {formatCurrency({
                value: providerChargedAmount,
                tbdString: TBD_STRING,
              })}
            </Box>
          </dl>

          <dl className="col-span-4">
            <Box
              display={DisplayVariant.InlineBlock}
              element="dt"
              margin={{ right: SpacingVariant.S8 }}
            >
              <FormattedMessage defaultMessage="Allowable Amount" id="Envpgp" />
            </Box>
            <Box display={DisplayVariant.InlineBlock} element="dd">
              {formatCurrency({
                value: allowableAmount,
                tbdString: TBD_STRING,
              })}
            </Box>
          </dl>

          <dl className="col-span-4">
            <Box
              display={DisplayVariant.InlineBlock}
              element="dt"
              margin={{ right: SpacingVariant.S8 }}
            >
              <FormattedMessage defaultMessage="Curative Pays" id="PMt1sx" />
            </Box>
            <Box display={DisplayVariant.InlineBlock} element="dd">
              {formatCurrency({
                value: curativePaysAmount,
                tbdString: TBD_STRING,
              })}
            </Box>
          </dl>
        </div>
      </div>

      <div className={clsx("col-span-3", "lg:col-span-2")}>
        <OutOfPocketAmount outOfPocketAmount={outOfPocketAmount} />
      </div>
    </div>
  );
};

const SmallScreenLeadingContent = ({
  claimId,
  claimStatus,
  providerChargedAmount,
  providerName,
  serviceDate,
}: Pick<
  ClaimCardProps,
  | "claimId"
  | "claimStatus"
  | "providerChargedAmount"
  | "providerName"
  | "serviceDate"
>): JSX.Element => {
  const { formatCurrency } = useFormatNumber();
  return (
    <>
      <Box element="div" margin={{ bottom: SpacingVariant.S8 }}>
        <div className={clsx("gap-x-1", "grid", "grid-cols-12")}>
          <div className="col-span-8">
            <ProviderName providerName={providerName} />
          </div>

          <Box
            className="col-span-4"
            element="div"
            textAlign={TextAlignVariant.RIGHT}
          >
            <ServiceDate serviceDate={serviceDate} />
          </Box>
        </div>
      </Box>

      <div className={clsx("gap-x-1", "gap-y-1", "grid", "grid-cols-12")}>
        <div className="col-span-8">
          <ClaimId claimId={claimId} />
        </div>

        <Box
          className={clsx("col-span-4")}
          element="div"
          textAlign={TextAlignVariant.RIGHT}
        >
          <ClaimStatusContainer claimStatus={claimStatus} />
        </Box>

        <dl
          className={clsx(
            "col-span-8",
            "gap-x-1",
            "gap-y-1",
            "grid",
            "grid-cols-12"
          )}
        >
          <dt className="col-span-7">
            <FormattedMessage defaultMessage="Provider Charged" id="AnSh0V" />
          </dt>
          <dd className="col-span-5">
            {formatCurrency({
              value: providerChargedAmount,
              tbdString: TBD_STRING,
            })}
          </dd>
        </dl>
      </div>
    </>
  );
};

const SmallScreenTrailingContent = ({
  allowableAmount,
  curativePaysAmount,
  eobUrl,
  outOfPocketAmount,
}: Pick<
  ClaimCardProps,
  "allowableAmount" | "curativePaysAmount" | "eobUrl" | "outOfPocketAmount"
>): JSX.Element => {
  const { formatCurrency } = useFormatNumber();

  return (
    <>
      <div className={clsx("gap-x-1", "gap-y-1", "grid", "grid-cols-12")}>
        <div className="col-span-8">
          <Box
            className={clsx("gap-x-1", "gap-y-1", "grid", "grid-cols-12")}
            element="dl"
            margin={{ bottom: SpacingVariant.S4 }}
          >
            <dt className="col-span-7">
              <FormattedMessage defaultMessage="Allowable Amount" id="Envpgp" />
            </dt>
            <dd className="col-span-5">
              {formatCurrency({
                value: allowableAmount,
                tbdString: TBD_STRING,
              })}
            </dd>

            <dt className="col-span-7">
              <FormattedMessage defaultMessage="Curative Pays" id="PMt1sx" />
            </dt>
            <dd className="col-span-5">
              {formatCurrency({
                value: curativePaysAmount,
                tbdString: TBD_STRING,
              })}
            </dd>
          </Box>

          <EobLink eobUrl={eobUrl} />
        </div>

        <div className="col-span-4">
          <OutOfPocketAmount outOfPocketAmount={outOfPocketAmount} />
        </div>
      </div>
    </>
  );
};

const ClaimCard = ({
  allowableAmount,
  claimId,
  claimStatus,
  claimType,
  curativePaysAmount,
  eobUrl,
  outOfPocketAmount,
  providerChargedAmount,
  providerName,
  serviceDate,
}: ClaimCardProps): JSX.Element => {
  const { trackEvent } = useMemberPortalMixpanelContext();
  const { formatMessage } = useIntl();

  const claimTitle = providerName
    ? providerName
    : claimType === ClaimType.Pharmacy
    ? formatMessage({
        defaultMessage: "Rx Pharmacy",
        id: "KW5nEm",
      })
    : "";

  const largeScreenContent: FormattedDisclosureCardProps["largeScreenContent"] =
    useMemo(
      () => ({
        leadingContent: (
          <LargeScreenLeadingContent
            claimId={claimId}
            claimStatus={claimStatus}
            eobUrl={eobUrl}
          />
        ),
        trailingContent: (
          <LargeScreenTrailingContent
            allowableAmount={allowableAmount}
            curativePaysAmount={curativePaysAmount}
            outOfPocketAmount={outOfPocketAmount}
            providerChargedAmount={providerChargedAmount}
            providerName={claimTitle}
            serviceDate={serviceDate}
          />
        ),
      }),
      [
        allowableAmount,
        claimId,
        claimStatus,
        claimTitle,
        curativePaysAmount,
        eobUrl,
        outOfPocketAmount,
        providerChargedAmount,
        serviceDate,
      ]
    );
  const smallScreenContent: FormattedDisclosureCardProps["smallScreenContent"] =
    useMemo(
      () => ({
        leadingContent: (
          <SmallScreenLeadingContent
            claimId={claimId}
            claimStatus={claimStatus}
            providerChargedAmount={providerChargedAmount}
            providerName={claimTitle}
            serviceDate={serviceDate}
          />
        ),
        trailingContent: (
          <SmallScreenTrailingContent
            allowableAmount={allowableAmount}
            curativePaysAmount={curativePaysAmount}
            eobUrl={eobUrl}
            outOfPocketAmount={outOfPocketAmount}
          />
        ),
      }),
      [
        allowableAmount,
        claimId,
        claimStatus,
        claimTitle,
        curativePaysAmount,
        eobUrl,
        outOfPocketAmount,
        providerChargedAmount,
        serviceDate,
      ]
    );

  return (
    <FormattedDisclosureCard
      largeScreenContent={largeScreenContent}
      smallScreenContent={smallScreenContent}
      onButtonClick={() =>
        trackEvent({
          uiIdentifier:
            MemberPortalMixpanelUiIdentifierName.CLAIMS_PAGE_MOBILE_DISCLOSURE_CARD,
          eventName: SharedMixpanelEventName.CLICK,
        })
      }
    />
  );
};

export type ClaimCardListProps = {
  claims: ClaimCardProps[];
  testId?: string;
};

export const ClaimCardList = ({
  claims,
  testId = "ClaimCardList",
}: ClaimCardListProps): JSX.Element => {
  return (
    <Box
      className={clsx(textColorsCommon.default)}
      element="ul"
      testId={testId}
      textVariant={TextVariant.MdRegularTall}
    >
      {claims.map((claim) => (
        <Box
          element="li"
          key={claim.claimId}
          margin={{ bottom: SpacingVariant.S12 }}
          testId={`${testId}__ListItem`}
        >
          <ClaimCard {...claim} />
        </Box>
      ))}
    </Box>
  );
};
