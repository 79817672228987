import clsx from "clsx";

import { SpacingVariant } from "../../constants";
import { linkBaseClassNames } from "../../utils";
import { Box, BoxProps } from "../Box";
import { textColorsCommon } from "../colors";
import { headingLevelNext } from "../heading";
import { Icon, IconProps, IconVariant } from "../Icon";
import { flexCommon, flexShrinkCommon } from "../layouts";
import {
  CUIComponent,
  CUIComponentProps,
  DisplayVariant,
  HeadingLevel,
  TextVariant,
} from "../types";

type DocumentListVariant = "normal" | "fit-content";

export type Document = Required<Pick<BoxProps<"a">, "href">> & {
  // list of content for paragraphs
  description?: Array<{
    // single paragraph content, without p tags
    content: JSX.Element | string;
    // unique array key for item in description list
    key: string | number;
  }>;
  // unique array key for item in document list
  key: string | number;
  // link rel attribute
  rel?: string;
  title: JSX.Element | string;
};

export type DocumentListProps = CUIComponentProps<
  {
    documents: Document[];
    variant?: DocumentListVariant;
  } & (
    | {
        heading: JSX.Element | string;
        headingLevel: HeadingLevel;
        linkLevel?: undefined;
      }
    | {
        heading?: undefined;
        headingLevel?: undefined;
        linkLevel: HeadingLevel;
      }
  )
>;

const DocumentDescription = ({
  description,
  testId,
}: Pick<Document, "description"> & {
  testId: string;
}): JSX.Element | null => {
  if (!description) return null;

  return (
    <Box
      className={clsx(textColorsCommon.subdued)}
      element="div"
      padding={{ left: SpacingVariant.S24, right: SpacingVariant.S24 }}
      textVariant={TextVariant.SmRegularTall}
      testId={testId}
    >
      {description.map(({ key, content }) => (
        <Box
          element="p"
          key={key}
          margin={{
            bottom: SpacingVariant.S8,
            left: SpacingVariant.S4,
            right: SpacingVariant.S4,
          }}
        >
          {content}
        </Box>
      ))}
    </Box>
  );
};

const IconContainer = ({
  className,
  variant,
}: Pick<BoxProps<"div">, "className"> &
  Pick<IconProps, "variant">): JSX.Element => {
  return (
    <Box className={clsx(flexShrinkCommon.shrink0, className)} element="div">
      <Icon size={SpacingVariant.S24} variant={variant} />
    </Box>
  );
};

const DocumentListItem = ({
  description,
  href,
  linkLevel,
  rel = "nofollow noopener noreferrer",
  title,
  testId,
  variant,
}: Required<Pick<BoxProps<"li">, "testId">> &
  Document & {
    linkLevel: HeadingLevel;
    variant: DocumentListVariant;
  }): JSX.Element => {
  return (
    <Box element="li" margin={{ bottom: SpacingVariant.S16 }} testId={testId}>
      <Box
        className={clsx(
          linkBaseClassNames,
          variant === "fit-content" && "gap-x-24"
        )}
        display={DisplayVariant.FLEX}
        element="a"
        href={href}
        margin={{
          bottom: SpacingVariant.S4,
        }}
        rel={rel}
        target="_blank"
        testId={`${testId}__Link`}
      >
        <Box
          element="div"
          display={DisplayVariant.FLEX}
          className={clsx(flexCommon.flex1)}
        >
          <IconContainer
            className={clsx(textColorsCommon.subdued)}
            variant={IconVariant.DOCUMENT}
          />
          <Box
            className={clsx(flexCommon.flex1)}
            element={linkLevel}
            margin={{
              left: SpacingVariant.S4,
              right: SpacingVariant.S4,
            }}
          >
            {title}
          </Box>
        </Box>
        <IconContainer variant={IconVariant.DOWNLOAD} />
      </Box>
      <DocumentDescription
        description={description}
        testId={`${testId}__Description`}
      />
    </Box>
  );
};

/**
 * List of documents with their URLs.
 *
 * See: https://tailwindui.com/components/application-ui/lists/stacked-lists
 */
export const DocumentList: CUIComponent<DocumentListProps> = ({
  className,
  variant = "normal",
  documents,
  heading,
  headingLevel,
  linkLevel,
  testId = "DocumentList",
}) => {
  // either headingLevel or linkLevel should be defined
  const hasHeading: boolean = !!headingLevel;
  const linkHeadingLevel: HeadingLevel = headingLevel
    ? headingLevelNext[headingLevel]
    : (linkLevel as HeadingLevel);

  return (
    <Box
      className={clsx(
        textColorsCommon.default,
        variant === "fit-content" && "w-fit",
        className
      )}
      element="div"
      testId={testId}
      textVariant={TextVariant.MdRegularTall}
    >
      {hasHeading && headingLevel && (
        <Box
          element={headingLevel}
          margin={{ bottom: SpacingVariant.S16 }}
          textVariant={TextVariant.LgBoldShort}
          testId={`${testId}__Heading`}
        >
          {heading}
        </Box>
      )}
      <Box element="ul" testId={`${testId}__List`}>
        {documents.map(({ key, ...rest }) => (
          <DocumentListItem
            key={key}
            linkLevel={linkHeadingLevel}
            testId={`${testId}__ListItem`}
            variant={variant}
            {...rest}
          />
        ))}
      </Box>
    </Box>
  );
};
