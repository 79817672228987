import { EmailBlockingConfiguration } from "@chp/shared/types";
import { validate } from "email-validator";
import { IntlShape } from "react-intl";
import * as Yup from "yup";

import { MEMBER_EMAIL_MAX_LENGTH } from "../../constants";

/** Determines if email should be blocked based on LD configuration. This is to block emails with certain domains such as email ending with "@curative.com" in Prod */
export const validateEmailDomain = (
  emailInput: string | null | undefined,
  emailBlockingConfig: EmailBlockingConfiguration
) => {
  // if the value is null, we pass this check because it will be handled by the required check
  if (!emailInput) {
    return true;
  }

  const valueLowerCase = emailInput.toLowerCase();

  if (
    emailBlockingConfig.whitelistedPatterns.find((whiteListedPattern) =>
      valueLowerCase.match(whiteListedPattern)
    )
  ) {
    return true;
  }

  if (
    emailBlockingConfig.blockedPatterns.find((blockedPattern) =>
      valueLowerCase.match(blockedPattern)
    )
  ) {
    return false;
  }

  return true;
};

export const getYupEmailValidation = ({
  formatMessage,
  isEmailRequired,
}: {
  formatMessage: IntlShape["formatMessage"];
  isEmailRequired?: boolean;
}) => {
  return Yup.string()
    .trim()
    .test(
      "is-valid",
      formatMessage({
        defaultMessage: "Enter a valid email.",
        id: "rmXLgt",
      }),
      (val) => {
        if (!val) {
          return true;
        }

        // Validation logic: https://github.com/manishsaraan/email-validator/blob/master/index.js
        return validate(val);
      }
    )
    .max(
      MEMBER_EMAIL_MAX_LENGTH,
      formatMessage(
        {
          defaultMessage:
            "Email must be {maxLength, number} characters or less.",
          id: "8c7emK",
        },
        {
          maxLength: MEMBER_EMAIL_MAX_LENGTH,
        }
      )
    )
    .test(
      "is-email-required",
      formatMessage({
        defaultMessage: "Email address is required.",
        id: "qy6KOp",
      }),
      (val) => {
        return !isEmailRequired ? true : !!val;
      }
    )
    .nullable();
};
