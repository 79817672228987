import { SpacingVariant } from "../constants";
import { PaddingSize } from "../types";

export type CommonBackgroundColor =
  | "depressed"
  | "subdued"
  | "surfaceHighlight"
  | "surfaceNeutral";
export type CommonTextColor =
  | "default"
  | "highlightDefault"
  | "onBgPrimary"
  | "subdued"
  | "warning";

export type CUIComponentProps<T> = T & {
  className?: string;
  testId?: string;
};

export type CUIComponent<T> = (
  props: CUIComponentProps<T>
) => JSX.Element | null;

export enum DisplayVariant {
  Block = "BLOCK",
  FLEX = "FLEX",
  Inline = "INLINE",
  InlineBlock = "INLINE_BLOCK",
}

export enum FormFieldLayout {
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL",
}

export type FormFieldBaseProps = {
  description?: string;
  // errorMessage is usually rendered inside a <p> tag. only use values that would be valid permitted content for <p>.
  // see: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/p
  // see: https://developer.mozilla.org/en-US/docs/Web/HTML/Content_categories#phrasing_content
  errorMessage?: string | JSX.Element | null;
  isLabelSrOnly?: boolean;
  label: string | JSX.Element | null;
  // horizontal layout has limited support. check if it is supported before you use it.
  layout?: FormFieldLayout;
};

export type HeadingLevel = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export enum Locale {
  ENGLISH = "ENGLISH",
  SPANISH = "SPANISH",
}

export type MarginProps = {
  bottom?: SpacingVariant;
  left?: SpacingVariant;
  right?: SpacingVariant;
  top?: SpacingVariant;
};

/** @deprecated See PaddingSize */
export type PaddingProps = PaddingSize;

export enum TextVariant {
  XsRegular = "XS_REGULAR",
  SmRegularTall = "SM_REGULAR_TALL",
  MdRegularTall = "MD_REGULAR_TALL",
  LgRegular = "L_REGULAR",
  LgRegularTall = "L_REGULAR_TALL",
  XlRegular = "XL_REGULAR",
  XlRegularTall = "XL_REGULAR_TALL",
  "2XlRegular" = "2XL_REGULAR",
  "3XlRegular" = "3XL_REGULAR",
  "4XlRegular" = "4XL_REGULAR",
  XsBold = "XS_BOLD",
  SmBold = "SM_BOLD",
  SmBoldTall = "SM_BOLD_TALL",
  MdBold = "MD_BOLD",
  LgBoldShort = "L_BOLD_SHORT",
  XlBold = "XL_BOLD",
  XlBoldTall = "XL_BOLD_TALL",
  "2XlBold" = "2XL_BOLD",
  "3XlBold" = "3XL_BOLD",
  "4XlBold" = "4XL_BOLD",
}

export enum TextAlignVariant {
  CENTER = "CENTER",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum VerticalAlignVariant {
  Bottom = "BOTTOM",
  Middle = "MIDDLE",
  Top = "TOP",
}

export enum ModalMaxWidth {
  Small = "SM",
  Medium = "MD",
  Large = "LG",
  ExtraLarge = "XL",
  TwoExtraLarge = "2XL",
  MaxContent = "MaxContent",
}

export enum ModalStyleVariant {
  DEFAULT = "DEFAULT",
  SIMPLE = "SIMPLE",
}
