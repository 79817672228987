import {
  Button,
  ButtonVariant,
  // TODO: create cui component for FileInput
  formFieldErrorId,
  FormFieldErrorMessage,
  FormFieldErrorMessageProps,
  Icon,
  IconVariant,
  SpacingVariant,
} from "@chp/curative_ui";
import clsx from "clsx";
import { isEmpty, uniqBy } from "lodash-es";
import { useRef } from "react";
import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  useFormContext,
} from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { useErrorMessageWithPrefix } from "../../hooks";

type FormFileInputProps<T extends FieldValues> = {
  id: string;
  name: Path<T>;
  className?: string;
  isMultiple?: boolean;
  testId?: string;
  accept: string;
  label?: string;
  description?: string;
};

// TODO: create shared formatted component for FileInput
const ErrorMessage = ({
  errorMessage,
  ...rest
}: FormFieldErrorMessageProps): JSX.Element | null => {
  const errorWithPrefix = useErrorMessageWithPrefix({ errorMessage });
  return <FormFieldErrorMessage errorMessage={errorWithPrefix} {...rest} />;
};

export function FormFileInput<T extends FieldValues>({
  id,
  name,
  className,
  isMultiple = false,
  accept,
  label,
  description,
  testId = "FormFileInput",
}: FormFileInputProps<T>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { control, setValue, trigger } = useFormContext<T>();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value }, fieldState: { error } }) => {
        // TODO: create cui component for FileInput
        const castValue = (value as File[]) || [];
        const hasError = Boolean(error?.message);
        const errorId: string | undefined = formFieldErrorId({ hasError, id });
        return (
          <div className={className} data-testid={testId}>
            <label htmlFor={id}>
              {label && <p className="mt-4">{label}</p>}

              {description && (
                <p className="text-sm-tall text-gray-700">{description}</p>
              )}

              <Button
                className={label && "mt-2"}
                ariaDescribedBy={errorId}
                onClick={() => inputRef.current?.click()}
                variant={ButtonVariant.SmallPrimary}
              >
                {isMultiple ? (
                  <FormattedMessage defaultMessage="Choose files" id="I9Vq88" />
                ) : (
                  <FormattedMessage defaultMessage="Choose file" id="kdTF/d" />
                )}
              </Button>

              <input
                aria-invalid={hasError}
                id={id}
                className="hidden"
                type="file"
                name={name}
                ref={inputRef}
                accept={accept}
                multiple={isMultiple}
                onChange={async (e) => {
                  const newFiles = Array.from(e.target.files ?? []);

                  // This means no file is chosen (user pressed 'Cancel` or `ESC`)
                  if (isEmpty(newFiles)) {
                    return;
                  }

                  const newValue = isMultiple
                    ? uniqBy(castValue.concat(newFiles), (file) => file.name)
                    : [newFiles[0]];

                  // @ts-expect-error
                  setValue(name, newValue);
                  trigger?.(name);
                }}
              />
            </label>

            {hasError && errorId && (
              <ErrorMessage
                errorMessage={error?.message}
                id={errorId}
                testId={`${testId}__ErrorMessage`}
              />
            )}

            {castValue.map((file, idx) => (
              <div
                key={file.name}
                className={clsx(
                  "w-60 rounded bg-gray-100 p-2",
                  "mt-2 grid grid-cols-[auto_1fr_auto] gap-2"
                )}
              >
                <Icon
                  variant={IconVariant.PAPER_CLIP}
                  size={SpacingVariant.S24}
                />

                <p className="truncate">{file.name}</p>
                <button
                  type="button"
                  onClick={() => {
                    castValue.splice(idx, 1);
                    setValue(name, castValue as PathValue<T, Path<T>>);
                    trigger?.(name);
                  }}
                >
                  <Icon variant={IconVariant.X} size={SpacingVariant.S24} />
                </button>
              </div>
            ))}
          </div>
        );
      }}
    />
  );
}
