import { Card, inlineLinkStyling } from "@chp/curative_ui";
import clsx from "clsx";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { VirtualCareVariant } from "../../types";
import { VirtualCareModal } from "../VirtualCareModal";

export type VirtualCareCardProps = {
  className?: string;
  variant: VirtualCareVariant;
  trackOpen?: () => void;
  trackLogin?: () => void;
  trackRegister?: () => void;
  trackClick?: () => void;
};

export const VirtualCareCard = ({
  className,
  trackOpen,
  trackLogin,
  trackRegister,
  trackClick,
  variant,
}: VirtualCareCardProps): JSX.Element | null => {
  const [isNormanMDModalOpen, setIsNormanMDModalOpen] =
    useState<boolean>(false);

  return (
    <>
      <Card className={clsx(className, "!py-4")}>
        <FormattedMessage
          defaultMessage="{variant, select,
    TEXAS {NormanMD}
    other {Teladoc Health®}
} offers 24/7 virtual urgent care for {variant, select,
    TEXAS {Texas members}
    other {members outside Texas}
}. <span><button>Get care now</button>.</span>"
          id="7ANfue"
          values={{
            variant,
            button: (chunks) => (
              <button
                className={inlineLinkStyling}
                onClick={() => {
                  setIsNormanMDModalOpen(true);
                  trackOpen && trackOpen();
                }}
              >
                {chunks}
              </button>
            ),
            span: (chunks) => (
              <span className="whitespace-nowrap">{chunks}</span>
            ),
          }}
        />
      </Card>
      <VirtualCareModal
        isOpen={isNormanMDModalOpen}
        onRequestClose={() => setIsNormanMDModalOpen(false)}
        trackLogin={trackLogin}
        trackRegister={trackRegister}
        trackClick={trackClick}
        virtualCareVariant={variant}
      />
    </>
  );
};
