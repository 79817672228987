import { TypeaheadOption } from "@chp/curative_ui";
import {
  FormattedTypeahead,
  FormattedTypeaheadProps,
} from "@chp/shared/components/FormattedTypeahead";
import {
  UseTypeaheadOptions,
  useTypeaheadOptions,
} from "@chp/shared/hooks/useTypeaheadOptions";
import { uniqBy } from "lodash-es";
import { useEffect } from "react";
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";

export type FormTypeaheadProps<T extends FieldValues, U> = Pick<
  FormattedTypeaheadProps<U>,
  | "isAlwaysOpen"
  | "id"
  | "isLabelSrOnly"
  | "className"
  | "hasDropdownToggle"
  | "isDisabled"
  | "isLoading"
  | "label"
  | "optionKeyExtractor"
  | "placeholder"
  | "queryStringMinLength"
  | "testId"
  | "noResultsTextWithQuery"
  | "noResultsTextWithoutQuery"
  | "prefixIcon"
  | "disableEnterWhileLoading"
  | "createNewOptionText"
  | "alwaysDisplayCreateNewOption"
> &
  UseTypeaheadOptions<U> & {
    name: Path<T>;
    shouldResetFieldOnChange?: boolean;
    customErrorMessage?: JSX.Element | null;
    onClickOption?: (newValue?: TypeaheadOption<U> | null) => void;
    prefixIconLoading?: boolean;
    onQueryStringDirty?: () => void;
    shouldHideGroupsOnQueryString?: boolean;
    trackQueryString?: (q: string) => void;
    trackClickOption?: (o: string) => void;
  };

export function FormTypeahead<T extends FieldValues, U = string>(
  props: FormTypeaheadProps<T, U>
) {
  const {
    customErrorMessage,
    fetchOptions,
    fetchOptionGroups,
    initialOptions,
    initialOptionGroups,
    name,
    shouldResetFieldOnChange,
    onClickOption,
    debounceMs,
    shouldHideGroupsOnQueryString = false,
    trackQueryString,
    trackClickOption,
  } = props;
  const {
    control,
    resetField,
    setValue: setFormValue,
    trigger,
  } = useFormContext<T>();

  const { options, optionGroups, queryString, setQueryString, errorMessage } =
    useTypeaheadOptions({
      fetchOptions,
      fetchOptionGroups,
      initialOptions,
      initialOptionGroups,
      debounceMs,
    });

  useEffect(() => {
    if (!shouldResetFieldOnChange || !resetField) {
      return;
    }

    const hasError =
      !!control.getFieldState(name).error?.message || !!errorMessage;

    if (hasError) {
      resetField(name, { defaultValue: null });
    }
  }, [
    queryString,
    control,
    errorMessage,
    name,
    resetField,
    shouldResetFieldOnChange,
  ]);

  // Since `options` and `optionGroups` cannot co-exist, we have to flip between the two.
  const optionProps = (function () {
    if (shouldHideGroupsOnQueryString && queryString) {
      if ((options && options.length) || !optionGroups) {
        return { options };
      } else {
        const dedupedOptions = uniqBy(
          optionGroups.groupList.flatMap((group) => group.options),
          (option) => option.label
        );
        return {
          options: dedupedOptions,
        };
      }
    }
    if (!optionGroups?.groupList?.length) return { options };

    return { optionGroups };
  })();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, ref },
        fieldState: { error: fieldValidationError },
      }) => (
        <FormattedTypeahead<U>
          {...props}
          {...optionProps}
          queryString={queryString}
          inputRef={ref}
          setQueryString={(q) => {
            trackQueryString && trackQueryString(q as string);
            setQueryString(q);
          }}
          optionLabelExtractor={(o) => o.label}
          optionLabel2Extractor={(o) => o.label2}
          optionLabel3Extractor={(o) => o.label3}
          value={value as TypeaheadOption<U>}
          onClickOption={(newValue?: TypeaheadOption<U> | null) => {
            // This is an issue with react-hook-form and will be fixed in the future
            trackClickOption && trackClickOption(newValue?.label as string);
            // @ts-expect-error
            setFormValue(name, newValue ?? null);
            if (!newValue) {
              setQueryString("");
            }
            trigger?.(name);
            onClickOption?.(newValue);
          }}
          errorMessage={
            customErrorMessage || errorMessage || fieldValidationError?.message
          }
        />
      )}
    />
  );
}
