import {
  Button,
  ButtonProps,
  ButtonVariant,
  SpacingVariant,
  Spinner,
} from "@chp/curative_ui";
import { FC } from "react";

export type FormSubmitButtonProps = Omit<
  ButtonProps,
  "children" | "variant" | "type"
> & {
  children: ButtonProps["children"];
  variant?: ButtonProps["variant"];
  isLoading?: boolean;
};

export const FormSubmitButton: FC<FormSubmitButtonProps> = ({
  children,
  variant = ButtonVariant.FullWidthPrimary,
  isDisabled,
  isLoading,
  ...restProps
}) => (
  <Button
    variant={variant}
    type="submit"
    isDisabled={isLoading || isDisabled}
    {...restProps}
  >
    <div className="flex items-center justify-center">
      <>
        {isLoading && (
          <div className="mr-2">
            <Spinner size={SpacingVariant.S16} className="stroke-gray-800" />
          </div>
        )}
        {children}
      </>
    </div>
  </Button>
);
