import { useIsOnScreen } from "@chp/shared/hooks/useIsOnScreen";
import { ReactNode, RefObject, useCallback } from "react";

import { ScrollToBottomButton } from "./ScrollToBottomButton";

export type LegalAgreementWrapperProps = {
  children: ReactNode;
  bottomRef: RefObject<HTMLDivElement>;
  title: string;
};

export const LegalAgreementWrapper = ({
  children,
  bottomRef,
  title,
}: LegalAgreementWrapperProps) => {
  const isAtBottom = useIsOnScreen(bottomRef);
  const onScrollToBottom = useCallback(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView();
    }
  }, [bottomRef]);

  return (
    <div
      data-testid="LegalAgreementWrapper"
      className="flex w-full flex-col items-center pb-40"
    >
      <h2 className="self-start pb-2 text-3xl font-extrabold">{title}</h2>
      {children}
      {!isAtBottom && <ScrollToBottomButton onClick={onScrollToBottom} />}
    </div>
  );
};
