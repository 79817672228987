/* eslint-disable @next/next/no-img-element */
import {
  COFINITY_LOGO_ASSET_NAME,
  FCOM_LOGO_ASSET_NAME,
  FIRST_HEALTH_LOGO_ASSET_NAME,
} from "@chp/shared/constants";
import { useContentfulAsset } from "@chp/shared/hooks";
import { FC, ImgHTMLAttributes } from "react";
import { useIntl } from "react-intl";

import {
  CAPITAL_RX_MEMBER_CARD_STATIC_LOGO_URI,
  CURATIVE_MEMBER_CARD_STATIC_LOGO_URI,
} from "../constants";
import { MemberCardLogoVariant, MemberCardStaticLogoVariant } from "../enums";

export type MemberCardLogoProps = {
  variant?: MemberCardLogoVariant;
  width?: number;
  height?: number;
};

export const MemberCardLogo: FC<MemberCardLogoProps> = ({
  variant = MemberCardLogoVariant.FIRST_HEALTH,
  width = 130,
  height = 32.5,
}) => {
  const { formatMessage } = useIntl();
  const logoTranslation = formatMessage({
    defaultMessage: "logo",
    id: "foSyCj",
  });
  const logos = {
    [MemberCardLogoVariant.COFINITY]: {
      asset: COFINITY_LOGO_ASSET_NAME,
      alt: `Cofinity ${logoTranslation}`,
    },
    [MemberCardLogoVariant.FCOM]: {
      asset: FCOM_LOGO_ASSET_NAME,
      alt: `First Choice of the Midwest, Inc ${logoTranslation}`,
    },
    [MemberCardLogoVariant.FIRST_HEALTH]: {
      asset: FIRST_HEALTH_LOGO_ASSET_NAME,
      alt: `First Health Network ${logoTranslation}`,
    },
  };
  const logoUrl = useContentfulAsset(logos[variant].asset);
  return (
    <img src={logoUrl} alt={logos[variant].alt} width={width} height={height} />
  );
};

export type MemberCardStaticLogoProps = Omit<
  ImgHTMLAttributes<HTMLImageElement>,
  "src"
> & {
  testId?: string;
  variant: MemberCardStaticLogoVariant;
};

export const MemberCardStaticLogo: FC<MemberCardStaticLogoProps> = ({
  testId,
  variant,
  ...props
}) => {
  switch (variant) {
    case MemberCardStaticLogoVariant.CAPITAL_RX: {
      return (
        <img
          aria-hidden
          data-testid={testId}
          src={CAPITAL_RX_MEMBER_CARD_STATIC_LOGO_URI}
          alt="Capital Rx logo"
          width={65}
          height={15}
          {...props}
        />
      );
    }
    case MemberCardStaticLogoVariant.CURATIVE: {
      return (
        <img
          aria-hidden
          data-testid={testId}
          src={CURATIVE_MEMBER_CARD_STATIC_LOGO_URI}
          alt="Curative logo"
          width={117}
          height={24}
          {...props}
        />
      );
    }
  }
};
