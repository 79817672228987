import { Disclosure, Transition } from "@headlessui/react";
import clsx from "clsx";
import { FC, ReactNode } from "react";

import { SpacingVariant } from "../../constants";
import {
  ActionButton,
  Box,
  DropdownButtonAlignment,
  Icon,
  IconVariant,
  TextVariant,
} from "..";

export type AccordionDropdownItemProps = {
  defaultOpen?: boolean;
  isOpen?: boolean; // if this is set, the accordion will be controlled
  icon?: IconVariant;
  onButtonClick?: (isOpen?: boolean) => void;
  panelContent: ReactNode;
  summaryContent: ReactNode;
  testId?: string;
  hasUnderline?: boolean;
  className?: string;
  parentContainerClassName?: string;
  summaryClassNames?: string;
  toggleButtonClassNames?: string;
  onDeleteClick?: () => void;
  deleteText?: string;
  dropDownButtonAlignment?: DropdownButtonAlignment;
};

export const AccordionDropdownItem: FC<AccordionDropdownItemProps> = ({
  defaultOpen,
  isOpen,
  icon,
  onButtonClick = () => {},
  panelContent,
  summaryContent,
  testId = "AccordionDropdownItem",
  hasUnderline,
  className,
  parentContainerClassName,
  toggleButtonClassNames,
  onDeleteClick,
  deleteText,
  summaryClassNames,
  dropDownButtonAlignment = DropdownButtonAlignment.RIGHT,
}) => {
  const shouldDisplayDeleteIcon = !!onDeleteClick && !!deleteText;

  return (
    <div data-testid={testId}>
      <Disclosure defaultOpen={defaultOpen}>
        {({ open: internalIsOpen }) => (
          <div
            className={clsx(
              "w-full bg-none text-textColor-default",
              parentContainerClassName
            )}
          >
            <div
              className={clsx(
                "flex",
                shouldDisplayDeleteIcon && "gap-x-4",
                className
              )}
            >
              <Disclosure.Button
                data-testid={`${testId}_toggle-accordion`}
                onClick={() => {
                  onButtonClick(isOpen !== undefined ? isOpen : internalIsOpen);
                }}
                className={clsx(
                  "bg-white",
                  "border-0",
                  "cursor-pointer",
                  "text-left",
                  "p-2",
                  "text-textColor-default",
                  "hover:text-textColor-highlight-default",
                  "hover:bg-surface-highlight",
                  "hover:underline",
                  "w-full",
                  hasUnderline &&
                    "border-b-2 border-solid border-borderColor-default",
                  toggleButtonClassNames
                )}
              >
                <Box
                  element="div"
                  textVariant={TextVariant.MdRegularTall}
                  className={clsx(
                    "flex w-full",
                    dropDownButtonAlignment === DropdownButtonAlignment.RIGHT
                      ? "justify-between"
                      : "space-x-2"
                  )}
                >
                  {dropDownButtonAlignment === DropdownButtonAlignment.LEFT && (
                    <Icon
                      className="flex shrink-0"
                      size={SpacingVariant.S24}
                      variant={
                        icon
                          ? icon
                          : (isOpen !== undefined ? isOpen : internalIsOpen)
                          ? IconVariant.CHEVRON_UP
                          : IconVariant.CHEVRON_DOWN
                      }
                    />
                  )}
                  <div
                    data-testid={`${testId}__Option`}
                    className={summaryClassNames || "text-xl font-bold"}
                  >
                    {summaryContent}
                  </div>
                  {dropDownButtonAlignment ===
                    DropdownButtonAlignment.RIGHT && (
                    <Icon
                      className="flex shrink-0"
                      size={SpacingVariant.S24}
                      variant={
                        icon
                          ? icon
                          : (isOpen !== undefined ? isOpen : internalIsOpen)
                          ? IconVariant.CHEVRON_UP
                          : IconVariant.CHEVRON_DOWN
                      }
                    />
                  )}
                </Box>
              </Disclosure.Button>
              {shouldDisplayDeleteIcon && (
                <ActionButton
                  iconVariant={IconVariant.TRASH}
                  onClick={onDeleteClick}
                  text={deleteText}
                  onlyDisplayIcon
                  className="items-center py-2"
                  testId={`${testId}__DeleteButton`}
                />
              )}
            </div>
            <div data-testid={`${testId}_panel-content`}>
              <Transition show={isOpen !== undefined ? isOpen : internalIsOpen}>
                <Disclosure.Panel static className="text-textColor-default">
                  {panelContent}
                </Disclosure.Panel>
              </Transition>
            </div>
          </div>
        )}
      </Disclosure>
    </div>
  );
};
