import {
  Icon,
  IconVariant,
  inlineLinkStyling,
  SpacingVariant,
} from "@chp/curative_ui";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";

import { VIRTUAL_URGENT_CARE_URL } from "../../constants";
import {
  NORMAN_MD_LOGIN_URL,
  NORMAN_MD_REGISTER_URL,
  TELADOC_LOGIN_URL,
  TELADOC_REGISTER_URL,
} from "../../services";
import { VirtualCareVariant } from "../../types";
import { FormattedModal } from "../index";

type VirtualCareModalConfig = {
  loginUrl: string;
  registerUrl: string;
};

const configForVariant: Record<VirtualCareVariant, VirtualCareModalConfig> = {
  [VirtualCareVariant.OTHER]: {
    loginUrl: TELADOC_LOGIN_URL,
    registerUrl: TELADOC_REGISTER_URL,
  },
  [VirtualCareVariant.TEXAS]: {
    loginUrl: NORMAN_MD_LOGIN_URL,
    registerUrl: NORMAN_MD_REGISTER_URL,
  },
};

export type VirtualCareModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  trackLogin?: () => void;
  trackRegister?: () => void;
  trackClick?: () => void;
  virtualCareVariant?: VirtualCareVariant;
};

export const VirtualCareModal = ({
  isOpen,
  onRequestClose,
  trackLogin,
  trackRegister,
  trackClick,
  virtualCareVariant = VirtualCareVariant.TEXAS,
}: VirtualCareModalProps): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <FormattedModal
      isOpen={isOpen}
      title={formatMessage({ defaultMessage: "Need care now?", id: "8gxgti" })}
      buttonText={
        <>
          <FormattedMessage defaultMessage="Learn more" id="TdTXXf" />
          <Icon
            className="mb-1 ml-1 !inline"
            variant={IconVariant.EXTERNAL_LINK}
            size={SpacingVariant.S20}
          />
        </>
      }
      onButtonClick={() => {
        trackClick?.();
        window.open(VIRTUAL_URGENT_CARE_URL, "_blank", "noreferrer");
      }}
      onRequestClose={onRequestClose}
      testId="virtual-care-modal"
    >
      <div className={clsx("font-normal", "pb-4", "pt-2", "text-lg-tall")}>
        <p className="pb-4">
          <FormattedMessage
            defaultMessage="Curative members have access to free virtual urgent care 24/7/365 with {variant, select,
    TEXAS {NormanMD}
    other {Teladoc Health®}
}!"
            id="PtX66l"
            values={{
              variant: virtualCareVariant,
            }}
          />
        </p>
        <p>
          <FormattedMessage
            defaultMessage="New to {variant, select,
    TEXAS {NormanMD}
    other {Teladoc Health®}
}?"
            id="4BPHRN"
            values={{
              variant: virtualCareVariant,
            }}
          />{" "}
          <a
            className={inlineLinkStyling}
            href={configForVariant[virtualCareVariant].registerUrl}
            target="_blank"
            rel="noreferrer"
            onClick={() => trackRegister?.()}
          >
            <FormattedMessage defaultMessage="Register" id="deEeEI" />
            <Icon
              className="mb-1 ml-1 !inline"
              variant={IconVariant.EXTERNAL_LINK}
              size={SpacingVariant.S20}
            />
          </a>
        </p>
        <p>
          <FormattedMessage
            defaultMessage="Already registered? <a>Log in</a>"
            id="syQ//o"
            values={{
              a: (chunks) => (
                <a
                  className={inlineLinkStyling}
                  href={configForVariant[virtualCareVariant].loginUrl}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => trackLogin?.()}
                >
                  <>
                    {chunks}
                    <Icon
                      className="mb-1 ml-1 !inline"
                      variant={IconVariant.EXTERNAL_LINK}
                      size={SpacingVariant.S20}
                    />
                  </>
                </a>
              ),
            }}
          />
        </p>
        <div className="mt-2 text-xs text-gray-400">
          <FormattedMessage
            defaultMessage="Powered by {variant, select,
    TEXAS {NormanMD}
    other {Teladoc Health®}
}"
            id="Jm3bws"
            values={{
              variant: virtualCareVariant,
            }}
          />
        </div>
      </div>
    </FormattedModal>
  );
};
