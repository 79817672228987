import { DisclosureCard, DisclosureCardProps } from "@chp/curative_ui";
import { useIntl } from "react-intl";

export type FormattedDisclosureCardProps = Omit<
  DisclosureCardProps,
  "buttonText"
>;

export const FormattedDisclosureCard = ({
  testId = "FormattedDisclosureCard",
  ...rest
}: FormattedDisclosureCardProps): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <DisclosureCard
      {...rest}
      buttonText={{
        closed: formatMessage({
          defaultMessage: "View more",
          id: "34Up+l",
        }),
        open: formatMessage({
          defaultMessage: "Close",
          id: "rbrahO",
        }),
      }}
      testId={testId}
    />
  );
};
