import { format, getDate, getMonth, getYear } from "date-fns";
import { isUndefined } from "lodash-es";

import { DATE_INPUT_FORMAT } from "./format";

export class InvalidDateError extends Error {
  constructor(message: string) {
    super(message);
    // Set the prototype explicitly as per the recommendation at
    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, InvalidDateError.prototype);
    this.name = "InvalidDateError";
  }
}

/**
 * Class to represent a plain date with no time.
 *
 * Month and day are both 1-indexed (unlike JavaScript Date objects which have
 * 0-indexed months).
 *
 * Will raise an InvalidDateError if given an invalid date.
 *
 * TODO: consider using Temporal polyfill & its PlainDate class instead. Might be preferable to use standardized API.
 * * https://tc39.es/proposal-temporal/docs/
 * * https://github.com/tc39/proposal-temporal#polyfills
 */
export class PlainDate {
  year: number;
  month: number;
  day: number;

  constructor(year: number, month: number, day: number) {
    if (
      !Number.isInteger(year) ||
      !Number.isInteger(month) ||
      !Number.isInteger(day)
    ) {
      throw new InvalidDateError("Year / month / day must be integers");
    }
    if (year <= 0 || year > 9999) {
      throw new InvalidDateError("Invalid year");
    }
    if (month < 1 || month > 12) {
      throw new InvalidDateError("Invalid month");
    }
    if (day < 1 || day > 31) {
      throw new InvalidDateError("Invalid day");
    }

    // Validate that the day is valid for the specified month/year using trick
    // from https://stackoverflow.com/a/5812341/1709587
    const dateObj = new Date(year, month - 1, day);
    if (dateObj.getMonth() + 1 !== month) {
      throw new InvalidDateError("Invalid day for specified month");
    }

    this.year = year;
    this.month = month;
    this.day = day;
  }

  static now(): PlainDate {
    const jsNow = Date.now();
    return new PlainDate(getYear(jsNow), getMonth(jsNow) + 1, getDate(jsNow));
  }

  /**
   * Attempt to parse a string in ISO 8601 format (yyyy-MM-DD) into a PlainDate.
   */
  static fromIsoString(dateString: string): PlainDate {
    const segments = dateString.split("-");
    const [year, month, day] = segments.map((val) => parseInt(val));

    if (
      isUndefined(year) ||
      !isFinite(year) ||
      isUndefined(month) ||
      !isFinite(month) ||
      isUndefined(day) ||
      !isFinite(day)
    ) {
      throw new InvalidDateError("Invalid ISO date string");
    }

    return new PlainDate(year, month, day);
  }

  toISOString(): string {
    const paddedYear = String(this.year).padStart(4, "0"),
      paddedMonth = String(this.month).padStart(2, "0"),
      paddedDay = String(this.day).padStart(2, "0");
    return `${paddedYear}-${paddedMonth}-${paddedDay}`;
  }

  /**
   * Return string with numeric date format MM/dd/yyyy. Do not localize, never show as d/m/y.
   */
  toMMDDYYYYFormat(): string {
    return format(this.asJsDate(), DATE_INPUT_FORMAT);
  }

  /**
   * Returns true if this date is before the given `otherDate`, false otherwise.
   *
   * @param otherDate The date to compare against.
   * @returns True if this date is before the given `otherDate`, false otherwise.
   */
  isEqual(otherDate: PlainDate): boolean {
    return (
      this.year === otherDate.year &&
      this.month === otherDate.month &&
      this.day === otherDate.day
    );
  }

  /**
   * Returns true if this date is before the given `otherDate`, false otherwise.
   *
   * @param otherDate The date to compare against.
   * @returns True if this date is before the given `otherDate`, false otherwise.
   */
  isBefore(otherDate: PlainDate): boolean {
    return this.year < otherDate.year
      ? true
      : this.year > otherDate.year
      ? false
      : this.month < otherDate.month
      ? true
      : this.month > otherDate.month
      ? false
      : this.day < otherDate.day
      ? true
      : false;
  }

  /**
   * Returns true if this date is after the given `otherDate`, false otherwise.
   *
   * @param otherDate The date to compare against.
   * @returns True if this date is after the given `otherDate`, false otherwise.
   */
  isAfter(otherDate: PlainDate): boolean {
    return this.year > otherDate.year
      ? true
      : this.year < otherDate.year
      ? false
      : this.month > otherDate.month
      ? true
      : this.month < otherDate.month
      ? false
      : this.day > otherDate.day
      ? true
      : false;
  }

  /**
   * Attempt to parse a string in MM/DD/YYYY format into a PlainDate, returning
   * null if it doesn't contain a valid date. (Month and day are both allowed
   * to be a single digit.)
   *
   * Returns null if the string isn't in the right format or contains an
   * invalid date (e.g. 32nd of February).
   */
  static fromMMDDYYYYFormat(dateString: string): PlainDate | null {
    const dateRegex = /(\d\d?)\/(\d\d?)\/(\d\d\d\d)/;
    const match = dateString.match(dateRegex);
    if (match == null) {
      return null;
    }
    const [_, monthStr, dayStr, yearStr] = match;
    const month = Number(monthStr);
    const day = Number(dayStr);
    const year = Number(yearStr);
    try {
      return new PlainDate(year, month, day);
    } catch (InvalidDateError) {
      return null;
    }
  }

  /**
   * Returns a JavaScript Date object representing the start of this PlainDate in
   * the user's current system timezone.
   *
   * (Note this time will usually be midnight, i.e. 00:00:00, but occasionally
   * another value like 01:00 if midnight was skipped on that day in that
   * timezone. Thus you can rely on .getDate(), .getMonth(), and .getFullYear()
   * returning the expected results, and so safely pass the returned Date to a
   * date-formatting library that uses those methods, but you cannot assume the
   * time is exactly midnight and should exercise caution if attempting any
   * kind of arithmetic on the returned Date.)
   */
  asJsDate(): Date {
    return new Date(this.year, this.month - 1, this.day);
  }

  /**
   * Returns a JavaScript Date object representing the start of this PlainDate in UTC
   */
  asJsDateUTC(): Date {
    return new Date(Date.UTC(this.year, this.month - 1, this.day));
  }

  minusMonths(months: number): PlainDate {
    const jsDate = this.asJsDate();
    jsDate.setMonth(jsDate.getMonth() - months);

    return PlainDate.fromJsDate(jsDate);
  }

  minusYears(years: number): PlainDate {
    const jsDate = this.asJsDate();
    jsDate.setFullYear(jsDate.getFullYear() - years);

    return PlainDate.fromJsDate(jsDate);
  }

  plusDays(daysToAdd: number): PlainDate {
    const jsDate = this.asJsDate();
    jsDate.setDate(jsDate.getDate() + daysToAdd);

    return PlainDate.fromJsDate(jsDate);
  }

  /**
   * Returns number of years passed since a previousDate in comparison to this PlainDate instance (current date)
   * To be used for cases such as calculating a person's current age
   */
  yearsPassedSince(previousDate: PlainDate): number {
    const currentYear = this.year;
    const previousDateYear = previousDate.year;

    const hasPassedOrIsDayInCurrentYear =
      this.month > previousDate.month ||
      (this.month === previousDate.month && this.day >= previousDate.day);

    // if the birthday has not yet passed, then we decrease the difference between years by 1
    const yearsPassed =
      currentYear - previousDateYear - (hasPassedOrIsDayInCurrentYear ? 0 : 1);

    return yearsPassed;
  }

  /**
   * Returns a PlainDate with the year, month and date of the JavaScript Date object in it's current timezone
   */
  static fromJsDate(date: Date): PlainDate {
    return new PlainDate(
      date.getFullYear(),
      // The getMonth() method of the Date object returns a zero-based index for the month, where January is 0 and December is 11. So we need the +1 to get the correct month number
      date.getMonth() + 1,
      date.getDate()
    );
  }

  /**
   * Returns a PlainDate with the year, month and date of the JavaScript Date object in UTC
   */
  static fromJsDateUTC(date: Date): PlainDate {
    return new PlainDate(
      date.getUTCFullYear(),
      date.getUTCMonth() + 1,
      date.getUTCDate()
    );
  }
}
