import {
  I18nProvider,
  I18nProviderProps,
  LOCALE_CODES_EN_ES,
  localeCodeToLanguages,
} from "@chp/shared";
import { useMemo } from "react";

import { localeCodeToMessages } from "../../../locales";
import { useMemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutation } from "./MemberPortalI18nProvider.generated";

export const MemberPortalI18nProvider = ({
  children,
  localeCodesInitial,
}: Pick<I18nProviderProps, "children" | "localeCodesInitial">) => {
  const [updateActiveUserPreferredLanguageMutation] =
    useMemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutation();

  const updateActiveUser: I18nProviderProps["updateActiveUser"] | null =
    useMemo(
      () =>
        async ({ locale }) => {
          try {
            await updateActiveUserPreferredLanguageMutation({
              variables: {
                preferredLanguage: localeCodeToLanguages[locale],
              },
            });
          } catch (_) {
            // i18n provider has to live outside the auth context, therefore can't check if there's an active user, so the mutation fails silently if there is none
            return;
          }
        },
      [updateActiveUserPreferredLanguageMutation]
    );

  return (
    <I18nProvider
      localeCodeToMessages={localeCodeToMessages}
      localeCodesInitial={localeCodesInitial}
      supportedLocales={LOCALE_CODES_EN_ES}
      updateActiveUser={updateActiveUser ?? undefined}
    >
      {children}
    </I18nProvider>
  );
};
