import {
  IconVariant,
  LocaleOption,
  patchLocaleButtonForGoogleTranslate,
  SideNavbarItemProps,
  SideNavbarItemType,
} from "@chp/curative_ui";
import { ExpandableMenuItemActionEvent } from "@chp/curative_ui/src/components/SideNavbar/ExpandableItem";
import {
  I18nContext,
  LOCALE_CODE_TO_DISPLAY_NAME,
  LocaleCode,
  MemberPortalMixpanelUiIdentifierName,
  RequestSetLocaleOptions,
  SharedMixpanelEventName,
  TrackAppSpecificEvent,
  useMemberPortalMixpanelContext,
} from "@chp/shared";
import { useCallback, useContext, useMemo } from "react";

import { useAuth } from "../contexts/AuthContext";

export const maybeUpdateActiveUserLocale = async ({
  hasActiveUser,
  locale,
  refreshSession,
  updateActiveUser,
}: {
  hasActiveUser: boolean;
  locale: LocaleCode;
  refreshSession: () => Promise<void>;
  updateActiveUser?: ({ locale }: { locale: LocaleCode }) => Promise<void>;
}): Promise<void> => {
  if (hasActiveUser && updateActiveUser) {
    await updateActiveUser({ locale });
    refreshSession();
  }
};

export const nestedLocaleNavItems = ({
  hasActiveUser,
  localeOptions,
  refreshSession,
  requestSetLocale,
  selectedLocale,
  updateActiveUser,
  trackEvent,
}: {
  hasActiveUser: boolean;
  localeOptions: LocaleOption<LocaleCode>[];
  refreshSession: () => Promise<void>;
  requestSetLocale: (_: RequestSetLocaleOptions) => void;
  selectedLocale: LocaleCode;
  updateActiveUser?: ({ locale }: { locale: LocaleCode }) => Promise<void>;
  trackEvent: TrackAppSpecificEvent;
}): SideNavbarItemProps[] =>
  localeOptions.reduce(
    (acc: SideNavbarItemProps[], curr: LocaleOption<LocaleCode>) => {
      if (curr.value === selectedLocale) {
        return acc;
      }
      const navItem: SideNavbarItemProps = {
        label: curr.label,
        onClick: () => {
          patchLocaleButtonForGoogleTranslate();
          requestSetLocale({
            locale: curr.value,
            shouldSetCookie: true,
          });
          trackEvent({
            uiIdentifier:
              MemberPortalMixpanelUiIdentifierName.SIDE_NAV_LANGUAGE_SELECTOR,
            eventName:
              curr.value === LocaleCode.ES
                ? SharedMixpanelEventName.SELECT_SPANISH
                : SharedMixpanelEventName.SELECT_ENGLISH,
          });
          maybeUpdateActiveUserLocale({
            hasActiveUser,
            locale: curr.value,
            refreshSession,
            updateActiveUser,
          });
        },
        type: SideNavbarItemType.NavButton,
      };

      return [...acc, navItem];
    },
    []
  );

export const useLocaleNavItems = (): SideNavbarItemProps | null => {
  const { activeUser } = useAuth();
  const { refreshSession } = useAuth();
  const hasActiveUser = !!activeUser;
  const { locale, localeOptions, requestSetLocale, updateActiveUser } =
    useContext(I18nContext);
  const { trackEvent } = useMemberPortalMixpanelContext();

  const languageMenuClickEvent = useCallback(
    (menuAction: ExpandableMenuItemActionEvent) =>
      trackEvent({
        uiIdentifier:
          MemberPortalMixpanelUiIdentifierName.SIDE_NAV_LANGUAGE_SELECTOR,
        eventName: menuAction,
      }),
    [trackEvent]
  );

  return useMemo(() => {
    const nestedNavItems = nestedLocaleNavItems({
      hasActiveUser,
      localeOptions,
      refreshSession,
      requestSetLocale,
      selectedLocale: locale,
      updateActiveUser,
      trackEvent,
    });

    return nestedNavItems.length > 0
      ? {
          iconVariant: IconVariant.GLOBE_ALT,
          label: LOCALE_CODE_TO_DISPLAY_NAME[locale],
          type: SideNavbarItemType.ExpandableItem,
          nestedNavItems,
          itemClickEvent: languageMenuClickEvent,
        }
      : null;
  }, [
    hasActiveUser,
    languageMenuClickEvent,
    locale,
    localeOptions,
    refreshSession,
    requestSetLocale,
    trackEvent,
    updateActiveUser,
  ]);
};
