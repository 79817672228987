import { RefObject } from "react";

/**
 * Check if element has width overflow. See: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollWidth
 */
export const hasOverflowWidth = ({
  ref,
}: {
  ref: RefObject<HTMLInputElement>;
}): boolean => {
  const { current } = ref;
  return !!current && current.scrollWidth > current.clientWidth;
};
