import { SignatureResponseType } from "@chp/shared/generated/memberPortalApi.graphql";
import { useIntl } from "react-intl";

export const useLegalAgreementIntlFormatters = () => {
  const { formatMessage } = useIntl();

  return {
    getAlreadyAcceptedLabelForTitle: (title: string): string => {
      return formatMessage(
        { defaultMessage: '"{title}" has already been accepted', id: "Nw7igX" },
        { title }
      );
    },
    getLabelForSignatureResponseType: (
      signatureResponseType: SignatureResponseType
    ): string => {
      switch (signatureResponseType) {
        case SignatureResponseType.Consent:
          return formatMessage({
            defaultMessage: "I consent",
            id: "GvL2tZ",
          });
        case SignatureResponseType.DoNotConsent:
          return formatMessage({
            defaultMessage: "I DO NOT consent",
            id: "jqkZE3",
          });
        case SignatureResponseType.HaveReadAndUnderstand:
          return formatMessage({
            defaultMessage: "I have read and understand this disclosure",
            id: "wTuYzj",
          });
      }
    },
  };
};
