import Lottie, { LottieComponentProps } from "lottie-react";

import errorAnimation from "./error.json";
import loadingAnimation from "./loading.json";
import successAnimation from "./success.json";

export enum AnimationType {
  SUCCESS = "SUCCESS",
  LOADING = "LOADING",
  ERROR = "ERROR",
}

export interface AnimationProps {
  type: AnimationType;
}

function getLottieComponentProps(type: AnimationType): LottieComponentProps {
  switch (type) {
    case AnimationType.SUCCESS:
      return {
        animationData: successAnimation,
        loop: false,
      };
    case AnimationType.LOADING:
      return {
        animationData: loadingAnimation,
      };
    case AnimationType.ERROR:
      return {
        animationData: errorAnimation,
        loop: false,
      };
  }
}

export const Animation = ({ type }: AnimationProps) => (
  <Lottie {...getLottieComponentProps(type)} />
);
