import { MultiSelect, MultiSelectProps } from "@chp/curative_ui";
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";

export type FormMultiSelectProps<T extends FieldValues, Option, Value> = {
  name: Path<T>;
  optionValueExtractor: (option: Option) => Value;
} & Omit<
  MultiSelectProps<Option>,
  "value" | "onChangeSelection" | "errorMessage"
>;

export function FormMultiSelect<T extends FieldValues, Option, Value>({
  name,
  optionValueExtractor,
  ...props
}: FormMultiSelectProps<T, Option, Value>) {
  const { control } = useFormContext<T>();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <MultiSelect<Option>
          {...props}
          value={
            value === undefined
              ? []
              : props.options.filter((option) =>
                  Array.from(value).includes(optionValueExtractor(option))
                )
          }
          onChangeSelection={(options) =>
            onChange({
              target: {
                value: options.map(optionValueExtractor),
              },
            })
          }
          errorMessage={error?.message}
        />
      )}
    />
  );
}
