import { SideDrawer, SideDrawerProps } from "@chp/curative_ui";
import { useIntl } from "react-intl";

// TODO: This is a workaround for the fact that the SideDrawerProps type requires the text props
export type FormattedSideDrawerProps = Omit<
  SideDrawerProps,
  "closeButtonText" | "cancelText" | "confirmText"
> & {
  closeButtonText?: string;
  cancelText?: string;
  confirmText?: string;
};

/**
 * Provides overrideable default text for the CUI SideDrawer component.
 */
export const FormattedSideDrawer = (
  props: FormattedSideDrawerProps
): JSX.Element => {
  const { formatMessage } = useIntl();
  const text = {
    closeButtonText: formatMessage({ defaultMessage: "Close", id: "rbrahO" }),
    cancelText: formatMessage({ defaultMessage: "Cancel", id: "47FYwb" }),
    confirmText: formatMessage({ defaultMessage: "Confirm", id: "N2IrpM" }),
  };

  return <SideDrawer {...text} {...props} />;
};
