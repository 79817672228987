import { SESSION_ID_STORAGE_KEY } from "@chp/shared/constants";
import { v4 as uuidv4 } from "uuid";

export const getStoredSessionId = (): string | null =>
  window.sessionStorage.getItem(SESSION_ID_STORAGE_KEY);

export const setStoredSessionId = (sessionId: string): string => {
  window.sessionStorage.setItem(SESSION_ID_STORAGE_KEY, sessionId);

  return sessionId;
};

export const getOrCreateStoredSessionId = (): string =>
  getStoredSessionId() ?? setStoredSessionId(uuidv4());
