import {
  Box,
  Button,
  ButtonVariant,
  FOCUS_RING_CLASS_NAMES,
  formFieldBorderClassNames,
  formFieldIconSize,
  FormFieldLabelText,
  Icon,
  IconVariant,
  linkButtonStyling,
  SpacingVariant,
  TEXT_CLASS_NAMES,
  textColorsCommon,
  TextVariant,
  TooltipWrapper,
} from "@chp/curative_ui";
import clsx from "clsx";
import Link from "next/link";
import React, { useEffect, useRef } from "react";

export const CustomDropdown = ({
  backButtonIcon = IconVariant.ARROW_SMALL_LEFT,
  backButtonTooltip,
  buttonCaption,
  buttonClassName,
  buttonIcon,
  buttonType,
  buttonVariant = ButtonVariant.Flat,
  children,
  className,
  displayValue,
  icon,
  isLabelSrOnly,
  isOpen,
  label,
  onBackButtonClick,
  onClickButton = () => {},
  onClose = () => {},
  placeholder,
  setIsOpen,
  testId,
  useMobileSettings,
}: {
  backButtonIcon?: IconVariant;
  backButtonTooltip?: string;
  buttonCaption?: string;
  buttonClassName?: string;
  buttonIcon: IconVariant;
  buttonType?: "submit" | "reset" | "button";
  buttonVariant?: ButtonVariant;
  children: React.ReactNode;
  className?: string;
  displayValue: string;
  icon: IconVariant;
  isLabelSrOnly?: boolean;
  isOpen: boolean;
  label: string;
  onBackButtonClick?: () => void;
  onClickButton?: () => void;
  onClose?: () => void;
  placeholder?: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  testId: string;
  useMobileSettings?: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  // Close the dropdown after clicking or focusing elsewhere.
  // from https://blog.logrocket.com/detect-click-outside-react-component-how-to/
  useEffect(() => {
    const handleClickOrFocusOutside = (event: MouseEvent | FocusEvent) => {
      if (!ref.current || !event.target) {
        return;
      }

      if (isOpen && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
        onClose();
      }
    };

    document.addEventListener("click", handleClickOrFocusOutside, true);
    document.addEventListener("focus", handleClickOrFocusOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOrFocusOutside, true);
      document.removeEventListener("focus", handleClickOrFocusOutside, true);
    };
  }, [onClose, isOpen, setIsOpen]);

  return (
    <div ref={ref} className="relative flex flex-col">
      <span data-testid={`${testId}__Label`}>
        <FormFieldLabelText isLabelSrOnly={isLabelSrOnly} label={label} />
      </span>
      <Box
        className={clsx(className)}
        element="div"
        textVariant={TextVariant.MdRegularTall}
      >
        <Box
          className={clsx("relative flex gap-2")}
          element="div"
          margin={{
            top: isLabelSrOnly ? SpacingVariant.S0 : SpacingVariant.S4,
          }}
        >
          <TooltipWrapper message={backButtonTooltip}>
            <Link
              href="/"
              className={clsx(
                linkButtonStyling(ButtonVariant.SmallOutline),
                "!flex items-center justify-center",
                "!border-borderColor-default",
                "!text-textColor-default",
                "h-12"
              )}
              onClick={onBackButtonClick}
            >
              <Icon variant={backButtonIcon} size={SpacingVariant.S24} />
              <div className="sr-only">{backButtonTooltip}</div>
            </Link>
          </TooltipWrapper>
          <div
            tabIndex={0}
            className={clsx(
              ...FOCUS_RING_CLASS_NAMES,
              ...formFieldBorderClassNames({
                hasError: false,
                isDisabled: false,
              }),
              "w-full",
              "overflow-auto",
              "cursor-default",
              "bg-surface-default",
              "relative",
              "rounded",
              "flex"
            )}
          >
            <div
              data-testid={`${testId}__Field`}
              className={clsx(
                "flex",
                "px-3 py-2",
                "text-left",
                "items-center",
                TEXT_CLASS_NAMES[TextVariant.LgRegularTall],
                // use placeholder text color when value is empty
                placeholder && !displayValue
                  ? textColorsCommon.subdued
                  : textColorsCommon.default,
                "w-full",
                "cursor-pointer"
              )}
              onClick={() =>
                setIsOpen((prev) => {
                  if (prev) {
                    onClose();
                  }
                  return !prev;
                })
              }
            >
              <span
                className={clsx("pointer-events-none flex items-center pr-2")}
              >
                <Icon variant={icon} size={SpacingVariant.S24} />
              </span>
              <span className={clsx("block flex-1 truncate pr-8")}>
                {displayValue || placeholder}
              </span>
              <span className={clsx("right-0 flex items-center")}>
                <Button
                  className={clsx(
                    "absolute",
                    "right-2",
                    "top-2",
                    "bottom-2",
                    "flex",
                    "w-fit",
                    buttonClassName
                  )}
                  testId={`${testId}__Button`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickButton();
                  }}
                  type={buttonType}
                  variant={buttonVariant}
                >
                  <>
                    <Icon
                      className={clsx("top-[calc(50%_-_0.75em)]")}
                      size={formFieldIconSize}
                      variant={buttonIcon}
                    />
                    <span className="sr-only">{buttonCaption}</span>
                  </>
                </Button>
              </span>
            </div>
          </div>
          {isOpen && (
            <>
              <div
                data-testid={`${testId}__Dropdown`}
                className={clsx(
                  "absolute",
                  useMobileSettings
                    ? "inset-x-0 top-14 -mx-4 w-auto p-4"
                    : "top-16 w-full border",
                  "flex flex-col",
                  "border-solid",
                  "border-gray-700",
                  "rounded",
                  "bg-white",
                  "z-[55]"
                )}
              >
                {children}
              </div>
              {useMobileSettings && (
                <div
                  data-testid="overlay"
                  className="fixed inset-0 top-40 z-40 bg-black opacity-80"
                />
              )}
            </>
          )}
        </Box>
      </Box>
    </div>
  );
};
