import * as Yup from "yup";

import { MEMBER_ZIP_CODE_MAX_LENGTH } from "../../constants";

export const zipCodeSchema = ({
  messages,
}: {
  messages: {
    maxLength: string;
  };
}) => Yup.string().max(MEMBER_ZIP_CODE_MAX_LENGTH, messages.maxLength);

export const zipCodeNullableSchema = ({
  messages,
}: {
  messages: {
    maxLength: string;
  };
}) => zipCodeSchema({ messages }).nullable();

export const zipCodeRequiredSchema = ({
  messages,
}: {
  messages: {
    maxLength: string;
    required: string;
  };
}) => zipCodeSchema({ messages }).required(messages.required);
