import { TypeaheadOption } from "@chp/curative_ui";
import { compact } from "lodash-es";
import { useCallback } from "react";
import { IntlShape, useIntl } from "react-intl";

import { formatStreetAddress } from "../../utils";
import {
  TypeaheadOptions__SearchProvidersQuery,
  useTypeaheadOptions__SearchProvidersLazyQuery,
} from "../memberPortalApi-TypeaheadOptions.generated";

export type ProviderSearchResult = NonNullable<
  TypeaheadOptions__SearchProvidersQuery["providersFlat"]["providers"][number]
>;

export const cleanProviderSearchResult = (
  provider: ProviderSearchResult,
  formatMessage: IntlShape["formatMessage"]
): TypeaheadOption<ProviderSearchResult> => {
  const otherLocationsLabel =
    provider.officeIdsList && provider.officeIdsList.length > 1
      ? formatMessage(
          { defaultMessage: `+ {count} other locations`, id: "poRtoq" },
          { count: provider.officeIdsList.length - 1 }
        )
      : "";

  return {
    label: compact([provider.firstName, provider.lastName]).join(" "),
    value: provider,
    label2: formatStreetAddress(provider),
    label3: otherLocationsLabel,
  };
};

export const useFetchProviderOptions = () => {
  const { formatMessage } = useIntl();

  const [searchProviders, { loading: areProviderOptionsLoading }] =
    useTypeaheadOptions__SearchProvidersLazyQuery();

  const fetchProviderOptions = useCallback(
    async (queryString: string) => {
      const response = await searchProviders({
        variables: {
          search: queryString,
        },
      });

      if (response.error || !response.data) {
        throw new Error(
          formatMessage({
            defaultMessage: "There was an error searching for providers.",
            id: "Kc9Rfi",
          })
        );
      }

      const providers = response.data.providersFlat.providers;

      return providers.map((provider) => {
        return cleanProviderSearchResult(provider, formatMessage);
      });
    },
    [searchProviders, formatMessage]
  );

  return {
    fetchProviderOptions,
    areProviderOptionsLoading,
  };
};
