import {
  IconVariant,
  SideNavbarItemProps,
  SideNavbarItemType,
} from "@chp/curative_ui";
import {
  checkCoverage,
  FAQ_URL,
  generateArrayKey,
  getVirtualCareVariant,
  isPolicyholderOrSpouse,
  MEMBER_PORTAL_FAMILY_ROUTE_RELATIVE,
  MemberPortalMixpanelUiIdentifierName,
  NORMAN_MD_LOGIN_URL,
  SharedMixpanelEventName,
  TELADOC_LOGIN_URL,
  useMemberPortalMixpanelContext,
  VirtualCareVariant,
} from "@chp/shared";
import { compact } from "lodash-es";
import { useRouter } from "next/router";
import {
  ComponentProps,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
} from "react";
import { useIntl } from "react-intl";

import { useAuth } from "~/contexts/AuthContext";
import {
  BENEFITS_ROUTE,
  CARE_COST_ROUTE,
  CLAIMS_ROUTE,
  CLINICAL_DOCS_ROUTE,
  DASHBOARD_ROUTE,
  FORMS_ROUTE,
  MEMBER_CARD_ROUTE,
  MEMBER_PAYMENT_CARD_ROUTE,
  OUT_OF_POCKET_COSTS_ROUTE,
  PHARMACY_ROUTE,
  PROFILE_ROUTE,
} from "~/utils/constants";

import { useLocaleNavItems } from "./useLocaleNavItems";

export interface RouteConfig {
  navLabel: string;
  pageHeading?: string;
  href: string;
  icon: (props: ComponentProps<"svg">) => JSX.Element;
  hideFromSidebar?: boolean;
}

export const useNavItems = (
  setIsMobileNavbarOpen: Dispatch<SetStateAction<boolean>>
): {
  navItems: SideNavbarItemProps[];
} => {
  const { formatMessage } = useIntl();
  const { logout, activeUser } = useAuth();
  const router = useRouter();
  const { trackEvent } = useMemberPortalMixpanelContext();
  const benefitsBookletUrl =
    activeUser?.member?.displayedEnrollment.benefitsBookletUrl;

  // add locale nav items
  const localeNavGroup: SideNavbarItemProps | null = useLocaleNavItems();

  const currentHref =
    typeof window !== "undefined" ? window.location.href : "/";

  const currentMember = activeUser?.member;

  const { hasExpiredCoverage } = checkCoverage(
    currentMember?.displayedEnrollment
  );
  const hasAccessToFamilyPage = isPolicyholderOrSpouse(
    currentMember?.relationship
  );

  const virtualCareUrl =
    getVirtualCareVariant({ state: currentMember?.address?.state }) ===
    VirtualCareVariant.TEXAS
      ? NORMAN_MD_LOGIN_URL
      : TELADOC_LOGIN_URL;

  const careCostPageNavItem: SideNavbarItemProps | null = useMemo(
    () =>
      hasExpiredCoverage
        ? null
        : {
            label: formatMessage({
              defaultMessage: "Cost Estimator",
              id: "73GGRf",
            }),
            type: SideNavbarItemType.NavLink,
            isActive: () => currentHref.includes(CARE_COST_ROUTE),
            href: CARE_COST_ROUTE,
          },
    [currentHref, formatMessage, hasExpiredCoverage]
  );

  const navItems: SideNavbarItemProps[] = useMemo<SideNavbarItemProps[]>(() => {
    const getCareNavGroup: SideNavbarItemProps | null = !hasExpiredCoverage
      ? {
          iconVariant: IconVariant.STETHOSCOPE,
          label: formatMessage({ defaultMessage: "Get Care", id: "mIA03x" }),
          type: SideNavbarItemType.ExpandableItem,
          nestedNavItems: compact([
            {
              label: formatMessage({
                defaultMessage: "Providers",
                id: "IL1Uey",
              }),
              type: SideNavbarItemType.NavLink,
              isExternal: true,
              href: process.env.NEXT_PUBLIC_PROVIDER_SEARCH_URL || null,
            },
            {
              label: formatMessage({
                defaultMessage: "Drug Search",
                id: "w+7A26",
              }),
              type: SideNavbarItemType.NavLink,
              isExternal: true,
              href: process.env.NEXT_PUBLIC_FORMULARY_SEARCH_URL || null,
            },
            activeUser?.canAccessTelehealth && {
              label: formatMessage({
                defaultMessage: "Virtual Urgent Care",
                id: "l8KO41",
              }),
              type: SideNavbarItemType.NavLink,
              isExternal: true,
              href: virtualCareUrl,
            },
          ]),
        }
      : null;

    const pharmacyNavGroup: SideNavbarItemProps | null = !hasExpiredCoverage
      ? {
          iconVariant: IconVariant.PILL,
          label: formatMessage({
            defaultMessage: "Pharmacy",
            id: "/P43ZP",
          }),
          type: SideNavbarItemType.NavLink,
          isActive: () => currentHref.includes(PHARMACY_ROUTE),
          onClick: () => {
            trackEvent({
              uiIdentifier:
                MemberPortalMixpanelUiIdentifierName.NAVBAR_PHARMACY_LINK,
              eventName: SharedMixpanelEventName.CLICK,
            });
          },
          href: PHARMACY_ROUTE,
        }
      : null;

    const healthPlanNavGroup: SideNavbarItemProps = {
      iconVariant: IconVariant.HEART_BEAT,
      label: formatMessage({ defaultMessage: "Health Plan", id: "Vd8kTJ" }),
      isActive: () => currentHref.includes("/plan"),
      type: SideNavbarItemType.ExpandableItem,
      nestedNavItems: compact([
        hasAccessToFamilyPage && {
          label: formatMessage({ defaultMessage: "Family", id: "rzCvRt" }),
          type: SideNavbarItemType.NavLink,
          isActive: () =>
            currentHref.includes(MEMBER_PORTAL_FAMILY_ROUTE_RELATIVE),
          href: MEMBER_PORTAL_FAMILY_ROUTE_RELATIVE,
        },
        {
          label: formatMessage({ defaultMessage: "Benefits", id: "KI98Wh" }),
          type: SideNavbarItemType.NavLink,
          isActive: () => currentHref.includes(BENEFITS_ROUTE),
          href: BENEFITS_ROUTE,
        },
        {
          label: formatMessage({
            defaultMessage: "Out-of-Pocket Costs",
            id: "YgdEsK",
          }),
          type: SideNavbarItemType.NavLink,
          isActive: () => currentHref.includes(OUT_OF_POCKET_COSTS_ROUTE),
          href: OUT_OF_POCKET_COSTS_ROUTE,
        },
        {
          label: formatMessage({ defaultMessage: "Claims", id: "ykGhmN" }),
          type: SideNavbarItemType.NavLink,
          isActive: () => currentHref.includes(CLAIMS_ROUTE),
          href: CLAIMS_ROUTE,
        },
        currentMember?.hasClinicalDocuments && {
          label: formatMessage({ defaultMessage: "Documents", id: "vBlT6y" }),
          type: SideNavbarItemType.NavLink,
          isActive: () => currentHref.includes(CLINICAL_DOCS_ROUTE),
          href: CLINICAL_DOCS_ROUTE,
        },
      ]),
    };

    const resourcesNavGroup: SideNavbarItemProps = {
      iconVariant: IconVariant.INFORMATION_CIRCLE,
      label: formatMessage({ defaultMessage: "Resources", id: "c/KktL" }),
      isActive: () =>
        currentHref.includes(FORMS_ROUTE) ||
        currentHref.includes(CARE_COST_ROUTE),
      type: SideNavbarItemType.ExpandableItem,
      nestedNavItems: compact([
        {
          label: formatMessage({
            defaultMessage: "Benefits Booklet",
            id: "sLHmuF",
          }),
          type: SideNavbarItemType.NavLink,
          href: benefitsBookletUrl || "", // Provide a default value for href
          isExternal: true,
        },
        {
          label: formatMessage({ defaultMessage: "Forms", id: "yGsZm0" }),
          isActive: () => currentHref.includes(FORMS_ROUTE),
          type: SideNavbarItemType.NavLink,
          href: FORMS_ROUTE,
        },
        careCostPageNavItem && careCostPageNavItem,
        {
          label: formatMessage({ defaultMessage: "FAQ", id: "W8nHSd" }),
          type: SideNavbarItemType.NavLink,
          href: FAQ_URL,
          isExternal: true,
        },
      ]),
    };

    return compact([
      {
        iconVariant: IconVariant.HOME,
        label: formatMessage({ defaultMessage: "Dashboard", id: "hzSNj4" }),
        isActive: () => currentHref.includes(DASHBOARD_ROUTE),
        href: DASHBOARD_ROUTE,
        type: SideNavbarItemType.NavLink,
      },
      getCareNavGroup,
      pharmacyNavGroup,
      healthPlanNavGroup,
      resourcesNavGroup,
      {
        type: SideNavbarItemType.Divider,
        key: generateArrayKey(),
      },
      !hasExpiredCoverage && {
        iconVariant: IconVariant.MEMBER_CARD,
        label: formatMessage({ defaultMessage: "Member Card", id: "ZdenWh" }),
        isActive: () => currentHref.includes(MEMBER_CARD_ROUTE),
        href: MEMBER_CARD_ROUTE,
        type: SideNavbarItemType.NavLink,
      },
      !currentMember?.isMinor &&
        !currentMember?.closestMemberPlanYear?.isPlanHighDeductible && {
          iconVariant: IconVariant.CREDIT_CARD,
          label: formatMessage({
            defaultMessage: "Zero Card",
            id: "7xE5yZ",
          }),
          type: SideNavbarItemType.NavLink,
          isActive: () => currentHref.includes(MEMBER_PAYMENT_CARD_ROUTE),
          href: MEMBER_PAYMENT_CARD_ROUTE,
        },
      {
        iconVariant: IconVariant.USER,
        label: formatMessage({ defaultMessage: "Profile", id: "itPgxd" }),
        isActive: () => currentHref.includes(PROFILE_ROUTE),
        href: PROFILE_ROUTE,
        type: SideNavbarItemType.NavLink,
      },
      localeNavGroup,
      {
        type: SideNavbarItemType.Divider,
        key: generateArrayKey(),
      },
      {
        iconVariant: IconVariant.LOGOUT,
        label: formatMessage({ defaultMessage: "Log out", id: "PlBReU" }),
        onClick: () => logout(),
        type: SideNavbarItemType.NavButton,
      },
    ]);
  }, [
    hasExpiredCoverage,
    formatMessage,
    activeUser?.canAccessTelehealth,
    virtualCareUrl,
    hasAccessToFamilyPage,
    currentMember?.hasClinicalDocuments,
    currentMember?.isMinor,
    currentMember?.closestMemberPlanYear?.isPlanHighDeductible,
    benefitsBookletUrl,
    careCostPageNavItem,
    localeNavGroup,
    currentHref,
    trackEvent,
    logout,
  ]);

  const handleRouteChangeComplete = () => {
    setIsMobileNavbarOpen(false);
  };

  useEffect(() => {
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUser]);

  return {
    navItems,
  };
};
