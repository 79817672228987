import { Button, ButtonVariant, IconVariant } from "@chp/curative_ui";
import clsx from "clsx";
import {
  FocusEvent as ReactFocusEvent,
  FormEvent as ReactFormEvent,
  useEffect,
  useRef,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  FormattedTextField,
  FormattedTextFieldProps,
} from "../../../components";
import { useSearchAutocompleteInternalContext } from "../contexts";
import { SearchAutocompleteSearchType } from "../enums";
import type { BaseSearchAutocompleteResultData } from "../types";
import {
  SearchAutocompleteResultsMenu,
  SearchAutocompleteResultsMenuProps,
} from "./SearchAutocompleteResults";

export type SearchAutocompleteSearchInputProps = Omit<
  FormattedTextFieldProps,
  "value" | "buttonCaption" | "buttonIcon" | "onClickButton"
>;

export type SearchAutocompleteProps<
  T extends BaseSearchAutocompleteResultData = BaseSearchAutocompleteResultData,
> = SearchAutocompleteResultsMenuProps<T> & {
  testId?: string;
  className?: string;
  resultsListClassName?: string;
  isClearButtonEnabled?: boolean;
  isFreeSearchEnabled?: boolean;
  onSubmit?: (searchInputValue: string) => void | Promise<void>;
  searchInputProps: SearchAutocompleteSearchInputProps;
};

export const SearchAutocomplete = <
  T extends BaseSearchAutocompleteResultData = BaseSearchAutocompleteResultData,
>({
  testId = "search-autocomplete",
  className,
  resultsListClassName,
  isClearButtonEnabled = false,
  isFreeSearchEnabled = false,
  renderSearchResultContent,
  onSearchResultClick,
  onSubmit,
  searchInputProps,
  resultsMenuHeaderContent,
}: SearchAutocompleteProps<T>) => {
  const {
    searchInputValue,
    setSearchInputValue,
    searchType,
    setSearchType,
    setSelectedSearchResult,
    setShouldDisplayAutocomplete,
  } = useSearchAutocompleteInternalContext<T>();

  const { formatMessage } = useIntl();
  const ref = useRef<HTMLDivElement>(null);

  // Close the autocomplete results after clicking or focusing elsewhere.
  // For our purposes, "elsewhere" is an element other than the search input,
  // the search button, or any of the auto-complete options.
  // from https://blog.logrocket.com/detect-click-outside-react-component-how-to/
  useEffect(() => {
    const handleClickOrFocusOutside = (event: MouseEvent | FocusEvent) => {
      if (!ref.current || !event.target) {
        return;
      }

      if (!ref.current.contains(event.target as Node)) {
        setShouldDisplayAutocomplete(false);
      }
    };

    document.addEventListener("click", handleClickOrFocusOutside, true);
    document.addEventListener("focus", handleClickOrFocusOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOrFocusOutside, true);
      document.removeEventListener("focus", handleClickOrFocusOutside, true);
    };
  }, [setShouldDisplayAutocomplete]);

  return (
    <div ref={ref} data-testid={testId} className={className}>
      <form
        data-testid={`${testId}__form`}
        className="md:align-center flex flex-col md:flex-row md:justify-between"
        onSubmit={async (event: ReactFormEvent<HTMLFormElement>) => {
          event.preventDefault();

          if (isFreeSearchEnabled) {
            setSearchType(SearchAutocompleteSearchType.FREE_TEXT);
            setShouldDisplayAutocomplete(false);

            await onSubmit?.(searchInputValue);
          }
        }}
      >
        <FormattedTextField
          {...searchInputProps}
          /* Disable the browser's built-in autocomplete, which otherwise
             overlaps with ours and is generally pointless and annoying */
          autoComplete="off"
          testId={`${testId}__text-field`}
          className={clsx(
            "w-full",
            isFreeSearchEnabled && "mb-2 md:mb-0 md:mr-2"
          )}
          value={searchInputValue}
          onFocus={(event: ReactFocusEvent<HTMLInputElement>) => {
            // Only show the auto-complete menu on focus if the search input
            // is not empty and we don't have an active search type
            if (
              searchInputValue.length !== 0 &&
              searchType === SearchAutocompleteSearchType.NONE
            ) {
              setShouldDisplayAutocomplete(true);
            }

            searchInputProps.onFocus?.(event);
          }}
          onRequestChangeValue={(value: string) => {
            setSearchInputValue(value);

            setSearchType(SearchAutocompleteSearchType.NONE);
            setSelectedSearchResult(undefined);

            // Show the auto-complete list while typing and hide it when the
            // search text is deleted but the input is still focused
            setShouldDisplayAutocomplete(!!value);

            searchInputProps.onRequestChangeValue?.(value);
          }}
          buttonCaption={
            isClearButtonEnabled
              ? formatMessage({
                  defaultMessage: "Clear search text",
                  id: "rKQODU",
                })
              : undefined
          }
          buttonIcon={
            isClearButtonEnabled && searchInputValue ? IconVariant.X : undefined
          }
          onClickButton={() => {
            setSearchInputValue("");
            setSearchType(SearchAutocompleteSearchType.NONE);
            setSelectedSearchResult(undefined);
            setShouldDisplayAutocomplete(false);
          }}
        />
        {isFreeSearchEnabled && (
          <Button
            testId={`${testId}__search-button`}
            variant={ButtonVariant.SmallPrimary}
            type="submit"
          >
            <FormattedMessage defaultMessage="Search" id="xmcVZ0" />
          </Button>
        )}
      </form>
      <SearchAutocompleteResultsMenu
        testId={`${testId}__results`}
        className={resultsListClassName}
        renderSearchResultContent={renderSearchResultContent}
        onSearchResultClick={onSearchResultClick}
        resultsMenuHeaderContent={resultsMenuHeaderContent}
      />
    </div>
  );
};
