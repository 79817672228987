import { Menu } from "@headlessui/react";
import clsx from "clsx";
import { ForwardedRef, forwardRef } from "react";

import {
  FOCUS_CLASS_NAMES,
  FOCUS_LIKE_CLASS_NAMES,
  FOCUS_NONE_CLASS_NAMES,
  SpacingVariant,
} from "../../constants";
import { cuiClassNames } from "../cuiClassNames";
import { Icon, IconVariant } from "../Icon";
import { CUIComponentProps, TextVariant } from "../types";

export type LocaleOption<T> = {
  label: string;
  value: T;
};

export type LocalePickerProps<T> = CUIComponentProps<{
  localeOptions: LocaleOption<T>[];
  selectedLocaleOption: LocaleOption<T>;
  onClickLocaleOption: (_: LocaleOption<T>) => void;
  onClickLanguageMenu?: () => void;
}>;

// GHP-8386: If the user is using chrome's builtin Translator and our LocalePicker simultaneously, it can
// crash the page. React will not address it, but has provided the following code as a workaround:
// https://github.com/facebook/react/issues/11538#issuecomment-417504600
export const patchLocaleButtonForGoogleTranslate = () => {
  if (typeof Node === "function" && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    // @ts-ignore
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.warn(
            "Cannot remove a child from a different parent",
            child,
            this
          );
        }
        return child;
      }
      // @ts-ignore
      // eslint-disable-next-line prefer-rest-params
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    // @ts-ignore
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.warn(
            "Cannot insert before a reference node from a different parent",
            referenceNode,
            this
          );
        }
        return newNode;
      }
      // @ts-ignore
      // eslint-disable-next-line prefer-rest-params
      return originalInsertBefore.apply(this, arguments);
    };
  }
};

const LocaleOptionButton = forwardRef(
  (
    {
      children,
      hasFocus,
      onClick,
      testId,
      ...rest
    }: {
      children: string;
      hasFocus: boolean;
      onClick: () => void;
      testId: string;
    },
    ref: ForwardedRef<HTMLButtonElement>
  ): JSX.Element => {
    return (
      <button
        className={cuiClassNames({
          className: clsx(
            "whitespace-nowrap",
            hasFocus ? FOCUS_LIKE_CLASS_NAMES : null
          ),
          padding: {
            bottom: SpacingVariant.S16,
            left: SpacingVariant.S16,
            right: SpacingVariant.S16,
            top: SpacingVariant.S16,
          },
          textVariant: TextVariant.MdBold,
        })}
        onClick={onClick}
        ref={ref}
        data-testid={testId}
        type="button"
        {...rest}
      >
        {children}
      </button>
    );
  }
);
LocaleOptionButton.displayName = "LocaleOptionButton";

export function LocalePicker<T>({
  localeOptions,
  selectedLocaleOption,
  onClickLocaleOption,
  onClickLanguageMenu,
  testId = "LocalePicker",
}: LocalePickerProps<T>): JSX.Element {
  return (
    <Menu as="div" className="relative bg-inherit" data-testid={testId}>
      {({ open }) => (
        <>
          <Menu.Button
            data-testid={`${testId}__ToggleMenuButton`}
            className={clsx(
              "flex",
              "gap-x-1",
              "md:gap-x-2",
              "items-center",
              FOCUS_CLASS_NAMES
            )}
            onClick={onClickLanguageMenu}
          >
            <Icon variant={IconVariant.GLOBE_ALT} size={SpacingVariant.S24} />
            <p
              className={cuiClassNames({
                className: "capitalize",
                textVariant: TextVariant.MdBold,
              })}
            >
              {selectedLocaleOption.label}
            </p>

            <Icon
              variant={open ? IconVariant.CHEVRON_UP : IconVariant.CHEVRON_DOWN}
              size={SpacingVariant.S20}
              className="stroke-2"
            />
          </Menu.Button>
          <Menu.Items
            className={clsx(
              "absolute",
              "flex",
              "flex-col",
              "bg-inherit",
              "border",
              "border-solid",
              "border-current",
              "rounded",
              "mt-4",
              FOCUS_NONE_CLASS_NAMES
            )}
          >
            {localeOptions
              .filter(({ value }) => value !== selectedLocaleOption.value)
              .map((localeOption: LocaleOption<T>) => {
                const { label } = localeOption;
                return (
                  <Menu.Item key={label}>
                    {({
                      // focused/active locale option button in dropdown list. not the selected locale.
                      active: hasFocus,
                    }) => (
                      <LocaleOptionButton
                        hasFocus={hasFocus}
                        onClick={() => {
                          patchLocaleButtonForGoogleTranslate();
                          onClickLocaleOption(localeOption);
                        }}
                        testId={`${testId}__LocaleOptionButton`}
                      >
                        {label}
                      </LocaleOptionButton>
                    )}
                  </Menu.Item>
                );
              })}
          </Menu.Items>
        </>
      )}
    </Menu>
  );
}
