import { Dialog } from "@headlessui/react";
import clsx from "clsx";
import { PropsWithChildren } from "react";

import { SpacingVariant } from "../../constants";
import {
  Button,
  ButtonVariant,
  CUIComponent,
  CUIComponentProps,
  DIALOG_BACKGROUND_COLOR_CLASS_NAME,
  Icon,
  IconVariant,
  ModalMaxWidth,
  ScreenReaderOnly,
  Spinner,
  TextVariant,
} from "..";
import { MODAL_VARIANT_MAX_WIDTHS, TEXT_CLASS_NAMES } from "../constants";

export type ModalProps = CUIComponentProps<
  PropsWithChildren<{
    buttonText?: string | JSX.Element;
    closeButtonText?: string;
    /**
     * If true, then on mobile (smaller than lg) screen sizes, the modal will
     * be stretched to take up the entire screen expect the Navbar (which we
     * presume to be present).
     */
    fullScreenOnMobile?: boolean;
    id?: string;
    isButtonDisabled?: boolean;
    isButtonLoading?: boolean;
    isOpen: boolean;
    maxWidth?: ModalMaxWidth;
    message?: string | JSX.Element;
    onButtonClick?: () => void;
    onButtonClickSecondary?: () => void;
    onRequestClose: () => void;
    secondaryButtonText?: string;
    styleVariant?: "default" | "simple";
    title: string;
  }>
>;

export const Modal: CUIComponent<ModalProps> = ({
  buttonText,
  children,
  className,
  closeButtonText,
  fullScreenOnMobile = false,
  id,
  isButtonDisabled,
  isButtonLoading,
  isOpen,
  maxWidth = ModalMaxWidth.Small,
  message,
  onButtonClick,
  onButtonClickSecondary,
  onRequestClose,
  secondaryButtonText,
  styleVariant = "default",
  testId = "Modal",
  title,
}) => {
  return (
    <Dialog
      data-testid={testId}
      open={isOpen}
      as="div"
      className={clsx(
        "fixed",
        "z-50",
        "inset-0",
        "overflow-y-auto",
        DIALOG_BACKGROUND_COLOR_CLASS_NAME,
        className
      )}
      onClose={onRequestClose}
    >
      <div id={id} className="flex min-h-full items-center justify-center">
        <Dialog.Panel
          className={clsx(
            fullScreenOnMobile
              ? "max-w-screen-md"
              : MODAL_VARIANT_MAX_WIDTHS[maxWidth],
            "bg-surface-default",
            "rounded shadow-xl",
            styleVariant === "default" ? "p-6" : "p-3",
            "md:my-auto",
            styleVariant === "simple" && "border-2 border-borderColor-default",
            fullScreenOnMobile &&
              "md:flex-0 w-full flex-1 self-stretch md:self-center",
            fullScreenOnMobile ? "mt-16" : "mt-[25%]",
            fullScreenOnMobile ? "md:mx-auto" : "mx-auto"
          )}
        >
          <div className="flex flex-row justify-between">
            <Dialog.Title
              // TODO: support other elements
              as="h3"
              className={clsx(
                styleVariant === "default"
                  ? "text-textColor-highlight-default"
                  : "mx-auto text-textColor-default",
                styleVariant === "default"
                  ? TEXT_CLASS_NAMES[TextVariant.XlBoldTall]
                  : TEXT_CLASS_NAMES[TextVariant.MdBold]
              )}
            >
              {title}
            </Dialog.Title>
            {closeButtonText && (
              <button
                data-testid={`${testId}__CloseButton`}
                onClick={onRequestClose}
              >
                <Icon
                  size={SpacingVariant.S24}
                  variant={IconVariant.X}
                  className="text-textColor-subdued"
                />
                <ScreenReaderOnly element="span">
                  {closeButtonText}
                </ScreenReaderOnly>
              </button>
            )}
          </div>

          {message && (
            <p
              data-testid={`${testId}__Message`}
              className={clsx(
                "my-4",
                styleVariant === "default"
                  ? TEXT_CLASS_NAMES[TextVariant.LgRegularTall]
                  : TEXT_CLASS_NAMES[TextVariant.MdRegularTall]
              )}
            >
              {message}
            </p>
          )}

          {children && (
            <div data-testid={`${testId}__Children`}>{children}</div>
          )}

          {buttonText && (
            <Button
              variant={ButtonVariant.FullWidthPrimary}
              onClick={onButtonClick}
              isDisabled={isButtonDisabled || isButtonLoading}
            >
              <div className="flex items-center justify-center">
                <>
                  {isButtonLoading && (
                    <div className="mr-2">
                      <Spinner
                        size={SpacingVariant.S16}
                        className="stroke-gray-800"
                      />
                    </div>
                  )}
                  {buttonText}
                </>
              </div>
            </Button>
          )}

          {secondaryButtonText && (
            <Button
              className="mt-4"
              variant={ButtonVariant.FullWidthOutline}
              onClick={onButtonClickSecondary}
            >
              {secondaryButtonText}
            </Button>
          )}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
