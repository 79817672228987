import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import { FOCUS_CLASS_NAMES, SpacingVariant } from "../../constants";
import { Box } from "../Box";
import { Icon, IconVariant } from "../Icon";
import { Tooltip } from "../Tooltip";
import { CUIComponentProps, TextVariant } from "../types";
import { DividerItem } from "./DividerItem";
import { FloatingMenu } from "./FloatingMenu";
import { NavButton, NavButtonProps } from "./NavButton";
import { NavLink } from "./NavLink";
import {
  ACTIVE_LINK_CLASSNAMES,
  INACTIVE_LINK_CLASSNAMES,
  SideNavbarItemProps,
  SideNavbarItemType,
} from "./SideNavbar";
import { IconOrSpacer } from "./utils";

export enum ExpandableMenuItemActionEventName {
  EXPAND = "EXPAND",
  COLLAPSE = "COLLAPSE",
}

export type ExpandableMenuItemActionEvent =
  | ExpandableMenuItemActionEventName.EXPAND
  | ExpandableMenuItemActionEventName.COLLAPSE;

export type ExpandableItemProps = CUIComponentProps<{
  label: string;
  nestedNavItems: SideNavbarItemProps[];
  isActive?: () => boolean;
  isNavbarCollapsed?: boolean;
  iconVariant?: IconVariant;
  itemClickEvent?: (menuAction: ExpandableMenuItemActionEvent) => void;
}>;

export const ExpandableItem: FC<ExpandableItemProps> = ({
  label,
  nestedNavItems,
  isActive,
  isNavbarCollapsed,
  iconVariant,
  className,
  testId = "ExpandableItem",
  itemClickEvent,
}) => {
  const [isItemExpanded, setIsItemExpanded] = useState(isActive?.() ?? false);
  const [tooltipTriggerElement, setTooltipTriggerElement] =
    useState<HTMLButtonElement | null>(null);

  const isFloatingMenuVisible = isItemExpanded && isNavbarCollapsed;
  const areNestedItemsVisible = isItemExpanded && !isNavbarCollapsed;

  useEffect(() => {
    if (isNavbarCollapsed) {
      setIsItemExpanded(false);
    }
  }, [isNavbarCollapsed]);

  return (
    <div className={clsx("relative w-full", className)} data-testid={testId}>
      <button
        aria-expanded={isItemExpanded}
        ref={setTooltipTriggerElement}
        type="button"
        className={clsx(
          "relative",
          "focus:z-40",
          "focus-visible:z-40",
          "w-full px-4 py-3",
          "grid items-center",
          "hover:text-textColor-highlight-default",
          isNavbarCollapsed
            ? "justify-center px-2"
            : "grid-cols-[40px_1fr_auto]",
          isActive?.() ? ACTIVE_LINK_CLASSNAMES : INACTIVE_LINK_CLASSNAMES,
          FOCUS_CLASS_NAMES
        )}
        onClick={() => {
          setIsItemExpanded(!isItemExpanded);
          itemClickEvent?.(
            isItemExpanded
              ? ExpandableMenuItemActionEventName.COLLAPSE
              : ExpandableMenuItemActionEventName.EXPAND
          );
        }}
      >
        {isNavbarCollapsed ? (
          <>
            <IconOrSpacer iconVariant={iconVariant} testId={testId} />
            {!isFloatingMenuVisible && (
              <Tooltip
                triggerElement={tooltipTriggerElement}
                message={label}
                fitMessage
              />
            )}
          </>
        ) : (
          <>
            <IconOrSpacer iconVariant={iconVariant} testId={testId} />
            <Box
              element="span"
              textVariant={
                isActive?.() ? TextVariant.LgBoldShort : TextVariant.LgRegular
              }
              className={clsx(
                "whitespace-nowrap",
                "overflow-hidden",
                "text-ellipsis",
                "text-left"
              )}
            >
              {label}
            </Box>
            <Icon
              variant={
                isItemExpanded
                  ? IconVariant.CHEVRON_UP
                  : IconVariant.CHEVRON_DOWN
              }
              size={SpacingVariant.S24}
            />
          </>
        )}
      </button>

      {isFloatingMenuVisible && (
        <OutsideClickHandler onOutsideClick={() => setIsItemExpanded(false)}>
          <FloatingMenu
            itemsList={nestedNavItems}
            setIsItemExpanded={setIsItemExpanded}
          />
        </OutsideClickHandler>
      )}

      {areNestedItemsVisible &&
        nestedNavItems.map((item, idx) => {
          switch (item.type) {
            case SideNavbarItemType.NavButton:
              return (
                <NavButton
                  key={item.label}
                  testId={`${testId}__${item.label}__NavButton`}
                  isNavbarCollapsed={isNavbarCollapsed}
                  {...(item as NavButtonProps)}
                />
              );
            case SideNavbarItemType.NavLink:
              return (
                <NavLink
                  key={item.label}
                  testId={`${testId}__${item.label}__NavLink`}
                  isNavbarCollapsed={isNavbarCollapsed}
                  {...item}
                />
              );
            case SideNavbarItemType.NavItem:
              return (
                <NavButton
                  key={item.label}
                  testId={`${testId}__${item.label}__NavItem`}
                  isNavbarCollapsed={isNavbarCollapsed}
                  {...(item as NavButtonProps)}
                />
              );
            case SideNavbarItemType.ExpandableItem:
              return (
                <ExpandableItem
                  className="border-l-4"
                  key={item.label}
                  isNavbarCollapsed={isNavbarCollapsed}
                  testId={`${testId}__ExpandableItem`}
                  {...(item as ExpandableItemProps)}
                />
              );
            case SideNavbarItemType.Divider:
              return (
                <div className="border-l-4">
                  {/* eslint-disable-next-line */}
                  <DividerItem key={idx} />
                </div>
              );
            default:
              return null;
          }
        })}
    </div>
  );
};
