import { Auth0Client } from "@auth0/auth0-spa-js";
import invariant from "invariant";
import { useMemo } from "react";

export const AUTH0_DOMAIN = process.env.NEXT_PUBLIC_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID;
export const AUTH0_AUDIENCE = process.env.NEXT_PUBLIC_AUTH0_AUDIENCE;
export const AUTH0_COOKIE_DOMAIN = ".curative.com";

export const useAuth0Client = (): Auth0Client => {
  invariant(
    AUTH0_DOMAIN && AUTH0_CLIENT_ID && AUTH0_AUDIENCE,
    "Missing required parameters for Auth0"
  );

  return useMemo(
    () =>
      new Auth0Client({
        domain: AUTH0_DOMAIN,
        clientId: AUTH0_CLIENT_ID,
        authorizationParams: {
          audience: AUTH0_AUDIENCE,
          redirect_uri:
            typeof window !== "undefined" ? window.location.origin : undefined,
        },
        cookieDomain: AUTH0_COOKIE_DOMAIN,
        useRefreshTokens: true,
        useRefreshTokensFallback: true,
      }),
    []
  );
};
