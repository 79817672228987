import { PasswordStrength } from "../../types";
import {
  containsNumberRegex,
  containsSymbolRegex,
  containsUpperAndLowerCaseLetterRegex,
} from "../string";

export const calculatePasswordStrength = ({
  email,
  familyName,
  givenName,
  password,
}: {
  email: string;
  familyName: string;
  givenName: string;
  password: string;
}): PasswordStrength => {
  if (!password) {
    return PasswordStrength.WEAK;
  }

  const containsNameOrEmail =
    password.includes(givenName) ||
    password.includes(familyName) ||
    password.includes(email);

  const isValidPassword =
    containsNumberRegex.test(password) &&
    containsSymbolRegex.test(password) &&
    containsUpperAndLowerCaseLetterRegex.test(password) &&
    passwordMeetsLengthRequirement(password) &&
    !containsNameOrEmail;

  if (isValidPassword) {
    return PasswordStrength.STRONG;
  }

  return PasswordStrength.WEAK;
};

export const passwordMeetsLengthRequirement = (password: string) => {
  return password.length >= 12;
};
