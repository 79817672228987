import { compact } from "lodash-es";

import {
  DEFAULT_LOCALE_CODE,
  languageToLocaleCodes,
  LocaleCode,
} from "../../features";
import { MemberPreferences } from "../../generated/memberPortalApi.graphql";
import { getLocaleCodeFromCookie } from "./getLocaleCodeFromCookie";
import { getLocaleCodesFromBrowser } from "./getLocaleCodesFromBrowser";

export const getLocaleCodesToDisplay = (
  userPreferredLanguage?: MemberPreferences["preferredLanguage"]
): LocaleCode[] => {
  const localeCodes: LocaleCode[] = [];

  const localeCodeFromUserPreference =
    userPreferredLanguage && languageToLocaleCodes[userPreferredLanguage];
  if (localeCodeFromUserPreference)
    localeCodes.push(localeCodeFromUserPreference);

  const localeCodeFromCookie = getLocaleCodeFromCookie({});
  if (localeCodeFromCookie) localeCodes.push(localeCodeFromCookie);

  const localeCodesFromBrowser = getLocaleCodesFromBrowser({});
  localeCodes.push(...localeCodesFromBrowser);

  localeCodes.push(DEFAULT_LOCALE_CODE);

  return compact(localeCodes);
};
