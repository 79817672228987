import { Dialog } from "@headlessui/react";
import clsx from "clsx";
import { ReactNode } from "react";

import { SpacingVariant } from "../../constants";
import { Box } from "../Box";
import { Button, ButtonProps, ButtonVariant } from "../Button";
import { FOCUS_RING_CLASS_NAMES } from "../constants";
import { DialogWithBackdrop } from "../dialogs";
import { Icon, IconVariant } from "../Icon";
import { ModalProps } from "../Modal";
import { ScreenReaderOnly } from "../ScreenReaderOnly";
import { Spinner } from "../Spinner";
import { CUIComponent, CUIComponentProps } from "../types";

export type SideDrawerProps = CUIComponentProps<
  Pick<
    ModalProps,
    "children" | "closeButtonText" | "isOpen" | "title" | "isButtonLoading"
  > & {
    sideDrawerClassName?: string;
    cancelText?: string;
    confirmText?: string;
    footerContent?: ReactNode;
    isConfirmBtnDisabled?: boolean;
    onCancelClick?: () => void;
    onCloseBtnClick: () => void;
    onConfirmClick?: () => void;
    // use onFormSubmit instead of onConfirmClick if we want a form with submit button
    onFormSubmit?: () => void;
    sideDrawerWidth?: string;
  }
>;

export const SideDrawer: CUIComponent<SideDrawerProps> = ({
  cancelText,
  children,
  className,
  sideDrawerClassName,
  closeButtonText,
  confirmText,
  footerContent,
  isButtonLoading,
  isConfirmBtnDisabled,
  isOpen,
  sideDrawerWidth,
  onCancelClick,
  onCloseBtnClick,
  onConfirmClick,
  onFormSubmit,
  testId = "SideDrawer",
  title,
}) => {
  // check if this is a form
  const hasConfirmButton: boolean = !!onFormSubmit || !!onConfirmClick;
  const {
    confirmButtonClickHandler,
    confirmButtonType,
    contentWrapperElement,
  }: {
    confirmButtonClickHandler: (() => void) | undefined;
    confirmButtonType: ButtonProps["type"];
    contentWrapperElement: keyof JSX.IntrinsicElements;
  } = onFormSubmit
    ? {
        confirmButtonClickHandler: undefined,
        confirmButtonType: "submit",
        contentWrapperElement: "form",
      }
    : {
        confirmButtonClickHandler: onConfirmClick,
        confirmButtonType: undefined,
        contentWrapperElement: "div",
      };

  const hasCtaSection: boolean = hasConfirmButton || !!onCancelClick;

  return (
    <DialogWithBackdrop
      className={className}
      isOpen={isOpen}
      onClose={onCloseBtnClick}
      testId={testId}
      title={title}
    >
      <Dialog.Panel>
        <Box
          className={clsx(
            "bg-white",
            "fixed",
            "flex",
            "p-6",
            // fill entire screen on mobile https://tailwindcss.com/docs/top-right-bottom-left
            "inset-0",
            // fix to right side on desktop
            "md:left-auto",
            sideDrawerWidth ? sideDrawerWidth : "md:w-96",
            sideDrawerClassName
          )}
          element="div"
          testId={`${testId}__PanelInner`}
        >
          <Box
            className={clsx(
              "bg-white",
              "absolute",
              "left-4",
              "top-4",
              "w-full",
              "z-10"
            )}
            element="div"
          >
            <button
              type="button"
              onClick={onCloseBtnClick}
              data-testid={`${testId}__CloseButton`}
              className={clsx(
                FOCUS_RING_CLASS_NAMES,
                "text-gray-700",
                "active:text-gray-600"
              )}
            >
              <Icon variant={IconVariant.X} size={SpacingVariant.S24} />
              <ScreenReaderOnly element="span">
                {closeButtonText}
              </ScreenReaderOnly>
            </button>
          </Box>
          <Box
            className={clsx("relative", "w-full")}
            element={contentWrapperElement}
            onSubmit={onFormSubmit}
          >
            <div
              className={clsx(
                "overflow-y-auto",
                hasCtaSection ? "h-[calc(100%-7rem)]" : "size-full"
              )}
            >
              <Box className="pt-12" element="div">
                {children}
              </Box>
            </div>

            {hasCtaSection && (
              <Box
                className={clsx(
                  "absolute",
                  "bg-white",
                  "bottom-0",
                  "flex",
                  "flex-col",
                  "w-full",
                  "items-center",
                  "h-28"
                )}
                element="section"
                padding={{ top: SpacingVariant.S16 }}
                testId={`${testId}__CTAButtonContainer`}
              >
                {footerContent}
                {hasConfirmButton && confirmText && (
                  <Button
                    isDisabled={isConfirmBtnDisabled || isButtonLoading}
                    onClick={confirmButtonClickHandler}
                    testId={`${testId}__ConfirmButton`}
                    type={confirmButtonType}
                    variant={ButtonVariant.BigPrimary}
                  >
                    {isButtonLoading ? (
                      <Spinner
                        size={SpacingVariant.S16}
                        className="stroke-gray-800"
                      />
                    ) : (
                      confirmText
                    )}
                  </Button>
                )}

                {onCancelClick && cancelText && (
                  <Button
                    className="mt-3"
                    variant={ButtonVariant.Flat}
                    onClick={onCancelClick}
                  >
                    {cancelText}
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Dialog.Panel>
    </DialogWithBackdrop>
  );
};
