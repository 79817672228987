import clsx from "clsx";

import { TEXT_CLASS_NAMES } from "../constants";
import { CUIComponentProps, TextVariant } from "../types";

const PROGRESS_BAR_CLASS_NAMES: string[] = [
  "relative",
  "w-full",
  "h-1",
  "bg-surface-disabled",
  "rounded-sm",
  "mt-2",
  ...TEXT_CLASS_NAMES[TextVariant.XsRegular],
];

const PROGRESS_INDICATOR_CLASS_NAMES: string[] = [
  "h-1",
  "bg-foundation-accentA",
  "flex",
  "rounded-l-[2px]",
  "rounded-r-[1px]",
];

const SECTION_CLASS_NAMES: string[] = [
  "absolute",
  "top-1/2",
  "-translate-x-[4px]",
  "-translate-y-1/2",
  "w-2",
  "h-2",
  "rounded-full",
];

const SECTION_NAME_CLASS_NAMES: string[] = [
  "-mt-5",
  "whitespace-nowrap",
  "flex",
];

export type ProgressBarProps = CUIComponentProps<{
  sections: { name: string; steps: string[] }[];
  currentStep: string;
}>;

export function ProgressBar({
  sections,
  currentStep,
  testId = "ProgressBar",
  className,
}: ProgressBarProps) {
  if (sections.length <= 1) {
    throw new Error("progress bars must have more than one section");
  }
  const currentSectionIndex = sections.findIndex((s) =>
    s.steps.includes(currentStep)
  );
  const currentSection = sections[currentSectionIndex];
  if (currentSection == null) {
    throw new Error(`could not find section with step {currentStep}`);
  }
  if (currentSection.steps.length === 0) {
    throw new Error(`sections must have at least one step`);
  }
  const currentStepIndex = currentSection.steps.indexOf(currentStep);

  const sectionProgress = currentSectionIndex / (sections.length - 1);
  const stepProgress =
    currentStepIndex / currentSection.steps.length / (sections.length - 1);
  const indicatorWidth = Math.floor((sectionProgress + stepProgress) * 100);

  return (
    <div
      data-testid={testId}
      className={clsx(PROGRESS_BAR_CLASS_NAMES, className)}
    >
      {sections.map((section, i) => {
        const sectionLeft = Math.floor((i / (sections.length - 1)) * 100);

        return (
          <div
            data-testid={`${testId}__Section`}
            className={clsx(
              SECTION_CLASS_NAMES,
              i <= currentSectionIndex
                ? "bg-foundation-accentA"
                : "bg-surface-dark"
            )}
            key={section.name}
            style={{ left: `${sectionLeft}%` }}
          >
            {i === currentSectionIndex ? (
              <div
                data-testid={`${testId}__SectionName`}
                className={clsx(
                  SECTION_NAME_CLASS_NAMES,
                  i === 0
                    ? "justify-start"
                    : i === sections.length - 1
                    ? "justify-end"
                    : "justify-center"
                )}
              >
                {section.name}
              </div>
            ) : null}
          </div>
        );
      })}
      <div
        className={clsx(PROGRESS_INDICATOR_CLASS_NAMES)}
        style={{ width: `${indicatorWidth}%` }}
        data-testid={`${testId}__Indicator`}
      />
    </div>
  );
}
