import clsx from "clsx";

import { useWithArrayKeys } from "../../hooks";
import { generateArrayKey } from "../../utils";
import { Box } from "../Box";
import { Card } from "../Card";

/**
 *
 * @param parentCount The number of cards you want shown
 * @param childrenCount The number of lines you want inside of each card to look like sentences
 * @param parentClassOverrides Override the default class names for the parent cards
 * @param childClassOverrides Override the default class names for the sentences inside of the parent cards
 */
export const CardSkeletons = ({
  parentCount = 1,
  childrenCount = 3,
  parentClassOverrides = "",
  childClassOverrides = "",
  testId = "CardSkeletons",
  className = "",
}) => {
  // Default class names
  const defaultParentClassNames = clsx(
    "animate-pulse",
    "bg-gray-100",
    "py-4",
    "h-28",
    parentClassOverrides
  );

  const defaultChildClassNames = clsx(
    "animate-pulse",
    "bg-gray-200",
    "p-2",
    "rounded",
    childClassOverrides
  );

  const parentArrKeys = useWithArrayKeys({
    array: Array.from({ length: parentCount }),
    keyGenerator: generateArrayKey,
  });

  const renderParentCards = () => {
    return parentArrKeys.map(({ key }, index) => (
      <Card
        key={`parent-${key}`}
        className={clsx(
          defaultParentClassNames,
          index !== parentCount - 1 ? "mb-3" : ""
        )}
        testId={`${testId}__ParentCard${index}`}
      >
        {renderChildBlocks(index)}
      </Card>
    ));
  };

  const childrenArrKeys = useWithArrayKeys({
    array: Array.from({ length: childrenCount }),
    keyGenerator: generateArrayKey,
  });

  const renderChildBlocks = (parentIdx: number) => {
    return childrenArrKeys.map(({ key }, index) => (
      <Box
        element="div"
        key={`child-${key}`}
        className={clsx(
          defaultChildClassNames,
          index !== childrenCount - 1 ? "mb-1" : "w-3/4"
        )}
        testId={`${testId}__ChildCard${parentIdx + "." + index}`}
      />
    ));
  };

  return <div className={clsx(className)}>{renderParentCards()}</div>;
};
