import { FOCUS_CLASS_NAMES, SpacingVariant } from "../constants";
import { PaddingSize } from "../types";

export const buttonBaseClassNames: string[] = [
  "text-center",
  "disabled:cursor-not-allowed",
  ...FOCUS_CLASS_NAMES,
];

export const buttonPaddingSmall: PaddingSize = {
  bottom: SpacingVariant.S6,
  left: SpacingVariant.S16,
  right: SpacingVariant.S16,
  top: SpacingVariant.S6,
};
