import clsx from "clsx";
import { ReactNode } from "react";

import { Box } from "../Box";
import {
  CUIComponent,
  CUIComponentProps,
  HeadingLevel,
  TextVariant,
} from "../types";

export type CardProps = CUIComponentProps<{
  // for more custom headings, leave this empty & pass custom heading with children prop
  children?: ReactNode;
  heading?: string | JSX.Element;
  headingClassName?: string;
  headingLevel?: HeadingLevel;
  hasNoBorder?: boolean;
}>;

export const Card: CUIComponent<CardProps> = ({
  children,
  className,
  heading,
  headingClassName,
  headingLevel = "h2",
  hasNoBorder = false,
  testId = "Card",
}): JSX.Element => {
  return (
    <Box
      element="div"
      testId={testId}
      className={clsx(
        !hasNoBorder && "border",
        "rounded-lg",
        "py-6",
        "px-4",
        className
      )}
    >
      {heading && (
        <Box
          element={headingLevel}
          textVariant={TextVariant.LgBoldShort}
          className={clsx("mb-4", headingClassName)}
        >
          {heading}
        </Box>
      )}
      {children}
    </Box>
  );
};
