export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** An arbitrary precision signed decimal */
  BigDecimal: { input: number; output: number; }
  /** An RFC-3339 compliant Full Date Scalar */
  Date: { input: string; output: string; }
  /** A slightly refined version of RFC-3339 compliant DateTime Scalar */
  DateTime: { input: string; output: string; }
  /** A 64-bit signed integer */
  Long: { input: number; output: number; }
  /** UUID type */
  UUID: { input: string; output: string; }
};

export enum AccessControlRate {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  None = 'NONE'
}

export enum AccessControlScope {
  AdminsOnly = 'ADMINS_ONLY',
  EmployersOnly = 'EMPLOYERS_ONLY',
  MembersOnly = 'MEMBERS_ONLY',
  Public = 'PUBLIC'
}

export enum AppointmentType {
  InPerson = 'IN_PERSON',
  OnSite = 'ON_SITE',
  Virtual = 'VIRTUAL'
}

export enum BaselineStatus {
  Completed = 'COMPLETED',
  NotCompleted = 'NOT_COMPLETED',
  NotRequired = 'NOT_REQUIRED',
  Scheduled = 'SCHEDULED'
}

export type BaselineVisitStatus = {
  __typename?: 'BaselineVisitStatus';
  baselineStatus: BaselineStatus;
  baselineVisit?: Maybe<Visit>;
  completeAt?: Maybe<Scalars['DateTime']['output']>;
  daysUntilDueDate: Scalars['Int']['output'];
  displayedDueDate: Scalars['Date']['output'];
  healthPlanMemberToken: Scalars['UUID']['output'];
  isBeforeDueDate: Scalars['Boolean']['output'];
  isComplete: Scalars['Boolean']['output'];
  originalDueDate: Scalars['Date']['output'];
  visitId?: Maybe<Scalars['Int']['output']>;
  visitRequired: Scalars['Boolean']['output'];
};

export type BasicMember = CommonMemberGql & {
  __typename?: 'BasicMember';
  /** Returns Member's address if exists, or Member subscriber's address otherwise */
  address?: Maybe<MemberAddress>;
  birthDate: Scalars['Date']['output'];
  email?: Maybe<Scalars['String']['output']>;
  /** @deprecated The term externalId is being phased out, replace with id */
  externalId: Scalars['String']['output'];
  familyName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  givenName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isMinor: Scalars['Boolean']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  portalRegistrationStatus: MemberPortalRegistrationStatus;
  relationship: Relationship;
  sex?: Maybe<Scalars['String']['output']>;
};


export type BasicMemberIsMinorArgs = {
  atDate?: InputMaybe<Scalars['Date']['input']>;
};

export type CareNavigator = {
  __typename?: 'CareNavigator';
  activeForScheduling: Scalars['Boolean']['output'];
  dockHealthId?: Maybe<Scalars['String']['output']>;
  elationId: Scalars['Long']['output'];
  elationTag: Scalars['String']['output'];
  eligibleForAutoAssignment: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  handledAppointmentTypes?: Maybe<Array<AppointmentType>>;
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  languages?: Maybe<Array<CareTeamMemberLanguages>>;
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
};

export enum CareTeamMemberLanguages {
  English = 'ENGLISH',
  Spanish = 'SPANISH'
}

export type ChildEmployer = {
  __typename?: 'ChildEmployer';
  hccId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Claim = {
  __typename?: 'Claim';
  allowableAmount?: Maybe<Scalars['Float']['output']>;
  billedAmount?: Maybe<Scalars['Float']['output']>;
  checkNumber?: Maybe<Scalars['String']['output']>;
  claimSystem: ClaimSystem;
  coInsuranceAmount?: Maybe<Scalars['Float']['output']>;
  copayAmount?: Maybe<Scalars['Float']['output']>;
  deductibleAmount?: Maybe<Scalars['Float']['output']>;
  eobUrl?: Maybe<Scalars['String']['output']>;
  memberExternalId: Scalars['String']['output'];
  paidAmount?: Maybe<Scalars['Float']['output']>;
  provider?: Maybe<ClaimProvider>;
  /** @deprecated Use `provider.name` instead */
  providerName: Scalars['String']['output'];
  referenceId?: Maybe<Scalars['String']['output']>;
  serviceEndDate: Scalars['Date']['output'];
  serviceStartDate: Scalars['Date']['output'];
  status: ClaimStatus;
  type: ClaimType;
};

export type ClaimProvider = {
  __typename?: 'ClaimProvider';
  name: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
};

export enum ClaimStatus {
  Denied = 'DENIED',
  DeniedPartial = 'DENIED_PARTIAL',
  Error = 'ERROR',
  NotAdjudicated = 'NOT_ADJUDICATED',
  Paid = 'PAID',
  Payable = 'PAYABLE',
  Pended = 'PENDED',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
  Refund = 'REFUND',
  Unknown = 'UNKNOWN',
  Void = 'VOID'
}

export enum ClaimSystem {
  HealthEdge = 'HEALTH_EDGE',
  Vba = 'VBA'
}

export enum ClaimType {
  Dental = 'DENTAL',
  Disability = 'DISABILITY',
  Flex = 'FLEX',
  Institutional = 'INSTITUTIONAL',
  Life = 'LIFE',
  Pharmacy = 'PHARMACY',
  Professional = 'PROFESSIONAL',
  Unknown = 'UNKNOWN'
}

export type Clinician = {
  __typename?: 'Clinician';
  credentials: Scalars['String']['output'];
  elationId: Scalars['Long']['output'];
  eligibleStates?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  firstName: Scalars['String']['output'];
  handledAppointmentTypes?: Maybe<Array<Maybe<AppointmentType>>>;
  hourInterval?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  isActive: Scalars['Boolean']['output'];
  languages?: Maybe<Array<Maybe<CareTeamMemberLanguages>>>;
  lastName: Scalars['String']['output'];
  licensedStates?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  physicianType?: Maybe<ElationPhysicianType>;
  providesPrimaryCare?: Maybe<Scalars['Boolean']['output']>;
  zoomEmail?: Maybe<Scalars['String']['output']>;
};

export type CommonMemberGql = {
  /** Returns Member's address if exists, or Member subscriber's address otherwise */
  address?: Maybe<MemberAddress>;
  birthDate: Scalars['Date']['output'];
  email?: Maybe<Scalars['String']['output']>;
  /** @deprecated The term externalId is being phased out, replace with id */
  externalId: Scalars['String']['output'];
  familyName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  givenName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isMinor: Scalars['Boolean']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  portalRegistrationStatus: MemberPortalRegistrationStatus;
  relationship: Relationship;
  sex?: Maybe<Scalars['String']['output']>;
};


export type CommonMemberGqlIsMinorArgs = {
  atDate?: InputMaybe<Scalars['Date']['input']>;
};

export enum ContactMethod {
  CallPhoneNumber = 'CALL_PHONE_NUMBER',
  CallSecondaryNumber = 'CALL_SECONDARY_NUMBER',
  DoNotContact = 'DO_NOT_CONTACT',
  EnrollmentEmail = 'ENROLLMENT_EMAIL',
  MemberPortalEmail = 'MEMBER_PORTAL_EMAIL',
  SmsPhoneNumber = 'SMS_PHONE_NUMBER',
  SmsSecondaryPhone = 'SMS_SECONDARY_PHONE'
}

export enum ContactType {
  Email = 'EMAIL',
  Phone = 'PHONE',
  Sms = 'SMS'
}

export enum CoveragePackage {
  Epo = 'EPO',
  Ppo = 'PPO',
  Ppop = 'PPOP',
  Ppox = 'PPOX'
}

export type CoveragePeriod = {
  __typename?: 'CoveragePeriod';
  divisionId: Scalars['String']['output'];
  endDate: Scalars['Date']['output'];
  groupCoverageStart: Scalars['Date']['output'];
  groupId: Scalars['String']['output'];
  startDate: Scalars['Date']['output'];
  subscriberStartDate: Scalars['Date']['output'];
};

export type CoveragePeriodOption = {
  __typename?: 'CoveragePeriodOption';
  endDate?: Maybe<Scalars['Date']['output']>;
  startDate: Scalars['Date']['output'];
};

export enum CoverageTier {
  E1 = 'E1',
  E2 = 'E2',
  E3 = 'E3',
  Ec = 'EC',
  Ee = 'EE',
  Ef = 'EF',
  Es = 'ES',
  F1 = 'F1',
  F2 = 'F2',
  F3 = 'F3'
}

export type CreateDependentInput = {
  address: CreateEmployeeAddressInput;
  birthDate: Scalars['Date']['input'];
  familyName: Scalars['String']['input'];
  givenName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  relationship: Relationship;
  sexAtBirth: SexAtBirth;
  ssn: Scalars['String']['input'];
};

export type CreateDependentMembersInput = {
  dependents: Array<CreateDependentInput>;
  employerId: Scalars['String']['input'];
  enrollmentEffectiveDate: Scalars['Date']['input'];
  policyholderExternalId: Scalars['String']['input'];
  policyholderSSN: Scalars['String']['input'];
};

export type CreateEmployeeAddressInput = {
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  homePhone?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  street2?: InputMaybe<Scalars['String']['input']>;
  workPhone?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEnrollmentInput = {
  coveragePackage: CoveragePackage;
  effectiveDate: Scalars['Date']['input'];
  employerId: Scalars['String']['input'];
  subscribableAccountId?: InputMaybe<Scalars['String']['input']>;
  termDate?: InputMaybe<Scalars['Date']['input']>;
};

export type CreatePolicyholderInput = {
  address: CreateEmployeeAddressInput;
  birthDate: Scalars['Date']['input'];
  familyName: Scalars['String']['input'];
  givenName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  sexAtBirth: SexAtBirth;
  ssn: Scalars['String']['input'];
};

export type CreateSubscriberGroupInput = {
  dependents: Array<CreateDependentInput>;
  enrollment: CreateEnrollmentInput;
  policyholder: CreatePolicyholderInput;
};

export type ESignature = {
  __typename?: 'ESignature';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  legalAgreement: LegalAgreement;
  legalAgreementId: Scalars['Int']['output'];
  memberId?: Maybe<Scalars['String']['output']>;
  signatureDate: Scalars['DateTime']['output'];
  signatureExpiry?: Maybe<Scalars['DateTime']['output']>;
  signatureResponseText: Scalars['String']['output'];
  signatureResponseType: SignatureResponseType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum ElationPhysicianType {
  CareNavigator = 'CARE_NAVIGATOR',
  Physician = 'PHYSICIAN'
}

export type EmployeeMember = {
  __typename?: 'EmployeeMember';
  /** Returns employee's member address */
  address: MemberAddress;
  birthDate: Scalars['Date']['output'];
  closestPlan: Plan;
  /** Returns available date options to update Displayed Coverage Period end date. If the list is empty, the end date cannot be updated for Employee Member. */
  coveragePeriodEndDateOptions: Array<Scalars['Date']['output']>;
  /** Returns Coverage Period which includes Displayed Enrollment */
  displayedCoveragePeriod: CoveragePeriod;
  /** Returns an enrollment to display for the current employee based on plan year. */
  displayedEnrollment: Enrollment;
  familyName: Scalars['String']['output'];
  givenName?: Maybe<Scalars['String']['output']>;
  hasClaims: Scalars['Boolean']['output'];
  hasDisplayedTerminationPending: Scalars['Boolean']['output'];
  hasExistingMemberRecord: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isInWorkbasket: Scalars['Boolean']['output'];
  /** Returns a presigned S3 URL for the member card of the current Member */
  memberCardUrl?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  relationship: Relationship;
  subscriberGroup: EmployeeSubscriberGroup;
  subscriptionId: Scalars['String']['output'];
};


export type EmployeeMemberDisplayedEnrollmentArgs = {
  planYear?: InputMaybe<Scalars['Int']['input']>;
};


export type EmployeeMemberMemberCardUrlArgs = {
  displayType: MemberCardDisplayType;
  fileType: MemberCardOutputFileType;
  urlType: MemberCardS3UrlType;
};

export type EmployeeSubscriberGroup = {
  __typename?: 'EmployeeSubscriberGroup';
  dependents: Array<EmployeeMember>;
  subscriberId: Scalars['String']['output'];
  /** Returns the member who is also the subscriber for a subscriber group / family. */
  subscriberMember: EmployeeMember;
};

export type EmployeesInput = {
  employerId: Scalars['String']['input'];
  externalIds?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type EmployeesPage = {
  __typename?: 'EmployeesPage';
  items: Array<EmployeeMember>;
  next?: Maybe<Scalars['String']['output']>;
  pageSize: Scalars['Int']['output'];
  totalChildrenCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalOtherCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
  totalPolicyholdersCount: Scalars['Int']['output'];
  totalSpousesCount: Scalars['Int']['output'];
};

export type Employer = {
  __typename?: 'Employer';
  canEditMembers: Scalars['Boolean']['output'];
  canViewInvoices: Scalars['Boolean']['output'];
  canViewMembers: Scalars['Boolean']['output'];
  claimSystem: ClaimSystem;
  coverageInfo?: Maybe<EmployerCoverageInfo>;
  createdAt: Scalars['DateTime']['output'];
  healthEdgeAccountId?: Maybe<Scalars['String']['output']>;
  healthEdgeAccountKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoices: Array<EmployerInvoice>;
  name: Scalars['String']['output'];
  subAccounts: Array<ChildEmployer>;
  subscribableAccounts: Array<SimpleEmployer>;
  vbaDivisionId?: Maybe<Scalars['String']['output']>;
};


export type EmployerCanEditMembersArgs = {
  userEmail?: InputMaybe<Scalars['String']['input']>;
};


export type EmployerCanViewInvoicesArgs = {
  userEmail?: InputMaybe<Scalars['String']['input']>;
};


export type EmployerCanViewMembersArgs = {
  userEmail?: InputMaybe<Scalars['String']['input']>;
};


export type EmployerInvoicesArgs = {
  numBillingMonthsFilter?: InputMaybe<Scalars['Int']['input']>;
  subAccountIdFilter?: InputMaybe<Scalars['String']['input']>;
};

export type EmployerCoverageInfo = {
  __typename?: 'EmployerCoverageInfo';
  coveragePeriodEndDate?: Maybe<Scalars['Date']['output']>;
  coveragePeriodOptions: Array<CoveragePeriodOption>;
  coveragePeriodStartDate: Scalars['Date']['output'];
  coverageStatus: EmployerCoverageStatus;
  defaultPlanYear?: Maybe<Scalars['Int']['output']>;
  effectiveDateRange: Array<Scalars['Date']['output']>;
  employerState: Scalars['String']['output'];
  planTypes: Array<CoveragePackage>;
  planYears?: Maybe<Array<Scalars['Int']['output']>>;
  plans: Array<Plan>;
};

export enum EmployerCoverageStatus {
  Active = 'ACTIVE',
  Future = 'FUTURE',
  Past = 'PAST'
}

export type EmployerInvoice = {
  __typename?: 'EmployerInvoice';
  accountId: Scalars['String']['output'];
  billingMonth: Scalars['Int']['output'];
  billingYear: Scalars['Int']['output'];
  downloadUrl: Scalars['String']['output'];
  invoiceDate: Scalars['Date']['output'];
};

export type Enrollment = {
  __typename?: 'Enrollment';
  benefitsBookletUrl: Scalars['String']['output'];
  cobraStartDate?: Maybe<Scalars['Date']['output']>;
  divisionId: Scalars['String']['output'];
  endDate: Scalars['Date']['output'];
  fundingType?: Maybe<FundingType>;
  groupCoverageStart: Scalars['Date']['output'];
  groupId: Scalars['String']['output'];
  hasMetINDeductible: Scalars['Boolean']['output'];
  hasMetINOOPMax: Scalars['Boolean']['output'];
  hasMetOONDeductible: Scalars['Boolean']['output'];
  hasMetOONOOPMax: Scalars['Boolean']['output'];
  memberId: Scalars['String']['output'];
  /** @deprecated Use MemberGQL.closestPlan instead. Data will be incorrect for anything other than displayed/closest enrollment. */
  plan: Plan;
  planId: Scalars['String']['output'];
  /** @deprecated This is not employer bound anymore - adapt your logic to rely on the feature flag's 'auto approval enabled for all' key instead */
  qualifiesForAutoZeroCardApprovals: Scalars['Boolean']['output'];
  startDate: Scalars['Date']['output'];
  status: EnrollmentStatus;
  subscriberStartDate: Scalars['Date']['output'];
  tier?: Maybe<Tier>;
  tierId?: Maybe<Scalars['String']['output']>;
  type: EnrollmentType;
};

export type EnrollmentProcessingResults = {
  __typename?: 'EnrollmentProcessingResults';
  errorReportUrl?: Maybe<Scalars['String']['output']>;
  numEdits: Scalars['Int']['output'];
  numEnrollments: Scalars['Int']['output'];
  numErrorsCurative: Scalars['Int']['output'];
  numErrorsUser: Scalars['Int']['output'];
  numTerminations: Scalars['Int']['output'];
};

export enum EnrollmentStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Cobra = 'COBRA',
  Expired = 'EXPIRED',
  Upcoming = 'UPCOMING'
}

export enum EnrollmentType {
  FullyInsured = 'FULLY_INSURED',
  SelfInsured = 'SELF_INSURED'
}

export enum Ethnicity {
  Hispanic = 'HISPANIC',
  NotHispanic = 'NOT_HISPANIC',
  NotShared = 'NOT_SHARED'
}

export enum FundingType {
  FullyFunded = 'FULLY_FUNDED',
  LevelFunded = 'LEVEL_FUNDED',
  SelfFunded = 'SELF_FUNDED'
}

export enum GenderIdentity {
  Man = 'MAN',
  NonBinary = 'NON_BINARY',
  NotListed = 'NOT_LISTED',
  NotShared = 'NOT_SHARED',
  TransMan = 'TRANS_MAN',
  TransWoman = 'TRANS_WOMAN',
  Woman = 'WOMAN'
}

export enum Language {
  English = 'ENGLISH',
  Spanish = 'SPANISH'
}

export type LegalAgreement = {
  __typename?: 'LegalAgreement';
  agreementMarkdown?: Maybe<Scalars['String']['output']>;
  allowedSignatureResponseTypes: Array<SignatureResponseType>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isCurrent: Scalars['Boolean']['output'];
  language: Language;
  /** Indicates whether user must be 18 years old to be eligible to sign a legal agreement */
  requiresEligibility: Scalars['Boolean']['output'];
  signatureType: SignatureType;
  summary: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum ManagingGroupId {
  BaselineClinicianTeam = 'BASELINE_CLINICIAN_TEAM',
  CareNavigatorManagers = 'CARE_NAVIGATOR_MANAGERS',
  Chimera = 'CHIMERA',
  ClinicalDocumentManagementTeam = 'CLINICAL_DOCUMENT_MANAGEMENT_TEAM',
  ClinicianManagers = 'CLINICIAN_MANAGERS',
  EmployerPortalSuperusersGroup = 'EMPLOYER_PORTAL_SUPERUSERS_GROUP',
  EmployerUserManagementTeam = 'EMPLOYER_USER_MANAGEMENT_TEAM',
  EngineeringTeam = 'ENGINEERING_TEAM',
  HealthPlanTeam = 'HEALTH_PLAN_TEAM',
  MemberServicesTeam = 'MEMBER_SERVICES_TEAM',
  PermissionManagementTeam = 'PERMISSION_MANAGEMENT_TEAM',
  PharmacyTeam = 'PHARMACY_TEAM',
  ProviderFacilityEditingTeam = 'PROVIDER_FACILITY_EDITING_TEAM',
  /** @deprecated Use CLINICIAN_MANAGERS, CARE_NAVIGATOR_MANAGERS, or BASELINE_CLINICIAN_TEAM instead */
  ProviderManagementTeam = 'PROVIDER_MANAGEMENT_TEAM',
  /** @deprecated Use CLINICIAN_MANAGERS, CARE_NAVIGATOR_MANAGERS, or BASELINE_CLINICIAN_TEAM instead */
  ProviderSchedulingTeam = 'PROVIDER_SCHEDULING_TEAM',
  TpaTeam = 'TPA_TEAM'
}

export enum MaritalStatus {
  DivorcedSeparated = 'DIVORCED_SEPARATED',
  Married = 'MARRIED',
  NotListed = 'NOT_LISTED',
  NotShared = 'NOT_SHARED',
  Single = 'SINGLE',
  UnmarriedPartner = 'UNMARRIED_PARTNER',
  Widowed = 'WIDOWED'
}

export type Member = CommonMemberGql & {
  __typename?: 'Member';
  accumulator?: Maybe<PlanYearAccumulator>;
  activeEnrollment?: Maybe<Enrollment>;
  /** Returns Member's address if exists, or Member subscriber's address otherwise */
  address?: Maybe<MemberAddress>;
  /** Returns the all member's plan years */
  allMemberPlanYears: Array<MemberPlanYear>;
  birthDate: Scalars['Date']['output'];
  /** Returns the associated Care Navigator, if any */
  careNavigator?: Maybe<CareNavigator>;
  /** Returns Claims for the Member. This is an Admin Portal only endpoint. */
  claims: Array<Claim>;
  /** Returns the member's current plan year, otherwise get the next plan year */
  closestMemberPlanYear?: Maybe<MemberPlanYear>;
  closestPlan: Plan;
  /** Returns Coverage Period which includes Displayed Enrollment */
  displayedCoveragePeriod: CoveragePeriod;
  displayedEnrollment: Enrollment;
  /** Returns the member's enrollment data based on member's current plan year if available, or the next plan year */
  displayedEnrollmentFromClosestMemberPlanYear: Enrollment;
  eSignatures: Array<ESignature>;
  email?: Maybe<Scalars['String']['output']>;
  /** @deprecated The term externalId is being phased out, replace with id */
  externalId: Scalars['String']['output'];
  familyName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  givenName?: Maybe<Scalars['String']['output']>;
  hasActiveEnrollment: Scalars['Boolean']['output'];
  hasClinicalDocuments: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isMinor: Scalars['Boolean']['output'];
  isMinorAtEffectiveDate: Scalars['Boolean']['output'];
  /** Returns the member's policyholder visibility status */
  isPolicyholderVisibilityEnabled: Scalars['Boolean']['output'];
  lastInvitedToPortalAt?: Maybe<Scalars['DateTime']['output']>;
  /** Returns the member's next plan year, otherwise get the current plan year */
  latestMemberPlanYear?: Maybe<MemberPlanYear>;
  /** Returns a presigned S3 URL for the member card of the current Member */
  memberCardUrl?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  paymentCardholder?: Maybe<MemberPaymentCardholder>;
  paymentCards: Array<MemberPaymentCard>;
  planYears: Array<Scalars['Int']['output']>;
  portalRegistrationStatus: MemberPortalRegistrationStatus;
  preferences: MemberPreferences;
  relationship: Relationship;
  sex?: Maybe<Scalars['String']['output']>;
  /** Returns Member subscriber's address. The value is the same for all members in the same group */
  subscriberAddress: MemberAddress;
  subscriberGroup: SubscriberGroup;
  subscriberId: Scalars['String']['output'];
};


export type MemberAccumulatorArgs = {
  planYear: Scalars['Int']['input'];
};


export type MemberIsMinorArgs = {
  atDate?: InputMaybe<Scalars['Date']['input']>;
};


export type MemberMemberCardUrlArgs = {
  displayType?: InputMaybe<MemberCardDisplayType>;
  fileType?: InputMaybe<MemberCardOutputFileType>;
  urlDurationInMinutes?: InputMaybe<Scalars['Long']['input']>;
  urlType?: InputMaybe<MemberCardS3UrlType>;
};

export type MemberAddress = {
  __typename?: 'MemberAddress';
  cellPhone?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  homePhone?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  street: Scalars['String']['output'];
  street2?: Maybe<Scalars['String']['output']>;
  workPhone?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

export enum MemberCardDisplayType {
  Back = 'BACK',
  BothSides = 'BOTH_SIDES',
  Front = 'FRONT'
}

export enum MemberCardOutputFileType {
  Jpeg = 'JPEG',
  Pdf = 'PDF',
  Png = 'PNG'
}

export enum MemberCardS3UrlType {
  Display = 'DISPLAY',
  Download = 'DOWNLOAD',
  Print = 'PRINT'
}

export type MemberPaymentCard = {
  __typename?: 'MemberPaymentCard';
  cardType: MemberPaymentCardType;
  hasActiveSpendApproval: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  status: MemberPaymentCardStatus;
  stripeId: Scalars['String']['output'];
};

export enum MemberPaymentCardStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Inactive = 'INACTIVE'
}

export enum MemberPaymentCardType {
  Physical = 'PHYSICAL',
  Virtual = 'VIRTUAL'
}

export type MemberPaymentCardholder = {
  __typename?: 'MemberPaymentCardholder';
  creationError?: Maybe<Scalars['String']['output']>;
  stripeId?: Maybe<Scalars['String']['output']>;
};

export type MemberPlanYear = {
  __typename?: 'MemberPlanYear';
  baselineVisitStatus: BaselineVisitStatus;
  claimSystem: ClaimSystem;
  cobraStartDate?: Maybe<Scalars['Date']['output']>;
  divisionId: Scalars['String']['output'];
  employerEndAt: Scalars['DateTime']['output'];
  employerEndDate: Scalars['Date']['output'];
  employerName?: Maybe<Scalars['String']['output']>;
  employerStartAt: Scalars['DateTime']['output'];
  employerStartDate: Scalars['Date']['output'];
  enrollmentTimezone: Scalars['String']['output'];
  isLateEnroll: Scalars['Boolean']['output'];
  isPlanHighDeductible: Scalars['Boolean']['output'];
  latestPlanId: Scalars['String']['output'];
  latestTier: Scalars['String']['output'];
  memberEndAt: Scalars['DateTime']['output'];
  memberEndDate: Scalars['Date']['output'];
  memberId: Scalars['String']['output'];
  memberStartAt: Scalars['DateTime']['output'];
  memberStartDate: Scalars['Date']['output'];
  memberStartDateAge: Scalars['Int']['output'];
  originalPlanId: Scalars['String']['output'];
  planCoveragePackage?: Maybe<CoveragePackage>;
  planFundingType: FundingType;
  planYear: Scalars['Int']['output'];
  status: EnrollmentStatus;
  subscriberId: Scalars['String']['output'];
};

export enum MemberPortalRegistrationStatus {
  Ineligible = 'INELIGIBLE',
  NotRegistered = 'NOT_REGISTERED',
  Registered = 'REGISTERED'
}

export type MemberPreferences = {
  __typename?: 'MemberPreferences';
  preferredContactMethod?: Maybe<ContactMethod>;
  preferredLanguage?: Maybe<Language>;
  subscribedToNonTransactionalEmails: Scalars['Boolean']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  cancelEnrollment: EmployeeMember;
  createDependentMembers: Array<EmployeeMember>;
  createSubscriberGroup: EmployeeMember;
  processEnrollmentFile: EnrollmentProcessingResults;
  updateEmployee: EmployeeMember;
  /** Updates the Displayed Coverage Period end date of given Employee Member. Use EmployeeMember.coveragePeriodEndDateOptions to get a list of available date options. */
  updateMemberEndDate: EmployeeMember;
  updateSelectedEmployer: Employer;
};


export type MutationCancelEnrollmentArgs = {
  memberId: Scalars['String']['input'];
  selectedEmployerId: Scalars['String']['input'];
};


export type MutationCreateDependentMembersArgs = {
  input: CreateDependentMembersInput;
  selectedEmployerId: Scalars['String']['input'];
};


export type MutationCreateSubscriberGroupArgs = {
  input: CreateSubscriberGroupInput;
  selectedEmployerId: Scalars['String']['input'];
};


export type MutationProcessEnrollmentFileArgs = {
  fileS3Key: Scalars['String']['input'];
  selectedEmployerId: Scalars['String']['input'];
};


export type MutationUpdateEmployeeArgs = {
  input: UpdateEmployeeInput;
  selectedEmployerId: Scalars['String']['input'];
};


export type MutationUpdateMemberEndDateArgs = {
  input: UpdateEmployeeEndDateInput;
  selectedEmployerId: Scalars['String']['input'];
};


export type MutationUpdateSelectedEmployerArgs = {
  employerId: Scalars['String']['input'];
  planYear?: InputMaybe<Scalars['Int']['input']>;
};

export enum OnboardingStatusType {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE'
}

export type PageInput = {
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  startFrom?: InputMaybe<Scalars['String']['input']>;
};

export type Plan = {
  __typename?: 'Plan';
  coveragePackage?: Maybe<CoveragePackage>;
  description?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  fundingType?: Maybe<FundingType>;
  hasHighDeductible: Scalars['Boolean']['output'];
  planType?: Maybe<PlanType>;
  planYear?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export enum PlanType {
  Noop = 'NOOP',
  Oop = 'OOP'
}

export type PlanYearAccumulator = {
  __typename?: 'PlanYearAccumulator';
  familyDedInNetMax: Scalars['BigDecimal']['output'];
  familyDedInNetUsed: Scalars['BigDecimal']['output'];
  familyDedOONMax: Scalars['BigDecimal']['output'];
  familyDedOONUsed: Scalars['BigDecimal']['output'];
  familyOOPInNetMax: Scalars['BigDecimal']['output'];
  familyOOPInNetUsed: Scalars['BigDecimal']['output'];
  familyOOPOONMax: Scalars['BigDecimal']['output'];
  familyOOPOONUsed: Scalars['BigDecimal']['output'];
  memberDedInNetMax: Scalars['BigDecimal']['output'];
  memberDedInNetUsed: Scalars['BigDecimal']['output'];
  memberDedOONMax: Scalars['BigDecimal']['output'];
  memberDedOONUsed: Scalars['BigDecimal']['output'];
  memberOOPInNetMax: Scalars['BigDecimal']['output'];
  memberOOPInNetUsed: Scalars['BigDecimal']['output'];
  memberOOPOONMax: Scalars['BigDecimal']['output'];
  memberOOPOONUsed: Scalars['BigDecimal']['output'];
};

export enum Pronouns {
  HeHim = 'HE_HIM',
  NotListed = 'NOT_LISTED',
  SheHer = 'SHE_HER',
  TheyThem = 'THEY_THEM'
}

export type Query = {
  __typename?: 'Query';
  /** Fetches the active employer user based on token */
  activeEmployerUser: User;
  /** Returns CoverageTier based on list of dependent relationships. */
  coverageTier: CoverageTier;
  /** Returns the effective date options for new dependents. */
  effectiveDateRangeForNewDependents: Array<Scalars['Date']['output']>;
  employee?: Maybe<EmployeeMember>;
  /** Look up a member record matching an SSN */
  employeeBySSN?: Maybe<EmployeeMember>;
  employees: EmployeesPage;
  /** Query for checking the health of the GraphQL server */
  health: Scalars['Boolean']['output'];
};


export type QueryCoverageTierArgs = {
  relationships: Array<InputMaybe<Relationship>>;
};


export type QueryEffectiveDateRangeForNewDependentsArgs = {
  newTier: CoverageTier;
  selectedEmployerId: Scalars['String']['input'];
  subscriberExternalId: Scalars['String']['input'];
};


export type QueryEmployeeArgs = {
  memberId: Scalars['String']['input'];
  selectedEmployerId: Scalars['String']['input'];
};


export type QueryEmployeeBySsnArgs = {
  selectedEmployerId: Scalars['String']['input'];
  socialSecurityNumber: Scalars['String']['input'];
};


export type QueryEmployeesArgs = {
  input: EmployeesInput;
  pageInput?: InputMaybe<PageInput>;
  selectedEmployerId: Scalars['String']['input'];
};

export enum Race {
  AmericanIndian = 'AMERICAN_INDIAN',
  Asian = 'ASIAN',
  Black = 'BLACK',
  Multiracial = 'MULTIRACIAL',
  NativeHawaiian = 'NATIVE_HAWAIIAN',
  NotListed = 'NOT_LISTED',
  NotShared = 'NOT_SHARED',
  White = 'WHITE'
}

export enum Relationship {
  Accountant = 'ACCOUNTANT',
  ActivitySponsor = 'ACTIVITY_SPONSOR',
  AdoptedChild = 'ADOPTED_CHILD',
  AdoptedDaughter = 'ADOPTED_DAUGHTER',
  AdoptedSon = 'ADOPTED_SON',
  AdoptiveFather = 'ADOPTIVE_FATHER',
  AdoptiveMother = 'ADOPTIVE_MOTHER',
  AdoptiveParents = 'ADOPTIVE_PARENTS',
  Advisor = 'ADVISOR',
  AgencyRepresentative = 'AGENCY_REPRESENTATIVE',
  AlmaMater = 'ALMA_MATER',
  Annuitant = 'ANNUITANT',
  Applicant = 'APPLICANT',
  Aunt = 'AUNT',
  Banker = 'BANKER',
  Betrothed = 'BETROTHED',
  BothParents = 'BOTH_PARENTS',
  Brother = 'BROTHER',
  BrotherInLaw = 'BROTHER_IN_LAW',
  Business = 'BUSINESS',
  BusinessAssociate = 'BUSINESS_ASSOCIATE',
  BusinessInsuranceTrust = 'BUSINESS_INSURANCE_TRUST',
  BusinessPartner = 'BUSINESS_PARTNER',
  CadaverDonor = 'CADAVER_DONOR',
  Charity = 'CHARITY',
  Child = 'CHILD',
  ChildrenOfMarriage = 'CHILDREN_OF_MARRIAGE',
  ChildInLaw = 'CHILD_IN_LAW',
  ChildOfADomesticPartner = 'CHILD_OF_A_DOMESTIC_PARTNER',
  ChildWhereInsuredHasNoFinancialResponsibility = 'CHILD_WHERE_INSURED_HAS_NO_FINANCIAL_RESPONSIBILITY',
  Clergyman = 'CLERGYMAN',
  Client = 'CLIENT',
  ClubOrOrganizationOfficer = 'CLUB_OR_ORGANIZATION_OFFICER',
  Coach = 'COACH',
  CollateralDependent = 'COLLATERAL_DEPENDENT',
  Company = 'COMPANY',
  Corporation = 'CORPORATION',
  CourtAppointedGuardian = 'COURT_APPOINTED_GUARDIAN',
  Cousin = 'COUSIN',
  CoWorker = 'CO_WORKER',
  Creditor = 'CREDITOR',
  Daughter = 'DAUGHTER',
  DaughterInLaw = 'DAUGHTER_IN_LAW',
  Dependent = 'DEPENDENT',
  DependentOfAMinorDependent = 'DEPENDENT_OF_A_MINOR_DEPENDENT',
  Doctor = 'DOCTOR',
  EcclesiasticalOrReligiousLeader = 'ECCLESIASTICAL_OR_RELIGIOUS_LEADER',
  EducatorOrTeacherOrInstructor = 'EDUCATOR_OR_TEACHER_OR_INSTRUCTOR',
  EmancipatedMinor = 'EMANCIPATED_MINOR',
  EmergencyContact = 'EMERGENCY_CONTACT',
  Employee = 'EMPLOYEE',
  Employer = 'EMPLOYER',
  Estate = 'ESTATE',
  ExHusband = 'EX_HUSBAND',
  ExSpouse = 'EX_SPOUSE',
  ExWife = 'EX_WIFE',
  FamilyMember = 'FAMILY_MEMBER',
  Father = 'FATHER',
  FatherInLaw = 'FATHER_IN_LAW',
  FianceFemale = 'FIANCE_FEMALE',
  FianceMale = 'FIANCE_MALE',
  Fiduciary = 'FIDUCIARY',
  FosterChild = 'FOSTER_CHILD',
  FosterDaughter = 'FOSTER_DAUGHTER',
  FosterFather = 'FOSTER_FATHER',
  FosterMother = 'FOSTER_MOTHER',
  FosterParent = 'FOSTER_PARENT',
  FosterSon = 'FOSTER_SON',
  Friend = 'FRIEND',
  GodDaughter = 'GOD_DAUGHTER',
  GodFather = 'GOD_FATHER',
  GodMother = 'GOD_MOTHER',
  GodParents = 'GOD_PARENTS',
  GodSon = 'GOD_SON',
  Grandchild = 'GRANDCHILD',
  Grandchildren = 'GRANDCHILDREN',
  Granddaughter = 'GRANDDAUGHTER',
  Grandfather = 'GRANDFATHER',
  Grandmother = 'GRANDMOTHER',
  Grandparent = 'GRANDPARENT',
  Grandparents = 'GRANDPARENTS',
  Grandson = 'GRANDSON',
  GrandsonOrGranddaughter = 'GRANDSON_OR_GRANDDAUGHTER',
  GreatAunt = 'GREAT_AUNT',
  Guardian = 'GUARDIAN',
  HalfBrother = 'HALF_BROTHER',
  HalfSister = 'HALF_SISTER',
  HandicappedDependent = 'HANDICAPPED_DEPENDENT',
  Husband = 'HUSBAND',
  InjuredPlaintiff = 'INJURED_PLAINTIFF',
  Institution = 'INSTITUTION',
  Insured = 'INSURED',
  Lawyer = 'LAWYER',
  LifePartner = 'LIFE_PARTNER',
  MedicalCareProvider = 'MEDICAL_CARE_PROVIDER',
  MinisterOrPriest = 'MINISTER_OR_PRIEST',
  MortgageHolder = 'MORTGAGE_HOLDER',
  Mother = 'MOTHER',
  MotherInLaw = 'MOTHER_IN_LAW',
  MutuallyDefined = 'MUTUALLY_DEFINED',
  Neighbor = 'NEIGHBOR',
  Nephew = 'NEPHEW',
  NephewOrNiece = 'NEPHEW_OR_NIECE',
  Niece = 'NIECE',
  None = 'NONE',
  NonApplicableIndividualRelationshipCategory = 'NON_APPLICABLE_INDIVIDUAL_RELATIONSHIP_CATEGORY',
  OrganDonor = 'ORGAN_DONOR',
  Other = 'OTHER',
  OtherAdult = 'OTHER_ADULT',
  OtherRelationship = 'OTHER_RELATIONSHIP',
  OtherRelative = 'OTHER_RELATIVE',
  OtherSchoolAdministrator = 'OTHER_SCHOOL_ADMINISTRATOR',
  Owner = 'OWNER',
  Parent = 'PARENT',
  ParentsInLaw = 'PARENTS_IN_LAW',
  ParentInLaw = 'PARENT_IN_LAW',
  Partner = 'PARTNER',
  Partnership = 'PARTNERSHIP',
  Payor = 'PAYOR',
  PersonalInsuranceTrust = 'PERSONAL_INSURANCE_TRUST',
  ProbationOfficer = 'PROBATION_OFFICER',
  SchoolCounselor = 'SCHOOL_COUNSELOR',
  SchoolPrincipal = 'SCHOOL_PRINCIPAL',
  Self = 'SELF',
  Sibling = 'SIBLING',
  SiblingInLaw = 'SIBLING_IN_LAW',
  SignificantOther = 'SIGNIFICANT_OTHER',
  Sister = 'SISTER',
  SisterInLaw = 'SISTER_IN_LAW',
  SoleProprietorship = 'SOLE_PROPRIETORSHIP',
  Son = 'SON',
  SonInLaw = 'SON_IN_LAW',
  SonOrDaughter = 'SON_OR_DAUGHTER',
  SponsoredDependent = 'SPONSORED_DEPENDENT',
  Spouse = 'SPOUSE',
  StateFund = 'STATE_FUND',
  Stepchild = 'STEPCHILD',
  Stepfather = 'STEPFATHER',
  Stepmother = 'STEPMOTHER',
  Stepparent = 'STEPPARENT',
  StepBrother = 'STEP_BROTHER',
  StepChildren = 'STEP_CHILDREN',
  StepDaughter = 'STEP_DAUGHTER',
  StepSister = 'STEP_SISTER',
  StepSon = 'STEP_SON',
  Student = 'STUDENT',
  Supervisor = 'SUPERVISOR',
  Teacher = 'TEACHER',
  Trust = 'TRUST',
  Trustee = 'TRUSTEE',
  Uncle = 'UNCLE',
  UncleOrAunt = 'UNCLE_OR_AUNT',
  Unknown = 'UNKNOWN',
  Ward = 'WARD',
  Widow = 'WIDOW',
  Widower = 'WIDOWER',
  Wife = 'WIFE'
}

export enum SexAtBirth {
  Female = 'FEMALE',
  Intersex = 'INTERSEX',
  Male = 'MALE',
  NotShared = 'NOT_SHARED'
}

export enum SignatureResponseType {
  Consent = 'consent',
  DoNotConsent = 'do_not_consent',
  HaveReadAndUnderstand = 'have_read_and_understand'
}

export enum SignatureType {
  AssignmentOfBenefits = 'ASSIGNMENT_OF_BENEFITS',
  BaselineVisitAssignmentOfBenefits = 'BASELINE_VISIT_ASSIGNMENT_OF_BENEFITS',
  BaselineVisitCommunicationsConsent = 'BASELINE_VISIT_COMMUNICATIONS_CONSENT',
  BaselineVisitPrivacyConsent = 'BASELINE_VISIT_PRIVACY_CONSENT',
  CommunicationsConsent = 'COMMUNICATIONS_CONSENT',
  ESignatureConsent = 'E_SIGNATURE_CONSENT',
  HealthInformationConsent = 'HEALTH_INFORMATION_CONSENT',
  HipaaConsent = 'HIPAA_CONSENT',
  MemberPaymentCardConsent = 'MEMBER_PAYMENT_CARD_CONSENT',
  PrivacyConsent = 'PRIVACY_CONSENT',
  WellnessProgramNotice = 'WELLNESS_PROGRAM_NOTICE'
}

export type SimpleEmployer = {
  __typename?: 'SimpleEmployer';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SubscriberGroup = {
  __typename?: 'SubscriberGroup';
  dependents: Array<CommonMemberGql>;
  subscriberId: Scalars['String']['output'];
  /** Returns the member who is also the subscriber for a subscriber group / family. */
  subscriberMember?: Maybe<CommonMemberGql>;
};

export type Tier = {
  __typename?: 'Tier';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type UpdateEmployeeAddressInput = {
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  homePhone?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  street2?: InputMaybe<Scalars['String']['input']>;
  workPhone?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEmployeeEndDateInput = {
  endDate: Scalars['Date']['input'];
  externalId: Scalars['String']['input'];
};

export type UpdateEmployeeInput = {
  address?: InputMaybe<UpdateEmployeeAddressInput>;
  birthDate: Scalars['Date']['input'];
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  hasIdCardRequest: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  addressId?: Maybe<Scalars['Int']['output']>;
  canAccessTelehealth: Scalars['Boolean']['output'];
  canViewEmployerPortalManagement: Scalars['Boolean']['output'];
  canViewMembersDetails: Scalars['Boolean']['output'];
  canViewPermissionsDashboard: Scalars['Boolean']['output'];
  canViewProviderDataManagement: Scalars['Boolean']['output'];
  canViewRxInvoiceTool: Scalars['Boolean']['output'];
  canViewSchedulingTools: Scalars['Boolean']['output'];
  contactPreferences?: Maybe<Array<ContactType>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  employers: Array<Employer>;
  employersAdminsOnly: Array<Employer>;
  ethnicity?: Maybe<Ethnicity>;
  genderIdentity?: Maybe<GenderIdentity>;
  hasEmployerPortalSuperuserAccess: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  maritalStatus?: Maybe<MaritalStatus>;
  member?: Maybe<Member>;
  memberId?: Maybe<Scalars['String']['output']>;
  onboardingStatus: OnboardingStatusType;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  preferences?: Maybe<MemberPreferences>;
  preferredName?: Maybe<Scalars['String']['output']>;
  pronouns?: Maybe<Pronouns>;
  race?: Maybe<Race>;
  selectedEmployer?: Maybe<Employer>;
  sexAtBirth?: Maybe<SexAtBirth>;
  subjectId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userType: UserType;
};

export enum UserType {
  Admin = 'ADMIN',
  Employer = 'EMPLOYER',
  Member = 'MEMBER'
}

export type Visit = {
  __typename?: 'Visit';
  isVirtual: Scalars['Boolean']['output'];
  leadClinician?: Maybe<Clinician>;
  location: VisitLocation;
  member?: Maybe<Member>;
  memberId: Scalars['String']['output'];
  memberState?: Maybe<Scalars['String']['output']>;
  physician?: Maybe<Scalars['Long']['output']>;
  urlIdToken?: Maybe<Scalars['String']['output']>;
  visitEndAt: Scalars['DateTime']['output'];
  visitLanguage?: Maybe<Language>;
  visitStartAt: Scalars['DateTime']['output'];
  visitTypeInfo: VisitTypeInfo;
};

export enum VisitLocation {
  InPerson = 'IN_PERSON',
  OnSite = 'ON_SITE',
  Virtual = 'VIRTUAL'
}

export enum VisitType {
  BaselineVisitFreedomMode = 'BASELINE_VISIT_FREEDOM_MODE',
  BaselineVisitFreedomModeClinicianCareNavigator = 'BASELINE_VISIT_FREEDOM_MODE_CLINICIAN_CARE_NAVIGATOR',
  BaselineVisitNewMember = 'BASELINE_VISIT_NEW_MEMBER',
  BaselineVisitNewMemberNonClinical = 'BASELINE_VISIT_NEW_MEMBER_NON_CLINICAL',
  BaselineVisitPreventative = 'BASELINE_VISIT_PREVENTATIVE',
  BaselineVisitPrimaryCare = 'BASELINE_VISIT_PRIMARY_CARE',
  BaselineVisitRenewal = 'BASELINE_VISIT_RENEWAL',
  BaselineVisitRenewalNonClinical = 'BASELINE_VISIT_RENEWAL_NON_CLINICAL',
  LegacyBaselineVisit = 'LEGACY_BASELINE_VISIT',
  Other = 'OTHER',
  PrimaryCare = 'PRIMARY_CARE',
  PrimaryCareFreedomMode = 'PRIMARY_CARE_FREEDOM_MODE'
}

export type VisitTypeInfo = {
  __typename?: 'VisitTypeInfo';
  visitType: VisitType;
  visitTypeId: Scalars['Int']['output'];
};
