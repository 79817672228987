/**
 * Shared logic for form related components.
 */

import clsx from "clsx";
import { useMemo } from "react";

import { SpacingVariant } from "../constants";
import { Box, BoxProps } from "./Box";
import { Icon, IconProps, IconVariant } from "./Icon";
import { ScreenReaderOnly } from "./ScreenReaderOnly";
import { isMessageEmpty } from "./text";
import {
  DisplayVariant,
  FormFieldBaseProps,
  FormFieldLayout,
  MarginProps,
  TextVariant,
} from "./types";

export const formFieldDescriptionTextVariant: TextVariant =
  TextVariant.SmRegularTall;
// standard size for icons that go inside form field input area
export const formFieldIconSize: IconProps["size"] = SpacingVariant.S24;
export const formFieldLabelTextDisplayVariant: DisplayVariant =
  DisplayVariant.Block;
export const formFieldTextVariant: TextVariant = TextVariant.MdRegularTall;

export const FormFieldDescription = (
  props: Pick<FormFieldBaseProps, "description"> &
    Pick<BoxProps<"span">, "className">
): JSX.Element | null => {
  const { className, description } = props;

  // Check if description is empty
  if (!description) {
    return null;
  }

  return (
    <Box
      className={clsx("text-textColor-subdued", className)}
      display={DisplayVariant.Block}
      element="span"
      textVariant={formFieldDescriptionTextVariant}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  );
};

export const formFieldErrorId = ({
  hasError,
  id,
}: {
  hasError: boolean;
  id: string;
}): string | undefined => (hasError ? `${id}_error` : undefined);

export type FormFieldErrorMessageProps = Pick<
  FormFieldBaseProps,
  "errorMessage"
> &
  Pick<BoxProps<"div">, "testId"> & {
    id?: string;
  };

export const FormFieldErrorMessage = ({
  errorMessage,
  id,
  testId,
}: FormFieldErrorMessageProps): JSX.Element | null => {
  const hasError = useHasError({ errorMessage });
  return (
    <Box
      className="text-textColor-critical"
      element="div"
      textVariant={TextVariant.SmRegularTall}
      id={id}
      aria-live="polite"
    >
      {hasError && (
        <Box element="div" className="mt-2 flex" testId={testId}>
          <Box
            className="shrink-0"
            element="div"
            margin={{ top: SpacingVariant.S2 }}
          >
            <Icon
              size={SpacingVariant.S16}
              variant={IconVariant.EXCLAMATION_CIRCLE}
            />
          </Box>
          <Box element="div" margin={{ left: SpacingVariant.S4 }}>
            <Box element="p">{errorMessage}</Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export const FormFieldLabelText = (
  props: Pick<FormFieldBaseProps, "isLabelSrOnly" | "label">
): JSX.Element => {
  const { isLabelSrOnly, label } = props;

  // Check if this is screen reader only
  if (isLabelSrOnly) {
    return <ScreenReaderOnly element="span">{label}</ScreenReaderOnly>;
  }

  return (
    <Box display={DisplayVariant.Block} element="span">
      {label}
    </Box>
  );
};

/**
 * Get border class names for text fields or select fields.
 *
 * See: https://tailwindcss.com/docs/border-color
 * See: https://tailwindcss.com/docs/border-width
 */
export const formFieldBorderClassNames = (params: {
  hasError?: boolean;
  isDisabled?: boolean;
}): string[] => {
  const { hasError, isDisabled } = params;
  return [
    "border",
    "focus:border-focused-default",
    // check if field has error & not disabled
    hasError && !isDisabled
      ? "border-foundation-critical"
      : "border-borderColor-default",
  ];
};

/**
 * Get margin props for form field wrapper on text fields or select fields. Create space between form control & label/description if not screen-reader only.
 */
export const formFieldWrapperMargin = ({
  isLabelSrOnly,
  layout,
}: Pick<FormFieldBaseProps, "isLabelSrOnly" | "layout">): MarginProps => {
  // Add top margin to field wrapper if it is not screen reader only
  return isLabelSrOnly || layout === FormFieldLayout.HORIZONTAL
    ? {}
    : { top: SpacingVariant.S4 };
};

/**
 * Memoized has error boolean.
 */
export const useHasError = ({
  errorMessage,
}: {
  errorMessage: FormFieldBaseProps["errorMessage"];
}): boolean =>
  useMemo(() => !isMessageEmpty({ message: errorMessage }), [errorMessage]);
