import clsx from "clsx";

import { SpacingVariant } from "../../constants";
import { Box, Icon, IconVariant, textColorsCommon } from "..";
import { DisplayVariant, TextVariant } from "../types";

export type TableValueLocation = {
  address?: string[] | null;
  contactInfo?: string[] | null;
  name?: string | null;
};

export type TableValueWithLocationProps = {
  description?: string;
  location: TableValueLocation;
  title: string;
};

export const TableValueWithLocation = ({
  description,
  location,
  title,
}: TableValueWithLocationProps): JSX.Element => {
  const iconSize: SpacingVariant = SpacingVariant.S20;
  const { address, contactInfo, name } = location;
  const hasLocationInfo = !!address?.length || contactInfo?.length || name;

  return (
    <Box
      className={clsx(textColorsCommon.default)}
      element="div"
      textVariant={TextVariant.MdRegularTall}
    >
      {/* title & description */}
      <Box element="div" margin={{ bottom: SpacingVariant.S12 }}>
        <Box element="div" textVariant={TextVariant.XlBold}>
          {title}
        </Box>
        {description && (
          <Box
            className={clsx(textColorsCommon.subdued)}
            element="div"
            textVariant={TextVariant.SmRegularTall}
          >
            {description}
          </Box>
        )}
      </Box>

      {/* location info */}
      {hasLocationInfo && (
        <Box
          className="items-start"
          display={DisplayVariant.FLEX}
          element="div"
        >
          <Box
            className={clsx("shrink-0", !name && "mt-1")}
            element="div"
            height={iconSize}
            width={iconSize}
          >
            <Icon
              className={clsx(textColorsCommon.highlightDefault)}
              size={iconSize}
              variant={IconVariant.LOCATION_MARKER}
            />
          </Box>
          <Box element="div" margin={{ left: SpacingVariant.S6 }}>
            {name && (
              <Box
                element="div"
                margin={{ bottom: SpacingVariant.S4 }}
                textVariant={TextVariant.MdBold}
              >
                {name}
              </Box>
            )}
            {[...(address ?? []), ...(contactInfo ?? [])]?.map(
              (line, index) => (
                /* eslint-disable-next-line */
                <Box element="div" key={index}>
                  {line}
                </Box>
              )
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
