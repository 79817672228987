import clsx from "clsx";
import { useMemo } from "react";

import { SpacingVariant } from "../../constants";
import { Box, BoxProps } from "../Box";
import { TextVariant } from "../types";
import { TableProps } from "./Table";
import {
  baseTableDataCellBoxProps,
  ClickableRowControl,
  getClickableRows,
  RowControlData,
  TableContentType,
  TableKey,
  TableValue,
} from "./utils";

// table body row
export type TableBodyRow = TableValue[];

export const TableBody = (
  props: Omit<
    TableProps,
    "className" | "headCellArrayKeyGenerator" | "rowArrayKeyGenerator"
  > & {
    descriptionListArrayKeys: Array<string | number>;
    rowArrayKeys: Array<string | number>;
  }
): JSX.Element | null => {
  const {
    columnClassNames,
    contentTypes,
    descriptionListArrayKeys,
    keys,
    rowArrayKeys,
    clickableRowData,
    rows,
    hasStripedRows,
    testId: tableTestId,
  } = props;

  const hasClickableRows = clickableRowData && clickableRowData.length > 0;

  const tableRows = hasClickableRows
    ? getClickableRows(rows, clickableRowData)
    : rows;

  const memoizedBasePropsForCells: BoxProps<"td">[] = useMemo(() => {
    return contentTypes.map((contentType, cellIndex) => {
      return baseTableDataCellBoxProps({ cellIndex, contentType });
    });
  }, [contentTypes]);

  const basePropsForCell = (params: {
    cellIndex: number;
    contentType?: TableContentType;
  }): BoxProps<"td"> => {
    const { cellIndex, contentType } = params;
    // mainly do this for type safety. cell index should virtually always be in memo list.
    return (
      memoizedBasePropsForCells[cellIndex] ??
      baseTableDataCellBoxProps({
        cellIndex,
        contentType,
      })
    );
  };

  const memoizedDescriptionListsForRows: Array<JSX.Element | null> =
    useMemo(() => {
      return rows.map((row, idx) => {
        return (
          <DescriptionList
            /* eslint-disable-next-line */
            key={idx}
            descriptionListArrayKeys={descriptionListArrayKeys}
            items={descriptionListItems({ keys, row })}
            testId={tableTestId}
            clickableRowData={
              hasClickableRows ? clickableRowData[idx] : undefined
            }
          />
        );
      });
    }, [
      clickableRowData,
      descriptionListArrayKeys,
      hasClickableRows,
      keys,
      rows,
      tableTestId,
    ]);

  return (
    <Box element="tbody">
      {tableRows.map((row, rowIndex) => (
        <Box
          className={clsx(
            hasStripedRows && "even:bg-surface-highlight",
            hasClickableRows && "hover:bg-blue-200",
            hasClickableRows && "focus-within:bg-blue-200",
            "border-b-2",
            "border-b-surface-neutral",
            hasClickableRows && "relative"
          )}
          element="tr"
          key={rowArrayKeys[rowIndex]}
        >
          {row.map((value, cellIndex) => {
            // get base cell box props
            const {
              className: baseTableDataCellClassName,
              ...restTableDataCellBoxProps
            } = basePropsForCell({
              cellIndex,
              contentType: contentTypes[cellIndex],
            });

            // on mobile, show first table data cell with nested description list, & hide the rest
            // on desktop, show all table data cells, & hide nested description list
            // see: https://tailwindui.com/components/application-ui/lists/tables#component-e56f750c63d4e53a24f5f0bf9fd7b52a
            return cellIndex === 0 ? (
              <Box
                {...restTableDataCellBoxProps}
                className={clsx(
                  baseTableDataCellClassName,
                  "px-0",
                  "py-0",
                  // full width on mobile
                  "w-full",
                  "max-w-0",
                  columnClassNames?.[cellIndex]
                )}
                testId={`${tableTestId}__DataCellPrimary`}
              >
                <Box className={clsx("hidden", "md:block")} element="div">
                  {value}
                </Box>
                {memoizedDescriptionListsForRows[rowIndex]}
              </Box>
            ) : (
              <Box
                {...restTableDataCellBoxProps}
                className={clsx(
                  baseTableDataCellClassName,
                  // hide on mobile
                  "hidden",
                  columnClassNames?.[cellIndex]
                )}
                testId={`${tableTestId}__DataCellSecondary`}
              >
                {value}
              </Box>
            );
          })}
        </Box>
      ))}
    </Box>
  );
};

type DescriptionListItemProps = {
  details: TableValue;
  term: TableKey;
};

type DescriptionListProps = Pick<TableProps, "testId"> & {
  descriptionListArrayKeys: Array<string | number>;
  items: DescriptionListItemProps[];
  clickableRowData?: RowControlData;
};
/**
 * Description List for row on mobile. Hide on desktop.
 *
 * See: https://tailwindui.com/components/application-ui/data-display/description-lists#component-d9372707af2e94e67936e94d7339cbb3
 */
export const DescriptionList = (
  props: DescriptionListProps
): JSX.Element | null => {
  const {
    descriptionListArrayKeys,
    items,
    testId: tableTestId,
    clickableRowData,
  } = props;

  return (
    <Box
      className={clsx("text-textColor-default", "md:hidden")}
      element="dl"
      margin={{
        bottom: SpacingVariant.S16,
        top: SpacingVariant.S16,
        left: SpacingVariant.S16,
        right: SpacingVariant.S16,
      }}
      textVariant={TextVariant.MdRegularTall}
      testId={`${tableTestId}__DescriptionList`}
    >
      {clickableRowData && <ClickableRowControl {...clickableRowData} />}
      {items.map((item, index) => (
        <Box
          className={clsx("grid", "grid-cols-3", "gap-4")}
          element="div"
          key={descriptionListArrayKeys[index]}
          margin={{ bottom: SpacingVariant.S16 }}
        >
          <Box
            className={clsx("text-textColor-subdued")}
            element="dt"
            textVariant={TextVariant.SmRegularTall}
          >
            {item.term}
          </Box>
          <Box className="col-span-2" element="dd">
            {item.details}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const descriptionListItems = (
  params: Pick<TableProps, "keys"> & {
    row: TableBodyRow;
  }
): DescriptionListProps["items"] => {
  const { keys, row } = params;
  return row.map((value, cellIndex) => {
    return {
      // use cell value for details
      details: value,
      // use key with same index as cell for term
      term: keys[cellIndex],
    };
  });
};
