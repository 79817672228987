const path = require("path");
const { withSentryConfig } = require("@sentry/nextjs");
const { merge } = require("lodash");

const MEMBER_CARDS_S3_DOMAIN_DEV =
  "curative-sw-dev-generated-member-cards.s3.us-west-2.amazonaws.com";
const MEMBER_CARDS_S3_DOMAIN_PROD =
  "curative-sw-prod-generated-member-cards.s3.us-west-2.amazonaws.com";
const STATIC_ASSETS_DOMAIN = "static.curative.com";

const sentryWebpackPluginOptions = {
  // Suppresses all logs. For all available options, see:
  // https://github.com/getsentry/sentry-webpack-plugin#options.
  silent: true,
};

const securityHeaders = [
  {
    key: "Content-Security-Policy",
    value: "frame-ancestors 'none';",
  },
];

/**
 * @typedef {import('next').NextConfig} NextConfig
 */

/**
 * @param {string} projectPath The full path to the current project folder (e.g. `__dirname`)
 * @param {Partial<NextConfig>} overrides Custom config that will be deeply merged into the base config
 * @returns {NextConfig}
 */
const createNextConfig = (projectPath, overrides = {}) => {
  /**
   * @type {NextConfig}
   */
  const baseConfig = {
    reactStrictMode: true,
    images: {
      remotePatterns: [
        STATIC_ASSETS_DOMAIN,
        MEMBER_CARDS_S3_DOMAIN_DEV,
        MEMBER_CARDS_S3_DOMAIN_PROD,
      ].map((hostname) => ({
        protocol: "https",
        hostname,
        pathname: "**",
      })),
    },
    pageExtensions: ["page.tsx", "page.ts"],
    transpilePackages: ["~", "@chp/curative_ui", "@chp/shared", "lodash-es"],
    eslint: {
      ignoreDuringBuilds: true,
    },
    typescript: {
      ignoreBuildErrors: true,
    },
    sentry: {
      hideSourceMaps: false,
    },
    webpack: (config, options) => {
      config.resolve.alias = {
        ...config.resolve.alias,
        // We manually resolve these because Yarn links them into `node_modules`.
        // Next caches `node_modules, which means after we make changes to any
        // file(s) in a workspace package folder like `shared`, we then have to
        // kill `yarn dev` and run `rm -rf .next && yarn dev`.
        "~": path.resolve(projectPath, "src"),
        "@chp/curative_ui": path.resolve(projectPath, "../curative_ui"),
        "@chp/shared": path.resolve(projectPath, "../shared"),
      };

      config.module.rules = [
        ...config.module.rules,
        {
          test: /\.svg$/i,
          issuer: /\.[jt]sx?$/,
          use: [
            // NOTE(Wes): Apparently `options.defaultLoaders.babel` resolves to
            // `next-swc-loader` if the SWC compiler is enabled. I verified
            // this with `console.log(options.defaultLoaders.babel)`. See the
            // link below for more context:
            // https://github.com/vercel/next.js/discussions/33161#discussioncomment-4137836
            options.defaultLoaders.babel,
            {
              loader: "@svgr/webpack",
              // Disable the default Babel loader since we're using SWC.
              options: { babel: false },
            },
          ],
        },
      ];

      if (!options.isServer) {
        // Adding this because the Next/postcss config runs the tailwind.config.js
        // file on both the server and browser builds, and fails in the browser because
        // our tailwind config relies on fs. After adding this, the browser build just
        // swallows the error of the missing fs module.
        config.resolve.fallback = { fs: false };
      }

      return config;
    },
    headers: async () => {
      return [
        {
          // Apply these headers to all routes in your application.
          source: "/:path*",
          headers: securityHeaders,
        },
      ];
    },
  };

  const nextConfig = merge(baseConfig, overrides);

  return withSentryConfig(nextConfig, sentryWebpackPluginOptions);
};

module.exports = { createNextConfig };
