import clsx from "clsx";

import {
  CheckboxVariants,
  formFieldErrorId,
  FormFieldErrorMessage,
  useHasError,
} from "..";
import { Checkbox } from "../Checkbox";
import { TEXT_CLASS_NAMES } from "../constants";
import { CUIComponent, CUIComponentProps, TextVariant } from "../types";

export type CheckboxSelectionOption = {
  /* the unique key for this option to refer to it */
  value: string;

  /* the value of the label that appears beside the checkbox */
  label: string;

  /* the value of the label title that can appears beside the checkbox and on top of the label */
  labelTitle?: string;

  /* whether the checkbox is disabled or not */
  isDisabled?: boolean;

  /* whether the checkbox is an error state or not */
  hasError?: boolean;

  /* whether the checkbox is currently checked or not */
  isChecked?: boolean;
};

export type CheckboxSelectionProps = CUIComponentProps<{
  id: string;
  isDisabled?: boolean;
  legend?: string; // legend that can appear before the checkbox, ie. "Select pizza size"
  options: Array<CheckboxSelectionOption>;
  setOptions: (options: Array<CheckboxSelectionOption>) => void; // we call this whenever a new checkbox is toggled to update our options state
  variant?: CheckboxVariants; // will use additional styling to make the checkboxes look like Control components (see Figma)
  errorMessage?: string;
  boxColor?: string;
}>;

export const CheckboxSelection: CUIComponent<CheckboxSelectionProps> = ({
  id,
  isDisabled,
  className,
  legend,
  options,
  setOptions,
  testId = "CheckboxSelection",
  variant = CheckboxVariants.Normal,
  errorMessage,
  boxColor,
}) => {
  const hasError = useHasError({ errorMessage });
  const errorId = formFieldErrorId({ hasError, id });

  const onRequestToggle = (value: string) => {
    const optsCopy = [...options];
    const optionToUpdate = optsCopy.find((x) => x.value === value);
    if (optionToUpdate) {
      optionToUpdate.isChecked = !optionToUpdate.isChecked;
      setOptions(optsCopy);
    }
  };

  return (
    <fieldset data-testid={testId} className={clsx("flex", className)}>
      {legend && (
        <legend
          data-testid={`${testId}__Legend`}
          className={clsx(
            "mb-4",
            TEXT_CLASS_NAMES[TextVariant.LgRegular],
            "text-textColor-default"
          )}
        >
          {legend}
        </legend>
      )}

      <div
        data-testid={`${testId}__Options`}
        className={clsx(
          "flex",
          variant === CheckboxVariants.NormalColumn ? "flex-col" : "flex-wrap",
          variant === CheckboxVariants.Normal
            ? "flex-col sm:flex-row"
            : "w-full flex-col"
        )}
      >
        {options.map((option) => {
          return (
            <Checkbox
              id={`checkbox-${id}-${option.value}`}
              key={`checkbox-${id}-${option.value}`}
              testId={`${testId}__${option.value}`}
              isDisabled={isDisabled}
              name={id}
              {...option}
              isChecked={option.isChecked ? true : false}
              onRequestToggle={onRequestToggle}
              variant={variant}
              boxColor={option.isChecked ? boxColor : ""}
            />
          );
        })}
      </div>

      {hasError && errorId && (
        <FormFieldErrorMessage
          errorMessage={errorMessage}
          testId={`${testId}__ErrorMessage`}
          id={errorId}
        />
      )}
    </fieldset>
  );
};
