import clsx from "clsx";
import { FC } from "react";

export type CopayDeductibleCostProps = {
  label: string;
  description: string | JSX.Element;
  hasDeductible: boolean;
  isBold?: boolean;
  isTwoLines?: boolean;
};

export const CopayDeductibleCost: FC<CopayDeductibleCostProps> = ({
  label,
  description,
  hasDeductible,
  isBold,
  isTwoLines,
}) => (
  <p
    className={clsx(
      isBold && "text-[11px] font-bold",
      isTwoLines && "mb-[11px]"
    )}
  >
    <span>{label}:</span>
    <span
      className={clsx(
        "absolute",
        hasDeductible ? "left-24" : "left-28",
        "min-w-max"
      )}
    >
      {description}
    </span>
  </p>
);
