import { RadioSelectionOption, RadioVariant } from "@chp/curative_ui";
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";

import { FormattedRadioSelection } from "../FormattedRadioSelection";

type FormRadioSelectionProps<T extends FieldValues> = {
  name: Path<T>;
  id?: string;
  label: string;
  className?: string;
  options: RadioSelectionOption[];
  testId?: string;
  variant?: RadioVariant;
};

export function FormRadioSelection<T extends FieldValues>({
  id,
  name,
  ...rest
}: FormRadioSelectionProps<T>) {
  const { control } = useFormContext<T>();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormattedRadioSelection
          id={id || name}
          onRequestToggle={(newValue: string) =>
            onChange({ target: { value: newValue } })
          }
          currentlySelectedOption={value}
          errorMessage={error?.message}
          {...rest}
        />
      )}
    />
  );
}
