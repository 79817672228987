import clsx from "clsx";
import { ReactNode } from "react";

import { SpacingVariant } from "../../constants";
import { PaddingSize } from "../../types";
import { buttonBaseClassNames, buttonPaddingSmall } from "../../utils";
import { Box, BoxProps } from "..";
import {
  CUIComponent,
  CUIComponentProps,
  DisplayVariant,
  TextVariant,
} from "../types";

/** @deprecated See buttonBaseClassNames */
export const BUTTON_BASE_CLASS_NAMES: string[] = buttonBaseClassNames;

const WIDTH_FULL_CLASS_NAME: string = "w-full";

enum ColorTheme {
  Outline = "OUTLINE",
  OUTLINE_PILL = "OUTLINE_PILL",
  Primary = "PRIMARY",
  Flat = "FLAT",
  FlatPrimary = "FLAT_PRIMARY",
}

const COLOR_THEME_CLASS_NAMES: Record<ColorTheme, string[]> = {
  [ColorTheme.Outline]: [
    "border",
    "rounded",
    "bg-surface-default",
    "border-borderColor-control",
    "text-textColor-highlight-default",
    "active:bg-foundation-primary",
    "active:text-textColor-onBg-primary",
    "hover:bg-surface-highlight",
    "disabled:bg-surface-disabled",
    "disabled:border-borderColor-default",
    "disabled:text-textColor-subdued",
    "focus:border-focused-default",
    "focus:text-textColor-highlight-default",
  ],
  [ColorTheme.OUTLINE_PILL]: [
    "border",
    // https://tailwindcss.com/docs/border-radius#pill-buttons
    "rounded-full",
    "bg-surface-default",
    "border-borderColor-control",
    "text-textColor-highlight-default",
    "active:bg-foundation-primary",
    "active:text-textColor-onBg-primary",
    "hover:bg-surface-highlight",
    "disabled:bg-surface-disabled",
    "disabled:border-borderColor-default",
    "disabled:text-textColor-subdued",
    "focus:border-focused-default",
    "focus:text-textColor-highlight-default",
  ],
  [ColorTheme.Primary]: [
    "border",
    "rounded",
    "bg-foundation-primary",
    "border-borderColor-control",
    "text-textColor-onBg-primary",
    "active:bg-blue-900",
    "active:border-blue-900",
    "hover:bg-blue-600",
    "hover:border-blue-600",
    "disabled:bg-surface-disabled",
    "disabled:border-surface-disabled",
    "disabled:text-textColor-subdued",
    "focus:bg-blue-600",
  ],
  [ColorTheme.Flat]: [
    "bg-none",
    "border-none",
    "text-textColor-highlight-default",
    "active:bg-blue-900",
    "active:text-textColor-onBg-primary",
    "hover:text-textColor-highlight-depressed",
    "hover:underline",
    "disabled:text-textColor-subdued",
    "disabled:no-underline",
    "disabled:bg-opacity-0",
    "focus:border-focused-default",
  ],
  [ColorTheme.FlatPrimary]: [
    "bg-none",
    "border-none",
    "text-textColor-onBg-primary",
    "active:bg-surface-highlight",
    "active:text-textColor-highlight-depressed",
    "hover:text-blue-200",
    "hover:underline",
    "disabled:text-textColor-subdued",
    "disabled:no-underline",
    "disabled:bg-opacity-0",
    "focus:border-focused-default",
  ],
};

export enum ButtonVariant {
  Base = "BASE",
  Primary = "PRIMARY",
  BigOutline = "BIG_OUTLINE",
  BIG_OUTLINE_PILL = "BIG_OUTLINE_PILL",
  BigPrimary = "BIG_PRIMARY",
  FullWidthOutline = "FULL_WIDTH_OUTLINE",
  FullWidthPrimary = "FULL_WIDTH_PRIMARY",
  SmallOutline = "SMALL_OUTLINE",
  SmallPrimary = "SMALL_PRIMARY",
  Flat = "FLAT",
  FlatPrimary = "FLAT_PRIMARY",
}

const BUTTON_VARIANT_CLASS_NAMES: Record<ButtonVariant, string[]> = {
  [ButtonVariant.Base]: buttonBaseClassNames,
  [ButtonVariant.Primary]: [
    ...buttonBaseClassNames,
    ...COLOR_THEME_CLASS_NAMES[ColorTheme.Primary],
  ],
  [ButtonVariant.BigOutline]: [
    ...buttonBaseClassNames,
    ...COLOR_THEME_CLASS_NAMES[ColorTheme.Outline],
  ],
  [ButtonVariant.BIG_OUTLINE_PILL]: [
    ...buttonBaseClassNames,
    ...COLOR_THEME_CLASS_NAMES[ColorTheme.OUTLINE_PILL],
  ],
  [ButtonVariant.BigPrimary]: [
    ...buttonBaseClassNames,
    ...COLOR_THEME_CLASS_NAMES[ColorTheme.Primary],
  ],
  [ButtonVariant.FullWidthOutline]: [
    ...buttonBaseClassNames,
    ...COLOR_THEME_CLASS_NAMES[ColorTheme.Outline],
    WIDTH_FULL_CLASS_NAME,
  ],
  [ButtonVariant.FullWidthPrimary]: [
    ...buttonBaseClassNames,
    ...COLOR_THEME_CLASS_NAMES[ColorTheme.Primary],
    WIDTH_FULL_CLASS_NAME,
  ],
  [ButtonVariant.SmallOutline]: [
    ...buttonBaseClassNames,
    ...COLOR_THEME_CLASS_NAMES[ColorTheme.Outline],
  ],
  [ButtonVariant.SmallPrimary]: [
    ...buttonBaseClassNames,
    ...COLOR_THEME_CLASS_NAMES[ColorTheme.Primary],
  ],
  [ButtonVariant.Flat]: [
    ...buttonBaseClassNames,
    ...COLOR_THEME_CLASS_NAMES[ColorTheme.Flat],
  ],
  [ButtonVariant.FlatPrimary]: [
    ...buttonBaseClassNames,
    ...COLOR_THEME_CLASS_NAMES[ColorTheme.FlatPrimary],
  ],
};

const paddingBig: PaddingSize = {
  bottom: SpacingVariant.S12,
  left: SpacingVariant.S32,
  right: SpacingVariant.S32,
  top: SpacingVariant.S12,
};

const paddingExtraSmall: PaddingSize = {
  bottom: SpacingVariant.S4,
  left: SpacingVariant.S4,
  right: SpacingVariant.S4,
  top: SpacingVariant.S4,
};

const noPadding: PaddingSize = {
  bottom: SpacingVariant.S0,
  left: SpacingVariant.S0,
  right: SpacingVariant.S0,
  top: SpacingVariant.S0,
};

const textBig: TextVariant = TextVariant.LgBoldShort;
const textSmall: TextVariant = TextVariant.MdBold;

const variantPadding: Record<ButtonVariant, PaddingSize> = {
  [ButtonVariant.Base]: noPadding,
  [ButtonVariant.Primary]: noPadding,
  [ButtonVariant.BigOutline]: paddingBig,
  [ButtonVariant.BIG_OUTLINE_PILL]: paddingBig,
  [ButtonVariant.BigPrimary]: paddingBig,
  [ButtonVariant.FullWidthOutline]: paddingBig,
  [ButtonVariant.FullWidthPrimary]: paddingBig,
  [ButtonVariant.SmallOutline]: buttonPaddingSmall,
  [ButtonVariant.SmallPrimary]: buttonPaddingSmall,
  [ButtonVariant.Flat]: paddingExtraSmall,
  [ButtonVariant.FlatPrimary]: paddingExtraSmall,
};
const variantText: Record<ButtonVariant, TextVariant> = {
  [ButtonVariant.Base]: textSmall,
  [ButtonVariant.Primary]: textSmall,
  [ButtonVariant.BigOutline]: textBig,
  [ButtonVariant.BIG_OUTLINE_PILL]: textBig,
  [ButtonVariant.BigPrimary]: textBig,
  [ButtonVariant.FullWidthOutline]: textBig,
  [ButtonVariant.FullWidthPrimary]: textBig,
  [ButtonVariant.SmallOutline]: textSmall,
  [ButtonVariant.SmallPrimary]: textSmall,
  [ButtonVariant.Flat]: textSmall,
  [ButtonVariant.FlatPrimary]: textSmall,
};

export const buttonStyleBoxProps = (
  variant: ButtonVariant,
  className?: string
): Pick<
  BoxProps<"button">,
  "className" | "display" | "padding" | "textVariant"
> => ({
  className: clsx(BUTTON_VARIANT_CLASS_NAMES[variant], className),
  display: DisplayVariant.InlineBlock,
  padding: variantPadding[variant],
  textVariant: variantText[variant],
});

export type ButtonProps = CUIComponentProps<{
  ariaDescribedBy?: string;
  ariaLabel?: string;
  // always provide accessible button text
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#accessibility_concerns
  children: JSX.Element | string | ReactNode[];
  isDisabled?: boolean;
  name?: string;
  //onClick can be omitted if type is "submit" used with form
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: "submit" | "reset" | "button";
  value?: string;
  variant: ButtonVariant;
  testId?: string;
  form?: string;
}>;

export const Button: CUIComponent<ButtonProps> = (props) => {
  const {
    ariaDescribedBy,
    ariaLabel,
    children,
    className,
    isDisabled = false,
    name,
    onClick,
    type = "button",
    value,
    variant,
    testId = `Button__${variant}`,
  } = props;
  return (
    <Box
      {...buttonStyleBoxProps(variant, className)}
      aria-describedby={ariaDescribedBy}
      aria-label={ariaLabel}
      data-testid={testId}
      disabled={isDisabled}
      element="button"
      name={name}
      onClick={onClick}
      type={type}
      value={value}
      form={props.form}
    >
      {children}
    </Box>
  );
};
