import App, { AppContext, AppInitialProps } from "next/app";

import { ChpAppProps } from "../../types";
import { getLocaleCodesFromHeaders } from "../i18n";

// get data from request headers & pass to App props
// see: https://github.com/vercel/next.js/discussions/36832#discussioncomment-2729581
export const getAppInitialProps = async (
  context: AppContext
): Promise<ChpAppProps & AppInitialProps> => {
  const appInitialProps = await App.getInitialProps(context);
  const localeCodesInitial = getLocaleCodesFromHeaders({
    acceptLanguage: context.ctx.req?.headers["accept-language"],
    cookie: context.ctx.req?.headers["cookie"],
  });
  return {
    ...appInitialProps,
    localeCodesInitial: localeCodesInitial,
  };
};
