export const NODE_ENV = process.env.NODE_ENV;
export const DEPLOYED_ENV = process.env.NEXT_PUBLIC_DEPLOYED_ENV;

export const IS_PROD = NODE_ENV === "production";
export const IS_DEV = NODE_ENV === "development";
export const IS_TEST = NODE_ENV === "test";
export const IS_CYPRESS = !!process.env.NEXT_PUBLIC_IS_CYPRESS;

export const IS_PROD_DEPLOYED_ENV = DEPLOYED_ENV === "prod";
export const IS_PREPROD_DEPLOYED_ENV = DEPLOYED_ENV === "preprod";

export const IS_NON_PROD_ENV =
  IS_DEV || IS_TEST || IS_CYPRESS || IS_PREPROD_DEPLOYED_ENV;
