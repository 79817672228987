import { LaunchDarklyFlags, ReactHookFormFieldValue } from "@chp/shared";
import {
  ClaimSystem,
  ProcedureType,
} from "@chp/shared/generated/memberPortalApi.graphql";
import {
  useTypeaheadOptions__SearchAuthorizationTypesLazyQuery,
  useTypeaheadOptions__SearchGcAuthorizationTypesLazyQuery,
} from "@chp/shared/hooks/memberPortalApi-TypeaheadOptions.generated";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback, useContext, useMemo } from "react";
import { useIntl } from "react-intl";

import { PriorAuthContext } from "./context";

export const useFetchAuthorizationTypeOptions = () => {
  const { formatMessage } = useIntl();
  const { enableSubmittingAuthorizationsToGuidingcare } =
    useFlags<LaunchDarklyFlags>();

  const { memberState } = useContext(PriorAuthContext);

  const [
    searchAuthorizationTypes,
    { loading: areAuthorizationTypeOptionsLoading },
  ] = useTypeaheadOptions__SearchAuthorizationTypesLazyQuery();
  const [
    searchGCAuthorizationTypes,
    { loading: areGCAuthorizationTypeOptionsLoading },
  ] = useTypeaheadOptions__SearchGcAuthorizationTypesLazyQuery();

  // if the flag is off/undefined or claimSystem is null, default to Mediview auth types
  const shouldUseGCAuthTypes = useMemo(
    () =>
      enableSubmittingAuthorizationsToGuidingcare === true &&
      memberState.claimSystem === ClaimSystem.HealthEdge,
    [enableSubmittingAuthorizationsToGuidingcare, memberState]
  );

  const fetchAuthorizationTypeOptions = useCallback(
    async (
      queryString: string,
      procedureType: ProcedureType | ReactHookFormFieldValue<ProcedureType>
    ) => {
      if (shouldUseGCAuthTypes) {
        const response = await searchGCAuthorizationTypes({
          variables: {
            search: queryString,
            procedureType:
              procedureType === ProcedureType.Inpatient
                ? ProcedureType.Inpatient
                : ProcedureType.Outpatient,
          },
        });

        if (response.error || !response.data) {
          throw new Error(
            formatMessage({
              defaultMessage:
                "There was an error searching for authorization types.",
              id: "VSPK7O",
            })
          );
        }

        const results = response.data.searchGCAuthorizationTypes;

        return results.map((result) => ({
          label: `${result.description}`,
          value: result,
        }));
      }

      const response = await searchAuthorizationTypes({
        variables: {
          search: queryString,
        },
      });

      if (response.error || !response.data) {
        throw new Error(
          formatMessage({
            defaultMessage:
              "There was an error searching for authorization types.",
            id: "VSPK7O",
          })
        );
      }

      const results = response.data.searchAuthorizationTypes;

      return results.map((result) => ({
        label: `${result.authType.trim()} - ${result.description}`,
        value: result,
      }));
    },
    [
      searchAuthorizationTypes,
      searchGCAuthorizationTypes,
      formatMessage,
      shouldUseGCAuthTypes,
    ]
  );

  return {
    fetchAuthorizationTypeOptions,
    areAuthorizationTypeOptionsLoading:
      areAuthorizationTypeOptionsLoading ||
      areGCAuthorizationTypeOptionsLoading,
  };
};
