import { VbaProvider } from "@chp/shared/generated/memberPortalApi.graphql";

export * from "./BaselineVisitEventVariant";
export * from "./ChpAppProps";
export * from "./Coordinate";
export * from "./errorCodes";
export * from "./fcWithChildren";
export * from "./FormAssetName";
export * from "./GenericComponentProps";
export * from "./graphql";
export * from "./InviteDependentMember";
export * from "./LaunchDarklyFlags";
export * from "./launchdarklyFlagTypes";
export * from "./MemberAddress";
export * from "./MemberCardDisplayType";
export * from "./MemberCardSideDrawer";
export * from "./next";
export * from "./NullOrUndefined";
export * from "./PasswordStrength";
export * from "./PhoneNumberType";
export * from "./ReactHookFormFieldValue";
export * from "./VirtualCareVariant";

export type RecursivePartial<T> = T extends object
  ? {
      [P in keyof T]?: RecursivePartial<T[P]>;
    }
  : T;

export type RecursiveNonNullable<T> = NonNullable<T>;

export type RecursiveOmit<T, O extends number | string | symbol> = Omit<
  {
    [K in keyof T]: RecursiveOmit<T[K], O>;
  },
  O
>;

export type NonNullableRequired<T> = {
  [P in keyof T]-?: NonNullable<T[P]>;
};

export type WithRequiredProps<T, K extends keyof T> = Omit<T, K> &
  NonNullableRequired<Pick<T, K>>;

export enum GroupId {
  CURATIVE = "CURATIVE",
  CURATIVESF = "CURATIVESF",
}

export enum PlanType {
  SELF_INSURED = "SELF_INSURED",
  FULLY_INSURED = "FULLY_INSURED",
}

export type VBAProviderSearchResult = Pick<
  VbaProvider,
  "firstName" | "lastName" | "nationalProviderId" | "vbaId"
>;

export type Option = {
  label: string;
  value: string;
};
