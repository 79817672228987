export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** An arbitrary precision signed decimal */
  BigDecimal: { input: number; output: number; }
  /** An RFC-3339 compliant Full Date Scalar */
  Date: { input: string; output: string; }
  /** A slightly refined version of RFC-3339 compliant DateTime Scalar */
  DateTime: { input: string; output: string; }
  /** 24-hour clock time value string in the format `hh:mm:ss` or `hh:mm:ss.sss`. */
  LocalTime: { input: any; output: any; }
  /** A 64-bit signed integer */
  Long: { input: number; output: number; }
  /** UUID type */
  UUID: { input: string; output: string; }
};

export enum AccessControlRate {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  None = 'NONE'
}

export enum AccessControlScope {
  AdminsOnly = 'ADMINS_ONLY',
  EmployersOnly = 'EMPLOYERS_ONLY',
  MembersOnly = 'MEMBERS_ONLY',
  Public = 'PUBLIC'
}

export type AppointmentAvailabilityCurrentAndGrossCount = {
  __typename?: 'AppointmentAvailabilityCurrentAndGrossCount';
  date: Scalars['DateTime']['output'];
  numberOfAppointments: Array<ProviderNumberOfAppointments>;
};

export type AppointmentAvailabilityCurrentAndGrossTotal = {
  __typename?: 'AppointmentAvailabilityCurrentAndGrossTotal';
  withAppts: Array<AppointmentAvailabilityCurrentAndGrossCount>;
  withoutAppts: Array<AppointmentAvailabilityCurrentAndGrossCount>;
};

export enum AppointmentType {
  InPerson = 'IN_PERSON',
  OnSite = 'ON_SITE',
  Virtual = 'VIRTUAL'
}

export type AppointmentVisit = {
  __typename?: 'AppointmentVisit';
  inPersonApptPart: Array<Array<IndividualApptPart>>;
  onsiteApptPart: Array<Array<IndividualApptPart>>;
  virtualApptPart: Array<Array<IndividualApptPart>>;
};

export type AppointmentWindowV2 = {
  __typename?: 'AppointmentWindowV2';
  apptInfo: AppointmentVisit;
  endDateTime: Scalars['DateTime']['output'];
  startDateTime: Scalars['DateTime']['output'];
  visitTypeInfo: VisitTypeInfo;
};

export type BaseOneOffEmailWorkflowResult = {
  __typename?: 'BaseOneOffEmailWorkflowResult';
  childWorkflowsSpawned?: Maybe<Scalars['Int']['output']>;
  failedMemberIds?: Maybe<Array<Scalars['String']['output']>>;
  missingInfoMemberIds?: Maybe<Array<Scalars['String']['output']>>;
  successfulMemberIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type BaseOneOffSmsWorkflowResult = {
  __typename?: 'BaseOneOffSmsWorkflowResult';
  childWorkflowsSpawned?: Maybe<Scalars['Int']['output']>;
  failedMemberIds?: Maybe<Array<Scalars['String']['output']>>;
  missingInfoMemberIds?: Maybe<Array<Scalars['String']['output']>>;
  successfulMemberIds?: Maybe<Array<Scalars['String']['output']>>;
};

export enum BaselineStatus {
  Completed = 'COMPLETED',
  NotCompleted = 'NOT_COMPLETED',
  NotRequired = 'NOT_REQUIRED',
  Scheduled = 'SCHEDULED'
}

export type BaselineVisitSettings = {
  __typename?: 'BaselineVisitSettings';
  durationMinutes: Scalars['Int']['output'];
  elationAppointmentReasons: Array<ElationAppointmentReasonOptions>;
  eligiblePhysicians: Array<Clinician>;
  eligiblePhysiciansHalfHour?: Maybe<Array<Clinician>>;
  eligiblePhysiciansHour?: Maybe<Array<Clinician>>;
  memberAddress?: Maybe<MemberAddress>;
  preferredLanguage: Language;
};

export type BaselineVisitStatus = {
  __typename?: 'BaselineVisitStatus';
  baselineStatus: BaselineStatus;
  baselineVisit?: Maybe<Visit>;
  completeAt?: Maybe<Scalars['DateTime']['output']>;
  daysUntilDueDate: Scalars['Int']['output'];
  displayedDueDate: Scalars['Date']['output'];
  healthPlanMemberToken: Scalars['UUID']['output'];
  isBeforeDueDate: Scalars['Boolean']['output'];
  isComplete: Scalars['Boolean']['output'];
  originalDueDate: Scalars['Date']['output'];
  visitId?: Maybe<Scalars['Int']['output']>;
  visitRequired: Scalars['Boolean']['output'];
};

export type BasicMember = CommonMemberGql & {
  __typename?: 'BasicMember';
  /** Returns Member's address if exists, or Member subscriber's address otherwise */
  address?: Maybe<MemberAddress>;
  birthDate: Scalars['Date']['output'];
  email?: Maybe<Scalars['String']['output']>;
  /** @deprecated The term externalId is being phased out, replace with id */
  externalId: Scalars['String']['output'];
  familyName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  givenName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isMinor: Scalars['Boolean']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  portalRegistrationStatus: MemberPortalRegistrationStatus;
  relationship: Relationship;
  sex?: Maybe<Scalars['String']['output']>;
};


export type BasicMemberIsMinorArgs = {
  atDate?: InputMaybe<Scalars['Date']['input']>;
};

export type CareNavigator = {
  __typename?: 'CareNavigator';
  activeForScheduling: Scalars['Boolean']['output'];
  dockHealthId?: Maybe<Scalars['String']['output']>;
  elationId: Scalars['Long']['output'];
  elationTag: Scalars['String']['output'];
  eligibleForAutoAssignment: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  handledAppointmentTypes?: Maybe<Array<AppointmentType>>;
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  languages?: Maybe<Array<CareTeamMemberLanguages>>;
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
};

export type CareTeamMember = {
  __typename?: 'CareTeamMember';
  activeForScheduling: Scalars['Boolean']['output'];
  credentials?: Maybe<Scalars['String']['output']>;
  dockHealthId?: Maybe<Scalars['String']['output']>;
  elationId: Scalars['Long']['output'];
  elationTag: Scalars['String']['output'];
  eligibleForAutoAssignment: Scalars['Boolean']['output'];
  eligibleStates?: Maybe<Array<Scalars['String']['output']>>;
  email: Scalars['String']['output'];
  employeeType: CareTeamMemberType;
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  handledAppointmentTypes?: Maybe<Array<AppointmentType>>;
  hourInterval?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Long']['output'];
  isActive: Scalars['Boolean']['output'];
  languages?: Maybe<Array<CareTeamMemberLanguages>>;
  lastName: Scalars['String']['output'];
  licensedStates?: Maybe<Array<Scalars['String']['output']>>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  physicianType?: Maybe<ElationPhysicianType>;
  providesPrimaryCare?: Maybe<Scalars['Boolean']['output']>;
};

export type CareTeamMemberAvailability = {
  __typename?: 'CareTeamMemberAvailability';
  appointmentTypes: Array<AppointmentType>;
  day: Days;
  enabled: Scalars['Boolean']['output'];
  endTime: Scalars['LocalTime']['output'];
  id: Scalars['Int']['output'];
  isBaselineVisit: Scalars['Boolean']['output'];
  isPrimaryCare: Scalars['Boolean']['output'];
  scheduleId: Scalars['Int']['output'];
  startTime: Scalars['LocalTime']['output'];
};

export type CareTeamMemberAvailabilityOverride = {
  __typename?: 'CareTeamMemberAvailabilityOverride';
  appointmentTypes: Array<AppointmentType>;
  date: Scalars['Date']['output'];
  elationPhysicianId: Scalars['Long']['output'];
  employerDivisionIds: Array<Scalars['String']['output']>;
  endTime: Scalars['LocalTime']['output'];
  id: Scalars['Int']['output'];
  isBaselineVisit: Scalars['Boolean']['output'];
  isPrimaryCare: Scalars['Boolean']['output'];
  notes: Scalars['String']['output'];
  startTime: Scalars['LocalTime']['output'];
  status: CareTeamMemberAvailabilityOverrideStatus;
};

export enum CareTeamMemberAvailabilityOverrideStatus {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE'
}

export type CareTeamMemberInput = {
  activeForScheduling: Scalars['Boolean']['input'];
  credentials?: InputMaybe<Scalars['String']['input']>;
  dockHealthId?: InputMaybe<Scalars['String']['input']>;
  elationId: Scalars['Long']['input'];
  elationTag: Scalars['String']['input'];
  eligibleForAutoAssignment: Scalars['Boolean']['input'];
  eligibleStates?: InputMaybe<Array<Scalars['String']['input']>>;
  email: Scalars['String']['input'];
  employeeType: CareTeamMemberType;
  firstName: Scalars['String']['input'];
  handledAppointmentTypes?: InputMaybe<Array<AppointmentType>>;
  hourInterval?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Long']['input'];
  isActive: Scalars['Boolean']['input'];
  languages?: InputMaybe<Array<CareTeamMemberLanguages>>;
  lastName: Scalars['String']['input'];
  licensedStates?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  physicianType?: InputMaybe<ElationPhysicianType>;
  providesPrimaryCare?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CareTeamMemberLanguages {
  English = 'ENGLISH',
  Spanish = 'SPANISH'
}

export type CareTeamMemberSchedule = {
  __typename?: 'CareTeamMemberSchedule';
  availabilities: Array<CareTeamMemberAvailability>;
  elationPhysicianId: Scalars['Long']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  startDate: Scalars['Date']['output'];
  timeZone: Scalars['String']['output'];
};

export enum CareTeamMemberType {
  CareNavigator = 'CARE_NAVIGATOR',
  Clinician = 'CLINICIAN'
}

export type ChildEmployer = {
  __typename?: 'ChildEmployer';
  hccId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Claim = {
  __typename?: 'Claim';
  allowableAmount?: Maybe<Scalars['Float']['output']>;
  billedAmount?: Maybe<Scalars['Float']['output']>;
  checkNumber?: Maybe<Scalars['String']['output']>;
  claimSystem: ClaimSystem;
  coInsuranceAmount?: Maybe<Scalars['Float']['output']>;
  copayAmount?: Maybe<Scalars['Float']['output']>;
  deductibleAmount?: Maybe<Scalars['Float']['output']>;
  eobUrl?: Maybe<Scalars['String']['output']>;
  memberExternalId: Scalars['String']['output'];
  paidAmount?: Maybe<Scalars['Float']['output']>;
  provider?: Maybe<ClaimProvider>;
  /** @deprecated Use `provider.name` instead */
  providerName: Scalars['String']['output'];
  referenceId?: Maybe<Scalars['String']['output']>;
  serviceEndDate: Scalars['Date']['output'];
  serviceStartDate: Scalars['Date']['output'];
  status: ClaimStatus;
  type: ClaimType;
};

export type ClaimProvider = {
  __typename?: 'ClaimProvider';
  name: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
};

export enum ClaimStatus {
  Denied = 'DENIED',
  DeniedPartial = 'DENIED_PARTIAL',
  Error = 'ERROR',
  NotAdjudicated = 'NOT_ADJUDICATED',
  Paid = 'PAID',
  Payable = 'PAYABLE',
  Pended = 'PENDED',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
  Refund = 'REFUND',
  Unknown = 'UNKNOWN',
  Void = 'VOID'
}

export enum ClaimSystem {
  HealthEdge = 'HEALTH_EDGE',
  Vba = 'VBA'
}

export enum ClaimType {
  Dental = 'DENTAL',
  Disability = 'DISABILITY',
  Flex = 'FLEX',
  Institutional = 'INSTITUTIONAL',
  Life = 'LIFE',
  Pharmacy = 'PHARMACY',
  Professional = 'PROFESSIONAL',
  Unknown = 'UNKNOWN'
}

export enum ClinicalDocumentType {
  BlvSummary = 'BLV_SUMMARY',
  Other = 'OTHER',
  PreferredProviders = 'PREFERRED_PROVIDERS'
}

export type Clinician = {
  __typename?: 'Clinician';
  credentials: Scalars['String']['output'];
  elationId: Scalars['Long']['output'];
  eligibleStates?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  firstName: Scalars['String']['output'];
  handledAppointmentTypes?: Maybe<Array<Maybe<AppointmentType>>>;
  hourInterval?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  isActive: Scalars['Boolean']['output'];
  languages?: Maybe<Array<Maybe<CareTeamMemberLanguages>>>;
  lastName: Scalars['String']['output'];
  licensedStates?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  physicianType?: Maybe<ElationPhysicianType>;
  providesPrimaryCare?: Maybe<Scalars['Boolean']['output']>;
  zoomEmail?: Maybe<Scalars['String']['output']>;
};

export type ClinicianInput = {
  credentials: Scalars['String']['input'];
  elationId: Scalars['Long']['input'];
  eligibleStates?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  firstName: Scalars['String']['input'];
  handledAppointmentTypes?: InputMaybe<Array<InputMaybe<AppointmentType>>>;
  hourInterval?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Long']['input']>;
  isActive: Scalars['Boolean']['input'];
  languages?: InputMaybe<Array<InputMaybe<CareTeamMemberLanguages>>>;
  lastName: Scalars['String']['input'];
  licensedStates?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  physicianType?: InputMaybe<ElationPhysicianType>;
  providesPrimaryCare?: InputMaybe<Scalars['Boolean']['input']>;
  zoomEmail?: InputMaybe<Scalars['String']['input']>;
};

export type CommandResult = {
  __typename?: 'CommandResult';
  count: Scalars['Int']['output'];
  result?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CommonMemberGql = {
  /** Returns Member's address if exists, or Member subscriber's address otherwise */
  address?: Maybe<MemberAddress>;
  birthDate: Scalars['Date']['output'];
  email?: Maybe<Scalars['String']['output']>;
  /** @deprecated The term externalId is being phased out, replace with id */
  externalId: Scalars['String']['output'];
  familyName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  givenName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isMinor: Scalars['Boolean']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  portalRegistrationStatus: MemberPortalRegistrationStatus;
  relationship: Relationship;
  sex?: Maybe<Scalars['String']['output']>;
};


export type CommonMemberGqlIsMinorArgs = {
  atDate?: InputMaybe<Scalars['Date']['input']>;
};

export enum ContactMethod {
  CallPhoneNumber = 'CALL_PHONE_NUMBER',
  CallSecondaryNumber = 'CALL_SECONDARY_NUMBER',
  DoNotContact = 'DO_NOT_CONTACT',
  EnrollmentEmail = 'ENROLLMENT_EMAIL',
  MemberPortalEmail = 'MEMBER_PORTAL_EMAIL',
  SmsPhoneNumber = 'SMS_PHONE_NUMBER',
  SmsSecondaryPhone = 'SMS_SECONDARY_PHONE'
}

export enum ContactType {
  Email = 'EMAIL',
  Phone = 'PHONE',
  Sms = 'SMS'
}

export type ConversionValidateResponse = {
  __typename?: 'ConversionValidateResponse';
  convertedRows?: Maybe<Array<EnrollmentFileRow>>;
  url?: Maybe<Scalars['String']['output']>;
  validationMessages: Array<ValidationMessage>;
};

export enum CorrespondenceType {
  ClaimReport = 'CLAIM_REPORT',
  Eob = 'EOB',
  Eop = 'EOP'
}

export enum CoveragePackage {
  Epo = 'EPO',
  Ppo = 'PPO',
  Ppop = 'PPOP',
  Ppox = 'PPOX'
}

export type CoveragePeriod = {
  __typename?: 'CoveragePeriod';
  divisionId: Scalars['String']['output'];
  endDate: Scalars['Date']['output'];
  groupCoverageStart: Scalars['Date']['output'];
  groupId: Scalars['String']['output'];
  startDate: Scalars['Date']['output'];
  subscriberStartDate: Scalars['Date']['output'];
};

export type CoveragePeriodOption = {
  __typename?: 'CoveragePeriodOption';
  endDate?: Maybe<Scalars['Date']['output']>;
  startDate: Scalars['Date']['output'];
};

export type CreateCareNavigatorInput = {
  activeForScheduling: Scalars['Boolean']['input'];
  elationId: Scalars['Long']['input'];
  elationTag: Scalars['String']['input'];
  eligibleForAutoAssignment: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  handledAppointmentTypes?: InputMaybe<Array<AppointmentType>>;
  isActive: Scalars['Boolean']['input'];
  languages?: InputMaybe<Array<CareTeamMemberLanguages>>;
  lastName: Scalars['String']['input'];
  needToUploadPhoto: Scalars['Boolean']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreateClinicalDocumentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  documentDate: Scalars['Date']['input'];
  documentType: ClinicalDocumentType;
  memberId: Scalars['String']['input'];
  source: Scalars['String']['input'];
};

export type CreateEmployerPortalUserInput = {
  email: Scalars['String']['input'];
  /** @deprecated Adds only employers with 'can view members' permission, replace with employerPermissions */
  employerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  employerPermissions?: InputMaybe<Array<EmployerPermissionInput>>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type CreateUpdateCareNavigatorResult = {
  __typename?: 'CreateUpdateCareNavigatorResult';
  careNavigator: CareNavigator;
  photoUploadData?: Maybe<PresignedUploadData>;
};

export type Customer = {
  __typename?: 'Customer';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerEmail: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  stripeCustomerId: Scalars['String']['output'];
  subscriberMemberId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum Days {
  F = 'F',
  M = 'M',
  S = 'S',
  Su = 'Su',
  T = 'T',
  Th = 'Th',
  W = 'W'
}

export type DivisionIdObject = {
  __typename?: 'DivisionIDObject';
  divisionId: Scalars['String']['output'];
};

export type ESignature = {
  __typename?: 'ESignature';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  legalAgreement: LegalAgreement;
  legalAgreementId: Scalars['Int']['output'];
  memberId?: Maybe<Scalars['String']['output']>;
  signatureDate: Scalars['DateTime']['output'];
  signatureExpiry?: Maybe<Scalars['DateTime']['output']>;
  signatureResponseText: Scalars['String']['output'];
  signatureResponseType: SignatureResponseType;
  updatedAt: Scalars['DateTime']['output'];
};

export type ESignatureInvite = {
  __typename?: 'ESignatureInvite';
  attemptsLeft: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  eSignatureId?: Maybe<Scalars['Int']['output']>;
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  memberId: Scalars['String']['output'];
  sentBy: Scalars['Int']['output'];
  signUrl?: Maybe<Scalars['String']['output']>;
  signatureType: SignatureType;
};

export enum ElationAppointmentReasonOptions {
  AnnualExam = 'ANNUAL_EXAM',
  BaselineVisit = 'BASELINE_VISIT',
  BaselineVisitOnsite = 'BASELINE_VISIT_ONSITE',
  BaselineVisitPcInPerson = 'BASELINE_VISIT_PC_IN_PERSON',
  BaselineVisitPcVirtual = 'BASELINE_VISIT_PC_VIRTUAL',
  BaselineVisitVirtual = 'BASELINE_VISIT_VIRTUAL',
  CnOnlyBlv = 'CN_ONLY_BLV',
  CnOnlyBlvVirtual = 'CN_ONLY_BLV_VIRTUAL',
  FollowUp = 'FOLLOW_UP',
  NewPatient = 'NEW_PATIENT',
  NursingVisitDm = 'NURSING_VISIT_DM',
  NursingVisitMh = 'NURSING_VISIT_MH',
  NursingVisitUtiStiGyneComplaint = 'NURSING_VISIT_UTI_STI_GYNE_COMPLAINT',
  OfficeVisit = 'OFFICE_VISIT',
  PhysicalExam = 'PHYSICAL_EXAM',
  PostBlvLabReview = 'POST_BLV_LAB_REVIEW',
  PrimaryCareLegacy = 'PRIMARY_CARE_LEGACY',
  PrimaryCareLong = 'PRIMARY_CARE_LONG',
  PrimaryCareNewPatient = 'PRIMARY_CARE_NEW_PATIENT',
  PrimaryCareShort = 'PRIMARY_CARE_SHORT',
  Procedure = 'PROCEDURE',
  RoutineFollowUp = 'ROUTINE_FOLLOW_UP',
  Test = 'TEST',
  TestFormsTesting = 'TEST_FORMS_TESTING',
  WalkIn = 'WALK_IN',
  WellWomen = 'WELL_WOMEN'
}

export enum ElationPhysicianType {
  CareNavigator = 'CARE_NAVIGATOR',
  Physician = 'PHYSICIAN'
}

export type EmailMessage = {
  __typename?: 'EmailMessage';
  createdAt: Scalars['DateTime']['output'];
  deliveredAt?: Maybe<Scalars['DateTime']['output']>;
  fromAddress: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  messageContents?: Maybe<Scalars['String']['output']>;
  status: MessageStatus;
  subject: Scalars['String']['output'];
  template: Scalars['String']['output'];
  toAddress: Scalars['String']['output'];
};

export type Employer = {
  __typename?: 'Employer';
  canEditMembers: Scalars['Boolean']['output'];
  canViewInvoices: Scalars['Boolean']['output'];
  canViewMembers: Scalars['Boolean']['output'];
  claimSystem: ClaimSystem;
  coverageInfo?: Maybe<EmployerCoverageInfo>;
  createdAt: Scalars['DateTime']['output'];
  healthEdgeAccountId?: Maybe<Scalars['String']['output']>;
  healthEdgeAccountKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoices: Array<EmployerInvoice>;
  name: Scalars['String']['output'];
  subAccounts: Array<ChildEmployer>;
  subscribableAccounts: Array<SimpleEmployer>;
  vbaDivisionId?: Maybe<Scalars['String']['output']>;
};


export type EmployerCanEditMembersArgs = {
  userEmail?: InputMaybe<Scalars['String']['input']>;
};


export type EmployerCanViewInvoicesArgs = {
  userEmail?: InputMaybe<Scalars['String']['input']>;
};


export type EmployerCanViewMembersArgs = {
  userEmail?: InputMaybe<Scalars['String']['input']>;
};


export type EmployerInvoicesArgs = {
  numBillingMonthsFilter?: InputMaybe<Scalars['Int']['input']>;
  subAccountIdFilter?: InputMaybe<Scalars['String']['input']>;
};

export type EmployerCoverageInfo = {
  __typename?: 'EmployerCoverageInfo';
  coveragePeriodEndDate?: Maybe<Scalars['Date']['output']>;
  coveragePeriodOptions: Array<CoveragePeriodOption>;
  coveragePeriodStartDate: Scalars['Date']['output'];
  coverageStatus: EmployerCoverageStatus;
  defaultPlanYear?: Maybe<Scalars['Int']['output']>;
  effectiveDateRange: Array<Scalars['Date']['output']>;
  employerState: Scalars['String']['output'];
  planTypes: Array<CoveragePackage>;
  planYears?: Maybe<Array<Scalars['Int']['output']>>;
  plans: Array<Plan>;
};

export enum EmployerCoverageStatus {
  Active = 'ACTIVE',
  Future = 'FUTURE',
  Past = 'PAST'
}

export type EmployerInvoice = {
  __typename?: 'EmployerInvoice';
  accountId: Scalars['String']['output'];
  billingMonth: Scalars['Int']['output'];
  billingYear: Scalars['Int']['output'];
  downloadUrl: Scalars['String']['output'];
  invoiceDate: Scalars['Date']['output'];
};

export type EmployerPermissionInput = {
  canViewInvoices: Scalars['Boolean']['input'];
  canViewMembers: Scalars['Boolean']['input'];
  employerId: Scalars['String']['input'];
};

export type Enrollment = {
  __typename?: 'Enrollment';
  benefitsBookletUrl: Scalars['String']['output'];
  cobraStartDate?: Maybe<Scalars['Date']['output']>;
  divisionId: Scalars['String']['output'];
  endDate: Scalars['Date']['output'];
  fundingType?: Maybe<FundingType>;
  groupCoverageStart: Scalars['Date']['output'];
  groupId: Scalars['String']['output'];
  hasMetINDeductible: Scalars['Boolean']['output'];
  hasMetINOOPMax: Scalars['Boolean']['output'];
  hasMetOONDeductible: Scalars['Boolean']['output'];
  hasMetOONOOPMax: Scalars['Boolean']['output'];
  memberId: Scalars['String']['output'];
  /** @deprecated Use MemberGQL.closestPlan instead. Data will be incorrect for anything other than displayed/closest enrollment. */
  plan: Plan;
  planId: Scalars['String']['output'];
  /** @deprecated This is not employer bound anymore - adapt your logic to rely on the feature flag's 'auto approval enabled for all' key instead */
  qualifiesForAutoZeroCardApprovals: Scalars['Boolean']['output'];
  startDate: Scalars['Date']['output'];
  status: EnrollmentStatus;
  subscriberStartDate: Scalars['Date']['output'];
  tier?: Maybe<Tier>;
  tierId?: Maybe<Scalars['String']['output']>;
  type: EnrollmentType;
};

export enum EnrollmentFileRelationship {
  Child = 'CHILD',
  HandicappedDependent = 'HANDICAPPED_DEPENDENT',
  LifePartner = 'LIFE_PARTNER',
  OtherRelationship = 'OTHER_RELATIONSHIP',
  Self = 'SELF',
  Spouse = 'SPOUSE'
}

export type EnrollmentFileRow = {
  __typename?: 'EnrollmentFileRow';
  benefitPlan?: Maybe<Scalars['String']['output']>;
  birthDate: Scalars['String']['output'];
  cellPhone?: Maybe<Scalars['String']['output']>;
  checkIsBeingTermed: Scalars['Boolean']['output'];
  divisionId: Scalars['String']['output'];
  divisionLocation?: Maybe<Scalars['String']['output']>;
  effectiveDate: Scalars['String']['output'];
  email: Scalars['String']['output'];
  employeeClass?: Maybe<Scalars['String']['output']>;
  enrollmentTier?: Maybe<EnrollmentFileTier>;
  firstName: Scalars['String']['output'];
  gender: Scalars['String']['output'];
  groupId: Scalars['String']['output'];
  groupName?: Maybe<Scalars['String']['output']>;
  homePhone?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  mailAddress: Scalars['String']['output'];
  mailAddress2: Scalars['String']['output'];
  mailCity: Scalars['String']['output'];
  mailState: Scalars['String']['output'];
  mailZipCode: Scalars['String']['output'];
  memberExchangeId: Scalars['String']['output'];
  middleName: Scalars['String']['output'];
  nameSuffix: Scalars['String']['output'];
  planId: Scalars['String']['output'];
  relationship: Scalars['String']['output'];
  relationshipCode?: Maybe<EnrollmentFileRelationship>;
  rowIndex: Scalars['Int']['output'];
  ssn: Scalars['String']['output'];
  subscriberExchangeId: Scalars['String']['output'];
  termDate: Scalars['String']['output'];
  tier: Scalars['String']['output'];
  workPhone?: Maybe<Scalars['String']['output']>;
};


export type EnrollmentFileRowCheckIsBeingTermedArgs = {
  existingMemberPlanEnd?: InputMaybe<Scalars['Date']['input']>;
};

export type EnrollmentFileRowInput = {
  benefitPlan?: InputMaybe<Scalars['String']['input']>;
  birthDate: Scalars['String']['input'];
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  divisionId: Scalars['String']['input'];
  divisionLocation?: InputMaybe<Scalars['String']['input']>;
  effectiveDate: Scalars['String']['input'];
  email: Scalars['String']['input'];
  employeeClass?: InputMaybe<Scalars['String']['input']>;
  enrollmentTier?: InputMaybe<EnrollmentFileTier>;
  firstName: Scalars['String']['input'];
  gender: Scalars['String']['input'];
  groupId: Scalars['String']['input'];
  groupName?: InputMaybe<Scalars['String']['input']>;
  homePhone?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  mailAddress: Scalars['String']['input'];
  mailAddress2: Scalars['String']['input'];
  mailCity: Scalars['String']['input'];
  mailState: Scalars['String']['input'];
  mailZipCode: Scalars['String']['input'];
  memberExchangeId: Scalars['String']['input'];
  middleName: Scalars['String']['input'];
  nameSuffix: Scalars['String']['input'];
  planId: Scalars['String']['input'];
  relationship: Scalars['String']['input'];
  relationshipCode?: InputMaybe<EnrollmentFileRelationship>;
  rowIndex: Scalars['Int']['input'];
  ssn: Scalars['String']['input'];
  subscriberExchangeId: Scalars['String']['input'];
  termDate: Scalars['String']['input'];
  tier: Scalars['String']['input'];
  workPhone?: InputMaybe<Scalars['String']['input']>;
};

export enum EnrollmentFileTier {
  Ec = 'EC',
  Ee = 'EE',
  Ef = 'EF',
  Es = 'ES'
}

export enum EnrollmentStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Cobra = 'COBRA',
  Expired = 'EXPIRED',
  Upcoming = 'UPCOMING'
}

export enum EnrollmentType {
  FullyInsured = 'FULLY_INSURED',
  SelfInsured = 'SELF_INSURED'
}

export enum Ethnicity {
  Hispanic = 'HISPANIC',
  NotHispanic = 'NOT_HISPANIC',
  NotShared = 'NOT_SHARED'
}

export type Facility = {
  __typename?: 'Facility';
  city?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  facilityId: Scalars['String']['output'];
  facilityName: Scalars['String']['output'];
  facilityNamesList?: Maybe<Array<Scalars['String']['output']>>;
  faxNumbersList?: Maybe<Array<Scalars['String']['output']>>;
  focusesList?: Maybe<Array<Scalars['String']['output']>>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  npi?: Maybe<Scalars['String']['output']>;
  providerNetworksList?: Maybe<Array<Scalars['String']['output']>>;
  rawServicesList?: Maybe<Array<Scalars['String']['output']>>;
  serviceDetailsList?: Maybe<Array<ServiceDetail>>;
  servicesList?: Maybe<Array<Scalars['String']['output']>>;
  state?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
  telephonesList?: Maybe<Array<Scalars['String']['output']>>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export enum FacilitySortOption {
  FacilityNameAsc = 'FACILITY_NAME_ASC',
  FacilityNameDesc = 'FACILITY_NAME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC'
}

export type FacilityType = {
  __typename?: 'FacilityType';
  curativeCategory: Scalars['String']['output'];
  curativeFacilityType: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isPopular?: Maybe<Scalars['Boolean']['output']>;
};

export type FacilityTypeMap = {
  __typename?: 'FacilityTypeMap';
  createdAt: Scalars['DateTime']['output'];
  curativeCategory?: Maybe<Scalars['String']['output']>;
  curativeFacilityType?: Maybe<Scalars['String']['output']>;
  editedBy?: Maybe<Scalars['Int']['output']>;
  editedByUser?: Maybe<User>;
  facilityService: Scalars['String']['output'];
  fsrId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum FormType {
  BaselineVisit = 'BASELINE_VISIT',
  BaselineVisitV2Page_1 = 'BASELINE_VISIT_V2_PAGE_1',
  BaselineVisitV2Page_2 = 'BASELINE_VISIT_V2_PAGE_2',
  BaselineVisitV3Page_1 = 'BASELINE_VISIT_V3_PAGE_1',
  BaselineVisitV3Page_2 = 'BASELINE_VISIT_V3_PAGE_2',
  BaselineVisitV4Page_1 = 'BASELINE_VISIT_V4_PAGE_1',
  BaselineVisitV4Page_2 = 'BASELINE_VISIT_V4_PAGE_2',
  BaselineVisitV4Page_3 = 'BASELINE_VISIT_V4_PAGE_3',
  BaselineVisitV4Page_4 = 'BASELINE_VISIT_V4_PAGE_4',
  BaselineVisitV4Page_5 = 'BASELINE_VISIT_V4_PAGE_5',
  BaselineVisitV4Page_6 = 'BASELINE_VISIT_V4_PAGE_6',
  BaselineVisitV4Page_7 = 'BASELINE_VISIT_V4_PAGE_7',
  BaselineVisitV4Page_8 = 'BASELINE_VISIT_V4_PAGE_8',
  BaselineVisitV4Page_9 = 'BASELINE_VISIT_V4_PAGE_9',
  BaselineVisitV4Page_10 = 'BASELINE_VISIT_V4_PAGE_10',
  Miscellaneous = 'MISCELLANEOUS',
  RxTransfer = 'RX_TRANSFER'
}

export enum FundingType {
  FullyFunded = 'FULLY_FUNDED',
  LevelFunded = 'LEVEL_FUNDED',
  SelfFunded = 'SELF_FUNDED'
}

export enum GenderIdentity {
  Man = 'MAN',
  NonBinary = 'NON_BINARY',
  NotListed = 'NOT_LISTED',
  NotShared = 'NOT_SHARED',
  TransMan = 'TRANS_MAN',
  TransWoman = 'TRANS_WOMAN',
  Woman = 'WOMAN'
}

export type HospitalAffiliation = {
  __typename?: 'HospitalAffiliation';
  admittingPrivileges?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  npi?: Maybe<Scalars['String']['output']>;
};

export type IncomingSmsMessage = {
  __typename?: 'IncomingSmsMessage';
  dateCreated: Scalars['DateTime']['output'];
  fromPhoneNumber: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  memberId?: Maybe<Scalars['String']['output']>;
  messageContents: Scalars['String']['output'];
  vendorUid: Scalars['String']['output'];
};

export type IndividualApptPart = {
  __typename?: 'IndividualApptPart';
  careTeamMember: CareTeamMember;
  durationInMinutes: Scalars['Int']['output'];
  endDateTime: Scalars['DateTime']['output'];
  scheduledApptTypeId: Scalars['Int']['output'];
  startDateTime: Scalars['DateTime']['output'];
};

export type IndividualApptPartInput = {
  careTeamMember: CareTeamMemberInput;
  durationInMinutes: Scalars['Int']['input'];
  endDateTime: Scalars['DateTime']['input'];
  scheduledApptTypeId: Scalars['Int']['input'];
  startDateTime: Scalars['DateTime']['input'];
};

export type Invoice = {
  __typename?: 'Invoice';
  createdAt: Scalars['DateTime']['output'];
  createdById: Scalars['Int']['output'];
  creator: User;
  customer: Customer;
  customerId: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  finalizedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  invoiceLineItems: Array<InvoiceLineItem>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  patientMemberId: Scalars['String']['output'];
  paymentUrl?: Maybe<Scalars['String']['output']>;
  status: InvoiceStatus;
  stripeInvoiceId?: Maybe<Scalars['String']['output']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']['output']>;
  subscriberMemberId: Scalars['String']['output'];
  totalAmount: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type InvoiceLineItem = {
  __typename?: 'InvoiceLineItem';
  copay: Scalars['Float']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  fdbRoutedDoseFormGenericId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  invoiceId: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  rxNumber: Scalars['String']['output'];
  unit: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum InvoiceStatus {
  Draft = 'DRAFT',
  Open = 'OPEN',
  Paid = 'PAID',
  Uncollectible = 'UNCOLLECTIBLE',
  Void = 'VOID'
}

export type ItemPage = {
  __typename?: 'ItemPage';
  afterCursor?: Maybe<Scalars['String']['output']>;
  beforeCursor?: Maybe<Scalars['String']['output']>;
  items: Array<PaginatedItem>;
};

export enum Language {
  English = 'ENGLISH',
  Spanish = 'SPANISH'
}

export type LegalAgreement = {
  __typename?: 'LegalAgreement';
  agreementMarkdown?: Maybe<Scalars['String']['output']>;
  allowedSignatureResponseTypes: Array<SignatureResponseType>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isCurrent: Scalars['Boolean']['output'];
  language: Language;
  /** Indicates whether user must be 18 years old to be eligible to sign a legal agreement */
  requiresEligibility: Scalars['Boolean']['output'];
  signatureType: SignatureType;
  summary: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum LibertyPrescriptionWorkflowLocation {
  Courier = 'COURIER',
  CourierNextDay = 'COURIER_NEXT_DAY',
  InternalNextDay = 'INTERNAL_NEXT_DAY',
  InternalSameDay = 'INTERNAL_SAME_DAY',
  NoResponse = 'NO_RESPONSE',
  VoidFill = 'VOID_FILL'
}

export type LicenseNumber = {
  __typename?: 'LicenseNumber';
  licenseNumber?: Maybe<Scalars['String']['output']>;
  licenseState?: Maybe<Scalars['String']['output']>;
};

export enum ManagingGroupId {
  BaselineClinicianTeam = 'BASELINE_CLINICIAN_TEAM',
  CareNavigatorManagers = 'CARE_NAVIGATOR_MANAGERS',
  Chimera = 'CHIMERA',
  ClinicalDocumentManagementTeam = 'CLINICAL_DOCUMENT_MANAGEMENT_TEAM',
  ClinicianManagers = 'CLINICIAN_MANAGERS',
  EmployerPortalSuperusersGroup = 'EMPLOYER_PORTAL_SUPERUSERS_GROUP',
  EmployerUserManagementTeam = 'EMPLOYER_USER_MANAGEMENT_TEAM',
  EngineeringTeam = 'ENGINEERING_TEAM',
  HealthPlanTeam = 'HEALTH_PLAN_TEAM',
  MemberServicesTeam = 'MEMBER_SERVICES_TEAM',
  PermissionManagementTeam = 'PERMISSION_MANAGEMENT_TEAM',
  PharmacyTeam = 'PHARMACY_TEAM',
  ProviderFacilityEditingTeam = 'PROVIDER_FACILITY_EDITING_TEAM',
  /** @deprecated Use CLINICIAN_MANAGERS, CARE_NAVIGATOR_MANAGERS, or BASELINE_CLINICIAN_TEAM instead */
  ProviderManagementTeam = 'PROVIDER_MANAGEMENT_TEAM',
  /** @deprecated Use CLINICIAN_MANAGERS, CARE_NAVIGATOR_MANAGERS, or BASELINE_CLINICIAN_TEAM instead */
  ProviderSchedulingTeam = 'PROVIDER_SCHEDULING_TEAM',
  TpaTeam = 'TPA_TEAM'
}

export type ManagingGroupRelation = {
  __typename?: 'ManagingGroupRelation';
  email?: Maybe<Scalars['String']['output']>;
  managingGroup: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

export type ManagingGroupRelationInput = {
  managingGroup: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export enum MaritalStatus {
  DivorcedSeparated = 'DIVORCED_SEPARATED',
  Married = 'MARRIED',
  NotListed = 'NOT_LISTED',
  NotShared = 'NOT_SHARED',
  Single = 'SINGLE',
  UnmarriedPartner = 'UNMARRIED_PARTNER',
  Widowed = 'WIDOWED'
}

export type Member = CommonMemberGql & {
  __typename?: 'Member';
  accumulator?: Maybe<PlanYearAccumulator>;
  activeEnrollment?: Maybe<Enrollment>;
  /** Returns Member's address if exists, or Member subscriber's address otherwise */
  address?: Maybe<MemberAddress>;
  /** Returns the all member's plan years */
  allMemberPlanYears: Array<MemberPlanYear>;
  birthDate: Scalars['Date']['output'];
  /** Returns the associated Care Navigator, if any */
  careNavigator?: Maybe<CareNavigator>;
  /** Returns Claims for the Member. This is an Admin Portal only endpoint. */
  claims: Array<Claim>;
  /** Returns the member's current plan year, otherwise get the next plan year */
  closestMemberPlanYear?: Maybe<MemberPlanYear>;
  closestPlan: Plan;
  /** Returns Coverage Period which includes Displayed Enrollment */
  displayedCoveragePeriod: CoveragePeriod;
  displayedEnrollment: Enrollment;
  /** Returns the member's enrollment data based on member's current plan year if available, or the next plan year */
  displayedEnrollmentFromClosestMemberPlanYear: Enrollment;
  eSignatures: Array<ESignature>;
  email?: Maybe<Scalars['String']['output']>;
  /** @deprecated The term externalId is being phased out, replace with id */
  externalId: Scalars['String']['output'];
  familyName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  givenName?: Maybe<Scalars['String']['output']>;
  hasActiveEnrollment: Scalars['Boolean']['output'];
  hasClinicalDocuments: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isMinor: Scalars['Boolean']['output'];
  isMinorAtEffectiveDate: Scalars['Boolean']['output'];
  /** Returns the member's policyholder visibility status */
  isPolicyholderVisibilityEnabled: Scalars['Boolean']['output'];
  lastInvitedToPortalAt?: Maybe<Scalars['DateTime']['output']>;
  /** Returns the member's next plan year, otherwise get the current plan year */
  latestMemberPlanYear?: Maybe<MemberPlanYear>;
  /** Returns a presigned S3 URL for the member card of the current Member */
  memberCardUrl?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  paymentCardholder?: Maybe<MemberPaymentCardholder>;
  paymentCards: Array<MemberPaymentCard>;
  planYears: Array<Scalars['Int']['output']>;
  portalRegistrationStatus: MemberPortalRegistrationStatus;
  preferences: MemberPreferences;
  relationship: Relationship;
  sex?: Maybe<Scalars['String']['output']>;
  /** Returns Member subscriber's address. The value is the same for all members in the same group */
  subscriberAddress: MemberAddress;
  subscriberGroup: SubscriberGroup;
  subscriberId: Scalars['String']['output'];
};


export type MemberAccumulatorArgs = {
  planYear: Scalars['Int']['input'];
};


export type MemberIsMinorArgs = {
  atDate?: InputMaybe<Scalars['Date']['input']>;
};


export type MemberMemberCardUrlArgs = {
  displayType?: InputMaybe<MemberCardDisplayType>;
  fileType?: InputMaybe<MemberCardOutputFileType>;
  urlDurationInMinutes?: InputMaybe<Scalars['Long']['input']>;
  urlType?: InputMaybe<MemberCardS3UrlType>;
};

export type MemberAddress = {
  __typename?: 'MemberAddress';
  cellPhone?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  homePhone?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  street: Scalars['String']['output'];
  street2?: Maybe<Scalars['String']['output']>;
  workPhone?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

export enum MemberCardDisplayType {
  Back = 'BACK',
  BothSides = 'BOTH_SIDES',
  Front = 'FRONT'
}

export enum MemberCardOutputFileType {
  Jpeg = 'JPEG',
  Pdf = 'PDF',
  Png = 'PNG'
}

export enum MemberCardS3UrlType {
  Display = 'DISPLAY',
  Download = 'DOWNLOAD',
  Print = 'PRINT'
}

export type MemberContactInfo = {
  __typename?: 'MemberContactInfo';
  cellPhone?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  homePhone?: Maybe<Scalars['String']['output']>;
  workPhone?: Maybe<Scalars['String']['output']>;
};

export type MemberDetails = {
  __typename?: 'MemberDetails';
  consents: Array<ESignature>;
  contactInfo: MemberContactInfo;
  id: Scalars['String']['output'];
  memberInfo: Member;
  /** Returns the Member's preferences; creates a new record if one does not yet exist */
  memberPreferences: MemberPreferences;
  otherPlanMembers: Array<Member>;
  userInfo?: Maybe<User>;
};

export type MemberEhr = {
  __typename?: 'MemberEhr';
  elationPatientId: Scalars['Long']['output'];
  memberId: Scalars['String']['output'];
};

export type MemberForm = {
  __typename?: 'MemberForm';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  formData: Scalars['String']['output'];
  formType: FormType;
  id: Scalars['Int']['output'];
  isCurrent: Scalars['Boolean']['output'];
  language: Language;
  uiSchema?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type MemberFormResponse = {
  __typename?: 'MemberFormResponse';
  createdAt: Scalars['DateTime']['output'];
  formId: Scalars['Int']['output'];
  formResponse: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isValid: Scalars['Boolean']['output'];
  memberForm?: Maybe<MemberForm>;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
};

export type MemberPaymentCard = {
  __typename?: 'MemberPaymentCard';
  cardType: MemberPaymentCardType;
  hasActiveSpendApproval: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  status: MemberPaymentCardStatus;
  stripeId: Scalars['String']['output'];
};

export type MemberPaymentCardSpendApproval = {
  __typename?: 'MemberPaymentCardSpendApproval';
  allowedMerchantCategoryCodes: Array<Scalars['String']['output']>;
  allowedZipCodes: Array<Scalars['String']['output']>;
  amountCents: Scalars['Int']['output'];
  authorizations: Array<PaymentCardAuthorization>;
  createdAt: Scalars['DateTime']['output'];
  deactivationReason?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  memberId: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
  purpose: Scalars['String']['output'];
  startDate: Scalars['Date']['output'];
  status: PaymentCardSpendApprovalStatus;
  usedAmountCents: Scalars['Int']['output'];
  utilizingMemberId: Scalars['String']['output'];
};

export enum MemberPaymentCardStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Inactive = 'INACTIVE'
}

export enum MemberPaymentCardType {
  Physical = 'PHYSICAL',
  Virtual = 'VIRTUAL'
}

export type MemberPaymentCardholder = {
  __typename?: 'MemberPaymentCardholder';
  creationError?: Maybe<Scalars['String']['output']>;
  stripeId?: Maybe<Scalars['String']['output']>;
};

export type MemberPlanYear = {
  __typename?: 'MemberPlanYear';
  baselineVisitStatus: BaselineVisitStatus;
  claimSystem: ClaimSystem;
  cobraStartDate?: Maybe<Scalars['Date']['output']>;
  divisionId: Scalars['String']['output'];
  employerEndAt: Scalars['DateTime']['output'];
  employerEndDate: Scalars['Date']['output'];
  employerName?: Maybe<Scalars['String']['output']>;
  employerStartAt: Scalars['DateTime']['output'];
  employerStartDate: Scalars['Date']['output'];
  enrollmentTimezone: Scalars['String']['output'];
  isLateEnroll: Scalars['Boolean']['output'];
  isPlanHighDeductible: Scalars['Boolean']['output'];
  latestPlanId: Scalars['String']['output'];
  latestTier: Scalars['String']['output'];
  memberEndAt: Scalars['DateTime']['output'];
  memberEndDate: Scalars['Date']['output'];
  memberId: Scalars['String']['output'];
  memberStartAt: Scalars['DateTime']['output'];
  memberStartDate: Scalars['Date']['output'];
  memberStartDateAge: Scalars['Int']['output'];
  originalPlanId: Scalars['String']['output'];
  planCoveragePackage?: Maybe<CoveragePackage>;
  planFundingType: FundingType;
  planYear: Scalars['Int']['output'];
  status: EnrollmentStatus;
  subscriberId: Scalars['String']['output'];
};

export enum MemberPortalRegistrationStatus {
  Ineligible = 'INELIGIBLE',
  NotRegistered = 'NOT_REGISTERED',
  Registered = 'REGISTERED'
}

export type MemberPreferences = {
  __typename?: 'MemberPreferences';
  preferredContactMethod?: Maybe<ContactMethod>;
  preferredLanguage?: Maybe<Language>;
  subscribedToNonTransactionalEmails: Scalars['Boolean']['output'];
};

export enum MessageStatus {
  Accepted = 'ACCEPTED',
  Delivered = 'DELIVERED',
  NoConsent = 'NO_CONSENT',
  Opened = 'OPENED',
  PermanentFail = 'PERMANENT_FAIL',
  Queued = 'QUEUED',
  TemporaryFail = 'TEMPORARY_FAIL'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Mutation to add Employer Portal superuser access. The access can only be granted for @curative.com users */
  addEmployerPortalSuperuserAccess: User;
  /** Add new override record */
  addOverride: Override;
  /** Cancel the baseline visit for a User */
  adminCancelBaselineVisitScheduledAppointment: Scalars['Boolean']['output'];
  /** Cancel a primary care visit */
  adminCancelPrimaryCareVisit: Scalars['Boolean']['output'];
  /** Schedule baseline visit */
  adminScheduleBaselineVisitProviderAvailability: Visit;
  /** Schedule baseline visit */
  adminScheduleBaselineVisitProviderAvailabilityNotSuspend: Visit;
  /** Schedule primary care visit (not a baseline visit) */
  adminSchedulePrimaryCareVisit: Visit;
  /** Resend confirmation email to member with an existing baseline appointment */
  adminSendConfirmationEmail: Scalars['String']['output'];
  backfillLegalAgreementsFromCSV: Array<ESignature>;
  /** Create a new User in response to someone logging into the Admin Portal for the first time. The fact that they were assigned to the app in Entra is sufficient evidence that they should have access, so no further approval is required. */
  createAdminUser: User;
  createAvailability: CareTeamMemberAvailability;
  createCareNavigator: CreateUpdateCareNavigatorResult;
  /** Adds document record to the clinical_documents table and returns a presigned upload url */
  createClinicalDocument: Scalars['String']['output'];
  /** Add new clinician */
  createClinician: Clinician;
  /** Create and optionally send legal agreement sign invite */
  createESignatureInvite: ESignatureInvite;
  createEmployerPortalUser: User;
  /** Create a new Facility Type Map */
  createFacilityTypeMap: FacilityTypeMap;
  /** Create a new Facility Type Map */
  createFacilityTypeMapV2: FacilityTypeMap;
  /** Create new Invoices */
  createInvoice: Invoice;
  /** Create user and managing group relations */
  createManagingGroupRelations: Array<ManagingGroupRelation>;
  /** Create new EHR record */
  createMemberEhr?: Maybe<MemberEhr>;
  /** Create a physical payment card for a member */
  createPhysicalPaymentCard: MemberPaymentCard;
  createPresignedUploadURLForLegalAgreementsBackfill: PresignedUploadData;
  createSchedule: CareTeamMemberSchedule;
  /** Create a new Specialty Map */
  createSpecialtyMap: SpecialtyMap;
  /** Create a new Specialty Map */
  createSpecialtyMapV2: SpecialtyMap;
  /** Create a spend approval window for a given member/card */
  createSpendApproval: MemberPaymentCardSpendApproval;
  /** Creates a Stripe API ephemeral key to display a member's card */
  createStripeEphemeralKeyAdmin: Scalars['String']['output'];
  deactivateEmployerPortalUser?: Maybe<User>;
  /** Deactivate a spend approval */
  deactivateSpendApproval: MemberPaymentCardSpendApproval;
  deleteAvailability: Scalars['Boolean']['output'];
  /** Update availability overrides on a given date for a Care Team Member's schedule */
  deleteAvailabilityOverridesByDate: Array<CareTeamMemberAvailabilityOverride>;
  /** Deletes the schedulable employee */
  deleteCareTeamMember: Scalars['Boolean']['output'];
  /** Deletes document record from the clinical_documents table */
  deleteClinicalDocument: Scalars['Boolean']['output'];
  /** Delete Draft Invoice */
  deleteDraftInvoice: Scalars['Boolean']['output'];
  /** Delete a Facility Type Map */
  deleteFacilityTypeMap: Scalars['Boolean']['output'];
  /** Delete user and managing group relations */
  deleteManagingGroupRelations: Scalars['Boolean']['output'];
  /** Delete visit and corresponding information from database and Elation, only for visits not tied to baseline visit */
  deleteOrphanedVisit: Scalars['Boolean']['output'];
  /** Delete override record */
  deleteOverride: Scalars['Boolean']['output'];
  deleteSchedule: Scalars['Boolean']['output'];
  /** Delete a Specialty Map */
  deleteSpecialtyMap: Scalars['Boolean']['output'];
  /** Disable policyholder visibility for a dependent member */
  disablePolicyholderVisibility: Member;
  /** Enable policyholder visibility for a member */
  enablePolicyholderVisibility: Member;
  /** Extend the due date for a member and their dependents' baseline visits. */
  extendBaselineVisitDueDate: BaselineVisitStatus;
  /** Update availability overrides on a given date for a Care Team Member's schedule */
  patchAvailabilityOverrides: Array<CareTeamMemberAvailabilityOverride>;
  /** Reassigns the schedulable employee assigned to an appointment */
  reassignAppointmentCareTeamMember: ScheduledAppointment;
  reassignMemberCareNavigator: Scalars['Boolean']['output'];
  /** Mutation to remove all employer IDs associated with an Employer Portal user */
  removeAllEmployerIdsForUser: User;
  /** Mutation to remove Employer Portal superuser access */
  removeEmployerPortalSuperuserAccess: User;
  resetBaselineVisitStatus: Scalars['Boolean']['output'];
  /** Run a specified command */
  runCommand: CommandResult;
  /** Send Invoices */
  sendInvoice: Invoice;
  /** Send an email to a member with their Insurance Card */
  sendMemberCardEmail: Scalars['Boolean']['output'];
  /** Sends a one-off email to the listed members (Async) */
  sendOneOffEmail: BaseOneOffEmailWorkflowResult;
  /** Sends a one-off sms to the listed members (Async) */
  sendOneOffSms: BaseOneOffSmsWorkflowResult;
  /** Sends a password reset email to the given email address */
  sendPasswordResetEmail: Scalars['Boolean']['output'];
  /** Create a new RxTransfer Form Response */
  submitRxTransferForm: RxTransfer;
  toggleAvailabilityForDay: Scalars['Boolean']['output'];
  updateAvailability: CareTeamMemberAvailability;
  updateCareNavigator: CreateUpdateCareNavigatorResult;
  /** Set clinician's licensed states */
  updateClinician: Clinician;
  /** Mutation to update the employer IDs associated with an Employer Portal user */
  updateEmployerIdsForUser: User;
  /** Update an existing Facility Type Map */
  updateFacilityTypeMap: FacilityTypeMap;
  /** Update an existing Facility Type Map */
  updateFacilityTypeMapV2: FacilityTypeMap;
  /** Update existing Invoices */
  updateInvoice: Invoice;
  updateMemberPreferences: MemberPreferences;
  /** Update override record */
  updateOverride?: Maybe<Override>;
  /** Updates the patients MetaData in elation to have the correct join-visit url */
  updatePatientMetaData: Scalars['Boolean']['output'];
  updatePhotoUrlForCareNavigator?: Maybe<CareNavigator>;
  /** Update a delivery request record */
  updatePrescriptionDeliveryRequest: PrescriptionDeliveryRequest;
  updateSchedule?: Maybe<CareTeamMemberSchedule>;
  /** Update an existing Specialty Map */
  updateSpecialtyMap: SpecialtyMap;
  /** Update an existing Specialty Map */
  updateSpecialtyMapV2: SpecialtyMap;
  /** Updates the email for a user */
  updateUserEmail: User;
};


export type MutationAddEmployerPortalSuperuserAccessArgs = {
  userId: Scalars['Int']['input'];
};


export type MutationAddOverrideArgs = {
  override: NewOverrideInput;
};


export type MutationAdminCancelBaselineVisitScheduledAppointmentArgs = {
  memberId: Scalars['String']['input'];
};


export type MutationAdminCancelPrimaryCareVisitArgs = {
  memberId: Scalars['String']['input'];
};


export type MutationAdminScheduleBaselineVisitProviderAvailabilityArgs = {
  apptPartList?: InputMaybe<Array<IndividualApptPartInput>>;
  ignoreSchedulingRules: Scalars['Boolean']['input'];
  location: VisitLocation;
  memberId: Scalars['String']['input'];
  physicianId: Scalars['Long']['input'];
  selectedAppointmentLanguage: Language;
  startDateTime: Scalars['DateTime']['input'];
  visitTypeParam?: InputMaybe<VisitTypeInfoInput>;
};


export type MutationAdminScheduleBaselineVisitProviderAvailabilityNotSuspendArgs = {
  apptPartList?: InputMaybe<Array<IndividualApptPartInput>>;
  ignoreSchedulingRules: Scalars['Boolean']['input'];
  location: VisitLocation;
  memberId: Scalars['String']['input'];
  physicianId: Scalars['Long']['input'];
  selectedAppointmentLanguage: Language;
  startDateTime: Scalars['DateTime']['input'];
  visitTypeParam?: InputMaybe<VisitTypeInfoInput>;
};


export type MutationAdminSchedulePrimaryCareVisitArgs = {
  apptPartList?: InputMaybe<Array<IndividualApptPartInput>>;
  clinicianElationId: Scalars['Long']['input'];
  ignoreSchedulingRules: Scalars['Boolean']['input'];
  location: VisitLocation;
  memberId: Scalars['String']['input'];
  selectedAppointmentLanguage: Language;
  startDateTime: Scalars['DateTime']['input'];
  visitTypeParam: VisitTypeInfoInput;
};


export type MutationAdminSendConfirmationEmailArgs = {
  memberId: Scalars['String']['input'];
  sendToMemberPortalEmail: Scalars['Boolean']['input'];
};


export type MutationBackfillLegalAgreementsFromCsvArgs = {
  fileName: Scalars['String']['input'];
};


export type MutationCreateAvailabilityArgs = {
  availability: NewCareTeamMemberAvailabilityInput;
};


export type MutationCreateCareNavigatorArgs = {
  input: CreateCareNavigatorInput;
};


export type MutationCreateClinicalDocumentArgs = {
  input: CreateClinicalDocumentInput;
};


export type MutationCreateClinicianArgs = {
  clinician: ClinicianInput;
};


export type MutationCreateESignatureInviteArgs = {
  memberId: Scalars['String']['input'];
  sendToEmail?: InputMaybe<Scalars['String']['input']>;
  signatureType: SignatureType;
};


export type MutationCreateEmployerPortalUserArgs = {
  input: CreateEmployerPortalUserInput;
};


export type MutationCreateFacilityTypeMapArgs = {
  newFacilityTypeMap: NewFacilityTypeMapInput;
};


export type MutationCreateFacilityTypeMapV2Args = {
  newFacilityTypeMap: NewFacilityTypeMapV2Input;
};


export type MutationCreateInvoiceArgs = {
  newInvoiceRequest: NewInvoiceRequestInput;
};


export type MutationCreateManagingGroupRelationsArgs = {
  newManagingGroupRelationInput: Array<ManagingGroupRelationInput>;
};


export type MutationCreateMemberEhrArgs = {
  memberId: Scalars['String']['input'];
};


export type MutationCreatePhysicalPaymentCardArgs = {
  request: PhysicalMemberPaymentCardCreateRequestInput;
};


export type MutationCreatePresignedUploadUrlForLegalAgreementsBackfillArgs = {
  fileName: Scalars['String']['input'];
};


export type MutationCreateScheduleArgs = {
  schedule: NewCareTeamMemberScheduleInput;
};


export type MutationCreateSpecialtyMapArgs = {
  newSpecialtyMap: NewSpecialtyMapInput;
};


export type MutationCreateSpecialtyMapV2Args = {
  newSpecialtyMap: NewSpecialtyMapV2Input;
};


export type MutationCreateSpendApprovalArgs = {
  request: PaymentCardSpendApprovalRequestInput;
};


export type MutationCreateStripeEphemeralKeyAdminArgs = {
  nonce: Scalars['String']['input'];
  stripeCardId: Scalars['String']['input'];
};


export type MutationDeactivateEmployerPortalUserArgs = {
  userId: Scalars['Int']['input'];
};


export type MutationDeactivateSpendApprovalArgs = {
  deactivationReason: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};


export type MutationDeleteAvailabilityArgs = {
  availabilityId: Scalars['Int']['input'];
};


export type MutationDeleteAvailabilityOverridesByDateArgs = {
  date: Scalars['Date']['input'];
  elationId: Scalars['Long']['input'];
};


export type MutationDeleteCareTeamMemberArgs = {
  elationId: Scalars['Long']['input'];
  employeeType: CareTeamMemberType;
};


export type MutationDeleteClinicalDocumentArgs = {
  documentId: Scalars['Int']['input'];
};


export type MutationDeleteDraftInvoiceArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteFacilityTypeMapArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteManagingGroupRelationsArgs = {
  managingGroupRelationsInput: Array<ManagingGroupRelationInput>;
};


export type MutationDeleteOrphanedVisitArgs = {
  visitId: Scalars['Int']['input'];
};


export type MutationDeleteOverrideArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteScheduleArgs = {
  scheduleId: Scalars['Int']['input'];
};


export type MutationDeleteSpecialtyMapArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDisablePolicyholderVisibilityArgs = {
  id: Scalars['String']['input'];
};


export type MutationEnablePolicyholderVisibilityArgs = {
  id: Scalars['String']['input'];
};


export type MutationExtendBaselineVisitDueDateArgs = {
  memberId: Scalars['String']['input'];
  newDueDate: Scalars['Date']['input'];
};


export type MutationPatchAvailabilityOverridesArgs = {
  date: Scalars['Date']['input'];
  elationId: Scalars['Long']['input'];
  newOverrides: Array<UpdateCareTeamMemberAvailabilityOverrideInput>;
};


export type MutationReassignAppointmentCareTeamMemberArgs = {
  memberId: Scalars['String']['input'];
  newCareTeamMemberElationId: Scalars['Long']['input'];
  scheduledApptId: Scalars['Int']['input'];
  shouldIgnoreAvailability: Scalars['Boolean']['input'];
};


export type MutationReassignMemberCareNavigatorArgs = {
  careNavigatorId?: InputMaybe<Scalars['Int']['input']>;
  memberId: Scalars['String']['input'];
};


export type MutationRemoveAllEmployerIdsForUserArgs = {
  userId: Scalars['Int']['input'];
};


export type MutationRemoveEmployerPortalSuperuserAccessArgs = {
  userId: Scalars['Int']['input'];
};


export type MutationResetBaselineVisitStatusArgs = {
  memberId: Scalars['String']['input'];
  visitId: Scalars['Int']['input'];
};


export type MutationRunCommandArgs = {
  args?: InputMaybe<Array<Scalars['String']['input']>>;
  command: Scalars['String']['input'];
};


export type MutationSendInvoiceArgs = {
  id: Scalars['Int']['input'];
};


export type MutationSendMemberCardEmailArgs = {
  emailAddress: Scalars['String']['input'];
  memberId: Scalars['String']['input'];
};


export type MutationSendOneOffEmailArgs = {
  allowUnsubscribe?: InputMaybe<Scalars['Boolean']['input']>;
  fromAddress?: InputMaybe<Scalars['String']['input']>;
  memberIds: Array<Scalars['String']['input']>;
  replyToAddress?: InputMaybe<Scalars['String']['input']>;
  templateId: Scalars['String']['input'];
  templateName: Scalars['String']['input'];
};


export type MutationSendOneOffSmsArgs = {
  memberIds: Array<Scalars['String']['input']>;
  templateId: Scalars['String']['input'];
  templateName: Scalars['String']['input'];
};


export type MutationSendPasswordResetEmailArgs = {
  emailAddress: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};


export type MutationSubmitRxTransferFormArgs = {
  formResponse: Scalars['String']['input'];
  memberId: Scalars['String']['input'];
};


export type MutationToggleAvailabilityForDayArgs = {
  day: Days;
  isCurrentlyEnabled: Scalars['Boolean']['input'];
  scheduleId: Scalars['Int']['input'];
};


export type MutationUpdateAvailabilityArgs = {
  availability: UpdateCareTeamMemberAvailabilityInput;
};


export type MutationUpdateCareNavigatorArgs = {
  id: Scalars['Int']['input'];
  input: UpdateCareNavigatorInput;
};


export type MutationUpdateClinicianArgs = {
  clinician: UpdateClinicianInput;
};


export type MutationUpdateEmployerIdsForUserArgs = {
  employerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  employerPermissions?: InputMaybe<Array<EmployerPermissionInput>>;
  userId: Scalars['Int']['input'];
};


export type MutationUpdateFacilityTypeMapArgs = {
  updateFacilityTypeMap: UpdateFacilityTypeMapInput;
};


export type MutationUpdateFacilityTypeMapV2Args = {
  updateFacilityTypeMap: UpdateFacilityTypeMapV2Input;
};


export type MutationUpdateInvoiceArgs = {
  updateInvoice: UpdateInvoiceInput;
};


export type MutationUpdateMemberPreferencesArgs = {
  memberId: Scalars['String']['input'];
  updatedPreferences: UpdateMemberPreferencesRequestInput;
};


export type MutationUpdateOverrideArgs = {
  override: UpdateOverrideRequestInput;
};


export type MutationUpdatePatientMetaDataArgs = {
  visitIds: Array<Scalars['Int']['input']>;
};


export type MutationUpdatePhotoUrlForCareNavigatorArgs = {
  careNavigatorId: Scalars['Int']['input'];
  s3Key: Scalars['String']['input'];
};


export type MutationUpdatePrescriptionDeliveryRequestArgs = {
  updatePrescriptionDeliveryRequest: UpdatePrescriptionDeliveryRequestInput;
};


export type MutationUpdateScheduleArgs = {
  scheduleId: Scalars['Int']['input'];
  updateRequest: UpdateCareTeamMemberScheduleInput;
};


export type MutationUpdateSpecialtyMapArgs = {
  updateSpecialtyMap: UpdateSpecialtyMapInput;
};


export type MutationUpdateSpecialtyMapV2Args = {
  updateSpecialtyMap: UpdateSpecialtyMapV2Input;
};


export type MutationUpdateUserEmailArgs = {
  proposedEmail: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type NewCareTeamMemberAvailabilityInput = {
  appointmentTypes: Array<AppointmentType>;
  day: Days;
  enabled: Scalars['Boolean']['input'];
  endTime: Scalars['LocalTime']['input'];
  scheduleId: Scalars['Int']['input'];
  startTime: Scalars['LocalTime']['input'];
};

export type NewCareTeamMemberScheduleInput = {
  elationPhysicianId: Scalars['Long']['input'];
  startDate: Scalars['Date']['input'];
  timeZone: Scalars['String']['input'];
};

export type NewFacilityTypeMapInput = {
  curativeCategory?: InputMaybe<Scalars['String']['input']>;
  curativeFacilityType?: InputMaybe<Scalars['String']['input']>;
  editedBy?: InputMaybe<Scalars['Int']['input']>;
  facilityService: Scalars['String']['input'];
  fsrId?: InputMaybe<Scalars['Int']['input']>;
};

export type NewFacilityTypeMapV2Input = {
  editedBy?: InputMaybe<Scalars['Int']['input']>;
  facilityService: Scalars['String']['input'];
  facilityTypeId: Scalars['Int']['input'];
  fsrId?: InputMaybe<Scalars['Int']['input']>;
};

export type NewInvoiceLineItemRequestInput = {
  copay: Scalars['Float']['input'];
  currency?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  fdbRoutedDoseFormGenericId?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Float']['input'];
  rxNumber: Scalars['String']['input'];
  unit: Scalars['String']['input'];
};

export type NewInvoiceRequestInput = {
  customerEmail: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  finalizedAt?: InputMaybe<Scalars['DateTime']['input']>;
  invoiceLineItems: Array<NewInvoiceLineItemRequestInput>;
  patientMemberId: Scalars['String']['input'];
  status: InvoiceStatus;
  subscriberMemberId: Scalars['String']['input'];
};

export type NewOverrideInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  operation: OperationType;
  originalDataJson?: InputMaybe<Scalars['String']['input']>;
  overrideDataJson?: InputMaybe<Scalars['String']['input']>;
  targetId: Scalars['String']['input'];
  targetTable: OverrideTarget;
};

export type NewSpecialtyMapInput = {
  curativeProviderType?: InputMaybe<Scalars['String']['input']>;
  curativeSpecialty?: InputMaybe<Scalars['String']['input']>;
  editedBy?: InputMaybe<Scalars['Int']['input']>;
  specId?: InputMaybe<Scalars['Int']['input']>;
  specialty: Scalars['String']['input'];
};

export type NewSpecialtyMapV2Input = {
  editedBy?: InputMaybe<Scalars['Int']['input']>;
  providerSpecialtyId: Scalars['Int']['input'];
  specId?: InputMaybe<Scalars['Int']['input']>;
  specialty: Scalars['String']['input'];
};

export type Office = {
  __typename?: 'Office';
  city?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  faxNumbersList?: Maybe<Array<Scalars['String']['output']>>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  npi?: Maybe<Scalars['String']['output']>;
  officeId: Scalars['String']['output'];
  officeName: Scalars['String']['output'];
  point?: Maybe<Point>;
  providerNetworksList?: Maybe<Array<Scalars['String']['output']>>;
  searchRelevance?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
  telephonesList?: Maybe<Array<Scalars['String']['output']>>;
  websitesList?: Maybe<Array<Scalars['String']['output']>>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type OfficeEntry = {
  __typename?: 'OfficeEntry';
  id?: Maybe<Scalars['String']['output']>;
  isAcceptingNewPatients?: Maybe<Scalars['Boolean']['output']>;
  isPrimary?: Maybe<Scalars['Boolean']['output']>;
  officeName?: Maybe<Scalars['String']['output']>;
  telephone?: Maybe<Scalars['String']['output']>;
};

export enum OfficeSortOption {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  OfficeNameAsc = 'OFFICE_NAME_ASC',
  OfficeNameDesc = 'OFFICE_NAME_DESC'
}

export enum OnboardingStatusType {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE'
}

export enum OperationType {
  Archive = 'ARCHIVE',
  Insert = 'INSERT',
  Update = 'UPDATE'
}

export type OutgoingSmsMessage = {
  __typename?: 'OutgoingSmsMessage';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  memberId?: Maybe<Scalars['String']['output']>;
  messageContents: Scalars['String']['output'];
  sendingPlatform: Scalars['String']['output'];
  status: MessageStatus;
  template: Scalars['String']['output'];
  toNumber: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId?: Maybe<Scalars['Int']['output']>;
  vendorUid: Scalars['String']['output'];
};

export type Override = {
  __typename?: 'Override';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  operation: OperationType;
  originalDataJson?: Maybe<Scalars['String']['output']>;
  overrideDataJson?: Maybe<Scalars['String']['output']>;
  targetId: Scalars['String']['output'];
  targetTable: OverrideTarget;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['Int']['output'];
};

export enum OverrideSortOption {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  TargetTableAsc = 'TARGET_TABLE_ASC',
  TargetTableDesc = 'TARGET_TABLE_DESC'
}

export enum OverrideTarget {
  Facility = 'FACILITY',
  Office = 'OFFICE',
  Provider = 'PROVIDER'
}

export type PackagedDrug = {
  __typename?: 'PackagedDrug';
  dispensableDrugId: Scalars['Int']['output'];
  dispensableDrugTallManDesc: Scalars['String']['output'];
  dispensableGenericId: Scalars['Int']['output'];
  drugNameTallmanDesc: Scalars['String']['output'];
  genericDrugNameDesc: Scalars['String']['output'];
  medStrength: Scalars['String']['output'];
  medStrengthUnit: Scalars['String']['output'];
  packagedDrugId: Scalars['Long']['output'];
  routedDoseFormGenericId: Scalars['Int']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  cursor?: Maybe<Scalars['String']['output']>;
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PagedInvoices = {
  __typename?: 'PagedInvoices';
  invoices: Array<Invoice>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedPrescriptionDeliveryRequests = {
  __typename?: 'PagedPrescriptionDeliveryRequests';
  pageInfo?: Maybe<PageInfo>;
  prescriptionDeliveryRequests: Array<PrescriptionDeliveryRequest>;
};

export type PaginatedItem = Claim | Facility | Office | Override | Provider;

export type PaymentCardAuthorization = {
  __typename?: 'PaymentCardAuthorization';
  approvalFlow?: Maybe<StripeAuthorizationApprovalFlow>;
  approved: Scalars['Boolean']['output'];
  authorizationMethod: Scalars['String']['output'];
  authorizedAmountCents: Scalars['Int']['output'];
  cardFinalDigits: Scalars['String']['output'];
  cardId: Scalars['Int']['output'];
  cardStripeId: Scalars['String']['output'];
  cardType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  merchantCategory: Scalars['String']['output'];
  merchantCategoryCode: Scalars['String']['output'];
  merchantCity: Scalars['String']['output'];
  merchantName: Scalars['String']['output'];
  merchantState: Scalars['String']['output'];
  rejectionReason?: Maybe<Scalars['String']['output']>;
  spendApprovalId?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
};

export type PaymentCardSpendApprovalRequestInput = {
  allowedMerchantCategoryCodes: Array<Scalars['String']['input']>;
  allowedZipCodes: Array<Scalars['String']['input']>;
  amountCents: Scalars['Int']['input'];
  endDate: Scalars['Date']['input'];
  memberId: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  providerName?: InputMaybe<Scalars['String']['input']>;
  purpose: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
  utilizingMemberId: Scalars['String']['input'];
};

export enum PaymentCardSpendApprovalStatus {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Expired = 'EXPIRED'
}

export type PendingAppointment = {
  __typename?: 'PendingAppointment';
  careTeamMemberId: Scalars['Long']['output'];
  dateInZone: Scalars['Date']['output'];
  datetimeEnd: Scalars['DateTime']['output'];
  datetimeStart: Scalars['DateTime']['output'];
  elationApptId: Scalars['Long']['output'];
  endTimeInZone: Scalars['LocalTime']['output'];
  memberInfo?: Maybe<PendingAppointmentMemberInfo>;
  reason: PendingAppointmentReason;
  startTimeInZone: Scalars['LocalTime']['output'];
  visitId: Scalars['Int']['output'];
};


export type PendingAppointmentDateInZoneArgs = {
  zone: Scalars['String']['input'];
};


export type PendingAppointmentEndTimeInZoneArgs = {
  zone: Scalars['String']['input'];
};


export type PendingAppointmentStartTimeInZoneArgs = {
  zone: Scalars['String']['input'];
};

export type PendingAppointmentMemberInfo = {
  __typename?: 'PendingAppointmentMemberInfo';
  memberId: Scalars['String']['output'];
  memberName: Scalars['String']['output'];
};

export enum PendingAppointmentReason {
  CouldNotBeDetermined = 'COULD_NOT_BE_DETERMINED',
  NotOrphaned = 'NOT_ORPHANED',
  NoMatchingAppointmentType = 'NO_MATCHING_APPOINTMENT_TYPE',
  NoMatchingTime = 'NO_MATCHING_TIME',
  OverlappingAppointment = 'OVERLAPPING_APPOINTMENT'
}

export type PharmacySearchResultGql = {
  __typename?: 'PharmacySearchResultGQL';
  city: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  state: Scalars['String']['output'];
  street1: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type PhysicalMemberPaymentCardCreateRequestInput = {
  memberId: Scalars['String']['input'];
  shippingAddress: ShippingAddressInput;
  shippingPhoneNumber: Scalars['String']['input'];
};

export type Plan = {
  __typename?: 'Plan';
  coveragePackage?: Maybe<CoveragePackage>;
  description?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  fundingType?: Maybe<FundingType>;
  hasHighDeductible: Scalars['Boolean']['output'];
  planType?: Maybe<PlanType>;
  planYear?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export enum PlanType {
  Noop = 'NOOP',
  Oop = 'OOP'
}

export type PlanYearAccumulator = {
  __typename?: 'PlanYearAccumulator';
  familyDedInNetMax: Scalars['BigDecimal']['output'];
  familyDedInNetUsed: Scalars['BigDecimal']['output'];
  familyDedOONMax: Scalars['BigDecimal']['output'];
  familyDedOONUsed: Scalars['BigDecimal']['output'];
  familyOOPInNetMax: Scalars['BigDecimal']['output'];
  familyOOPInNetUsed: Scalars['BigDecimal']['output'];
  familyOOPOONMax: Scalars['BigDecimal']['output'];
  familyOOPOONUsed: Scalars['BigDecimal']['output'];
  memberDedInNetMax: Scalars['BigDecimal']['output'];
  memberDedInNetUsed: Scalars['BigDecimal']['output'];
  memberDedOONMax: Scalars['BigDecimal']['output'];
  memberDedOONUsed: Scalars['BigDecimal']['output'];
  memberOOPInNetMax: Scalars['BigDecimal']['output'];
  memberOOPInNetUsed: Scalars['BigDecimal']['output'];
  memberOOPOONMax: Scalars['BigDecimal']['output'];
  memberOOPOONUsed: Scalars['BigDecimal']['output'];
};

export type Point = {
  __typename?: 'Point';
  lat: Scalars['Float']['output'];
  lon: Scalars['Float']['output'];
};

export type PrescriptionDeliveryRequest = {
  __typename?: 'PrescriptionDeliveryRequest';
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  checkoutSessionId?: Maybe<Scalars['String']['output']>;
  checkoutSessionUrl?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  confirmedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deliveryInstructions?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  finalizedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  identityVerifiedAt?: Maybe<Scalars['DateTime']['output']>;
  items: Array<PrescriptionDeliveryRequestItem>;
  patientMemberId: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  requestedDeliveryDate?: Maybe<Scalars['Date']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
  subscriberMemberId: Scalars['String']['output'];
  targetLibertyPrescriptionWorkflowLocation?: Maybe<LibertyPrescriptionWorkflowLocation>;
  totalCopay: Scalars['Float']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type PrescriptionDeliveryRequestItem = {
  __typename?: 'PrescriptionDeliveryRequestItem';
  copay: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isControlledSubstance?: Maybe<Scalars['Boolean']['output']>;
  isRefill: Scalars['Boolean']['output'];
  libertyRxNpi: Scalars['String']['output'];
  libertyRxScriptNumber: Scalars['String']['output'];
  libertyVerifiedAt?: Maybe<Scalars['DateTime']['output']>;
  prescriptionDeliveryRequestId: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  requiresRefrigeration?: Maybe<Scalars['Boolean']['output']>;
  requiresSignatureForDelivery: Scalars['Boolean']['output'];
  unit: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PresignedUploadData = {
  __typename?: 'PresignedUploadData';
  fileName: Scalars['String']['output'];
  s3Key: Scalars['String']['output'];
  uploadUrl: Scalars['String']['output'];
};

export enum Pronouns {
  HeHim = 'HE_HIM',
  NotListed = 'NOT_LISTED',
  SheHer = 'SHE_HER',
  TheyThem = 'THEY_THEM'
}

export type Provider = {
  __typename?: 'Provider';
  degreesList?: Maybe<Array<ProviderDegree>>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  hospitalAffiliationsList?: Maybe<Array<HospitalAffiliation>>;
  languagesList?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Scalars['String']['output']>;
  licenseNumbersList?: Maybe<Array<LicenseNumber>>;
  npi?: Maybe<Scalars['String']['output']>;
  officeIdsList?: Maybe<Array<OfficeEntry>>;
  providerId: Scalars['String']['output'];
  specialtiesList?: Maybe<Array<Specialty>>;
};

export type ProviderDegree = {
  __typename?: 'ProviderDegree';
  abbreviation?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ProviderNumberOfAppointments = {
  __typename?: 'ProviderNumberOfAppointments';
  careTeamMemberId: Scalars['Long']['output'];
  totalAcrossApptTypes: Scalars['Int']['output'];
};

export enum ProviderSortOption {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC'
}

export type ProviderSpecialty = {
  __typename?: 'ProviderSpecialty';
  curativeCategory: Scalars['String']['output'];
  curativeSpecialty: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isPopular?: Maybe<Scalars['Boolean']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** Fetches the active admin user based on token */
  activeAdminUser: User;
  /** Get active schedulable employees of one type (clinicians or care navigators), disregarding availability */
  activeAndEligibleForReassignment: Array<CareTeamMember>;
  /** Fetches appointment slots for baseline visit availability for a member between the range start and end on the Admin portal */
  adminBaselineVisitAppointmentSlotsV4: Array<AppointmentWindowV2>;
  /** Fetches baseline visit settings of a specific member */
  adminBaselineVisitSettingsByMemberId: BaselineVisitSettings;
  /** Fetches available appointment windows for Primary Care Scheduling */
  adminPrimaryCareAppointmentWindows: Array<AppointmentWindowV2>;
  /** Fetch an upcoming primary care visit for a member */
  adminPrimaryCareVisit?: Maybe<Visit>;
  /** Returns a pre-signed S3 URL for the bulk member card file associated with the given member IDs */
  bulkMemberCardUrl: Scalars['String']['output'];
  /** Returns the associated Care Navigator, if any */
  careNavigatorForMember?: Maybe<CareNavigator>;
  careNavigators: Array<CareNavigator>;
  /** Fetches a list of schedulable employees available to be assigned to a specific appointment. */
  careTeamMembersAvailableForAppointment: Array<CareTeamMember>;
  /** Returns all ESignature invites for the given member */
  eSignatureInvites: Array<ESignatureInvite>;
  /** Get all past emails for a given Member */
  emailMessagesByMemberId: Array<EmailMessage>;
  employerUserByEmail: User;
  employers: Array<Employer>;
  /** Queries for all Facility Network records and returns paginated results */
  facilitiesPage: ItemPage;
  /** Returns custom facility type mappings records */
  facilityTypeMappings: Array<FacilityTypeMap>;
  /** Returns custom facility type mappings records */
  facilityTypeMappingsV2: Array<FacilityTypeMap>;
  /** Returns a list of facility types + ids from the facility_types table */
  facilityTypes: Array<FacilityType>;
  /** Generates an S3 presigned URL for Correspondence PDF files stored in the S3 HE correspondence bucket. */
  generateCorrespondenceUrl: Scalars['String']['output'];
  /** Generates an S3 presigned URL for an EOB PDF file stored in the S3 HE correspondence bucket. */
  generateEOBUrl: Scalars['String']['output'];
  /**
   * Fetches a list of all appointments for a clinician.
   * @deprecated This will be removed soon, only kept in case we need to fallback for now.
   */
  getAllAppointmentsByClinician: Array<ScheduledAppointment>;
  /** Fetches a list of all appointments for a care team member within a given start and end date. */
  getAllAppointmentsForCtmInDateRange: Array<ScheduledAppointment>;
  /** Fetches a list of all appointments for all Schedulable Employees in a date range. */
  getAllCareTeamMemberAppointmentsInDateRange: Array<ScheduledAppointment>;
  /** Returns a list of all the schedules for each Care Team Member */
  getAllCareTeamMemberSchedules: Array<CareTeamMemberSchedule>;
  /** Fetches all CareTeamMembers from our database and returns a list */
  getAllCareTeamMembers: Array<CareTeamMember>;
  /** Returns a list of all the schedules for each Schedulable Employee */
  getAllCareTeamMembersSchedules: Array<CareTeamMemberSchedule>;
  /** Fetches a list of all appointments for all clinician. */
  getAllClinicianAppointments: Array<ScheduledAppointment>;
  /** Returns a list of all the overrides for each Clinician */
  getAllClinicianOverrides: Array<CareTeamMemberAvailabilityOverride>;
  /** Fetches all clinician from our database and returns a list */
  getAllClinicians: Array<Clinician>;
  /** Returns a list of all distinct Division IDs in the MemberPlanYear table */
  getAllDistinctDivisionIDs: Array<DivisionIdObject>;
  getCareNavigatorByElationId?: Maybe<CareNavigator>;
  getCareNavigatorById?: Maybe<CareNavigator>;
  /** Fetches schedulable employee information from our database */
  getCareTeamMember: CareTeamMember;
  /** Fetches clinician information from our database */
  getClinician: Clinician;
  /** Fetches availability count per provider in 30 minute intervals */
  getClinicianAndCareNavigatorAvailabilityCount: AppointmentAvailabilityCurrentAndGrossTotal;
  getClinicianOverrides: Array<CareTeamMemberAvailabilityOverride>;
  /** Fetches clinician on a specific hour interval and returns a list. */
  getCliniciansOnHourInterval: Array<Maybe<Clinician>>;
  /** Fetches member forms */
  getMemberForms: Array<MemberForm>;
  /** Fetches a PackagedDrug from FDB by id */
  getPackagedDrug?: Maybe<PackagedDrug>;
  /** Fetches RxTransfer request objects along with the form contents */
  getRxTransfers: Array<RxTransfer>;
  /** Query to get the correct Zoom join link */
  getVisitMeetingInfo: VisitMeetingInfoGql;
  /** Query for checking the health of the GraphQL server */
  health: Scalars['Boolean']['output'];
  /** Get all incoming SMS messages received from a given Member */
  incomingSmsMessagesByMemberId: Array<IncomingSmsMessage>;
  /** Returns a list of distinct records of languages from all available Providers */
  languages: Array<Scalars['String']['output']>;
  /** Returns a list of the legal agreements matching the provided criteria */
  legalAgreements: Array<LegalAgreement>;
  /** Fetch all user and managing group relations, optionally filtered by email and managing group */
  managingGroupRelations: Array<ManagingGroupRelation>;
  /** Fetch all available managing groups from Authzed */
  managingGroups: Array<Scalars['String']['output']>;
  /** Get the member's information for the Admin Portal */
  memberDetailsById: MemberDetails;
  /** Fetches member EHR information on a Member ID */
  memberEhr?: Maybe<MemberEhr>;
  /** Query to fetch Office records from Snowflake */
  offices: ItemPage;
  /** Get all outgoing SMS messages sent to a given Member */
  outgoingSmsMessagesByMemberId: Array<OutgoingSmsMessage>;
  /** Fetches overrides */
  overrides: ItemPage;
  /** Return PagedInvoice records, with optional filtering */
  pagedInvoices: PagedInvoices;
  /** Return PagedPrescriptionDeliveryRequest records, with optional filtering */
  pagedPrescriptionDeliveryRequests: PagedPrescriptionDeliveryRequests;
  /** Get all payment authorizations made with a member's card(s) */
  paymentAuthorizations: Array<PaymentCardAuthorization>;
  /** Returns a list of appointments that are scheduled, but not covered by a provider's stated availability */
  pendingAppointments: Array<PendingAppointment>;
  pharmacyTeamUsers: Array<SimpleUser>;
  /** Returns a list of distinct records of degrees from all available Providers */
  providerDegrees: Array<ProviderDegree>;
  /** Returns a list of specialties + ids from the provider_specialties table */
  providerSpecialties: Array<ProviderSpecialty>;
  /** Returns Provider records */
  providers: ItemPage;
  schedules: Array<CareTeamMemberSchedule>;
  /** Searches for admin users by email address given a search term */
  searchAdminUsersByEmail: Array<User>;
  /** Searches for employer users by email address given a search term */
  searchEmployerUsersByEmail: Array<User>;
  /** Searches for members. Can match by any of their names or by their DOB (in mm/dd/yyyy or yyyy-mm-dd format), email, phone number, member ID, or subscriber ID. */
  searchMembers: Array<Member>;
  /** Searches for formulary drugs using the FDB searchPackagedDrugs Endpoint, returning DispensableDrugs enriched with some PackagedDrug fields */
  searchPackagedDrugs?: Maybe<Array<PackagedDrug>>;
  searchPharmacies: Array<PharmacySearchResultGql>;
  /** Returns a pre-signed S3 URL for the member card file associated with the given member ID */
  singleMemberCardUrl: Scalars['String']['output'];
  /** Returns a list of distinct specialties from all of the available Providers */
  specialties: Array<Scalars['String']['output']>;
  /** Returns custom specialty mappings records */
  specialtyMappings: Array<SpecialtyMap>;
  /** Returns custom specialty mappings records */
  specialtyMappingsV2: Array<SpecialtyMap>;
  /** Get all spend approval's associated with a member's card(s) */
  spendApprovals: Array<MemberPaymentCardSpendApproval>;
  validateAndConvertV3File: ConversionValidateResponse;
  validateDailyEnrollmentFile: Array<ValidationMessage>;
  validateEnrollmentFileAndTransmit: ValidateResponse;
  validateNewEnrollmentFile: Array<ValidationMessage>;
  /** Get the care navigator associated with the appointment's visit */
  visitCareNavigator: CareTeamMember;
  /** Get the clinician associated with the appointment's visit */
  visitClinician?: Maybe<CareTeamMember>;
  /** Determines if the member has requested a spanish language appointment */
  wasSpanishAppointmentRequested: Scalars['Boolean']['output'];
};


export type QueryActiveAndEligibleForReassignmentArgs = {
  employeeType: CareTeamMemberType;
  memberId: Scalars['String']['input'];
};


export type QueryAdminBaselineVisitAppointmentSlotsV4Args = {
  isPrimaryCareBLV: Scalars['Boolean']['input'];
  memberId: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
};


export type QueryAdminBaselineVisitSettingsByMemberIdArgs = {
  memberId: Scalars['String']['input'];
};


export type QueryAdminPrimaryCareAppointmentWindowsArgs = {
  memberId: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
};


export type QueryAdminPrimaryCareVisitArgs = {
  memberId: Scalars['String']['input'];
};


export type QueryBulkMemberCardUrlArgs = {
  memberIds: Array<Scalars['String']['input']>;
  urlDurationInMinutes?: InputMaybe<Scalars['Long']['input']>;
  urlType?: InputMaybe<MemberCardS3UrlType>;
};


export type QueryCareNavigatorForMemberArgs = {
  memberId: Scalars['String']['input'];
};


export type QueryCareTeamMembersAvailableForAppointmentArgs = {
  currentCareTeamMemberElationId: Scalars['Long']['input'];
  memberId: Scalars['String']['input'];
  scheduledApptId: Scalars['Int']['input'];
};


export type QueryESignatureInvitesArgs = {
  memberId: Scalars['String']['input'];
};


export type QueryEmailMessagesByMemberIdArgs = {
  memberId: Scalars['String']['input'];
};


export type QueryEmployerUserByEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryEmployersArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFacilitiesPageArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bounds?: InputMaybe<Array<Scalars['Float']['input']>>;
  category?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  exactSearch?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  shouldSearchByNameOnly?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<FacilitySortOption>;
  state?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFacilityTypeMappingsArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFacilityTypeMappingsV2Args = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGenerateCorrespondenceUrlArgs = {
  correspondenceType: CorrespondenceType;
  params: Array<Scalars['String']['input']>;
};


export type QueryGenerateEobUrlArgs = {
  claimNumber: Scalars['String']['input'];
  memberId: Scalars['String']['input'];
};


export type QueryGetAllAppointmentsByClinicianArgs = {
  careTeamMemberId: Scalars['Long']['input'];
};


export type QueryGetAllAppointmentsForCtmInDateRangeArgs = {
  careTeamMemberId: Scalars['Long']['input'];
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  timeZone: Scalars['String']['input'];
};


export type QueryGetAllCareTeamMemberAppointmentsInDateRangeArgs = {
  employeeType?: InputMaybe<CareTeamMemberType>;
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  timeZone: Scalars['String']['input'];
};


export type QueryGetCareNavigatorByElationIdArgs = {
  elationId: Scalars['Long']['input'];
};


export type QueryGetCareNavigatorByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetCareTeamMemberArgs = {
  elationId: Scalars['String']['input'];
};


export type QueryGetClinicianArgs = {
  elationId: Scalars['String']['input'];
};


export type QueryGetClinicianAndCareNavigatorAvailabilityCountArgs = {
  rangeEndDate: Scalars['Date']['input'];
  rangeStartDate: Scalars['Date']['input'];
  timeZone: Scalars['String']['input'];
};


export type QueryGetClinicianOverridesArgs = {
  elationPhysicianId: Scalars['Long']['input'];
};


export type QueryGetCliniciansOnHourIntervalArgs = {
  hourInterval: Scalars['Int']['input'];
};


export type QueryGetMemberFormsArgs = {
  formType?: InputMaybe<FormType>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  isCurrent?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
};


export type QueryGetPackagedDrugArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetRxTransfersArgs = {
  memberIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type QueryGetVisitMeetingInfoArgs = {
  idToken: Scalars['UUID']['input'];
};


export type QueryIncomingSmsMessagesByMemberIdArgs = {
  memberId: Scalars['String']['input'];
};


export type QueryLegalAgreementsArgs = {
  current?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  language?: InputMaybe<Language>;
  signatureType?: InputMaybe<SignatureType>;
};


export type QueryManagingGroupRelationsArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  managingGroup?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMemberDetailsByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryMemberEhrArgs = {
  memberId: Scalars['String']['input'];
};


export type QueryOfficesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bounds?: InputMaybe<Array<Scalars['Float']['input']>>;
  city?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<OfficeSortOption>;
  state?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOutgoingSmsMessagesByMemberIdArgs = {
  memberId: Scalars['String']['input'];
};


export type QueryOverridesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  operations?: InputMaybe<OperationType>;
  sort?: InputMaybe<OverrideSortOption>;
  targetIds?: InputMaybe<Array<Scalars['String']['input']>>;
  targetTable?: InputMaybe<OverrideTarget>;
};


export type QueryPagedInvoicesArgs = {
  customerId?: InputMaybe<Scalars['Int']['input']>;
  customerName?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  patientMemberId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InvoiceStatus>;
  stripeInvoiceId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPagedPrescriptionDeliveryRequestsArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type QueryPaymentAuthorizationsArgs = {
  memberId: Scalars['String']['input'];
};


export type QueryPendingAppointmentsArgs = {
  careTeamMemberId: Scalars['Long']['input'];
};


export type QueryProvidersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  exactSearch?: InputMaybe<Scalars['Boolean']['input']>;
  hospitalAffiliations?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  officeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  officeName?: InputMaybe<Scalars['String']['input']>;
  providerTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  shouldSearchByNameOnly?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<ProviderSortOption>;
  specialties?: InputMaybe<Array<Scalars['String']['input']>>;
  state?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySchedulesArgs = {
  elationPhysicianId: Scalars['Long']['input'];
};


export type QuerySearchAdminUsersByEmailArgs = {
  search: Scalars['String']['input'];
};


export type QuerySearchEmployerUsersByEmailArgs = {
  search: Scalars['String']['input'];
};


export type QuerySearchMembersArgs = {
  searchQuery: Scalars['String']['input'];
};


export type QuerySearchPackagedDrugsArgs = {
  searchTerm: Scalars['String']['input'];
  shouldSearchMedicalDevices?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySearchPharmaciesArgs = {
  query: Scalars['String']['input'];
  zipcode: Scalars['String']['input'];
};


export type QuerySingleMemberCardUrlArgs = {
  fileType: MemberCardOutputFileType;
  memberId: Scalars['String']['input'];
  urlDurationInMinutes?: InputMaybe<Scalars['Long']['input']>;
  urlType: MemberCardS3UrlType;
};


export type QuerySpecialtyMappingsArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySpecialtyMappingsV2Args = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySpendApprovalsArgs = {
  memberId: Scalars['String']['input'];
};


export type QueryValidateAndConvertV3FileArgs = {
  enrollmentFileRows: Array<EnrollmentFileRowInput>;
  filename: Scalars['String']['input'];
  headers: Array<Scalars['String']['input']>;
  isDaily: Scalars['Boolean']['input'];
};


export type QueryValidateDailyEnrollmentFileArgs = {
  enrollmentFileRows: Array<EnrollmentFileRowInput>;
  headers: Array<Scalars['String']['input']>;
  isHealthEdge?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryValidateEnrollmentFileAndTransmitArgs = {
  enrollmentFileRows: Array<EnrollmentFileRowInput>;
  filename: Scalars['String']['input'];
  headers: Array<Scalars['String']['input']>;
  isDaily: Scalars['Boolean']['input'];
  isHealthEdge: Scalars['Boolean']['input'];
};


export type QueryValidateNewEnrollmentFileArgs = {
  enrollmentFileRows: Array<EnrollmentFileRowInput>;
  headers: Array<Scalars['String']['input']>;
  isHealthEdge?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryVisitCareNavigatorArgs = {
  scheduledApptId: Scalars['Int']['input'];
};


export type QueryVisitClinicianArgs = {
  scheduledApptId: Scalars['Int']['input'];
};


export type QueryWasSpanishAppointmentRequestedArgs = {
  memberId: Scalars['String']['input'];
};

export enum Race {
  AmericanIndian = 'AMERICAN_INDIAN',
  Asian = 'ASIAN',
  Black = 'BLACK',
  Multiracial = 'MULTIRACIAL',
  NativeHawaiian = 'NATIVE_HAWAIIAN',
  NotListed = 'NOT_LISTED',
  NotShared = 'NOT_SHARED',
  White = 'WHITE'
}

export enum Relationship {
  Accountant = 'ACCOUNTANT',
  ActivitySponsor = 'ACTIVITY_SPONSOR',
  AdoptedChild = 'ADOPTED_CHILD',
  AdoptedDaughter = 'ADOPTED_DAUGHTER',
  AdoptedSon = 'ADOPTED_SON',
  AdoptiveFather = 'ADOPTIVE_FATHER',
  AdoptiveMother = 'ADOPTIVE_MOTHER',
  AdoptiveParents = 'ADOPTIVE_PARENTS',
  Advisor = 'ADVISOR',
  AgencyRepresentative = 'AGENCY_REPRESENTATIVE',
  AlmaMater = 'ALMA_MATER',
  Annuitant = 'ANNUITANT',
  Applicant = 'APPLICANT',
  Aunt = 'AUNT',
  Banker = 'BANKER',
  Betrothed = 'BETROTHED',
  BothParents = 'BOTH_PARENTS',
  Brother = 'BROTHER',
  BrotherInLaw = 'BROTHER_IN_LAW',
  Business = 'BUSINESS',
  BusinessAssociate = 'BUSINESS_ASSOCIATE',
  BusinessInsuranceTrust = 'BUSINESS_INSURANCE_TRUST',
  BusinessPartner = 'BUSINESS_PARTNER',
  CadaverDonor = 'CADAVER_DONOR',
  Charity = 'CHARITY',
  Child = 'CHILD',
  ChildrenOfMarriage = 'CHILDREN_OF_MARRIAGE',
  ChildInLaw = 'CHILD_IN_LAW',
  ChildOfADomesticPartner = 'CHILD_OF_A_DOMESTIC_PARTNER',
  ChildWhereInsuredHasNoFinancialResponsibility = 'CHILD_WHERE_INSURED_HAS_NO_FINANCIAL_RESPONSIBILITY',
  Clergyman = 'CLERGYMAN',
  Client = 'CLIENT',
  ClubOrOrganizationOfficer = 'CLUB_OR_ORGANIZATION_OFFICER',
  Coach = 'COACH',
  CollateralDependent = 'COLLATERAL_DEPENDENT',
  Company = 'COMPANY',
  Corporation = 'CORPORATION',
  CourtAppointedGuardian = 'COURT_APPOINTED_GUARDIAN',
  Cousin = 'COUSIN',
  CoWorker = 'CO_WORKER',
  Creditor = 'CREDITOR',
  Daughter = 'DAUGHTER',
  DaughterInLaw = 'DAUGHTER_IN_LAW',
  Dependent = 'DEPENDENT',
  DependentOfAMinorDependent = 'DEPENDENT_OF_A_MINOR_DEPENDENT',
  Doctor = 'DOCTOR',
  EcclesiasticalOrReligiousLeader = 'ECCLESIASTICAL_OR_RELIGIOUS_LEADER',
  EducatorOrTeacherOrInstructor = 'EDUCATOR_OR_TEACHER_OR_INSTRUCTOR',
  EmancipatedMinor = 'EMANCIPATED_MINOR',
  EmergencyContact = 'EMERGENCY_CONTACT',
  Employee = 'EMPLOYEE',
  Employer = 'EMPLOYER',
  Estate = 'ESTATE',
  ExHusband = 'EX_HUSBAND',
  ExSpouse = 'EX_SPOUSE',
  ExWife = 'EX_WIFE',
  FamilyMember = 'FAMILY_MEMBER',
  Father = 'FATHER',
  FatherInLaw = 'FATHER_IN_LAW',
  FianceFemale = 'FIANCE_FEMALE',
  FianceMale = 'FIANCE_MALE',
  Fiduciary = 'FIDUCIARY',
  FosterChild = 'FOSTER_CHILD',
  FosterDaughter = 'FOSTER_DAUGHTER',
  FosterFather = 'FOSTER_FATHER',
  FosterMother = 'FOSTER_MOTHER',
  FosterParent = 'FOSTER_PARENT',
  FosterSon = 'FOSTER_SON',
  Friend = 'FRIEND',
  GodDaughter = 'GOD_DAUGHTER',
  GodFather = 'GOD_FATHER',
  GodMother = 'GOD_MOTHER',
  GodParents = 'GOD_PARENTS',
  GodSon = 'GOD_SON',
  Grandchild = 'GRANDCHILD',
  Grandchildren = 'GRANDCHILDREN',
  Granddaughter = 'GRANDDAUGHTER',
  Grandfather = 'GRANDFATHER',
  Grandmother = 'GRANDMOTHER',
  Grandparent = 'GRANDPARENT',
  Grandparents = 'GRANDPARENTS',
  Grandson = 'GRANDSON',
  GrandsonOrGranddaughter = 'GRANDSON_OR_GRANDDAUGHTER',
  GreatAunt = 'GREAT_AUNT',
  Guardian = 'GUARDIAN',
  HalfBrother = 'HALF_BROTHER',
  HalfSister = 'HALF_SISTER',
  HandicappedDependent = 'HANDICAPPED_DEPENDENT',
  Husband = 'HUSBAND',
  InjuredPlaintiff = 'INJURED_PLAINTIFF',
  Institution = 'INSTITUTION',
  Insured = 'INSURED',
  Lawyer = 'LAWYER',
  LifePartner = 'LIFE_PARTNER',
  MedicalCareProvider = 'MEDICAL_CARE_PROVIDER',
  MinisterOrPriest = 'MINISTER_OR_PRIEST',
  MortgageHolder = 'MORTGAGE_HOLDER',
  Mother = 'MOTHER',
  MotherInLaw = 'MOTHER_IN_LAW',
  MutuallyDefined = 'MUTUALLY_DEFINED',
  Neighbor = 'NEIGHBOR',
  Nephew = 'NEPHEW',
  NephewOrNiece = 'NEPHEW_OR_NIECE',
  Niece = 'NIECE',
  None = 'NONE',
  NonApplicableIndividualRelationshipCategory = 'NON_APPLICABLE_INDIVIDUAL_RELATIONSHIP_CATEGORY',
  OrganDonor = 'ORGAN_DONOR',
  Other = 'OTHER',
  OtherAdult = 'OTHER_ADULT',
  OtherRelationship = 'OTHER_RELATIONSHIP',
  OtherRelative = 'OTHER_RELATIVE',
  OtherSchoolAdministrator = 'OTHER_SCHOOL_ADMINISTRATOR',
  Owner = 'OWNER',
  Parent = 'PARENT',
  ParentsInLaw = 'PARENTS_IN_LAW',
  ParentInLaw = 'PARENT_IN_LAW',
  Partner = 'PARTNER',
  Partnership = 'PARTNERSHIP',
  Payor = 'PAYOR',
  PersonalInsuranceTrust = 'PERSONAL_INSURANCE_TRUST',
  ProbationOfficer = 'PROBATION_OFFICER',
  SchoolCounselor = 'SCHOOL_COUNSELOR',
  SchoolPrincipal = 'SCHOOL_PRINCIPAL',
  Self = 'SELF',
  Sibling = 'SIBLING',
  SiblingInLaw = 'SIBLING_IN_LAW',
  SignificantOther = 'SIGNIFICANT_OTHER',
  Sister = 'SISTER',
  SisterInLaw = 'SISTER_IN_LAW',
  SoleProprietorship = 'SOLE_PROPRIETORSHIP',
  Son = 'SON',
  SonInLaw = 'SON_IN_LAW',
  SonOrDaughter = 'SON_OR_DAUGHTER',
  SponsoredDependent = 'SPONSORED_DEPENDENT',
  Spouse = 'SPOUSE',
  StateFund = 'STATE_FUND',
  Stepchild = 'STEPCHILD',
  Stepfather = 'STEPFATHER',
  Stepmother = 'STEPMOTHER',
  Stepparent = 'STEPPARENT',
  StepBrother = 'STEP_BROTHER',
  StepChildren = 'STEP_CHILDREN',
  StepDaughter = 'STEP_DAUGHTER',
  StepSister = 'STEP_SISTER',
  StepSon = 'STEP_SON',
  Student = 'STUDENT',
  Supervisor = 'SUPERVISOR',
  Teacher = 'TEACHER',
  Trust = 'TRUST',
  Trustee = 'TRUSTEE',
  Uncle = 'UNCLE',
  UncleOrAunt = 'UNCLE_OR_AUNT',
  Unknown = 'UNKNOWN',
  Ward = 'WARD',
  Widow = 'WIDOW',
  Widower = 'WIDOWER',
  Wife = 'WIFE'
}

export type RxTransfer = {
  __typename?: 'RxTransfer';
  createdAt: Scalars['DateTime']['output'];
  formResponse?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  memberId?: Maybe<Scalars['String']['output']>;
  memberUserId?: Maybe<Scalars['Int']['output']>;
  pharmacyNotes?: Maybe<Scalars['String']['output']>;
  pharmacyTeamUserId?: Maybe<Scalars['Int']['output']>;
  rxTransferFormResponse?: Maybe<MemberFormResponse>;
  rxTransferFormResponseId?: Maybe<Scalars['Int']['output']>;
  status: RxTransferStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export enum RxTransferStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Denied = 'DENIED',
  InProgress = 'IN_PROGRESS',
  Submitted = 'SUBMITTED'
}

export type ScheduledAppointment = {
  __typename?: 'ScheduledAppointment';
  /** The clinician or care navigator associated with the appointment */
  careTeamMember: CareTeamMember;
  careTeamMemberId: Scalars['Long']['output'];
  dateInUTC: Scalars['Date']['output'];
  dateInZone: Scalars['Date']['output'];
  datetimeEnd: Scalars['DateTime']['output'];
  datetimeStart: Scalars['DateTime']['output'];
  elationApptId?: Maybe<Scalars['Long']['output']>;
  endTimeInUTC: Scalars['LocalTime']['output'];
  endTimeInZone: Scalars['LocalTime']['output'];
  id: Scalars['Int']['output'];
  isLead: Scalars['Boolean']['output'];
  scheduledAppointmentType: Scalars['Int']['output'];
  startTimeInUTC: Scalars['LocalTime']['output'];
  startTimeInZone: Scalars['LocalTime']['output'];
  visit?: Maybe<Visit>;
  visitId?: Maybe<Scalars['Int']['output']>;
};


export type ScheduledAppointmentDateInZoneArgs = {
  zone: Scalars['String']['input'];
};


export type ScheduledAppointmentEndTimeInZoneArgs = {
  zone: Scalars['String']['input'];
};


export type ScheduledAppointmentStartTimeInZoneArgs = {
  zone: Scalars['String']['input'];
};

export type ServiceDetail = {
  __typename?: 'ServiceDetail';
  curativeCategory?: Maybe<Scalars['String']['output']>;
  curativeFacilityType?: Maybe<Scalars['String']['output']>;
  facilityTypeId?: Maybe<Scalars['Int']['output']>;
};

export enum SexAtBirth {
  Female = 'FEMALE',
  Intersex = 'INTERSEX',
  Male = 'MALE',
  NotShared = 'NOT_SHARED'
}

export type ShippingAddressInput = {
  city: Scalars['String']['input'];
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export enum SignatureResponseType {
  Consent = 'consent',
  DoNotConsent = 'do_not_consent',
  HaveReadAndUnderstand = 'have_read_and_understand'
}

export enum SignatureType {
  AssignmentOfBenefits = 'ASSIGNMENT_OF_BENEFITS',
  BaselineVisitAssignmentOfBenefits = 'BASELINE_VISIT_ASSIGNMENT_OF_BENEFITS',
  BaselineVisitCommunicationsConsent = 'BASELINE_VISIT_COMMUNICATIONS_CONSENT',
  BaselineVisitPrivacyConsent = 'BASELINE_VISIT_PRIVACY_CONSENT',
  CommunicationsConsent = 'COMMUNICATIONS_CONSENT',
  ESignatureConsent = 'E_SIGNATURE_CONSENT',
  HealthInformationConsent = 'HEALTH_INFORMATION_CONSENT',
  HipaaConsent = 'HIPAA_CONSENT',
  MemberPaymentCardConsent = 'MEMBER_PAYMENT_CARD_CONSENT',
  PrivacyConsent = 'PRIVACY_CONSENT',
  WellnessProgramNotice = 'WELLNESS_PROGRAM_NOTICE'
}

export type SimpleEmployer = {
  __typename?: 'SimpleEmployer';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SimpleUser = {
  __typename?: 'SimpleUser';
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  preferredName?: Maybe<Scalars['String']['output']>;
};

export type Specialty = {
  __typename?: 'Specialty';
  curativeProviderType?: Maybe<Scalars['String']['output']>;
  curativeSpecialty?: Maybe<Scalars['String']['output']>;
  providerSpecialtyId?: Maybe<Scalars['Int']['output']>;
  rawSpecialty?: Maybe<Scalars['String']['output']>;
};

export type SpecialtyMap = {
  __typename?: 'SpecialtyMap';
  createdAt: Scalars['DateTime']['output'];
  curativeCategory?: Maybe<Scalars['String']['output']>;
  curativeProviderType?: Maybe<Scalars['String']['output']>;
  curativeSpecialty?: Maybe<Scalars['String']['output']>;
  editedBy?: Maybe<Scalars['Int']['output']>;
  editedByUser?: Maybe<User>;
  id: Scalars['Int']['output'];
  specId?: Maybe<Scalars['Int']['output']>;
  specialty: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum StripeAuthorizationApprovalFlow {
  AutoApproval = 'AUTO_APPROVAL',
  SpendApproval = 'SPEND_APPROVAL'
}

export type SubscriberGroup = {
  __typename?: 'SubscriberGroup';
  dependents: Array<CommonMemberGql>;
  subscriberId: Scalars['String']['output'];
  /** Returns the member who is also the subscriber for a subscriber group / family. */
  subscriberMember?: Maybe<CommonMemberGql>;
};

export type Tier = {
  __typename?: 'Tier';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type UpdateCareNavigatorInput = {
  activeForScheduling?: InputMaybe<Scalars['Boolean']['input']>;
  elationId?: InputMaybe<Scalars['Long']['input']>;
  elationTag?: InputMaybe<Scalars['String']['input']>;
  eligibleForAutoAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  handledAppointmentTypes?: InputMaybe<Array<AppointmentType>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<CareTeamMemberLanguages>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  needToUploadPhoto?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCareTeamMemberAvailabilityInput = {
  appointmentTypes?: InputMaybe<Array<AppointmentType>>;
  endTime?: InputMaybe<Scalars['LocalTime']['input']>;
  id: Scalars['Int']['input'];
  isBaselineVisit?: InputMaybe<Scalars['Boolean']['input']>;
  isPrimaryCare?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['LocalTime']['input']>;
};

export type UpdateCareTeamMemberAvailabilityOverrideInput = {
  appointmentTypes: Array<AppointmentType>;
  date: Scalars['Date']['input'];
  elationPhysicianId: Scalars['Long']['input'];
  employerDivisionIds: Array<Scalars['String']['input']>;
  endTime: Scalars['LocalTime']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  isBaselineVisit: Scalars['Boolean']['input'];
  isPrimaryCare: Scalars['Boolean']['input'];
  notes: Scalars['String']['input'];
  startTime: Scalars['LocalTime']['input'];
  status: CareTeamMemberAvailabilityOverrideStatus;
};

export type UpdateCareTeamMemberScheduleInput = {
  startDate: Scalars['Date']['input'];
};

export type UpdateClinicianInput = {
  credentials?: InputMaybe<Scalars['String']['input']>;
  elationId: Scalars['Long']['input'];
  eligibleStates?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  handledAppointmentTypes?: InputMaybe<Array<InputMaybe<AppointmentType>>>;
  hourInterval?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Long']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<InputMaybe<CareTeamMemberLanguages>>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  licensedStates?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  physicianType?: InputMaybe<ElationPhysicianType>;
  providesPrimaryCare?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateFacilityTypeMapInput = {
  curativeCategory: Scalars['String']['input'];
  curativeFacilityType: Scalars['String']['input'];
  facilityService: Scalars['String']['input'];
  fsrId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
};

export type UpdateFacilityTypeMapV2Input = {
  editedBy?: InputMaybe<Scalars['Int']['input']>;
  facilityService: Scalars['String']['input'];
  facilityTypeId: Scalars['Int']['input'];
  fsrId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
};

export type UpdateInvoiceInput = {
  customerEmail: Scalars['String']['input'];
  customerId: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  finalizedAt?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['Int']['input'];
  invoiceLineItems: Array<UpdateInvoiceLineItemInput>;
  patientMemberId: Scalars['String']['input'];
  status: InvoiceStatus;
  stripeInvoiceId?: InputMaybe<Scalars['String']['input']>;
  stripeInvoiceItemId?: InputMaybe<Scalars['String']['input']>;
  subscriberMemberId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateInvoiceLineItemInput = {
  copay: Scalars['Float']['input'];
  currency?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  fdbRoutedDoseFormGenericId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  invoiceId: Scalars['Int']['input'];
  quantity: Scalars['Float']['input'];
  rxNumber: Scalars['String']['input'];
  unit: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateMemberPreferencesRequestInput = {
  preferredContactMethod?: InputMaybe<ContactMethod>;
  preferredLanguage?: InputMaybe<Language>;
  subscribedToNonTransactionalEmails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateOverrideRequestInput = {
  id: Scalars['Int']['input'];
  overrideDataJson: Scalars['String']['input'];
};

export type UpdatePrescriptionDeliveryRequestInput = {
  canceledAt?: InputMaybe<Scalars['DateTime']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  confirmedAt?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryInstructions?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  items: Array<UpdatePrescriptionDeliveryRequestItemInput>;
  patientMemberId: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  requestedDeliveryDate?: InputMaybe<Scalars['Date']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street1?: InputMaybe<Scalars['String']['input']>;
  street2?: InputMaybe<Scalars['String']['input']>;
  subscriberMemberId: Scalars['String']['input'];
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePrescriptionDeliveryRequestItemInput = {
  copay: Scalars['Float']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  libertyRxNpi: Scalars['String']['input'];
  libertyRxScriptNumber: Scalars['String']['input'];
  libertyVerifiedAt?: InputMaybe<Scalars['DateTime']['input']>;
  quantity: Scalars['Float']['input'];
  unit: Scalars['String']['input'];
};

export type UpdateSpecialtyMapInput = {
  curativeProviderType: Scalars['String']['input'];
  curativeSpecialty: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  specId?: InputMaybe<Scalars['Int']['input']>;
  specialty: Scalars['String']['input'];
};

export type UpdateSpecialtyMapV2Input = {
  id: Scalars['Int']['input'];
  providerSpecialtyId: Scalars['Int']['input'];
  specId?: InputMaybe<Scalars['Int']['input']>;
  specialty: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  addressId?: Maybe<Scalars['Int']['output']>;
  canAccessTelehealth: Scalars['Boolean']['output'];
  canViewEmployerPortalManagement: Scalars['Boolean']['output'];
  canViewMembersDetails: Scalars['Boolean']['output'];
  canViewPermissionsDashboard: Scalars['Boolean']['output'];
  canViewProviderDataManagement: Scalars['Boolean']['output'];
  canViewRxInvoiceTool: Scalars['Boolean']['output'];
  canViewSchedulingTools: Scalars['Boolean']['output'];
  contactPreferences?: Maybe<Array<ContactType>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  employers: Array<Employer>;
  employersAdminsOnly: Array<Employer>;
  ethnicity?: Maybe<Ethnicity>;
  genderIdentity?: Maybe<GenderIdentity>;
  hasEmployerPortalSuperuserAccess: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  maritalStatus?: Maybe<MaritalStatus>;
  member?: Maybe<Member>;
  memberId?: Maybe<Scalars['String']['output']>;
  onboardingStatus: OnboardingStatusType;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  preferences?: Maybe<MemberPreferences>;
  preferredName?: Maybe<Scalars['String']['output']>;
  pronouns?: Maybe<Pronouns>;
  race?: Maybe<Race>;
  selectedEmployer?: Maybe<Employer>;
  sexAtBirth?: Maybe<SexAtBirth>;
  subjectId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userType: UserType;
};

export enum UserType {
  Admin = 'ADMIN',
  Employer = 'EMPLOYER',
  Member = 'MEMBER'
}

export type ValidateResponse = {
  __typename?: 'ValidateResponse';
  url?: Maybe<Scalars['String']['output']>;
  validationMessages: Array<ValidationMessage>;
};

export type ValidationMessage = {
  __typename?: 'ValidationMessage';
  level: ValidationMessageLevel;
  message: Scalars['String']['output'];
  rowIndex: Scalars['Int']['output'];
};

export enum ValidationMessageLevel {
  Error = 'ERROR',
  Summary = 'SUMMARY',
  Warning = 'WARNING'
}

export type Visit = {
  __typename?: 'Visit';
  isVirtual: Scalars['Boolean']['output'];
  leadClinician?: Maybe<Clinician>;
  location: VisitLocation;
  member?: Maybe<Member>;
  memberId: Scalars['String']['output'];
  memberState?: Maybe<Scalars['String']['output']>;
  physician?: Maybe<Scalars['Long']['output']>;
  urlIdToken?: Maybe<Scalars['String']['output']>;
  visitEndAt: Scalars['DateTime']['output'];
  visitLanguage?: Maybe<Language>;
  visitStartAt: Scalars['DateTime']['output'];
  visitTypeInfo: VisitTypeInfo;
};

export enum VisitLocation {
  InPerson = 'IN_PERSON',
  OnSite = 'ON_SITE',
  Virtual = 'VIRTUAL'
}

export type VisitMeetingInfoGql = {
  __typename?: 'VisitMeetingInfoGQL';
  appointmentEndDateTime: Scalars['DateTime']['output'];
  appointmentStartDateTime: Scalars['DateTime']['output'];
  careNavigatorFullName?: Maybe<Scalars['String']['output']>;
  careTeamMemberFullName?: Maybe<Scalars['String']['output']>;
  isVirtual: Scalars['Boolean']['output'];
  meetingType: VisitMeetingType;
  memberElationPatientId?: Maybe<Scalars['Long']['output']>;
  memberFullName?: Maybe<Scalars['String']['output']>;
  memberId: Scalars['String']['output'];
  memberPreferredName?: Maybe<Scalars['String']['output']>;
  urls: VisitUrlInfoGql;
};

export enum VisitMeetingType {
  CareTeamMember = 'CARE_TEAM_MEMBER',
  Member = 'MEMBER'
}

export enum VisitType {
  BaselineVisitFreedomMode = 'BASELINE_VISIT_FREEDOM_MODE',
  BaselineVisitFreedomModeClinicianCareNavigator = 'BASELINE_VISIT_FREEDOM_MODE_CLINICIAN_CARE_NAVIGATOR',
  BaselineVisitNewMember = 'BASELINE_VISIT_NEW_MEMBER',
  BaselineVisitNewMemberNonClinical = 'BASELINE_VISIT_NEW_MEMBER_NON_CLINICAL',
  BaselineVisitPreventative = 'BASELINE_VISIT_PREVENTATIVE',
  BaselineVisitPrimaryCare = 'BASELINE_VISIT_PRIMARY_CARE',
  BaselineVisitRenewal = 'BASELINE_VISIT_RENEWAL',
  BaselineVisitRenewalNonClinical = 'BASELINE_VISIT_RENEWAL_NON_CLINICAL',
  LegacyBaselineVisit = 'LEGACY_BASELINE_VISIT',
  Other = 'OTHER',
  PrimaryCare = 'PRIMARY_CARE',
  PrimaryCareFreedomMode = 'PRIMARY_CARE_FREEDOM_MODE'
}

export type VisitTypeInfo = {
  __typename?: 'VisitTypeInfo';
  visitType: VisitType;
  visitTypeId: Scalars['Int']['output'];
};

export type VisitTypeInfoInput = {
  visitType: VisitType;
  visitTypeId: Scalars['Int']['input'];
};

export type VisitUrlInfoGql = {
  __typename?: 'VisitUrlInfoGQL';
  joinUrl?: Maybe<Scalars['String']['output']>;
  memberUrl?: Maybe<Scalars['String']['output']>;
  startUrl?: Maybe<Scalars['String']['output']>;
};
