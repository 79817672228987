import { compact } from "lodash-es";

import { LocaleCode, LocaleCodesInitial } from "../../features";
import { getLocaleCodeFromCookie } from "./getLocaleCodeFromCookie";
import { getLocaleCodesFromBrowser } from "./getLocaleCodesFromBrowser";

export const getLocaleCodesFromHeaders = ({
  acceptLanguage,
  cookie,
}: {
  acceptLanguage?: string | undefined;
  cookie?: string | undefined;
}): LocaleCodesInitial => {
  const localeCodeFromCookie: LocaleCode | null = cookie
    ? getLocaleCodeFromCookie({ cookie })
    : null;
  const localeCodesFromAcceptLanguage: LocaleCode[] = acceptLanguage
    ? getLocaleCodesFromBrowser({
        languages: acceptLanguage.split(","),
      })
    : [];

  return compact([localeCodeFromCookie, ...localeCodesFromAcceptLanguage]);
};
