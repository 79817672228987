import { datadogRum, RumInitConfiguration } from "@datadog/browser-rum";

export const initializeDatadogRum = (
  rumConfig: RumInitConfiguration & { env: string }
) => {
  const { env } = rumConfig;

  const isProd = env === "prod";
  const isPreProd = env === "preprod";

  if (!isProd && !isPreProd) {
    console.log("Not initializing Datadog RUM in non-deployed environment.");
    return;
  }

  const baseConfig = {
    site: "datadoghq.com",
    sampleRate: isProd ? 50 : 100,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
  };

  const config = {
    ...baseConfig,
    ...rumConfig,
  };

  datadogRum.init(config);
  datadogRum.startSessionReplayRecording();
};
