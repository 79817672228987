import {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  createHttpLink,
  HttpOptions,
  InMemoryCacheConfig,
  NormalizedCacheObject,
} from "@apollo/client";
import { DefaultOptions } from "@apollo/client";

import { createInMemoryCache } from "./cache";
import {
  debounceLink,
  errorLink,
  getAppendHeadersLink,
  retryLink,
} from "./links";

export type ApolloClientOptionsOverrides = Partial<
  Omit<ApolloClientOptions<NormalizedCacheObject>, "link">
>;

export type ApolloClientFactoryOptions = {
  clientOptions?: ApolloClientOptionsOverrides;
  customCacheConfig?: InMemoryCacheConfig;
  customLinks?: ApolloLink[];
  httpLinkOptions?: HttpOptions;
  appName: string;
  appendAuthHeader?: boolean;
};

const getDefaultHttpLinkUri = (): string | undefined =>
  process.env.NEXT_PUBLIC_API_GRAPHQL_URI;

export const createApolloClient = ({
  clientOptions,
  customCacheConfig,
  customLinks = [],
  httpLinkOptions,
  appName,
  appendAuthHeader = true,
}: ApolloClientFactoryOptions): ApolloClient<NormalizedCacheObject> => {
  const httpLink = createHttpLink({
    ...httpLinkOptions,
    uri: httpLinkOptions?.uri ?? getDefaultHttpLinkUri(),
  });

  return new ApolloClient({
    defaultOptions: ((): DefaultOptions | undefined => {
      // NOTE: The ApolloClient is initialized on both the server and browser but we only
      // care about the browser for the purposes of disabling the cache during cypress tests
      if (typeof window !== "undefined" && "Cypress" in window) {
        return {
          query: {
            fetchPolicy: "no-cache",
          },
          watchQuery: {
            fetchPolicy: "no-cache",
          },
        };
      }
      return undefined;
    })(),
    cache: createInMemoryCache(customCacheConfig),
    ...clientOptions,
    link: ApolloLink.from([
      ...customLinks,
      retryLink,
      ...(appendAuthHeader ? [getAppendHeadersLink(appName)] : []),
      debounceLink,
      errorLink,
      httpLink,
    ]),
  });
};
