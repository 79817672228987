/* eslint-disable @typescript-eslint/no-explicit-any */

import { Button, ButtonVariant } from "@chp/curative_ui";
import { FunctionComponent } from "react";

import { DownloadCSVButton } from "./DownloadCSVButton";
import SmartTableFiltersHeader from "./SmartTableFiltersHeader";
import { SmartTableFilter } from "./types";

interface SmartTableFiltersProps {
  filters?: Array<SmartTableFilter>;
  activeFilters: Record<string, any>;
  newButtonLabel?: string;
  allFiltersButtonLabel?: string;
  setFilters: (value: Record<string, any>) => void;
  showClickDownloadCSV?: boolean;
  canDownloadCSV?: boolean;
  isDownloadingCSV?: boolean;
  onClickDownloadCSV(): void;
  onClickNew?(): void;
}

const SmartTableFilters: FunctionComponent<SmartTableFiltersProps> = (
  props
) => {
  //                                                                  //
  // First we're going to set up some state around search, filters,   //
  // ordering, and pagination along with respective helper methods.   //
  //                                                                  //

  const addFilters = (futureFilters: Record<string, any>) => {
    // if we don't set it to a new object, react doesn't know that anything
    // has changed.
    props.setFilters({ ...props.activeFilters, ...futureFilters });
  };
  const removeFilters = (formerFilters: Record<string, any>) => {
    const updatedFilters = Object.assign({}, props.activeFilters);
    Object.keys(formerFilters).forEach((filterKey: string) => {
      delete updatedFilters[filterKey];
    });
    props.setFilters(updatedFilters);
  };

  return (
    <div className="flex w-full">
      {props.filters && props.filters.length > 0 && (
        <SmartTableFiltersHeader
          addFilters={addFilters}
          removeFilters={removeFilters}
          activeFilters={props.activeFilters}
          filters={props.filters}
          allFiltersButtonLabel={props.allFiltersButtonLabel}
        />
      )}
      <div className="ml-auto mt-8 flex flex-row flex-wrap justify-between">
        <div>
          {props.onClickDownloadCSV && props.showClickDownloadCSV && (
            <DownloadCSVButton
              isDisabled={props.isDownloadingCSV}
              canDownloadCsv={props.canDownloadCSV}
              onConfirm={() => props.onClickDownloadCSV()}
            />
          )}
          {props.onClickNew && props.newButtonLabel && (
            <span className="ml-2">
              <Button
                variant={ButtonVariant.SmallOutline}
                onClick={props.onClickNew}
              >
                {props.newButtonLabel}
              </Button>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default SmartTableFilters;
