import clsx from "clsx";

import { SpacingVariant } from "../../constants";
import { Box, BoxProps } from "../Box";
import { isMessageEmpty } from "../text";
import { TextVariant } from "../types";
import { TableProps } from "./Table";
import { TableKey, textAlignForContentType } from "./utils";

const hasEmptyHeader = (params: { key: TableKey }): boolean => {
  const { key } = params;
  // check for empty header a11y issue
  // see: https://dequeuniversity.com/rules/axe/4.4/empty-table-header?application=axeAPI
  return isMessageEmpty({ message: key });
};

const headCellElementAndScope = (params: {
  key: TableKey;
}):
  | Pick<BoxProps<"th">, "element" | "scope">
  | Pick<BoxProps<"td">, "element"> => {
  const { key } = params;
  // use td element if header is empty, otherwise use th element
  return hasEmptyHeader({ key })
    ? {
        element: "td",
      }
    : {
        element: "th",
        scope: "col",
      };
};

export const TableHead = (
  props: Pick<
    TableProps,
    "columnClassNames" | "contentTypes" | "keys" | "testId"
  > & {
    headCellArrayKeys: Array<string | number>;
  }
): JSX.Element | null => {
  const {
    columnClassNames,
    contentTypes,
    headCellArrayKeys,
    keys,
    testId: tableTestId,
  } = props;
  return (
    <Box className="bg-surface-neutral" element="thead">
      <Box element="tr">
        {keys.map((key, index) => (
          <Box
            {...headCellElementAndScope({ key })}
            className={clsx(
              "hidden",
              "md:table-cell",
              "text-textColor-subdued",
              columnClassNames?.[index]
            )}
            key={headCellArrayKeys[index]}
            padding={{
              bottom: SpacingVariant.S12,
              left: SpacingVariant.S16,
              right: SpacingVariant.S16,
              top: SpacingVariant.S12,
            }}
            testId={`${tableTestId}__HeadCell`}
            textAlign={textAlignForContentType({
              contentType: contentTypes[index],
            })}
            textVariant={TextVariant.SmRegularTall}
          >
            {key}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
