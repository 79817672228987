import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";

import {
  FormattedDateInput,
  FormattedDateInputProps,
} from "../FormattedDateInput";

export type FormDateInputProps<T extends FieldValues> = {
  name: Path<T>;
  id?: string;
  className?: string;
  dialogTitle: string;
  openCalendarButtonAriaLabel: string;
  onValueChanged?: (newValue: string | undefined) => void;
} & Pick<
  FormattedDateInputProps,
  | "label"
  | "description"
  | "isLabelSrOnly"
  | "fromDate"
  | "toDate"
  | "isDisabled"
  | "testId"
>;

export function FormDateInput<T extends FieldValues>({
  name,
  id,
  label,
  description,
  fromDate,
  toDate,
  className,
  isLabelSrOnly,
  openCalendarButtonAriaLabel,
  dialogTitle,
  onValueChanged,
  isDisabled,
  testId,
}: FormDateInputProps<T>) {
  const { control } = useFormContext<T>();

  return (
    <Controller<T, typeof name>
      control={control}
      name={name}
      render={({
        field: { value, ref, onBlur, onChange },
        fieldState: { error },
      }) => (
        <FormattedDateInput
          id={id || name}
          label={label}
          description={description}
          isLabelSrOnly={isLabelSrOnly}
          isDisabled={isDisabled}
          className={className}
          name={name}
          value={(value as string) || ""}
          innerRef={ref}
          errorMessage={error?.message}
          testId={testId}
          onChange={onChange}
          onRequestChangeValue={(newValue: string | undefined) => {
            onChange({
              target: {
                value: newValue,
              },
            });
            onValueChanged?.(newValue);
          }}
          onBlur={onBlur}
          fromDate={fromDate}
          toDate={toDate}
          displayMaskPlaceholder
          openCalendarButtonAriaLabel={openCalendarButtonAriaLabel}
          dialogTitle={dialogTitle}
        />
      )}
    />
  );
}
