import { RefObject, useEffect } from "react";

export const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  onClickOutside: (e: MouseEvent) => void
) => {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        e.target &&
        e.target instanceof Node &&
        !ref.current?.contains(e.target)
      ) {
        onClickOutside(e);
      }
    };

    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [onClickOutside, ref]);
};
