import * as Types from '@chp/shared/generated/memberPortalApi.graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActiveUser__GetMemberPlanYearsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ActiveUser__GetMemberPlanYearsQuery = { __typename?: 'Query', activeUser: { __typename?: 'User', id: number, member?: { __typename?: 'Member', externalId: string, id: string, planYears: Array<number> } | null } };


export const ActiveUser__GetMemberPlanYearsDocument = gql`
    query ActiveUser__GetMemberPlanYears {
  activeUser {
    id
    member {
      externalId
      id
      planYears
    }
  }
}
    `;

/**
 * __useActiveUser__GetMemberPlanYearsQuery__
 *
 * To run a query within a React component, call `useActiveUser__GetMemberPlanYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveUser__GetMemberPlanYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveUser__GetMemberPlanYearsQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveUser__GetMemberPlanYearsQuery(baseOptions?: Apollo.QueryHookOptions<ActiveUser__GetMemberPlanYearsQuery, ActiveUser__GetMemberPlanYearsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveUser__GetMemberPlanYearsQuery, ActiveUser__GetMemberPlanYearsQueryVariables>(ActiveUser__GetMemberPlanYearsDocument, options);
      }
export function useActiveUser__GetMemberPlanYearsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveUser__GetMemberPlanYearsQuery, ActiveUser__GetMemberPlanYearsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveUser__GetMemberPlanYearsQuery, ActiveUser__GetMemberPlanYearsQueryVariables>(ActiveUser__GetMemberPlanYearsDocument, options);
        }
export function useActiveUser__GetMemberPlanYearsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ActiveUser__GetMemberPlanYearsQuery, ActiveUser__GetMemberPlanYearsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ActiveUser__GetMemberPlanYearsQuery, ActiveUser__GetMemberPlanYearsQueryVariables>(ActiveUser__GetMemberPlanYearsDocument, options);
        }
export type ActiveUser__GetMemberPlanYearsQueryHookResult = ReturnType<typeof useActiveUser__GetMemberPlanYearsQuery>;
export type ActiveUser__GetMemberPlanYearsLazyQueryHookResult = ReturnType<typeof useActiveUser__GetMemberPlanYearsLazyQuery>;
export type ActiveUser__GetMemberPlanYearsSuspenseQueryHookResult = ReturnType<typeof useActiveUser__GetMemberPlanYearsSuspenseQuery>;
export type ActiveUser__GetMemberPlanYearsQueryResult = Apollo.QueryResult<ActiveUser__GetMemberPlanYearsQuery, ActiveUser__GetMemberPlanYearsQueryVariables>;