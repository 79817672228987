import clsx from "clsx";

import { SpacingVariant } from "../../constants";
import { Box, TooltipInfoIcon } from "..";
import { bgColorsCommon, textColorsCommon } from "../colors";
import {
  CUIComponent,
  CUIComponentProps,
  DisplayVariant,
  HeadingLevel,
  TextVariant,
  VerticalAlignVariant,
} from "../types";

const headerPaddingSize = SpacingVariant.S16;
const displayVariant: DisplayVariant = DisplayVariant.InlineBlock;
const verticalAlignVariant: VerticalAlignVariant = VerticalAlignVariant.Middle;

const DEFAULT_TOOLTIP_WIDTH = 232;

export type HeaderSecondaryProps = CUIComponentProps<{
  heading: JSX.Element | string;
  headingLevel: HeadingLevel;
  toolTipMessage?: string;
  tooltipMaxWidth?: number;
}>;

export const HeaderSecondary: CUIComponent<HeaderSecondaryProps> = ({
  className,
  heading,
  headingLevel,
  toolTipMessage,
  tooltipMaxWidth = DEFAULT_TOOLTIP_WIDTH,
  testId = "HeaderSecondary",
}) => {
  return (
    <Box
      className={clsx(
        bgColorsCommon.surfaceNeutral,
        textColorsCommon.subdued,
        className
      )}
      element="header"
      padding={{
        bottom: headerPaddingSize,
        left: headerPaddingSize,
        right: headerPaddingSize,
        top: headerPaddingSize,
      }}
      testId={testId}
      textVariant={TextVariant.SmBoldTall}
    >
      <Box
        element={headingLevel}
        display={displayVariant}
        margin={{ right: toolTipMessage ? SpacingVariant.S4 : undefined }}
        verticalAlign={verticalAlignVariant}
        testId={`${testId}__Heading`}
      >
        {heading}
      </Box>
      {toolTipMessage && (
        <TooltipInfoIcon
          message={toolTipMessage}
          testId={`${testId}__TooltipInfoIcon`}
          maxWidth={tooltipMaxWidth}
        />
      )}
    </Box>
  );
};
