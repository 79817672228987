import { Disclosure, Transition } from "@headlessui/react";
import clsx from "clsx";

import { SpacingVariant } from "../../constants";
import { useMediaBreakpoints } from "../../hooks";
import { PaddingSize } from "../../types";
import { buttonBaseClassNames, buttonPaddingSmall } from "../../utils";
import { Box } from "../Box";
import { bgColorsCommon, textColorsCommon } from "../colors";
import { DISPLAY_CLASS_NAMES } from "../constants";
import { Icon, IconVariant } from "../Icon";
import {
  CUIComponent,
  CUIComponentProps,
  DisplayVariant,
  TextVariant,
  VerticalAlignVariant,
} from "../types";

export type DisclosureCardContent = string | JSX.Element;

export type DisclosureCardProps = CUIComponentProps<{
  buttonText: { closed: string; open: string };
  largeScreenContent: {
    leadingContent: DisclosureCardContent;
    trailingContent: DisclosureCardContent;
  };
  onButtonClick?: () => void;
  smallScreenContent: {
    leadingContent: DisclosureCardContent;
    trailingContent: DisclosureCardContent;
  };
}>;

const contentPadding: PaddingSize = {
  bottom: SpacingVariant.S12,
  left: SpacingVariant.S16,
  right: SpacingVariant.S16,
  top: SpacingVariant.S12,
};

const DisclosureCardLargeScreen = ({
  largeScreenContent: { leadingContent, trailingContent },
  testId,
}: Pick<DisclosureCardProps, "largeScreenContent" | "testId">): JSX.Element => {
  return (
    <Box
      className={clsx("grid", "grid-cols-12")}
      element="article"
      testId={testId}
    >
      <Box
        className={clsx(
          bgColorsCommon.surfaceNeutral,
          "border-b",
          "border-l",
          "border-t",
          "col-span-3",
          "rounded-l-lg"
        )}
        element="section"
        padding={contentPadding}
        testId={`${testId}__LeadingContent`}
      >
        {leadingContent}
      </Box>
      <Box
        className={clsx(
          "bg-surface-default",
          "border-b",
          "border-r",
          "border-t",
          "col-span-9",
          "rounded-r-lg"
        )}
        element="section"
        padding={contentPadding}
        testId={`${testId}__TrailingContent`}
      >
        {trailingContent}
      </Box>
    </Box>
  );
};

const DisclosureCardSmallScreen = ({
  buttonText,
  smallScreenContent: { leadingContent, trailingContent },
  testId,
  onButtonClick,
}: Pick<
  DisclosureCardProps,
  "buttonText" | "smallScreenContent" | "testId" | "onButtonClick"
>): JSX.Element => {
  return (
    <Box element="article" testId={testId}>
      <Box
        className={clsx(
          bgColorsCommon.surfaceNeutral,
          "border-l",
          "border-r",
          "border-t",
          "rounded-t"
        )}
        element="section"
        padding={contentPadding}
        testId={`${testId}__LeadingContent`}
      >
        {leadingContent}
      </Box>
      <section>
        <Disclosure>
          {({ open: isOpen }) => (
            <>
              <Transition
                enter={clsx("duration-100", "ease-out", "transition")}
                enterFrom={clsx("opacity-0", "scale-95", "")}
                enterTo={clsx("opacity-100", "scale-100", "")}
                leave={clsx("duration-75", "ease-out", "transition")}
                leaveFrom={clsx("opacity-100", "scale-100", "")}
                leaveTo={clsx("opacity-0", "scale-95", "")}
              >
                <Disclosure.Panel>
                  <Box
                    className={clsx(
                      "bg-surface-default",
                      "border-l",
                      "border-r",
                      "border-t"
                    )}
                    element="div"
                    padding={contentPadding}
                    testId={`${testId}__TrailingContent`}
                  >
                    {trailingContent}
                  </Box>
                </Disclosure.Panel>
              </Transition>
              <Disclosure.Button
                className={clsx(
                  "relative",
                  "focus:z-40",
                  "focus-visible:z-40",
                  buttonBaseClassNames,
                  DISPLAY_CLASS_NAMES[DisplayVariant.InlineBlock],
                  textColorsCommon.default,
                  "bg-surface-default",
                  "border",
                  "rounded-b",
                  "w-full"
                )}
                data-testid={`${testId}__Button`}
                onClick={() => {
                  onButtonClick?.();
                }}
              >
                <Box
                  display={DisplayVariant.InlineBlock}
                  element="span"
                  padding={buttonPaddingSmall}
                  textVariant={TextVariant.SmRegularTall}
                >
                  <Box
                    display={DisplayVariant.InlineBlock}
                    element="span"
                    verticalAlign={VerticalAlignVariant.Middle}
                  >
                    {isOpen ? buttonText.open : buttonText.closed}
                  </Box>
                  <Box
                    display={DisplayVariant.InlineBlock}
                    element="span"
                    margin={{ left: SpacingVariant.S8 }}
                    verticalAlign={VerticalAlignVariant.Middle}
                  >
                    <Icon
                      size={SpacingVariant.S20}
                      variant={
                        isOpen
                          ? IconVariant.CHEVRON_UP
                          : IconVariant.CHEVRON_DOWN
                      }
                    />
                  </Box>
                </Box>
              </Disclosure.Button>
            </>
          )}
        </Disclosure>
      </section>
    </Box>
  );
};

/**
 * Disclosure Card. See: https://headlessui.com/react/disclosure
 */
export const DisclosureCard: CUIComponent<DisclosureCardProps> = ({
  buttonText,
  onButtonClick,
  className,
  largeScreenContent,
  smallScreenContent,
  testId = "DisclosureCard",
}): JSX.Element => {
  const { isMinWidthMD } = useMediaBreakpoints();
  return (
    <Box className={className} element="div" testId={testId}>
      {isMinWidthMD ? (
        <DisclosureCardLargeScreen
          largeScreenContent={largeScreenContent}
          testId={`${testId}__LargeScreen`}
        />
      ) : (
        <DisclosureCardSmallScreen
          buttonText={buttonText}
          smallScreenContent={smallScreenContent}
          onButtonClick={onButtonClick}
          testId={`${testId}__SmallScreen`}
        />
      )}
    </Box>
  );
};
