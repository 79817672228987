import { format, parse } from "date-fns";

import { PlainDate } from "./PlainDate";

export const DATE_INPUT_FORMAT = "MM/dd/yyyy";

// Example: Aug. 18
export const DATE_SELECTION_FORMAT = "MMM. d";

export const ISO_DATE_FORMAT = "yyyy-MM-dd";

/** This is how the input mask would represent the value in the textbox when nothing is entered */
export const DATE_INPUT_EMPTY_MASK_VALUE = "__/__/____";

/** This is what we want to actually display in the textbox when nothing is entered, ie. when value == DEFAULT_EMPTY_VALUE  */
export const DATE_INPUT_EMPTY_PLACEHOLDER_VALUE = "MM/DD/YYYY";

export const formatJsDate = (
  date?: Date | null,
  dateFormat: string = "MM-dd-yyyy"
): string => {
  if (!date) {
    return "";
  }

  return format(date, dateFormat);
};

export const formatIsoDateString = (
  isoDateString?: string | null,
  dateFormat: string = "MM-dd-yyyy"
): string => {
  if (!isoDateString) {
    return "";
  }

  const isoPlainDate = PlainDate.fromIsoString(isoDateString);

  return format(isoPlainDate.asJsDate(), dateFormat);
};

export const parseDateInput = (dateString: string | Date): Date => {
  const dateToParse =
    dateString instanceof Date
      ? format(dateString, DATE_INPUT_FORMAT)
      : dateString;

  return parse(dateToParse, DATE_INPUT_FORMAT, new Date());
};
