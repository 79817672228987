import { Checkbox, CheckboxProps } from "@chp/curative_ui";
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";

export type FormCheckboxProps<T extends FieldValues> = {
  name: Path<T>;
  id?: string;
  label: string;
  className?: string;
  value: string;
  stringToggleOptions?: { checked: string; unchecked: string | null };
  onRequestToggle?: () => void;
  testId?: string;
} & Partial<
  Pick<
    CheckboxProps,
    "isChecked" | "labelTitle" | "errorMessage" | "isDisabled" | "variant"
  >
>;

export function FormCheckbox<T extends FieldValues>({
  name,
  id,
  label,
  className,
  isDisabled,
  value: cbValue,
  stringToggleOptions,
  onRequestToggle,
  testId,
}: FormCheckboxProps<T>) {
  const { control } = useFormContext<T>();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onBlur, onChange },
        fieldState: { error },
      }) => (
        <Checkbox
          id={id || name}
          name={name}
          label={label}
          value={cbValue}
          isDisabled={isDisabled}
          className={className}
          testId={testId}
          isChecked={
            stringToggleOptions
              ? value === stringToggleOptions.checked
              : (value as boolean)
          }
          errorMessage={error?.message}
          onBlur={onBlur}
          onRequestToggle={() => {
            if (stringToggleOptions) {
              const { checked, unchecked } = stringToggleOptions;
              onChange({
                target: {
                  value: value === checked ? unchecked : checked,
                },
              });
            } else {
              onChange({
                target: {
                  value: !value,
                },
              });
            }

            onRequestToggle?.();
          }}
        />
      )}
    />
  );
}
