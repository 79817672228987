import clsx from "clsx";
import { PropsWithChildren } from "react";

import { SpacingVariant } from "../../constants";
import { MODAL_VARIANT_MAX_WIDTHS, TEXT_CLASS_NAMES } from "../constants";
import { Icon, IconVariant } from "../Icon";
import { ScreenReaderOnly } from "../ScreenReaderOnly";
import {
  CUIComponent,
  CUIComponentProps,
  ModalMaxWidth,
  TextVariant,
} from "../types";

export type PopupProps = CUIComponentProps<
  PropsWithChildren<{
    closeButtonText?: string;
    icon?: IconVariant;
    id?: string;
    isOpen: boolean;
    maxWidth?: ModalMaxWidth;
    message?: string | JSX.Element;
    onRequestClose?: () => void;
    title: string;
  }>
>;

export const Popup: CUIComponent<PopupProps> = ({
  className,
  closeButtonText,
  icon,
  id,
  isOpen,
  maxWidth = ModalMaxWidth.Small,
  message,
  onRequestClose,
  testId = "Popup",
  title,
}) => {
  if (!isOpen) {
    return <></>;
  }
  return (
    <div
      id={id}
      data-testid={testId}
      className={clsx(
        MODAL_VARIANT_MAX_WIDTHS[maxWidth],
        "bg-surface-default",
        "rounded",
        "p-6",
        "mx-auto",
        "pointer-events-auto",
        className
      )}
    >
      <div className="min-h-full items-center justify-center">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center">
            {icon && (
              <Icon
                size={SpacingVariant.S24}
                variant={icon}
                className="mr-2 text-foundation-accentB"
              />
            )}
            <h3
              data-testid={`${testId}__Title`}
              className={clsx(
                TEXT_CLASS_NAMES[TextVariant.LgBoldShort],
                "text-foundation-accentB"
              )}
            >
              {title}
            </h3>
          </div>
          {closeButtonText && (
            <button
              data-testid={`${testId}__CloseButton`}
              onClick={onRequestClose}
            >
              <Icon
                size={SpacingVariant.S24}
                variant={IconVariant.X}
                className="text-textColor-subdued"
              />
              <ScreenReaderOnly element="span">
                {closeButtonText}
              </ScreenReaderOnly>
            </button>
          )}
        </div>
        {message && (
          <p
            data-testid={`${testId}__Message`}
            className={clsx(
              "my-4",
              "text-pretty",
              TEXT_CLASS_NAMES[TextVariant.MdRegularTall]
            )}
          >
            {message}
          </p>
        )}
      </div>
    </div>
  );
};
