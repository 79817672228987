// See https://github.com/microsoft/TypeScript/pull/32695 for an explanation
// of the asserts modifier on functions, used here.
/**
 * Throw an error if `obj` is null. Otherwise, TypeScript will understand that
 * `obj` is not null in the calling scope.
 *
 * Serves as an alternative to using ! for non-null assertions, which our
 * eslint config forbids, but is stricter, in that if you are lying to the
 * compiler, your code will crash.
 */
export function assertNonNull<T>(
  obj: T | null | undefined,
  error?: string
): asserts obj is T {
  if (obj == null) {
    if (error) throw new Error(error);
    throw new Error("Non-null assertion violated");
  }
}

/**
 * Throw an error if `obj` is null. Otherwise, return it.
 *
 * Serves as an alternative to using ! for non-null assertions, which our
 * eslint config forbids, but is stricter, in that if you are lying to the
 * compiler, your code will crash.
 */
export function nonNull<T>(obj: T | null | undefined, error?: string): T {
  assertNonNull(obj, error);
  return obj;
}

// From https://stackoverflow.com/a/39419171/1709587
// Used as part of exhaustiveness checking as described in that answer.
export function assertUnreachable(_x: never): never {
  throw new Error("Didn't expect to get here");
}
