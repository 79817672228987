module.exports = {
  xs: ["12px", "14px"],
  sm: ["14px", "16px"],
  "sm-tall": ["14px", "20px"],
  md: ["16px", "18px"],
  "md-tall": ["16px", "24px"],
  "lg-short": ["18px", "20px"],
  lg: ["18px", "24px"],
  "lg-tall": ["18px", "30px"],
  xl: ["20px", "24px"],
  "xl-tall": ["20px", "28px"],
  "2xl": ["24px", "30px"],
  "3xl": ["32px", "36px"],
  "4xl": ["40px", "42px"],
};
