import clsx from "clsx";

interface TableHeaderProps {
  children?: React.ReactNode;
  className?: string;
}

const TableHeader = (props: TableHeaderProps) => (
  <div
    className={clsx(
      "flex w-full border-b border-gray-300 text-lg text-gray-600",
      props.className
    )}
  >
    {props.children}
  </div>
);

export default TableHeader;
