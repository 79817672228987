import {
  Box,
  SpacingVariant,
  TextAlignVariant,
  TextVariant,
} from "@chp/curative_ui";
import * as Sentry from "@sentry/react";
import { PropsWithChildren, useContext, useMemo } from "react";
import { createIntl, IntlContext, IntlShape } from "react-intl";

import { FCWithChildren } from "../types";
import { AnimationType } from "./Animation";
import {
  MemberServicesEmailLink,
  MemberServicesPhoneNumberLink,
} from "./links";
import {
  StatusMessageWithLogo,
  StatusMessageWithLogoSize,
} from "./StatusMessageWithLogo";

export type SupportInfo =
  | {
      email: string | JSX.Element;
      phoneNumber: string | JSX.Element | null;
    }
  | {
      email: string | JSX.Element | null;
      phoneNumber: string | JSX.Element;
    };

export type ErrorBoundaryProps = PropsWithChildren<{
  supportInfo?: SupportInfo;
}>;

type FallbackRenderProps = Pick<ErrorBoundaryProps, "supportInfo"> & {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError(): void;
  intl: IntlShape;
};

const Fallback = ({ eventId, intl, supportInfo }: FallbackRenderProps) => {
  const { formatMessage } = intl;

  const { email, phoneNumber } = supportInfo ?? {
    email: <MemberServicesEmailLink />,
    phoneNumber: <MemberServicesPhoneNumberLink />,
  };
  const hasBothEmailAndPhone: boolean = email !== null && phoneNumber !== null;

  return (
    <StatusMessageWithLogo
      size={StatusMessageWithLogoSize.MD}
      animationType={AnimationType.ERROR}
      title={formatMessage({
        defaultMessage: "Oh no... something went wrong",
        id: "1w9AwM",
      })}
    >
      <Box
        element="p"
        margin={{ top: SpacingVariant.S24, bottom: SpacingVariant.S8 }}
        textVariant={TextVariant.LgRegular}
        textAlign={TextAlignVariant.CENTER}
      >
        {formatMessage({
          defaultMessage: "Please refresh your browser and try again.",
          id: "pqN1M0",
        })}{" "}
        {hasBothEmailAndPhone
          ? formatMessage(
              {
                defaultMessage:
                  "If the issue persists, please contact support at {phoneNumber} or {email}.",
                id: "xfSx06",
              },
              {
                phoneNumber,
                email,
              }
            )
          : formatMessage(
              {
                defaultMessage:
                  "If the issue persists, please contact support at {contactInfo}.",
                id: "sCXzOz",
              },
              {
                contactInfo: phoneNumber ?? email,
              }
            )}{" "}
        {formatMessage(
          {
            defaultMessage: "Reference ID: {eventId}",
            id: "/kwtXA",
          },
          {
            eventId,
          }
        )}
      </Box>
    </StatusMessageWithLogo>
  );
};

export const ErrorBoundary: FCWithChildren<ErrorBoundaryProps> = ({
  children,
  supportInfo,
}) => {
  const intl = useContext(IntlContext);

  const defaultIntl = useMemo(() => {
    return createIntl({
      messages: {},
      locale: "en",
    });
  }, []);

  return (
    <Sentry.ErrorBoundary
      fallback={(props) => (
        <Fallback
          intl={intl ?? defaultIntl}
          supportInfo={supportInfo}
          {...props}
        />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
