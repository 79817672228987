import { PlainDate } from "@chp/shared/utils/dateTime";
import { FormattedDate } from "react-intl";

export enum FormattedPlainDateVariant {
  // pin date format to m/d/y. no intl support - display same format for all languages.
  NUMERIC = "NUMERIC",
  // use format like "Aug DD, YYYY". with intl support.
  TEXT = "TEXT",
}

export type FormattedPlainDateProps = {
  value: PlainDate;
  variant?: FormattedPlainDateVariant;
};

const variantFormatters: Record<
  FormattedPlainDateVariant,
  (value: PlainDate) => JSX.Element
> = {
  [FormattedPlainDateVariant.NUMERIC]: (value: PlainDate) => (
    // pin date format to m/d/y. never show as d/m/y. no intl support.
    <>{value.toMMDDYYYYFormat()}</>
  ),
  [FormattedPlainDateVariant.TEXT]: (value: PlainDate) => (
    // use intl for text type. format like "Aug DD, YYYY".
    <FormattedDate
      value={value.asJsDate()}
      day="2-digit"
      month="short"
      year="numeric"
    />
  ),
};

/**
 * Return formatted PlainDate as either i18n text pattern like "Aug DD, YYYY", or non-i18n numeric pattern like
 * "12/31/2022". Similar to FormattedDate but for use with PlainDate instead of JavaScript Date.
 *
 * @remarks
 *
 * FormattedDate does not work well with plain date strings like "2022-12-31" & will potentially return value like
 * "12/30/2022" if user system time zone is earlier than UTC, because the string gets converted to JavaScript Date with
 * default UTC time zone.
 *
 * FormattedPlainDate will default to user system time zone instead, so PlainDate like {year: 2022, month: 12, day: 31}
 * will display like "12/31/2022" (for English-US).
 */
export const FormattedPlainDate = ({
  value,
  variant = FormattedPlainDateVariant.TEXT,
}: FormattedPlainDateProps): JSX.Element => {
  return variantFormatters[variant](value);
};
