import { HeadingLevel } from "./types";

/**
 * For a given HeadingLevel, get next HeadingLevel down for sub-heading.
 */
export const headingLevelNext: Record<HeadingLevel, HeadingLevel> = {
  h1: "h2",
  h2: "h3",
  h3: "h4",
  h4: "h5",
  h5: "h6",
  h6: "h6",
};
