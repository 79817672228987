import { Disclosure } from "@headlessui/react";
import clsx from "clsx";
import { PropsWithChildren } from "react";
import { useMediaPredicate } from "react-media-hook";

import { SpacingVariant } from "../../constants";
import { desktopBreakpoint } from "../../mediaBreakpoints";
import { Box } from "../Box";
import { Icon, IconVariant } from "../Icon";
import { CUIComponent, CUIComponentProps, TextVariant } from "../types";

export type BannerProps = CUIComponentProps<
  PropsWithChildren<{
    title: string;
    shouldStartOpen?: boolean;
    className?: string;
    textVariant?: TextVariant;
  }>
>;

export const Banner: CUIComponent<BannerProps> = ({
  title,
  shouldStartOpen = true,
  className,
  testId = "Banner",
  textVariant = TextVariant.SmRegularTall,
  children,
}) => {
  const desktop = useMediaPredicate(`(min-width: ${desktopBreakpoint}px)`);
  const bannerClassNames = [
    "flex",
    "border-t-4",
    "border-t-borderColor-control",
    "bg-surface-highlight",
  ];

  const colorClassName = "text-textColor-highlight-default";

  return desktop ? (
    <div
      data-testid={testId}
      className={clsx(
        ...bannerClassNames,
        "justify-center",
        "p-4",
        "pt-3",
        className
      )}
    >
      <Disclosure>
        <Box element="div" textVariant={textVariant} className={colorClassName}>
          {children}
        </Box>
      </Disclosure>
    </div>
  ) : (
    <div
      data-testid={testId}
      className={clsx(
        ...bannerClassNames,
        "flex-col",
        "py-2",
        "px-4",
        className
      )}
    >
      <Disclosure defaultOpen={shouldStartOpen}>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={clsx("flex", "w-full", "justify-between")}
              data-testid={`${testId}__Toggle`}
            >
              <div className={clsx("flex")}>
                <Icon
                  variant={IconVariant.EXCLAMATION_CIRCLE}
                  size={SpacingVariant.S16}
                  className={colorClassName}
                />
                <Box
                  element="p"
                  textVariant={TextVariant.SmBold}
                  margin={{ left: SpacingVariant.S8 }}
                  className={colorClassName}
                >
                  {title}
                </Box>
              </div>
              <Icon
                variant={
                  open ? IconVariant.CHEVRON_UP : IconVariant.CHEVRON_DOWN
                }
                size={SpacingVariant.S16}
                className={colorClassName}
              />
            </Disclosure.Button>
            {open && (
              <Disclosure.Panel className={clsx("flex", "w-full", "mt-2")}>
                <Box
                  element="div"
                  margin={{ left: SpacingVariant.S24 }}
                  padding={{ right: SpacingVariant.S16 }}
                  textVariant={textVariant}
                  className={colorClassName}
                >
                  {children}
                </Box>
              </Disclosure.Panel>
            )}
          </>
        )}
      </Disclosure>
    </div>
  );
};
