import * as Types from '@chp/shared/generated/memberPortalApi.graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TypeaheadOptions__SearchPharmaciesQueryVariables = Types.Exact<{
  zipCode: Types.Scalars['String']['input'];
  query: Types.Scalars['String']['input'];
}>;


export type TypeaheadOptions__SearchPharmaciesQuery = { __typename?: 'Query', searchPharmacies: Array<{ __typename?: 'PharmacySearchResultGQL', name: string, street1: string, city: string, state: string, zipCode: string, phoneNumber: string }> };

export type TypeaheadOptions__SearchDiagnosisCodesQueryVariables = Types.Exact<{
  search: Types.Scalars['String']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type TypeaheadOptions__SearchDiagnosisCodesQuery = { __typename?: 'Query', searchDiagnosisCodes: Array<{ __typename?: 'DiagnosisCode', code: string, description: string, shortDescription: string }> };

export type TypeaheadOptions__SearchAuthorizationTypesQueryVariables = Types.Exact<{
  search: Types.Scalars['String']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type TypeaheadOptions__SearchAuthorizationTypesQuery = { __typename?: 'Query', searchAuthorizationTypes: Array<{ __typename?: 'PriorAuthorizationType', authType: string, description: string }> };

export type TypeaheadOptions__SearchGcAuthorizationTypesQueryVariables = Types.Exact<{
  search: Types.Scalars['String']['input'];
  procedureType: Types.ProcedureType;
}>;


export type TypeaheadOptions__SearchGcAuthorizationTypesQuery = { __typename?: 'Query', searchGCAuthorizationTypes: Array<{ __typename?: 'PriorAuthorizationType', authType: string, description: string }> };

export type TypeaheadOptions__SearchProcedureCodesQueryVariables = Types.Exact<{
  search: Types.Scalars['String']['input'];
  onlyCareCostProcedures?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type TypeaheadOptions__SearchProcedureCodesQuery = { __typename?: 'Query', searchProcedureCodes: Array<{ __typename?: 'ProcedureCode', code: string, type?: string | null, description: string, plainLanguageDescription?: string | null }> };

export type TypeaheadOptions__SearchVbaProvidersQueryVariables = Types.Exact<{
  search: Types.Scalars['String']['input'];
}>;


export type TypeaheadOptions__SearchVbaProvidersQuery = { __typename?: 'Query', searchVBAProviders: Array<{ __typename?: 'VBAProvider', firstName?: string | null, lastName?: string | null, nationalProviderId: string, vbaId: string, address: { __typename?: 'VBAProviderAddress', city?: string | null, state?: string | null, streetAddress?: string | null, suiteNumber?: string | null, zipCode?: string | null } }> };

export type TypeaheadOptions__SearchProvidersQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type TypeaheadOptions__SearchProvidersQuery = { __typename?: 'Query', providersFlat: { __typename?: 'ProviderResponse', providers: Array<{ __typename?: 'ProviderFlat', id: string, providerId: string, npi?: string | null, firstName?: string | null, lastName?: string | null, city?: string | null, state?: string | null, street1?: string | null, street2?: string | null, zipCode?: string | null, officeIdsList?: Array<{ __typename?: 'OfficeEntry', id?: string | null }> | null }> } };

export type TypeaheadOptions__SearchHePractitionersQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type TypeaheadOptions__SearchHePractitionersQuery = { __typename?: 'Query', searchHEPractitioners: Array<{ __typename?: 'Practitioner', id: string, practitionerId: string, firstName?: string | null, lastName?: string | null, nationalProviderId?: string | null, address?: { __typename?: 'PractitionerAddress', practitionerAddressId: string, addressLine?: string | null, addressLine2?: string | null, cityName?: string | null, stateCode?: string | null, zipCode?: string | null } | null }> };


export const TypeaheadOptions__SearchPharmaciesDocument = gql`
    query TypeaheadOptions__searchPharmacies($zipCode: String!, $query: String!) {
  searchPharmacies(zipcode: $zipCode, query: $query) {
    name
    street1
    city
    state
    zipCode
    phoneNumber
  }
}
    `;

/**
 * __useTypeaheadOptions__SearchPharmaciesQuery__
 *
 * To run a query within a React component, call `useTypeaheadOptions__SearchPharmaciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeaheadOptions__SearchPharmaciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeaheadOptions__SearchPharmaciesQuery({
 *   variables: {
 *      zipCode: // value for 'zipCode'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useTypeaheadOptions__SearchPharmaciesQuery(baseOptions: Apollo.QueryHookOptions<TypeaheadOptions__SearchPharmaciesQuery, TypeaheadOptions__SearchPharmaciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TypeaheadOptions__SearchPharmaciesQuery, TypeaheadOptions__SearchPharmaciesQueryVariables>(TypeaheadOptions__SearchPharmaciesDocument, options);
      }
export function useTypeaheadOptions__SearchPharmaciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TypeaheadOptions__SearchPharmaciesQuery, TypeaheadOptions__SearchPharmaciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TypeaheadOptions__SearchPharmaciesQuery, TypeaheadOptions__SearchPharmaciesQueryVariables>(TypeaheadOptions__SearchPharmaciesDocument, options);
        }
export function useTypeaheadOptions__SearchPharmaciesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TypeaheadOptions__SearchPharmaciesQuery, TypeaheadOptions__SearchPharmaciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TypeaheadOptions__SearchPharmaciesQuery, TypeaheadOptions__SearchPharmaciesQueryVariables>(TypeaheadOptions__SearchPharmaciesDocument, options);
        }
export type TypeaheadOptions__SearchPharmaciesQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchPharmaciesQuery>;
export type TypeaheadOptions__SearchPharmaciesLazyQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchPharmaciesLazyQuery>;
export type TypeaheadOptions__SearchPharmaciesSuspenseQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchPharmaciesSuspenseQuery>;
export type TypeaheadOptions__SearchPharmaciesQueryResult = Apollo.QueryResult<TypeaheadOptions__SearchPharmaciesQuery, TypeaheadOptions__SearchPharmaciesQueryVariables>;
export const TypeaheadOptions__SearchDiagnosisCodesDocument = gql`
    query TypeaheadOptions__searchDiagnosisCodes($search: String!, $limit: Int) {
  searchDiagnosisCodes(limit: $limit, search: $search) {
    code
    description
    shortDescription
  }
}
    `;

/**
 * __useTypeaheadOptions__SearchDiagnosisCodesQuery__
 *
 * To run a query within a React component, call `useTypeaheadOptions__SearchDiagnosisCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeaheadOptions__SearchDiagnosisCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeaheadOptions__SearchDiagnosisCodesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTypeaheadOptions__SearchDiagnosisCodesQuery(baseOptions: Apollo.QueryHookOptions<TypeaheadOptions__SearchDiagnosisCodesQuery, TypeaheadOptions__SearchDiagnosisCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TypeaheadOptions__SearchDiagnosisCodesQuery, TypeaheadOptions__SearchDiagnosisCodesQueryVariables>(TypeaheadOptions__SearchDiagnosisCodesDocument, options);
      }
export function useTypeaheadOptions__SearchDiagnosisCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TypeaheadOptions__SearchDiagnosisCodesQuery, TypeaheadOptions__SearchDiagnosisCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TypeaheadOptions__SearchDiagnosisCodesQuery, TypeaheadOptions__SearchDiagnosisCodesQueryVariables>(TypeaheadOptions__SearchDiagnosisCodesDocument, options);
        }
export function useTypeaheadOptions__SearchDiagnosisCodesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TypeaheadOptions__SearchDiagnosisCodesQuery, TypeaheadOptions__SearchDiagnosisCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TypeaheadOptions__SearchDiagnosisCodesQuery, TypeaheadOptions__SearchDiagnosisCodesQueryVariables>(TypeaheadOptions__SearchDiagnosisCodesDocument, options);
        }
export type TypeaheadOptions__SearchDiagnosisCodesQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchDiagnosisCodesQuery>;
export type TypeaheadOptions__SearchDiagnosisCodesLazyQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchDiagnosisCodesLazyQuery>;
export type TypeaheadOptions__SearchDiagnosisCodesSuspenseQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchDiagnosisCodesSuspenseQuery>;
export type TypeaheadOptions__SearchDiagnosisCodesQueryResult = Apollo.QueryResult<TypeaheadOptions__SearchDiagnosisCodesQuery, TypeaheadOptions__SearchDiagnosisCodesQueryVariables>;
export const TypeaheadOptions__SearchAuthorizationTypesDocument = gql`
    query TypeaheadOptions__searchAuthorizationTypes($search: String!, $limit: Int) {
  searchAuthorizationTypes(search: $search, limit: $limit) {
    authType
    description
  }
}
    `;

/**
 * __useTypeaheadOptions__SearchAuthorizationTypesQuery__
 *
 * To run a query within a React component, call `useTypeaheadOptions__SearchAuthorizationTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeaheadOptions__SearchAuthorizationTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeaheadOptions__SearchAuthorizationTypesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTypeaheadOptions__SearchAuthorizationTypesQuery(baseOptions: Apollo.QueryHookOptions<TypeaheadOptions__SearchAuthorizationTypesQuery, TypeaheadOptions__SearchAuthorizationTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TypeaheadOptions__SearchAuthorizationTypesQuery, TypeaheadOptions__SearchAuthorizationTypesQueryVariables>(TypeaheadOptions__SearchAuthorizationTypesDocument, options);
      }
export function useTypeaheadOptions__SearchAuthorizationTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TypeaheadOptions__SearchAuthorizationTypesQuery, TypeaheadOptions__SearchAuthorizationTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TypeaheadOptions__SearchAuthorizationTypesQuery, TypeaheadOptions__SearchAuthorizationTypesQueryVariables>(TypeaheadOptions__SearchAuthorizationTypesDocument, options);
        }
export function useTypeaheadOptions__SearchAuthorizationTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TypeaheadOptions__SearchAuthorizationTypesQuery, TypeaheadOptions__SearchAuthorizationTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TypeaheadOptions__SearchAuthorizationTypesQuery, TypeaheadOptions__SearchAuthorizationTypesQueryVariables>(TypeaheadOptions__SearchAuthorizationTypesDocument, options);
        }
export type TypeaheadOptions__SearchAuthorizationTypesQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchAuthorizationTypesQuery>;
export type TypeaheadOptions__SearchAuthorizationTypesLazyQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchAuthorizationTypesLazyQuery>;
export type TypeaheadOptions__SearchAuthorizationTypesSuspenseQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchAuthorizationTypesSuspenseQuery>;
export type TypeaheadOptions__SearchAuthorizationTypesQueryResult = Apollo.QueryResult<TypeaheadOptions__SearchAuthorizationTypesQuery, TypeaheadOptions__SearchAuthorizationTypesQueryVariables>;
export const TypeaheadOptions__SearchGcAuthorizationTypesDocument = gql`
    query TypeaheadOptions__searchGCAuthorizationTypes($search: String!, $procedureType: ProcedureType!) {
  searchGCAuthorizationTypes(search: $search, procedureType: $procedureType) {
    authType
    description
  }
}
    `;

/**
 * __useTypeaheadOptions__SearchGcAuthorizationTypesQuery__
 *
 * To run a query within a React component, call `useTypeaheadOptions__SearchGcAuthorizationTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeaheadOptions__SearchGcAuthorizationTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeaheadOptions__SearchGcAuthorizationTypesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      procedureType: // value for 'procedureType'
 *   },
 * });
 */
export function useTypeaheadOptions__SearchGcAuthorizationTypesQuery(baseOptions: Apollo.QueryHookOptions<TypeaheadOptions__SearchGcAuthorizationTypesQuery, TypeaheadOptions__SearchGcAuthorizationTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TypeaheadOptions__SearchGcAuthorizationTypesQuery, TypeaheadOptions__SearchGcAuthorizationTypesQueryVariables>(TypeaheadOptions__SearchGcAuthorizationTypesDocument, options);
      }
export function useTypeaheadOptions__SearchGcAuthorizationTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TypeaheadOptions__SearchGcAuthorizationTypesQuery, TypeaheadOptions__SearchGcAuthorizationTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TypeaheadOptions__SearchGcAuthorizationTypesQuery, TypeaheadOptions__SearchGcAuthorizationTypesQueryVariables>(TypeaheadOptions__SearchGcAuthorizationTypesDocument, options);
        }
export function useTypeaheadOptions__SearchGcAuthorizationTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TypeaheadOptions__SearchGcAuthorizationTypesQuery, TypeaheadOptions__SearchGcAuthorizationTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TypeaheadOptions__SearchGcAuthorizationTypesQuery, TypeaheadOptions__SearchGcAuthorizationTypesQueryVariables>(TypeaheadOptions__SearchGcAuthorizationTypesDocument, options);
        }
export type TypeaheadOptions__SearchGcAuthorizationTypesQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchGcAuthorizationTypesQuery>;
export type TypeaheadOptions__SearchGcAuthorizationTypesLazyQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchGcAuthorizationTypesLazyQuery>;
export type TypeaheadOptions__SearchGcAuthorizationTypesSuspenseQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchGcAuthorizationTypesSuspenseQuery>;
export type TypeaheadOptions__SearchGcAuthorizationTypesQueryResult = Apollo.QueryResult<TypeaheadOptions__SearchGcAuthorizationTypesQuery, TypeaheadOptions__SearchGcAuthorizationTypesQueryVariables>;
export const TypeaheadOptions__SearchProcedureCodesDocument = gql`
    query TypeaheadOptions__searchProcedureCodes($search: String!, $onlyCareCostProcedures: Boolean) {
  searchProcedureCodes(
    search: $search
    onlyCareCostProcedures: $onlyCareCostProcedures
  ) {
    code
    type
    description
    plainLanguageDescription
  }
}
    `;

/**
 * __useTypeaheadOptions__SearchProcedureCodesQuery__
 *
 * To run a query within a React component, call `useTypeaheadOptions__SearchProcedureCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeaheadOptions__SearchProcedureCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeaheadOptions__SearchProcedureCodesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      onlyCareCostProcedures: // value for 'onlyCareCostProcedures'
 *   },
 * });
 */
export function useTypeaheadOptions__SearchProcedureCodesQuery(baseOptions: Apollo.QueryHookOptions<TypeaheadOptions__SearchProcedureCodesQuery, TypeaheadOptions__SearchProcedureCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TypeaheadOptions__SearchProcedureCodesQuery, TypeaheadOptions__SearchProcedureCodesQueryVariables>(TypeaheadOptions__SearchProcedureCodesDocument, options);
      }
export function useTypeaheadOptions__SearchProcedureCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TypeaheadOptions__SearchProcedureCodesQuery, TypeaheadOptions__SearchProcedureCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TypeaheadOptions__SearchProcedureCodesQuery, TypeaheadOptions__SearchProcedureCodesQueryVariables>(TypeaheadOptions__SearchProcedureCodesDocument, options);
        }
export function useTypeaheadOptions__SearchProcedureCodesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TypeaheadOptions__SearchProcedureCodesQuery, TypeaheadOptions__SearchProcedureCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TypeaheadOptions__SearchProcedureCodesQuery, TypeaheadOptions__SearchProcedureCodesQueryVariables>(TypeaheadOptions__SearchProcedureCodesDocument, options);
        }
export type TypeaheadOptions__SearchProcedureCodesQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchProcedureCodesQuery>;
export type TypeaheadOptions__SearchProcedureCodesLazyQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchProcedureCodesLazyQuery>;
export type TypeaheadOptions__SearchProcedureCodesSuspenseQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchProcedureCodesSuspenseQuery>;
export type TypeaheadOptions__SearchProcedureCodesQueryResult = Apollo.QueryResult<TypeaheadOptions__SearchProcedureCodesQuery, TypeaheadOptions__SearchProcedureCodesQueryVariables>;
export const TypeaheadOptions__SearchVbaProvidersDocument = gql`
    query TypeaheadOptions__searchVBAProviders($search: String!) {
  searchVBAProviders(search: $search) {
    firstName
    lastName
    nationalProviderId
    vbaId
    address {
      city
      state
      streetAddress
      suiteNumber
      zipCode
    }
  }
}
    `;

/**
 * __useTypeaheadOptions__SearchVbaProvidersQuery__
 *
 * To run a query within a React component, call `useTypeaheadOptions__SearchVbaProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeaheadOptions__SearchVbaProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeaheadOptions__SearchVbaProvidersQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTypeaheadOptions__SearchVbaProvidersQuery(baseOptions: Apollo.QueryHookOptions<TypeaheadOptions__SearchVbaProvidersQuery, TypeaheadOptions__SearchVbaProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TypeaheadOptions__SearchVbaProvidersQuery, TypeaheadOptions__SearchVbaProvidersQueryVariables>(TypeaheadOptions__SearchVbaProvidersDocument, options);
      }
export function useTypeaheadOptions__SearchVbaProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TypeaheadOptions__SearchVbaProvidersQuery, TypeaheadOptions__SearchVbaProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TypeaheadOptions__SearchVbaProvidersQuery, TypeaheadOptions__SearchVbaProvidersQueryVariables>(TypeaheadOptions__SearchVbaProvidersDocument, options);
        }
export function useTypeaheadOptions__SearchVbaProvidersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TypeaheadOptions__SearchVbaProvidersQuery, TypeaheadOptions__SearchVbaProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TypeaheadOptions__SearchVbaProvidersQuery, TypeaheadOptions__SearchVbaProvidersQueryVariables>(TypeaheadOptions__SearchVbaProvidersDocument, options);
        }
export type TypeaheadOptions__SearchVbaProvidersQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchVbaProvidersQuery>;
export type TypeaheadOptions__SearchVbaProvidersLazyQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchVbaProvidersLazyQuery>;
export type TypeaheadOptions__SearchVbaProvidersSuspenseQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchVbaProvidersSuspenseQuery>;
export type TypeaheadOptions__SearchVbaProvidersQueryResult = Apollo.QueryResult<TypeaheadOptions__SearchVbaProvidersQuery, TypeaheadOptions__SearchVbaProvidersQueryVariables>;
export const TypeaheadOptions__SearchProvidersDocument = gql`
    query TypeaheadOptions__searchProviders($search: String) {
  providersFlat(
    search: $search
    shouldSearchByNameOnly: true
    limit: 20
    sortByRelevance: true
  ) {
    providers {
      id
      providerId
      npi
      firstName
      lastName
      officeIdsList {
        id
      }
      city
      state
      street1
      street2
      zipCode
    }
  }
}
    `;

/**
 * __useTypeaheadOptions__SearchProvidersQuery__
 *
 * To run a query within a React component, call `useTypeaheadOptions__SearchProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeaheadOptions__SearchProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeaheadOptions__SearchProvidersQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTypeaheadOptions__SearchProvidersQuery(baseOptions?: Apollo.QueryHookOptions<TypeaheadOptions__SearchProvidersQuery, TypeaheadOptions__SearchProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TypeaheadOptions__SearchProvidersQuery, TypeaheadOptions__SearchProvidersQueryVariables>(TypeaheadOptions__SearchProvidersDocument, options);
      }
export function useTypeaheadOptions__SearchProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TypeaheadOptions__SearchProvidersQuery, TypeaheadOptions__SearchProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TypeaheadOptions__SearchProvidersQuery, TypeaheadOptions__SearchProvidersQueryVariables>(TypeaheadOptions__SearchProvidersDocument, options);
        }
export function useTypeaheadOptions__SearchProvidersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TypeaheadOptions__SearchProvidersQuery, TypeaheadOptions__SearchProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TypeaheadOptions__SearchProvidersQuery, TypeaheadOptions__SearchProvidersQueryVariables>(TypeaheadOptions__SearchProvidersDocument, options);
        }
export type TypeaheadOptions__SearchProvidersQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchProvidersQuery>;
export type TypeaheadOptions__SearchProvidersLazyQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchProvidersLazyQuery>;
export type TypeaheadOptions__SearchProvidersSuspenseQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchProvidersSuspenseQuery>;
export type TypeaheadOptions__SearchProvidersQueryResult = Apollo.QueryResult<TypeaheadOptions__SearchProvidersQuery, TypeaheadOptions__SearchProvidersQueryVariables>;
export const TypeaheadOptions__SearchHePractitionersDocument = gql`
    query TypeaheadOptions__searchHEPractitioners($search: String) {
  searchHEPractitioners(search: $search) {
    id
    practitionerId
    firstName
    lastName
    nationalProviderId
    address {
      practitionerAddressId
      addressLine
      addressLine2
      cityName
      stateCode
      zipCode
    }
  }
}
    `;

/**
 * __useTypeaheadOptions__SearchHePractitionersQuery__
 *
 * To run a query within a React component, call `useTypeaheadOptions__SearchHePractitionersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeaheadOptions__SearchHePractitionersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeaheadOptions__SearchHePractitionersQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTypeaheadOptions__SearchHePractitionersQuery(baseOptions?: Apollo.QueryHookOptions<TypeaheadOptions__SearchHePractitionersQuery, TypeaheadOptions__SearchHePractitionersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TypeaheadOptions__SearchHePractitionersQuery, TypeaheadOptions__SearchHePractitionersQueryVariables>(TypeaheadOptions__SearchHePractitionersDocument, options);
      }
export function useTypeaheadOptions__SearchHePractitionersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TypeaheadOptions__SearchHePractitionersQuery, TypeaheadOptions__SearchHePractitionersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TypeaheadOptions__SearchHePractitionersQuery, TypeaheadOptions__SearchHePractitionersQueryVariables>(TypeaheadOptions__SearchHePractitionersDocument, options);
        }
export function useTypeaheadOptions__SearchHePractitionersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TypeaheadOptions__SearchHePractitionersQuery, TypeaheadOptions__SearchHePractitionersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TypeaheadOptions__SearchHePractitionersQuery, TypeaheadOptions__SearchHePractitionersQueryVariables>(TypeaheadOptions__SearchHePractitionersDocument, options);
        }
export type TypeaheadOptions__SearchHePractitionersQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchHePractitionersQuery>;
export type TypeaheadOptions__SearchHePractitionersLazyQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchHePractitionersLazyQuery>;
export type TypeaheadOptions__SearchHePractitionersSuspenseQueryHookResult = ReturnType<typeof useTypeaheadOptions__SearchHePractitionersSuspenseQuery>;
export type TypeaheadOptions__SearchHePractitionersQueryResult = Apollo.QueryResult<TypeaheadOptions__SearchHePractitionersQuery, TypeaheadOptions__SearchHePractitionersQueryVariables>;