import clsx from "clsx";
import { ReactElement, useEffect, useState } from "react";
import { usePopper } from "react-popper";

import { SpacingVariant } from "../../constants";
import { Box } from "../Box";
import { DISPLAY_CLASS_NAMES, VERTICAL_ALIGN_CLASS_NAMES } from "../constants";
import { Icon, IconVariant } from "../Icon";
import {
  CUIComponent,
  CUIComponentProps,
  DisplayVariant,
  TextVariant,
  VerticalAlignVariant,
} from "../types";

const SHOW_EVENTS = ["mouseenter", "focus"];
const HIDE_EVENTS = ["mouseleave", "blur"];

export enum TooltipPlacement {
  AUTO = "auto",
  AUTO_START = "auto-start",
  AUTO_END = "auto-end",
  BOTTOM = "bottom",
  BOTTOM_END = "bottom-end",
  BOTTOM_START = "bottom-start",
  LEFT_END = "left-end",
  LEFT_START = "left-start",
  LEFT = "left",
  RIGHT = "right",
  RIGHT_END = "right-end",
  RIGHT_START = "right-start",
  TOP = "top",
  TOP_END = "top-end",
  TOP_START = "top-start",
}

export enum TooltipSize {
  Large,
  Medium,
  Small,
}

export enum TooltipVariant {
  Light,
  Dark,
}

const TOOL_TIP_VARIANT_DARK_CLASS_NAMES = [
  "bg-background-subdued",
  "text-white",
];

const TOOL_TIP_VARIANT_LIGHT_CLASS_NAMES = [
  "bg-white",
  "text-textColor-subdued",
];

export interface TooltipProps {
  message: string;
  maxWidth?: number;
  offsetX?: number;
  offsetY?: number;
  placement?: TooltipPlacement;
  size?: TooltipSize;
  triggerElement: Element | null;
  variant?: TooltipVariant;
  fitMessage?: boolean;
}

export interface TooltipWrapperProps {
  message?: string;
  maxWidth?: number;
  offsetX?: number;
  offsetY?: number;
  placement?: TooltipPlacement;
  size?: TooltipSize;
  variant?: TooltipVariant;
  fitMessage?: boolean;
  children?: ReactElement;
  isDisabled?: boolean;
  className?: string;
}

export const Tooltip: CUIComponent<TooltipProps> = ({
  maxWidth,
  message,
  offsetX = 0,
  offsetY = 0,
  placement = TooltipPlacement.RIGHT_END,
  size = TooltipSize.Large,
  testId = "Tooltip",
  triggerElement,
  variant = TooltipVariant.Light,
  fitMessage,
  className,
}) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const { styles, attributes } = usePopper(triggerElement, popperElement, {
    placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [offsetY, offsetX],
        },
      },
    ],
  });
  const [isVisible, setIsVisible] = useState(false);

  function show() {
    setIsVisible(true);
  }

  function hide() {
    setIsVisible(false);
  }

  useEffect(() => {
    if (!triggerElement) return;
    SHOW_EVENTS.forEach((event) => {
      triggerElement.addEventListener(event, show);
    });

    HIDE_EVENTS.forEach((event) => {
      triggerElement.addEventListener(event, hide);
    });

    return () => {
      SHOW_EVENTS.forEach((event) => {
        triggerElement.removeEventListener(event, show);
      });
      HIDE_EVENTS.forEach((event) => {
        triggerElement.removeEventListener(event, hide);
      });
    };
  }, [triggerElement]);

  let textVariant = TextVariant.MdRegularTall;

  switch (size) {
    case TooltipSize.Large:
      textVariant = TextVariant.MdRegularTall;
      break;
    case TooltipSize.Medium:
      textVariant = TextVariant.SmRegularTall;
      break;
    case TooltipSize.Small:
      textVariant = TextVariant.XsRegular;
      break;
  }

  if (!isVisible) return null;

  return (
    <div
      className={clsx([
        "px-3",
        "py-1",
        "rounded-lg",
        "shadow-md",
        "whitespace-normal",
        "z-[100]", // Needed to display tooltips on top of the SideNavbar
        fitMessage && "w-max",
        variant === TooltipVariant.Dark
          ? TOOL_TIP_VARIANT_DARK_CLASS_NAMES
          : TOOL_TIP_VARIANT_LIGHT_CLASS_NAMES,
        className,
      ])}
      ref={setPopperElement}
      style={{
        ...styles.popper,
        maxWidth: maxWidth ? `${maxWidth}px` : undefined,
      }}
      data-testid={testId}
      {...attributes.popper}
    >
      <Box textVariant={textVariant} element="div">
        {message}
      </Box>
    </div>
  );
};

export const TooltipWrapper: CUIComponent<TooltipWrapperProps> = (props) => {
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const child =
    props.children instanceof Array ? props.children[0] : props.children;

  return props.isDisabled || !props.message ? (
    child
  ) : (
    <>
      <Tooltip
        {...props}
        triggerElement={ref}
        message={props.message}
        placement={props.placement}
      />
      <div
        className={clsx(
          // TODO: fix css classes missing * prefix
          "flex h-fit w-fit",
          props.className
        )}
        ref={setRef}
      >
        {child}
      </div>
    </>
  );
};

export type TooltipInfoIconProps = CUIComponentProps<
  Pick<TooltipProps, "message" | "maxWidth" | "placement"> & {
    iconSize?: SpacingVariant;
  }
>;

export const TooltipInfoIcon: CUIComponent<TooltipInfoIconProps> = ({
  className,
  placement = TooltipPlacement.AUTO,
  testId = "TooltipInfoIcon",
  iconSize = SpacingVariant.S20,
  ...rest
}) => {
  const [ref, setRef] = useState<HTMLElement | null>(null);

  return (
    <div
      className={clsx(
        DISPLAY_CLASS_NAMES[DisplayVariant.InlineBlock],
        VERTICAL_ALIGN_CLASS_NAMES[VerticalAlignVariant.Middle],
        className
      )}
      ref={setRef}
      data-testid={testId}
    >
      <Icon size={iconSize} variant={IconVariant.INFORMATION_CIRCLE} />
      <Tooltip
        placement={placement}
        triggerElement={ref}
        variant={TooltipVariant.Dark}
        {...rest}
      />
    </div>
  );
};
