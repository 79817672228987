import {
  dateOfBirthRequiredSchema,
  type MinAgeErrorMessageFormatter,
  MinimumAge,
} from "@chp/shared/utils/validation/dateOfBirth";
import { memberIdSchema } from "@chp/shared/utils/validation/memberId";
import { ssnSchema } from "@chp/shared/utils/validation/ssn";
import { IntlFormatters } from "react-intl";
import * as Yup from "yup";

import {
  DOB_FIELD_NAME,
  MEMBER_EXTERNAL_ID_FIELD_NAME,
  SIGNUP_CONSENT,
  SSN_FIELD_NAME,
} from "../constants";

export type LooseMemberVerificationFormValidationSchema = {
  [DOB_FIELD_NAME]: Date;
  [SIGNUP_CONSENT]: boolean;
};

export type StrictMemberVerificationFormValidationSchema =
  LooseMemberVerificationFormValidationSchema & {
    [SSN_FIELD_NAME]: string | null | undefined;
    [MEMBER_EXTERNAL_ID_FIELD_NAME]: string | null | undefined;
  };
export type AgeVerificationProps = {
  formatMessage: IntlFormatters["formatMessage"];
  formatMinAgeErrorMessage?: MinAgeErrorMessageFormatter;
  minAge: MinimumAge;
};

export const getLooseMemberVerificationFormValidators = ({
  formatMessage,
  formatMinAgeErrorMessage,
  minAge,
}: AgeVerificationProps) => {
  const signupConsetValidationError = formatMessage({
    defaultMessage:
      "To proceed, you must check the checkbox above to confirm your eligibility for creating this account.",
    id: "FohTob",
  });

  return {
    [DOB_FIELD_NAME]: dateOfBirthRequiredSchema({
      formatMessage,
      minAge,
      formatMinAgeErrorMessage,
    }),
    [SIGNUP_CONSENT]: Yup.boolean()
      .required(signupConsetValidationError)
      .oneOf([true], signupConsetValidationError),
  };
};

export const getDateOfBirthFormValidators = ({
  formatMessage,
  formatMinAgeErrorMessage,
  minAge,
}: AgeVerificationProps) => {
  return {
    [DOB_FIELD_NAME]: dateOfBirthRequiredSchema({
      formatMessage,
      minAge,
      formatMinAgeErrorMessage,
    }),
  };
};

export const getStrictMemberVerificationFormValidators = ({
  formatMessage,
  formatMinAgeErrorMessage,
  minAge,
}: {
  formatMessage: IntlFormatters["formatMessage"];
  formatMinAgeErrorMessage?: MinAgeErrorMessageFormatter;
  minAge: MinimumAge;
}) => ({
  ...getLooseMemberVerificationFormValidators({
    formatMessage,
    formatMinAgeErrorMessage,
    minAge,
  }),
  [SSN_FIELD_NAME]: Yup.string()
    .nullable()
    .when("memberId", {
      is: (val: string | undefined) => !val,
      then: () =>
        ssnSchema(formatMessage).required(
          formatMessage({
            defaultMessage:
              "Either a Social Security Number or Member ID is required.",
            id: "jcBKT0",
          })
        ),
    }),
  [MEMBER_EXTERNAL_ID_FIELD_NAME]: Yup.string()
    .nullable()
    .when(SSN_FIELD_NAME, {
      is: (val: string | undefined) => !val,
      then: () =>
        memberIdSchema(formatMessage).required(
          formatMessage({
            defaultMessage:
              "Either a Member ID or Social Security Number is required.",
            id: "u81wNT",
          })
        ),
    }),
});

export const getMemberVerificationFormValidationSchema = ({
  formatMessage,
  formatMinAgeErrorMessage,
  isStrict,
  minAge,
}: AgeVerificationProps & {
  isStrict: boolean;
}): Yup.ObjectSchema<
  | LooseMemberVerificationFormValidationSchema
  | StrictMemberVerificationFormValidationSchema
> =>
  !isStrict
    ? Yup.object().shape(
        getLooseMemberVerificationFormValidators({
          formatMessage,
          formatMinAgeErrorMessage,
          minAge,
        })
      )
    : Yup.object().shape(
        getStrictMemberVerificationFormValidators({
          formatMessage,
          formatMinAgeErrorMessage,
          minAge,
        }),
        [[MEMBER_EXTERNAL_ID_FIELD_NAME, SSN_FIELD_NAME]]
      );
