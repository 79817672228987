module.exports = {
  primary: {
    signalOrange: "#FC5C50",
    skyBlue: "#00B2FF",
  },
  neutral: {
    black: "#181B25",
    eggshell: "#EBE5D8",
    offWhite: "#F6F3EB",
    white: "#FDFDFD",
  },
  blue: {
    100: "#F1F6FF",
    200: "#DCE5FF",
    300: "#B8CBFD",
    400: "#8CAAF6",
    500: "#6489EF",
    600: "#1C63EB",
    700: "#074ADF",
    800: "#1F40B8",
    900: "#0C2F91",
  },
  green: {
    100: "#E9FAF0",
    200: "#B2F4D1",
    300: "#81DFAE",
    400: "#41C087",
    500: "#00A06A",
    600: "#007953",
    700: "#0E6345",
    800: "#18543C",
    900: "#1E3D2F",
  },
  red: {
    100: "#FFF3F0",
    200: "#FFDDD8",
    300: "#FFBBAB",
    400: "#F68B7B",
    500: "#E85C4F",
    600: "#C23028",
    700: "#A12721",
    800: "#862822",
    900: "#621F1D",
  },
  orange: {
    100: "#FFF4E3",
    200: "#FEE0B6",
    300: "#EEC781",
    400: "#E09A45",
    500: "#CC751A",
    600: "#A94D00",
    700: "#923A08",
    800: "#7B3413",
    900: "#5C251A",
  },
  teal: {
    100: "#E0FBFF",
    200: "#BAEFFC",
    300: "#8ED8EA",
    400: "#4BB9D1",
    500: "#009BB3",
    600: "#00748C",
    700: "#006075",
    800: "#005266",
    900: "#003D4A",
  },
  purple: {
    100: "#F5F4FF",
    200: "#E5E3FA",
    300: "#CEC7F1",
    400: "#B2A1E2",
    500: "#9D7CD9",
    600: "#7C56BB",
    700: "#65469F",
    800: "#583A8D",
    900: "#412A6F",
  },
  pink: {
    100: "#FFF2F2",
    200: "#FEDDE5",
    300: "#FFBBBF",
    400: "#FA8698",
    500: "#E55B79",
    600: "#BB3263",
    700: "#9F2254",
    800: "#84244D",
    900: "#621B43",
  },
  gray: {
    100: "#F3F5FA",
    200: "#E2E6EC",
    300: "#C8CDD5",
    400: "#A5ABB7",
    500: "#848D9D",
    600: "#5F6A7F",
    700: "#4D576F",
    800: "#45495F",
    900: "#33364C",
  },
};
