import clsx from "clsx";
import { Fragment } from "react";

import { ScreenReaderOnly } from "..";
import { CUIComponentProps } from "../types";

const PROGRESS_BAR_CLASS_NAMES: string[] = [
  "-ml-px",
  "absolute",
  "top-5",
  "bottom-5",
  "left-[4.5px]",
  "w-px",
  "h-[90%]",
];

const PROGRESS_STEP_CIRCLE_CLASS_NAMES: string[] = [
  "relative",
  "z-10",
  "w-2",
  "h-2",
  "flex",
  "items-center",
  "justify-center",
  "rounded-full",
  "text-xs",
  "font-semibold",
  "border-2",
];

enum StepState {
  COMPLETED,
  CURRENT,
  FUTURE,
}

const CIRCLE_STYLING: Record<StepState, string | string[]> = {
  [StepState.COMPLETED]: ["bg-skyBlue", "border-2 border-skyBlue"],
  [StepState.CURRENT]: ["bg-transparent", "border-2 border-skyBlue"],
  [StepState.FUTURE]: ["bg-blue-200", "border-2 border-blue-200"],
};

const LINE_STYLING_MOBILE: Record<StepState, string> = {
  [StepState.CURRENT]:
    "bg-gradient-to-r from-skyBlue from-[0%] to-blue-200 to-[50%]",
  [StepState.COMPLETED]: "bg-skyBlue",
  [StepState.FUTURE]: "bg-blue-200",
};

const LINE_STYLING: Record<StepState, string> = {
  [StepState.CURRENT]:
    "bg-gradient-to-b from-skyBlue from-[0%] to-blue-200 to-[50%]",
  [StepState.COMPLETED]: "bg-skyBlue",
  [StepState.FUTURE]: "bg-blue-200",
};

export interface Step {
  name: string;
  description?: string;
  /* Locale specific numbering */
  number: string;
}

export type StepperProps = CUIComponentProps<{
  steps: Step[];
  currentStepIdx: number;
  ariaLabel: string;
}>;

const MobileStepper = ({ steps, currentStepIdx, ariaLabel }: StepperProps) => {
  return (
    <ol
      aria-label={ariaLabel}
      className="flex w-full flex-row items-center justify-between"
    >
      {steps.map((step, stepIdx) => {
        const isBeforeCurrentStep = stepIdx < currentStepIdx;
        const isLastStep = stepIdx === steps.length - 1;
        const isCurrentStep = stepIdx === currentStepIdx;
        const stepState = isBeforeCurrentStep
          ? StepState.COMPLETED
          : isCurrentStep
          ? StepState.CURRENT
          : StepState.FUTURE;

        return (
          <Fragment key={step.name}>
            <li aria-label={step.name} className="flex flex-row items-center">
              {/** Circle  */}
              <div
                className={clsx(
                  "size-1 rounded-xl p-1",
                  CIRCLE_STYLING[stepState]
                )}
              >
                &nbsp;
              </div>
            </li>
            {/** Line */}
            {!isLastStep && (
              <div
                aria-hidden="true"
                className={clsx("h-px w-full", LINE_STYLING_MOBILE[stepState])}
              >
                &nbsp;
              </div>
            )}
          </Fragment>
        );
      })}
    </ol>
  );
};

export function Stepper({
  steps,
  currentStepIdx,
  testId = "Stepper",
  className,
  ariaLabel,
}: StepperProps) {
  const currentStep = steps[currentStepIdx];
  const finalStep = steps[steps.length - 1];

  if (!currentStep || !finalStep) return null;

  const connectorLength = "pb-4";

  return (
    <div
      data-testid={testId}
      // TODO: i18n support
      aria-label={ariaLabel}
      // TODO: add cui class to root element
      className={className}
    >
      <ScreenReaderOnly element="p">{`${currentStep.number}/${finalStep.number} ${currentStep.name}`}</ScreenReaderOnly>
      <span className="mb-6 justify-center text-md-tall text-textColor-subdued md:hidden">
        <MobileStepper
          currentStepIdx={currentStepIdx}
          steps={steps}
          ariaLabel={ariaLabel}
        />
      </span>
      <ol className="hidden overflow-hidden md:block">
        {steps.map((step, stepIdx) => {
          const isBeforeCurrentStep = stepIdx < currentStepIdx;
          const isLastStep = stepIdx === steps.length - 1;
          const isCurrentStep = stepIdx === currentStepIdx;
          const stepState = isBeforeCurrentStep
            ? StepState.COMPLETED
            : isCurrentStep
            ? StepState.CURRENT
            : StepState.FUTURE;
          return (
            <li
              key={step.name}
              className={clsx(!isLastStep ? connectorLength : "", "relative")}
            >
              <ProgressStep
                step={step}
                stepIdx={stepIdx}
                testId={testId}
                isLastStep={isLastStep}
                stepState={stepState}
              />
            </li>
          );
        })}
      </ol>
    </div>
  );
}

const ProgressStep = ({
  step,
  stepState,
  isLastStep,
}: {
  step: Step;
  stepIdx: number;
  testId: string;
  stepState: StepState;
  isLastStep: boolean;
}) => {
  return (
    <>
      {!isLastStep && (
        <div className={clsx(PROGRESS_BAR_CLASS_NAMES)}>
          <div className={clsx("h-full", LINE_STYLING[stepState])} />
        </div>
      )}

      <div className="group relative flex items-start" aria-current="step">
        <ProgressStepCircle stepNumber={step.number} stepState={stepState} />

        <span className="ml-6 flex min-w-0 flex-col">
          <span
            className={clsx(
              "mt-1.5",
              stepState === StepState.COMPLETED
                ? "text-md text-gray-600 line-through"
                : stepState === StepState.CURRENT
                ? "text-md-tall font-semibold text-gray-500"
                : "text-md text-gray-600"
            )}
          >
            {step.name}
          </span>
        </span>
      </div>
    </>
  );
};

const ProgressStepCircle = ({
  stepState,
}: {
  stepNumber: string;
  stepState: StepState;
}) => {
  return (
    <span className="flex h-9 items-center" aria-hidden="true">
      <span
        className={clsx(
          PROGRESS_STEP_CIRCLE_CLASS_NAMES,
          stepState === StepState.COMPLETED
            ? "border-foundation-accentA bg-foundation-accentA marker:bg-white"
            : stepState === StepState.CURRENT
            ? "border-foundation-accentA marker:bg-white"
            : "border-blue-200 bg-blue-200 marker:bg-white"
        )}
      ></span>
    </span>
  );
};
