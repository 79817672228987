export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** BaselineVisitState */
  BaselineVisitState: { input: any; output: any; }
  /** An arbitrary precision signed decimal */
  BigDecimal: { input: number; output: number; }
  /** An RFC-3339 compliant Full Date Scalar */
  Date: { input: string; output: string; }
  /** A slightly refined version of RFC-3339 compliant DateTime Scalar */
  DateTime: { input: string; output: string; }
  /** A 64-bit signed integer */
  Long: { input: number; output: number; }
  /** UUID type */
  UUID: { input: string; output: string; }
};

export enum AccessControlRate {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  None = 'NONE'
}

export enum AccessControlScope {
  AdminsOnly = 'ADMINS_ONLY',
  EmployersOnly = 'EMPLOYERS_ONLY',
  MembersOnly = 'MEMBERS_ONLY',
  Public = 'PUBLIC'
}

export type AddressInput = {
  city: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  geolocationH3?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  state: Scalars['String']['input'];
  street1: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

export enum AppointmentType {
  InPerson = 'IN_PERSON',
  OnSite = 'ON_SITE',
  Virtual = 'VIRTUAL'
}

export type AppointmentVisit = {
  __typename?: 'AppointmentVisit';
  inPersonApptPart: Array<Array<IndividualApptPart>>;
  onsiteApptPart: Array<Array<IndividualApptPart>>;
  virtualApptPart: Array<Array<IndividualApptPart>>;
};

export type AppointmentWindowV2 = {
  __typename?: 'AppointmentWindowV2';
  apptInfo: AppointmentVisit;
  endDateTime: Scalars['DateTime']['output'];
  startDateTime: Scalars['DateTime']['output'];
  visitTypeInfo: VisitTypeInfo;
};

export enum BaselineStatus {
  Completed = 'COMPLETED',
  NotCompleted = 'NOT_COMPLETED',
  NotRequired = 'NOT_REQUIRED',
  Scheduled = 'SCHEDULED'
}

export type BaselineVisitEventInput =
  { createMemberFormResponse: CreateMemberFormResponseInput; next?: never; setCommunicationsConsent?: never; setContactMethod?: never; setDateOfBirth?: never; setGender?: never; setPhoneNumberVerification?: never; }
  |  { createMemberFormResponse?: never; next: Scalars['Boolean']['input']; setCommunicationsConsent?: never; setContactMethod?: never; setDateOfBirth?: never; setGender?: never; setPhoneNumberVerification?: never; }
  |  { createMemberFormResponse?: never; next?: never; setCommunicationsConsent: SetCommunicationsConsentInput; setContactMethod?: never; setDateOfBirth?: never; setGender?: never; setPhoneNumberVerification?: never; }
  |  { createMemberFormResponse?: never; next?: never; setCommunicationsConsent?: never; setContactMethod: SetContactMethodInput; setDateOfBirth?: never; setGender?: never; setPhoneNumberVerification?: never; }
  |  { createMemberFormResponse?: never; next?: never; setCommunicationsConsent?: never; setContactMethod?: never; setDateOfBirth: SetDateOfBirthInput; setGender?: never; setPhoneNumberVerification?: never; }
  |  { createMemberFormResponse?: never; next?: never; setCommunicationsConsent?: never; setContactMethod?: never; setDateOfBirth?: never; setGender: SetGenderInput; setPhoneNumberVerification?: never; }
  |  { createMemberFormResponse?: never; next?: never; setCommunicationsConsent?: never; setContactMethod?: never; setDateOfBirth?: never; setGender?: never; setPhoneNumberVerification: SetPhoneNumberVerificationInput; };

export type BaselineVisitSettings = {
  __typename?: 'BaselineVisitSettings';
  durationMinutes: Scalars['Int']['output'];
  elationAppointmentReasons: Array<ElationAppointmentReasonOptions>;
  eligiblePhysicians: Array<Clinician>;
  eligiblePhysiciansHalfHour?: Maybe<Array<Clinician>>;
  eligiblePhysiciansHour?: Maybe<Array<Clinician>>;
  memberAddress?: Maybe<MemberAddress>;
  preferredLanguage: Language;
};

/** BaselineVisitStateType */
export enum BaselineVisitStateType {
  Complete = 'Complete',
  NewMember = 'NewMember',
  NewMemberBenefits = 'NewMember_Benefits',
  NewMemberBenefitsConclusion = 'NewMember_Benefits_Conclusion',
  NewMemberBenefitsIntroduction = 'NewMember_Benefits_Introduction',
  NewMemberCareNavigator = 'NewMember_CareNavigator',
  NewMemberCareNavigatorConclusion = 'NewMember_CareNavigator_Conclusion',
  NewMemberCareNavigatorIntroduction = 'NewMember_CareNavigator_Introduction',
  NewMemberClinicalScheduling = 'NewMember_ClinicalScheduling',
  NewMemberClinicalSchedulingConclusion = 'NewMember_ClinicalScheduling_Conclusion',
  NewMemberClinicalSchedulingIntroduction = 'NewMember_ClinicalScheduling_Introduction',
  NewMemberIntroduction = 'NewMember_Introduction',
  NewMemberMedicationsAndPharmacy = 'NewMember_MedicationsAndPharmacy',
  NewMemberMedicationsAndPharmacyConclusion = 'NewMember_MedicationsAndPharmacy_Conclusion',
  NewMemberMedicationsAndPharmacyIntroduction = 'NewMember_MedicationsAndPharmacy_Introduction',
  NewMemberPrograms = 'NewMember_Programs',
  NewMemberProgramsConclusion = 'NewMember_Programs_Conclusion',
  NewMemberProgramsIntroduction = 'NewMember_Programs_Introduction',
  NewMemberProvidersAndFacilities = 'NewMember_ProvidersAndFacilities',
  NewMemberProvidersAndFacilitiesConclusion = 'NewMember_ProvidersAndFacilities_Conclusion',
  NewMemberProvidersAndFacilitiesIntroduction = 'NewMember_ProvidersAndFacilities_Introduction',
  NewMemberTelehealth = 'NewMember_Telehealth',
  NewMemberTelehealthConclusion = 'NewMember_Telehealth_Conclusion',
  NewMemberTelehealthIntroduction = 'NewMember_Telehealth_Introduction',
  NewMemberZeroCard = 'NewMember_ZeroCard',
  NewMemberZeroCardConclusion = 'NewMember_ZeroCard_Conclusion',
  NewMemberZeroCardIntroduction = 'NewMember_ZeroCard_Introduction',
  Renewal = 'Renewal',
  RenewalCommunications = 'Renewal_Communications',
  RenewalCommunicationsCommunicationsConsent = 'Renewal_Communications_CommunicationsConsent',
  RenewalCommunicationsContactMethod = 'Renewal_Communications_ContactMethod',
  RenewalCommunicationsPhoneNumberVerification = 'Renewal_Communications_PhoneNumberVerification',
  RenewalIntroduction = 'Renewal_Introduction',
  RenewalPersonalInformation = 'Renewal_PersonalInformation',
  RenewalPersonalInformationDateOfBirth = 'Renewal_PersonalInformation_DateOfBirth',
  RenewalPersonalInformationGender = 'Renewal_PersonalInformation_Gender'
}

export type BaselineVisitStatus = {
  __typename?: 'BaselineVisitStatus';
  baselineStatus: BaselineStatus;
  baselineVisit?: Maybe<Visit>;
  completeAt?: Maybe<Scalars['DateTime']['output']>;
  daysUntilDueDate: Scalars['Int']['output'];
  displayedDueDate: Scalars['Date']['output'];
  healthPlanMemberToken: Scalars['UUID']['output'];
  isBeforeDueDate: Scalars['Boolean']['output'];
  isComplete: Scalars['Boolean']['output'];
  originalDueDate: Scalars['Date']['output'];
  visitId?: Maybe<Scalars['Int']['output']>;
  visitRequired: Scalars['Boolean']['output'];
};

export type BasicInvoice = {
  __typename?: 'BasicInvoice';
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  finalizedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  status: InvoiceStatus;
  stripeInvoiceId?: Maybe<Scalars['String']['output']>;
  totalAmount: Scalars['Float']['output'];
};

export type BasicMember = CommonMemberGql & {
  __typename?: 'BasicMember';
  /** Returns Member's address if exists, or Member subscriber's address otherwise */
  address?: Maybe<MemberAddress>;
  birthDate: Scalars['Date']['output'];
  email?: Maybe<Scalars['String']['output']>;
  /** @deprecated The term externalId is being phased out, replace with id */
  externalId: Scalars['String']['output'];
  familyName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  givenName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isMinor: Scalars['Boolean']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  portalRegistrationStatus: MemberPortalRegistrationStatus;
  relationship: Relationship;
  sex?: Maybe<Scalars['String']['output']>;
};


export type BasicMemberIsMinorArgs = {
  atDate?: InputMaybe<Scalars['Date']['input']>;
};

export type BasicPrescriptionDeliveryRequest = {
  __typename?: 'BasicPrescriptionDeliveryRequest';
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  libertyRxNpi: Scalars['String']['output'];
  totalCopay: Scalars['Float']['output'];
};

export type BenefitsSummary = {
  __typename?: 'BenefitsSummary';
  hasMetINOOPMax: Scalars['Boolean']['output'];
  hasMetOONOOPMax: Scalars['Boolean']['output'];
  isPlanHighDeductible: Scalars['Boolean']['output'];
  memberName: Scalars['String']['output'];
  planCoveragePackage?: Maybe<CoveragePackage>;
  planEndDate: Scalars['Date']['output'];
  planStartDate: Scalars['Date']['output'];
  status: EnrollmentStatus;
};

export type CareNavigator = {
  __typename?: 'CareNavigator';
  activeForScheduling: Scalars['Boolean']['output'];
  dockHealthId?: Maybe<Scalars['String']['output']>;
  elationId: Scalars['Long']['output'];
  elationTag: Scalars['String']['output'];
  eligibleForAutoAssignment: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  handledAppointmentTypes?: Maybe<Array<AppointmentType>>;
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  languages?: Maybe<Array<CareTeamMemberLanguages>>;
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
};

export type CareTeamMember = {
  __typename?: 'CareTeamMember';
  activeForScheduling: Scalars['Boolean']['output'];
  credentials?: Maybe<Scalars['String']['output']>;
  dockHealthId?: Maybe<Scalars['String']['output']>;
  elationId: Scalars['Long']['output'];
  elationTag: Scalars['String']['output'];
  eligibleForAutoAssignment: Scalars['Boolean']['output'];
  eligibleStates?: Maybe<Array<Scalars['String']['output']>>;
  email: Scalars['String']['output'];
  employeeType: CareTeamMemberType;
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  handledAppointmentTypes?: Maybe<Array<AppointmentType>>;
  hourInterval?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Long']['output'];
  isActive: Scalars['Boolean']['output'];
  languages?: Maybe<Array<CareTeamMemberLanguages>>;
  lastName: Scalars['String']['output'];
  licensedStates?: Maybe<Array<Scalars['String']['output']>>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  physicianType?: Maybe<ElationPhysicianType>;
  providesPrimaryCare?: Maybe<Scalars['Boolean']['output']>;
};

export type CareTeamMemberInput = {
  activeForScheduling: Scalars['Boolean']['input'];
  credentials?: InputMaybe<Scalars['String']['input']>;
  dockHealthId?: InputMaybe<Scalars['String']['input']>;
  elationId: Scalars['Long']['input'];
  elationTag: Scalars['String']['input'];
  eligibleForAutoAssignment: Scalars['Boolean']['input'];
  eligibleStates?: InputMaybe<Array<Scalars['String']['input']>>;
  email: Scalars['String']['input'];
  employeeType: CareTeamMemberType;
  firstName: Scalars['String']['input'];
  handledAppointmentTypes?: InputMaybe<Array<AppointmentType>>;
  hourInterval?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Long']['input'];
  isActive: Scalars['Boolean']['input'];
  languages?: InputMaybe<Array<CareTeamMemberLanguages>>;
  lastName: Scalars['String']['input'];
  licensedStates?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  physicianType?: InputMaybe<ElationPhysicianType>;
  providesPrimaryCare?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CareTeamMemberLanguages {
  English = 'ENGLISH',
  Spanish = 'SPANISH'
}

export enum CareTeamMemberType {
  CareNavigator = 'CARE_NAVIGATOR',
  Clinician = 'CLINICIAN'
}

export enum Category {
  HospitalBased = 'HOSPITAL_BASED',
  PrimaryCare = 'PRIMARY_CARE',
  Speciality = 'SPECIALITY'
}

export type ChildEmployer = {
  __typename?: 'ChildEmployer';
  hccId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Claim = {
  __typename?: 'Claim';
  allowableAmount?: Maybe<Scalars['Float']['output']>;
  billedAmount?: Maybe<Scalars['Float']['output']>;
  checkNumber?: Maybe<Scalars['String']['output']>;
  claimSystem: ClaimSystem;
  coInsuranceAmount?: Maybe<Scalars['Float']['output']>;
  copayAmount?: Maybe<Scalars['Float']['output']>;
  deductibleAmount?: Maybe<Scalars['Float']['output']>;
  eobUrl?: Maybe<Scalars['String']['output']>;
  memberExternalId: Scalars['String']['output'];
  paidAmount?: Maybe<Scalars['Float']['output']>;
  provider?: Maybe<ClaimProvider>;
  /** @deprecated Use `provider.name` instead */
  providerName: Scalars['String']['output'];
  referenceId?: Maybe<Scalars['String']['output']>;
  serviceEndDate: Scalars['Date']['output'];
  serviceStartDate: Scalars['Date']['output'];
  status: ClaimStatus;
  type: ClaimType;
};

export type ClaimProvider = {
  __typename?: 'ClaimProvider';
  name: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
};

export enum ClaimSortOption {
  ServiceEndDateAsc = 'SERVICE_END_DATE_ASC',
  ServiceEndDateDesc = 'SERVICE_END_DATE_DESC'
}

export enum ClaimStatus {
  Denied = 'DENIED',
  DeniedPartial = 'DENIED_PARTIAL',
  Error = 'ERROR',
  NotAdjudicated = 'NOT_ADJUDICATED',
  Paid = 'PAID',
  Payable = 'PAYABLE',
  Pended = 'PENDED',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
  Refund = 'REFUND',
  Unknown = 'UNKNOWN',
  Void = 'VOID'
}

export enum ClaimStatusFilterInput {
  Denied = 'DENIED',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING'
}

export enum ClaimSystem {
  HealthEdge = 'HEALTH_EDGE',
  Vba = 'VBA'
}

export enum ClaimType {
  Dental = 'DENTAL',
  Disability = 'DISABILITY',
  Flex = 'FLEX',
  Institutional = 'INSTITUTIONAL',
  Life = 'LIFE',
  Pharmacy = 'PHARMACY',
  Professional = 'PROFESSIONAL',
  Unknown = 'UNKNOWN'
}

export type ClaimsPage = {
  __typename?: 'ClaimsPage';
  afterCursor?: Maybe<Scalars['String']['output']>;
  beforeCursor?: Maybe<Scalars['String']['output']>;
  hasPharmacyClaims: Scalars['Boolean']['output'];
  items: Array<PaginatedItem>;
  pendingClaimsCount: Scalars['Int']['output'];
  providers: Array<ClaimProvider>;
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type ClinicalDocument = {
  __typename?: 'ClinicalDocument';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  documentDate: Scalars['Date']['output'];
  documentType: ClinicalDocumentType;
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  isUnread: Scalars['Boolean']['output'];
  memberId: Scalars['String']['output'];
  presignedURL: Scalars['String']['output'];
  s3Key: Scalars['String']['output'];
  source: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum ClinicalDocumentType {
  BlvSummary = 'BLV_SUMMARY',
  Other = 'OTHER',
  PreferredProviders = 'PREFERRED_PROVIDERS'
}

export type Clinician = {
  __typename?: 'Clinician';
  credentials: Scalars['String']['output'];
  elationId: Scalars['Long']['output'];
  eligibleStates?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  firstName: Scalars['String']['output'];
  handledAppointmentTypes?: Maybe<Array<Maybe<AppointmentType>>>;
  hourInterval?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  isActive: Scalars['Boolean']['output'];
  languages?: Maybe<Array<Maybe<CareTeamMemberLanguages>>>;
  lastName: Scalars['String']['output'];
  licensedStates?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  physicianType?: Maybe<ElationPhysicianType>;
  providesPrimaryCare?: Maybe<Scalars['Boolean']['output']>;
  zoomEmail?: Maybe<Scalars['String']['output']>;
};

export type CommonMemberGql = {
  /** Returns Member's address if exists, or Member subscriber's address otherwise */
  address?: Maybe<MemberAddress>;
  birthDate: Scalars['Date']['output'];
  email?: Maybe<Scalars['String']['output']>;
  /** @deprecated The term externalId is being phased out, replace with id */
  externalId: Scalars['String']['output'];
  familyName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  givenName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isMinor: Scalars['Boolean']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  portalRegistrationStatus: MemberPortalRegistrationStatus;
  relationship: Relationship;
  sex?: Maybe<Scalars['String']['output']>;
};


export type CommonMemberGqlIsMinorArgs = {
  atDate?: InputMaybe<Scalars['Date']['input']>;
};

export type ConfirmPrescriptionDeliveryRequestInput = {
  canceledRequestItemIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  city: Scalars['String']['input'];
  deliveryInstructions?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  requestedDeliveryDate: Scalars['Date']['input'];
  state: Scalars['String']['input'];
  street1: Scalars['String']['input'];
  street2: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export enum ContactMethod {
  CallPhoneNumber = 'CALL_PHONE_NUMBER',
  CallSecondaryNumber = 'CALL_SECONDARY_NUMBER',
  DoNotContact = 'DO_NOT_CONTACT',
  EnrollmentEmail = 'ENROLLMENT_EMAIL',
  MemberPortalEmail = 'MEMBER_PORTAL_EMAIL',
  SmsPhoneNumber = 'SMS_PHONE_NUMBER',
  SmsSecondaryPhone = 'SMS_SECONDARY_PHONE'
}

export enum ContactType {
  Email = 'EMAIL',
  Phone = 'PHONE',
  Sms = 'SMS'
}

export type CostProvider = {
  __typename?: 'CostProvider';
  addresses?: Maybe<Array<CostProviderAddress>>;
  admittingPrivileges?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Category>;
  degree?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  gender?: Maybe<Gender>;
  groupNpi?: Maybe<Array<Scalars['String']['output']>>;
  hospitalAffiliation?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Array<Scalars['String']['output']>>;
  lastName: Scalars['String']['output'];
  networks?: Maybe<Array<NetworkType>>;
  npi?: Maybe<Scalars['String']['output']>;
  speciality: Array<Scalars['String']['output']>;
  telephone?: Maybe<Scalars['String']['output']>;
};

export type CostProviderAddress = {
  __typename?: 'CostProviderAddress';
  city: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  street1: Scalars['String']['output'];
  street2?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

export enum CoveragePackage {
  Epo = 'EPO',
  Ppo = 'PPO',
  Ppop = 'PPOP',
  Ppox = 'PPOX'
}

export type CoveragePeriod = {
  __typename?: 'CoveragePeriod';
  divisionId: Scalars['String']['output'];
  endDate: Scalars['Date']['output'];
  groupCoverageStart: Scalars['Date']['output'];
  groupId: Scalars['String']['output'];
  startDate: Scalars['Date']['output'];
  subscriberStartDate: Scalars['Date']['output'];
};

export type CoveragePeriodOption = {
  __typename?: 'CoveragePeriodOption';
  endDate?: Maybe<Scalars['Date']['output']>;
  startDate: Scalars['Date']['output'];
};

export type CreateESignatureRequestInput = {
  signatureDate?: InputMaybe<Scalars['DateTime']['input']>;
  signatureExpiry?: InputMaybe<Scalars['DateTime']['input']>;
  signatureResponseText: Scalars['String']['input'];
  signatureResponseType: SignatureResponseType;
  signatureType: SignatureType;
};

export type CreateInvitedESignatureRequestInput = {
  dateOfBirth: Scalars['Date']['input'];
  signatureInvite: Scalars['UUID']['input'];
  signatureResponseText: Scalars['String']['input'];
  signatureResponseType: SignatureResponseType;
};

export type CreateMemberFormResponseInput = {
  formType: FormType;
  memberFormResponse: NewMemberFormResponseInput;
};

export type Customer = {
  __typename?: 'Customer';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerEmail: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  stripeCustomerId: Scalars['String']['output'];
  subscriberMemberId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DependentInvitationInput = {
  email: Scalars['String']['input'];
  memberId: Scalars['String']['input'];
};

export type DiagnosisCode = {
  __typename?: 'DiagnosisCode';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  shortDescription: Scalars['String']['output'];
};

export type Drug = {
  __typename?: 'Drug';
  brandingVariant: DrugBrandingVariant;
  commercialSpecialty?: Maybe<Scalars['String']['output']>;
  dispensableDrugId: Scalars['Int']['output'];
  dispensableGenericId: Scalars['Int']['output'];
  dosage?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<DrugGender>;
  genericName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  maintenance?: Maybe<Scalars['Boolean']['output']>;
  maximumAgeLimit?: Maybe<Scalars['Int']['output']>;
  minimumAgeLimit?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  ndcs: Array<Scalars['String']['output']>;
  priorAuthorization?: Maybe<Scalars['String']['output']>;
  quantityLimit?: Maybe<Scalars['String']['output']>;
  routedDoseFormGenericId: Scalars['Int']['output'];
  stepTherapy?: Maybe<Scalars['String']['output']>;
  tier: DrugTier;
};

export enum DrugBrandingVariant {
  Branded = 'BRANDED',
  Generic = 'GENERIC',
  NonDrug = 'NON_DRUG'
}

export enum DrugGender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export enum DrugTier {
  Tier_1 = 'TIER_1',
  Tier_2 = 'TIER_2',
  Tier_3 = 'TIER_3'
}

export type ESignature = {
  __typename?: 'ESignature';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  legalAgreement: LegalAgreement;
  legalAgreementId: Scalars['Int']['output'];
  memberId?: Maybe<Scalars['String']['output']>;
  signatureDate: Scalars['DateTime']['output'];
  signatureExpiry?: Maybe<Scalars['DateTime']['output']>;
  signatureResponseText: Scalars['String']['output'];
  signatureResponseType: SignatureResponseType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum ElationAppointmentReasonOptions {
  AnnualExam = 'ANNUAL_EXAM',
  BaselineVisit = 'BASELINE_VISIT',
  BaselineVisitOnsite = 'BASELINE_VISIT_ONSITE',
  BaselineVisitPcInPerson = 'BASELINE_VISIT_PC_IN_PERSON',
  BaselineVisitPcVirtual = 'BASELINE_VISIT_PC_VIRTUAL',
  BaselineVisitVirtual = 'BASELINE_VISIT_VIRTUAL',
  CnOnlyBlv = 'CN_ONLY_BLV',
  CnOnlyBlvVirtual = 'CN_ONLY_BLV_VIRTUAL',
  FollowUp = 'FOLLOW_UP',
  NewPatient = 'NEW_PATIENT',
  NursingVisitDm = 'NURSING_VISIT_DM',
  NursingVisitMh = 'NURSING_VISIT_MH',
  NursingVisitUtiStiGyneComplaint = 'NURSING_VISIT_UTI_STI_GYNE_COMPLAINT',
  OfficeVisit = 'OFFICE_VISIT',
  PhysicalExam = 'PHYSICAL_EXAM',
  PostBlvLabReview = 'POST_BLV_LAB_REVIEW',
  PrimaryCareLegacy = 'PRIMARY_CARE_LEGACY',
  PrimaryCareLong = 'PRIMARY_CARE_LONG',
  PrimaryCareNewPatient = 'PRIMARY_CARE_NEW_PATIENT',
  PrimaryCareShort = 'PRIMARY_CARE_SHORT',
  Procedure = 'PROCEDURE',
  RoutineFollowUp = 'ROUTINE_FOLLOW_UP',
  Test = 'TEST',
  TestFormsTesting = 'TEST_FORMS_TESTING',
  WalkIn = 'WALK_IN',
  WellWomen = 'WELL_WOMEN'
}

export enum ElationPhysicianType {
  CareNavigator = 'CARE_NAVIGATOR',
  Physician = 'PHYSICIAN'
}

export type EmailSubscriptionPreferences = {
  __typename?: 'EmailSubscriptionPreferences';
  subscribedToNonTransactionalEmails: Scalars['Boolean']['output'];
};

export type EmailSubscriptionPreferencesInput = {
  subscribedToNonTransactionalEmails: Scalars['Boolean']['input'];
};

export type Employer = {
  __typename?: 'Employer';
  canEditMembers: Scalars['Boolean']['output'];
  canViewInvoices: Scalars['Boolean']['output'];
  canViewMembers: Scalars['Boolean']['output'];
  claimSystem: ClaimSystem;
  coverageInfo?: Maybe<EmployerCoverageInfo>;
  createdAt: Scalars['DateTime']['output'];
  healthEdgeAccountId?: Maybe<Scalars['String']['output']>;
  healthEdgeAccountKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoices: Array<EmployerInvoice>;
  name: Scalars['String']['output'];
  subAccounts: Array<ChildEmployer>;
  subscribableAccounts: Array<SimpleEmployer>;
  vbaDivisionId?: Maybe<Scalars['String']['output']>;
};


export type EmployerCanEditMembersArgs = {
  userEmail?: InputMaybe<Scalars['String']['input']>;
};


export type EmployerCanViewInvoicesArgs = {
  userEmail?: InputMaybe<Scalars['String']['input']>;
};


export type EmployerCanViewMembersArgs = {
  userEmail?: InputMaybe<Scalars['String']['input']>;
};


export type EmployerInvoicesArgs = {
  numBillingMonthsFilter?: InputMaybe<Scalars['Int']['input']>;
  subAccountIdFilter?: InputMaybe<Scalars['String']['input']>;
};

export type EmployerCoverageInfo = {
  __typename?: 'EmployerCoverageInfo';
  coveragePeriodEndDate?: Maybe<Scalars['Date']['output']>;
  coveragePeriodOptions: Array<CoveragePeriodOption>;
  coveragePeriodStartDate: Scalars['Date']['output'];
  coverageStatus: EmployerCoverageStatus;
  defaultPlanYear?: Maybe<Scalars['Int']['output']>;
  effectiveDateRange: Array<Scalars['Date']['output']>;
  employerState: Scalars['String']['output'];
  planTypes: Array<CoveragePackage>;
  planYears?: Maybe<Array<Scalars['Int']['output']>>;
  plans: Array<Plan>;
};

export enum EmployerCoverageStatus {
  Active = 'ACTIVE',
  Future = 'FUTURE',
  Past = 'PAST'
}

export type EmployerInvoice = {
  __typename?: 'EmployerInvoice';
  accountId: Scalars['String']['output'];
  billingMonth: Scalars['Int']['output'];
  billingYear: Scalars['Int']['output'];
  downloadUrl: Scalars['String']['output'];
  invoiceDate: Scalars['Date']['output'];
};

export type Enrollment = {
  __typename?: 'Enrollment';
  benefitsBookletUrl: Scalars['String']['output'];
  cobraStartDate?: Maybe<Scalars['Date']['output']>;
  divisionId: Scalars['String']['output'];
  endDate: Scalars['Date']['output'];
  fundingType?: Maybe<FundingType>;
  groupCoverageStart: Scalars['Date']['output'];
  groupId: Scalars['String']['output'];
  hasMetINDeductible: Scalars['Boolean']['output'];
  hasMetINOOPMax: Scalars['Boolean']['output'];
  hasMetOONDeductible: Scalars['Boolean']['output'];
  hasMetOONOOPMax: Scalars['Boolean']['output'];
  memberId: Scalars['String']['output'];
  /** @deprecated Use MemberGQL.closestPlan instead. Data will be incorrect for anything other than displayed/closest enrollment. */
  plan: Plan;
  planId: Scalars['String']['output'];
  /** @deprecated This is not employer bound anymore - adapt your logic to rely on the feature flag's 'auto approval enabled for all' key instead */
  qualifiesForAutoZeroCardApprovals: Scalars['Boolean']['output'];
  startDate: Scalars['Date']['output'];
  status: EnrollmentStatus;
  subscriberStartDate: Scalars['Date']['output'];
  tier?: Maybe<Tier>;
  tierId?: Maybe<Scalars['String']['output']>;
  type: EnrollmentType;
};

export enum EnrollmentStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Cobra = 'COBRA',
  Expired = 'EXPIRED',
  Upcoming = 'UPCOMING'
}

export enum EnrollmentType {
  FullyInsured = 'FULLY_INSURED',
  SelfInsured = 'SELF_INSURED'
}

export enum Ethnicity {
  Hispanic = 'HISPANIC',
  NotHispanic = 'NOT_HISPANIC',
  NotShared = 'NOT_SHARED'
}

export type Facility = {
  __typename?: 'Facility';
  city?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  facilityId: Scalars['String']['output'];
  facilityName: Scalars['String']['output'];
  facilityNamesList?: Maybe<Array<Scalars['String']['output']>>;
  faxNumbersList?: Maybe<Array<Scalars['String']['output']>>;
  focusesList?: Maybe<Array<Scalars['String']['output']>>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  npi?: Maybe<Scalars['String']['output']>;
  providerNetworksList?: Maybe<Array<Scalars['String']['output']>>;
  rawServicesList?: Maybe<Array<Scalars['String']['output']>>;
  serviceDetailsList?: Maybe<Array<ServiceDetail>>;
  servicesList?: Maybe<Array<Scalars['String']['output']>>;
  state?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
  telephonesList?: Maybe<Array<Scalars['String']['output']>>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type FacilityFlat = {
  __typename?: 'FacilityFlat';
  city?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  curativeCategoriesList?: Maybe<Array<Scalars['String']['output']>>;
  facilityId: Scalars['String']['output'];
  facilityName: Scalars['String']['output'];
  facilityTypeList?: Maybe<Array<FacilityTypeDetail>>;
  faxNumbersList?: Maybe<Array<Scalars['String']['output']>>;
  focusesList?: Maybe<Array<Scalars['String']['output']>>;
  isAlwaysOpen?: Maybe<Scalars['Boolean']['output']>;
  isVirtualCareFacility?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  licensedLocations?: Maybe<Array<Scalars['String']['output']>>;
  longitude?: Maybe<Scalars['Float']['output']>;
  npi?: Maybe<Scalars['String']['output']>;
  point?: Maybe<Point>;
  providerFacilityId: Scalars['String']['output'];
  providerNetworksList?: Maybe<Array<Scalars['String']['output']>>;
  searchRelevance?: Maybe<Scalars['Float']['output']>;
  servicesList?: Maybe<Array<Scalars['String']['output']>>;
  state?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
  telephonesList?: Maybe<Array<Scalars['String']['output']>>;
  websitesList?: Maybe<Array<Scalars['String']['output']>>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type FacilityResponse = {
  __typename?: 'FacilityResponse';
  count: Scalars['Int']['output'];
  facilities: Array<FacilityFlat>;
};

export type FacilityType = {
  __typename?: 'FacilityType';
  curativeCategory: Scalars['String']['output'];
  curativeFacilityType: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isPopular?: Maybe<Scalars['Boolean']['output']>;
};

export type FacilityTypeDetail = {
  __typename?: 'FacilityTypeDetail';
  curativeCategory?: Maybe<Scalars['String']['output']>;
  curativeFacilityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type FacilityTypeMap = {
  __typename?: 'FacilityTypeMap';
  createdAt: Scalars['DateTime']['output'];
  curativeCategory?: Maybe<Scalars['String']['output']>;
  curativeFacilityType?: Maybe<Scalars['String']['output']>;
  editedBy?: Maybe<Scalars['Int']['output']>;
  editedByUser?: Maybe<User>;
  facilityService: Scalars['String']['output'];
  fsrId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum FormType {
  BaselineVisit = 'BASELINE_VISIT',
  BaselineVisitV2Page_1 = 'BASELINE_VISIT_V2_PAGE_1',
  BaselineVisitV2Page_2 = 'BASELINE_VISIT_V2_PAGE_2',
  BaselineVisitV3Page_1 = 'BASELINE_VISIT_V3_PAGE_1',
  BaselineVisitV3Page_2 = 'BASELINE_VISIT_V3_PAGE_2',
  BaselineVisitV4Page_1 = 'BASELINE_VISIT_V4_PAGE_1',
  BaselineVisitV4Page_2 = 'BASELINE_VISIT_V4_PAGE_2',
  BaselineVisitV4Page_3 = 'BASELINE_VISIT_V4_PAGE_3',
  BaselineVisitV4Page_4 = 'BASELINE_VISIT_V4_PAGE_4',
  BaselineVisitV4Page_5 = 'BASELINE_VISIT_V4_PAGE_5',
  BaselineVisitV4Page_6 = 'BASELINE_VISIT_V4_PAGE_6',
  BaselineVisitV4Page_7 = 'BASELINE_VISIT_V4_PAGE_7',
  BaselineVisitV4Page_8 = 'BASELINE_VISIT_V4_PAGE_8',
  BaselineVisitV4Page_9 = 'BASELINE_VISIT_V4_PAGE_9',
  BaselineVisitV4Page_10 = 'BASELINE_VISIT_V4_PAGE_10',
  Miscellaneous = 'MISCELLANEOUS',
  RxTransfer = 'RX_TRANSFER'
}

export enum FundingType {
  FullyFunded = 'FULLY_FUNDED',
  LevelFunded = 'LEVEL_FUNDED',
  SelfFunded = 'SELF_FUNDED'
}

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER',
  Unknown = 'UNKNOWN'
}

export enum GenderIdentity {
  Man = 'MAN',
  NonBinary = 'NON_BINARY',
  NotListed = 'NOT_LISTED',
  NotShared = 'NOT_SHARED',
  TransMan = 'TRANS_MAN',
  TransWoman = 'TRANS_WOMAN',
  Woman = 'WOMAN'
}

export type HospitalAffiliation = {
  __typename?: 'HospitalAffiliation';
  admittingPrivileges?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  npi?: Maybe<Scalars['String']['output']>;
};

export type IdentityVerificationResponse = {
  __typename?: 'IdentityVerificationResponse';
  accessToken: Scalars['String']['output'];
  expiresAt: Scalars['String']['output'];
};

export type InNetworkCost = {
  __typename?: 'InNetworkCost';
  allowableAmount: Scalars['Int']['output'];
  curativeCost: Scalars['Int']['output'];
  note?: Maybe<Scalars['String']['output']>;
  patientCost: Scalars['Int']['output'];
  provider: CostProvider;
};

export type InNetworkCostPage = {
  __typename?: 'InNetworkCostPage';
  items: Array<InNetworkCost>;
  next?: Maybe<Scalars['String']['output']>;
  pageSize: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type IndividualApptPart = {
  __typename?: 'IndividualApptPart';
  careTeamMember: CareTeamMember;
  durationInMinutes: Scalars['Int']['output'];
  endDateTime: Scalars['DateTime']['output'];
  scheduledApptTypeId: Scalars['Int']['output'];
  startDateTime: Scalars['DateTime']['output'];
};

export type IndividualApptPartInput = {
  careTeamMember: CareTeamMemberInput;
  durationInMinutes: Scalars['Int']['input'];
  endDateTime: Scalars['DateTime']['input'];
  scheduledApptTypeId: Scalars['Int']['input'];
  startDateTime: Scalars['DateTime']['input'];
};

export type InvitedLegalAgreements = {
  __typename?: 'InvitedLegalAgreements';
  attemptsLeft?: Maybe<Scalars['Int']['output']>;
  legalAgreements: Array<LegalAgreement>;
  memberFirstName?: Maybe<Scalars['String']['output']>;
  preferredLanguage?: Maybe<Language>;
};

export type Invoice = {
  __typename?: 'Invoice';
  createdAt: Scalars['DateTime']['output'];
  createdById: Scalars['Int']['output'];
  creator: User;
  customer: Customer;
  customerId: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  finalizedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  invoiceLineItems: Array<InvoiceLineItem>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  patientMemberId: Scalars['String']['output'];
  paymentUrl?: Maybe<Scalars['String']['output']>;
  status: InvoiceStatus;
  stripeInvoiceId?: Maybe<Scalars['String']['output']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']['output']>;
  subscriberMemberId: Scalars['String']['output'];
  totalAmount: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type InvoiceLineItem = {
  __typename?: 'InvoiceLineItem';
  copay: Scalars['Float']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  fdbRoutedDoseFormGenericId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  invoiceId: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  rxNumber: Scalars['String']['output'];
  unit: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum InvoiceStatus {
  Draft = 'DRAFT',
  Open = 'OPEN',
  Paid = 'PAID',
  Uncollectible = 'UNCOLLECTIBLE',
  Void = 'VOID'
}

export enum Language {
  English = 'ENGLISH',
  Spanish = 'SPANISH'
}

export type LanguagePreferences = {
  __typename?: 'LanguagePreferences';
  language: Language;
};

export type LanguagePreferencesInput = {
  language: Language;
};

export type LegalAgreement = {
  __typename?: 'LegalAgreement';
  agreementMarkdown?: Maybe<Scalars['String']['output']>;
  allowedSignatureResponseTypes: Array<SignatureResponseType>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isCurrent: Scalars['Boolean']['output'];
  language: Language;
  /** Indicates whether user must be 18 years old to be eligible to sign a legal agreement */
  requiresEligibility: Scalars['Boolean']['output'];
  signatureType: SignatureType;
  summary: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LegalAgreementSignatureStatus = {
  __typename?: 'LegalAgreementSignatureStatus';
  hasSignedAgreement: Scalars['Boolean']['output'];
  signatureType: SignatureType;
};

export enum LibertyPrescriptionWorkflowLocation {
  Courier = 'COURIER',
  CourierNextDay = 'COURIER_NEXT_DAY',
  InternalNextDay = 'INTERNAL_NEXT_DAY',
  InternalSameDay = 'INTERNAL_SAME_DAY',
  NoResponse = 'NO_RESPONSE',
  VoidFill = 'VOID_FILL'
}

export type LicenseNumber = {
  __typename?: 'LicenseNumber';
  licenseNumber?: Maybe<Scalars['String']['output']>;
  licenseState?: Maybe<Scalars['String']['output']>;
};

export enum ManagingGroupId {
  BaselineClinicianTeam = 'BASELINE_CLINICIAN_TEAM',
  CareNavigatorManagers = 'CARE_NAVIGATOR_MANAGERS',
  Chimera = 'CHIMERA',
  ClinicalDocumentManagementTeam = 'CLINICAL_DOCUMENT_MANAGEMENT_TEAM',
  ClinicianManagers = 'CLINICIAN_MANAGERS',
  EmployerPortalSuperusersGroup = 'EMPLOYER_PORTAL_SUPERUSERS_GROUP',
  EmployerUserManagementTeam = 'EMPLOYER_USER_MANAGEMENT_TEAM',
  EngineeringTeam = 'ENGINEERING_TEAM',
  HealthPlanTeam = 'HEALTH_PLAN_TEAM',
  MemberServicesTeam = 'MEMBER_SERVICES_TEAM',
  PermissionManagementTeam = 'PERMISSION_MANAGEMENT_TEAM',
  PharmacyTeam = 'PHARMACY_TEAM',
  ProviderFacilityEditingTeam = 'PROVIDER_FACILITY_EDITING_TEAM',
  /** @deprecated Use CLINICIAN_MANAGERS, CARE_NAVIGATOR_MANAGERS, or BASELINE_CLINICIAN_TEAM instead */
  ProviderManagementTeam = 'PROVIDER_MANAGEMENT_TEAM',
  /** @deprecated Use CLINICIAN_MANAGERS, CARE_NAVIGATOR_MANAGERS, or BASELINE_CLINICIAN_TEAM instead */
  ProviderSchedulingTeam = 'PROVIDER_SCHEDULING_TEAM',
  TpaTeam = 'TPA_TEAM'
}

export enum MaritalStatus {
  DivorcedSeparated = 'DIVORCED_SEPARATED',
  Married = 'MARRIED',
  NotListed = 'NOT_LISTED',
  NotShared = 'NOT_SHARED',
  Single = 'SINGLE',
  UnmarriedPartner = 'UNMARRIED_PARTNER',
  Widowed = 'WIDOWED'
}

export type Member = CommonMemberGql & {
  __typename?: 'Member';
  accumulator?: Maybe<PlanYearAccumulator>;
  activeEnrollment?: Maybe<Enrollment>;
  /** Returns Member's address if exists, or Member subscriber's address otherwise */
  address?: Maybe<MemberAddress>;
  /** Returns the all member's plan years */
  allMemberPlanYears: Array<MemberPlanYear>;
  birthDate: Scalars['Date']['output'];
  /** Returns the associated Care Navigator, if any */
  careNavigator?: Maybe<CareNavigator>;
  /** Returns Claims for the Member. This is an Admin Portal only endpoint. */
  claims: Array<Claim>;
  /** Returns the member's current plan year, otherwise get the next plan year */
  closestMemberPlanYear?: Maybe<MemberPlanYear>;
  closestPlan: Plan;
  /** Returns Coverage Period which includes Displayed Enrollment */
  displayedCoveragePeriod: CoveragePeriod;
  displayedEnrollment: Enrollment;
  /** Returns the member's enrollment data based on member's current plan year if available, or the next plan year */
  displayedEnrollmentFromClosestMemberPlanYear: Enrollment;
  eSignatures: Array<ESignature>;
  email?: Maybe<Scalars['String']['output']>;
  /** @deprecated The term externalId is being phased out, replace with id */
  externalId: Scalars['String']['output'];
  familyName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  givenName?: Maybe<Scalars['String']['output']>;
  hasActiveEnrollment: Scalars['Boolean']['output'];
  hasClinicalDocuments: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isMinor: Scalars['Boolean']['output'];
  isMinorAtEffectiveDate: Scalars['Boolean']['output'];
  /** Returns the member's policyholder visibility status */
  isPolicyholderVisibilityEnabled: Scalars['Boolean']['output'];
  lastInvitedToPortalAt?: Maybe<Scalars['DateTime']['output']>;
  /** Returns the member's next plan year, otherwise get the current plan year */
  latestMemberPlanYear?: Maybe<MemberPlanYear>;
  /** Returns a presigned S3 URL for the member card of the current Member */
  memberCardUrl?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  paymentCardholder?: Maybe<MemberPaymentCardholder>;
  paymentCards: Array<MemberPaymentCard>;
  planYears: Array<Scalars['Int']['output']>;
  portalRegistrationStatus: MemberPortalRegistrationStatus;
  preferences: MemberPreferences;
  relationship: Relationship;
  sex?: Maybe<Scalars['String']['output']>;
  /** Returns Member subscriber's address. The value is the same for all members in the same group */
  subscriberAddress: MemberAddress;
  subscriberGroup: SubscriberGroup;
  subscriberId: Scalars['String']['output'];
};


export type MemberAccumulatorArgs = {
  planYear: Scalars['Int']['input'];
};


export type MemberIsMinorArgs = {
  atDate?: InputMaybe<Scalars['Date']['input']>;
};


export type MemberMemberCardUrlArgs = {
  displayType?: InputMaybe<MemberCardDisplayType>;
  fileType?: InputMaybe<MemberCardOutputFileType>;
  urlDurationInMinutes?: InputMaybe<Scalars['Long']['input']>;
  urlType?: InputMaybe<MemberCardS3UrlType>;
};

export type MemberAddress = {
  __typename?: 'MemberAddress';
  cellPhone?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  homePhone?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  street: Scalars['String']['output'];
  street2?: Maybe<Scalars['String']['output']>;
  workPhone?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

export type MemberBaselineVisitState = {
  __typename?: 'MemberBaselineVisitState';
  currentState: Scalars['BaselineVisitState']['output'];
  id: Scalars['Int']['output'];
  planYearId: Scalars['Int']['output'];
  state: MemberBaselineVisitStateJsonNode;
};

export type MemberBaselineVisitStateJsonNode = {
  __typename?: 'MemberBaselineVisitStateJsonNode';
  states: Array<MemberBaselineVisitStateJsonNode>;
  status: Status;
  type: BaselineVisitStateType;
};

export enum MemberCardDisplayType {
  Back = 'BACK',
  BothSides = 'BOTH_SIDES',
  Front = 'FRONT'
}

export enum MemberCardOutputFileType {
  Jpeg = 'JPEG',
  Pdf = 'PDF',
  Png = 'PNG'
}

export enum MemberCardS3UrlType {
  Display = 'DISPLAY',
  Download = 'DOWNLOAD',
  Print = 'PRINT'
}

export type MemberEhr = {
  __typename?: 'MemberEhr';
  elationPatientId: Scalars['Long']['output'];
  memberId: Scalars['String']['output'];
};

export type MemberForm = {
  __typename?: 'MemberForm';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  formData: Scalars['String']['output'];
  formType: FormType;
  id: Scalars['Int']['output'];
  isCurrent: Scalars['Boolean']['output'];
  language: Language;
  uiSchema?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type MemberFormCompletionStatus = {
  __typename?: 'MemberFormCompletionStatus';
  formType: FormType;
  hasCompletedForm: Scalars['Boolean']['output'];
};

export type MemberFormResponse = {
  __typename?: 'MemberFormResponse';
  createdAt: Scalars['DateTime']['output'];
  formId: Scalars['Int']['output'];
  formResponse: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isValid: Scalars['Boolean']['output'];
  memberForm?: Maybe<MemberForm>;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
};

export type MemberFormResponseCache = {
  __typename?: 'MemberFormResponseCache';
  createdAt: Scalars['DateTime']['output'];
  formId: Scalars['Int']['output'];
  formResponse: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
};

export type MemberPaymentCard = {
  __typename?: 'MemberPaymentCard';
  cardType: MemberPaymentCardType;
  hasActiveSpendApproval: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  status: MemberPaymentCardStatus;
  stripeId: Scalars['String']['output'];
};

export enum MemberPaymentCardStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Inactive = 'INACTIVE'
}

export enum MemberPaymentCardType {
  Physical = 'PHYSICAL',
  Virtual = 'VIRTUAL'
}

export type MemberPaymentCardholder = {
  __typename?: 'MemberPaymentCardholder';
  creationError?: Maybe<Scalars['String']['output']>;
  stripeId?: Maybe<Scalars['String']['output']>;
};

export type MemberPlanYear = {
  __typename?: 'MemberPlanYear';
  baselineVisitStatus: BaselineVisitStatus;
  claimSystem: ClaimSystem;
  cobraStartDate?: Maybe<Scalars['Date']['output']>;
  divisionId: Scalars['String']['output'];
  employerEndAt: Scalars['DateTime']['output'];
  employerEndDate: Scalars['Date']['output'];
  employerName?: Maybe<Scalars['String']['output']>;
  employerStartAt: Scalars['DateTime']['output'];
  employerStartDate: Scalars['Date']['output'];
  enrollmentTimezone: Scalars['String']['output'];
  isLateEnroll: Scalars['Boolean']['output'];
  isPlanHighDeductible: Scalars['Boolean']['output'];
  latestPlanId: Scalars['String']['output'];
  latestTier: Scalars['String']['output'];
  memberEndAt: Scalars['DateTime']['output'];
  memberEndDate: Scalars['Date']['output'];
  memberId: Scalars['String']['output'];
  memberStartAt: Scalars['DateTime']['output'];
  memberStartDate: Scalars['Date']['output'];
  memberStartDateAge: Scalars['Int']['output'];
  originalPlanId: Scalars['String']['output'];
  planCoveragePackage?: Maybe<CoveragePackage>;
  planFundingType: FundingType;
  planYear: Scalars['Int']['output'];
  status: EnrollmentStatus;
  subscriberId: Scalars['String']['output'];
};

export enum MemberPortalRegistrationStatus {
  Ineligible = 'INELIGIBLE',
  NotRegistered = 'NOT_REGISTERED',
  Registered = 'REGISTERED'
}

export type MemberPreferences = {
  __typename?: 'MemberPreferences';
  preferredContactMethod?: Maybe<ContactMethod>;
  preferredLanguage?: Maybe<Language>;
  subscribedToNonTransactionalEmails: Scalars['Boolean']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Cancel the baseline visit for a User */
  cancelBaselineVisitScheduledAppointment: Scalars['Boolean']['output'];
  /** Cancel prescription delivery request record by access token and member details */
  cancelPrescriptionDeliveryRequestByAccessTokenAndMemberDetails: PrescriptionDeliveryRequest;
  /** Complete onboarding for the authenticated user. */
  completeOnboarding: User;
  /** Confirm prescription delivery request record by access token and member details */
  confirmPrescriptionDeliveryRequestByAccessTokenAndMemberDetails: PrescriptionDeliveryRequest;
  /** Create new ESignature from invite without authentication */
  createESignatureFromInvite: ESignature;
  /** Create new ESignatures */
  createESignatures: Array<ESignature>;
  /** Create a prior authorization request */
  createMedicalPriorAuthorizationRequest: PriorAuthorizationRequest;
  /** Create new EHR record for a User */
  createMemberEhrUser?: Maybe<MemberEhr>;
  /** Create a new Member Form Response */
  createMemberFormResponse?: Maybe<MemberFormResponse>;
  createPriorAuthSupportUploadURL: PresignedUploadData;
  /** Create a prior authorization request */
  createRxPriorAuthorizationRequest: PriorAuthorizationRequest;
  /** Creates a Stripe API ephemeral key to display a member's card */
  createStripeEphemeralKey: Scalars['String']['output'];
  logError: Scalars['Boolean']['output'];
  logRequestHeaders: Scalars['Boolean']['output'];
  logRequestIp: Scalars['Boolean']['output'];
  /** Schedule baseline visit */
  scheduleBaselineVisitProviderAvailability: Visit;
  /** Send an email to dependent members inviting them to register for the member portal - triggered by member portal user */
  sendDependentInvitations: Scalars['Boolean']['output'];
  /** Send an email requesting a new Insurance Card */
  sendMemberCardEmails: Scalars['Boolean']['output'];
  /** Send an email requesting a new Insurance Card */
  sendRequestForInsuranceCardEmail: Scalars['Boolean']['output'];
  /** Create a new User with an Auth0 login */
  signUp: User;
  /** Submit feedback for provider search */
  submitProviderSearchFeedback: Scalars['Boolean']['output'];
  /** Create a new RxTransfer Form Response */
  submitRxTransferForm: RxTransfer;
  /** Create a new RxTransfer Form Response */
  submitRxTransferFormPublic: RxTransfer;
  throwError: Scalars['Boolean']['output'];
  /** Update active User */
  updateActiveUser?: Maybe<User>;
  /** Update active User preferred language */
  updateActiveUserPreferredLanguage?: Maybe<User>;
  /** Unsubscribes the member from non-transactional emails by updating their preference */
  updateEmailPreferences: EmailSubscriptionPreferences;
  /** Updates the preferred language for the member by updating their preference */
  updateLanguagePreferences: LanguagePreferences;
  /** Update the member's baseline visit state, and return a new state */
  updateMemberBaselineVisitState: MemberBaselineVisitState;
  /** Update an existing Member Form Response */
  updateMemberFormResponse?: Maybe<MemberFormResponse>;
  /** Upsert a new Member Form Response to the cache */
  upsertMemberFormResponseCache?: Maybe<MemberFormResponseCache>;
  /** Verify the identity of a member and generate an access token for them to use */
  verifyIdentity: IdentityVerificationResponse;
};


export type MutationCancelPrescriptionDeliveryRequestByAccessTokenAndMemberDetailsArgs = {
  accessToken: Scalars['String']['input'];
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  familyName: Scalars['String']['input'];
  prescriptionDeliveryRequestId: Scalars['Int']['input'];
};


export type MutationConfirmPrescriptionDeliveryRequestByAccessTokenAndMemberDetailsArgs = {
  accessToken: Scalars['String']['input'];
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  confirmPrescriptionDeliveryRequest: ConfirmPrescriptionDeliveryRequestInput;
  externalId?: InputMaybe<Scalars['String']['input']>;
  familyName: Scalars['String']['input'];
};


export type MutationCreateESignatureFromInviteArgs = {
  request: CreateInvitedESignatureRequestInput;
};


export type MutationCreateESignaturesArgs = {
  newESignatures: Array<CreateESignatureRequestInput>;
};


export type MutationCreateMedicalPriorAuthorizationRequestArgs = {
  admissionDate?: InputMaybe<Scalars['Date']['input']>;
  diagnoses: Array<PriorAuthorizationRequestDiagnosisInput>;
  isAutoRelated: Scalars['Boolean']['input'];
  isElective: Scalars['Boolean']['input'];
  isOtherInsuranceRelated: Scalars['Boolean']['input'];
  isUrgent: Scalars['Boolean']['input'];
  isWorkRelated: Scalars['Boolean']['input'];
  memberExternalId: Scalars['String']['input'];
  notes: Scalars['String']['input'];
  procedureType: ProcedureType;
  procedures: Array<PriorAuthorizationRequestProcedureInput>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  referringProviderFederalTaxId?: InputMaybe<Scalars['String']['input']>;
  referringProviderId?: InputMaybe<Scalars['String']['input']>;
  s3Keys: Array<Scalars['String']['input']>;
  submittedDate: Scalars['Date']['input'];
  type: PriorAuthorizationTypeInput;
  validFromDate: Scalars['Date']['input'];
  validToDate: Scalars['Date']['input'];
};


export type MutationCreateMemberFormResponseArgs = {
  memberFormResponse: NewMemberFormResponseInput;
};


export type MutationCreatePriorAuthSupportUploadUrlArgs = {
  fileName: Scalars['String']['input'];
};


export type MutationCreateRxPriorAuthorizationRequestArgs = {
  requestForm: PriorAuthorizationRequestFormInput;
};


export type MutationCreateStripeEphemeralKeyArgs = {
  nonce: Scalars['String']['input'];
  stripeCardId: Scalars['String']['input'];
};


export type MutationScheduleBaselineVisitProviderAvailabilityArgs = {
  apptPartList?: InputMaybe<Array<IndividualApptPartInput>>;
  location: VisitLocation;
  physicianId: Scalars['Long']['input'];
  selectedAppointmentLanguage: Language;
  startDateTime: Scalars['DateTime']['input'];
  visitTypeParam?: InputMaybe<VisitTypeInfoInput>;
};


export type MutationSendDependentInvitationsArgs = {
  dependentInvitationInputs: Array<DependentInvitationInput>;
};


export type MutationSendMemberCardEmailsArgs = {
  emailAddresses: Array<Scalars['String']['input']>;
  memberId: Scalars['String']['input'];
};


export type MutationSendRequestForInsuranceCardEmailArgs = {
  memberId: Scalars['String']['input'];
};


export type MutationSignUpArgs = {
  request: SignUpRequestInput;
};


export type MutationSubmitProviderSearchFeedbackArgs = {
  browserUrl: Scalars['String']['input'];
  facilityId?: InputMaybe<Scalars['String']['input']>;
  feedback: Scalars['String']['input'];
  providerId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSubmitRxTransferFormArgs = {
  formResponse: Scalars['String']['input'];
};


export type MutationSubmitRxTransferFormPublicArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  formResponse: Scalars['String']['input'];
};


export type MutationUpdateActiveUserArgs = {
  userAttributes: UpdateUserRequestInput;
};


export type MutationUpdateActiveUserPreferredLanguageArgs = {
  preferredLanguage: Language;
};


export type MutationUpdateEmailPreferencesArgs = {
  inviteId: Scalars['UUID']['input'];
  requestedUpdatedPreferences: EmailSubscriptionPreferencesInput;
  templateName: Scalars['String']['input'];
};


export type MutationUpdateLanguagePreferencesArgs = {
  inviteId: Scalars['UUID']['input'];
  requestedUpdatedPreferences: LanguagePreferencesInput;
};


export type MutationUpdateMemberBaselineVisitStateArgs = {
  event: BaselineVisitEventInput;
};


export type MutationUpdateMemberFormResponseArgs = {
  memberFormResponse: UpdateMemberFormResponseRequestInput;
};


export type MutationUpsertMemberFormResponseCacheArgs = {
  memberFormResponse: UpsertMemberFormResponseCacheRequestInput;
};


export type MutationVerifyIdentityArgs = {
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  familyName: Scalars['String']['input'];
  memberId?: InputMaybe<Scalars['String']['input']>;
};

export enum NetworkType {
  CurativePpo = 'CURATIVE_PPO',
  FirstHealth = 'FIRST_HEALTH',
  FirstHealthComplementary = 'FIRST_HEALTH_COMPLEMENTARY'
}

export type NewMemberFormResponseInput = {
  formId: Scalars['Int']['input'];
  formResponse: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type Office = {
  __typename?: 'Office';
  city?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  faxNumbersList?: Maybe<Array<Scalars['String']['output']>>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  npi?: Maybe<Scalars['String']['output']>;
  officeId: Scalars['String']['output'];
  officeName: Scalars['String']['output'];
  point?: Maybe<Point>;
  providerNetworksList?: Maybe<Array<Scalars['String']['output']>>;
  searchRelevance?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
  telephonesList?: Maybe<Array<Scalars['String']['output']>>;
  websitesList?: Maybe<Array<Scalars['String']['output']>>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type OfficeEntry = {
  __typename?: 'OfficeEntry';
  id?: Maybe<Scalars['String']['output']>;
  isAcceptingNewPatients?: Maybe<Scalars['Boolean']['output']>;
  isPrimary?: Maybe<Scalars['Boolean']['output']>;
  officeName?: Maybe<Scalars['String']['output']>;
  telephone?: Maybe<Scalars['String']['output']>;
};

export type OfficeResponse = {
  __typename?: 'OfficeResponse';
  count: Scalars['Long']['output'];
  offices: Array<Office>;
};

export enum OnboardingStatusType {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE'
}

export type OutOfNetworkCost = {
  __typename?: 'OutOfNetworkCost';
  allowableAmount: Scalars['Int']['output'];
  curativeCost: Scalars['Int']['output'];
  note?: Maybe<Scalars['String']['output']>;
  patientCost: Scalars['Int']['output'];
};

export type PackagedDrug = {
  __typename?: 'PackagedDrug';
  dispensableDrugId: Scalars['Int']['output'];
  dispensableDrugTallManDesc: Scalars['String']['output'];
  dispensableGenericId: Scalars['Int']['output'];
  drugNameTallmanDesc: Scalars['String']['output'];
  genericDrugNameDesc: Scalars['String']['output'];
  medStrength: Scalars['String']['output'];
  medStrengthUnit: Scalars['String']['output'];
  packagedDrugId: Scalars['Long']['output'];
  routedDoseFormGenericId: Scalars['Int']['output'];
};

export type PageInput = {
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  startFrom?: InputMaybe<Scalars['String']['input']>;
};

export type PaginatedItem = Claim | Facility | Office | Provider;

export type PharmacySearchResultGql = {
  __typename?: 'PharmacySearchResultGQL';
  city: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  state: Scalars['String']['output'];
  street1: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type Plan = {
  __typename?: 'Plan';
  coveragePackage?: Maybe<CoveragePackage>;
  description?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  fundingType?: Maybe<FundingType>;
  hasHighDeductible: Scalars['Boolean']['output'];
  planType?: Maybe<PlanType>;
  planYear?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export enum PlanType {
  Noop = 'NOOP',
  Oop = 'OOP'
}

export type PlanYearAccumulator = {
  __typename?: 'PlanYearAccumulator';
  familyDedInNetMax: Scalars['BigDecimal']['output'];
  familyDedInNetUsed: Scalars['BigDecimal']['output'];
  familyDedOONMax: Scalars['BigDecimal']['output'];
  familyDedOONUsed: Scalars['BigDecimal']['output'];
  familyOOPInNetMax: Scalars['BigDecimal']['output'];
  familyOOPInNetUsed: Scalars['BigDecimal']['output'];
  familyOOPOONMax: Scalars['BigDecimal']['output'];
  familyOOPOONUsed: Scalars['BigDecimal']['output'];
  memberDedInNetMax: Scalars['BigDecimal']['output'];
  memberDedInNetUsed: Scalars['BigDecimal']['output'];
  memberDedOONMax: Scalars['BigDecimal']['output'];
  memberDedOONUsed: Scalars['BigDecimal']['output'];
  memberOOPInNetMax: Scalars['BigDecimal']['output'];
  memberOOPInNetUsed: Scalars['BigDecimal']['output'];
  memberOOPOONMax: Scalars['BigDecimal']['output'];
  memberOOPOONUsed: Scalars['BigDecimal']['output'];
};

export type Point = {
  __typename?: 'Point';
  lat: Scalars['Float']['output'];
  lon: Scalars['Float']['output'];
};

export type Practitioner = {
  __typename?: 'Practitioner';
  address?: Maybe<PractitionerAddress>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  nationalProviderId?: Maybe<Scalars['String']['output']>;
  practitionerId: Scalars['String']['output'];
};

export type PractitionerAddress = {
  __typename?: 'PractitionerAddress';
  addressLine?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  cityName?: Maybe<Scalars['String']['output']>;
  practitionerAddressId: Scalars['String']['output'];
  stateCode?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type PrescriptionDeliveryRequest = {
  __typename?: 'PrescriptionDeliveryRequest';
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  checkoutSessionId?: Maybe<Scalars['String']['output']>;
  checkoutSessionUrl?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  confirmedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deliveryInstructions?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  finalizedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  identityVerifiedAt?: Maybe<Scalars['DateTime']['output']>;
  items: Array<PrescriptionDeliveryRequestItem>;
  patientMemberId: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  requestedDeliveryDate?: Maybe<Scalars['Date']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
  subscriberMemberId: Scalars['String']['output'];
  targetLibertyPrescriptionWorkflowLocation?: Maybe<LibertyPrescriptionWorkflowLocation>;
  totalCopay: Scalars['Float']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type PrescriptionDeliveryRequestItem = {
  __typename?: 'PrescriptionDeliveryRequestItem';
  copay: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isControlledSubstance?: Maybe<Scalars['Boolean']['output']>;
  isRefill: Scalars['Boolean']['output'];
  libertyRxNpi: Scalars['String']['output'];
  libertyRxScriptNumber: Scalars['String']['output'];
  libertyVerifiedAt?: Maybe<Scalars['DateTime']['output']>;
  prescriptionDeliveryRequestId: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  requiresRefrigeration?: Maybe<Scalars['Boolean']['output']>;
  requiresSignatureForDelivery: Scalars['Boolean']['output'];
  unit: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PresignedUploadData = {
  __typename?: 'PresignedUploadData';
  fileName: Scalars['String']['output'];
  s3Key: Scalars['String']['output'];
  uploadUrl: Scalars['String']['output'];
};

export enum PriorAuthStatus {
  NotSetOrUnknown = 'NOT_SET_OR_UNKNOWN',
  VbaApproved = 'VBA_APPROVED',
  VbaDenied = 'VBA_DENIED',
  VbaPartialApproval = 'VBA_PARTIAL_APPROVAL',
  VbaPended = 'VBA_PENDED',
  VbaVoided = 'VBA_VOIDED'
}

export type PriorAuthorizationRequest = {
  __typename?: 'PriorAuthorizationRequest';
  id: Scalars['Int']['output'];
  referenceCode?: Maybe<Scalars['String']['output']>;
  wasSuccessful: Scalars['Boolean']['output'];
};

export type PriorAuthorizationRequestDeviceInput = {
  deviceId: Scalars['String']['input'];
  deviceName: Scalars['String']['input'];
  durationOfUse: Scalars['String']['input'];
};

export type PriorAuthorizationRequestDiagnosisInput = {
  code: Scalars['String']['input'];
  description: Scalars['String']['input'];
};

export type PriorAuthorizationRequestDrugInput = {
  NDC?: InputMaybe<Scalars['String']['input']>;
  daysSupply: Scalars['String']['input'];
  dosePerAdministration?: InputMaybe<Scalars['String']['input']>;
  drugId: Scalars['String']['input'];
  drugName: Scalars['String']['input'];
  expectedTherapyDuration: Scalars['String']['input'];
  isAdherent?: InputMaybe<Scalars['Boolean']['input']>;
  isNewTherapy: Scalars['Boolean']['input'];
  isTherapyEffective?: InputMaybe<Scalars['Boolean']['input']>;
  providerAdministeredDrugNote?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['String']['input'];
  routeOfAdministration: Scalars['String']['input'];
  strength: Scalars['String']['input'];
  therapyInitiatedDate?: InputMaybe<Scalars['Date']['input']>;
};

export type PriorAuthorizationRequestFormInput = {
  attachmentS3Keys: Array<Scalars['String']['input']>;
  date: Scalars['Date']['input'];
  drugsTaken: Array<PriorAuthorizationRequestTakenDrugInput>;
  isUrgent: Scalars['Boolean']['input'];
  justification: Scalars['String']['input'];
  laboratoryValues: Array<PriorAuthorizationRequestLaboratoryValueInput>;
  patient: PriorAuthorizationRequestPatientInput;
  patientClinicalInfo: PriorAuthorizationRequestPatientClinicalInfoInput;
  referringProvider?: InputMaybe<PriorAuthorizationRequestProviderInput>;
  referringProviderId?: InputMaybe<Scalars['String']['input']>;
  requestedDevice?: InputMaybe<PriorAuthorizationRequestDeviceInput>;
  requestedDrug?: InputMaybe<PriorAuthorizationRequestDrugInput>;
  requestingOffice: PriorAuthorizationRequestOfficeInput;
  validFromDate: Scalars['Date']['input'];
  validToDate: Scalars['Date']['input'];
};

export type PriorAuthorizationRequestLaboratoryValueInput = {
  date: Scalars['Date']['input'];
  test: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type PriorAuthorizationRequestOfficeInput = {
  contactName: Scalars['String']['input'];
  contactPhoneNumber: Scalars['String']['input'];
  faxNumber: Scalars['String']['input'];
  federalTaxId?: InputMaybe<Scalars['String']['input']>;
  officeName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type PriorAuthorizationRequestPatientClinicalInfoInput = {
  ICDCode: Scalars['String']['input'];
  ICDVersion: Scalars['String']['input'];
  diagnosis: Scalars['String']['input'];
};

export type PriorAuthorizationRequestPatientInput = {
  birthDate: Scalars['Date']['input'];
  issuerName?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  sex: Scalars['String']['input'];
};

export type PriorAuthorizationRequestProcedureInput = {
  code: Scalars['String']['input'];
  modifier: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  units: Scalars['Int']['input'];
};

export type PriorAuthorizationRequestProviderInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  groupNpi?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  npi: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type PriorAuthorizationRequestTakenDrugInput = {
  drugName: Scalars['String']['input'];
  duration: Scalars['String']['input'];
  frequency: Scalars['String']['input'];
  response: Scalars['String']['input'];
  strength: Scalars['String']['input'];
};

export type PriorAuthorizationStatus = {
  __typename?: 'PriorAuthorizationStatus';
  status: PriorAuthStatus;
};

export type PriorAuthorizationType = {
  __typename?: 'PriorAuthorizationType';
  authType: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export type PriorAuthorizationTypeInput = {
  authType: Scalars['String']['input'];
  description: Scalars['String']['input'];
};

export type ProcedureCode = {
  __typename?: 'ProcedureCode';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  /** Returns the page of out of network costs, optionally filtered by the procedureCosts query */
  inNetworkCosts: InNetworkCostPage;
  outOfNetworkCost?: Maybe<OutOfNetworkCost>;
  plainLanguageDescription?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};


export type ProcedureCodeInNetworkCostsArgs = {
  pageInput?: InputMaybe<PageInput>;
};

export type ProcedureCostInput = {
  procedureCode: Scalars['String']['input'];
  providerNpi?: InputMaybe<Scalars['String']['input']>;
  providerTextQuery?: InputMaybe<Scalars['String']['input']>;
  providerZipCode?: InputMaybe<Scalars['String']['input']>;
  searchArea?: InputMaybe<SearchAreaInput>;
};

export enum ProcedureType {
  Inpatient = 'INPATIENT',
  Outpatient = 'OUTPATIENT'
}

export enum Pronouns {
  HeHim = 'HE_HIM',
  NotListed = 'NOT_LISTED',
  SheHer = 'SHE_HER',
  TheyThem = 'THEY_THEM'
}

export type Provider = {
  __typename?: 'Provider';
  degreesList?: Maybe<Array<ProviderDegree>>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  hospitalAffiliationsList?: Maybe<Array<HospitalAffiliation>>;
  languagesList?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Scalars['String']['output']>;
  licenseNumbersList?: Maybe<Array<LicenseNumber>>;
  npi?: Maybe<Scalars['String']['output']>;
  officeIdsList?: Maybe<Array<OfficeEntry>>;
  providerId: Scalars['String']['output'];
  specialtiesList?: Maybe<Array<Specialty>>;
};

export type ProviderDegree = {
  __typename?: 'ProviderDegree';
  abbreviation?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ProviderFlat = {
  __typename?: 'ProviderFlat';
  city?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  degreesList?: Maybe<Array<ProviderDegree>>;
  faxNumbersList?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  hasSubmittedCurativeClaim?: Maybe<Scalars['Boolean']['output']>;
  hospitalAffiliationsList?: Maybe<Array<HospitalAffiliation>>;
  id: Scalars['String']['output'];
  isAcceptingNewPatients?: Maybe<Scalars['Boolean']['output']>;
  isPrimaryOffice?: Maybe<Scalars['Boolean']['output']>;
  languagesList?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  licenseNumbersList?: Maybe<Array<LicenseNumber>>;
  longitude?: Maybe<Scalars['Float']['output']>;
  npi?: Maybe<Scalars['String']['output']>;
  officeId: Scalars['String']['output'];
  officeIdsList?: Maybe<Array<OfficeEntry>>;
  officeName?: Maybe<Scalars['String']['output']>;
  officeNpi?: Maybe<Scalars['String']['output']>;
  point?: Maybe<Point>;
  providerId: Scalars['String']['output'];
  providerNetworksList?: Maybe<Array<Scalars['String']['output']>>;
  providerSpecialtyList?: Maybe<Array<ProviderSpecialtyDetail>>;
  providerTypeList?: Maybe<Array<Scalars['String']['output']>>;
  searchRelevance?: Maybe<Scalars['Float']['output']>;
  specialtyTypeList?: Maybe<Array<Scalars['String']['output']>>;
  state?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
  telephone?: Maybe<Scalars['String']['output']>;
  telephonesList?: Maybe<Array<Scalars['String']['output']>>;
  websitesList?: Maybe<Array<Scalars['String']['output']>>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type ProviderResponse = {
  __typename?: 'ProviderResponse';
  count: Scalars['Long']['output'];
  providers: Array<ProviderFlat>;
};

export type ProviderSpecialty = {
  __typename?: 'ProviderSpecialty';
  curativeCategory: Scalars['String']['output'];
  curativeSpecialty: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isPopular?: Maybe<Scalars['Boolean']['output']>;
};

export type ProviderSpecialtyDetail = {
  __typename?: 'ProviderSpecialtyDetail';
  curativeCategory?: Maybe<Scalars['String']['output']>;
  curativeSpecialty?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** Fetches the active user based on token */
  activeUser: User;
  /** Fetches appointment slots for baseline visit available to user between the range start and end */
  baselineVisitAppointmentSlotsV4: Array<AppointmentWindowV2>;
  /** Fetches baseline visit settings of active user */
  baselineVisitSettingsByActiveUser: BaselineVisitSettings;
  /** Gets a paginated list of claims for the given member ID. */
  claimsByMemberId: ClaimsPage;
  /** Returns clinical documents for a given member */
  clinicalDocuments: Array<ClinicalDocument>;
  /** Returns the current legal agreements of the inputted signature type and language */
  currentLegalAgreement?: Maybe<LegalAgreement>;
  /** Retrieves the NPI of the pharmacy for internal delivery, or null if ineligible for internal delivery */
  deliveringPharmacyNpi?: Maybe<Scalars['String']['output']>;
  /** Gets a list of dates (assumed to be in the delivering pharmacy's TZ) that are eligible for prescription delivery to a particular zipcode (based on current time), */
  eligiblePrescriptionDeliveryDates: Array<Scalars['Date']['output']>;
  /** Queries for all flat Facility records */
  facilitiesFlat: FacilityResponse;
  /** Returns custom facility type mappings records */
  facilityTypeMappings: Array<FacilityTypeMap>;
  /** Returns a list of distinct records of facility types from all available Facilities */
  facilityTypes: Array<FacilityType>;
  /** Fetches care team member information for scheduling */
  getAllCareTeamMembers: Array<CareTeamMember>;
  /** Upsert a new Member Form Response to the cache */
  getCachedMemberFormResponses: Array<MemberFormResponseCache>;
  /** Retrieves Drugs by Dispensable Drug Id */
  getDrugsByDispensableDrugId: Array<Drug>;
  /** Returns ESignature records, with optional filtering */
  getESignatures: Array<ESignature>;
  /** Returns the status of whether the member has or has not signed a list of legal agreements */
  getLegalAgreementSignatureStatus: Array<LegalAgreementSignatureStatus>;
  /** Get a JSON representation of the member's baseline visit state */
  getMemberBaselineVisitState: MemberBaselineVisitState;
  /** Fetches member email preferences */
  getMemberEmailPreferences: EmailSubscriptionPreferences;
  /** Returns the status of whether the member has or has not completed a list of forms */
  getMemberFormCompletionStatus: Array<MemberFormCompletionStatus>;
  /** Fetches member forms */
  getMemberForms: Array<MemberForm>;
  /** Fetches member language preferences */
  getMemberLanguagePreferences: LanguagePreferences;
  /** Fetches member forms responses */
  getOwnMemberFormResponses: Array<MemberFormResponse>;
  /** Fetches a PackagedDrug from FDB by id */
  getPackagedDrug?: Maybe<PackagedDrug>;
  /** Retrieves Related Drugs - including Therapeutic Alternatives and Biosimilars */
  getRelatedDrugs: Array<Drug>;
  /** Fetches RxTransfer request objects along with the form contents */
  getRxTransfers: Array<RxTransfer>;
  /** Query to get the correct Zoom join link */
  getVisitMeetingInfo: VisitMeetingInfoGql;
  /** Query for checking the health of the GraphQL server */
  health: Scalars['Boolean']['output'];
  /** Returns the legal agreement, in all languages, associated with the given invite */
  invitedLegalAgreements: InvitedLegalAgreements;
  /** Retrieve full invoice record by access token and member details */
  invoiceByAccessTokenAndMemberDetailsPublic: Invoice;
  /** Retrieve invoice record with limited fields by access token */
  invoiceByAccessTokenPublic: BasicInvoice;
  /** Evaluates questionnaire responses for Primary Care eligibility */
  isEligibleForPrimaryCare: Scalars['Boolean']['output'];
  /** Returns true if a Member with the given external ID exists */
  isMemberIdValid: Scalars['Boolean']['output'];
  /** Returns True if the inputted practitioner id matches the federal tax ID in the HRP data warehouse */
  isPractitionerTaxIdValid: Scalars['Boolean']['output'];
  /** Returns True if the inputted national provider ID matches the federal tax ID in the VBA database */
  isProviderTaxIdValid: Scalars['Boolean']['output'];
  /** Returns a list of distinct records of languages from all available Providers */
  languages: Array<Scalars['String']['output']>;
  /** Returns a list of the legal agreements matching the provided criteria */
  legalAgreements: Array<LegalAgreement>;
  /** Check a member's benefits summary using the external ID and 'birth date + family name' for validation */
  memberBenefitsSummary?: Maybe<BenefitsSummary>;
  /** Returns Apple Wallet Member Card string associated with the given member ID */
  memberCardAppleWallet: Scalars['String']['output'];
  /** Returns claim system for a given member ID */
  memberClaimSystem?: Maybe<ClaimSystem>;
  /** Fetches member EHR information on a Member ID for users. */
  memberEhrUser?: Maybe<MemberEhr>;
  /** Verify that there is a valid member record matching an External ID and Date of Birth */
  memberOnboardingInfoVerificationByExternalId: Member;
  /** Verify that there is a member record matching an SSN and Date of Birth */
  memberOnboardingInfoVerificationBySSN: Member;
  /** Retrieve prescription delivery request record by access token */
  prescriptionDeliveryRequestByAccessToken: BasicPrescriptionDeliveryRequest;
  /** Retrieve full prescription delivery request record by access token and member details */
  prescriptionDeliveryRequestByAccessTokenAndMemberDetails: PrescriptionDeliveryRequest;
  /** Look up the status of a submitted prior authorization using a reference code */
  priorAuthorizationStatus?: Maybe<PriorAuthorizationStatus>;
  /** Returns the page of procedure costs, optionally filtered by input values */
  procedureCost?: Maybe<ProcedureCode>;
  /** Returns a list of distinct records of degrees from all available Providers */
  providerDegrees: Array<ProviderDegree>;
  /** Returns a presigned URL to download a Provider Directory PDF by state */
  providerDirectoryPDF: Scalars['String']['output'];
  /** Returns flattened Provider records */
  providersFlat: ProviderResponse;
  /** Returns AuthType records, with optional filtering */
  searchAuthorizationTypes: Array<PriorAuthorizationType>;
  /** Returns diagnosis codes */
  searchDiagnosisCodes: Array<DiagnosisCode>;
  /** Returns AuthType records for GC, with optional filtering */
  searchGCAuthorizationTypes: Array<PriorAuthorizationType>;
  /** Returns Provider records from the HE database, with optional filtering */
  searchHEPractitioners: Array<Practitioner>;
  /** Returns Office records from OpenSearch */
  searchOffices: OfficeResponse;
  /** Searches for formulary drugs using the FDB searchPackagedDrugs Endpoint, returning DispensableDrugs enriched with some PackagedDrug fields */
  searchPackagedDrugs?: Maybe<Array<PackagedDrug>>;
  searchPharmacies: Array<PharmacySearchResultGql>;
  /** Returns CPT procedure codes */
  searchProcedureCodes: Array<ProcedureCode>;
  /** Returns Provider records from the VBA database, with optional filtering */
  searchVBAProviders: Array<VbaProvider>;
  /** Returns a list of distinct specialties from all of the available Providers */
  specialties: Array<ProviderSpecialty>;
  /** Returns custom specialty mappings records */
  specialtyMappings: Array<SpecialtyMap>;
  /** Validate a Single Address */
  validateAddress: Scalars['Boolean']['output'];
  /** Returns a list of distinct records of virtual care facility types */
  virtualCareFacilityTypes: Array<FacilityType>;
};


export type QueryBaselineVisitAppointmentSlotsV4Args = {
  isPrimaryCareBLV: Scalars['Boolean']['input'];
  startDate: Scalars['Date']['input'];
};


export type QueryClaimsByMemberIdArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  dateRangeDays?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  memberId: Scalars['String']['input'];
  providerId?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<ClaimSortOption>;
  statusFilter?: InputMaybe<ClaimStatusFilterInput>;
};


export type QueryClinicalDocumentsArgs = {
  memberId: Scalars['String']['input'];
};


export type QueryCurrentLegalAgreementArgs = {
  language: Language;
  signatureType: SignatureType;
};


export type QueryDeliveringPharmacyNpiArgs = {
  address: AddressInput;
};


export type QueryEligiblePrescriptionDeliveryDatesArgs = {
  address: AddressInput;
  prescriptionDeliveryRequestId: Scalars['Int']['input'];
};


export type QueryFacilitiesFlatArgs = {
  bounds?: InputMaybe<Array<Scalars['Float']['input']>>;
  city?: InputMaybe<Scalars['String']['input']>;
  exactSearch?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  lastId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  networkTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  serviceType?: InputMaybe<Scalars['String']['input']>;
  shouldIncludeVirtualCareFacilitiesOnly?: InputMaybe<Scalars['Boolean']['input']>;
  shouldSearchByNameOnly?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFacilityTypeMappingsArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetCachedMemberFormResponsesArgs = {
  formType: FormType;
};


export type QueryGetDrugsByDispensableDrugIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetESignaturesArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  memberId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetLegalAgreementSignatureStatusArgs = {
  signatureTypes: Array<SignatureType>;
};


export type QueryGetMemberEmailPreferencesArgs = {
  inviteId: Scalars['UUID']['input'];
};


export type QueryGetMemberFormCompletionStatusArgs = {
  formTypes: Array<FormType>;
};


export type QueryGetMemberFormsArgs = {
  formType?: InputMaybe<FormType>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  isCurrent?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
};


export type QueryGetMemberLanguagePreferencesArgs = {
  inviteId: Scalars['UUID']['input'];
};


export type QueryGetOwnMemberFormResponsesArgs = {
  formType?: InputMaybe<FormType>;
};


export type QueryGetPackagedDrugArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGetRelatedDrugsArgs = {
  dispensableGenericId: Scalars['Int']['input'];
  routedDoseFormGenericId: Scalars['Int']['input'];
};


export type QueryGetRxTransfersArgs = {
  memberIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type QueryGetVisitMeetingInfoArgs = {
  idToken: Scalars['UUID']['input'];
};


export type QueryInvitedLegalAgreementsArgs = {
  signatureInvite: Scalars['UUID']['input'];
};


export type QueryInvoiceByAccessTokenAndMemberDetailsPublicArgs = {
  accessToken: Scalars['String']['input'];
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  familyName: Scalars['String']['input'];
};


export type QueryInvoiceByAccessTokenPublicArgs = {
  accessToken: Scalars['String']['input'];
};


export type QueryIsMemberIdValidArgs = {
  externalId: Scalars['String']['input'];
};


export type QueryIsPractitionerTaxIdValidArgs = {
  federalTaxId: Scalars['String']['input'];
  practitionerId: Scalars['String']['input'];
};


export type QueryIsProviderTaxIdValidArgs = {
  federalTaxId: Scalars['String']['input'];
  nationalProviderId: Scalars['String']['input'];
};


export type QueryLegalAgreementsArgs = {
  current?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  language?: InputMaybe<Language>;
  signatureType?: InputMaybe<SignatureType>;
};


export type QueryMemberBenefitsSummaryArgs = {
  birthDate: Scalars['Date']['input'];
  externalId: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
};


export type QueryMemberCardAppleWalletArgs = {
  memberId: Scalars['String']['input'];
};


export type QueryMemberClaimSystemArgs = {
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  memberId: Scalars['String']['input'];
};


export type QueryMemberOnboardingInfoVerificationByExternalIdArgs = {
  birthDate: Scalars['Date']['input'];
  externalId: Scalars['String']['input'];
};


export type QueryMemberOnboardingInfoVerificationBySsnArgs = {
  birthDate: Scalars['Date']['input'];
  socialSecurityNumber: Scalars['String']['input'];
};


export type QueryPrescriptionDeliveryRequestByAccessTokenArgs = {
  accessToken: Scalars['String']['input'];
};


export type QueryPrescriptionDeliveryRequestByAccessTokenAndMemberDetailsArgs = {
  accessToken: Scalars['String']['input'];
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  familyName: Scalars['String']['input'];
};


export type QueryPriorAuthorizationStatusArgs = {
  referenceCode: Scalars['String']['input'];
};


export type QueryProcedureCostArgs = {
  input: ProcedureCostInput;
};


export type QueryProviderDirectoryPdfArgs = {
  state: UsState;
};


export type QueryProvidersFlatArgs = {
  bounds?: InputMaybe<Array<Scalars['Float']['input']>>;
  exactSearch?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  hospitalAffiliation?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isAcceptingNewPatients?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  lastId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  networkTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  officeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  providerTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  shouldSearchByNameOnly?: InputMaybe<Scalars['Boolean']['input']>;
  sortByRelevance?: InputMaybe<Scalars['Boolean']['input']>;
  specialties?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QuerySearchAuthorizationTypesArgs = {
  lastAuthType?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchDiagnosisCodesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  search: Scalars['String']['input'];
};


export type QuerySearchGcAuthorizationTypesArgs = {
  procedureType: ProcedureType;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchHePractitionersArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchOfficesArgs = {
  bounds?: InputMaybe<Array<Scalars['Float']['input']>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  lastId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  networkTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchPackagedDrugsArgs = {
  searchTerm: Scalars['String']['input'];
  shouldSearchMedicalDevices?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySearchPharmaciesArgs = {
  query: Scalars['String']['input'];
  zipcode: Scalars['String']['input'];
};


export type QuerySearchProcedureCodesArgs = {
  onlyCareCostProcedures?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchVbaProvidersArgs = {
  lastMediviewId?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySpecialtyMappingsArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryValidateAddressArgs = {
  address: AddressInput;
};

export enum Race {
  AmericanIndian = 'AMERICAN_INDIAN',
  Asian = 'ASIAN',
  Black = 'BLACK',
  Multiracial = 'MULTIRACIAL',
  NativeHawaiian = 'NATIVE_HAWAIIAN',
  NotListed = 'NOT_LISTED',
  NotShared = 'NOT_SHARED',
  White = 'WHITE'
}

export enum Relationship {
  Accountant = 'ACCOUNTANT',
  ActivitySponsor = 'ACTIVITY_SPONSOR',
  AdoptedChild = 'ADOPTED_CHILD',
  AdoptedDaughter = 'ADOPTED_DAUGHTER',
  AdoptedSon = 'ADOPTED_SON',
  AdoptiveFather = 'ADOPTIVE_FATHER',
  AdoptiveMother = 'ADOPTIVE_MOTHER',
  AdoptiveParents = 'ADOPTIVE_PARENTS',
  Advisor = 'ADVISOR',
  AgencyRepresentative = 'AGENCY_REPRESENTATIVE',
  AlmaMater = 'ALMA_MATER',
  Annuitant = 'ANNUITANT',
  Applicant = 'APPLICANT',
  Aunt = 'AUNT',
  Banker = 'BANKER',
  Betrothed = 'BETROTHED',
  BothParents = 'BOTH_PARENTS',
  Brother = 'BROTHER',
  BrotherInLaw = 'BROTHER_IN_LAW',
  Business = 'BUSINESS',
  BusinessAssociate = 'BUSINESS_ASSOCIATE',
  BusinessInsuranceTrust = 'BUSINESS_INSURANCE_TRUST',
  BusinessPartner = 'BUSINESS_PARTNER',
  CadaverDonor = 'CADAVER_DONOR',
  Charity = 'CHARITY',
  Child = 'CHILD',
  ChildrenOfMarriage = 'CHILDREN_OF_MARRIAGE',
  ChildInLaw = 'CHILD_IN_LAW',
  ChildOfADomesticPartner = 'CHILD_OF_A_DOMESTIC_PARTNER',
  ChildWhereInsuredHasNoFinancialResponsibility = 'CHILD_WHERE_INSURED_HAS_NO_FINANCIAL_RESPONSIBILITY',
  Clergyman = 'CLERGYMAN',
  Client = 'CLIENT',
  ClubOrOrganizationOfficer = 'CLUB_OR_ORGANIZATION_OFFICER',
  Coach = 'COACH',
  CollateralDependent = 'COLLATERAL_DEPENDENT',
  Company = 'COMPANY',
  Corporation = 'CORPORATION',
  CourtAppointedGuardian = 'COURT_APPOINTED_GUARDIAN',
  Cousin = 'COUSIN',
  CoWorker = 'CO_WORKER',
  Creditor = 'CREDITOR',
  Daughter = 'DAUGHTER',
  DaughterInLaw = 'DAUGHTER_IN_LAW',
  Dependent = 'DEPENDENT',
  DependentOfAMinorDependent = 'DEPENDENT_OF_A_MINOR_DEPENDENT',
  Doctor = 'DOCTOR',
  EcclesiasticalOrReligiousLeader = 'ECCLESIASTICAL_OR_RELIGIOUS_LEADER',
  EducatorOrTeacherOrInstructor = 'EDUCATOR_OR_TEACHER_OR_INSTRUCTOR',
  EmancipatedMinor = 'EMANCIPATED_MINOR',
  EmergencyContact = 'EMERGENCY_CONTACT',
  Employee = 'EMPLOYEE',
  Employer = 'EMPLOYER',
  Estate = 'ESTATE',
  ExHusband = 'EX_HUSBAND',
  ExSpouse = 'EX_SPOUSE',
  ExWife = 'EX_WIFE',
  FamilyMember = 'FAMILY_MEMBER',
  Father = 'FATHER',
  FatherInLaw = 'FATHER_IN_LAW',
  FianceFemale = 'FIANCE_FEMALE',
  FianceMale = 'FIANCE_MALE',
  Fiduciary = 'FIDUCIARY',
  FosterChild = 'FOSTER_CHILD',
  FosterDaughter = 'FOSTER_DAUGHTER',
  FosterFather = 'FOSTER_FATHER',
  FosterMother = 'FOSTER_MOTHER',
  FosterParent = 'FOSTER_PARENT',
  FosterSon = 'FOSTER_SON',
  Friend = 'FRIEND',
  GodDaughter = 'GOD_DAUGHTER',
  GodFather = 'GOD_FATHER',
  GodMother = 'GOD_MOTHER',
  GodParents = 'GOD_PARENTS',
  GodSon = 'GOD_SON',
  Grandchild = 'GRANDCHILD',
  Grandchildren = 'GRANDCHILDREN',
  Granddaughter = 'GRANDDAUGHTER',
  Grandfather = 'GRANDFATHER',
  Grandmother = 'GRANDMOTHER',
  Grandparent = 'GRANDPARENT',
  Grandparents = 'GRANDPARENTS',
  Grandson = 'GRANDSON',
  GrandsonOrGranddaughter = 'GRANDSON_OR_GRANDDAUGHTER',
  GreatAunt = 'GREAT_AUNT',
  Guardian = 'GUARDIAN',
  HalfBrother = 'HALF_BROTHER',
  HalfSister = 'HALF_SISTER',
  HandicappedDependent = 'HANDICAPPED_DEPENDENT',
  Husband = 'HUSBAND',
  InjuredPlaintiff = 'INJURED_PLAINTIFF',
  Institution = 'INSTITUTION',
  Insured = 'INSURED',
  Lawyer = 'LAWYER',
  LifePartner = 'LIFE_PARTNER',
  MedicalCareProvider = 'MEDICAL_CARE_PROVIDER',
  MinisterOrPriest = 'MINISTER_OR_PRIEST',
  MortgageHolder = 'MORTGAGE_HOLDER',
  Mother = 'MOTHER',
  MotherInLaw = 'MOTHER_IN_LAW',
  MutuallyDefined = 'MUTUALLY_DEFINED',
  Neighbor = 'NEIGHBOR',
  Nephew = 'NEPHEW',
  NephewOrNiece = 'NEPHEW_OR_NIECE',
  Niece = 'NIECE',
  None = 'NONE',
  NonApplicableIndividualRelationshipCategory = 'NON_APPLICABLE_INDIVIDUAL_RELATIONSHIP_CATEGORY',
  OrganDonor = 'ORGAN_DONOR',
  Other = 'OTHER',
  OtherAdult = 'OTHER_ADULT',
  OtherRelationship = 'OTHER_RELATIONSHIP',
  OtherRelative = 'OTHER_RELATIVE',
  OtherSchoolAdministrator = 'OTHER_SCHOOL_ADMINISTRATOR',
  Owner = 'OWNER',
  Parent = 'PARENT',
  ParentsInLaw = 'PARENTS_IN_LAW',
  ParentInLaw = 'PARENT_IN_LAW',
  Partner = 'PARTNER',
  Partnership = 'PARTNERSHIP',
  Payor = 'PAYOR',
  PersonalInsuranceTrust = 'PERSONAL_INSURANCE_TRUST',
  ProbationOfficer = 'PROBATION_OFFICER',
  SchoolCounselor = 'SCHOOL_COUNSELOR',
  SchoolPrincipal = 'SCHOOL_PRINCIPAL',
  Self = 'SELF',
  Sibling = 'SIBLING',
  SiblingInLaw = 'SIBLING_IN_LAW',
  SignificantOther = 'SIGNIFICANT_OTHER',
  Sister = 'SISTER',
  SisterInLaw = 'SISTER_IN_LAW',
  SoleProprietorship = 'SOLE_PROPRIETORSHIP',
  Son = 'SON',
  SonInLaw = 'SON_IN_LAW',
  SonOrDaughter = 'SON_OR_DAUGHTER',
  SponsoredDependent = 'SPONSORED_DEPENDENT',
  Spouse = 'SPOUSE',
  StateFund = 'STATE_FUND',
  Stepchild = 'STEPCHILD',
  Stepfather = 'STEPFATHER',
  Stepmother = 'STEPMOTHER',
  Stepparent = 'STEPPARENT',
  StepBrother = 'STEP_BROTHER',
  StepChildren = 'STEP_CHILDREN',
  StepDaughter = 'STEP_DAUGHTER',
  StepSister = 'STEP_SISTER',
  StepSon = 'STEP_SON',
  Student = 'STUDENT',
  Supervisor = 'SUPERVISOR',
  Teacher = 'TEACHER',
  Trust = 'TRUST',
  Trustee = 'TRUSTEE',
  Uncle = 'UNCLE',
  UncleOrAunt = 'UNCLE_OR_AUNT',
  Unknown = 'UNKNOWN',
  Ward = 'WARD',
  Widow = 'WIDOW',
  Widower = 'WIDOWER',
  Wife = 'WIFE'
}

export type RxTransfer = {
  __typename?: 'RxTransfer';
  createdAt: Scalars['DateTime']['output'];
  formResponse?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  memberId?: Maybe<Scalars['String']['output']>;
  memberUserId?: Maybe<Scalars['Int']['output']>;
  pharmacyNotes?: Maybe<Scalars['String']['output']>;
  pharmacyTeamUserId?: Maybe<Scalars['Int']['output']>;
  rxTransferFormResponse?: Maybe<MemberFormResponse>;
  rxTransferFormResponseId?: Maybe<Scalars['Int']['output']>;
  status: RxTransferStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export enum RxTransferStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Denied = 'DENIED',
  InProgress = 'IN_PROGRESS',
  Submitted = 'SUBMITTED'
}

export type SearchAreaInput = {
  centerLatitude: Scalars['Float']['input'];
  centerLongitude: Scalars['Float']['input'];
  searchRadius: Scalars['Float']['input'];
};

export type ServiceDetail = {
  __typename?: 'ServiceDetail';
  curativeCategory?: Maybe<Scalars['String']['output']>;
  curativeFacilityType?: Maybe<Scalars['String']['output']>;
  facilityTypeId?: Maybe<Scalars['Int']['output']>;
};

export type SetCommunicationsConsentInput = {
  consent: Scalars['Boolean']['input'];
};

export type SetContactMethodInput = {
  contactMethod: ContactMethod;
};

export type SetDateOfBirthInput = {
  dateOfBirth: Scalars['Date']['input'];
};

export type SetGenderInput = {
  gender: Gender;
};

export type SetPhoneNumberVerificationInput = {
  verificationCode: Scalars['String']['input'];
};

export enum SexAtBirth {
  Female = 'FEMALE',
  Intersex = 'INTERSEX',
  Male = 'MALE',
  NotShared = 'NOT_SHARED'
}

export type SignUpRequestInput = {
  contactPreferences?: InputMaybe<Array<ContactType>>;
  dateOfBirth: Scalars['Date']['input'];
  email: Scalars['String']['input'];
  memberId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  preferredLanguage?: InputMaybe<Language>;
  preferredName?: InputMaybe<Scalars['String']['input']>;
  pronouns?: InputMaybe<Pronouns>;
};

export enum SignatureResponseType {
  Consent = 'consent',
  DoNotConsent = 'do_not_consent',
  HaveReadAndUnderstand = 'have_read_and_understand'
}

export enum SignatureType {
  AssignmentOfBenefits = 'ASSIGNMENT_OF_BENEFITS',
  BaselineVisitAssignmentOfBenefits = 'BASELINE_VISIT_ASSIGNMENT_OF_BENEFITS',
  BaselineVisitCommunicationsConsent = 'BASELINE_VISIT_COMMUNICATIONS_CONSENT',
  BaselineVisitPrivacyConsent = 'BASELINE_VISIT_PRIVACY_CONSENT',
  CommunicationsConsent = 'COMMUNICATIONS_CONSENT',
  ESignatureConsent = 'E_SIGNATURE_CONSENT',
  HealthInformationConsent = 'HEALTH_INFORMATION_CONSENT',
  HipaaConsent = 'HIPAA_CONSENT',
  MemberPaymentCardConsent = 'MEMBER_PAYMENT_CARD_CONSENT',
  PrivacyConsent = 'PRIVACY_CONSENT',
  WellnessProgramNotice = 'WELLNESS_PROGRAM_NOTICE'
}

export type SimpleEmployer = {
  __typename?: 'SimpleEmployer';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Specialty = {
  __typename?: 'Specialty';
  curativeProviderType?: Maybe<Scalars['String']['output']>;
  curativeSpecialty?: Maybe<Scalars['String']['output']>;
  providerSpecialtyId?: Maybe<Scalars['Int']['output']>;
  rawSpecialty?: Maybe<Scalars['String']['output']>;
};

export type SpecialtyMap = {
  __typename?: 'SpecialtyMap';
  createdAt: Scalars['DateTime']['output'];
  curativeCategory?: Maybe<Scalars['String']['output']>;
  curativeProviderType?: Maybe<Scalars['String']['output']>;
  curativeSpecialty?: Maybe<Scalars['String']['output']>;
  editedBy?: Maybe<Scalars['Int']['output']>;
  editedByUser?: Maybe<User>;
  id: Scalars['Int']['output'];
  specId?: Maybe<Scalars['Int']['output']>;
  specialty: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum Status {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE'
}

export type SubscriberGroup = {
  __typename?: 'SubscriberGroup';
  dependents: Array<CommonMemberGql>;
  subscriberId: Scalars['String']['output'];
  /** Returns the member who is also the subscriber for a subscriber group / family. */
  subscriberMember?: Maybe<CommonMemberGql>;
};

export type Tier = {
  __typename?: 'Tier';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum UsState {
  Alabama = 'ALABAMA',
  Alaska = 'ALASKA',
  Arizona = 'ARIZONA',
  Arkansas = 'ARKANSAS',
  California = 'CALIFORNIA',
  Colorado = 'COLORADO',
  Connecticut = 'CONNECTICUT',
  Delaware = 'DELAWARE',
  Florida = 'FLORIDA',
  Georgia = 'GEORGIA',
  Hawaii = 'HAWAII',
  Idaho = 'IDAHO',
  Illinois = 'ILLINOIS',
  Indiana = 'INDIANA',
  Iowa = 'IOWA',
  Kansas = 'KANSAS',
  Kentucky = 'KENTUCKY',
  Louisiana = 'LOUISIANA',
  Maine = 'MAINE',
  Maryland = 'MARYLAND',
  Massachusetts = 'MASSACHUSETTS',
  Michigan = 'MICHIGAN',
  Minnesota = 'MINNESOTA',
  Mississippi = 'MISSISSIPPI',
  Missouri = 'MISSOURI',
  Montana = 'MONTANA',
  Nebraska = 'NEBRASKA',
  Nevada = 'NEVADA',
  NewHampshire = 'NEW_HAMPSHIRE',
  NewJersey = 'NEW_JERSEY',
  NewMexico = 'NEW_MEXICO',
  NewYork = 'NEW_YORK',
  NorthCarolina = 'NORTH_CAROLINA',
  NorthDakota = 'NORTH_DAKOTA',
  Ohio = 'OHIO',
  Oklahoma = 'OKLAHOMA',
  Oregon = 'OREGON',
  Pennsylvania = 'PENNSYLVANIA',
  RhodeIsland = 'RHODE_ISLAND',
  SouthCarolina = 'SOUTH_CAROLINA',
  SouthDakota = 'SOUTH_DAKOTA',
  Tennessee = 'TENNESSEE',
  Texas = 'TEXAS',
  Utah = 'UTAH',
  Vermont = 'VERMONT',
  Virginia = 'VIRGINIA',
  Washington = 'WASHINGTON',
  WestVirginia = 'WEST_VIRGINIA',
  Wisconsin = 'WISCONSIN',
  Wyoming = 'WYOMING'
}

export type UpdateMemberFormResponseRequestInput = {
  formResponse: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateUserRequestInput = {
  ethnicity?: InputMaybe<Ethnicity>;
  genderIdentity?: InputMaybe<GenderIdentity>;
  maritalStatus?: InputMaybe<MaritalStatus>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  preferredLanguage?: InputMaybe<Language>;
  preferredName?: InputMaybe<Scalars['String']['input']>;
  pronouns?: InputMaybe<Pronouns>;
  race?: InputMaybe<Race>;
  sexAtBirth?: InputMaybe<SexAtBirth>;
};

export type UpsertMemberFormResponseCacheRequestInput = {
  formId: Scalars['Int']['input'];
  formResponse: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  addressId?: Maybe<Scalars['Int']['output']>;
  canAccessTelehealth: Scalars['Boolean']['output'];
  canViewEmployerPortalManagement: Scalars['Boolean']['output'];
  canViewMembersDetails: Scalars['Boolean']['output'];
  canViewPermissionsDashboard: Scalars['Boolean']['output'];
  canViewProviderDataManagement: Scalars['Boolean']['output'];
  canViewRxInvoiceTool: Scalars['Boolean']['output'];
  canViewSchedulingTools: Scalars['Boolean']['output'];
  contactPreferences?: Maybe<Array<ContactType>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  employers: Array<Employer>;
  employersAdminsOnly: Array<Employer>;
  ethnicity?: Maybe<Ethnicity>;
  genderIdentity?: Maybe<GenderIdentity>;
  hasEmployerPortalSuperuserAccess: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  maritalStatus?: Maybe<MaritalStatus>;
  member?: Maybe<Member>;
  memberId?: Maybe<Scalars['String']['output']>;
  onboardingStatus: OnboardingStatusType;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  preferences?: Maybe<MemberPreferences>;
  preferredName?: Maybe<Scalars['String']['output']>;
  pronouns?: Maybe<Pronouns>;
  race?: Maybe<Race>;
  selectedEmployer?: Maybe<Employer>;
  sexAtBirth?: Maybe<SexAtBirth>;
  subjectId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userType: UserType;
};

export enum UserType {
  Admin = 'ADMIN',
  Employer = 'EMPLOYER',
  Member = 'MEMBER'
}

export type VbaProvider = {
  __typename?: 'VBAProvider';
  acceptingNewPatients: Scalars['Boolean']['output'];
  address: VbaProviderAddress;
  createdAt: Scalars['DateTime']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  nationalProviderId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vbaId: Scalars['String']['output'];
};

export type VbaProviderAddress = {
  __typename?: 'VBAProviderAddress';
  city?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  suiteNumber?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type Visit = {
  __typename?: 'Visit';
  isVirtual: Scalars['Boolean']['output'];
  leadClinician?: Maybe<Clinician>;
  location: VisitLocation;
  member?: Maybe<Member>;
  memberId: Scalars['String']['output'];
  memberState?: Maybe<Scalars['String']['output']>;
  physician?: Maybe<Scalars['Long']['output']>;
  urlIdToken?: Maybe<Scalars['String']['output']>;
  visitEndAt: Scalars['DateTime']['output'];
  visitLanguage?: Maybe<Language>;
  visitStartAt: Scalars['DateTime']['output'];
  visitTypeInfo: VisitTypeInfo;
};

export enum VisitLocation {
  InPerson = 'IN_PERSON',
  OnSite = 'ON_SITE',
  Virtual = 'VIRTUAL'
}

export type VisitMeetingInfoGql = {
  __typename?: 'VisitMeetingInfoGQL';
  appointmentEndDateTime: Scalars['DateTime']['output'];
  appointmentStartDateTime: Scalars['DateTime']['output'];
  careNavigatorFullName?: Maybe<Scalars['String']['output']>;
  careTeamMemberFullName?: Maybe<Scalars['String']['output']>;
  isVirtual: Scalars['Boolean']['output'];
  meetingType: VisitMeetingType;
  memberElationPatientId?: Maybe<Scalars['Long']['output']>;
  memberFullName?: Maybe<Scalars['String']['output']>;
  memberId: Scalars['String']['output'];
  memberPreferredName?: Maybe<Scalars['String']['output']>;
  urls: VisitUrlInfoGql;
};

export enum VisitMeetingType {
  CareTeamMember = 'CARE_TEAM_MEMBER',
  Member = 'MEMBER'
}

export enum VisitType {
  BaselineVisitFreedomMode = 'BASELINE_VISIT_FREEDOM_MODE',
  BaselineVisitFreedomModeClinicianCareNavigator = 'BASELINE_VISIT_FREEDOM_MODE_CLINICIAN_CARE_NAVIGATOR',
  BaselineVisitNewMember = 'BASELINE_VISIT_NEW_MEMBER',
  BaselineVisitNewMemberNonClinical = 'BASELINE_VISIT_NEW_MEMBER_NON_CLINICAL',
  BaselineVisitPreventative = 'BASELINE_VISIT_PREVENTATIVE',
  BaselineVisitPrimaryCare = 'BASELINE_VISIT_PRIMARY_CARE',
  BaselineVisitRenewal = 'BASELINE_VISIT_RENEWAL',
  BaselineVisitRenewalNonClinical = 'BASELINE_VISIT_RENEWAL_NON_CLINICAL',
  LegacyBaselineVisit = 'LEGACY_BASELINE_VISIT',
  Other = 'OTHER',
  PrimaryCare = 'PRIMARY_CARE',
  PrimaryCareFreedomMode = 'PRIMARY_CARE_FREEDOM_MODE'
}

export type VisitTypeInfo = {
  __typename?: 'VisitTypeInfo';
  visitType: VisitType;
  visitTypeId: Scalars['Int']['output'];
};

export type VisitTypeInfoInput = {
  visitType: VisitType;
  visitTypeId: Scalars['Int']['input'];
};

export type VisitUrlInfoGql = {
  __typename?: 'VisitUrlInfoGQL';
  joinUrl?: Maybe<Scalars['String']['output']>;
  memberUrl?: Maybe<Scalars['String']['output']>;
  startUrl?: Maybe<Scalars['String']['output']>;
};
