import { CoveragePackage } from "../../generated/memberPortalApi.graphql";

export const coveragePackageOptions = {
  [CoveragePackage.Ppo]: "PPO",
  [CoveragePackage.Ppox]: "PPOx",
  [CoveragePackage.Ppop]: "PPO+",
  [CoveragePackage.Epo]: "EPO",
};

export const getCoveragePackageString = (
  coveragePackage: CoveragePackage | undefined | null
) => {
  if (!coveragePackage) return;
  return coveragePackageOptions[coveragePackage];
};
