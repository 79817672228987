import * as Types from '@chp/shared/generated/memberPortalApi.graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutationVariables = Types.Exact<{
  preferredLanguage: Types.Language;
}>;


export type MemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutation = { __typename?: 'Mutation', updateActiveUserPreferredLanguage?: { __typename?: 'User', id: number, preferences?: { __typename?: 'MemberPreferences', preferredLanguage?: Types.Language | null } | null } | null };


export const MemberPortalI18nProvider__UpdateActiveUserPreferredLanguageDocument = gql`
    mutation MemberPortalI18nProvider__UpdateActiveUserPreferredLanguage($preferredLanguage: Language!) {
  updateActiveUserPreferredLanguage(preferredLanguage: $preferredLanguage) {
    id
    preferences {
      preferredLanguage
    }
  }
}
    `;
export type MemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutationFn = Apollo.MutationFunction<MemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutation, MemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutationVariables>;

/**
 * __useMemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutation__
 *
 * To run a mutation, you first call `useMemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [memberPortalI18nProviderUpdateActiveUserPreferredLanguageMutation, { data, loading, error }] = useMemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutation({
 *   variables: {
 *      preferredLanguage: // value for 'preferredLanguage'
 *   },
 * });
 */
export function useMemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutation(baseOptions?: Apollo.MutationHookOptions<MemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutation, MemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutation, MemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutationVariables>(MemberPortalI18nProvider__UpdateActiveUserPreferredLanguageDocument, options);
      }
export type MemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutationHookResult = ReturnType<typeof useMemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutation>;
export type MemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutationResult = Apollo.MutationResult<MemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutation>;
export type MemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutationOptions = Apollo.BaseMutationOptions<MemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutation, MemberPortalI18nProvider__UpdateActiveUserPreferredLanguageMutationVariables>;