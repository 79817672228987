import { LocaleOption } from "@chp/curative_ui";

import { LOCALE_CODE_TO_DISPLAY_NAME, LocaleCode } from "./constants";

export const localeCodeOption = ({
  localeCode,
}: {
  localeCode: LocaleCode;
}): LocaleOption<LocaleCode> => ({
  label: LOCALE_CODE_TO_DISPLAY_NAME[localeCode],
  value: localeCode,
});
