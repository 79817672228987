import clsx from "clsx";
import React from "react";

export type TableCellTextWrapping = "break-all" | "break-words" | "ellipsis";

interface TableCellProps {
  width?: number | string;
  left?: boolean;
  right?: boolean;
  center?: boolean;
  flex?: number;
  textWrapping?: TableCellTextWrapping;
  clickable?: boolean;
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

const TableCell: React.FC<TableCellProps> = ({
  width,
  left,
  right,
  center,
  flex,
  textWrapping = "break-words",
  children,
  className,
  onClick,
}) => {
  const customStyles: React.CSSProperties = {
    width: width ? `${width}%` : undefined,
  };

  const classes = [
    "flex",
    "align-center",
    "justify-start",
    "min-h-[24px]",
    "py-2",
    flex && `flex-${flex}`,
    left && "text-left",
    right && "text-right",
    center && "text-center",
    textWrapping === "ellipsis" &&
      "block overflow-hidden whitespace-normal text-ellipsis",
    textWrapping === "break-all"
      ? "break-all"
      : textWrapping === "break-words"
      ? "break-words"
      : "break-normal",
    className,
  ];

  return (
    <div onClick={onClick} className={clsx(classes)} style={customStyles}>
      {children}
    </div>
  );
};

export default TableCell;
