import { Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment } from "react";

import { FOCUS_CLASS_NAMES, SpacingVariant } from "../../constants";
import { Icon, IconVariant } from "../Icon";
import { Logo, LogoVariant } from "../Logo";
import { CUIComponent } from "../types";
import { DividerItem } from "./DividerItem";
import { ExpandableItem, ExpandableItemProps } from "./ExpandableItem";
import { NavButton, NavButtonProps } from "./NavButton";
import { NavItem, NavItemProps } from "./NavItem";
import { NavLink, NavLinkProps } from "./NavLink";
import { SideNavbarItemProps, SideNavbarItemType } from "./SideNavbar";

interface MobileSideNavbarProps {
  items?: SideNavbarItemProps[];
  secondaryItems?: NavLinkProps[];
  isOpen: boolean;
  onRequestOpen: (arg: boolean) => void;
  openMobileMenuButtonAriaLabel: string;
  closeMobileMenuButtonAriaLabel: string;
}

export const MobileSideNavbar: CUIComponent<MobileSideNavbarProps> = ({
  items = [],
  secondaryItems = [],
  testId = "MobileSideNavbar",
  isOpen,
  onRequestOpen,
  openMobileMenuButtonAriaLabel,
  closeMobileMenuButtonAriaLabel,
}) => {
  return (
    <nav className="z-40" data-testid={testId}>
      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className={clsx(
            "fixed h-screen w-screen",
            "overflow-auto bg-surface-neutral"
          )}
        >
          <div className={clsx("flex flex-col gap-2", "items-start p-4")}>
            <Logo
              variant={LogoVariant.SignalOrangeSymbolOnly}
              height={SpacingVariant.S40}
              altText=""
              className="mb-4 ml-2"
            />
            {items.map((item) => {
              switch (item.type) {
                case SideNavbarItemType.NavItem:
                  return (
                    <NavItem
                      key={item.label}
                      testId={`${testId}__NavItem`}
                      {...(item as NavItemProps)}
                    />
                  );
                case SideNavbarItemType.NavButton:
                  return (
                    <NavButton
                      key={item.label}
                      testId={`${testId}__NavButton`}
                      {...(item as NavButtonProps)}
                    />
                  );
                case SideNavbarItemType.NavLink:
                  return (
                    <NavLink
                      key={item.label}
                      testId={`${testId}__NavLink`}
                      {...(item as NavLinkProps)}
                    />
                  );
                case SideNavbarItemType.ExpandableItem:
                  return (
                    <ExpandableItem
                      key={item.label}
                      testId={`${testId}__ExpandableItem`}
                      {...(item as ExpandableItemProps)}
                    />
                  );
                case SideNavbarItemType.Divider:
                  return <DividerItem key={item.key} />;
                default:
                  return null;
              }
            })}

            {!!secondaryItems?.length && (
              <>
                <div className="my-2 h-0.5 w-full bg-gray-200" />
                {secondaryItems?.map((link, idx) => (
                  <>
                    {/* eslint-disable-next-line */}
                    <NavLink key={idx} {...link} />
                  </>
                ))}
              </>
            )}
          </div>
          {/* NOTE: This invisible div is so that the scrolling area extends behind the 'fixed' row below, so that content at the bottom of the page is accessible */}
          <div className={clsx("invisible h-16")}></div>
          <div
            className={clsx(
              "fixed bottom-0 z-50 w-full p-4",
              "border-t-2 border-solid border-t-gray-200",
              "bg-surface-neutral"
            )}
          >
            <button
              aria-label={closeMobileMenuButtonAriaLabel}
              className={clsx(FOCUS_CLASS_NAMES)}
              type="button"
              onClick={() => onRequestOpen(false)}
            >
              <Icon variant={IconVariant.X} size={SpacingVariant.S24} />
            </button>
          </div>
        </div>
      </Transition>

      <Transition
        show={!isOpen}
        leave="transition duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <button
          className={clsx(
            "fixed bottom-4 left-4",
            "rounded-lg bg-surface-neutral p-2",
            "shadow-md-surround border-2 border-blue-900",
            FOCUS_CLASS_NAMES
          )}
          type="button"
          aria-label={openMobileMenuButtonAriaLabel}
          onClick={() => onRequestOpen(true)}
        >
          <Icon variant={IconVariant.MENU} size={SpacingVariant.S16} />
        </button>
      </Transition>
    </nav>
  );
};
