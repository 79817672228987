import { WidgetProps } from "@rjsf/utils";

import { FormattedTextField } from "../../FormattedTextField";

export const BaseInput = (props: WidgetProps) => {
  const {
    id,
    options,
    label,
    value,
    type,
    placeholder,
    required,
    disabled,
    onChange,
    onBlur,
    onFocus,
    rawErrors,
    hideError,
  } = props;
  const onTextChange = (val: string) => {
    // Use the options.emptyValue if it is specified and newVal is also an empty string
    onChange(val === "" ? options.emptyValue : val);
  };
  const onTextBlur = ({
    target: { value: val },
  }: React.FocusEvent<HTMLInputElement>) => onBlur(id, val);
  const onTextFocus = ({
    target: { value: val },
  }: React.FocusEvent<HTMLInputElement>) => onFocus(id, val);
  const hasError = rawErrors && rawErrors.length > 0 && !hideError;

  return (
    <FormattedTextField
      id={id}
      placeholder={placeholder}
      label={required ? `${label}*` : label}
      value={value}
      type={type}
      isDisabled={disabled}
      isRequired={required}
      errorMessage={hasError ? rawErrors[0] : undefined}
      onRequestChangeValue={onTextChange}
      onBlur={onTextBlur}
      onFocus={onTextFocus}
    />
  );
};
