import clsx from "clsx";
import { PropsWithChildren } from "react";

import { Box } from "..";
import { CUIComponentProps } from "../types";

export type ScreenReaderOnlyProps<T extends keyof JSX.IntrinsicElements> =
  JSX.IntrinsicElements[T] &
    CUIComponentProps<
      PropsWithChildren<{
        element: T;
      }>
    >;

/**
 * ScreenReaderOnly hides an element visually without hiding it from screen readers
 */
export const ScreenReaderOnly = <T extends keyof JSX.IntrinsicElements>(
  props: ScreenReaderOnlyProps<T>
): JSX.Element => {
  const { className, testId = "ScreenReaderOnly", ...rest } = props;
  return (
    <Box
      className={clsx("sr-only", className)}
      data-testid={testId}
      {...rest}
    />
  );
};
