export enum LocaleCode {
  EN = "en",
  ES = "es",
}

export const DEFAULT_LOCALE_CODE = LocaleCode.EN;

export const LOCALE_CODE_TO_DISPLAY_NAME: Record<LocaleCode, string> = {
  [LocaleCode.EN]: "English",
  [LocaleCode.ES]: "Español",
};

export const LOCALE_CODES_EN_ES: LocaleCode[] = [LocaleCode.EN, LocaleCode.ES];
