import { useWithArrayKeys } from "@chp/curative_ui";
import { createEscapedRegExp, generateArrayKey } from "@chp/shared/utils";
import { FC, Fragment, useMemo } from "react";

export type TextWithStyledSubstringProps = {
  className?: string;
  substringClassname: string;
  fullText: string;
  substring: string;
};

// From https://stackoverflow.com/questions/51022834/react-jsx-make-substring-in-bold
export const TextWithStyledSubstring: FC<TextWithStyledSubstringProps> = ({
  className,
  substringClassname,
  fullText,
  substring,
}) => {
  const allSubstrings = useMemo<string[]>(
    () => fullText.split(createEscapedRegExp(substring, "ig")),
    [fullText, substring]
  );
  const allSubstringsWithArrayKeys = useWithArrayKeys({
    array: allSubstrings,
    keyGenerator: generateArrayKey,
  });
  const match = fullText.match(createEscapedRegExp(substring, "ig"));
  const lastSubstringIndex = allSubstrings.length - 1;

  return (
    <span className={className}>
      {allSubstringsWithArrayKeys.map(({ item, key }, index) => (
        <Fragment key={key}>
          {item !== "" && item}
          {index !== lastSubstringIndex && match && (
            <span className={substringClassname}>{match[index]}</span>
          )}
        </Fragment>
      ))}
    </span>
  );
};
