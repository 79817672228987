import { useContentful } from "@chp/shared/contexts/ContentfulContext";
import { useEffect, useState } from "react";

export const useContentfulAsset = (assetName: string): string => {
  const [assetUrl, setAssetUrl] = useState<string>("");
  const { getMediaUrl } = useContentful();

  useEffect(() => {
    const fetchAndSetUrl = async () => {
      try {
        const url = await getMediaUrl(assetName);
        setAssetUrl(url);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAndSetUrl();
  }, [assetName, getMediaUrl]);

  return assetUrl;
};
