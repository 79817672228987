import { Navbar, NavbarProps } from "@chp/curative_ui";
import { useIntl } from "react-intl";

export type FormattedNavbarProps = Omit<
  NavbarProps,
  "closeMenuButtonAriaLabel" | "logoAltText" | "openMenuButtonAriaLabel"
>;

export const FormattedNavbar = ({
  testId = "FormattedNavbar",
  ...rest
}: FormattedNavbarProps): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <Navbar
      closeMenuButtonAriaLabel={formatMessage({
        defaultMessage: "Close menu",
        id: "dg8ZEb",
      })}
      logoAltText={formatMessage({
        defaultMessage: "Curative Logo",
        id: "IwZB3x",
      })}
      openMenuButtonAriaLabel={formatMessage({
        defaultMessage: "Open menu",
        id: "bdcMGy",
      })}
      testId={testId}
      {...rest}
    />
  );
};
