import {
  Enrollment,
  EnrollmentStatus,
} from "../../generated/memberPortalApi.graphql";

export const checkCoverage = (
  displayedEnrollment: Pick<Enrollment, "status"> | null | undefined
) => {
  return {
    hasFutureCoverage:
      displayedEnrollment?.status === EnrollmentStatus.Upcoming,
    hasExpiredCoverage:
      displayedEnrollment?.status === EnrollmentStatus.Expired,
    hasActiveCoverage: displayedEnrollment?.status === EnrollmentStatus.Active,
    hasCancelledCoverage:
      displayedEnrollment?.status === EnrollmentStatus.Cancelled,
  };
};
