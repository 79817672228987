import { IntlFormatters } from "@formatjs/intl/src/types";
import { ReactNode } from "react";

import { CoveragePackage } from "../../generated/memberPortalApi.graphql";

export enum BenefitsCategory {
  BEHAVIORAL_HEALTH = "BEHAVIORAL_HEALTH",
  EMERGENCY_CARE = "EMERGENCY_CARE",
  EXTENDED_CARE = "EXTENDED_CARE",
  INPATIENT_CARE = "INPATIENT_CARE",
  MEMBER_ELIGIBILITY = "MEMBER_ELIGIBILITY",
  OBSTETRICAL_CARE = "OBSTETRICAL_CARE",
  OFFICE_SERVICES = "OFFICE_SERVICES",
  OTHER_SERVICES = "OTHER_SERVICES",
  OUT_OF_POCKET_COSTS = "OUT_OF_POCKET_COSTS",
  OUTPATIENT_CARE = "OUTPATIENT_CARE",
  PHARMACY_BENEFITS = "PHARMACY_BENEFITS",
  THERAPY = "THERAPY",
}

// values for formatMessage
const v = {
  br: <br />,
};

export type BenefitsCategoryContent = {
  rows: ReactNode[][];
  description?: string;
  title: string;
};

export type PlanOOPValues = {
  annualDeductiblePerson: string;
  annualDeductibleFamily: string;
  coinsurancePercentage: string;
  annualOOPMaxPerson: string;
  annualOOPMaxFamily: string;
};
const ppoXOONValues: PlanOOPValues = {
  annualDeductiblePerson: "30,000",
  annualDeductibleFamily: "40,000",
  coinsurancePercentage: "50",
  annualOOPMaxPerson: "30,000",
  annualOOPMaxFamily: "40,000",
};
const ppoPlusOONValues: PlanOOPValues = {
  annualDeductiblePerson: "5,000",
  annualDeductibleFamily: "10,000",
  coinsurancePercentage: "20",
  annualOOPMaxPerson: "7,500",
  annualOOPMaxFamily: "15,000",
};
const ppoOONValues: PlanOOPValues = {
  annualDeductiblePerson: "10,000",
  annualDeductibleFamily: "20,000",
  coinsurancePercentage: "50",
  annualOOPMaxPerson: "15,000",
  annualOOPMaxFamily: "30,000",
};

const getPlanValues = (
  coveragePackage?: CoveragePackage | null
): PlanOOPValues => {
  switch (coveragePackage) {
    case CoveragePackage.Ppo:
      return ppoOONValues;
    case CoveragePackage.Ppop:
      return ppoPlusOONValues;
    case CoveragePackage.Ppox:
      return ppoXOONValues;
    default:
      return ppoOONValues;
  }
};

/**
 * Content from "Member Benefits Summary 2023" https://drive.google.com/file/d/1QhuukCE443NMFYa0wvQxCscADwoHC14J/view?ts=6388d24e
 *
 * Formatted into TypeScript/i18n code with spreadsheet formula like:
 * = SUBSTITUTE("formatMessage({ defaultMessage: """ & source!A2 & """ }, v),", CHAR(10), "{br}")
 *
 * * runs content through react intl formatter
 * * replaces plain text line break with token
 */
export const benefitsCategoryContent = ({
  formatMessage,
  coveragePackage,
}: {
  formatMessage: IntlFormatters["formatMessage"];
  coveragePackage?: CoveragePackage | null;
}): Record<BenefitsCategory, BenefitsCategoryContent> => {
  const planValues = getPlanValues(coveragePackage);
  const {
    annualDeductibleFamily,
    annualDeductiblePerson,
    coinsurancePercentage,
    annualOOPMaxFamily,
    annualOOPMaxPerson,
  } = planValues;
  return {
    [BenefitsCategory.OUT_OF_POCKET_COSTS]: {
      title: formatMessage({
        defaultMessage: "Out-of-pocket costs",
        id: "ZMFM3D",
      }),
      rows: [
        [
          formatMessage(
            { defaultMessage: "Annual deductible", id: "KsU+GB" },
            v
          ),
          formatMessage({ defaultMessage: "$0", id: "Fg6Vy9" }, v),
          formatMessage(
            {
              defaultMessage: "$5,000/person{br}$10,000/family",
              id: "IQNfEQ",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "${deductiblePerson}/person{br}${deductibleFamily}/family",
              id: "WcVEF/",
            },
            {
              ...v,
              deductiblePerson: annualDeductiblePerson,
              deductibleFamily: annualDeductibleFamily,
            }
          ),
        ],
        [
          formatMessage(
            { defaultMessage: "Coinsurance percentage", id: "1O/V5o" },
            v
          ),
          formatMessage({ defaultMessage: "0%", id: "5b/kGk" }, v),
          formatMessage(
            {
              defaultMessage:
                "20% Medical;{br}25% Pharmacy{br}(with $25/mo cap on insulin)",
              id: "zcWyhw",
            },
            v
          ),
          formatMessage(
            { defaultMessage: "{coinsurancePercentage}%", id: "uxkJ7Z" },
            { ...v, coinsurancePercentage }
          ),
        ],
        [
          formatMessage(
            {
              defaultMessage: "Annual out-of-pocket maximum{br}(Medical)",
              id: "wRAipM",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0", id: "Fg6Vy9" }, v),
          formatMessage(
            { defaultMessage: "$7,500/person{br}$15,000/family", id: "vhF3nc" },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "${annualOOPMaxPerson}/person{br}${annualOOPMaxFamily}/family",
              id: "hJE84z",
            },
            {
              ...v,
              annualOOPMaxPerson,
              annualOOPMaxFamily,
            }
          ),
        ],
        [
          formatMessage(
            { defaultMessage: "Lifetime maximum benefit", id: "dbofjS" },
            v
          ),
          formatMessage({ defaultMessage: "No limit", id: "PfW4AI" }, v),
          formatMessage({ defaultMessage: "No limit", id: "PfW4AI" }, v),
          formatMessage({ defaultMessage: "No limit", id: "PfW4AI" }, v),
        ],
      ],
    },
    [BenefitsCategory.OFFICE_SERVICES]: {
      title: formatMessage({ defaultMessage: "Office services", id: "66mkt1" }),
      rows: [
        [
          formatMessage(
            { defaultMessage: "Preventative care", id: "DZSfEJ" },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage({ defaultMessage: "$50 copay", id: "XDZjId" }, v),
        ],
        [
          formatMessage(
            {
              defaultMessage:
                "Child immunizations for children under the age of 6 covered at 100%",
              id: "rw2eyl",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
        ],
        [
          formatMessage(
            {
              defaultMessage:
                "Office/virtual visit - family practice, internal medicine OB/GYN, pediatrics",
              id: "H5KljI",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            { defaultMessage: "$25 copay after deductible", id: "hvKdJa" },
            v
          ),
          formatMessage(
            { defaultMessage: "$50 copay after deductible", id: "55DlSX" },
            v
          ),
        ],
        [
          formatMessage(
            { defaultMessage: "Specialist office/virtual visit", id: "ASlLfF" },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            { defaultMessage: "$50 copay after deductible", id: "55DlSX" },
            v
          ),
          formatMessage(
            { defaultMessage: "$100 copay after deductible", id: "3NT7yA" },
            v
          ),
        ],
        [
          formatMessage(
            {
              defaultMessage:
                "Telemedicine - Urgent Care with a 24/7/365 on demand doctor visit",
              id: "lQibzo",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage({ defaultMessage: "No coverage", id: "0R8LNC" }, v),
        ],
        [
          formatMessage({ defaultMessage: "Urgent Care", id: "U/Da+o" }, v),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage({ defaultMessage: "Lab and X-Ray", id: "F9VI8f" }, v),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage({ defaultMessage: "Other tests", id: "b6e3aR" }, v),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage({ defaultMessage: "Allergy testing", id: "bwcVi0" }, v),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            {
              defaultMessage:
                "Allergy serum/injections{br}(if no office visit billed)",
              id: "tC8Q6+",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
      ],
    },
    [BenefitsCategory.PHARMACY_BENEFITS]: {
      title: formatMessage({
        defaultMessage: "Pharmacy benefits",
        id: "uJLLQy",
      }),
      description: formatMessage({
        defaultMessage:
          "(a $25/month cap on cost-sharing obligations applies for insulin included on the Curative Drug Formulary)",
        id: "lBmVz9",
      }),
      rows: [
        [
          formatMessage(
            {
              defaultMessage:
                "Preferred drugs{br}(includes certain generic, brand name, & specialty drugs)",
              id: "V4qjr8",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            { defaultMessage: "$50 copay after deductible", id: "55DlSX" },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            {
              defaultMessage:
                "Non-preferred brand name & generic drugs{br}(annual max out-of-pocket)",
              id: "8ZvZ6M",
            },
            v
          ),
          formatMessage({ defaultMessage: "$50 copay", id: "XDZjId" }, v),
          formatMessage(
            { defaultMessage: "$100 copay after deductible", id: "3NT7yA" },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            {
              defaultMessage:
                "Non-preferred specialty drugs{br}(annual max out-of-pocket)",
              id: "qJUw7R",
            },
            v
          ),
          formatMessage({ defaultMessage: "$250 copay", id: "M5ZQc/" }, v),
          formatMessage(
            {
              defaultMessage: "25% coinsurance{br}after deductible",
              id: "jvwJWS",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
      ],
    },
    [BenefitsCategory.EMERGENCY_CARE]: {
      title: formatMessage({ defaultMessage: "Emergency care", id: "6sW/Ph" }),
      description: formatMessage({
        defaultMessage: "(limited to services in the United States)",
        id: "OY6oUe",
      }),
      rows: [
        [
          formatMessage(
            { defaultMessage: "Ambulance service", id: "AbsPSt" },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
        ],
        [
          formatMessage(
            {
              defaultMessage: "Hospital / Freestanding Emergency Room",
              id: "gLLWv7",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
        ],
        [
          formatMessage(
            { defaultMessage: "Emergency Room physicians", id: "kuq2g8" },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
        ],
      ],
    },
    [BenefitsCategory.OUTPATIENT_CARE]: {
      title: formatMessage({ defaultMessage: "Outpatient care", id: "0dzAfv" }),
      rows: [
        [
          formatMessage({ defaultMessage: "Observation", id: "oVLHje" }, v),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            { defaultMessage: "Surgery - facility", id: "GJrMR0" },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            { defaultMessage: "Surgery - physician", id: "tVCkzF" },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage({ defaultMessage: "Lab and X-Ray", id: "F9VI8f" }, v),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            { defaultMessage: "Advanced imaging scans", id: "uuawWt" },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage({ defaultMessage: "Other tests", id: "b6e3aR" }, v),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            {
              defaultMessage: "Outpatient procedures in physician’s office",
              id: "tvDKh/",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
      ],
    },
    [BenefitsCategory.INPATIENT_CARE]: {
      title: formatMessage({ defaultMessage: "Inpatient care", id: "LeXn8G" }),
      rows: [
        [
          formatMessage(
            {
              defaultMessage: "Hospital - Semi-private room and board",
              id: "CoVMPX",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            { defaultMessage: "Hospital inpatient surgery", id: "6Z9DR3" },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage({ defaultMessage: "Physician", id: "VbvoJk" }, v),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
      ],
    },
    [BenefitsCategory.OBSTETRICAL_CARE]: {
      title: formatMessage({
        defaultMessage: "Obstetrical care",
        id: "MqvkMV",
      }),
      rows: [
        [
          formatMessage(
            {
              defaultMessage: "Prenatal and postnatal care - office visits",
              id: "Tj+Zu0",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage:
                "$25 copay after deductible{br}(first visit only)",
              id: "exl0t1",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            {
              defaultMessage: "Delivery - facility/inpatient care",
              id: "9jPbm6",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            {
              defaultMessage: "Obstetrical care and delivery - physician",
              id: "0fcKP7",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            {
              defaultMessage:
                "Newborn care - covered for first 30 days without enrollment",
              id: "5QR2Ri",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage:
                "20% coinsurance{br}after deductible{br}(covered under mother’s{br}deductible first 30 days)",
              id: "edtwvb",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
      ],
    },
    [BenefitsCategory.THERAPY]: {
      title: formatMessage({ defaultMessage: "Therapy", id: "v9LvPl" }),
      rows: [
        [
          formatMessage(
            { defaultMessage: "Physical therapy", id: "+JhM38" },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            { defaultMessage: "Occupational therapy", id: "rrNQVt" },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            { defaultMessage: "Speech and hearing therapy", id: "TMOBLO" },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            { defaultMessage: "Applied behavioral analysis", id: "97cMGv" },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
      ],
    },
    [BenefitsCategory.EXTENDED_CARE]: {
      title: formatMessage({ defaultMessage: "Extended care", id: "aJvinc" }),
      rows: [
        [
          formatMessage(
            { defaultMessage: "Skilled nursing facility", id: "VtQgx5" },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            { defaultMessage: "Home health care services", id: "7vZXId" },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            { defaultMessage: "Hospice care services", id: "HPQrQf" },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
      ],
    },
    [BenefitsCategory.BEHAVIORAL_HEALTH]: {
      title: formatMessage({
        defaultMessage: "Behavioral health",
        id: "8DmytC",
      }),
      rows: [
        [
          formatMessage(
            {
              defaultMessage: "Mental illness - office/virtual Visit",
              id: "aC6apb",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            { defaultMessage: "$25 copay after deductible", id: "hvKdJa" },
            v
          ),
          formatMessage(
            { defaultMessage: "$50 copay after deductible", id: "55DlSX" },
            v
          ),
        ],
        [
          formatMessage(
            {
              defaultMessage:
                "Mental Illness - Intensive outpatient and partial hospitalization",
              id: "I2GggM",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            { defaultMessage: "Mental illness - inpatient", id: "DNOJD/" },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            {
              defaultMessage: "Substance use disorder - office/virtual visit",
              id: "0vv/kJ",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            { defaultMessage: "$25 copay after deductible", id: "hvKdJa" },
            v
          ),
          formatMessage(
            { defaultMessage: "$50 copay after deductible", id: "55DlSX" },
            v
          ),
        ],
        [
          formatMessage(
            {
              defaultMessage:
                "Substance Use Disorder - Intensive outpatient and partial hospitalization",
              id: "q8QXco",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            {
              defaultMessage: "Substance use disorder - inpatient treatment",
              id: "YWHvzI",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
      ],
    },
    [BenefitsCategory.OTHER_SERVICES]: {
      title: formatMessage({ defaultMessage: "Other services", id: "GBDCvt" }),
      rows: [
        [
          formatMessage(
            { defaultMessage: "Durable medical equipment", id: "SqRDBr" },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            { defaultMessage: "Prosthetic devices", id: "zSM13V" },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage({ defaultMessage: "Hearing aids", id: "ApInM3" }, v),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            {
              defaultMessage:
                "Bariatric surgery{br}(limited to one surgery per lifetime)",
              id: "twHIP6",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            {
              defaultMessage:
                "Breastfeeding support and services{br}(limited to $500 benefit and 1 pump per pregnancy)",
              id: "CuqtuS",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            {
              defaultMessage:
                "Acupuncture{br}(Limited to 20 visits per calendar year)",
              id: "Wv54Wo",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            {
              defaultMessage: "Wigs{br}(limited to $200 annual benefit)",
              id: "hH63tV",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
      ],
    },
    [BenefitsCategory.MEMBER_ELIGIBILITY]: {
      // MEMBER_ELIGIBILITY category does not directly match "Member Benefits Summary 2023", but is a truncated subset
      // of rows from other categories, & should stay in sync for consistency
      title: formatMessage({
        defaultMessage: "Member eligibility",
        id: "1YdJ1K",
      }),
      rows: [
        [
          formatMessage(
            { defaultMessage: "Annual deductible", id: "KsU+GB" },
            v
          ),
          formatMessage({ defaultMessage: "$0", id: "Fg6Vy9" }, v),
          formatMessage(
            { defaultMessage: "$5,000/person{br}$10,000/family", id: "IQNfEQ" },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "${deductiblePerson}/person{br}${deductibleFamily}/family",
              id: "WcVEF/",
            },
            {
              ...v,
              deductiblePerson: annualDeductiblePerson,
              deductibleFamily: annualDeductibleFamily,
            }
          ),
        ],
        [
          formatMessage(
            { defaultMessage: "Coinsurance percentage", id: "1O/V5o" },
            v
          ),
          formatMessage({ defaultMessage: "0%", id: "5b/kGk" }, v),
          formatMessage(
            {
              defaultMessage:
                "20% Medical;{br}25% Pharmacy{br}(with $25/mo cap on insulin)",
              id: "zcWyhw",
            },
            v
          ),
          formatMessage(
            { defaultMessage: "{coinsurancePercentage}%", id: "uxkJ7Z" },
            { ...v, coinsurancePercentage }
          ),
        ],
        [
          formatMessage(
            {
              defaultMessage: "Annual out-of-pocket{br}maximum (Medical)",
              id: "nGfMd1",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0", id: "Fg6Vy9" }, v),
          formatMessage(
            { defaultMessage: "$7,500/person{br}$15,000/family", id: "vhF3nc" },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "${annualOOPMaxPerson}/person{br}${annualOOPMaxFamily}/family",
              id: "hJE84z",
            },
            {
              ...v,
              annualOOPMaxPerson,
              annualOOPMaxFamily,
            }
          ),
        ],
        [
          formatMessage({
            defaultMessage: "Lifetime maximum{br}benefit",
            id: "WXCoK6",
          }),
          formatMessage({ defaultMessage: "No limit", id: "PfW4AI" }, v),
          formatMessage({ defaultMessage: "No limit", id: "PfW4AI" }, v),
          formatMessage({ defaultMessage: "No limit", id: "PfW4AI" }, v),
        ],
        [
          formatMessage(
            { defaultMessage: "Preventative Care", id: "SKzpwv" },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage({ defaultMessage: "$50 copay", id: "XDZjId" }, v),
        ],
        [
          formatMessage(
            {
              defaultMessage:
                "Office/virtual visit -{br}Family Practice{br}Internal Medicine{br}OB/GYN{br}Pediatrics",
              id: "MpggpY",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            { defaultMessage: "$25 copay after deductible", id: "hvKdJa" },
            v
          ),
          formatMessage(
            { defaultMessage: "$50 copay after deductible", id: "55DlSX" },
            v
          ),
        ],
        [
          formatMessage(
            { defaultMessage: "Specialist office/virtual visit", id: "ASlLfF" },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            { defaultMessage: "$50 copay after deductible", id: "55DlSX" },
            v
          ),
          formatMessage(
            { defaultMessage: "$100 copay after deductible", id: "3NT7yA" },
            v
          ),
        ],
        [
          formatMessage({ defaultMessage: "Urgent Care", id: "U/Da+o" }, v),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage:
                "{coinsurancePercentage}% coinsurance{br}after deductible",
              id: "47NtF2",
            },
            {
              ...v,
              coinsurancePercentage,
            }
          ),
        ],
        [
          formatMessage(
            {
              defaultMessage: "Hospital / Freestanding{br}Emergency Room",
              id: "Jr2yWa",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
          formatMessage(
            {
              defaultMessage: "20% coinsurance{br}after deductible",
              id: "bcdUxe",
            },
            v
          ),
        ],
        [
          formatMessage(
            {
              defaultMessage:
                "Telemedicine -{br}Urgent Care with a 24/7/365{br}On-demand doctor visit",
              id: "OuoqUf",
            },
            v
          ),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage({ defaultMessage: "$0 copay", id: "xlSDk6" }, v),
          formatMessage({ defaultMessage: "No coverage", id: "0R8LNC" }, v),
        ],
      ],
    },
  };
};
