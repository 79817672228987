import { RadioVariant } from "@chp/curative_ui";
import { FormCheckbox, FormRadioSelection } from "@chp/shared/components";
import { ESignature } from "@chp/shared/generated/memberPortalApi.graphql";
import { nonNull } from "@chp/shared/utils";
import { RefObject } from "react";
import { Path } from "react-hook-form";

import { useLegalAgreementIntlFormatters } from "../hooks";
import type {
  LegalAgreementFormValues,
  RequiredLegalAgreementProps,
} from "../types";

export type ConsentFieldsProps = {
  ref?: RefObject<HTMLDivElement> | undefined;
  className?: string;
  legalAgreement: RequiredLegalAgreementProps;
  previousESignature?: ESignature | null;
};

export const ConsentFields = <TFormValues extends LegalAgreementFormValues>({
  legalAgreement,
  previousESignature = null,
}: ConsentFieldsProps) => {
  const { getAlreadyAcceptedLabelForTitle, getLabelForSignatureResponseType } =
    useLegalAgreementIntlFormatters();

  if (previousESignature) {
    return (
      <div className="flex w-full flex-col">
        <FormCheckbox
          id={previousESignature.signatureResponseType}
          label={getAlreadyAcceptedLabelForTitle(legalAgreement.title)}
          name={"signatureResponseType" as Path<TFormValues>}
          value={previousESignature.signatureResponseType}
          isChecked
          isDisabled
        />
      </div>
    );
  }

  if (legalAgreement.allowedSignatureResponseTypes.length === 1) {
    const signatureResponseType = nonNull(
      legalAgreement.allowedSignatureResponseTypes[0]
    );

    return (
      <FormCheckbox
        value={signatureResponseType}
        label={getLabelForSignatureResponseType(signatureResponseType)}
        name={"signatureResponseType" as Path<TFormValues>}
        stringToggleOptions={{
          checked: signatureResponseType,
          unchecked: null,
        }}
      />
    );
  }

  return (
    <FormRadioSelection
      testId="ConsentFields"
      name={"signatureResponseType" as Path<TFormValues>}
      label=""
      variant={RadioVariant.NormalColumn}
      options={legalAgreement.allowedSignatureResponseTypes.map(
        (signatureResponseType) => {
          return {
            value: signatureResponseType,
            label: getLabelForSignatureResponseType(signatureResponseType),
          };
        }
      )}
    />
  );
};
