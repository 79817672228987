import {
  FormFieldBaseProps,
  ScreenReaderOnly,
  useHasError,
} from "@chp/curative_ui";
import { useIntl } from "react-intl";

export const useErrorMessageWithPrefix = ({
  errorMessage,
}: Pick<
  FormFieldBaseProps,
  "errorMessage"
>): FormFieldBaseProps["errorMessage"] => {
  const { formatMessage } = useIntl();
  const hasError = useHasError({ errorMessage });
  return hasError
    ? (formatMessage(
        { defaultMessage: "<srOnly>Error:</srOnly> {message}", id: "sInbdU" },
        {
          message: errorMessage,
          srOnly: (prefix) => (
            <ScreenReaderOnly element="span" testId="FormattedErrorPrefix">
              {prefix}
            </ScreenReaderOnly>
          ),
        }
      ) as FormFieldBaseProps["errorMessage"])
    : null;
};
