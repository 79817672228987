import { BaselineVisitEventVariant } from "../../types";

export const getBaselineVisitEventVariant = ({
  isVirtualBaselineVisit,
  hasPhysician,
}: {
  isVirtualBaselineVisit: boolean;
  hasPhysician: boolean;
}): BaselineVisitEventVariant => {
  if (isVirtualBaselineVisit) {
    return hasPhysician
      ? BaselineVisitEventVariant.IS_VIRTUAL_HAS_PHYSICIAN
      : BaselineVisitEventVariant.IS_VIRTUAL_NOT_PHYSICIAN;
  } else {
    return hasPhysician
      ? BaselineVisitEventVariant.NOT_VIRTUAL_HAS_PHYSICIAN
      : BaselineVisitEventVariant.NOT_VIRTUAL_NOT_PHYSICIAN;
  }
};
