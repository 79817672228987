import { Button, ButtonVariant, IconVariant } from "@chp/curative_ui";
import clsx from "clsx";
import {
  FocusEvent as ReactFocusEvent,
  FormEvent as ReactFormEvent,
  useRef,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { FormattedTextField, FormattedTextFieldProps } from "../../components";
import { useSearchFreeTextContext } from "./SearchFreeTextContext";

export type SearchFreeTextSearchInputProps = Omit<
  FormattedTextFieldProps,
  "value" | "buttonCaption" | "buttonIcon" | "onClickButton"
>;

export type SearchFreeTextProps = {
  testId?: string;
  className?: string;
  isClearButtonEnabled?: boolean;
  hideSubmitButton?: boolean;
  onSubmit?: (searchInputValue: string) => void | Promise<void>;
  searchInputProps: SearchFreeTextSearchInputProps;
};

export const SearchFreeText = ({
  testId = "search-freetext",
  className,
  isClearButtonEnabled = false,
  hideSubmitButton = false,
  onSubmit,
  searchInputProps,
}: SearchFreeTextProps) => {
  const { searchInputValue, setSearchInputValue } = useSearchFreeTextContext();

  const { formatMessage } = useIntl();
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref} data-testid={testId} className={className}>
      <form
        data-testid={`${testId}__form`}
        className="md:align-center flex flex-col md:flex-row md:justify-between"
        onSubmit={async (event: ReactFormEvent<HTMLFormElement>) => {
          event.preventDefault();

          await onSubmit?.(searchInputValue);
        }}
      >
        <FormattedTextField
          {...searchInputProps}
          testId={`${testId}__text-field`}
          className={clsx(
            "w-full",
            hideSubmitButton ? "" : "mb-2 md:mb-0 md:mr-2"
          )}
          value={searchInputValue}
          onFocus={(event: ReactFocusEvent<HTMLInputElement>) => {
            searchInputProps.onFocus?.(event);
          }}
          onRequestChangeValue={(value: string) => {
            setSearchInputValue(value);
            searchInputProps.onRequestChangeValue?.(value);
          }}
          buttonCaption={
            isClearButtonEnabled
              ? formatMessage({
                  defaultMessage: "Clear search text",
                  id: "rKQODU",
                })
              : undefined
          }
          buttonIcon={
            isClearButtonEnabled && searchInputValue ? IconVariant.X : undefined
          }
          onClickButton={() => {
            setSearchInputValue("");
            searchInputProps.onRequestChangeValue?.("");
          }}
        />
        {!hideSubmitButton && (
          <Button
            testId={`${testId}__search-button`}
            variant={ButtonVariant.SmallPrimary}
            type="submit"
          >
            <FormattedMessage defaultMessage="Search" id="xmcVZ0" />
          </Button>
        )}
      </form>
    </div>
  );
};
