import clsx from "clsx";
import { FC } from "react";

export enum OrangeArrowIconOpacity {
  SIX = "0.06",
  NINE = "0.09",
  TWELVE = "0.12",
}

export enum OrangeArrowSize {
  XSMALL = "15",
  MEDIUM = "34",
  LARGE = "50",
}

export type OrangeArrowIconProps = {
  opacity: OrangeArrowIconOpacity;
  size?: OrangeArrowSize;
  className?: string;
};

export const OrangeArrowIcon: FC<OrangeArrowIconProps> = ({
  opacity,
  size = OrangeArrowSize.MEDIUM,
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(`w-[${size}px] h-[${size}px]`, "inline-block", className)}
    >
      <path
        opacity={opacity}
        d="M12.7606 0.950001H0.949997V7.56326H12.7606L4.40842 15.9149L9.08565 20.5918L17.4358 12.2402V24.05H24.0495V12.2402L24.05 0.950001H12.7606Z"
        fill="#FC5C50"
      />
    </svg>
  );
};
