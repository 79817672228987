import { useEffect, useState } from "react";

type UseKeyPressOptions = {
  targetKey: string;
  shouldPreventDefault?: boolean;
  shouldStopPropagation?: boolean;
  onKeyPress: () => void;
};

export const useKeyPress = ({
  targetKey,
  shouldPreventDefault = false,
  shouldStopPropagation = false,
  onKeyPress,
}: UseKeyPressOptions) => {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === targetKey) {
        if (shouldPreventDefault) {
          event.preventDefault();
        }
        if (shouldStopPropagation) {
          event.stopPropagation();
        }
        setKeyPressed(true);
        onKeyPress();
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === targetKey) {
        if (shouldPreventDefault) {
          event.preventDefault();
        }
        setKeyPressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [targetKey, shouldPreventDefault, shouldStopPropagation, onKeyPress]);

  return keyPressed;
};
