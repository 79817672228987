/**
 * Shared utilities for story logic, styles, etc.
 */

import { ColorItem } from "@storybook/addon-docs";
import { ReactNode } from "react";

import { Box } from "../components";
import { TextVariant } from "../components/types";
import { SpacingVariant } from "../constants";

export type StoryMainProps = {
  children: ReactNode;
};

export const StoryMain = ({ children }: StoryMainProps) => {
  return (
    <Box element="main" textVariant={TextVariant.LgRegular}>
      {children}
    </Box>
  );
};

type StoryHeaderProps = {
  heading: string;
};

export const StoryH1 = ({ heading }: StoryHeaderProps) => {
  return (
    <Box
      element="h1"
      margin={{ bottom: SpacingVariant.S32 }}
      textVariant={TextVariant["3XlBold"]}
    >
      {heading}
    </Box>
  );
};

export const StoryH2 = ({ heading }: StoryHeaderProps) => {
  return (
    <Box
      element="h2"
      margin={{ bottom: SpacingVariant.S32 }}
      textVariant={TextVariant["2XlBold"]}
    >
      {heading}
    </Box>
  );
};

export const StoryH3 = ({ heading }: StoryHeaderProps) => {
  return (
    <Box
      element="h3"
      margin={{ bottom: SpacingVariant.S16, top: SpacingVariant.S16 }}
      textVariant={TextVariant.XlBold}
    >
      {heading}
    </Box>
  );
};

/**
 * Utils for Colors.stories.mdx
 */

type NestedColor = {
  [key: string]: string;
};

export const isValidColor = (alias: string, color: string) =>
  color.match(/^#/) || color.match(/^rgb/) || alias.match(/color/i);

export const getColorItems = (
  colors: Record<string, string | NestedColor>,
  subtitle: string
) => {
  const items = Object.entries(colors).map(([k, v]) => {
    if (typeof v === "string" && isValidColor(k, v)) {
      return (
        <ColorItem
          key={k}
          title={k}
          subtitle={`${subtitle}.${k}`}
          colors={{ [k]: v }}
        />
      );
    } else if (typeof v === "object") {
      return (
        <ColorItem
          key={k}
          title={k.toUpperCase()}
          subtitle={`colors.${k}.[alias]`}
          colors={Object.entries(v).reduce((acc, [key, value]) => {
            return isValidColor(key, value) ? { ...acc, [key]: value } : acc;
          }, {})}
        />
      );
    }
    return null;
  });
  return items;
};
