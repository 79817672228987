import { RadioSelection, RadioSelectionProps } from "@chp/curative_ui";

import { useErrorMessageWithPrefix } from "../../hooks";

export type FormattedRadioSelectionProps = RadioSelectionProps;

export const FormattedRadioSelection = ({
  errorMessage,
  ...rest
}: FormattedRadioSelectionProps): JSX.Element => {
  const errorWithPrefix = useErrorMessageWithPrefix({ errorMessage });
  return <RadioSelection errorMessage={errorWithPrefix} {...rest} />;
};
