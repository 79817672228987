import clsx from "clsx";

import { formFieldErrorId } from "..";
import {
  FormFieldDescription,
  FormFieldErrorMessage,
  FormFieldLabelText,
  useHasError,
} from "../forms";
import { Radio, RadioVariant } from "../Radio";
import { CUIComponent, CUIComponentProps, FormFieldBaseProps } from "../types";

export type RadioSelectionOption = {
  /* the unique key for this option to refer to it */
  value: string;

  /* the value of the label that appears beside the radio */
  label: string | null;

  /* the value of the label title that can appears beside the radio and on top of the label */
  labelTitle?: string;

  /* whether the radio is disabled or not */
  isDisabled?: boolean;

  /* whether the radio is an error state or not */
  hasError?: boolean;

  /* the test id for the radio */
  testId?: string;
};

export type RadioSelectionProps = FormFieldBaseProps &
  CUIComponentProps<{
    id: string;
    options: Array<RadioSelectionOption>;
    currentlySelectedOption: string | null;
    onRequestToggle: (key: string) => void; //called whenever a new radio is selected
    variant?: RadioVariant;
    testId?: string;
    name?: string;
  }>;

export const RadioSelection: CUIComponent<RadioSelectionProps> = ({
  id,
  className,
  description,
  label,
  isLabelSrOnly,
  options,
  currentlySelectedOption,
  variant = RadioVariant.Normal,
  testId = "RadioSelection",
  onRequestToggle,
  errorMessage,
  name,
}) => {
  const hasError = useHasError({ errorMessage });
  const errorId = formFieldErrorId({ hasError, id });

  return (
    <fieldset
      data-testid={testId}
      className={clsx("flex", "flex-col", className)}
      name={name}
    >
      {(label || description) && (
        <legend
          data-testid={testId ? `${testId}__Legend` : "RadioSelection__Legend"}
          className="mb-2"
        >
          {label && (
            <FormFieldLabelText isLabelSrOnly={isLabelSrOnly} label={label} />
          )}
          {description && (
            <FormFieldDescription
              className={clsx(isLabelSrOnly && "sr-only")}
              description={description}
            />
          )}
        </legend>
      )}

      <div
        data-testid={`${testId}__Options`}
        className={clsx(
          "flex",
          "flex-wrap",
          variant === RadioVariant.Normal
            ? "flex-col sm:flex-row"
            : "w-full flex-col",

          variant === RadioVariant.Control2 && "items-start"
        )}
      >
        {variant === RadioVariant.Control2 ? (
          <div className="space-y-6">
            {options.map((option) => (
              <Radio
                id={`radio-${id}-${option.value}`}
                key={`radio-${id}-${option.value}`}
                name={id}
                {...option}
                isChecked={currentlySelectedOption === option.value}
                onRequestToggle={onRequestToggle}
                variant={variant}
              />
            ))}
          </div>
        ) : (
          options.map((option) => (
            <Radio
              id={`radio-${id}-${option.value}`}
              key={`radio-${id}-${option.value}`}
              name={id}
              {...option}
              isChecked={currentlySelectedOption === option.value}
              onRequestToggle={onRequestToggle}
              variant={variant}
            />
          ))
        )}
      </div>

      {hasError && errorId && (
        <FormFieldErrorMessage
          errorMessage={errorMessage}
          testId={`${testId}__ErrorMessage`}
          id={errorId}
        />
      )}
    </fieldset>
  );
};
