import { IconVariant, linkStyling } from "@chp/curative_ui";
import clsx from "clsx";
import { uniqBy } from "lodash-es";
import React, { useCallback } from "react";
import { FieldValues, Path } from "react-hook-form";
import { useIntl } from "react-intl";

import { FormTypeahead } from "../typeahead";
import { useDrugSearchTypeahead__SearchPackagedDrugsLazyQuery } from "./memberPortalApi-drugSearchTypeahead.generated";

const HAS_DROPDOWN = false;
const QUERY_MIN_LENGTH = 1;

const prefixIcon: IconVariant = IconVariant.SEARCH;

export interface Props<T extends FieldValues> {
  label: string;
  name: Path<T>;
  testId?: string;
  className?: string;
  placeholder?: string;
  disableCreateNewOption?: boolean;
  shouldSearchMedicalDevices?: boolean;
}

const ensureNonNullString = (s: string | undefined): string => {
  return s && s !== "null" ? s : "";
};
export const useFetchDrugOptions = () => {
  const { formatMessage } = useIntl();

  const [searchDrugs, { loading: isLoading }] =
    useDrugSearchTypeahead__SearchPackagedDrugsLazyQuery();

  return {
    isLoading: isLoading,
    fetchDrugOptions: useCallback(
      async (queryString: string, shouldSearchMedicalDevices?: boolean) => {
        const { data, error } = await searchDrugs({
          variables: {
            searchTerm: queryString,
            shouldSearchMedicalDevices,
          },
        });

        if (error || !data || !data.searchPackagedDrugs) {
          throw new Error(
            formatMessage({
              defaultMessage: "Unable to perform drug search",
              id: "Gs0+H3",
            })
          );
        }

        return uniqBy(
          data.searchPackagedDrugs.map((drug) => {
            return {
              label: `${drug.drugNameTallmanDesc} ${ensureNonNullString(
                drug.medStrength
              )} ${ensureNonNullString(drug.medStrengthUnit)}`,
              value: `${drug.drugNameTallmanDesc} ${ensureNonNullString(
                drug.medStrength
              )} ${ensureNonNullString(drug.medStrengthUnit)}`,
              label2: drug.packagedDrugId.toString(),
            };
          }),
          (drug) => drug.value
        );
      },
      [formatMessage, searchDrugs]
    ),
  };
};
export const DrugSearchTypeahead = <T extends FieldValues>({
  ...props
}: Props<T>): JSX.Element => {
  const { isLoading, fetchDrugOptions } = useFetchDrugOptions();

  const memoizedFetchDrugOptions = useCallback(
    (query: string) =>
      fetchDrugOptions(query, props.shouldSearchMedicalDevices),
    [props.shouldSearchMedicalDevices, fetchDrugOptions]
  );

  return (
    <FormTypeahead<T>
      {...props}
      disableEnterWhileLoading
      alwaysDisplayCreateNewOption
      fetchOptions={memoizedFetchDrugOptions}
      hasDropdownToggle={HAS_DROPDOWN}
      isLoading={isLoading}
      createNewOptionText={
        props.disableCreateNewOption
          ? undefined
          : (queryString: string) => (
              <p>
                <span>Don’t see your medication?</span>{" "}
                <span className={clsx(linkStyling, "!inline-block")}>
                  Use "{queryString}"
                </span>
              </p>
            )
      }
      optionKeyExtractor={(option) => {
        return option.value;
      }}
      prefixIcon={prefixIcon}
      queryStringMinLength={QUERY_MIN_LENGTH}
    />
  );
};
