import { startCase } from "lodash-es";

import { UsState } from "../generated/memberPortalApi.graphql";

export * from "./auth0";
export * from "./categories";
export * from "./phoneNumbers";
export * from "./urls";

export const AUSTIN_ADDRESS = "900 Congress Ave, Austin";
export const AUSTIN_TIMEZONE = "America/Chicago";

export const AUTH_TOKEN_STORAGE_KEY = "token";
export const DEFAULT_LOCALE = "en";
export const DEFAULT_TIMEZONE = "America/Chicago";
export const HEALTH_PLAN_CLAIMS_SUBMISSION_ADDRESS = {
  street1: "P. O. Box 1786",
  city: "Austin",
  state: "TX",
  zipCode: 78767,
};
export const HEALTH_PLAN_CUSTOMER_SUPPORT_EMAIL_ADDRESS = "health@curative.com";
export const EMPLOYER_PORTAL_CUSTOMER_SUPPORT_EMAIL =
  "AccountSupport@curative.com";
export const PHARMACY_SUPPORT_EMAIL_ADDRESS = "pharmacy@curative.com";

export const LATITUDE_MAX = 90;
export const LATITUDE_MIN = -90;
export const LONGITUDE_MAX = 180;
export const LONGITUDE_MIN = -180;

// member max lengths should match API validation rules in updateEmployee
export const MEMBER_ADDRESS_MAX_LENGTH: number = 128;
export const MEMBER_ADDRESS_2_MAX_LENGTH: number = 128;
export const MEMBER_CITY_MAX_LENGTH: number = 32;
export const MEMBER_EMAIL_MAX_LENGTH: number = 128;
export const MEMBER_FAMILY_NAME_MAX_LENGTH: number = 25;
export const MEMBER_GIVEN_NAME_MAX_LENGTH: number = 25;
export const MEMBER_MIDDLE_NAME_MAX_LENGTH: number = 25;
export const MEMBER_ZIP_CODE_MAX_LENGTH: number = 32;
export const DELIVERY_INSTRUCTIONS_MAX_LENGTH: number = 255;

export const SESSION_ID_HEADER_NAME = "x-session-id";
export const CLIENT_NAME_HEADER_NAME = "x-client-name";
export const SESSION_ID_STORAGE_KEY = "sessionId";

// Contentful Assets
// PDFs
export const COVID_TEST_CLAIM_FORM_ASSET_NAME =
  "COVID-19 test reimbursement request";
export const ACCESS_PHI_REQUEST_FORM_ASSET_NAME = "Access PHI request";
export const REQUEST_PHI_DISCLOSURES_FORM_ASSET_NAME =
  "Accounting of PHI disclosures request";
export const AMEND_PHI_REQUEST_FORM_ASSET_NAME = "Amend PHI request";
export const PRIVACY_COMPLAINT_FORM_ASSET_NAME =
  "Privacy and security complaint";
export const CONFIDENTIAL_COMMUNICATION_REQUEST_FORM_ASSET_NAME =
  "Confidential communication request";
export const OON_REIMBURSEMENT_REQUEST_FORM_ASSET_NAME =
  "Out-of-Network reimbursement request";

// Benefits Booklets (PDFs)
// Self/Level-funded PPO
export const SELF_FUNDED_PPO_BENEFITS_BOOKLET_ASSET_NAME =
  "Self/Level-funded PPO Benefits Booklet 2023";
// Self/Level-funded PPO+
export const SELF_FUNDED_PPOP_BENEFITS_BOOKLET_ASSET_NAME =
  "Self/Level-funded PPO+ Benefits Booklet 2023";
// Self/Level-funded EPO
export const SELF_FUNDED_EPO_BENEFITS_BOOKLET_ASSET_NAME =
  "Self/Level-funded EPO Benefits Booklet 2023";
// Fully-funded PPO
export const FULLY_FUNDED_PPO_BENEFITS_BOOKLET_ASSET_NAME =
  "Fully-funded PPO Benefits Booklet 2023";
// Fully-funded PPO+
export const FULLY_FUNDED_PPOP_BENEFITS_BOOKLET_ASSET_NAME =
  "Fully-funded PPO+ Benefits Booklet 2023";
// Fully-funded EPO
export const FULLY_FUNDED_EPO_BENEFITS_BOOKLET_ASSET_NAME =
  "Fully-funded EPO Benefits Booklet 2023";
// Fully-funded PPOx
export const FULLY_FUNDED_PPOX_BENEFITS_BOOKLET_ASSET_NAME =
  "Fully-funded PPOx Benefits Booklet 2023";

// Benefits Booklets TEAM (PDFs)
// Fully-funded PPO
export const FULLY_FUNDED_PPO_BENEFITS_BOOKLET_ASSET_NAME_TEAM =
  "Fully-funded PPO Benefits Booklet TEAM";
// Fully-funded EPO
export const FULLY_FUNDED_EPO_BENEFITS_BOOKLET_ASSET_NAME_TEAM =
  "Fully-funded EPO Benefits Booklet TEAM";
// Fully-funded PPOx
export const FULLY_FUNDED_PPOX_BENEFITS_BOOKLET_ASSET_NAME_TEAM =
  "Fully-funded PPOx Benefits Booklet TEAM";

// Benefits Booklets 2024 (PDFs)
// Self/Level-funded PPO
export const SELF_FUNDED_PPO_BENEFITS_BOOKLET_ASSET_NAME_2024 =
  "Self/Level-funded PPO Benefits Booklet 2024";
// Self/Level-funded PPO+
export const SELF_FUNDED_PPOP_BENEFITS_BOOKLET_ASSET_NAME_2024 =
  "Self/Level-funded PPO+ Benefits Booklet 2024";
// Self/Level-funded PPOx
export const SELF_FUNDED_PPOX_BENEFITS_BOOKLET_ASSET_NAME_2024 =
  "Self/Level-funded PPOx Benefits Booklet 2024";
// Self/Level-funded EPO
export const SELF_FUNDED_EPO_BENEFITS_BOOKLET_ASSET_NAME_2024 =
  "Self/Level-funded EPO Benefits Booklet 2024";
// Fully-funded PPO
export const FULLY_FUNDED_PPO_BENEFITS_BOOKLET_ASSET_NAME_2024 =
  "Fully-funded PPO Benefits Booklet 2024";
// Fully-funded PPO+
export const FULLY_FUNDED_PPOP_BENEFITS_BOOKLET_ASSET_NAME_2024 =
  "Fully-funded PPO+ Benefits Booklet 2024";
// Fully-funded EPO
export const FULLY_FUNDED_EPO_BENEFITS_BOOKLET_ASSET_NAME_2024 =
  "Fully-funded EPO Benefits Booklet 2024";
// Fully-funded PPOx
export const FULLY_FUNDED_PPOX_BENEFITS_BOOKLET_ASSET_NAME_2024 =
  "Fully-funded PPOx Benefits Booklet 2024";

// Benefits Booklets 2024 (PDFs) - Florida
// Fully-funded PPO - Florida
export const FULLY_FUNDED_PPO_BENEFITS_BOOKLET_ASSET_NAME_FL =
  "Fully-funded PPO Benefits Booklet Florida 2024";
// Fully-funded PPO+ - Florida
export const FULLY_FUNDED_PPOP_BENEFITS_BOOKLET_ASSET_NAME_FL =
  "Fully-funded PPO+ Benefits Booklet Florida 2024";
// Fully-funded EPO - Florida
export const FULLY_FUNDED_EPO_BENEFITS_BOOKLET_ASSET_NAME_FL =
  "Fully-funded EPO Benefits Booklet Florida 2024";
// Fully-funded PPOx - Florida
export const FULLY_FUNDED_PPOX_BENEFITS_BOOKLET_ASSET_NAME_FL =
  "Fully-funded PPOx Benefits Booklet Florida 2024";

// Images
export const FIRST_HEALTH_LOGO_ASSET_NAME = "First Health Network Logo";
export const COFINITY_LOGO_ASSET_NAME = "Cofinity Logo";
export const FCOM_LOGO_ASSET_NAME = "FCOM Logo";

export const enum ErrorCode {
  "ADMIN_PORTAL_AUTH/NO_SUCH_USER" = "no_such_user",
  "ONBOARDING_INFO_VERIFICATION/USER_ALREADY_EXISTS_FOR_MEMBER" = "ONBOARDING_INFO_VERIFICATION/USER_ALREADY_EXISTS_FOR_MEMBER",
  "ONBOARDING_INFO_VERIFICATION/MEMBER_DETAILS_INCORRECT" = "ONBOARDING_INFO_VERIFICATION/MEMBER_DETAILS_INCORRECT",
  "ONBOARDING_INFO_VERIFICATION/MEMBER_HAS_NO_ENROLLMENTS" = "ONBOARDING_INFO_VERIFICATION/MEMBER_HAS_NO_ENROLLMENTS",
  "E_SIGNATURE_INVITE/NOT_FOUND" = "E_SIGNATURE_INVITE/NOT_FOUND",
  "E_SIGNATURE_INVITE/EXPIRED" = "E_SIGNATURE_INVITE/EXPIRED",
  "E_SIGNATURE_INVITE/ALREADY_SIGNED" = "E_SIGNATURE_INVITE/ALREADY_SIGNED",
  "E_SIGNATURE_INVITE/DOB_VALIDATION_FAILED" = "E_SIGNATURE_INVITE/DOB_VALIDATION_FAILED",
  "E_SIGNATURE_INVITE/TOO_MANY_ATTEMPTS" = "E_SIGNATURE_INVITE/TOO_MANY_ATTEMPTS",
}

// prior auth
export const FILLABLE_PRIOR_AUTH_FORM_HREF =
  "https://static.curative.com/health_plan/Curative_Medical_Prior_Authorization_Form.pdf";
export const CURATIVE_RX_PRIOR_AUTH_EMAIL = "pharmacypa@curative.com";
export const CURATIVE_RX_PRIOR_AUTH_FAX = "888-293-4075";
export const CURATIVE_RX_PRIOR_AUTH_FORM =
  "https://static.curative.com/health_plan/Curative-Rx-Prior-Authorization-Form.pdf";
export const TDI_PRESCRIPTION_FORM =
  "https://static.curative.com/health_plan/TDI_Prescription_Form.pdf";
/** @deprecated See HEALTH_PLAN_CUSTOMER_SUPPORT_EMAIL_ADDRESS */
export const CURATIVE_SUPPORT_EMAIL =
  HEALTH_PLAN_CUSTOMER_SUPPORT_EMAIL_ADDRESS;

export const UsStateFromAbbreviation: { [key: string]: UsState } = {
  AL: UsState.Alabama,
  AK: UsState.Alaska,
  AZ: UsState.Arizona,
  AR: UsState.Arkansas,
  CA: UsState.California,
  CO: UsState.Colorado,
  CT: UsState.Connecticut,
  DE: UsState.Delaware,
  FL: UsState.Florida,
  GA: UsState.Georgia,
  HI: UsState.Hawaii,
  ID: UsState.Idaho,
  IL: UsState.Illinois,
  IN: UsState.Indiana,
  IA: UsState.Iowa,
  KS: UsState.Kansas,
  KY: UsState.Kentucky,
  LA: UsState.Louisiana,
  ME: UsState.Maine,
  MD: UsState.Maryland,
  MA: UsState.Massachusetts,
  MI: UsState.Michigan,
  MN: UsState.Minnesota,
  MS: UsState.Mississippi,
  MO: UsState.Missouri,
  MT: UsState.Montana,
  NE: UsState.Nebraska,
  NV: UsState.Nevada,
  NH: UsState.NewHampshire,
  NJ: UsState.NewJersey,
  NM: UsState.NewMexico,
  NY: UsState.NewYork,
  NC: UsState.NorthCarolina,
  ND: UsState.NorthDakota,
  OH: UsState.Ohio,
  OK: UsState.Oklahoma,
  OR: UsState.Oregon,
  PA: UsState.Pennsylvania,
  RI: UsState.RhodeIsland,
  SC: UsState.SouthCarolina,
  SD: UsState.SouthDakota,
  TN: UsState.Tennessee,
  TX: UsState.Texas,
  UT: UsState.Utah,
  VT: UsState.Vermont,
  VA: UsState.Virginia,
  WA: UsState.Washington,
  WV: UsState.WestVirginia,
  WI: UsState.Wisconsin,
  WY: UsState.Wyoming,
};

export function formatUsState(state?: UsState): string {
  if (!state) return "";
  return startCase(state.toLowerCase());
}

export const PHARMACY_NPI_AUSTIN = "1174253520";
export const PHARMACY_NPI_DALLAS = "1396422994";
export const PHARMACY_NPI_HOUSTON = "1497432090";
export const PHARMACY_NPI_TAMPA = "1316726029";
export const enum Portal {
  MEMBER = "MEMBER",
  EMPLOYER = "EMPLOYER",
  ADMIN = "ADMIN",
}

export enum ProviderSpecialtyId {
  Acupuncture = 1,
  AddictionMedicine = 2,
  AllergyImmunology = 3,
  AllergyImmunologyPediatric = 4,
  Anesthesiology = 5,
  Audiology = 6,
  BehavioralHealthTherapy = 7,
  Cardiology = 8,
  CardiovascularSurgery = 9,
  ChildAbusePediatrics = 10,
  Chiropractic = 11,
  ClinicalCounseling = 12,
  ColonRectalSurgery = 13,
  CriticalCare = 14,
  CriticalCarePediatric = 15,
  Dermatology = 16,
  DietitiansNutritionists = 17,
  EmergencyMedicine = 18,
  Endocrinology = 19,
  EndocrinologyPediatric = 20,
  Gastroenterology = 21,
  GeneralSurgery = 22,
  Genetics = 23,
  GeriatricMedicine = 24,
  Hematology = 25,
  HematologyOncology = 26,
  Hospitalist = 27,
  InfectiousDisease = 28,
  InfectiousDiseasePediatric = 29,
  MarriageFamilyTherapy = 30,
  Midwifery = 31,
  Neonatology = 32,
  Nephrology = 33,
  NeurologicalSurgery = 34,
  Neurology = 35,
  NeuromusculoskeletalMedicine = 36,
  ObstetricsGynecology = 37,
  OccupationalTherapy = 38,
  Oncology = 39,
  OncologyRadiationOncology = 40,
  Ophthalmology = 41,
  OralSurgery = 42,
  Orthopedics = 43,
  OrthopedicsHand = 44,
  OrthopedicsSportsMedicine = 45,
  Other = 46,
  OtolaryngologyEarNoseThroat = 47,
  OtolaryngologyENTEar = 48,
  OtolaryngologyENTPediatric = 49,
  OtolaryngologyENTPlasticSurgery = 50,
  PainMedicine = 51,
  Pathology = 52,
  PediatricsChildAbuse = 53,
  PhysicalMedicineRehabilitation = 54,
  PhysicalTherapy = 55,
  PlasticSurgery = 56,
  Podiatry = 57,
  PreventiveMedicine = 58,
  PrimaryCareAdolescentMedicine = 59,
  PrimaryCareFamilyMedicine = 60,
  PrimaryCareGeneralPractice = 61,
  PrimaryCareInternalMedicine = 62,
  PrimaryCarePediatrics = 63,
  Psychiatry = 64,
  PsychiatryPediatrics = 65,
  Psychology = 66,
  Pulmonology = 67,
  Radiology = 68,
  ReproductiveEndocrinology = 69,
  Rheumatology = 70,
  SpeechLanguageTherapy = 71,
  Surgery = 72,
  SurgeryPediatric = 73,
  ThoracicSurgery = 74,
  Urology = 75,
  UrologyPediatric = 76,
  VascularSurgery = 77,
}

export enum FacilityTypeId {
  AcuteCareHospital = 1,
  AmbulatorySurgeryCenter = 2,
  Anesthesiology = 3,
  BehavioralHealthIntensiveOutpatientProgram = 4,
  BehavioralHealthResidentialTreatmentCenter = 5,
  BirthingCenter = 6,
  ChemicalDependencyIntensiveOutpatientProgram = 7,
  ChemicalDependencyPartialHospitalizationProgram = 8,
  ChemicalDependencyResidentialTreatmentCenter = 9,
  Chiropractic = 10,
  Clinics = 11,
  Dialysis = 12,
  DurableMedicalEquipment = 13,
  EmergencyCenter = 14,
  EyeCare = 15,
  HearingTherapyCenter = 16,
  HomeHealth = 17,
  Hospice = 18,
  InfusionCenter = 19,
  Laboratory = 20,
  OccupationalTherapyClinic = 21,
  OncologyCenter = 22,
  OrthopedicAndOtherRehabilitation = 23,
  OrthopedicSurgery = 24,
  Other = 25,
  Pathology = 26,
  Pharmacy = 27,
  PhysicalTherapyClinic = 28,
  RadiologyImagingCenter = 29,
  RespiratoryTherapyClinic = 30,
  SkilledNursingFacility = 31,
  SpecialtyHospital = 32,
  SpeechTherapyClinic = 33,
  UrgentCare = 34,
  WalkInClinic = 35,
  VirtualCareDermatology = 36,
  VirtualCareBehavioralHealthTherapy = 37,
  VirtualCarePrimaryCare = 38,
}
