import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { FormattedToastAlert, FormattedToastAlertProps } from "../components";
import { ToastAlertAlignment, ToastAlertPosition } from "../enums";

export type ToastAlertProps = Pick<
  FormattedToastAlertProps,
  "type" | "title" | "message" | "testId" | "className"
> & {
  hideAfterMs?: number;
};

export type ToastAlertContextValue = {
  showToastAlert: (toastAlertProps: ToastAlertProps) => void;
  hideToastAlert: () => void;
};

export type ToastAlertContextProviderProps = {
  children?: ReactNode;
  alignment?: ToastAlertAlignment;
  position?: ToastAlertPosition;
};

export const ToastAlertContext = createContext<ToastAlertContextValue>({
  showToastAlert: () => {},
  hideToastAlert: () => {},
});

export const useToastAlertContext = () => useContext(ToastAlertContext);

export const ToastAlertContextProvider: FC<ToastAlertContextProviderProps> = ({
  children,
  alignment,
  position,
}) => {
  const [toastAlertProps, setToastAlertProps] =
    useState<ToastAlertProps | null>(null);

  const hideToastAlert = useCallback(() => {
    setToastAlertProps(null);
  }, []);

  const contextProviderValue = useMemo<ToastAlertContextValue>(
    () => ({
      showToastAlert: setToastAlertProps,
      hideToastAlert,
    }),
    [hideToastAlert]
  );

  useEffect(() => {
    if (toastAlertProps?.hideAfterMs) {
      const timeout = setTimeout(() => {
        hideToastAlert();
      }, toastAlertProps.hideAfterMs);
      return () => clearTimeout(timeout);
    }
  }, [toastAlertProps, hideToastAlert]);
  return (
    <ToastAlertContext.Provider value={contextProviderValue}>
      {toastAlertProps && (
        <FormattedToastAlert
          {...toastAlertProps}
          alignment={alignment}
          position={position}
          onRequestClose={hideToastAlert}
        />
      )}
      {children}
    </ToastAlertContext.Provider>
  );
};
