import { Pagination, PaginationProps } from "@chp/curative_ui";
import { FormattedNumber, useIntl } from "react-intl";

export type FormattedPaginationProps = Omit<
  PaginationProps,
  "FormattedNumber" | "nextText" | "previousText" | "separatorText"
>;

export const FormattedPagination = (
  props: FormattedPaginationProps
): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <Pagination
      FormattedNumber={FormattedNumber}
      nextText={formatMessage({ defaultMessage: "Next", id: "9+Ddtu" })}
      previousText={formatMessage({ defaultMessage: "Previous", id: "JJNc3c" })}
      separatorText={formatMessage({ defaultMessage: "...", id: "Oo+0sJ" })}
      {...props}
    />
  );
};
