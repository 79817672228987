import { FormatNumberOptions, useIntl } from "react-intl";

// Since `formatNumber` is being used in a standalone format here, it's okay to keep.
export const useFormatNumber = () => {
  const { formatNumber: fn, formatMessage } = useIntl();

  return {
    formatCurrency: ({
      value,
      opts,
      tbdString,
    }: {
      value: string | number | null | undefined;
      opts?: FormatNumberOptions;
      tbdString?: string;
    }) =>
      value != null
        ? fn(Number(value), {
            style: "currency",
            currency: "USD",
            ...opts,
          })
        : tbdString || formatMessage({ defaultMessage: "TBD", id: "UE+7ux" }),
  };
};
