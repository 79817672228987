import clsx from "clsx";
import { Dispatch, SetStateAction } from "react";
import { FC, useState } from "react";

import { FOCUS_CLASS_NAMES, SpacingVariant } from "../../constants";
import { Box, Icon, IconVariant } from "..";
import { CUIComponentProps, TextVariant } from "../types";
import { DividerItem } from "./DividerItem";
import { NavLink } from "./NavLink";
import {
  ACTIVE_LINK_CLASSNAMES,
  INACTIVE_LINK_CLASSNAMES,
  SideNavbarItemProps,
  SideNavbarItemType,
} from "./SideNavbar";

export type FloatingMenuProps = CUIComponentProps<{
  itemsList: SideNavbarItemProps[];
  testId?: string;
  setIsItemExpanded: Dispatch<SetStateAction<boolean>>;
}>;

export const FloatingMenu: FC<FloatingMenuProps> = ({
  itemsList,
  testId = "FloatingMenu",
  setIsItemExpanded,
}) => {
  const [wasClicked, setWasClicked] = useState(false);

  return (
    <div //TODO: Convert to <ul> and <li> for accessibility
      className={clsx(
        "relative left-16 top-0",
        "w-[250px] p-4",
        "rounded-lg bg-surface-neutral",
        "flex flex-1 flex-col gap-2",
        "shadow-md-surround"
      )}
      data-testid={`${testId}__FloatingMenu`}
    >
      {itemsList.map((item, idx) => {
        switch (item.type) {
          case SideNavbarItemType.NavLink:
            return (
              <NavLink
                key={item.label}
                href={item.href}
                label={item.label}
                isFloatingMenuItem={true}
                isExternal={item.isExternal}
                testId={`${testId}__${item.label}`}
              />
            );
          case SideNavbarItemType.NavItem:
            return (
              <button
                key={item.label}
                onClick={() => {
                  item.onClick();
                  setIsItemExpanded(false);
                }}
                className={clsx(
                  "px-3 py-2",
                  "flex justify-start",
                  "hover:text-textColor-highlight-default",
                  item.isActive?.()
                    ? ACTIVE_LINK_CLASSNAMES
                    : INACTIVE_LINK_CLASSNAMES,
                  FOCUS_CLASS_NAMES
                )}
              >
                <Box
                  className="whitespace-nowrap"
                  element="span"
                  textVariant={
                    item.isActive?.()
                      ? TextVariant.LgBoldShort
                      : TextVariant.LgRegular
                  }
                >
                  {item.label}
                  {item.isExternal && (
                    <Icon
                      className="ml-2 inline-block align-bottom"
                      variant={IconVariant.EXTERNAL_LINK}
                      size={SpacingVariant.S24}
                    />
                  )}
                </Box>
              </button>
            );
          case SideNavbarItemType.ExpandableItem:
            return (
              <button
                key={item.label}
                onClick={() => {
                  setWasClicked(true);
                }}
                className={clsx(
                  "px-3 py-2",
                  "flex justify-start",
                  "hover:text-textColor-highlight-default",
                  item.isActive?.()
                    ? ACTIVE_LINK_CLASSNAMES
                    : INACTIVE_LINK_CLASSNAMES,
                  FOCUS_CLASS_NAMES
                )}
              >
                <Box
                  className={clsx("w-full whitespace-nowrap text-left")}
                  element="span"
                  textVariant={
                    item.isActive?.()
                      ? TextVariant.LgBoldShort
                      : TextVariant.LgRegular
                  }
                >
                  {item.label}
                </Box>
                {!wasClicked && (
                  <Icon
                    variant={IconVariant.CHEVRON_RIGHT}
                    size={SpacingVariant.S24}
                  />
                )}
                {wasClicked && (
                  <FloatingMenu
                    itemsList={item.nestedNavItems}
                    setIsItemExpanded={setIsItemExpanded}
                  />
                )}
              </button>
            );
          case SideNavbarItemType.Divider:
            // eslint-disable-next-line
            return <DividerItem key={idx} />;
          default:
            return null;
        }
      })}
    </div>
  );
};
