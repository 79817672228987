export enum SORT_DIRECTION {
  ASC = "ASCENDING",
  DESC = "DESCENDING",
}

export const sortByPredicate = <T = unknown>(
  sortDirection: SORT_DIRECTION,
  predicate: (value: T) => boolean,
  values: T[]
): T[] =>
  [...values].sort((a: T, b: T): number => {
    const resultA = predicate(a);
    const resultB = predicate(b);

    if (resultA && resultB) {
      return 0;
    }

    if (!resultA && !resultB) {
      return 0;
    }

    if (resultA) {
      return sortDirection === SORT_DIRECTION.ASC ? -1 : 1;
    }

    return sortDirection === SORT_DIRECTION.ASC ? 1 : -1;
  });

export const sortByRegExpMatch = (
  sortDirection: SORT_DIRECTION,
  regExp: RegExp,
  values: string[]
): string[] =>
  sortByPredicate<string>(
    sortDirection,
    (value) => value.match(regExp) !== null,
    values
  );

export const sortByRegExpMatchAsc = (
  regExp: RegExp,
  values: string[]
): string[] => sortByRegExpMatch(SORT_DIRECTION.ASC, regExp, values);

export const sortByRegExpMatchDesc = (
  regExp: RegExp,
  values: string[]
): string[] => sortByRegExpMatch(SORT_DIRECTION.DESC, regExp, values);
