import { PageViewMixpanelEventName } from "../enums";
import type { MixpanelEventNameProps } from "../types";

export const formatPageViewEventName = (pathname: string): string => {
  const formattedPathname =
    pathname.replace(/\//g, ".").replace(/^\.*(.*)$/, "$1") || "index";

  return `${formattedPathname}.${PageViewMixpanelEventName.PAGE_VIEW}`;
};

export const formatFullyQualifiedEventName = ({
  appName,
  uiIdentifier,
  eventName,
}: MixpanelEventNameProps): string => `${appName}.${uiIdentifier}.${eventName}`;
