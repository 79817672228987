import { IconVariant, TypeaheadOption } from "@chp/curative_ui";
import {
  FormTypeahead,
  GeocodeQueryType,
  MapCoordinate,
} from "@chp/shared/components";
import {
  formatPlaceName,
  generateArrayKey,
  geocodeToLocationDetails,
} from "@chp/shared/utils";
import GeocodingService, {
  GeocodeFeature,
} from "@mapbox/mapbox-sdk/services/geocoding";
import invariant from "invariant";
import { useCallback, useState } from "react";
import { FieldValues, Path } from "react-hook-form";
import { useIntl } from "react-intl";

import { LocationDetails } from "../../types";

export type LocationSearchResult = LocationDetails & {
  name: string;
};

export const geocodeFeatureToLocationSearchResult = (
  geocodeFeature: GeocodeFeature
): LocationSearchResult => {
  const name = formatPlaceName(geocodeFeature.place_name);
  return {
    name,
    ...geocodeToLocationDetails(geocodeFeature),
  };
};

const useMapboxGeocodeSearch = ({
  proximity,
  geocodingAccessToken,
  locationTypes,
}: {
  proximity?: MapCoordinate | null;
  geocodingAccessToken: string | undefined;
  locationTypes?: GeocodeQueryType[];
}) => {
  const { formatMessage } = useIntl();
  const [areLocationsLoading, setAreLocationsLoading] = useState(false);

  const fetchLocations = useCallback(
    async (queryString: string) => {
      setAreLocationsLoading(true);

      invariant(
        geocodingAccessToken,
        "Missing required environment variable NEXT_PUBLIC_MAPBOX_KEY"
      );
      const geocodingClient = GeocodingService({
        accessToken: geocodingAccessToken,
      });
      const request = geocodingClient.forwardGeocode({
        query: queryString,
        mode: "mapbox.places",
        countries: ["US"],
        types: locationTypes,
        proximity: proximity
          ? [proximity.longitude, proximity.latitude]
          : undefined,
        autocomplete: true,
        limit: 5,
      });

      return request
        .send()
        .then(({ body: { features } }) => {
          setAreLocationsLoading(false);

          return features.map((feature) => {
            const locationSearchResult =
              geocodeFeatureToLocationSearchResult(feature);

            const option: TypeaheadOption<LocationSearchResult> = {
              label: locationSearchResult.name,
              value: locationSearchResult,
            };
            return option;
          });
        })
        .catch(() => {
          setAreLocationsLoading(false);
          throw new Error(
            formatMessage({
              defaultMessage: "There was an error searching for locations.",
              id: "v0KAvz",
            })
          );
        });
    },
    [formatMessage, locationTypes, proximity, geocodingAccessToken]
  );

  return { fetchLocations, areLocationsLoading };
};

export interface LocationSearchProps<T extends FieldValues> {
  geocodingAccessToken: string | undefined;
  label: string;
  name: Path<T>;
  className?: string;
  placeholder?: string;
  onlyCareCostProcedures?: boolean;
  shouldResetFieldOnChange?: boolean;
  proximity?: MapCoordinate | null;
  locationTypes?: GeocodeQueryType[];
  isLoadingCurrentLocation?: boolean;
  onLocationQueryStringDirty?: () => void;
  isDisabled?: boolean;
}

export function LocationSearch<T extends FieldValues>({
  proximity,
  geocodingAccessToken,
  locationTypes,
  isLoadingCurrentLocation,
  onLocationQueryStringDirty,
  ...props
}: LocationSearchProps<T>) {
  const { fetchLocations, areLocationsLoading } = useMapboxGeocodeSearch({
    proximity,
    geocodingAccessToken,
    locationTypes,
  });

  return (
    <FormTypeahead<T, LocationSearchResult>
      {...props}
      hasDropdownToggle={false}
      optionKeyExtractor={() => generateArrayKey()}
      fetchOptions={fetchLocations}
      isLoading={areLocationsLoading}
      testId="LocationTypeahead"
      prefixIcon={IconVariant.LOCATION_MARKER}
      onQueryStringDirty={onLocationQueryStringDirty}
      prefixIconLoading={isLoadingCurrentLocation}
      queryStringMinLength={3}
      disableEnterWhileLoading={true}
    />
  );
}
