import { ApolloError, isApolloError as _isApolloError } from "@apollo/client";
import { ErrorCode } from "@chp/shared/constants";
import type { GraphQLErrorWithErrorCode } from "@chp/shared/types/graphql";
import { nonNull } from "@chp/shared/utils/asserts";
import type { GraphQLFormattedError } from "graphql";
import { isEmpty } from "lodash-es";

export * from "./removeTypename";

export const isApolloError = (error: unknown): error is ApolloError =>
  !!error && error instanceof Error && _isApolloError(error);

export const isGraphQLErrorWithErrorCode = (
  error: GraphQLFormattedError
): error is GraphQLErrorWithErrorCode => !!error.extensions?.errorCode;

export const getApolloErrorCode = (error: unknown): ErrorCode | undefined => {
  if (!error || !isApolloError(error) || isEmpty(error.graphQLErrors)) {
    return undefined;
  }
  const graphQLError = nonNull(error.graphQLErrors[0]);

  if (!isGraphQLErrorWithErrorCode(graphQLError)) {
    return undefined;
  }

  return graphQLError.extensions.errorCode;
};
