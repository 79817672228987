import { FieldValues } from "react-hook-form";
import { useIntl } from "react-intl";

import {
  FormMaskedInput,
  FormMaskedInputProps,
} from "../../../../components/form/FormMaskedInput";
import { SSN_FIELD_TEST_ID } from "../../constants";

type SSNInputOptionalProps<T extends FieldValues = FieldValues> = Partial<
  Pick<
    FormMaskedInputProps<T>,
    | "id"
    | "testId"
    | "isDisabled"
    | "label"
    | "description"
    | "placeholder"
    | "onValueChanged"
  >
>;

type SSNInputRequiredProps<T extends FieldValues = FieldValues> = Required<
  Pick<FormMaskedInputProps<T>, "name">
>;

export type SSNInputProps<T extends FieldValues = FieldValues> =
  SSNInputOptionalProps<T> & SSNInputRequiredProps<T>;

/** @deprecated see SsnFormInput */
export const SSNInput = <T extends FieldValues = FieldValues>({
  name,
  id = name,
  testId = SSN_FIELD_TEST_ID,
  isDisabled,
  label,
  description,
  placeholder,
  onValueChanged,
}: SSNInputProps<T>) => {
  const { formatMessage } = useIntl();

  return (
    <FormMaskedInput<T>
      autoComplete="off"
      name={name}
      id={id}
      testId={testId}
      isDisabled={isDisabled}
      label={
        label ||
        formatMessage({
          defaultMessage: "Social Security Number",
          id: "bhOaOJ",
        })
      }
      description={description}
      placeholder={
        placeholder ||
        formatMessage({ defaultMessage: "e.g. 123-45-6789", id: "tSbQAr" })
      }
      placeholderAlwaysVisible={false}
      emptyValue="___-__-____"
      mask="000-00-0000"
      unmask={false}
      onValueChanged={onValueChanged}
    />
  );
};
