import { Dialog } from "@headlessui/react";
import clsx from "clsx";
import {
  addYears,
  format,
  isAfter,
  isBefore,
  isValid,
  Locale as DateFnsLocale,
  parse,
} from "date-fns";
import { enUS, es } from "date-fns/locale";
import {
  ChangeEventHandler,
  PropsWithChildren,
  RefCallback,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  isMatch,
  Matcher,
  MonthChangeEventHandler,
  useInput,
} from "react-day-picker";
import { IMaskInput } from "react-imask";

import { SpacingVariant } from "../../constants";
import {
  Box,
  DialogWithBackdrop,
  formFieldErrorId,
  getTextFieldClassNames,
  Icon,
  IconVariant,
} from "..";
import { DatePicker } from "../DatePicker/DatePicker";
import {
  FormFieldDescription,
  FormFieldErrorMessage,
  FormFieldLabelText,
  formFieldTextVariant,
  formFieldWrapperMargin,
  useHasError,
} from "../forms";
import {
  CUIComponent,
  CUIComponentProps,
  FormFieldBaseProps,
  Locale,
} from "../types";

export const isPlaceholderPresent = (date: string) => {
  return date.includes("_");
};

/** @deprecated handle form validation in consuming app */
export const isDateValid = (date: string) => {
  return isValid(date);
};

// form validation should be handled by consuming app. however some basic errors will be detected by component.
/** @deprecated handle form validation in consuming app */
export enum DateInputError {
  /* When the user has not yet entered a syntactically valid complete date */
  INCOMPLETE_DATE = "INCOMPLETE_DATE",
  /* When the user has typed a date that is blocked/excluded, datepicker blocks it but user can still type them */
  UNAVAILABLE_DATE = "UNAVAILABLE_DATE",
}

const localeToDateFnsLocales: Record<Locale, DateFnsLocale> = {
  [Locale.ENGLISH]: enUS,
  [Locale.SPANISH]: es,
};

// TODO: add PlainDate support
export type DateInputProps = CUIComponentProps<
  FormFieldBaseProps & {
    dateInputFormat: string;
    /** This is how the input mask would represent the value in the textbox when nothing is entered */
    emptyMaskValue: string;
    /** This is what we want to actually display in the textbox when nothing is entered, ie. when value == DEFAULT_EMPTY_VALUE  */
    emptyPlaceholderValue: string;
    locale: Locale;
    name?: string;
    id: string;
    innerRef?: RefObject<HTMLInputElement> | RefCallback<HTMLInputElement>;
    isDisabled?: boolean;
    isRequired?: boolean;
    onBlur?: () => void;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onMonthChange?: MonthChangeEventHandler;
    onRequestChangeValue?: (value: string | undefined) => void;
    /** @deprecated handle form validation in consuming app */
    onError?: (value: DateInputError) => void;
    /** @deprecated handle form validation in consuming app */
    onValidDateEntered?: () => void;
    placeholder?: string;
    value: string | undefined;
    excludedDates?: Matcher | Matcher[];
    fromDate?: string;
    toDate?: string;
    openCalendarButtonAriaLabel?: string;
    dialogTitle?: string;
    displayMaskPlaceholder?: boolean;
  }
>;

export const DateInput: CUIComponent<DateInputProps> = ({
  className,
  dateInputFormat,
  emptyMaskValue,
  emptyPlaceholderValue,
  description,
  errorMessage,
  id,
  testId = "DateInput",
  isDisabled,
  isLabelSrOnly,
  isRequired,
  label,
  locale,
  name,
  onChange,
  onMonthChange,
  onRequestChangeValue = () => {},
  value,
  excludedDates = [],
  onBlur,
  innerRef,
  openCalendarButtonAriaLabel = "Open date selection helper",
  dialogTitle = "Select a date",
  fromDate,
  toDate,
  onValidDateEntered,
  onError,
  displayMaskPlaceholder,
}) => {
  /** by default, we will make dates from today + 1 year available */
  const fromDateObj = fromDate
    ? parse(fromDate, dateInputFormat, new Date())
    : new Date();

  const toDateObj = toDate
    ? parse(toDate, dateInputFormat, new Date())
    : addYears(fromDateObj, 1);

  const [datePickerVisible, setDatePickerVisible] = useState<boolean>(false);

  const fromYear = fromDateObj.getFullYear();
  const toYear = toDateObj.getFullYear();

  const { inputProps } = useInput({
    fromYear,
    toYear,
    format: dateInputFormat,
    required: true,
    locale: localeToDateFnsLocales[locale],
  });

  const valueAsDate = value
    ? parse(value, dateInputFormat, new Date())
    : undefined;

  const isDateBlocked = (day: Date) => {
    /* block days that fall outside the date range */
    const isDayOutsideAllowedRange =
      isBefore(day, fromDateObj) || isAfter(day, toDateObj);

    /* block days that are explicitly defined as excluded */
    const isDayExcluded = isMatch(day, matcherToArray(excludedDates));

    return isDayOutsideAllowedRange || isDayExcluded;
  };

  const onDateChange = (newValue: string | undefined) => {
    if (!newValue || isPlaceholderPresent(newValue)) {
      onError?.(DateInputError.INCOMPLETE_DATE);
    } else {
      const selectedDate = parse(newValue, dateInputFormat, new Date());
      if (!isValid(selectedDate) || isDateBlocked(selectedDate)) {
        onError?.(DateInputError.UNAVAILABLE_DATE);
      }

      // date must be valid
      else {
        onValidDateEntered?.();
      }
    }

    const defaultMask = displayMaskPlaceholder
      ? emptyPlaceholderValue
      : emptyMaskValue;

    /** if the value was changed to the default mask, means no value is entered so set it to undefined */
    onRequestChangeValue?.(newValue === defaultMask ? undefined : newValue);
  };

  const hasError = useHasError({ errorMessage });

  const wrapperRef = useRef<HTMLDivElement>(null);

  useOutsideClick(wrapperRef, () => setDatePickerVisible(false));

  const errorId = formFieldErrorId({ hasError, id });

  const [month, setMonth] = useState(valueAsDate);

  const [isFocused, setFocused] = useState(false);

  return (
    <Box
      className={clsx("relative", className)}
      element="div"
      testId={testId}
      textVariant={formFieldTextVariant}
    >
      <label data-testid={`${testId}__Label`} className="block">
        <FormFieldLabelText isLabelSrOnly={isLabelSrOnly} label={label} />

        <FormFieldDescription description={description} />

        <Box
          className={clsx("flex", "items-center")}
          element="div"
          margin={formFieldWrapperMargin({ isLabelSrOnly })}
        >
          <IMaskInput
            {...inputProps}
            id={id}
            name={name}
            data-testid={`${testId}__Input`}
            lazy={!value && !isFocused}
            inputRef={innerRef}
            inputMode="numeric"
            type="text"
            className={getTextFieldClassNames({
              isDisabled,
              hasError,
            })}
            // We need it as uppercase since the incoming format used intenally can contain lower cases MM/dd/yyyy which is valid for date-fns but when we display the mask, we want to show MM/DD/YYYY
            mask={dateInputFormat.toUpperCase()}
            placeholder={emptyPlaceholderValue}
            autofix={true}
            blocks={{
              DD: {
                mask: "00",
                placeholderChar: displayMaskPlaceholder ? "D" : "_",
              },
              MM: {
                mask: "00",
                placeholderChar: displayMaskPlaceholder ? "M" : "_",
              },
              YYYY: {
                mask: "0000",
                placeholderChar: displayMaskPlaceholder ? "Y" : "_",
              },
            }}
            required={isRequired}
            disabled={isDisabled}
            value={value}
            onAccept={(value, _, evt) => {
              // filter out events where the user didn't change anything (input received focus)
              if (!evt) return;
              onDateChange(value);

              const defaultMask = displayMaskPlaceholder
                ? emptyPlaceholderValue
                : emptyMaskValue;

              onChange &&
                onChange({
                  target: {
                    /** if the value was changed to the default mask, means no value is entered so set it to undefined */
                    value: value === defaultMask ? undefined : value,
                  },
                } as React.ChangeEvent<HTMLInputElement>);
            }}
            onBlur={() => {
              setFocused(false);
              onBlur && onBlur();
            }}
            onFocus={() => {
              setFocused(true);
            }}
            aria-describedby={errorId}
            aria-invalid={hasError}
          />

          {/** swap w icon button component when available */}
          <button
            data-testid={`${testId}__OpenDatePickerButton`}
            disabled={isDisabled}
            className="ml-4"
            onClick={() => setDatePickerVisible(!datePickerVisible)}
            aria-label={openCalendarButtonAriaLabel}
            type="button"
          >
            <Icon size={SpacingVariant.S24} variant={IconVariant.CALENDAR} />
          </button>
        </Box>
      </label>

      <Overlay
        testId={testId}
        isOpen={datePickerVisible}
        dialogTitle={dialogTitle}
      >
        <div ref={wrapperRef}>
          <DatePicker
            locale={locale}
            value={
              valueAsDate && isValid(valueAsDate) && !isDateBlocked(valueAsDate)
                ? valueAsDate
                : undefined
            }
            onSelect={(newDate?: number | Date) => {
              const dateStr = newDate
                ? format(newDate, dateInputFormat)
                : undefined;

              onDateChange(dateStr);
              setDatePickerVisible(false);
              onBlur?.();
            }}
            disabled={isDateBlocked}
            fromDate={fromDateObj}
            toDate={toDateObj}
            month={month}
            onMonthChange={(date) => {
              setMonth(date);
              onMonthChange && onMonthChange(date);
            }}
            required
          />
        </div>
      </Overlay>

      {hasError && errorId && (
        <FormFieldErrorMessage
          errorMessage={errorMessage}
          testId={`${testId}__ErrorMessage`}
          id={errorId}
        />
      )}
    </Box>
  );
};

/** This function is not exported by the library so we define it here */
/** Normalize to array a matcher input. */
export function matcherToArray(
  matcher: Matcher | Matcher[] | undefined
): Matcher[] {
  if (Array.isArray(matcher)) {
    return matcher;
  } else if (matcher !== undefined) {
    return [matcher];
  } else {
    return [];
  }
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideClick(
  ref: RefObject<HTMLDivElement>,
  onClickOutside: () => void
) {
  useEffect(() => {
    /**
     * Trigger the click outside event when clicked outside
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    }

    // bind the event
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClickOutside, ref]);
}

const Overlay = ({
  children,
  isOpen,
  testId,
  dialogTitle,
}: PropsWithChildren<{
  isOpen: boolean;
  testId: string;
  dialogTitle: string;
}>) => {
  return (
    // TODO: use cui Modal?
    <DialogWithBackdrop
      isOpen={isOpen}
      onClose={() => {}}
      title={dialogTitle}
      testId={testId}
    >
      {/* The actual dialog panel  */}
      <Dialog.Panel
        className={clsx("mx-auto", "max-w-sm", "rounded", "bg-white")}
      >
        {children}
      </Dialog.Panel>
    </DialogWithBackdrop>
  );
};
