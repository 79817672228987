// This helper function exists to help us get rid of Typescript errors relating to
// State Machine assign objects when we don't pass a callback with a context argument
// XState themselves are aware of the issue and recommend fixing it by always passing
// a callback explicitly declaring context even if it is unused.
// In order for us not to repeat a bunch of unused parameters in a callback, this helper
// method was created.
export const createAssignAction =
  <TContext, TAssignAction>(assignAction: TAssignAction) =>
  (_context: TContext): TAssignAction =>
    assignAction;
