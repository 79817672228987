import { Icon, IconVariant, SpacingVariant } from "@chp/curative_ui";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, onPageChange } = props;
  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  return (
    <div className="ml-6 flex w-full flex-row">
      <button
        aria-label="first page"
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
      >
        <Icon
          variant={IconVariant.CHEVRON_DOUBLE_LEFT}
          size={SpacingVariant.S20}
          className={
            page === 0
              ? "cursor-not-allowed stroke-gray-400 stroke-1"
              : "cursor-pointer stroke-blue-700 stroke-2"
          }
        />
      </button>
      <button
        aria-label="previous page"
        onClick={handleBackButtonClick}
        disabled={page === 0}
      >
        <Icon
          variant={IconVariant.CHEVRON_LEFT}
          size={SpacingVariant.S20}
          className={
            page === 0
              ? "cursor-not-allowed stroke-gray-400 stroke-1"
              : "cursor-pointer stroke-blue-700 stroke-2"
          }
        />
      </button>
      <button
        aria-label="next page"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <Icon
          variant={IconVariant.CHEVRON_RIGHT}
          size={SpacingVariant.S20}
          className={
            page >= Math.ceil(count / rowsPerPage) - 1
              ? "cursor-not-allowed stroke-gray-400 stroke-1"
              : "cursor-pointer stroke-blue-700 stroke-2"
          }
        />
      </button>
    </div>
  );
}

export default TablePaginationActions;
