import {
  FormattedMaskedInput,
  FormattedMaskedInputProps,
} from "@chp/shared/components/FormattedMaskedInput";
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";

export type FormMaskedInputProps<T extends FieldValues> = {
  name: Path<T>;
  id?: string;
  className?: string;
  onValueChanged?: (newValue: string | null) => void;
  description?: FormattedMaskedInputProps["description"];
  label: string;
  mask: string;
  // the value when the user deletes everything, so we know to compare to it and not validate against it
  emptyValue: string;
} & Partial<
  Pick<
    FormattedMaskedInputProps,
    | "autoComplete"
    | "isLabelSrOnly"
    | "placeholder"
    | "type"
    | "isDisabled"
    | "unmask"
    | "placeholderChar"
    | "placeholderAlwaysVisible"
    | "testId"
    | "blocks"
    | "icon"
    | "onClickButton"
    | "clearButtonText"
  >
>;

export function FormMaskedInput<T extends FieldValues>(
  props: FormMaskedInputProps<T>
) {
  const { name, id, onValueChanged, emptyValue, ...rest } = props;

  const { control } = useFormContext<T>();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, ref, onBlur, onChange },
        fieldState: { error },
      }) => (
        <FormattedMaskedInput
          id={id || name}
          value={value || ""}
          innerRef={ref}
          //workaround react-hook-form typing issue
          errorMessage={error?.message}
          onBlur={onBlur}
          onRequestChangeValue={(newValue: string) => {
            // Treat all empty string values as null so Yup handles validation
            // properly if it's an optional field, otherwise it will try to
            // validate an empty string, which will fail. We use null because
            // if we set the value to undefined, react-hook-form falls back to
            // the initial value.
            const newValueOrNull =
              newValue === "" || newValue === emptyValue ? null : newValue;

            onChange({
              target: {
                value: newValueOrNull,
              },
            });

            onValueChanged?.(newValueOrNull);
          }}
          {...rest}
        />
      )}
    />
  );
}
