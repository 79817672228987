import {
  Box,
  Card,
  CardProps,
  ScreenReaderOnly,
  SpacingVariant,
  Table,
  TableContentType,
  TableProps,
  TextVariant,
} from "@chp/curative_ui";
import { compact } from "lodash-es";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { CoveragePackage } from "../../generated/memberPortalApi.graphql";
import { generateArrayKey } from "../../utils";
import {
  BenefitsCategory,
  BenefitsCategoryContent,
  benefitsCategoryContent,
} from "./benefitsCategoryContent";

export enum InNetworkVariant {
  NO_COPAY = "NO_COPAY",
  WITH_COPAY = "WITH_COPAY",
}

const benefitsCategoryTableTestId = "BenefitsCategoryTable";

const InNetworkVariantCellIndex: Record<InNetworkVariant, number> = {
  [InNetworkVariant.NO_COPAY]: 1,
  [InNetworkVariant.WITH_COPAY]: 2,
};

// all columns should be left-aligned
const tableContentTypes: TableProps["contentTypes"] = [
  TableContentType.TEXT,
  TableContentType.TEXT,
  TableContentType.TEXT,
];

export type BenefitsCategoryTableProps = Pick<
  TableProps,
  "columnClassNames"
> & {
  category: BenefitsCategory;
  planCoveragePackage?: CoveragePackage;
  isPlanHighDeductible?: boolean;
  benefitsBookletUrl?: string;
};

export const BenefitsCategoryTable = (
  props: BenefitsCategoryTableProps
): JSX.Element => {
  const {
    category,
    columnClassNames,
    planCoveragePackage,
    isPlanHighDeductible,
  } = props;
  const { formatMessage } = useIntl();
  const coveragePackage = planCoveragePackage;
  const isEpo = coveragePackage === CoveragePackage.Epo;
  const memoizedContent: Record<BenefitsCategory, BenefitsCategoryContent> =
    useMemo(
      () =>
        benefitsCategoryContent({
          formatMessage,
          coveragePackage,
        }),
      [coveragePackage, formatMessage]
    );

  // If they are in the grace period or completed the baseline, they will see the in-network cost with $0 copay.
  // if they didn't complete the baseline, then they see the in-network cost with copay.
  const inNetworkVariant = isPlanHighDeductible
    ? InNetworkVariant.WITH_COPAY
    : InNetworkVariant.NO_COPAY;

  const tableKeys: TableProps["keys"] = compact([
    <ScreenReaderOnly element="span" key="Coverage">
      {formatMessage({ defaultMessage: "Coverage", id: "jSqwdW" })}
    </ScreenReaderOnly>,
    formatMessage({ defaultMessage: "In-Network", id: "tKgqWD" }),
    !isEpo && formatMessage({ defaultMessage: "Out-of-Network", id: "rPbxHL" }),
  ]);

  const tableContentOriginal: BenefitsCategoryContent =
    memoizedContent[category];
  const content = {
    ...tableContentOriginal,
    rows: tableContentOriginal.rows.map((row) => {
      // map row with 4 cells into row with 3 cells
      return compact([
        row[0],
        // show either index 1 or index 2 for in-network-info
        row[InNetworkVariantCellIndex[inNetworkVariant]],
        // skip to index 3 - out-of-network
        !isEpo && row[3],
      ]);
    }),
  };

  return (
    <Table
      columnClassNames={columnClassNames}
      contentTypes={tableContentTypes}
      headCellArrayKeyGenerator={() => generateArrayKey()}
      keys={tableKeys}
      rowArrayKeyGenerator={() => generateArrayKey()}
      rows={content.rows}
      testId={`${benefitsCategoryTableTestId}-${inNetworkVariant}`}
    />
  );
};

export type BenefitsCategoryTableCardsProps = Omit<
  BenefitsCategoryTableProps,
  "category"
> &
  Pick<CardProps, "headingLevel"> & {
    categories: BenefitsCategory[];
  };

/**
 * Display BenefitsCategoryTable Cards for a BenefitsCategory list
 */
export const BenefitsCategoryTableCards = (
  props: BenefitsCategoryTableCardsProps
): JSX.Element => {
  const {
    categories,
    columnClassNames,
    headingLevel,
    planCoveragePackage,
    isPlanHighDeductible,
    benefitsBookletUrl,
  } = props;
  const { formatMessage } = useIntl();
  const memoizedContent: Record<BenefitsCategory, BenefitsCategoryContent> =
    useMemo(
      () =>
        benefitsCategoryContent({
          formatMessage,
        }),
      [formatMessage]
    );
  const benefitsBookletLink = (
    <a
      className="!underline"
      href={benefitsBookletUrl}
      target="_blank"
      rel="nofollow noreferrer"
    >
      <FormattedMessage defaultMessage="Benefits Booklet" id="sLHmuF" />
    </a>
  );
  const isEpo = planCoveragePackage === CoveragePackage.Epo;
  return (
    <Box element="div" testId="BenefitsCategoryTableCards">
      {categories.map((category) => {
        const tableProps = {
          columnClassNames,
          planCoveragePackage,
          isPlanHighDeductible,
          category,
        };
        const cardHeading = memoizedContent[category].title;
        const description = memoizedContent[category].description;
        return (
          <Box
            element="div"
            key={cardHeading}
            margin={{ bottom: SpacingVariant.S24 }}
          >
            <Card heading={cardHeading} headingLevel={headingLevel}>
              {isEpo && (
                <Box
                  element="p"
                  textVariant={TextVariant.SmRegularTall}
                  margin={{ bottom: SpacingVariant.S16 }}
                >
                  <FormattedMessage
                    defaultMessage="*Services received from Out-of-Network providers are not covered unless In-Network services were not available as described in “Necessary Out-of-Network Services” in the {benefitsBookletLink}.  Necessary Out-of-Network Services will be treated the same as In-Network Services."
                    id="rySMI5"
                    values={{ benefitsBookletLink }}
                  />
                </Box>
              )}
              {description && (
                <Box element="p" margin={{ bottom: SpacingVariant.S16 }}>
                  {description}
                </Box>
              )}
              <BenefitsCategoryTable {...tableProps} />
            </Card>
          </Box>
        );
      })}
    </Box>
  );
};
