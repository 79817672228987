import { useCallback } from "react";
import { useIntl } from "react-intl";

import { useTypeaheadOptions__SearchProcedureCodesLazyQuery } from "../memberPortalApi-TypeaheadOptions.generated";

export const useFetchProcedureCodeOptions = () => {
  const { formatMessage } = useIntl();
  const [searchProcedureCodes, { loading: areProcedureCodeOptionsLoading }] =
    useTypeaheadOptions__SearchProcedureCodesLazyQuery();

  const fetchProcedureCodeOptions = useCallback(
    async (queryString: string) => {
      const response = await searchProcedureCodes({
        variables: {
          search: queryString,
          onlyCareCostProcedures: false,
        },
      });

      if (response.error || !response.data) {
        throw new Error(
          formatMessage({
            defaultMessage: "There was an error searching for procedure codes.",
            id: "v4Gccs",
          })
        );
      }

      const results = response.data.searchProcedureCodes;

      // TODO: Add i18n support
      return results.map((result) => ({
        label: `${result.code}: ${result.description}`,
        value: result,
      }));
    },
    [searchProcedureCodes, formatMessage]
  );

  return {
    fetchProcedureCodeOptions,
    areProcedureCodeOptionsLoading,
  };
};
