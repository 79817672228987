import clsx from "clsx";
import { FC } from "react";

import { Box } from "../Box";
import { CUIComponentProps, TextVariant } from "../types";

const MAX_FILL_PERCENT = 100;

const BACKGROUND_BAR_CLASS_NAMES = clsx("rounded-sm", "h-3");

const FILL_BAR_CLASS_NAMES = clsx(
  BACKGROUND_BAR_CLASS_NAMES,
  "absolute",
  "top-0",
  "left-0"
);

export type MinMaxProgressBarProps = CUIComponentProps<{
  startText: string | JSX.Element;
  ariaLabel: string;
  fillPercent: number;

  fillColor?: "blue" | "gray";
  endText?: string | JSX.Element;
  maxMetText?: string | JSX.Element;
}>;

export const MinMaxProgressBar: FC<MinMaxProgressBarProps> = ({
  startText,
  endText,
  ariaLabel,
  fillPercent,
  fillColor = "blue",
  maxMetText,
  className,
  testId = "MinMaxProgressBar",
}) => {
  const barBackgroundColorClassname =
    fillColor === "blue" ? "bg-blue-200" : "bg-gray-200";
  const barFillColorClassname =
    fillColor === "blue" ? "bg-foundation-primary" : "bg-gray-500";
  const textColorClassname =
    fillColor === "blue" && fillPercent > 0
      ? "text-textColor-highlight-default"
      : "text-textColor-default";

  const isMaxMet = fillPercent >= MAX_FILL_PERCENT;

  return (
    <div className={clsx("w-full ", className)} data-testid={testId}>
      <Box element="div" className={clsx("flex", "justify-between", "h-6")}>
        {isMaxMet ? (
          <Box
            element="span"
            textVariant={TextVariant.MdBold}
            className={textColorClassname}
            testId={`${testId}__MaxMetText`}
          >
            {maxMetText ?? startText}
          </Box>
        ) : (
          <>
            <Box
              element="span"
              textVariant={
                fillPercent > 0 ? TextVariant.MdBold : TextVariant.MdRegularTall
              }
              className={textColorClassname}
            >
              {startText}
            </Box>
            {endText && (
              <Box element="span" textVariant={TextVariant.MdRegularTall}>
                {endText}
              </Box>
            )}
          </>
        )}
      </Box>
      <div
        aria-label={ariaLabel}
        role="meter"
        aria-valuemin={0}
        aria-valuemax={100}
        aria-valuenow={fillPercent}
        className="relative mt-1"
      >
        <div
          className={clsx(
            BACKGROUND_BAR_CLASS_NAMES,
            barBackgroundColorClassname
          )}
        />
        <div
          className={clsx(FILL_BAR_CLASS_NAMES, barFillColorClassname)}
          data-testid={`${testId}__FillBar`}
          style={{ width: `${fillPercent}%` }}
        />
      </div>
    </div>
  );
};
