import { TextAlignVariant } from "@chp/curative_ui";
import { FormattedMessage } from "react-intl";

import { AnimationType } from "../Animation";
import {
  MemberServicesEmailLink,
  MemberServicesPhoneNumberLink,
} from "../links";
import {
  StatusMessageWithLogo,
  StatusMessageWithLogoSize,
} from "./StatusMessageWithLogo";

export const DefaultErrorMessageWithLogo = ({
  testId = "DefaultErrorMessageWithLogo",
}: {
  testId?: string;
}) => (
  <StatusMessageWithLogo
    size={StatusMessageWithLogoSize.MD}
    animationType={AnimationType.ERROR}
    testId={testId}
    textAlign={TextAlignVariant.CENTER}
    title={
      <FormattedMessage
        defaultMessage="Oh no... something went wrong"
        id="1w9AwM"
      />
    }
    message={
      <FormattedMessage
        defaultMessage="The information has failed to load. Please refresh your browser and try again. If the issue persists, please contact support at {phoneNumber} or {email}."
        id="3qsrtK"
        values={{
          phoneNumber: <MemberServicesPhoneNumberLink />,
          email: <MemberServicesEmailLink />,
        }}
      />
    }
  />
);
