import { useMediaPredicate } from "react-media-hook";

import {
  BREAKPOINT_2XL,
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XL,
} from "../mediaBreakpoints";

export const useMediaBreakpoints = (): {
  isDesktop: boolean;
  isLargeDesktop: boolean;
  isMinWidthSM: boolean;
  isMinWidthMD: boolean;
  isMinWidthLG: boolean;
  isMinWidthXL: boolean;
  isMinWidth2XL: boolean;
  isMobile: boolean;
} => {
  const isMobile = useMediaPredicate(`(max-width: ${BREAKPOINT_SM}px)`);

  const isDesktop = useMediaPredicate(`(min-width: ${BREAKPOINT_MD}px)`);

  const isLargeDesktop = useMediaPredicate(`(min-width: ${BREAKPOINT_LG}px)`);

  const isMinWidthSM = useMediaPredicate(`(min-width: ${BREAKPOINT_SM}px)`);
  const isMinWidthMD = useMediaPredicate(`(min-width: ${BREAKPOINT_MD}px)`);
  const isMinWidthLG = useMediaPredicate(`(min-width: ${BREAKPOINT_LG}px)`);
  const isMinWidthXL = useMediaPredicate(`(min-width: ${BREAKPOINT_XL}px)`);
  const isMinWidth2XL = useMediaPredicate(`(min-width: ${BREAKPOINT_2XL}px)`);

  return {
    isMobile,
    isDesktop,
    isLargeDesktop,
    isMinWidthSM,
    isMinWidthMD,
    isMinWidthLG,
    isMinWidthXL,
    isMinWidth2XL,
  };
};
