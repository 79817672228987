import { useMemo } from "react";
import { useIntl } from "react-intl";

import { BaselineVisitEventVariant } from "../types";
import {
  baselineVisitEventDescriptions,
  getBaselineVisitEventVariant,
} from "../utils";

export interface UseBaselineVisitEventDescriptionProps {
  isVirtualBaselineVisit: boolean;
  careTeamMemberFullName?: string | null | undefined;
  scheduledDate: string;
  telehealthLink: string | null;
  timeZone: string;
}

/**
 * Memoized Baseline Visit Event Description.
 */
export const useBaselineVisitEventDescription = ({
  isVirtualBaselineVisit,
  careTeamMemberFullName,
  scheduledDate,
  telehealthLink,
  timeZone,
}: UseBaselineVisitEventDescriptionProps): string => {
  const { formatDate, formatMessage, formatTime } = useIntl();
  return useMemo(() => {
    if (!scheduledDate) {
      return "";
    }

    const eventVariant: BaselineVisitEventVariant =
      getBaselineVisitEventVariant({
        isVirtualBaselineVisit,
        hasPhysician: !!careTeamMemberFullName,
      });

    const eventDescriptions: Record<BaselineVisitEventVariant, string> =
      baselineVisitEventDescriptions({
        formatDate,
        formatMessage,
        formatTime,
        careTeamMemberFullName,
        scheduledDate,
        telehealthLink,
        timeZone,
      });
    return eventDescriptions[eventVariant];
  }, [
    formatDate,
    formatMessage,
    formatTime,
    isVirtualBaselineVisit,
    careTeamMemberFullName,
    scheduledDate,
    telehealthLink,
    timeZone,
  ]);
};
