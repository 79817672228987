import { Alert, AlertProps } from "@chp/curative_ui";
import { useIntl } from "react-intl";

export type FormattedAlertProps = Omit<AlertProps, "closeButtonText">;

export const FormattedAlert = ({
  onRequestClose,
  testId = "FormattedAlert",
  ...rest
}: FormattedAlertProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const closeButtonOptionalProps = onRequestClose
    ? {
        closeButtonText: formatMessage({
          defaultMessage: "Close alert",
          id: "cvfzg7",
        }),
        onRequestClose,
      }
    : {};
  return <Alert {...closeButtonOptionalProps} testId={testId} {...rest} />;
};
