import {
  ScreenReaderOnly,
  Table,
  TableContentType,
  TableProps,
  TableValueLocation,
  TableValueWithLocation,
} from "@chp/curative_ui";
import { FormattedNumber, useIntl } from "react-intl";

import { generateArrayKey } from "../../utils";

const PROCEDURE_COST_TABLE_TEST_ID = "ProcedureCostTable";

const tableContentTypes: TableProps["contentTypes"] = [
  TableContentType.TEXT,
  TableContentType.NUMERIC,
  TableContentType.NUMERIC,
];

export type ProcedureCostProvider = {
  description?: string;
  name: string;
  location: TableValueLocation;
};

export type ProcedureCostTableRow = {
  allowableAmount: number;
  estimatedCost: number;
  provider: ProcedureCostProvider;
};

export type ProcedureCostTableProps = Pick<
  TableProps,
  "testId" | "className"
> & {
  rows: ProcedureCostTableRow[];
};

const ProcedureCostAmount = ({ value }: { value: number }): JSX.Element => (
  <div className="text-2xl font-semibold">
    <FormattedNumber
      currency="USD"
      style="currency"
      value={value / 100}
      maximumFractionDigits={0}
    />
  </div>
);

export const ProcedureCostTable = ({
  rows,
  className,
  testId = PROCEDURE_COST_TABLE_TEST_ID,
}: ProcedureCostTableProps): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <Table
      contentTypes={tableContentTypes}
      headCellArrayKeyGenerator={(_, index) =>
        ["Provider", "Allowable Amount", "Your Estimated Cost"][index] ?? index
      }
      keys={[
        <ScreenReaderOnly element="span" key="Provider">
          {formatMessage({ defaultMessage: "Provider", id: "xaj9Ba" })}
        </ScreenReaderOnly>,
        formatMessage({ defaultMessage: "Allowable Amount", id: "Envpgp" }),
        formatMessage({ defaultMessage: "Your Estimated Cost", id: "TP6uVC" }),
      ]}
      rowArrayKeyGenerator={() => generateArrayKey()}
      rows={rows.map(({ allowableAmount, estimatedCost, provider }) => [
        // TODO: Add i18n support
        <TableValueWithLocation
          description={provider.description}
          key="provider"
          location={provider.location}
          title={provider.name}
        />,
        <ProcedureCostAmount key="allowableAmount" value={allowableAmount} />,
        <ProcedureCostAmount key="estimatedCost" value={estimatedCost} />,
      ])}
      testId={testId}
      className={className}
    />
  );
};
