import clsx from "clsx";

import { SpacingVariant } from "../../constants";
import { Box, BoxProps } from "../Box";
import {
  CUIComponent,
  CUIComponentProps,
  DisplayVariant,
  PaddingProps,
  TextVariant,
} from "../types";

const LOZENGE_CLASS_NAMES: string[] = [
  "rounded",
  "text-left",
  "bg-surface-neutral",
  "text-textColor-subdued",
];

const padding: PaddingProps = {
  bottom: SpacingVariant.S4,
  left: SpacingVariant.S4,
  right: SpacingVariant.S4,
  top: SpacingVariant.S4,
};

const textVariant = TextVariant.SmRegularTall;

export const lozengeStyleBoxProps = (
  className?: string
): Pick<
  BoxProps<"div">,
  "className" | "padding" | "textVariant" | "display"
> => ({
  className: clsx(LOZENGE_CLASS_NAMES, className),
  padding,
  textVariant,
  display: DisplayVariant.InlineBlock,
});

export type LozengeProps = CUIComponentProps<{
  children: JSX.Element | string;
}>;

export const Lozenge: CUIComponent<LozengeProps> = ({
  children,
  testId = "Lozenge",
  className,
}) => {
  return (
    <Box
      {...lozengeStyleBoxProps(className)}
      element="div"
      data-testid={testId}
    >
      {children}
    </Box>
  );
};
