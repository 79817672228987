import clsx from "clsx";

import { buttonStyleBoxProps, ButtonVariant } from "../Button";
import {
  DISPLAY_CLASS_NAMES,
  PADDING_BOTTOM_CLASS_NAMES,
  PADDING_LEFT_CLASS_NAMES,
  PADDING_RIGHT_CLASS_NAMES,
  PADDING_TOP_CLASS_NAMES,
  TEXT_CLASS_NAMES,
} from "../constants";

/* Classnames to be used to mimic button styling. */
export const linkButtonStyling = (variant: ButtonVariant) => {
  const { className, display, padding, textVariant } =
    buttonStyleBoxProps(variant);

  return clsx(
    className,
    display ? DISPLAY_CLASS_NAMES[display] : null,
    padding?.bottom ? PADDING_BOTTOM_CLASS_NAMES[padding.bottom] : null,
    padding?.left ? PADDING_LEFT_CLASS_NAMES[padding.left] : null,
    padding?.right ? PADDING_RIGHT_CLASS_NAMES[padding.right] : null,
    padding?.top ? PADDING_TOP_CLASS_NAMES[padding.top] : null,
    textVariant ? TEXT_CLASS_NAMES[textVariant] : null
  );
};
