import { Popover } from "@headlessui/react";
import clsx from "clsx";
import { useEffect, useState } from "react";

import { FOCUS_CLASS_NAMES, SpacingVariant } from "../../constants";
import { Box } from "../Box";
import { Icon, IconVariant } from "../Icon";
import { linkStyling } from "../Link";
import { ScreenReaderOnly } from "../ScreenReaderOnly";
import { CUIComponentProps, TextVariant } from "../types";

export type HelpWidgetProps = CUIComponentProps<{
  descriptionText: string;
  headingText: string;
  memberServicesEmail: string;
  memberServicesPhoneNumber?: string | JSX.Element;
  needHelpText?: string;
  pharmacyPhoneNumber?: string | JSX.Element;
  onOpen?: () => void;
  srOnlyOpenLabel: string;
  pharmacyHeadingText: string;
  placement?: "top" | "bottom";
}>;

/**
 * PopoverButton mostly just returns Popover.Button, but also calls onOpen effect with access to Popover isOpen state.
 */
const PopoverButton = ({
  children,
  isOpen,
  onOpen,
  testId,
}: {
  children: JSX.Element;
  isOpen: boolean;
  onOpen?: () => void;
  testId: string;
}): JSX.Element => {
  // if isOpen has changed to true, then call custom onOpen handler
  const [isOpenLast, setIsOpenLast] = useState<boolean>(isOpen);
  useEffect(() => {
    // check if isOpen has changed
    if (isOpen !== isOpenLast) {
      setIsOpenLast(isOpen);
      if (isOpen) {
        onOpen?.();
      }
    }
  }, [isOpen, isOpenLast, onOpen]);

  return (
    <Popover.Button
      className={clsx(
        "rounded-lg bg-surface-neutral p-2",
        "shadow-md-surround flex items-center gap-2",
        FOCUS_CLASS_NAMES
      )}
      data-testid={testId}
    >
      {children}
    </Popover.Button>
  );
};

export const HelpWidget = ({
  className,
  descriptionText,
  headingText,
  memberServicesEmail,
  memberServicesPhoneNumber,
  needHelpText,
  pharmacyPhoneNumber,
  onOpen,
  srOnlyOpenLabel,
  pharmacyHeadingText,
  placement = "top",
  testId = "HelpWidget",
}: HelpWidgetProps) => {
  return (
    <Popover className={clsx("relative", className)} data-testid={testId}>
      {({ open: isOpen }) => (
        <>
          <PopoverButton
            isOpen={isOpen}
            onOpen={onOpen}
            testId={`${testId}__Button`}
          >
            <>
              <Icon
                variant={IconVariant.QUESTION_MARK_CIRCLE}
                size={SpacingVariant.S16}
              />
              {needHelpText}
              <ScreenReaderOnly element="span">
                {srOnlyOpenLabel}
              </ScreenReaderOnly>
            </>
          </PopoverButton>

          <Popover.Panel
            className={clsx(
              "absolute rounded-lg bg-surface-neutral",
              "z-50 w-80 px-4 py-6",
              "shadow-md-surround right-0",
              placement === "top" ? "bottom-12" : "top-12"
            )}
            data-testid={`${testId}__Dialog`}
          >
            <>
              <Box
                element="p"
                textVariant={TextVariant.LgBoldShort}
                margin={{ bottom: SpacingVariant.S8 }}
              >
                {headingText}
              </Box>
              <Box
                element="p"
                textVariant={TextVariant.MdRegularTall}
                margin={{ bottom: SpacingVariant.S16 }}
              >
                {descriptionText}
              </Box>

              <div className="flex items-center gap-2">
                <Icon
                  variant={IconVariant.PHONE}
                  size={SpacingVariant.S16}
                  className="text-gray-800"
                />
                {memberServicesPhoneNumber}
              </div>

              <Box
                element="p"
                textVariant={TextVariant.MdBold}
                margin={{ top: SpacingVariant.S4, bottom: SpacingVariant.S40 }}
                className="flex items-center gap-2"
              >
                <Icon
                  variant={IconVariant.MAIL}
                  size={SpacingVariant.S16}
                  className="text-gray-800"
                />
                <a
                  className={linkStyling}
                  href={`mailto:${memberServicesEmail}`}
                >
                  {memberServicesEmail}
                </a>
              </Box>

              <Box
                element="p"
                textVariant={TextVariant.LgBoldShort}
                margin={{ bottom: SpacingVariant.S8 }}
              >
                {pharmacyHeadingText}
              </Box>

              <div className="flex items-center gap-2">
                <Icon
                  variant={IconVariant.PHONE}
                  size={SpacingVariant.S16}
                  className="text-gray-800"
                />
                {pharmacyPhoneNumber}
              </div>
            </>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};
