import { defaultFormatMessage } from "@chp/shared/features/i18n/utils";
import { parseDateInput } from "@chp/shared/utils/dateTime/format";
import { format, isValid, subYears } from "date-fns";
import { IntlFormatters } from "react-intl";
import * as Yup from "yup";

import { PlainDate } from "../dateTime";
import { dateInputSchema } from "./dateInput";

export enum MinimumAge {
  EIGHTEEN = 18,
  THIRTEEN = 13,
}

export type MinAgeErrorMessageFormatter = (values: {
  minAge: MinimumAge;
}) => string;

const createDefaultFormatMinAgeErrorMessage =
  (
    formatMessage: IntlFormatters["formatMessage"]
  ): MinAgeErrorMessageFormatter =>
  ({ minAge }): string =>
    formatMessage(
      {
        defaultMessage: "You must be at least {minAge} to submit this form.",
        id: "SkM6xS",
      },
      { minAge }
    );

export const MAX_DEPENDENT_AGE_FOR_FLORIDA_EMPLOYERS = 30;
export const MAX_DEPENDENT_AGE_FOR_OTHER_EMPLOYERS = 26;

// max dob for anyone born before now
export const dateOfBirthMax = (): PlainDate => PlainDate.now();
// max dob for anyone at least 18 yrs old
export const dateOfBirthMaxForAdult = (): PlainDate =>
  PlainDate.now().minusYears(18);
// max dob for anyone at least 15 yrs old
export const dateOfBirthMaxAtLeast15Yrs = (): PlainDate =>
  PlainDate.now().minusYears(15);
// max dob for anyone at least 6 months old
export const dateOfBirthMaxAtLeast6Mos = (): PlainDate =>
  PlainDate.now().minusMonths(6);
// min dob for anyone less than 26 yrs old or 30 yrs old if state is Florida
export const dateOfBirthMaxForDependent = (
  state?: string | null
): PlainDate => {
  let minimumAge = 0;
  if (state === "FL") {
    minimumAge = MAX_DEPENDENT_AGE_FOR_FLORIDA_EMPLOYERS;
  } else {
    minimumAge = MAX_DEPENDENT_AGE_FOR_OTHER_EMPLOYERS;
  }

  return PlainDate.now().minusYears(minimumAge).plusDays(1);
};

export const dateOfBirthMin: PlainDate = new PlainDate(1900, 1, 1);

export const dateOfBirthSchema = ({
  formatMessage,
  minAge,
  formatMinAgeErrorMessage = createDefaultFormatMinAgeErrorMessage(
    formatMessage
  ),
}: {
  formatMessage: IntlFormatters["formatMessage"];
  minAge?: MinimumAge;
  formatMinAgeErrorMessage?: MinAgeErrorMessageFormatter;
}) => {
  const schema = dateInputSchema({
    message: formatMessage({
      defaultMessage: "The date of birth you entered is invalid.",
      id: "MLBnhJ",
    }),
  })
    .max(
      dateOfBirthMax().toMMDDYYYYFormat(),
      [
        formatMessage({
          defaultMessage: "The date you entered is invalid.",
          id: "5Z7us0",
        }),
        formatMessage({
          defaultMessage: "Date of birth cannot be in the future.",
          id: "GH6cHC",
        }),
      ].join(" ")
    )
    .min(
      dateOfBirthMin.toMMDDYYYYFormat(),
      [
        formatMessage({
          defaultMessage: "The date you entered is invalid.",
          id: "5Z7us0",
        }),
        formatMessage({
          defaultMessage: "Date of birth must be after 1900.",
          id: "GKDOJ3",
        }),
      ].join(" ")
    );

  if (!minAge) {
    return schema;
  }

  return schema.max(
    subYears(new Date(), minAge),
    formatMinAgeErrorMessage({ minAge })
  );
};

export const dateOfBirthRequiredSchema = ({
  formatMessage,
  messages,
  minAge,
  formatMinAgeErrorMessage,
}: {
  formatMessage: IntlFormatters["formatMessage"];
  messages?: {
    required?: string;
  };
  minAge?: MinimumAge;
  formatMinAgeErrorMessage?: MinAgeErrorMessageFormatter;
}) =>
  dateOfBirthSchema({
    formatMessage,
    minAge,
    formatMinAgeErrorMessage,
  }).required(
    messages?.required ??
      formatMessage({
        defaultMessage: "Date of birth is required.",
        id: "BiACh2",
      })
  );

export const isValidDateOfBirth = (
  dateOfBirth: Date,
  formatMessage: IntlFormatters["formatMessage"] = defaultFormatMessage
): boolean =>
  isValid(parseDateInput(dateOfBirth)) &&
  dateOfBirthSchema({ formatMessage }).isValidSync(dateOfBirth);

export const genericDependentDOBSchema = ({
  schema,
  formatMessage,
}: {
  schema: Yup.DateSchema;
  formatMessage: IntlFormatters["formatMessage"];
}) => {
  return schema.max(
    dateOfBirthMaxAtLeast6Mos().toMMDDYYYYFormat(),
    [
      formatMessage({
        defaultMessage:
          "Dependents under 6 months old cannot be enrolled through the Employer Portal.",
        id: "RGosqF",
      }),
      formatMessage({
        defaultMessage:
          "Reach out to your account representative for assistance.",
        id: "W3RU74",
      }),
    ].join(" ")
  );
};

export const formatDOBforApi = (birthDate: Date | string): string =>
  format(new Date(birthDate), "yyyy-MM-dd");

export const spouseAndLifePartnerDOBSchema = ({
  schema,
  formatMessage,
}: {
  schema: Yup.DateSchema;
  formatMessage: IntlFormatters["formatMessage"];
}) => {
  return schema.max(
    dateOfBirthMaxAtLeast15Yrs().toMMDDYYYYFormat(),
    formatMessage({
      defaultMessage: "Spouse or Life Partner must be at least 15 years old.",
      id: "4heIgg",
    })
  );
};

export const dependentMaxDOBSchema = ({
  schema,
  formatMessage,
  state,
}: {
  schema: Yup.DateSchema;
  formatMessage: IntlFormatters["formatMessage"];
  state?: string | null;
}) => {
  return schema.min(
    dateOfBirthMaxForDependent(state).toMMDDYYYYFormat(),
    [
      formatMessage(
        {
          defaultMessage:
            '"Child" and "Other Relationship" dependents over {maxAge} years old cannot be enrolled through the Employer Portal.',
          id: "iwMkIv",
        },
        {
          maxAge:
            state === "FL"
              ? MAX_DEPENDENT_AGE_FOR_FLORIDA_EMPLOYERS
              : MAX_DEPENDENT_AGE_FOR_OTHER_EMPLOYERS,
        }
      ),
      formatMessage({
        defaultMessage:
          "Reach out to your account representative for assistance.",
        id: "W3RU74",
      }),
    ].join(" ")
  );
};
