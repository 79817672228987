import { EventObject } from "xstate";

export enum MemberCardSideDrawerVariant {
  DOWNLOAD,
  EMAIL,
  REQUEST_PHYSICAL_COPY,
}

interface OpenEmailSideDrawerEvent extends EventObject {
  type: "OPEN_EMAIL_SIDEDRAWER";
}

interface OpenRequestCopySideDrawerEvent extends EventObject {
  type: "OPEN_REQUEST_COPY_SIDEDRAWER";
}

interface OpenDownloadSideDrawerEvent extends EventObject {
  type: "OPEN_DOWNLOAD_SIDEDRAWER";
}

interface SubmitEmailCopyRequestEvent extends EventObject {
  type: "REQUEST_EMAIL_COPY";
  memberId: string;
  emails: { email: string }[];
}

interface SubmitPhysicalCopyRequestEvent extends EventObject {
  type: "REQUEST_PHYSICAL_COPY";
  memberId: string;
}

interface SubmitDownloadEvent extends EventObject {
  type: "DOWNLOAD_MEMBER_CARD";
  bothSidesUrl: string;
  cardDownloadName: string;
  fileFormat: "jpeg" | "png";
}

interface SubmitFailedEvent extends EventObject {
  type: "SUBMIT_FAILED";
}

interface EmailSendSucceededEvent extends EventObject {
  type: "EMAIL_SEND_SUCCEEDED";
}

interface PhysicalCopyRequestSucceededEvent extends EventObject {
  type: "PHYSICAL_COPY_REQUEST_SUCCEEDED";
}

interface DownloadSucceededEvent extends EventObject {
  type: "DOWNLOAD_SUCCEEDED";
}

interface CloseSideDrawerEvent extends EventObject {
  type: "CLOSE_SIDEDRAWER";
}

interface ClearSuccessMessageVariantEvent extends EventObject {
  type: "CLEAR_SUCCESS_VARIANT";
}

interface ClearErrorMessageVariantEvent extends EventObject {
  type: "CLEAR_ERROR_VARIANT";
}

interface ToggleDidRequestPhysicalCopyEvent extends EventObject {
  type: "TOGGLE_DID_REQUEST_PHYSICAL_COPY";
}

export type MemberCardSideDrawerMachineEvents =
  | OpenEmailSideDrawerEvent
  | OpenRequestCopySideDrawerEvent
  | OpenDownloadSideDrawerEvent
  | SubmitEmailCopyRequestEvent
  | SubmitPhysicalCopyRequestEvent
  | SubmitDownloadEvent
  | SubmitFailedEvent
  | EmailSendSucceededEvent
  | PhysicalCopyRequestSucceededEvent
  | DownloadSucceededEvent
  | CloseSideDrawerEvent
  | ClearSuccessMessageVariantEvent
  | ClearErrorMessageVariantEvent
  | ToggleDidRequestPhysicalCopyEvent;
