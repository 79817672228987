import clsx from "clsx";

import {
  linkBaseClassNames,
  linkClassNamesCommon,
  linkClassNamesCommonNoColor,
} from "../../utils";

// common class names that do not include font weight, underline, or colors
const LINK_CLASS_NAMES_LESS_BOLD_NO_UNDERLINE: string[] = [
  ...linkBaseClassNames,
  "font-normal",
];

const LINK_CLASS_NAMES_LESS_BOLD_WITH_COLOR: string[] = [
  ...LINK_CLASS_NAMES_LESS_BOLD_NO_UNDERLINE,
  "text-textColor-highlight-default",
  "hover:text-textColor-highlight-hover",
];

/* Classnames to be used for internal links or wherever link styling is needed (ie. Passing it to a React Router Link Component as className) */
export const linkStyling = clsx(...linkClassNamesCommon, "block");

/* Classnames to be used for internal links or wherever link styling is needed (ie. Passing it to a React Router Link Component as className) */
export const inlineLinkStyling = clsx(...linkClassNamesCommon, "inline");

export const inlineLinkStylingNoColor = clsx(
  ...linkClassNamesCommonNoColor,
  "cui-inline"
);

export const lessBoldInlineLinkStyling = clsx(
  ...LINK_CLASS_NAMES_LESS_BOLD_WITH_COLOR,
  "inline"
);
