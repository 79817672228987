import { ContentfulProviderProps } from "@chp/shared/contexts/ContentfulContext";

export const contentfulProps: Pick<
  ContentfulProviderProps,
  "space" | "accessToken" | "environment"
> = {
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE || "",
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN || "",
  environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT || "",
};
