import {
  defaultDataIdFromObject,
  InMemoryCache,
  InMemoryCacheConfig,
  StoreObject,
  StoreValue,
} from "@apollo/client";
import { IS_PREPROD_DEPLOYED_ENV, IS_PROD_DEPLOYED_ENV } from "@chp/shared/env";
import { captureException } from "@sentry/nextjs";
import { get, has, isEmpty, isNumber } from "lodash-es";
import { v4 as uuidv4 } from "uuid";

const SHOULD_LOG_ERRORS_TO_CONSOLE = !IS_PROD_DEPLOYED_ENV;
const SHOULD_LOG_ERRORS_TO_SENTRY =
  IS_PREPROD_DEPLOYED_ENV || IS_PROD_DEPLOYED_ENV;

/**
 * Each type name below has an array of field paths that are used to create a
 * unique key for every object of that type. Apollo uses this key to determine
 * when an object has changed and needs to be updated in the cache. If multiple
 * key fields are listed, their values will be combined when creating the key.
 * The Lodash `get()` function is used to get each key field value, meaning dot
 * notation is supported for nested fields (e.g. `foo.bar.baz`). All of the key
 * fields listed should be included in any query that includes a field of the
 * corresponding type. For example, any query that includes a field whose type
 * is `Member` should also include the `id` field like so:
 *
 *   member {
 *     id
 *     ...
 *   }
 *
 * Nullable fields should not be used. If any of the fields listed for a type
 * name either (a) is missing from the query or (b) has a null, undefined, or
 * empty value, an error will be logged and a randomly-generated UUID will be
 * used in the cache key in place of the missing field value.
 *
 */
const CACHE_KEY_FIELD_PATHS_BY_TYPE_NAME: Record<string, string[]> = {
  Member: ["id"],
  CareTeamMember: ["id", "elationId"],
};

const getTypeName = (responseObject: StoreObject): string =>
  responseObject.__typename ?? "Unknown";

const getCacheKeyFieldPaths = (typeName: string): string[] =>
  get(CACHE_KEY_FIELD_PATHS_BY_TYPE_NAME, typeName, []);

const getCacheKeyFieldErrorMessage = ({
  responseObject,
  fieldName,
  fieldValue,
}: {
  responseObject: StoreObject;
  fieldName: string;
  fieldValue: StoreValue;
}): string => {
  if (!has(responseObject, fieldName)) {
    return `Field "${fieldName}" was not found in response`;
  }

  if (isEmpty(fieldValue) && !isNumber(fieldValue)) {
    return `Field "${fieldName}" had an empty value`;
  }

  return "";
};

const getCacheKey = ({
  typeName,
  fieldPaths,
  responseObject,
}: {
  typeName: string;
  fieldPaths: string[];
  responseObject: Readonly<StoreObject>;
}): string => {
  if (isEmpty(fieldPaths)) {
    return "";
  }

  const { fieldValues, errorMessages } = fieldPaths.reduce<{
    fieldValues: StoreValue[];
    errorMessages: string[];
  }>(
    (acc, fieldName) => {
      const fieldValue = get(responseObject, fieldName, "");
      const errorMessage = getCacheKeyFieldErrorMessage({
        responseObject,
        fieldName,
        fieldValue,
      });

      acc.fieldValues.push(fieldValue || uuidv4());

      if (errorMessage) {
        acc.errorMessages.push(errorMessage);
      }

      return acc;
    },
    {
      fieldValues: [],
      errorMessages: [],
    }
  );

  if (!isEmpty(errorMessages)) {
    const fullErrorMessage = [
      `The following errors were encountered when creating a cache key for ${typeName}:`,
      ...errorMessages,
    ].join("\n");

    if (SHOULD_LOG_ERRORS_TO_CONSOLE) {
      console.error(fullErrorMessage);
    }

    if (SHOULD_LOG_ERRORS_TO_SENTRY) {
      captureException(fullErrorMessage);
    }
  }

  if (isEmpty(fieldValues)) {
    return "";
  }

  return [typeName, ...fieldValues].join(":");
};

const dataIdFromObject: typeof defaultDataIdFromObject = (
  responseObject,
  context
): string | undefined => {
  const typeName = getTypeName(responseObject);
  const fieldPaths = getCacheKeyFieldPaths(typeName);
  const cacheKey = getCacheKey({
    typeName,
    fieldPaths,
    responseObject,
  });

  if (isEmpty(cacheKey)) {
    return defaultDataIdFromObject(responseObject, context);
  }

  return cacheKey;
};

export const createInMemoryCache = (
  customConfig: InMemoryCacheConfig | undefined
): InMemoryCache =>
  new InMemoryCache({
    ...customConfig,
    dataIdFromObject,
  });
