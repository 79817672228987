import { SpinnerCentered } from "@chp/shared/components/SpinnerCentered";
import { TextWithBoldSubstring } from "@chp/shared/components/text/TextWithBoldSubstring";
import { useKeyPress } from "@chp/shared/hooks/useKeyPress";
import clsx from "clsx";
import {
  MouseEvent as ReactMouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { useSearchAutocompleteInternalContext } from "../contexts";
import type {
  BaseSearchAutocompleteResultData,
  SearchAutocompleteResult,
} from "../types";

const searchResultItemBaseClassNames = ["flex", "w-full", "px-3"];

const searchResultItemInteractableClassNames = [
  ...searchResultItemBaseClassNames,
  "hover:cursor-pointer",
  "hover:text-textColor-highlight-default",
  "focus:text-textColor-highlight-default",
  "focus:outline-none",
  "focus:rounded",
  "focus:ring",
  "focus:ring-inset",
  "focus:ring-blue-500",
];

export type SearchAutocompleteOptionsListProps<
  T extends BaseSearchAutocompleteResultData = BaseSearchAutocompleteResultData,
> = {
  testId?: string;
  className?: string;
  renderSearchResultContent?: (
    result: SearchAutocompleteResult<T>,
    searchInputValue: string
  ) => ReactNode;
  onSearchResultClick?: (
    result: SearchAutocompleteResult<T>
  ) => void | Promise<void>;
};

export const SearchAutocompleteOptionsList = <
  T extends BaseSearchAutocompleteResultData = BaseSearchAutocompleteResultData,
>({
  testId = "search-results",
  renderSearchResultContent,
  onSearchResultClick,
}: SearchAutocompleteOptionsListProps<T>) => {
  const {
    searchResultsLoading,
    searchResultsError,
    searchResultsForAutocomplete,
    searchInputValue,
    noResultsMessage,
    renderErrorMessage,
    setSelectedSearchResult,
    setShouldDisplayAutocomplete,
  } = useSearchAutocompleteInternalContext<T>();

  const containerRef = useRef<HTMLDivElement>(null);

  // start the cursor at -1 so no element is selected
  const [cursor, setCursor] = useState(-1);

  useKeyPress({
    targetKey: "ArrowDown",
    shouldPreventDefault: true,
    onKeyPress: useCallback(
      () =>
        setCursor((prevCursor) =>
          prevCursor < searchResultsForAutocomplete.length - 1
            ? prevCursor + 1
            : prevCursor
        ),
      [searchResultsForAutocomplete.length]
    ),
  });

  useKeyPress({
    targetKey: "ArrowUp",
    shouldPreventDefault: true,
    onKeyPress: useCallback(
      () =>
        setCursor((prevCursor) =>
          prevCursor > 0 ? prevCursor - 1 : prevCursor
        ),
      []
    ),
  });

  const handleSearchResultClick = useCallback(
    async (selectedResult: SearchAutocompleteResult<T>) => {
      setSelectedSearchResult(selectedResult);
      setShouldDisplayAutocomplete(false);

      await onSearchResultClick?.(selectedResult);
    },
    [onSearchResultClick, setSelectedSearchResult, setShouldDisplayAutocomplete]
  );

  useEffect(() => {
    if (cursor === -1) {
      return;
    }

    const childNodes = containerRef.current?.childNodes as
      | HTMLButtonElement[]
      | undefined;

    if (!childNodes) {
      return;
    }

    childNodes.forEach((childNode, index) => {
      if (cursor === index) {
        childNode.focus();
      } else {
        childNode.blur();
      }
    });
  }, [cursor]);

  if (searchResultsLoading) {
    return (
      <div
        data-testid={`${testId}__loading`}
        className={clsx(searchResultItemBaseClassNames)}
      >
        <SpinnerCentered />
      </div>
    );
  }

  if (searchResultsError) {
    return (
      <div
        data-testid={`${testId}__error`}
        className={clsx(searchResultItemBaseClassNames)}
      >
        {renderErrorMessage(searchResultsError)}
      </div>
    );
  }

  if (searchResultsForAutocomplete.length === 0) {
    return (
      <div
        data-testid={`${testId}__no-results-found`}
        className={clsx(searchResultItemBaseClassNames)}
      >
        {noResultsMessage}
      </div>
    );
  }

  return (
    <div
      ref={containerRef}
      data-testid={testId}
      onBlur={() => {
        setCursor(-1);
      }}
    >
      {searchResultsForAutocomplete.map((result, index) => (
        <button
          data-testid={`${testId}__result-${result.key}`}
          key={result.key}
          className={clsx(searchResultItemInteractableClassNames)}
          onClick={(event: ReactMouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            handleSearchResultClick(result);
          }}
          onFocus={() => {
            setCursor(index);
          }}
        >
          {renderSearchResultContent?.(result, searchInputValue) ?? (
            <TextWithBoldSubstring
              fullText={result.label}
              substring={searchInputValue}
            />
          )}
        </button>
      ))}
    </div>
  );
};

export type SearchAutocompleteResultsMenuProps<
  T extends BaseSearchAutocompleteResultData = BaseSearchAutocompleteResultData,
> = SearchAutocompleteOptionsListProps<T> & {
  resultsMenuHeaderContent?: ReactNode;
};

export const SearchAutocompleteResultsMenu = <
  T extends BaseSearchAutocompleteResultData = BaseSearchAutocompleteResultData,
>({
  testId = "search-results",
  className,
  renderSearchResultContent,
  onSearchResultClick,
  resultsMenuHeaderContent,
}: SearchAutocompleteResultsMenuProps<T>) => {
  const { shouldDisplayAutocomplete } =
    useSearchAutocompleteInternalContext<T>();

  if (!shouldDisplayAutocomplete) {
    return null;
  }

  // The max-h-184 value below was calculated as follows:
  // -- Each auto-complete item has a height of 1.5rem
  // -- The auto-complete list container has 0.5rem of top and bottom padding
  // -- We want 7 items to be visible when opening the auto-complete list
  // -- (1.5rem * 7) + (0.5rem * 2) = 11.5rem
  // -- 11.5 * 16px = 184px
  return (
    <div
      tabIndex={-1}
      className={clsx(
        "mt-2 max-h-[184px] overflow-y-scroll rounded border border-blue-600 py-2 text-textColor-default",
        className
      )}
    >
      {resultsMenuHeaderContent}
      <SearchAutocompleteOptionsList
        testId={testId}
        renderSearchResultContent={renderSearchResultContent}
        onSearchResultClick={onSearchResultClick}
      />
    </div>
  );
};
