import { SpacingVariant, Spinner } from "@chp/curative_ui";
import clsx from "clsx";

export const LoadingIndicator = ({
  className,
  testId = "LoadingIndicator",
}: {
  className?: string;
  testId?: string;
}) => {
  return (
    <div
      className={clsx("mt-16 flex flex-col items-center", className)}
      data-testid={testId}
    >
      <Spinner size={SpacingVariant.S64} />
    </div>
  );
};
