import Script from "next/script";

// next/script loads the script and provides the onLoad() callback to run afterwards. Since the SmartlingContextTracker.init() function is not available until after the script is loaded, we declare it globally as a property of the window object to access the function.
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    SmartlingContextTracker: any;
  }
}

/**
 * ### SmartlingContextTracker
 *
 * Send Smartling screenshots for translation ONLY in staging to avoid sharing PHI
 *
 * https://help.smartling.com/hc/en-us/articles/360007996553-JavaScript-Context-Capture-Library
 */
export function SmartlingTracker() {
  /*
    PR environments (eg https://health-<PR_NUMBER>.dev.curative.com/) currently have their NEXT_PUBLIC_DEPLOYED_ENV set to preprod, so we can't can't use that to target just staging.
    At the moment, I'm not sure how to address this, since it seems like both the dev and staging environment use the same docker image.
    So for the time being, we can use a test of the window.location.hostname to make sure the SmartlingTracker is only run in the staging environment
  */
  if (
    typeof window === "undefined" ||
    !/preprod\.curative\.com/.test(window.location.hostname)
  )
    return null;
  return (
    <Script
      src="https://d2c7xlmseob604.cloudfront.net/tracker.min.js"
      onLoad={() => {
        if (!window.SmartlingContextTracker) return;
        window.SmartlingContextTracker.init({
          orgId: "QoxMTFsMrZrq4QWXgEwepA",
        });
      }}
    />
  );
}
