import {
  LATITUDE_MAX,
  LATITUDE_MIN,
  LONGITUDE_MAX,
  LONGITUDE_MIN,
} from "../../constants";

export * from "./coverageStart";
export * from "./dateInput";
export * from "./dateOfBirth";
export * from "./email";
export * from "./memberId";
export * from "./names";
export * from "./password";
export * from "./phoneNumber";
export * from "./ssn";
export * from "./zipCode";

export type ValidateRangeParams = {
  max: number;
  min: number;
  name?: string;
  value: number;
};

/**
 * Check if a value is in the set or range (inclusive) of allowed values.
 *
 * @param params - Params for validation
 *
 * @throws {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RangeError}
 * This exception is thrown if the value is not in the valid range
 */
export const validateRange = (params: ValidateRangeParams): void => {
  const { max, min, name = "Value", value } = params;
  if (!(value >= min && value <= max)) {
    throw new RangeError(`${name} must be between ${min} and ${max}.`);
  }
};

/**
 * Check if latitude is valid
 *
 * @param latitude - Value we want to validate
 *
 * @throws {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RangeError}
 * This exception is thrown if the value is not in the valid range
 */
export const validateLatitude = (latitude: number): void =>
  validateRange({
    max: LATITUDE_MAX,
    min: LATITUDE_MIN,
    name: "Latitude",
    value: latitude,
  });

/**
 * Check if longitude is valid
 *
 * @param longitude - Value we want to validate
 *
 * @throws {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RangeError}
 * This exception is thrown if the value is not in the valid range
 */
export const validateLongitude = (longitude: number): void =>
  validateRange({
    max: LONGITUDE_MAX,
    min: LONGITUDE_MIN,
    name: "Longitude",
    value: longitude,
  });
