import { Badge, BadgeVariant } from "@chp/curative_ui";
import { EnrollmentStatus } from "@chp/shared/generated/employerPortalApi.graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";

type EnrollmentStatusBadgeVariant = Record<EnrollmentStatus, BadgeVariant>;
export type EnrollmentStatusBadgeText = Record<EnrollmentStatus, string>;

export type EnrollmentStatusBadgeProps = {
  enrollmentStatus: EnrollmentStatus;
  hasDisplayedTerminationPending?: boolean;
  badgeTextOverrides?: Partial<EnrollmentStatusBadgeText>;
  badgeVariantOverrides?: Partial<EnrollmentStatusBadgeVariant>;
  testId?: string;
};

export const EnrollmentStatusBadge = ({
  enrollmentStatus,
  hasDisplayedTerminationPending,
  testId = "EnrollmentStatus",
  badgeTextOverrides,
  badgeVariantOverrides,
}: EnrollmentStatusBadgeProps) => {
  const { formatMessage } = useIntl();
  const memberStatusToText = useMemo<EnrollmentStatusBadgeText>(
    () => ({
      [EnrollmentStatus.Active]: hasDisplayedTerminationPending
        ? formatMessage({
            defaultMessage: "Termination pending",
            id: "OF5CAX",
          })
        : formatMessage({
            defaultMessage: "Active",
            id: "3a5wL8",
          }),
      [EnrollmentStatus.Upcoming]: formatMessage({
        defaultMessage: "Upcoming",
        id: "6/aEFZ",
      }),
      [EnrollmentStatus.Expired]: formatMessage({
        defaultMessage: "Expired",
        id: "RahCRH",
      }),
      [EnrollmentStatus.Cancelled]: formatMessage({
        defaultMessage: "Cancelled",
        id: "3wsVWF",
      }),
      [EnrollmentStatus.Cobra]: formatMessage({
        defaultMessage: "COBRA",
        id: "39KvMg",
      }),
      ...badgeTextOverrides,
    }),
    [formatMessage, badgeTextOverrides, hasDisplayedTerminationPending]
  );

  const memberStatusToBadgeVariant: EnrollmentStatusBadgeVariant = {
    [EnrollmentStatus.Active]: hasDisplayedTerminationPending
      ? BadgeVariant.WARNING
      : BadgeVariant.SUCCESS,
    [EnrollmentStatus.Upcoming]: BadgeVariant.INFO,
    [EnrollmentStatus.Expired]: BadgeVariant.WARNING,
    [EnrollmentStatus.Cancelled]: BadgeVariant.ERROR,
    [EnrollmentStatus.Cobra]: BadgeVariant.SUCCESS,
    ...badgeVariantOverrides,
  };

  const variant = memberStatusToBadgeVariant[enrollmentStatus];

  return (
    <Badge
      size="small"
      message={memberStatusToText[enrollmentStatus]}
      testId={`${testId}__${variant}__Badge`}
      variant={variant}
    />
  );
};
