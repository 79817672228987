export const FIRST_HEALTH_PHONE_NUMBER = "1-800-226-5116";
export const HEALTH_PLAN_CUSTOMER_SUPPORT_NUMBER = "855-428-7284";
export const HEALTH_PLAN_CUSTOMER_SUPPORT_NUMBER_MNEMONIC = "855-4-CURATIVE";
export const HEALTH_PLAN_CUSTOMER_SUPPORT_NUMBER_MNEMONIC_AND_DIGITS =
  "855-4-CURATIVE (855-428-7284)";
export const HEALTH_PLAN_CUSTOMER_SUPPORT_NUMBER_MNEMONIC_AND_DIGITS_NUMBERS_FIRST =
  "855-428-7284 (855-4-CURATIVE)";
export const PHARMA_SUPPORT_NUMBER = "888-647-8741";
export const PROVIDER_FAX_NUMBER = "877-734-6537";
export const PROVIDER_SUPPORT_NUMBER = "855-414-1083";
export const FILLABLE_PRIOR_AUTH_FORM_FAX_NUMBER = "877-942-4448";
export const RX_PRIOR_AUTH_FORM_FAX_NUMBER = "888-293-4075";

export const PHARMACY_NPI_AUSTIN = "1174253520";
export const PHARMACY_NPI_DALLAS = "1396422994";
export const PHARMACY_NPI_HOUSTON = "1497432090";
export const PHARMACY_NPI_TAMPA = "1316726029";

// If you change these, you must also change the corresponding values in /shared/constants/Pharmacy.kt
export const PHARMACY_PHONE_NUMBERS_BY_NPI = {
  [PHARMACY_NPI_AUSTIN]: "833-428-7284",
  [PHARMACY_NPI_DALLAS]: "855-543-3124",
  [PHARMACY_NPI_HOUSTON]: "855-544-8242",
  [PHARMACY_NPI_TAMPA]: "855-606-4600",
};

export const PHARMACY_PHONE_NUMBERS_MNEMONIC_AND_DIGITS_BY_NPI = {
  "1174253520": "833-4-CURATIVE (833-428-7284)", // Austin
};
