import { FC } from "react";

import {
  TextWithStyledSubstring,
  TextWithStyledSubstringProps,
} from "./TextWithStyledSubstring";

export type TextWithBoldSubstringProps = Omit<
  TextWithStyledSubstringProps,
  "substringClassname"
>;

export const TextWithBoldSubstring: FC<TextWithBoldSubstringProps> = ({
  className,
  fullText,
  substring,
}) => (
  <TextWithStyledSubstring
    className={className}
    substringClassname="font-bold"
    fullText={fullText}
    substring={substring}
  />
);
