import { compact } from "lodash-es";

import { LocaleCode } from "../../features";

export const getLocaleCodesFromBrowser = ({
  languages,
}: {
  languages?: ReadonlyArray<string>;
}): LocaleCode[] => {
  // get languages from param if present, or from window if present
  const languagesOption: ReadonlyArray<string> | null =
    languages ??
    (typeof window !== "undefined" ? window.navigator.languages : null);
  if (languagesOption === null) return [];

  return compact(
    languagesOption.map((browserLocaleCode) => {
      const simpleBrowserLocaleCode = browserLocaleCode.split("-")[0] ?? "";
      return Object.values(LocaleCode).find(
        (lc) => lc.valueOf() === simpleBrowserLocaleCode
      );
    })
  );
};
