import { FOCUS_CLASS_NAMES } from "@chp/curative_ui";
import clsx from "clsx";
import { useMemo } from "react";

import {
  HEALTH_PLAN_CUSTOMER_SUPPORT_NUMBER,
  HEALTH_PLAN_CUSTOMER_SUPPORT_NUMBER_MNEMONIC,
  HEALTH_PLAN_CUSTOMER_SUPPORT_NUMBER_MNEMONIC_AND_DIGITS,
  HEALTH_PLAN_CUSTOMER_SUPPORT_NUMBER_MNEMONIC_AND_DIGITS_NUMBERS_FIRST,
} from "../../constants";

export enum MemberServicesPhoneNumberLinkVariant {
  FULL,
  FULL_WRAPPED,
  SIMPLE,

  FULL_NUMBERS_FIRST,
  FULL_WRAPPED_NUMBERS_FIRST,
}

export type MemberServicesPhoneNumberLinkProps = {
  className?: string;
  variant?: MemberServicesPhoneNumberLinkVariant;
};

/**
 * clickable phone number link with the option to wrap the non-mnemonic phone number onto a new line
 */
export const MemberServicesPhoneNumberLink = ({
  className,
  variant = MemberServicesPhoneNumberLinkVariant.FULL,
}: MemberServicesPhoneNumberLinkProps) => {
  // The phone number to call for member services in a format suitable for the href attribute.
  const phoneNumberHref = `tel:${HEALTH_PLAN_CUSTOMER_SUPPORT_NUMBER}`;

  const content = useMemo(() => {
    let phoneNumber;

    switch (variant) {
      case MemberServicesPhoneNumberLinkVariant.SIMPLE:
        phoneNumber = HEALTH_PLAN_CUSTOMER_SUPPORT_NUMBER;
        break;
      case MemberServicesPhoneNumberLinkVariant.FULL:
        phoneNumber = HEALTH_PLAN_CUSTOMER_SUPPORT_NUMBER_MNEMONIC_AND_DIGITS;
        break;
      case MemberServicesPhoneNumberLinkVariant.FULL_WRAPPED:
        phoneNumber = (
          <div className="flex flex-wrap">
            <div className="mr-1">
              {HEALTH_PLAN_CUSTOMER_SUPPORT_NUMBER_MNEMONIC}
            </div>
            <div>{`(${HEALTH_PLAN_CUSTOMER_SUPPORT_NUMBER})`}</div>
          </div>
        );
        break;

      case MemberServicesPhoneNumberLinkVariant.FULL_NUMBERS_FIRST:
        phoneNumber =
          HEALTH_PLAN_CUSTOMER_SUPPORT_NUMBER_MNEMONIC_AND_DIGITS_NUMBERS_FIRST;
        break;
      case MemberServicesPhoneNumberLinkVariant.FULL_WRAPPED_NUMBERS_FIRST:
        phoneNumber = (
          <div className="flex flex-wrap">
            <div className="mr-1">{HEALTH_PLAN_CUSTOMER_SUPPORT_NUMBER}</div>
            <div>{`(${HEALTH_PLAN_CUSTOMER_SUPPORT_NUMBER_MNEMONIC})`}</div>
          </div>
        );
    }

    return phoneNumber;
  }, [variant]);

  return (
    <a
      className={clsx(FOCUS_CLASS_NAMES, "!underline", className)}
      href={phoneNumberHref}
    >
      {content}
    </a>
  );
};

export type PhoneNumberLinkProps = {
  phoneNumber: string;
  className?: string;
  variant?: MemberServicesPhoneNumberLinkVariant;
};

/**
 * clickable phone number link
 */
export const PhoneNumberLink = ({
  className,
  phoneNumber,
}: PhoneNumberLinkProps) => {
  // The phone number to call for services in a format suitable for the href attribute.
  const phoneNumberHref = `tel:${phoneNumber}`;

  return (
    <a href={phoneNumberHref} className={clsx("!underline", className)}>
      {phoneNumber}
    </a>
  );
};
