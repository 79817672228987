import { SideNavbar, SideNavbarProps } from "@chp/curative_ui";
import { useIntl } from "react-intl";

type FormattedSideNavbarProps = Omit<
  SideNavbarProps,
  | "openMobileMenuButtonAriaLabel"
  | "closeMobileMenuButtonAriaLabel"
  | "expandDesktopMenuScreenReaderText"
  | "collapseDesktopMenuBtnText"
>;

export const FormattedSideNavbar = ({
  logoLinkAriaLabel,
  testId = "FormattedSideNavbar",
  ...rest
}: FormattedSideNavbarProps) => {
  const { formatMessage } = useIntl();

  return (
    <SideNavbar
      logoLinkAriaLabel={logoLinkAriaLabel}
      collapseDesktopMenuBtnText={formatMessage({
        defaultMessage: "Collapse",
        id: "W/V6+Y",
      })}
      expandDesktopMenuScreenReaderText={formatMessage({
        defaultMessage: "Expand menu",
        id: "ui7zcH",
      })}
      openMobileMenuButtonAriaLabel={formatMessage({
        defaultMessage: "Open menu",
        id: "bdcMGy",
      })}
      closeMobileMenuButtonAriaLabel={formatMessage({
        defaultMessage: "Close menu",
        id: "dg8ZEb",
      })}
      testId={testId}
      {...rest}
    />
  );
};
