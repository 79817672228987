import { ApolloClient, useApolloClient } from "@apollo/client";
import { Auth0Client } from "@auth0/auth0-spa-js";
import {
  Auth0ErrorCode,
  createMockMember,
  createMockSubscriberGroup,
  FCWithChildren,
  fetchAccessToken,
  getLocaleCodeFromCookie,
  IS_DEV,
  isTokenExpired,
  LocaleCode,
  setAccessTokenForApollo,
  useEffectOnce,
  useMemberPortalMixpanelContext,
} from "@chp/shared";
import {
  ContactMethod,
  Language,
  OnboardingStatusType,
  Relationship,
  User,
  UserType,
} from "@chp/shared/generated/memberPortalApi.graphql";
import {
  LDSingleKindContext,
  useLDClient,
} from "launchdarkly-react-client-sdk";
import mixpanel from "mixpanel-browser";
import { NextRouter, useRouter } from "next/router";
import qs from "qs";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { MessageDescriptor, useIntl } from "react-intl";

import { useAuth0Client } from "~/utils/auth0Utils";

import {
  GetActiveUserDocument,
  GetActiveUserQuery,
} from "../state/effects/registrationEffects.generated";
import {
  AVAILABLE_UNAUTHENTICATED_ROUTES,
  DASHBOARD_ROUTE,
  ONBOARDING_ROUTE,
  PUBLIC_RXTRANSFER_NEW_ROUTE,
} from "../utils/constants";

export const MOCK_CYPRESS_ACTIVE_USER = {
  id: 1,
  email: "abc",
  canAccessTelehealth: true,
  isActive: true,
  userType: UserType.Member,
  employers: [],
  employerPermissions: [],
  onboardingStatus: OnboardingStatusType.Complete,
  preferences: {
    preferredContactMethod: ContactMethod.DoNotContact,
    preferredLanguage: Language.English,
    subscribedToNonTransactionalEmails: false,
  },
  member: createMockMember({
    subscriberGroup: createMockSubscriberGroup({
      dependents: [
        createMockMember({
          externalId: "CM016169805",
          familyName: "Beckie",
          givenName: "Hemple",
          fullName: "Beckie Hemple",
          relationship: Relationship.Spouse,
          memberCardUrl: "https://curative.com/member-cards/0000000001.png",
        }),
      ],
    }),
  }),
  canViewMembersDetails: false,
  canViewPermissionsDashboard: false,
  canViewProviderDataManagement: false,
  canViewRxInvoiceTool: false,
  canViewSchedulingTools: false,
  canViewEmployerPortalManagement: false,
  hasEmployerPortalSuperuserAccess: false,
  employersAdminsOnly: [],
};

export const CHECK_TOKEN_HEARTBEAT_INTERVAL = 10000;
const AUTH_TIMEOUT_IN_SECONDS = 60; // Same as SDK default

export interface AuthContextType {
  activeUser: User | null;
  errorMessage: string | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  hasCompletedOnboarding: boolean;
  // on login, check if we should update user to match locale code
  localeCodeFromLogin: LocaleCode | null;
  setLoggedInAs: (activeUser: User) => void;
  completeOnboarding: () => void;
  logout: (returnTo?: string) => void;
  login: () => void;
  setErrorMessage: (errorMessage: string | null) => void;
  setLocaleCodeFromLogin: (localeCodeFromLogin: LocaleCode | null) => void;
  refreshSession: () => Promise<void>;
}

type AuthContextState = Pick<
  AuthContextType,
  | "activeUser"
  | "isLoading"
  | "errorMessage"
  | "isAuthenticated"
  | "hasCompletedOnboarding"
  | "localeCodeFromLogin"
>;

const authContextDefaults: AuthContextType = {
  activeUser: null,
  errorMessage: null,
  isAuthenticated: false,
  isLoading: true,
  hasCompletedOnboarding: false,
  localeCodeFromLogin: null,
  setLoggedInAs: () => undefined,
  completeOnboarding: () => undefined,
  logout: () => undefined,
  login: () => undefined,
  setErrorMessage: () => undefined,
  setLocaleCodeFromLogin: () => undefined,
  refreshSession: () => Promise.resolve(),
};

export const AuthContext =
  React.createContext<AuthContextType>(authContextDefaults);

const AuthProvider: FCWithChildren = ({ children }) => {
  const router = useRouter();
  const auth0Client = useAuth0Client();
  const apolloClient = useApolloClient();
  const ldClient = useLDClient();
  const { isMixpanelLoaded } = useMemberPortalMixpanelContext();
  const { formatMessage } = useIntl();
  const shouldSkipAuthForCypress = () =>
    "Cypress" in window &&
    localStorage.getItem("isSmokeTest") !== "true" &&
    router.query.skipAuthMock !== "true" &&
    !router.query.code &&
    !router.query.state;

  const [authContextState, setAuthContextState] = useState<AuthContextState>({
    activeUser: null,
    isLoading: true,
    errorMessage: null,
    isAuthenticated: false,
    hasCompletedOnboarding: false,
    localeCodeFromLogin: null,
  });

  const authContext: AuthContextType = {
    ...authContextState,
    setLoggedInAs: (activeUser: User) => {
      setAuthContextState((previousState) => ({
        ...previousState,
        isAuthenticated: true,
        hasCompletedOnboarding:
          activeUser.onboardingStatus === OnboardingStatusType.Complete,
        activeUser,
        isLoading: false,
        errorMessage: null,
      }));
    },
    completeOnboarding: () => {
      setAuthContextState((previousState) => ({
        ...previousState,
        isAuthenticated: true,
        hasCompletedOnboarding: true,
        isLoading: false,
        activeUser: {
          ...(previousState.activeUser as User),
          onboardingStatus: OnboardingStatusType.Complete,
        },
      }));
    },
    logout: (returnTo?: string) => {
      setAccessTokenForApollo(null);
      setAuthContextState({
        activeUser: null,
        isLoading: false,
        errorMessage: null,
        isAuthenticated: false,
        hasCompletedOnboarding: false,
        localeCodeFromLogin: null,
      });

      auth0Client.logout({
        openUrl: async (url: string) => {
          window.location.replace(returnTo ?? url);
        },
      });
    },
    login: () =>
      auth0Client.loginWithRedirect({
        authorizationParams: {
          scope: "openid profile email offline_access",
          prompt: "login",
        },
      }),
    setErrorMessage: (errorMessage: string | null) => {
      setAuthContextState((prevState) => ({
        ...prevState,
        errorMessage,
        isLoading: false,
      }));
    },
    setLocaleCodeFromLogin: (localeCodeFromLogin: LocaleCode | null) => {
      setAuthContextState((prevState) => ({
        ...prevState,
        localeCodeFromLogin,
      }));
    },
    refreshSession: () =>
      getOrRefreshAccessToken(
        authContext,
        auth0Client,
        apolloClient,
        router,
        formatMessage,
        setAuthContextState
      ),
  };

  // When the AuthProvider first mounts (and never again thereafter), kick off
  // the asynchronous process of:
  // 1. Handling any OAuth response in the query string (which we expect to be
  //    present if we were redirected to this page from Auth0's login page)
  // 2. Checking whether the user can get/refresh an access token
  useEffectOnce(() => {
    if (shouldSkipAuthForCypress()) {
      authContext.setLoggedInAs({
        ...MOCK_CYPRESS_ACTIVE_USER,
        onboardingStatus:
          router.query.onboarding === "true"
            ? OnboardingStatusType.Incomplete
            : OnboardingStatusType.Complete,
      });
      return;
    }

    initializeAuth0Context(
      authContext,
      auth0Client,
      apolloClient,
      router,
      formatMessage,
      setAuthContextState
    );
  });

  // Run every 10 seconds, attempts to refresh token or take user to log in if cannot
  useEffect(() => {
    // This logic doesn't work with the fake auth token used in Cypress tests
    if (shouldSkipAuthForCypress()) {
      return;
    }

    const interval = setInterval(() => {
      const accessToken = fetchAccessToken();
      if (accessToken && isTokenExpired(accessToken)) {
        getOrRefreshAccessToken(
          authContext,
          auth0Client,
          apolloClient,
          router,
          formatMessage,
          setAuthContextState
        );
      }
    }, CHECK_TOKEN_HEARTBEAT_INTERVAL);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ("Cypress" in window) return;
    const ldContext = ldClient?.getContext();
    if (
      authContext.activeUser &&
      authContext.activeUser.email !== ldContext?.email
    ) {
      const userContext: LDSingleKindContext = {
        kind: "user",
        key: ldContext?.key as string,
        email: authContext.activeUser.email,
      };
      ldClient?.identify(userContext);
    }
  }, [ldClient, authContext.activeUser]);

  useEffect(() => {
    if (!authContext.activeUser || IS_DEV || !isMixpanelLoaded) return;
    mixpanel.identify(authContext.activeUser.id.toString());
    const member = authContext.activeUser.member;
    if (member) {
      mixpanel.people.set({
        "Member ID": member.externalId,
        "Division ID": member.displayedEnrollment?.divisionId,
      });
    }
  }, [isMixpanelLoaded, authContext?.activeUser]);

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};

const getOrRefreshAccessToken = async (
  authContext: AuthContextType,
  auth0Client: Auth0Client,
  apolloClient: ApolloClient<object>,
  router: NextRouter,
  formatMessage: (message: MessageDescriptor) => string,
  setAuthContextState: Dispatch<SetStateAction<AuthContextState>>
) => {
  if (
    !authContext.isAuthenticated &&
    AVAILABLE_UNAUTHENTICATED_ROUTES.some((route) =>
      router.pathname.includes(route)
    )
  ) {
    setAuthContextState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
    return;
  }

  try {
    const accessToken = await auth0Client.getTokenSilently({
      timeoutInSeconds: AUTH_TIMEOUT_IN_SECONDS,
    });

    setAccessTokenForApollo(accessToken);

    // The reason we don't check for `ONBOARDING_ROUTE` as part of `AVAILABLE_UNAUTHENTICATED_ROUTES`
    // is because we need to redirect the user to the dashboard if they are already logged in, unlike other
    // unauthenticated routes that do not require the redirect.
    if (router.pathname.includes(ONBOARDING_ROUTE)) {
      await router.replace(DASHBOARD_ROUTE);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (
      error.error === Auth0ErrorCode.LOGIN_REQUIRED ||
      error.error === Auth0ErrorCode.MISSING_REFRESH_TOKEN ||
      error.error === Auth0ErrorCode.INVALID_GRANT
    ) {
      setAuthContextState({
        ...authContext,
        isLoading: false,
      });

      if (
        !router.pathname.includes(ONBOARDING_ROUTE) &&
        !router.pathname.includes(PUBLIC_RXTRANSFER_NEW_ROUTE)
      ) {
        await router.replace("/");
      }
      return;
    }

    // we got some other unexpected error
    console.error(
      "Encountered an unexpected Auth0 error from getTokenSilently",
      error
    );

    authContext.setErrorMessage(
      formatMessage({
        defaultMessage:
          "An unexpected error occurred, please try to log in again",
        id: "cMl0Dq",
      })
    );
    router.replace("/");

    throw new Error(
      `Unexpected error from Auth0 getTokenSilently: ${error.message ?? error}`
    );
  }

  try {
    const getUserResponse = await apolloClient.query<GetActiveUserQuery>({
      query: GetActiveUserDocument,
      fetchPolicy: "network-only",
    });
    authContext.setLoggedInAs(getUserResponse.data.activeUser as User);
  } catch (e) {
    console.error(e);
    authContext.setErrorMessage(
      formatMessage({
        defaultMessage:
          "An unexpected error occurred, please try to log in again",
        id: "cMl0Dq",
      })
    );
    router.replace("/");
    return;
  }
};

export const initializeAuth0Context = async (
  authContext: AuthContextType,
  auth0Client: Auth0Client,
  apolloClient: ApolloClient<object>,
  router: NextRouter,
  formatMessage: (message: MessageDescriptor) => string,
  setAuthContextState: Dispatch<SetStateAction<AuthContextState>>
) => {
  const { setErrorMessage } = authContext;

  const searchParams = qs.parse(window.location.search.slice(1));

  // if we have a code in the url as a search param, it means we were just redirected from auth0
  const urlHasRedirectParams =
    (searchParams.code && searchParams.state) || searchParams.error;

  if (urlHasRedirectParams) {
    // we parse the code from the url and use it to get a token via the auth0 sdk
    try {
      await auth0Client.handleRedirectCallback();

      // update localeCodeFromLogin from locale cookie on successful login event
      const { setLocaleCodeFromLogin } = authContext;
      setLocaleCodeFromLogin(getLocaleCodeFromCookie({}));

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // we have an attempt to load the page with invalid parameters which we
      // could not parse (expired/etc)
      console.error(
        "Received an error response from Auth0. Error:",
        error.error,
        "Error description:",
        error.error_description
      );

      setErrorMessage(
        typeof error.error_description == "string"
          ? error.error_description
          : formatMessage({
              defaultMessage:
                "An unexpected error occurred, please try to log in again",
              id: "cMl0Dq",
            })
      );

      // go no further since we encountered an error
      return;
    }
  }

  await getOrRefreshAccessToken(
    authContext,
    auth0Client,
    apolloClient,
    router,
    formatMessage,
    setAuthContextState
  );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
