export enum AdminPortalMixpanelUiIdentifierName {
  LEGAL_AGREEMENTS_QUERY = "legal-agreements-query",
  MEMBER_CLAIMS_HISTORY_QUERY = "member-claims-history-query",
  MEMBER_COMMUNICATION_HISTORY_QUERY = "member-communication-history-query",
  MEMBER_SPEND_APPROVAL_HISTORY_QUERY = "member-spend-approval-history-query",
  MEMBER_ZERO_CARD_PAYMENT_AUTHORIZATION_HISTORY_QUERY = "member-zero-card-payment-authorization-history-query",
  MEMBER_DETAILS_QUERY = "member-details-query",
  MEMBER_E_SIGNATURES_QUERY = "member-e-signatures-query",
  MEMBER_RX_TRANSFERS_QUERY = "member-rx-transfers-query",
  MEMBER_SEARCH_QUERY = "member-search-query",
  UPDATE_MEMBER_EMAIL_FORM = "update-member-email-form",
  BASELINE_SCHEDULE_APPOINTMENT = "baseline-schedule-appointment",
  BASELINE_CONTINUE_WITH_FREEDOM_MODE = "baseline-continue-with-freedom-mode",
  BASELINE_SCHEDULE_FREEDOM_APPOINTMENT = "baseline-schedule-freedom-appointment",
  UPDATE_PERMISSIONS = "update-permissions",
  UPDATE_RX_INVOICE = "update-rx-invoice",

  CREATE_CARE_TEAM_MEMBER = "create-care-team-member",
  CREATE_CARE_NAVIGATOR = "create-care-navigator",
  EDIT_CARE_NAVIGATOR = "edit-care-navigator",
  EDIT_CLINICIAN = "edit-clinician",

  PROVIDER_SLIDE_OVER = "provider-slide-over",
  ARCHIVE_PROVIDER = "archive-provider",

  OFFICE_SLIDE_OVER = "office-slide-over",
  ARCHIVE_OFFICE = "archive-office",

  FACILITY_SLIDE_OVER = "facilility-slide-over",
  ARCHIVE_FACILITY = "archive-facility",

  DELETE_OVERRIDE = "delete-override",

  EOB_PRESIGNED_URL_QUERY = "eob-presigned-url-query",
}
