import {
  AppointmentType,
  BaselineStatus,
  BaselineVisitStatus,
  BasicPrescriptionDeliveryRequest,
  CareNavigator,
  CareTeamMemberLanguages,
  Claim,
  ClaimStatus,
  ClaimSystem,
  ClaimType,
  Clinician,
  CoveragePackage,
  CoveragePeriod,
  Enrollment,
  EnrollmentStatus,
  EnrollmentType,
  FundingType,
  IdentityVerificationResponse,
  Language,
  Member,
  MemberAddress,
  MemberPaymentCard,
  MemberPaymentCardStatus,
  MemberPaymentCardType,
  MemberPlanYear,
  MemberPortalRegistrationStatus,
  MemberPreferences,
  Office,
  OnboardingStatusType,
  Plan,
  PlanType,
  PlanYearAccumulator,
  PrescriptionDeliveryRequest,
  PrescriptionDeliveryRequestItem,
  ProviderFlat,
  ProviderSpecialty,
  Relationship,
  SubscriberGroup,
  User,
  UserType,
  Visit,
  VisitType,
  VisitTypeInfo,
} from "@chp/shared/generated/memberPortalApi.graphql";
import { RecursivePartial } from "@chp/shared/types";
import { addDays, addHours, addYears, format, subYears } from "date-fns";
import { merge } from "lodash-es";

import {
  PackagedDrug,
  VisitLocation,
} from "../../generated/adminPortalApi.graphql";
import {
  EmployeeMember,
  Employer,
} from "../../generated/employerPortalApi.graphql";
import { mockCoverageInfo } from "./mocks";

const yearMonthDayFormat = (date: Date) => format(date, "yyyy-MM-dd");

export const createMockClaim = (overrides?: RecursivePartial<Claim>): Claim => {
  const base: Claim = {
    referenceId: "1",
    allowableAmount: 247.65,
    billedAmount: 496,
    checkNumber: "210493 - EOB",
    copayAmount: 0,
    memberExternalId: "memberExternalId_1",
    paidAmount: 247.65,
    providerName: "Dinkle",
    serviceStartDate: "2022-10-31",
    serviceEndDate: "2022-11-31",
    status: ClaimStatus.Processed,
    type: ClaimType.Pharmacy,
    claimSystem: ClaimSystem.Vba,
  };

  return merge(base, overrides);
};

export const createMockPlan = (overrides?: RecursivePartial<Plan>): Plan => {
  const base: Plan = {
    externalId: "1234",
    coveragePackage: CoveragePackage.Ppop,
    hasHighDeductible: false,
    planType: PlanType.Noop,
  };

  return merge(base, overrides) as Plan;
};

export const createMockCareNavigator = (
  overrides?: RecursivePartial<CareNavigator>
): CareNavigator => {
  const base: CareNavigator = {
    id: 1,
    firstName: "Jane",
    lastName: "Doe",
    fullName: "Jane Doe",
    email: "careteam518@curative.com",
    elationTag: "CN:FALLBACK",
    photoUrl: null,
    phoneNumber: "555-55-55-55",
    isActive: true,
    __typename: "CareNavigator",
    elationId: 0,
    languages: [CareTeamMemberLanguages.English],
    handledAppointmentTypes: [AppointmentType.InPerson],
    activeForScheduling: false,
    eligibleForAutoAssignment: false,
  };

  return merge(base, overrides);
};

export const createMockAccumulator = (
  overrides?: RecursivePartial<PlanYearAccumulator>
): PlanYearAccumulator => {
  const base: PlanYearAccumulator = {
    memberDedInNetUsed: 0,
    memberDedInNetMax: 0,
    memberDedOONUsed: 0,
    memberDedOONMax: 0,
    familyDedInNetUsed: 0,
    familyDedInNetMax: 0,
    familyDedOONUsed: 0,
    familyDedOONMax: 0,
    memberOOPInNetUsed: 0,
    memberOOPInNetMax: 0,
    memberOOPOONUsed: 0,
    memberOOPOONMax: 0,
    familyOOPInNetUsed: 0,
    familyOOPInNetMax: 0,
    familyOOPOONUsed: 0,
    familyOOPOONMax: 0,
  };

  return merge(base, overrides);
};

export const createMockEnrollment = (
  overrides?: RecursivePartial<Enrollment>
): Enrollment => {
  const nowDate = new Date();

  const base: Enrollment = {
    memberId: "123456789",
    planId: "PLANID",
    status: EnrollmentStatus.Active,
    endDate: yearMonthDayFormat(addYears(nowDate, 1)),
    startDate: yearMonthDayFormat(subYears(nowDate, 1)),
    type: EnrollmentType.FullyInsured,
    hasMetINOOPMax: false,
    hasMetOONOOPMax: false,
    hasMetINDeductible: false,
    hasMetOONDeductible: false,
    divisionId: "123",
    groupCoverageStart: "2023-05-01",
    groupId: "123",
    subscriberStartDate: "2023-05-01",
    plan: createMockPlan(),
    benefitsBookletUrl: "",
    qualifiesForAutoZeroCardApprovals: false,
  };

  return merge(base, overrides);
};

export const createClinician = (
  overrides?: RecursivePartial<Clinician>
): Clinician => {
  const base: Clinician = {
    id: 1,
    isActive: true,
    firstName: "Default",
    lastName: "Member",
    credentials: "NP",
    elationId: 1234,
    providesPrimaryCare: false,
    __typename: "Clinician",
  };

  return merge(base, overrides);
};

export const createVisitTypeInfo = (
  overrides?: RecursivePartial<VisitTypeInfo>
): VisitTypeInfo => {
  const base: VisitTypeInfo = {
    visitType: VisitType.BaselineVisitNewMember,
    visitTypeId: 1,
  };

  return merge(base, overrides);
};

export const createVisit = (overrides?: RecursivePartial<Visit>): Visit => {
  const nowDate = new Date();

  const base: Visit = {
    visitStartAt: yearMonthDayFormat(addDays(nowDate, 30)),
    visitEndAt: yearMonthDayFormat(addDays(nowDate, 30)),
    isVirtual: false,
    memberId: "123456789",
    visitTypeInfo: createVisitTypeInfo(),
    leadClinician: createClinician(),
    location: VisitLocation.InPerson,
    __typename: "Visit",
  };

  return merge(base, overrides);
};

export const createBaselineVisitStatus = (
  overrides?: RecursivePartial<BaselineVisitStatus>
): BaselineVisitStatus => {
  const nowDate = new Date();
  const defaultMock: BaselineVisitStatus = {
    __typename: "BaselineVisitStatus",
    baselineStatus: BaselineStatus.Scheduled,
    baselineVisit: null,
    completeAt: null,
    daysUntilDueDate: 10,
    displayedDueDate: yearMonthDayFormat(addDays(subYears(nowDate, 1), 120)),
    originalDueDate: yearMonthDayFormat(addDays(subYears(nowDate, 1), 120)),
    healthPlanMemberToken: "uuid-token",
    isBeforeDueDate: true,
    isComplete: false,
    visitRequired: true,
  };
  return merge(defaultMock, overrides);
};

export const createMemberPlanYear = (
  overrides?: RecursivePartial<MemberPlanYear>
): MemberPlanYear => {
  const baselineVisitStatusFn: () => BaselineVisitStatus = () =>
    ({ visitRequired: true }) as BaselineVisitStatus;

  const nowDate = new Date();
  const base: MemberPlanYear = {
    memberStartDate: yearMonthDayFormat(nowDate),
    memberStartDateAge: 42,
    baselineVisitStatus: baselineVisitStatusFn(),
    divisionId: "",
    employerEndAt: "",
    employerEndDate: "",
    employerStartAt: "",
    employerStartDate: "",
    enrollmentTimezone: "",
    latestPlanId: "",
    memberEndAt: "",
    memberEndDate: "",
    memberId: "",
    memberStartAt: "",
    originalPlanId: "",
    latestTier: "",
    planYear: 0,
    status: EnrollmentStatus.Active,
    subscriberId: "",
    isLateEnroll: false,
    isPlanHighDeductible: false,
    claimSystem: ClaimSystem.Vba,
    planFundingType: FundingType.FullyFunded,
    planCoveragePackage: CoveragePackage.Ppop,
  };

  return merge(base, overrides);
};

export const createMockCoveragePeriod = (
  overrides?: RecursivePartial<CoveragePeriod>
): CoveragePeriod => {
  const nowDate = new Date();

  const base: CoveragePeriod = {
    groupId: "123",
    divisionId: "123",
    groupCoverageStart: "2023-05-01",
    subscriberStartDate: "2023-05-01",
    startDate: yearMonthDayFormat(subYears(nowDate, 1)),
    endDate: yearMonthDayFormat(addYears(nowDate, 1)),
  };

  return merge(base, overrides);
};

export const createMockMemberPreferences = (
  overrides?: RecursivePartial<MemberPreferences>
): MemberPreferences => {
  const base: MemberPreferences = {
    preferredLanguage: Language.English,
    subscribedToNonTransactionalEmails: true,
  };

  return merge(base, overrides);
};

export const createMockMemberAddress = (
  overrides?: RecursivePartial<MemberAddress>
): MemberAddress => {
  const base: MemberAddress = {
    city: "Foo City",
    state: "ABC",
    street: "123 Main Street",
    street2: "",
    zipCode: "12345",
  };

  return merge(base, overrides);
};

export const createMockSubscriberGroup = (
  overrides?: RecursivePartial<SubscriberGroup>
): SubscriberGroup => {
  const base: SubscriberGroup = {
    subscriberId: "123",
    // NOTE: calling createMockMember here will cause a recursion issue
    dependents: [],
  };

  return merge(base, overrides);
};

export const createMockMember = (
  overrides?: RecursivePartial<Member>
): Member => {
  const base: Member = {
    birthDate: "2004-03-24",
    id: "CM016169804",
    externalId: "CM016169804",
    familyName: "Treaster",
    givenName: "Sunni",
    fullName: "Sunni Treaster",
    preferences: { subscribedToNonTransactionalEmails: true },
    middleName: null,
    relationship: Relationship.Self,
    isMinor: false,
    portalRegistrationStatus: MemberPortalRegistrationStatus.Registered,
    address: createMockMemberAddress(),
    latestMemberPlanYear: createMemberPlanYear(),
    allMemberPlanYears: [createMemberPlanYear()],
    displayedEnrollmentFromClosestMemberPlanYear: createMockEnrollment(),
    displayedEnrollment: createMockEnrollment(),
    displayedCoveragePeriod: createMockCoveragePeriod(),
    claims: [],
    closestPlan: createMockPlan(),
    eSignatures: [],
    hasActiveEnrollment: true,
    isMinorAtEffectiveDate: false,
    planYears: [],
    subscriberAddress: createMockMemberAddress(),
    subscriberGroup: createMockSubscriberGroup(),
    subscriberId: "12345",
    memberCardUrl: "https://curative.com/member-cards/0000000000.png",
    accumulator: createMockAccumulator(),
    careNavigator: createMockCareNavigator(),
    hasClinicalDocuments: true,
    paymentCards: [],
    isPolicyholderVisibilityEnabled: true,
    __typename: "Member",
  };

  return merge(base, overrides);
};

export const createMockUser = (overrides?: RecursivePartial<User>): User => {
  const base: User = {
    addressId: 1,
    canAccessTelehealth: false,
    email: "",
    id: 1,
    onboardingStatus: OnboardingStatusType.Complete,
    preferences: createMockMemberPreferences(),
    member: createMockMember(),
    isActive: true,
    userType: UserType.Member,
    canViewMembersDetails: false,
    canViewPermissionsDashboard: false,
    canViewProviderDataManagement: false,
    canViewRxInvoiceTool: false,
    canViewSchedulingTools: false,
    canViewEmployerPortalManagement: false,
    hasEmployerPortalSuperuserAccess: false,
    employers: [],
    employersAdminsOnly: [],
  };

  return merge(base, overrides);
};

export const createMockProviderFlat = (
  overrides?: RecursivePartial<ProviderFlat>
): ProviderFlat => {
  const base: ProviderFlat = {
    firstName: "Robert",
    lastName: "Hoch",
    fullName: "Robert Hoch",
    latitude: 41.674728,
    longitude: -70.297699,
    officeId: "02e82e43721e29ae891f7512c5165f5e",
    id: "10a7ebec01d6a0bc0846d4841dbfc50028",
    providerId: "a7ebec01d6a0bc0846d4841dbfc50028",
    providerNetworksList: ["Not Zero Card Only"],
  };

  return merge(base, overrides);
};

export const createMockOffice = (
  overrides?: RecursivePartial<Office>
): Office => {
  const base: Office = {
    officeId: "02e82e43721e29ae891f7512c5165f5e",
    officeName: "Dunder Mifflin",
  };

  return merge(base, overrides);
};

export const createMockBasicPrescriptionDeliveryRequest = (
  overrides?: RecursivePartial<BasicPrescriptionDeliveryRequest>
): BasicPrescriptionDeliveryRequest => {
  const base: BasicPrescriptionDeliveryRequest = {
    id: 1,
    totalCopay: 0,
    libertyRxNpi: "123456",
    firstName: "Cruz",
  };

  return merge(base, overrides);
};

export const createMockPrescriptionDeliveryRequest = (
  overrides?: RecursivePartial<PrescriptionDeliveryRequest>
): PrescriptionDeliveryRequest => {
  const base: PrescriptionDeliveryRequest = {
    id: 1,
    createdAt: new Date().toISOString(),
    items: [],
    patientMemberId: "123",
    subscriberMemberId: "123",
    totalCopay: 0,
    canceledAt: null,
    finalizedAt: null,
  };

  return merge(base, overrides);
};

export const createMockPrescriptionDeliveryRequestItem = (
  overrides?: RecursivePartial<PrescriptionDeliveryRequestItem>
): PrescriptionDeliveryRequestItem => {
  const base: PrescriptionDeliveryRequestItem = {
    id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
    createdAt: new Date().toISOString(),
    copay: 50,
    isRefill: false,
    libertyRxScriptNumber: "",
    libertyRxNpi: "",
    prescriptionDeliveryRequestId: 1,
    quantity: 50,
    unit: "Pill",
    requiresSignatureForDelivery: false,
  };

  return merge(base, overrides);
};

export const createMockMemberPaymentCard = (
  overrides?: RecursivePartial<MemberPaymentCard>
): MemberPaymentCard => {
  const base: MemberPaymentCard = {
    cardType: MemberPaymentCardType.Physical,
    id: 1,
    stripeId: "1",
    hasActiveSpendApproval: true,
    status: MemberPaymentCardStatus.Inactive,
  };

  return merge(base, overrides);
};

export const createMockIdentityVerificationResponse = (
  overrides?: RecursivePartial<IdentityVerificationResponse>
): IdentityVerificationResponse => {
  const base: IdentityVerificationResponse = {
    accessToken: "token",
    expiresAt: addHours(new Date(), 1).toISOString(),
  };

  return merge(base, overrides);
};

export const createMockEmployeeMember = (
  overrides?: RecursivePartial<EmployeeMember>
): Omit<EmployeeMember, "subscriberGroup"> => {
  const base: Omit<EmployeeMember, "subscriberGroup"> = {
    id: "1",
    subscriptionId: "1",
    givenName: "Wayne",
    familyName: "Bruce",
    address: createMockMemberAddress(),
    birthDate: "2004-03-24",
    relationship: Relationship.Dependent,
    closestPlan: createMockPlan(),
    coveragePeriodEndDateOptions: ["2054-01-31", "2054-02-28", "2054-03-31"],
    displayedCoveragePeriod: createMockCoveragePeriod(),
    displayedEnrollment: createMockEnrollment(),
    hasDisplayedTerminationPending: false,
    hasExistingMemberRecord: false,
    isInWorkbasket: false,
    hasClaims: false,
  };

  return merge(base, overrides);
};

export const createMockProviderSpecialty = (
  overrides?: RecursivePartial<ProviderSpecialty>
): ProviderSpecialty => {
  const base: ProviderSpecialty = {
    curativeCategory: "Specialty Care Providers",
    curativeSpecialty: "Acupuncture",
    id: 1,
  };

  return merge(base, overrides);
};

export const createMockPackagedDrug = (
  overrides?: RecursivePartial<PackagedDrug>
): PackagedDrug => {
  const base: PackagedDrug = {
    dispensableDrugId: 123,
    dispensableDrugTallManDesc: "Atorvastatin",
    dispensableGenericId: 456,
    drugNameTallmanDesc: "Atorvastatin",
    genericDrugNameDesc: "Generic description",
    medStrength: "10",
    medStrengthUnit: "mg",
    packagedDrugId: 789,
    routedDoseFormGenericId: 135,
  };

  return merge(base, overrides);
};

export const createMockEmployer = (
  overrides?: RecursivePartial<Employer>
): Employer => {
  const base: Employer = {
    id: "1",
    claimSystem: ClaimSystem.Vba,
    name: "Mock Employer",
    vbaDivisionId: "1",
    healthEdgeAccountKey: null,
    healthEdgeAccountId: null,
    createdAt: "2024-07-16T19:04:53Z",
    coverageInfo: mockCoverageInfo,
    canViewMembers: true,
    canViewInvoices: true,
    canEditMembers: true,
    invoices: [],
    subscribableAccounts: [
      {
        id: "111",
        name: "Mock Subscribable Account 1",
      },
    ],
    subAccounts: [],
  };

  return merge(base, overrides);
};
