import clsx from "clsx";

import { Box } from "../Box";
import { bgColorsCommon, textColorsCommon } from "../colors";
import {
  CUIComponent,
  CUIComponentProps,
  TextAlignVariant,
  TextVariant,
} from "../types";

export type HeroBannerProps = CUIComponentProps<{
  children: JSX.Element | string;
}>;

export const HeroBanner: CUIComponent<HeroBannerProps> = ({
  className,
  children,
  testId = "HeroBanner",
}) => {
  return (
    <Box
      element="div"
      className={clsx(
        bgColorsCommon.surfaceHighlight,
        textColorsCommon.highlightDefault,
        "px-2.5",
        "py-[30px]",
        "sm:p-[60px]",
        "sm:text-3xl",
        className
      )}
      testId={testId}
      textAlign={TextAlignVariant.CENTER}
      textVariant={TextVariant.LgBoldShort}
    >
      {children}
    </Box>
  );
};
