import { ProviderConfig } from "launchdarkly-react-client-sdk";
import { merge } from "lodash-es";

export const createLaunchDarklyProviderConfig = (
  config: ProviderConfig
): ProviderConfig => {
  const baseConfig = {
    options: {
      allAttributesPrivate: true,
      // LD spams the cypress console and we don't want/need want to stream flags in a test anyway
      streaming: typeof window !== "undefined" && !("Cypress" in window),
    },
  };

  return merge(baseConfig, config);
};
