import type { PrimitiveType } from "react-intl";

export const defaultFormatMessage = (
  { defaultMessage }: { defaultMessage: string },
  values?: Record<string, PrimitiveType>
) => {
  if (!values) {
    return defaultMessage;
  }

  return Object.entries(values).reduce(
    (acc, [key, value]) => acc.replace(key, value ? String(value) : ""),
    defaultMessage
  );
};
