import { formatStreetAddress } from "@chp/shared/utils/formatters";
import { useCallback } from "react";
import { useIntl } from "react-intl";

import { useTypeaheadOptions__SearchVbaProvidersLazyQuery } from "../memberPortalApi-TypeaheadOptions.generated";

export const useFetchMediviewProviderOptions = () => {
  const { formatMessage } = useIntl();
  const [
    searchMediviewProviders,
    { loading: areMediviewProviderOptionsLoading },
  ] = useTypeaheadOptions__SearchVbaProvidersLazyQuery();

  const fetchMediviewProviderOptions = useCallback(
    async (queryString: string) => {
      const response = await searchMediviewProviders({
        variables: {
          search: queryString,
        },
      });

      if (response.error || !response.data) {
        throw new Error(
          formatMessage({
            defaultMessage: "There was an error searching for providers.",
            id: "Kc9Rfi",
          })
        );
      }

      const providerNoNameFallback = formatMessage({
        defaultMessage: "(no name)",
        id: "1xSCmt",
      });

      const providers = response.data.searchVBAProviders;
      // // TODO: Add i18n support
      return providers.map((provider) => {
        const formattedProviderName =
          [provider.firstName, provider.lastName].filter(Boolean).join(" ") ||
          providerNoNameFallback;

        return {
          label: formattedProviderName,
          value: provider,
          label2: provider.nationalProviderId
            ? `NPI: ${provider.nationalProviderId}`
            : "",
          label3: formatStreetAddress(provider.address),
        };
      });
    },
    [searchMediviewProviders, formatMessage]
  );

  return {
    fetchMediviewProviderOptions,
    areMediviewProviderOptionsLoading,
  };
};
