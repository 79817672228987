import { useCallback, useEffect, useRef } from "react";

// Modify the last number to change the number of minutes
const TIMEOUT_IN_SECONDS = 1000 * 60 * 30;
const ACTIVE_EVENTS = ["click", "scroll"];

export const useInactivityTimer = ({
  onExpire,
  sessionStorageKey,
  initializer,
}: {
  onExpire: () => void;
  sessionStorageKey: string;
  initializer?: () => void;
}) => {
  const inactiveCheckInterval = useRef<NodeJS.Timeout>();

  const timeChecker = useCallback(() => {
    const expiresAt = sessionStorage.getItem(sessionStorageKey);

    inactiveCheckInterval.current = setInterval(() => {
      if (Date.now() - Number(expiresAt) > TIMEOUT_IN_SECONDS) {
        clearInterval(inactiveCheckInterval.current);
        sessionStorage.removeItem(sessionStorageKey);
        onExpire();
      }
    }, 1000);
  }, [onExpire, sessionStorageKey]);

  const resetTimer = useCallback(() => {
    clearInterval(inactiveCheckInterval.current);

    const timeStamp = Date.now();
    sessionStorage.setItem(sessionStorageKey, timeStamp.toString());

    timeChecker();
  }, [sessionStorageKey, timeChecker]);

  useEffect(() => {
    initializer?.();
  }, [initializer]);

  useEffect(() => {
    const timeStamp = Date.now();
    sessionStorage.setItem(sessionStorageKey, `${timeStamp}`);

    ACTIVE_EVENTS.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    timeChecker();

    return () => {
      clearInterval(inactiveCheckInterval.current);
      sessionStorage.removeItem(sessionStorageKey);
      ACTIVE_EVENTS.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, [resetTimer, timeChecker, sessionStorageKey]);
};
