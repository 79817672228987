export const BASELINE_VISIT_ROUTE = "/baseline-visit";
export const CARE_COST_ROUTE = "/care-cost";
export const DASHBOARD_ROUTE = "/dashboard";
export const FORMS_ROUTE = "/forms";
export const MEMBER_CARD_ROUTE = "/member-card";
export const MEMBER_PAYMENT_CARD_ROUTE = "/zero-card";
export const ONBOARDING_ROUTE = "/onboarding";
export const INVOICE_ROUTE = "/invoice";
export const PRESCRIPTION_DELIVERY_ROUTE = "/pharmacy/prescription-delivery";
export const PHARMACY_ROUTE = "/pharmacy";
export const PROFILE_ROUTE = "/profile";
export const RXTRANSFER_ROUTE = "/pharmacy/rxtransfer";
export const RXTRANSFER_NEW_ROUTE = "/pharmacy/rxtransfer/new";
export const PUBLIC_RXTRANSFER_NEW_ROUTE = "/pharmacy/rxtransfer/new/public";
export const JOIN_VISIT_ROUTE = "/join-visit";
export const LEGAL_AGREEMENT_SIGN_ROUTE = "/sign/[inviteId]";
export const UPDATE_EMAIL_PREFERENCES_ROUTE = "/email-preferences/[inviteId]";
export const UPDATE_LANGUAGE_PREFERENCES_ROUTE =
  "/language-preferences/[inviteId]";

// Health plan SideNav routes
export const BENEFITS_ROUTE = "/plan/benefits";
export const OUT_OF_POCKET_COSTS_ROUTE = "/plan/out-of-pocket-costs";
export const CLAIMS_ROUTE = "/plan/claims";
export const CLINICAL_DOCS_ROUTE = "/clinical-documents";

export const POST_LOGIN_REDIRECT_KEY = "postLoginRedirectUrl";
export const AUTHORIZATION_FAILURE_MESSAGE_KEY = "authorizationFailureMessage";

export const AVAILABLE_UNAUTHENTICATED_ROUTES = [
  INVOICE_ROUTE,
  PRESCRIPTION_DELIVERY_ROUTE,
  LEGAL_AGREEMENT_SIGN_ROUTE,
  UPDATE_EMAIL_PREFERENCES_ROUTE,
  UPDATE_LANGUAGE_PREFERENCES_ROUTE,
  JOIN_VISIT_ROUTE,
];

export const ALBERTSONS_LINKS = [
  {
    name: "Acme Pharmacy",
    link: "https://www.acmemarkets.com/health/pharmacy/guest-transfer-prescription",
  },
  {
    name: "Albertsons Pharmacy",
    link: "https://www.albertsons.com/health/pharmacy/guest-transfer-prescription",
  },
  {
    name: "Albertsons Market Pharmacy",
    link: "https://www.albertsonsmarket.com/health/pharmacy/guest-transfer-prescription",
  },
  {
    name: "Amigos Pharmacy",
    link: "https://www.amigosunited.com/health/pharmacy/guest-transfer-prescription",
  },
  {
    name: "Carrs Pharmacy",
    link: "https://www.carrsqc.com/health/pharmacy/guest-transfer-prescription",
  },
  {
    name: "Haggen Pharmacy",
    link: "https://www.haggen.com/health/pharmacy/guest-transfer-prescription",
  },
  {
    name: "Jewel-Osco Pharmacy",
    link: "https://www.jewelosco.com/health/pharmacy/guest-transfer-prescription",
  },
  {
    name: "Market Street Pharmacy",
    link: "https://portal.digitalpharmacist.com/login?pharmacy_id=c67c9dac-0efb-4366-866f-f946ff0cbaaa",
  },
  {
    name: "Pavillions Pharmacy",
    link: "https://www.pavilions.com/health/pharmacy/guest-transfer-prescription",
  },
  {
    name: "Radalls Pharmacy",
    link: "https://www.randalls.com/health/pharmacy/guest-transfer-prescription",
  },
  {
    name: "Safeway Pharmacy",
    link: "https://www.safeway.com/health/pharmacy/guest-transfer-prescription",
  },
  {
    name: "Sav-On Pharmacy",
    link: "https://insiderx.com/pharmacy-near-me/savon",
  },
  {
    name: "Star Market Pharmacy",
    link: "https://www.starmarket.com/health/pharmacy/guest-transfer-prescription",
  },
  {
    name: "Shaws Pharmacy",
    link: "https://www.shaws.com/health/pharmacy/guest-transfer-prescription",
  },
  {
    name: "Tom Thumb Pharmacy",
    link: "https://www.tomthumb.com/health/pharmacy/guest-transfer-prescription",
  },
  {
    name: "United Coalition Pharmacy",
    link: "https://www.healthgrades.com/pharmacy/united-coalition-pharmacy-pel3kv",
  },
  {
    name: "United Pharmacy",
    link: "https://www.unitedsupermarkets.com/health/pharmacy/guest-transfer-prescription",
  },
  {
    name: "Vons Pharmacy",
    link: "https://www.vons.com/health/pharmacy/guest-transfer-prescription",
  },
];
