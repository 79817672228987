import { FieldValues, Path } from "react-hook-form";
import { useIntl } from "react-intl";

import { FormMaskedInput } from "./FormMaskedInput";

export type PhoneNumberInputProps<T extends FieldValues> = {
  className?: string;
  id?: string;
  isDisabled?: boolean;
  label?: string;
  name: Path<T>;
  testId?: string;
  mask?: string;
  placeholder?: string;
};

export const PhoneNumberInput = <T extends FieldValues>({
  testId = "PhoneNumber__MaskedInput",
  label,
  mask,
  placeholder,
  ...rest
}: PhoneNumberInputProps<T>) => {
  const { formatMessage } = useIntl();
  const props = {
    ...rest,
    testId,
    label:
      label ||
      formatMessage({
        defaultMessage: "Phone number",
        id: "jdJhOL",
      }),
    type: "tel",
    autoComplete: "tel-national",
    mask: mask ?? "000-000-0000",
    emptyValue: "___-___-____",
    unmask: false,
    placeholder: placeholder ?? "123-456-9999",
  };
  return <FormMaskedInput<T> {...props} />;
};
