import { IconVariant } from "@chp/curative_ui";
import { IntlFormatters } from "react-intl";

import { PlainDate } from "../../utils";

type BenefitsStatusConfig = {
  iconVariant: IconVariant;
  statusHeading: JSX.Element | string;
  statusSubheading: JSX.Element | string;
  textColorClassName: string | null;
};

export type BenefitsStatusConfigProps = {
  isBeforeBaselineVisitDueDate: boolean;
  isBaselineVisitComplete: boolean;
  isBaselineVisitRequired: boolean;
  memberStartDate?: PlainDate;
  memberEndDate?: PlainDate;
  formatMessage: IntlFormatters["formatMessage"];
};

export const benefitsStatusConfig = ({
  isBeforeBaselineVisitDueDate,
  isBaselineVisitComplete,
  isBaselineVisitRequired,
  memberStartDate,
  memberEndDate,
  formatMessage,
}: BenefitsStatusConfigProps): BenefitsStatusConfig => {
  let iconVariant, textColorClassName, statusHeading, statusSubheading;

  if (!isBaselineVisitRequired) {
    iconVariant = IconVariant.INFORMATION_CIRCLE;
    textColorClassName = "text-textColor-highlight-depressed";
    statusHeading = formatMessage({
      defaultMessage: "Baseline Not Required",
      id: "1D2bGq",
    });
    statusSubheading = formatMessage({
      defaultMessage:
        "See parent's Baseline status. Minors receive the $0 benefit if at least one of their parents has completed the Baseline Visit.",
      id: "qFODK+",
    });
  } else if (isBaselineVisitComplete) {
    iconVariant = IconVariant.CHECK_CIRCLE;
    textColorClassName = "text-textColor-success";
    statusHeading = formatMessage({
      defaultMessage: "Baseline Complete",
      id: "5nNxZP",
    });
    statusSubheading = formatMessage({
      defaultMessage:
        "Well done! $0 copay/deductible maintained for the plan year",
      id: "OMG42O",
    });
    if (
      memberStartDate &&
      memberEndDate &&
      memberStartDate.isBefore(memberEndDate)
    ) {
      statusSubheading += ` (${memberStartDate.toMMDDYYYYFormat()} - ${memberEndDate.toMMDDYYYYFormat()})`;
    }
  } else if (isBeforeBaselineVisitDueDate) {
    iconVariant = IconVariant.INFORMATION_CIRCLE;
    textColorClassName = "text-textColor-highlight-depressed";
    statusHeading = formatMessage({
      defaultMessage: "Baseline Grace Period",
      id: "EN/zrY",
    });
    statusSubheading = formatMessage({
      defaultMessage:
        "You can enjoy $0 copay/deductible during the first 120 days",
      id: "UAYGP7",
    });
  } else {
    iconVariant = IconVariant.EXCLAMATION_CIRCLE;
    statusHeading = formatMessage({
      defaultMessage: "Baseline Not Completed",
      id: "z1KzxB",
    });
    statusSubheading = formatMessage({
      defaultMessage: "You are responsible for copays and deductibles",
      id: "Pjm6b8",
    });
    textColorClassName = null;
  }

  return {
    iconVariant,
    textColorClassName,
    statusHeading,
    statusSubheading,
  };
};
