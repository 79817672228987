// This is being stored here rather than state variables in our providers to
// prevent unnecessary re-renders each time we refresh a token. The Apollo
// Client needs to send the access token with each request and previously it was
// stored in local storage and the Apollo Link would fetch it from there. Now we
// update this token here and the link will fetch it via the "fetchAccessToken"
// function.
export let accessToken: string | null = null;

export const setAccessTokenForApollo = (newAccessToken: string | null) => {
  accessToken = newAccessToken;
};

export const fetchAccessToken = () => {
  return accessToken;
};
