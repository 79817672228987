import {
  Typeahead,
  TypeaheadProps,
  WithTypeaheadOptions,
} from "@chp/curative_ui";
import { useIntl } from "react-intl";

import { useErrorMessageWithPrefix } from "../../hooks";

export type FormattedTypeaheadProps<T> = Omit<
  TypeaheadProps<T>,
  | "clearButtonAriaLabel"
  | "loadingAriaLabel"
  | "noResultsTextWithQuery"
  | "noResultsTextWithoutQuery"
> &
  WithTypeaheadOptions<T> &
  Partial<
    Pick<
      TypeaheadProps<T>,
      "noResultsTextWithQuery" | "noResultsTextWithoutQuery"
    >
  >;

export const FormattedTypeahead = <T,>({
  errorMessage,
  noResultsTextWithQuery,
  noResultsTextWithoutQuery,
  testId = "FormattedTypeahead",
  ...rest
}: FormattedTypeaheadProps<T>): JSX.Element => {
  const { formatMessage } = useIntl();
  const errorWithPrefix = useErrorMessageWithPrefix({ errorMessage });
  const noResultsTextDefault = formatMessage({
    defaultMessage:
      "No results found. Please select another option or refine the search.",
    id: "5QWlMW",
  });

  return (
    <Typeahead
      clearButtonAriaLabel={formatMessage({
        defaultMessage: "Clear this field",
        id: "F+9ilI",
      })}
      loadingAriaLabel={formatMessage({
        defaultMessage: "Loading",
        id: "iFsDVR",
      })}
      errorMessage={errorWithPrefix}
      testId={testId}
      noResultsTextWithQuery={noResultsTextWithQuery ?? noResultsTextDefault}
      noResultsTextWithoutQuery={
        noResultsTextWithoutQuery ?? noResultsTextDefault
      }
      {...rest}
    />
  );
};
