import { useRouter } from "next/router";
import { useEffect } from "react";
import { Control, FieldValues, useFormState } from "react-hook-form";
import { useIntl } from "react-intl";

export const useDirtyFormModal = <T extends FieldValues>(
  control: Control<T>
) => {
  const { isDirty, isSubmitting, isSubmitSuccessful } = useFormState({
    control,
  });
  const { formatMessage } = useIntl();
  const confirmationMessage = formatMessage({
    defaultMessage: "You have unsaved changes. Are you sure you want to leave?",
    id: "Wq1yhE",
  });
  const { events } = useRouter();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!isDirty || isSubmitting || isSubmitSuccessful) return;

      event.preventDefault();
      event.returnValue = true;
    };

    const handleConfirmNavigate = () => {
      if (!isDirty || isSubmitting || isSubmitSuccessful) return;

      if (window.confirm(confirmationMessage)) return;

      events.emit("routeChangeError");
      throw "cancelRouteChange";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    events.on("routeChangeStart", handleConfirmNavigate);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      events.off("routeChangeStart", handleConfirmNavigate);
    };
  }, [isDirty, confirmationMessage, events, isSubmitting, isSubmitSuccessful]);
};

export default useDirtyFormModal;
