import { RefObject, useEffect, useState } from "react";

export const useIsOnScreen = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>
) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry?.isIntersecting || false)
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
};
