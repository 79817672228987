import { PlainDate } from "@chp/shared/utils/dateTime";
import { FormattedMessage } from "react-intl";

import {
  FormattedPlainDate,
  FormattedPlainDateProps,
} from "./FormattedPlainDate";

export type FormattedPlainDateRangeProps = {
  from: PlainDate;
  testId?: string;
  to: PlainDate;
} & Pick<FormattedPlainDateProps, "variant">;

/**
 * Return i18n formatted PlainDate range like "9/1/2022 - 12/31/2022"
 */
export const FormattedPlainDateRange = ({
  from,
  to,
  variant,
}: FormattedPlainDateRangeProps): JSX.Element => (
  // TODO: consider using FormattedDateTimeRange https://formatjs.io/docs/react-intl/components/#formatteddatetimerange
  <FormattedMessage
    defaultMessage="{fromDate} - {toDate}"
    id="b/S/Er"
    values={{
      fromDate: <FormattedPlainDate variant={variant} value={from} />,
      toDate: <FormattedPlainDate variant={variant} value={to} />,
    }}
  />
);
