import { useMemo } from "react";

/**
 * Memoized list of array keys.
 *
 * @deprecated see useWithArrayKeys
 */
export const useArrayKeys = <T,>({
  items,
  keyGenerator,
}: {
  items: T[];
  keyGenerator: (item: T, index: number) => string | number;
}): Array<string | number> =>
  useMemo(
    () => items.map((item, index) => keyGenerator(item, index)),
    [items, keyGenerator]
  );
