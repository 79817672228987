import { Modal, ModalMaxWidth, SpacingVariant, Tabs } from "@chp/curative_ui";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { LoadingIndicator } from "../../components/LoadingIndicator";
import CardDetails from "./CardDetails";
import {
  CardDetail,
  PaymentCardType,
  usePaymentCardsContext,
} from "./MemberPaymentCardsContext";

const MultiCardDetails = ({
  name,
  physical,
  virtual,
}: {
  name: string;
  physical: CardDetail;
  virtual: CardDetail;
}) => {
  const { formatMessage } = useIntl();

  return (
    <Tabs
      isCompact
      disableArrows
      contentPadding={{
        top: SpacingVariant.S0,
        bottom: SpacingVariant.S0,
        left: SpacingVariant.S0,
        right: SpacingVariant.S0,
      }}
      tabs={[
        {
          name: formatMessage({
            defaultMessage: "Virtual Card",
            id: "Qzcib0",
          }),
          content: (
            <CardDetails
              cardHolderName={name}
              cardId={virtual.info.stripeId}
              elements={virtual.elements}
            />
          ),
          key: "virtual-card",
        },
        {
          name: formatMessage({
            defaultMessage: "Physical Card",
            id: "6z2Yy/",
          }),
          content: (
            <CardDetails
              cardHolderName={name}
              cardId={physical.info.stripeId}
              elements={physical.elements}
              physical={true}
            />
          ),
          key: "physical-card",
        },
      ]}
      leftButtonAriaLabel="left"
      rightButtonAriaLabel="right"
    />
  );
};

const CardDetailsModal = ({
  name,
  isVisible,
  setIsVisible,
  displayInfo = true,
}: {
  name: string;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  displayInfo?: boolean;
}) => {
  const { formatMessage } = useIntl();
  const { cards, loadCard } = usePaymentCardsContext();

  const [loading, setLoading] = useState(false);

  const hasMultipleCards = Object.values(cards).filter((c) => !!c).length > 1;

  useEffect(() => {
    if (loading) {
      return;
    }

    const loadingCards: Promise<void>[] = [];
    if (
      cards[PaymentCardType.Physical] &&
      !cards[PaymentCardType.Physical]!.elements
    ) {
      loadingCards.push(loadCard(PaymentCardType.Physical));
    }
    if (
      cards[PaymentCardType.Virtual] &&
      !cards[PaymentCardType.Virtual].elements
    ) {
      loadingCards.push(loadCard(PaymentCardType.Virtual));
    }

    if (loadingCards.length > 0) {
      setLoading(true);
      Promise.all(loadingCards).finally(() => {
        setLoading(false);
      });
    }
  }, [cards, loadCard, loading]);

  return (
    <Modal
      maxWidth={ModalMaxWidth.Medium}
      isOpen={isVisible}
      title={formatMessage({ defaultMessage: "Your Zero Card", id: "R/IrHR" })}
      closeButtonText={formatMessage({ defaultMessage: "Close", id: "rbrahO" })}
      onRequestClose={() => setIsVisible(false)}
    >
      {loading ? (
        <LoadingIndicator />
      ) : (
        <div className="min-w-screen md:min-w-[350px]">
          {displayInfo && (
            <p className="pb-2 pt-4">
              <FormattedMessage
                defaultMessage="Your Curative Zero Card can only be used for authorized purchases. Contact Member Services to unlock your card."
                id="z1tWWn"
              />
            </p>
          )}

          {hasMultipleCards ? (
            <MultiCardDetails
              name={name}
              physical={cards[PaymentCardType.Physical]!}
              virtual={cards[PaymentCardType.Virtual]!}
            />
          ) : (
            <CardDetails
              cardHolderName={name}
              cardId={Object.values(cards).find((c) => !!c)!.info.stripeId}
              elements={Object.values(cards).find((c) => !!c)!.elements}
            />
          )}
        </div>
      )}
    </Modal>
  );
};

export default CardDetailsModal;
