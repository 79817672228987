import { ExpandableMenuItemActionEventName } from "@chp/curative_ui/src/components/SideNavbar/ExpandableItem";

import { EmployerPortalMixpanelEventName } from "./employerPortal";
import { FormularySearchMixpanelEventName } from "./formularySearch";
import { MemberPortalMixpanelEventName } from "./memberPortal";
import { ProviderFacilitySearchMixpanelEventName } from "./providerFacilitySearch";

export * from "./adminPortal";
export * from "./employerPortal";
export * from "./formularySearch";
export * from "./memberPortal";
export * from "./memberStatusPages";
export * from "./providerFacilitySearch";
export * from "./providerPortal";

// This has a mixture of casing in order to keep the original casings from the original files
// so that we don't have to change things up in Mixpanel dashboard
export enum MixpanelAppName {
  EMPLOYER_PORTAL = "EMPLOYER_PORTAL",
  MEMBER_PORTAL = "member_portal",
  PROVIDER_PORTAL = "prior_auth",
  MEMBER_STATUS_PAGES = "MEMBER_STATUS_PAGES",
  PROVIDER_FACILITY_SEARCH = "provider_search",
  ADMIN_PORTAL = "admin_portal",
  FORMULARY_SEARCH = "FORMULARY_SEARCH",
}

export enum PageViewMixpanelEventName {
  PAGE_VIEW = "PAGE_VIEW",
}

// This is for the ones in `web/shared` directory, or being used across multiple apps
export enum SharedMixpanelUiIdentiferName {
  MEMBER_CARD_APPLE_WALLET = "MEMBER_CARD_APPLE_WALLET",
  MEMBER_CARD_DOWNLOAD = "MEMBER_CARD_DOWNLOAD",
  MEMBER_CARD_PRINT = "MEMBER_CARD_PRINT",
  MEMBER_CARD_REQUEST_COPY = "MEMBER_CARD_REQUEST_COPY",
  MEMBER_CARD_SEND_BY_EMAIL = "MEMBER_CARD_SEND_BY_EMAIL",
  HEADER_LANGUAGE_SELECTOR = "HEADER_LANGUAGE_SELECTOR",
}

export enum SharedMixpanelEventName {
  CLICK = "CLICK",
  COMPONENT_LOAD = "COMPONENT_LOAD",
  DOWNLOAD = "DOWNLOAD",
  DOWNLOAD_FAIL = "DOWNLOAD_FAIL",
  DOWNLOAD_SUCCESS = "DOWNLOAD_SUCCESS",
  ERROR = "ERROR",
  FETCH = "FETCH",
  NOT_FOUND = "NOT_FOUND",
  SELECT_ENGLISH = "SELECT_ENGLISH",
  SELECT_SPANISH = "SELECT_SPANISH",
  SUBMIT = "SUBMIT",
  SUBMIT_FAIL = "SUBMIT_FAIL",
  SUBMIT_SUCCESS = "SUBMIT_SUCCESS",
  SUCCESS = "SUCCESS",
  OPEN = "OPEN",
  UPDATE_FILTER = "UPDATE_FILTER",
}

export type MixpanelEventName =
  | ExpandableMenuItemActionEventName
  | SharedMixpanelEventName
  | FormularySearchMixpanelEventName
  | EmployerPortalMixpanelEventName
  | ProviderFacilitySearchMixpanelEventName
  | MemberPortalMixpanelEventName;
