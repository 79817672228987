import clsx from "clsx";
import { ReactElement, useState } from "react";
import { useMediaPredicate } from "react-media-hook";

import { FOCUS_CLASS_NAMES, SpacingVariant } from "../../constants";
import { BREAKPOINT_LG, desktopBreakpoint } from "../../mediaBreakpoints";
import { Box } from "../Box";
import { ButtonVariant } from "../Button";
import { Icon, IconVariant } from "../Icon";
import { linkButtonStyling } from "../Link";
import { LocalePicker } from "../LocalePicker";
import { LogoResponsive, LogoVariant } from "../Logo";
import { CUIComponent, TextVariant } from "../types";
import { CUIComponentProps } from "../types";

export type NavbarLink = { key?: string; text: string; href: string };

export type NavbarProps = CUIComponentProps<{
  links?: NavbarLink[];
  buttons?: NavbarLink[];
  logoAltText: string;
  logoHref?: string;
  renderLocalePicker?: () => ReactElement<typeof LocalePicker> | null;
  openMenuButtonAriaLabel: string;
  closeMenuButtonAriaLabel: string;
}>;

export const Navbar: CUIComponent<NavbarProps> = ({
  links,
  buttons,
  logoAltText,
  logoHref = "/",
  renderLocalePicker,
  testId = "Navbar",
  className,
  openMenuButtonAriaLabel,
  closeMenuButtonAriaLabel,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const desktop = useMediaPredicate(`(min-width: ${desktopBreakpoint}px)`);
  const displayMenuLinks = useMediaPredicate(`(min-width: ${BREAKPOINT_LG}px)`);

  return (
    <>
      <nav
        data-testid={testId}
        className={clsx(
          "flex",
          "top-0",
          "left-0",
          "fixed",
          "w-full",
          "h-16",
          "md:h-20",
          "bg-blue-700",
          "text-white",
          "items-center",
          "md:py-5",
          "px-6",
          "z-40",
          className
        )}
      >
        <div
          className={clsx(
            "flex",
            "flex-1",
            "mx-auto",
            "bg-inherit",
            "items-center",
            "justify-between",
            "max-w-screen-md",
            "md:max-w-full",
            "lg:max-w-screen-lg",
            "xl:max-w-screen-xl"
          )}
        >
          <a
            href={logoHref}
            className={clsx("flex", "grow", "md:grow-[2]", FOCUS_CLASS_NAMES)}
          >
            <LogoResponsive
              altText={logoAltText}
              variant={LogoVariant.OffWhite}
            />
          </a>
          <div
            className={clsx(
              "flex",
              "md:grow-[4]",
              "justify-evenly",
              "items-center",
              desktop ? "" : "gap-x-6"
            )}
          >
            {displayMenuLinks && (
              <>
                {links?.map(({ key, href, text }) => (
                  <Box
                    className={clsx(FOCUS_CLASS_NAMES)}
                    element="a"
                    textVariant={TextVariant.MdRegularTall}
                    key={key || href}
                    href={href}
                    testId={`${testId}__Link`}
                  >
                    {text}
                  </Box>
                ))}
                {links && renderLocalePicker && (
                  <div className={clsx("h-6", "w-0")} />
                )}
              </>
            )}
          </div>
          <div
            className={clsx(
              "flex",
              "grow",
              "items-center",
              "justify-end",
              desktop ? "gap-x-10" : "gap-x-6"
            )}
          >
            {desktop && (
              <>
                {buttons?.map(({ key, href, text }) => (
                  <Box
                    className={clsx(
                      linkButtonStyling(ButtonVariant.BIG_OUTLINE_PILL)
                    )}
                    element="a"
                    key={key || href}
                    href={href}
                    testId={`${testId}__Link`}
                  >
                    {text}
                  </Box>
                ))}
                {links && renderLocalePicker && (
                  <div className={clsx("h-6", "w-0")} />
                )}
              </>
            )}
          </div>
          {renderLocalePicker && renderLocalePicker()}
          {links && !displayMenuLinks && !mobileMenuOpen && (
            <button
              type="button"
              className={clsx("ml-4 p-[10px]", FOCUS_CLASS_NAMES)}
              onClick={() => setMobileMenuOpen(true)}
              aria-label={openMenuButtonAriaLabel}
              data-testid={`${testId}__MobileMenuButton`}
            >
              <Icon
                variant={IconVariant.MENU}
                size={desktop ? SpacingVariant.S32 : SpacingVariant.S24}
              />
            </button>
          )}
        </div>
      </nav>

      {mobileMenuOpen && (
        <nav
          data-testid={`${testId}__MobileMenu`}
          className={clsx(
            "top-0",
            "left-0",
            "fixed",
            "flex",
            "py-4",
            "px-6",
            "flex-col",
            "w-screen",
            "h-screen",
            "bg-blue-700",
            "text-white",
            "z-50"
          )}
        >
          <div
            className={clsx("flex", "items-center", "justify-between", "h-8")}
          >
            <LogoResponsive
              altText={logoAltText}
              variant={LogoVariant.OffWhite}
            />
            <button
              className={clsx(FOCUS_CLASS_NAMES)}
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              aria-label={closeMenuButtonAriaLabel}
              data-testid={`${testId}__MobileMenuButton`}
            >
              <Icon variant={IconVariant.X} size={SpacingVariant.S24} />
            </button>
          </div>

          <div className={clsx("flex", "flex-col", "gap-y-4", "mt-8")}>
            {links?.map(({ href, text }) => (
              <Box
                className={clsx(FOCUS_CLASS_NAMES)}
                element="a"
                textVariant={TextVariant["3XlRegular"]}
                key={href}
                href={href}
              >
                {text}
              </Box>
            ))}
          </div>
        </nav>
      )}
    </>
  );
};
